import { PostTable } from "../components/admin/PostTable"

export default function AdminDashboard() {
  return (
    <div className=" mx-auto py-10 px-10 pb-32">
      <h1 className="text-3xl font-bold mb-6 text-foreground">Admin Dashboard</h1>
      <PostTable />
    </div>
  )
}


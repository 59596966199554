import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class CustomerFeedbackApi{
    baseUrl = EnvSettings.url;
    SearchCustomerFeedbackUrl = 'SearchCustomerFeedbacks';
    GetCustomerFeedbackByGuidUrl = 'GetCustomerFeedbackByGuid';
    ClaimFeedbackRewardUrl = 'ClaimFeedbackReward';

    async ClaimFeedbackReward(req)
    {
        const res = await fetch(this.baseUrl + this.ClaimFeedbackRewardUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchCustomerFeedbacks(req)
    {
        const res = await fetch(this.baseUrl + this.SearchCustomerFeedbackUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetCustomerFeedbackByGuid(req)
    {
        const res = await fetch(this.baseUrl + this.GetCustomerFeedbackByGuidUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

}

export default new CustomerFeedbackApi();
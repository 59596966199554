import React, { useState } from 'react';

import FileUploadWizard from "../FileUploadWizard/index";
import FiberHouseApi from '../../../../API/FiberHouseApi';
import BatchCreateCandidateLeadReq from '../../../../Requests/FiberHouse/BatchCreateCandidateLeadReq';



const TestingFileUploadWizard =({setMapStringObjects})=>{


  const Req= new BatchCreateCandidateLeadReq();
  Req.candidateType='Company';
  //let res = await FiberHouseApi.BatchCreateCandidateLeads(Req);
    const [mapping] = useState([
        { header: 'Company Name', Datatype: 'string', Required: true },
        
        { header: 'name', Datatype: 'string', Required: true },

        { header: 'personalEmail', Datatype: 'string', Required: false },
        { header: 'country', Datatype: 'string', Required: true },
        { header: 'age', Datatype: 'number', Required: false },
        { header: 'Email', Datatype: 'string', Required: false },
        { header: 'address', Datatype: 'string', Required: false },

        { header: 'city', Datatype: 'string', Required: false },

        { header: 'linkedIn', Datatype: 'string', Required: false },
        { header: 'Phone Numbers', Datatype: 'string', Required: false },
      ]);

    // return <FileUploadWizard mapping={mapping} onFinish={setMapStringObjects} apiCallback={FiberHouseApi.BatchCreateCandidateLeads}   apiRequest={Req} dataField={'candidates'}/>;
    return <FileUploadWizard mapping={mapping} onFinish={setMapStringObjects} apiCallback={(req)=>FiberHouseApi.BatchCreateCandidateLeads(req)}   apiRequest={Req} dataField={'candidates'}/>;
      
}
export default TestingFileUploadWizard;
import { Modal } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import StringIntReq from "../../../Requests/StringIntReq";
import FiberCompanyApi from "../../../API/FiberCompanyApi";
import Swal from "sweetalert2";
import CommandTextArea from "./../../CommandTextArea";
import { UserContext } from "../../../App";
import CustomerApi from "../../../API/CustomerApi";
import ContactButtons from "../../../Services/ContactButtons";
const ReviewModal = ({ open, setOpen, lead }) => {
  const [communicationTemplates, setCommunicationTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailSubject, setEmailSubject] = useState("Review Request");
  const [emailBody, setEmailBody] = useState("Review Request");
  const [mode, setMode] = useState("email");
  const [files, setFiles] = useState([]);
  const user = useContext(UserContext);
  const [method, setMethod] = useState(null);
  const contactButtons = new ContactButtons();
  const fetchCommunicationTemplates = async () => {
    try {
      let req = new StringIntReq();
      req.string = null;
      req.category = "ReviewRequest";
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 1000;
      let response = await FiberCompanyApi.GetCommunicationTemplates(req);
      if (response.status.success) {
        const filteredTemplates = response.data.list.filter(t => t.isVisible)



        setCommunicationTemplates(filteredTemplates);
        setSelectedTemplate(filteredTemplates.length>0?filteredTemplates[0]:null);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendReview = () => {
    if (method === "email") {
      contactButtons.email(
        `${lead?.fidiumCustomer?.email}?subject=${encodeURIComponent(
          selectedTemplate?.subject
        )} &body=${encodeURIComponent(selectedTemplate?.body)}`
      );
    } else if (method === "wa") {
      contactButtons.whatsapp(
        `${lead?.fidiumCustomer?.cellPhone}?text=${encodeURIComponent(selectedTemplate?.body)}`
      );
    } else if (method === "sms") {
      contactButtons.sms(
        `${lead?.fidiumCustomer?.cellPhone}?body=${encodeURIComponent(selectedTemplate.body)}`
      );
    }
  };

  useEffect(() => {
    if (method && selectedTemplate) {
        sendReview();
        setMethod(null)
    }
}, [method]);

  useEffect(() => {
    if (selectedTemplate) {
      if (user.userType === "SalesRep") {
        setEmailBody(selectedTemplate?.body);
        setEmailSubject(selectedTemplate?.subject);
        if (selectedTemplate.attachments?.length > 0) {
          // changeFiles();
        }
      } else if (mode === "email") {
        setEmailBody(selectedTemplate?.body);
        setEmailSubject(selectedTemplate?.subject);
        if (selectedTemplate.attachments?.length > 0) {
          // changeFiles();
        }
      } else if (mode === "sms") {
        setEmailBody(selectedTemplate?.textBody);
      }
    }
  }, [mode, selectedTemplate]);

    useEffect(() => {
        if(open)
        fetchCommunicationTemplates();
    }, [open]);

  const sendReviewRequest = async () => {
    Swal.fire({
      icon: "info",
      title: "Sending Review Request",
      text: "Please wait...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const selectedTemplateSubject = selectedTemplate.subject;
    const selectedTemplateBody = selectedTemplate.body;
    const smsBody = selectedTemplate.textBody;

    const req = {
      leadId: lead.id,
      subject: selectedTemplateSubject,
      content: selectedTemplateBody,
      body: smsBody,
      userId: user.id,
      mode: mode,
    };

    const result = await CustomerApi.SendReviewRequestToCustomer(req);

    if (result.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Review Request sent successfully",
      });
      setOpen(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send Review Request",
      });
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="custom_modal">
        <div className="flex flex-col gap-4 w-full">
          <h1>Review Request</h1>
          {user.userType === "SuperAdmin" && (
            <div className="flex items-center gap-4 w-full">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="email"
                  checked={mode === "email"}
                  onChange={() => setMode("email")}
                  className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                />
                <label htmlFor="email" className="text-sm font-medium">
                  Email
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="sms"
                  checked={mode === "sms"}
                  onChange={() => setMode("sms")}
                  className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                />
                <label htmlFor="sms" className="text-sm font-medium">
                  SMS
                </label>
              </div>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <label htmlFor="template" className="text-sm font-medium">
              Template
            </label>
            <select
              id="template"
              style={{ textAlign: "left" }}
              className="w-full border-2 border-[#022780] rounded-lg p-2"
              onChange={(e) => {
                setSelectedTemplate(
                  communicationTemplates.find(
                    (template) => template.name === e.target.value
                  )
                );
              }}
              value={selectedTemplate?.name}
            >
              {communicationTemplates.map((template) => (
                <option key={template.id} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
          {mode === "email" && (
            <div className="flex flex-col gap-2">
              <label htmlFor="subject" className="text-sm font-medium">
                Subject
              </label>
              <input
                id="subject"
                type="text"
                placeholder="Subject"
                value={emailSubject}
                style={{ textAlign: "left" }}
                onChange={(e) => setEmailSubject(e.target.value)}
                className="border-2 border-[#022780] rounded-lg p-2"
              />
            </div>
          )}
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="body" className="text-sm font-medium">
              {mode === "email"
                ? "Body"
                : user.userType !== "SuperAdmin"
                ? "SMS Body"
                : "Body"}
            </label>
            <CommandTextArea
              id="body"
              placeholder="Body"
              text={emailBody}
              options={[
                { label: "Receiver Name", value: "{{Receiver Name}}" },
                { label: "link", value: "{{link}}" },
              ]}
              setText={setEmailBody}
              className="border-2 w-full rounded-lg p-2 h-28"
            />
          </div>
          {}
          {user.userType === "SuperAdmin" && (
            <button
              onClick={() => {
                sendReviewRequest();
              }}
              className="bg-[#022780] text-white px-4 py-2 rounded-lg"
            >
              Send
            </button>
          )}
          {user.userType === "SalesRep" && (
            <div className="flex gap-2 mt-4">
              <button
                onClick={() => setMethod("email")}
                className={`px-4 py-2 rounded-lg ${
                  method === "email" ? "bg-blue-600 text-white" : "bg-gray-200"
                }`}
              >
                Email
              </button>
              <button
                onClick={() => setMethod("sms")}
                className={`px-4 py-2 rounded-lg ${
                  method === "sms" ? "bg-blue-600 text-white" : "bg-gray-200"
                }`}
              >
                SMS
              </button>
              <button
                onClick={() => setMethod("wa")}
                className={`px-4 py-2 rounded-lg ${
                  method === "wa" ? "bg-blue-600 text-white" : "bg-gray-200"
                }`}
              >
                WhatsApp
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;

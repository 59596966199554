import BlogList from "../components/blogs/BlogList";
import PublicLayout from "../../../../Layouts/PublicLayout";


export default function BlogsPage() {
  return (
    <PublicLayout>
      <div className="min-h-screen flex flex-col ">
        <main className="flex-grow container mx-auto px-4 py-8 ">
          <h1 className="text-3xl font-bold mb-8 text-white">Latest Blog Posts</h1>
          <BlogList />
        </main>
      </div>
    </PublicLayout>
  );
}

import React, { useState, useEffect } from "react";
import "./styles/FccMapPopUpDetails.css";

const SpeedMeter = ({ percentage, label }) => {
  const speedClass =
    {
      "0-2": "low",
      "1-10": "medium-low",
      "3-25": "medium",
      "20-100": "medium-high",
      "25-250": "high",
      "100-1000": "very-high",
    }[label] || "low";

  return (
    <div className="speed-item">
      <div className="speed-label">{label} Mbps</div>
      <div className="speed-bar-container">
        <div
          className={`speed-bar ${speedClass}`}
          style={{ width: `${percentage}%` }}
        />
        <span className="speed-percent">{percentage}%</span>
      </div>
    </div>
  );
};

const FccMapPopUpDetails = ({ fccData, onClose, allData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const speedRanges = [
    ["speed_02_02", "0-2"],
    ["speed_10_1", "1-10"],
    ["speed_25_3", "3-25"],
    ["speed_100_20", "20-100"],
    ["speed_250_25", "25-250"],
    ["speed_1000_100", "100-1000"],
  ].map(([key, label]) => ({ key, label }));

  useEffect(() => {
    setCurrentIndex(0);
  }, [allData]);

  const goToPrevious = () => {
    setCurrentIndex(prev => Math.max(prev - 1, 0));
  };

  const goToNext = () => {
    setCurrentIndex(prev => Math.min(prev + 1, allData?.length - 1 || 0));
  };

  if (!allData?.length) {
    return (
      <div className="fcc-popup">
        <div className="fcc-popup-header">
          <h2>FCC Data Details</h2>
          <button className="fcc-popup-close" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </div>
        <div className="fcc-popup-content">
          <div className="no-data-message">
            <svg viewBox="0 0 24 24">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
            </svg>
            <p>No FCC data available</p>
          </div>
        </div>
      </div>
    );
  }

  const currentItem = allData[currentIndex];


  return (
    <div className="fcc-popup">
      <div className="fcc-popup-header">
        <h4>Broadband Coverage ({currentIndex + 1}/{allData.length})</h4>
        <button className="fcc-popup-close" onClick={onClose} aria-label="Close">
          &times;
        </button>
      </div>
      
      <div className="fcc-popup-content">
        <div className="fcc-detail-card">
          <div className="card-section">
            <div className="provider-info font-bold text-lg">🏢 {currentItem.holdingCompany}</div>
            <div className="tech-info">📶 {currentItem.technologyDesc}</div>
          </div>

          <div className="card-section">
            <div className="stats-grid">
              <div className="stat-item">
                <span>Total Units: </span>
                <span>{currentItem.totalUnits}</span>
              </div>
              <div className="stat-item">
                <span>Residential: </span>
                <span className="highlight">
                  {(currentItem.residentialServicePct * 100).toFixed(1)}%
                </span>
              </div>
              <div className="stat-item">
                <span>Business: </span>
                <span className="highlight">
                  {(currentItem.businessServicePct * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          <div className="card-section">
            <h6>Speed Distribution</h6>
            <div className="speed-distribution">
              {speedRanges.map(({ key, label }) => (
                <SpeedMeter
                  key={key}
                  label={label}
                  percentage={(currentItem[key] * 100).toFixed(1)}
                />
              ))}
            </div>
          </div>
        </div>

        {allData.length > 1 && (
          <div className="slider-controls">
            <button 
              onClick={goToPrevious} 
              disabled={currentIndex === 0}
              className="slider-button"
            >
              ← Previous
            </button>
            <button 
              onClick={goToNext} 
              disabled={currentIndex === allData.length - 1}
              className="slider-button"
            >
              Next →
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FccMapPopUpDetails;
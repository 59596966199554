/* eslint-disable react-hooks/exhaustive-deps */
import { toLonLat } from 'ol/proj'
import React, { useEffect } from 'react'
// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import Swal from 'sweetalert2'
import { MapContext } from '..'
import FiberHouseApi from '../../../../API/FiberHouseApi'
// import MapIconApi from '../../../../API/MapIconApi'
import { UserContext } from '../../../../App'
import CreateFiberHouseReq from '../../../../Requests/FiberHouse/CreateFiberHouseReq'
// import StringIntReq from '../../../../Requests/StringIntReq'
import MapHelper from '../MapHelper'
import { Icon, Style } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import { Feature } from 'ol'


const CreateFiberHouse = () => {

    const user = React.useContext(UserContext)
    const { mapObject, setIndex,mapIcons,setFiberHouses,createMode } = React.useContext(MapContext)
    const helper = new MapHelper(mapObject)
    const [address, setAddress] = React.useState("")
    const [selectedMapIcon, setSelectedMapIcon] = React.useState(null);

    const lightningBolts = [
        "Buy1",
        "Inst1",
        "Inst2",
        "Inst3",
        "CNL",
        "PC",
        "Inst4",
        "IC",
        "OSL",
        "MA",
        "HotL2"
    ]

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])



    //delete display markers when component unmounts
    useEffect(() => {
        return () => {
            // Remove the marker layer by name from the map object without having to keep track of the layer object
            if (mapObject) {
                const layers = mapObject.getLayers();
                layers.forEach((layer) => {
                    if (layer?.get('name') === 'markerLayer') {
                        mapObject.removeLayer(layer);
                    }
                });
            }
        }
    }, [])







    const createFiberHouse = (add, coor, assignedMapIcons,vectorLayer) => {


        if (!address && !add) {
            Swal.fire({
                icon: 'error',
                title: 'Address is required',
                text: 'Please enter Address',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

     

        if (!selectedMapIcon) {
            Swal.fire({
                icon: 'error',
                title: 'Map Icon is required',
                text: 'Please enter Map Icon',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        let orgId = null;
        if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
            orgId = null;
        }
        else {
            orgId = user.salesOrgId;
        }

        const req = new CreateFiberHouseReq()
        req.createdById = user.id
        req.salesOrgId = orgId

        req.mapString = add ? add : address

        req.coordinate.latitude = coor[1]
        req.coordinate.longitude = coor[0]
        req.coordinate.popUpColor = "#000000"
        req.mapIconId = assignedMapIcons.id
        req.lead.salesOrgId = user.salesOrgId
        FiberHouseApi.CreateFiberHouse(req).then(
            (res) => {
                if (res?.status?.success) {
                    console.log(res)

                    
                    mapObject.removeLayer(vectorLayer);
                    setFiberHouses((prev) => {
                        return [...prev, res.data]
                    })

                }
                else {

                }
            }
        )

    }



    const displayDraggableMapIcon = async (coordinates) => {
        if (mapObject && selectedMapIcon) {
          
             
            const feature = new Feature({
                geometry: new Point(coordinates)
            });

            const vectorSource = new VectorSource({
                features: [feature]
            });

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                name: 'markerLayer'
            });

            vectorLayer.getSource().getFeatures()[0].setStyle(new Style({
                image: new Icon({
                    src: "data:image/png;base64,"+selectedMapIcon.image,
                    scale: 0.5,
                    anchor: [0.5, 1],
                })
            }));

            mapObject.addLayer(vectorLayer);


            const res = await helper.getLocationName(toLonLat(coordinates))
            createFiberHouse(res, toLonLat(coordinates), selectedMapIcon,vectorLayer)
            setAddress(res)
            setSelectedMapIcon(null)
        }
    }

    //Display the moveable marker
    useEffect(() => {

        async function displayMarkerOnMap(event) {
            const coordinates = event.coordinate;
            displayDraggableMapIcon(coordinates);
        }

        if (selectedMapIcon) {

            // disable all the other onclicks

            createMode.current = true;
            mapObject.on('click', displayMarkerOnMap);
        }

        return () => {
            mapObject.un('click', displayMarkerOnMap);
            createMode.current = false;
        }
    }, [selectedMapIcon])



    const style = {
        borderRadius: screenWidth < 1000 ? "50%" : "0",
        padding: screenWidth < 1000 ? "0.2rem" : "0",
    }

    return (
        <div  className='CreateFiberHouse'>
            {<div className='TitleDiv'>
                <h3 className='text-4xl font-bold'>Create Fiber House</h3>

                {user.userType === "SuperAdmin" && <button onClick={() => setIndex(6)} className='uppercase text-[#2761D0] '>Create Using CSV</button>}

            </div>}

           
            <div style={{
                overflow:"auto",
                width:"100%",
                margin:"0rem"
            }} className='MapIconDisplay' >
                {
                    mapIcons
                    .filter(icon => icon.iconType === null)
                    .filter(m => {

                        if (lightningBolts.includes(m.abbreviation)) {

                            return false; 
                        }
                        return true;
                    })
                    .map(
                        (assignedMapIcons) => {
                            return <div className={`MapIconDiv`}
                            style={{ border: selectedMapIcon?.id === assignedMapIcons.id ? screenWidth > 1000 ? "2px solid #2761D0" : "none" : "none", cursor: 'pointer' }} onClick={() => setSelectedMapIcon(assignedMapIcons)}>
                                 <img style={{
                                        border: screenWidth < 1000 && selectedMapIcon?.id === assignedMapIcons.id ? "4px solid #2761D0" : "none",
                                        ...style
                                    }} src={"data:image/png;base64," + assignedMapIcons.image} alt="Map Icon" />
                                <div>{assignedMapIcons.abbreviation}</div>
                            </div>
                        }

                    )
                }
            </div>
        </div>
    )
}

export default CreateFiberHouse
import { Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

const Index = ({ style, setFrom, from, to, setTo, label, placeholder, className, reset, future, selectQuickDate, setQuickDateValue }) => {
    const [quickDate, setQuickDate] = useState(selectQuickDate || "All Time");
    const [fromDateDisplay, setFromDateDisplay] = useState(from ? from.split('T')[0] : "");
    const [toDateDisplay, setToDateDisplay] = useState(to ? to.split('T')[0] : "");
    const initialRender = useRef(true);
    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    // const formatDateWithoutTimezone = (date) => {
    //     return date.toLocaleString('sv-SE', { hour12: false }).replace(' ', 'T');
    // };


    const handleChange = (e) => {



        switch (e.target.value) {
            case "Today":
                const today = new Date();
                let todayStart = new Date(today.setHours(0, 0, 0, 0));
                let todayEnd = new Date(today.setHours(23, 59, 59, 999));
                console.log(todayStart)
                console.log(todayEnd)
                setFrom(formatDateToISOString(todayStart)); // 'en-CA' format is YYYY-MM-DD
                setTo(formatDateToISOString(todayEnd));

                setFromDateDisplay(todayStart.toLocaleDateString('en-CA'));
                setToDateDisplay(todayEnd.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("Today");
                break;
            case "Tomorrow":
                let tomorrow = new Date();
                let tomorrowStart = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
                let tomorrowEnd = new Date(tomorrow.setHours(23, 59, 59, 999));
                setFromDateDisplay(tomorrowStart.toLocaleDateString('en-CA'));
                setToDateDisplay(tomorrowEnd.toLocaleDateString('en-CA'));
                setFrom(formatDateToISOString(tomorrowStart));
                setTo(formatDateToISOString(tomorrowEnd));
                if (setQuickDateValue)
                    setQuickDateValue("Tomorrow");
                break;
            case "Yesterday":
                let yesterday = new Date();
                let yesterdayStart = new Date(new Date(yesterday.setDate(yesterday.getDate() - 1)).setHours(0, 0, 0, 0));
                let yesterdayEnd = new Date(yesterday.setHours(23, 59, 59, 999));
                setFromDateDisplay(yesterdayStart.toLocaleDateString('en-CA'));
                setToDateDisplay(yesterdayEnd.toLocaleDateString('en-CA'));
                setFrom(formatDateToISOString(yesterdayStart));
                setTo(formatDateToISOString(yesterdayEnd));
                if (setQuickDateValue)
                    setQuickDateValue("Yesterday");
                break;
            case "This Week":
                const currentDay = new Date();
                const firstDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - (currentDay.getDay() + 6) % 7));
                const lastDayOfWeek = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 7));
                let from = new Date(firstDayOfWeek.setHours(0, 0, 0, 0));
                let to = new Date(lastDayOfWeek.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(from));
                setTo(formatDateToISOString(to));
                setFromDateDisplay(firstDayOfWeek.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfWeek.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("This Week");
                break;
            case "Next Week":
                const nextWeekDay = new Date();
                const firstDayOfNextWeek = new Date(nextWeekDay.setDate(nextWeekDay.getDate() - (nextWeekDay.getDay() + 6) % 7 + 7));
                const lastDayOfNextWeek = new Date(nextWeekDay.setDate(nextWeekDay.getDate() - nextWeekDay.getDay() + 7));
                let fromNextWeek = new Date(firstDayOfNextWeek.setHours(0, 0, 0, 0));
                let toNextWeek = new Date(lastDayOfNextWeek.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromNextWeek));
                setTo(formatDateToISOString(toNextWeek));
                setFromDateDisplay(firstDayOfNextWeek.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfNextWeek.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("Next Week");
                break;
            case "Last Week":
                const lastWeekDay = new Date();
                const firstDayOfLastWeek = new Date(lastWeekDay.setDate(lastWeekDay.getDate() - (lastWeekDay.getDay() + 6) % 7 - 7));
                const lastDayOfLastWeek = new Date(lastWeekDay.setDate(lastWeekDay.getDate() - lastWeekDay.getDay() + 7));
                let fromLastWeek = new Date(firstDayOfLastWeek.setHours(0, 0, 0, 0));
                let toLastWeek = new Date(lastDayOfLastWeek.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromLastWeek));
                setTo(formatDateToISOString(toLastWeek));
                setFromDateDisplay(firstDayOfLastWeek.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfLastWeek.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("Last Week");
                break;
            case "This Month":
                const currentMonth = new Date();
                const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
                const lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
                let fromThisMonth = new Date(firstDayOfMonth.setHours(0, 0, 0, 0));
                let toThisMonth = new Date(lastDayOfMonth.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromThisMonth));
                setTo(formatDateToISOString(toThisMonth));
                setFromDateDisplay(firstDayOfMonth.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfMonth.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("This Month");
                break;
            case "Next Month":
                const nextMonth = new Date();
                const firstDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1);
                const lastDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 2, 0);
                let fromNextMonth = new Date(firstDayOfNextMonth.setHours(0, 0, 0, 0));
                let toNextMonth = new Date(lastDayOfNextMonth.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromNextMonth));
                setTo(formatDateToISOString(toNextMonth));
                setFromDateDisplay(firstDayOfNextMonth.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfNextMonth.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("Next Month");
                break;
            case "Last Month":
                const lastMonth = new Date();
                const firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
                const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 0);
                let fromLastMonth = new Date(firstDayOfLastMonth.setHours(0, 0, 0, 0));
                let toLastMonth = new Date(lastDayOfLastMonth.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromLastMonth));
                setTo(formatDateToISOString(toLastMonth));
                setFromDateDisplay(firstDayOfLastMonth.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfLastMonth.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("Last Month");
                break;
            case "This Year":
                const currentYear = new Date();
                const firstDayOfYear = new Date(currentYear.getFullYear(), 0, 1);
                const lastDayOfYear = new Date(currentYear.getFullYear(), 11, 31);
                let fromThisYear = new Date(firstDayOfYear.setHours(0, 0, 0, 0));
                let toThisYear = new Date(lastDayOfYear.setHours(23, 59, 59, 999));
                setFrom(formatDateToISOString(fromThisYear));
                setTo(formatDateToISOString(toThisYear));
                setFromDateDisplay(firstDayOfYear.toLocaleDateString('en-CA'));
                setToDateDisplay(lastDayOfYear.toLocaleDateString('en-CA'));
                if (setQuickDateValue)
                    setQuickDateValue("This Year");
                break;
            case "All Time":
                setFrom("");
                setTo("");
                setFromDateDisplay("");
                setToDateDisplay("");
                if (setQuickDateValue)
                    setQuickDateValue("All Time");
                break;
            default:
                // setFromDateDisplay(from?from:"");
                // setToDateDisplay(to?to:"");
                break;
        }


    }

    useEffect(
        () => {
            setQuickDate(selectQuickDate || "All Time");
            handleChange({ target: { value: selectQuickDate || "All Time" } })
        }, [reset, selectQuickDate]
    )

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {

        }
    }, [fromDateDisplay, toDateDisplay])

    return (
        <>
            <div className='selector_con selector_con_border'>
                <label>{label ? label : "Quick Date"}</label>
                <select value={quickDate} className={`search_input selector ${className}`} onChange={(e) => {
                    setQuickDate(e.target.value);
                    if (setQuickDateValue) {
                        setQuickDateValue(e.target.value);
                    }
                    handleChange(e);
                }} style={style} >
                    {/* <option value="">{placeholder}</option> */}
                    <option value="All Time">All Time</option>
                    {
                        future ? <>
                            <option value="Today">Today</option>
                            <option value="Tomorrow">Tomorrow</option>
                            <option value="This Week">This Week</option>
                            <option value="Next Week">Next Week</option>
                            <option value="This Month">This Month</option>
                            <option value="Next Month">Next Month</option>
                            <option value="This Year">This Year</option>
                        </>
                            :
                            <>
                                <option value="Today">Today</option>
                                <option value="Yesterday">Yesterday</option>
                                <option value="This Week">This Week</option>
                                <option value="Last Week">Last Week</option>
                                <option value="This Month">This Month</option>
                                <option value="Last Month">Last Month</option>
                                <option value="This Year">This Year</option>
                            </>
                    }
                    <option value={"Custom"}>Custom</option>
                </select>
            </div>

            <div className='date_range' >
                <label>Date Range</label>
                <div className='quick_date'>
                    <input type='date' onChange={(e) => {
                        setFromDateDisplay(e.target.value)
                        if (e.target.value === "") {
                            setFrom("");
                            return;
                        }
                        let date = new Date();
                       
                        date = new Date(date.setMonth(parseInt(e.target.value.split("-")[1]) - 1));
                        date = new Date(date.setFullYear(parseInt(e.target.value.split("-")[0])));
                        date = new Date(date.setDate(parseInt(e.target.value.split("-")[2])));
                        date.setHours(0, 0, 0, 0);
                        setFrom(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                        if (setQuickDateValue)
                        {
                            setQuickDateValue("Custom");
                        }
                    }} value={fromDateDisplay} />
                    <p>To</p>
                    <input type='date' onChange={(e) => {
                        setToDateDisplay(e.target.value)
                        if (e.target.value === "") {
                            setTo("");
                            return;
                        }
                        let date = new Date();
                        date = new Date(date.setMonth(parseInt(e.target.value.split("-")[1]) - 1));
                        date = new Date(date.setFullYear(parseInt(e.target.value.split("-")[0])));
                        date = new Date(date.setDate(parseInt(e.target.value.split("-")[2])));
                        date.setHours(23, 59, 59, 999);
                        setTo(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                        if (setQuickDateValue)
                        {
                            setQuickDateValue("Custom");
                        }
                    }} value={toDateDisplay} />
                </div>
            </div>
        </>
    )
}

export default Index
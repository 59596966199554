import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import CreateCustomerReq from "../../../../Requests/Customer/CreateCustomerReq";
import Swal from "sweetalert2";
import OfferApi from "../../../../API/OfferApi";
import logo from "./../../Offer/components/logo.png";
import authorized from "./../../Offer/components/authorized.png";
import LightAcceptOfferReq from "../../../../Requests/Customer/LightAcceptOfferReq";
import { IoMdCloseCircle } from "react-icons/io";
import authorizedFastwyre from "./../../Offer/components/authorizedFastwyre.png";
import { useNavigate, useParams } from "react-router-dom";
import CreateLightCustomerReq from "../../../../Requests/Customer/CreateLightCustomerReq";
import CustomerApi from "../../../../API/CustomerApi";
const LightAcceptOfferForm = ({
  color,
  lead,
  offer,
  setOpen,
  setReload,
  fiberPlan,
  isSharedOffer,
  setOpenRequestCall,
}) => {
  const [lightFirstName, setLightFirstName] = useState(
    lead?.name?.split(" ")[0]
  );
  const [lightLastName, setLightLastName] = useState(
    lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ")[1] : ""
  );
  const [lightEmail, setLightEmail] = useState(lead?.email);
  const [lightPhone, setLightPhone] = useState(lead?.phone);
  const [lightConsent, setLightConsent] = useState(true);
  const [address, setAddress] = useState("");
  const { leadId } = useParams();

  const navigate = useNavigate();

  const createLightCustomer = async () => {
    // console.log(offer)
    // console.log(fiberPlan)
    const s = Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Processing your Request",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: "custom-swal",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (!lightFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "First Name is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightPhone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightEmail) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (lightPhone.length < 11) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is invalid",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!offer.fiberCompanyId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Company is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!fiberPlan) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Plan is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightConsent) {
      Swal.fire({
        icon: "error",
        title: "Please agree to the terms and conditions",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (
      (offer.referredByOfferId ||
        offer.offerType === "BusinessCard" ||
        window.location.href.includes("digital-business-center")) &&
      !address
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Address is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    var req = new CreateLightCustomerReq();
    if (window.location.href.includes("digital-business-center")) {
      req.signedUpBy = "BizCardOffer";
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      req.timezone = timeZone;
    } else {
      req.signedUpBy = "SalesRep";
      req.leadId = lead?.id;
    }

    req.firstName = lightFirstName;
    req.lastName = lightLastName;
    req.email = lightEmail;
    req.phoneNumber = lightPhone;
    req.address = address;
    req.salesRepId = offer?.salesRepId;
    req.companyId = offer?.fiberCompanyId;
    req.planId = fiberPlan.id;
    req.offerId = offer?.id;

    let res;
    if (window.location.href.includes("digital-business-center")) {
      res = await CustomerApi.AcceptBizCardOffer(req);
    } else if (window.location.href.includes("preview-offer") && leadId) {
      res = await CustomerApi.CreateLightCustomer(req);
    } else if (window.location.href.includes("preview-offer")) {
      res = await CustomerApi.AcceptBizCardOffer(req);
    } else {
      res = await OfferApi.LightAcceptOffer(req);
    }
    if (res?.status?.success) {
      // const opt = await Swal.fire({
      //     icon: "success",
      //     title: "Success",
      //     text: "A Rep will contact you shortly.\nWould you like to fill the Order Form Now Or Wait for the Rep to contact you?",
      //     showConfirmButton: true,
      //     showCancelButton: true,
      //     confirmButtonText: "Fill Order Form Now",
      //     cancelButtonText: "Wait For the Rep",
      //     allowOutsideClick: false,
      //     allowEscapeKey: false,
      //     allowEnterKey: false,
      //     customClass: {
      //         container: 'custom-swal'
      //     },
      //     willClose: () => {
      //         Swal.hideLoading()
      //     }
      // })
      // close the Swal
      Swal.close(s);

      if (window.location.href.includes("preview-offer") && leadId) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer Created Successfully",
          customClass: {
            container: "custom-swal",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        setOpen(false);
        return;
      }

      if (
        window.location.href.includes("preview-offer") ||
        window.location.href.includes("digital-business-center")
      ) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Offer Accepted Successfully",
          customClass: {
            container: "custom-swal",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        setOpen(false);
        return;
      }

      // if (opt.isConfirmed) {
      if (offer.fiberCompanyId === 9) {
        navigate(`/fastwyre-form/${offer.offerGuid}`);
      } else if (offer.fiberCompanyId === 2) {
        navigate(
          `/lightcurve-form/${offer.offerGuid ?? res.data?.offer?.offerGuid}`
        );
      } else if (offer.fiberCompanyId === 10 || offer.fiberCompanyId === 11) {
        // Swal.fire({
        //     icon: "success",
        //     title: "Success",
        //     text: "A Rep will contact you shortly",
        //     customClass: {
        //         container: 'custom-swal'
        //     },
        //     didOpen: () => {
        //         Swal.hideLoading()
        //     }
        // })
        // setOpen(false);
        navigate(`/pointbroadband-form/${offer.offerGuid}`);
      }
      // else if (offer.fiberCompanyId === 12) {
      //   navigate(`/wyyerd-form/${offer.offerGuid}`);
      // }
      // else if (offer.fiberCompanyId === 13) {
      //   navigate(`/astound-form/${offer.offerGuid}`);
      // }
      else {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "A Rep will contact you shortly",
          customClass: {
            container: "custom-swal",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        setOpen(false);
      }
      // }

      // setReload(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          container: "custom-swal",
        },
        text: res?.status?.message,
      });
    }
  };

  return (
    <div className="custom_modal">
      <div style={{ width: "100%" }}>
        <IoMdCloseCircle
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => {
            setOpen(false);
          }}
          size={30}
          color={color}
        />
      </div>
      {offer?.fiberCompanyId === 9 && (
        <img alt="Logo" src={authorizedFastwyre} className="logo" />
      )}
      {offer?.fiberCompanyId === 2 && (
        <img alt="Logo" src={authorized} className="logo" />
      )}
      {(offer?.fiberCompanyId === 10 || offer?.fiberCompanyId === 11) && (
        <img
          src="/images/offer/pointbroadband/logo.png"
          alt="Logo"
          className="logo"
        />
      )}
      {offer?.fiberCompanyId === 12 && (
        <img src="/images/offer/wyyerd/logo.png" alt="Logo" className="logo" />
      )}
      {offer?.fiberCompanyId === 13 && (
        <img src="/images/offer/astound/logo.png" alt="Logo" className="logo" />
      )}
      <div className="inputs">
        <input
          placeholder="*First Name"
          value={lightFirstName}
          onChange={(e) => {
            setLightFirstName(e.target.value);
          }}
        />
        <input
          placeholder="Last Name"
          value={lightLastName}
          onChange={(e) => {
            setLightLastName(e.target.value);
          }}
        />
        <input
          placeholder="*Email"
          value={lightEmail}
          onChange={(e) => {
            setLightEmail(e.target.value);
          }}
        />
        {(offer.referredByOfferId ||
          offer.offerType === "BusinessCard" ||
          window.location.href.includes("digital-business-center") ||
          (!leadId && window.location.href.includes("preview-offer"))) && (
          <input
            placeholder="*Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        )}
        <PhoneInput
          placeholder="* Cellphone"
          country={"us"}
          containerStyle={{
            width: "100%",
            borderRadius: "10px",
          }}
          inputClass="custom_input"
          value={lightPhone}
          onChange={(e) => {
            setLightPhone(e);
          }}
        />
        <div className="consent">
          <input
            type="checkbox"
            checked={lightConsent}
            onChange={(e) => {
              setLightConsent(e.target.checked);
            }}
          />
          <label>
            I agree to receive email and/or sms messages from Lightning OS LLC.
          </label>
        </div>
        <div className="border-2 flex flex-col gap-2 border-gray-300 p-2 rounded-lg">
          <p className="text-lg font-bold text-center ">
            {offer?.fiberCompanyId === 13
              ? "NEW CUSTOMER FORM"
              : "ORDER FREE INSTALL"}
          </p>
          <button
            className="text-sm mt-0"
            style={{ backgroundColor: color, marginTop: "0rem" }}
            onClick={() => {
              createLightCustomer();
            }}
          >
            {offer?.fiberCompanyId === 12 || offer?.fiberCompanyId === 13
              ? "Submit"
              : "Fill Out Additional Info"}
          </button>
          <button
            className="text-sm"
            onClick={() => {
              setOpenRequestCall(true);
              setOpen(false);
            }}
            style={{ marginTop: "0rem" }}
          >
            Request a Call
          </button>
        </div>
      </div>
    </div>
  );
};

export default LightAcceptOfferForm;

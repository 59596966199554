import React, { useEffect, useState } from "react";
import SearchCustomerReq from "../../../../Requests/Customer/SearchCustomerReq";
import CustomerApi from "../../../../API/CustomerApi";
import Swal from "sweetalert2";
import CancelationFormReq from "../../../../Requests/Customer/CancelFormReq";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import authorized from "../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png";
import authorizedFastwyre from "../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png";

const CustomerCancelForm = ({ selectedContacts }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [subAnswer, setSubAnswer] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [routePhone, setRoutePhone] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(null);

  const getCustomer = async () => {
    let req = new SearchCustomerReq();
    req.cellPhone = routePhone;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1;
    let res = await CustomerApi.SearchFidiumCustomers(req);
    if (res?.status?.success) {
      setCustomer(res.data.list[0]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const phoneFromUrl = url.split("/").pop();
    setRoutePhone(phoneFromUrl);
  }, []);

  useEffect(() => {
    if (routePhone) {
      getCustomer();
    }
  }, [routePhone]);

  const handleMainOptionChange = (option) => {
    setSelectedOption(option);
    setSubAnswer("");
  };

  const resetForm = () => {
    setSelectedOption(null);
    setPhoneNumber("");
    setSubAnswer("");
  };

  const showEnterAnswerSwal = () => {
    Swal.fire({
      icon: "error",
      title: "Please enter an answer",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.hideLoading();
      },
    });
  };

  

  const showSelectOptions = () => {
    Swal.fire({
      icon: "error",
      title: "Please select an option",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.hideLoading();
      },
    });
  };

  const handleSubmit = async () => {
    if (selectedContacts.length > 1) {
      Swal.fire({
        icon: "error",
        title: "Please select only one contact",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if(!selectedContacts[0]?.fidiumCustomer){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Customer not found",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return
    }

    if (PhoneNumber === "" && routePhone === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a phone number",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    if (selectedOption === null) {
      showSelectOptions();
      return;
    }
    let req = new CancelationFormReq();
    if (routePhone) {
      if (routePhone.includes("+")) {
        req.phoneNumber = routePhone;
      } else {
        req.phoneNumber = "+" + routePhone;
      }
    }
    if (PhoneNumber) {
      if (PhoneNumber.includes("+")) {
        req.phoneNumber = PhoneNumber;
      } else {
        req.phoneNumber = "+" + PhoneNumber;
      }
    }

    switch (selectedOption) {
      case "PayLess":
        req.payLess = true;
        if (subAnswer === "") {
          showEnterAnswerSwal();
          return;
        }
        req.payLessSubAnswer = subAnswer;
        break;
      case "BundledService":
        req.bundledService = true;
        if (subAnswer === "") {
          showEnterAnswerSwal();
          return;
        }
        req.bundledServiceSubAnswer = subAnswer;
        break;
      case "InContract":
        req.inContract = true;
        if (subAnswer === "") {
          showEnterAnswerSwal();
          return;
        }
        req.inContractSubAnswer = subAnswer;
        break;
      case "OtherReason":
        req.other = true;
        if (subAnswer === "") {
          showEnterAnswerSwal();
          return;
        }
        req.otherSubAnswer = subAnswer;
        break;
      case "TooBusy":
        req.tooBusy = true;
        break;
      case "MovingSoon":
        req.movingSoon = true;
        break;
      case "DecisionMaker":
        req.spouse = true;
        break;
      default:
        showSelectOptions();
        return;
    }
    setIsLoading(true);
    req.fidiumCustomerId = selectedContacts[0]?.fidiumCustomer?.id;
    let res = await CustomerApi.CancelForm(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Form Submitted",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      resetForm();
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      setIsLoading(false);
    }
  };

  const handlePreferNotToSay = () => {
    setSubAnswer("I prefer not to say");
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-sm">
      <h2 className="text-2xl font-semibold mb-6">Cancel Form</h2>

      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress size={50} sx={{ color: "var(--primary-color)" }} />
        </div>
      ) : (
        <div className="space-y-6">
          {customer?.fiberCompanyId === 2 && (
            <img alt="Logo" src={authorized} className="mb-4 w-32 h-auto" />
          )}
          {customer?.fiberCompanyId === 9 && (
            <img
              alt="Logo"
              src={authorizedFastwyre}
              className="mb-4 w-32 h-auto"
            />
          )}

          {!routePhone && (
            <div className="space-y-2">
              <label
                className="block text-sm font-medium text-gray-700 mb-2"
                htmlFor="phoneNumber"
              >
                Enter Phone Number
              </label>
              <PhoneInput
                country={"us"}
                value={PhoneNumber}
                onChange={setPhoneNumber}
                excludeCountries={["il"]}
                specialLabel="Phone Number"
                inputStyle={{ paddingLeft: "3rem", width: "100%" }}
              />
            </div>
          )}

          <h1 className="text-xl font-semibold mt-6 mb-4 text-left">
            What is your reason for canceling your Free Lightcurve Installation?
          </h1>
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="PayLess"
                id="payless"
                checked={selectedOption === "PayLess"}
                onChange={() => handleMainOptionChange("PayLess")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="payless"
                className="text-sm font-medium text-gray-700"
              >
                Paying Less w/ Another Internet Service Provider (ISP)
              </label>
            </div>
            {selectedOption === "PayLess" && (
              <div className="space-y-2">
                <label
                  htmlFor="ispdetails"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  It’s possible we can beat their price! Who is your ISP? How
                  many mbps is your plan? And finally, what is your monthly
                  cost?
                </label>
                <div className="flex items-center gap-2">
                  <textarea
                    value={subAnswer}
                    onChange={(e) => setSubAnswer(e.target.value)}
                    placeholder="Type your answers here"
                    className="flex-1 p-2 border rounded-md h-[3rem]"
                  />
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={handlePreferNotToSay}
                  >
                    I prefer not to say
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="BundledService"
                id="bundledservice"
                checked={selectedOption === "BundledService"}
                onChange={() => handleMainOptionChange("BundledService")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="bundledservice"
                className="text-sm font-medium text-gray-700"
              >
                Bundled Service w/ Another ISP
              </label>
            </div>
            {selectedOption === "BundledService" && (
              <div className="space-y-2">
                <label
                  htmlFor="bundledispdetails"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Lightcurve also has bundled options, and can most likely beat
                  their price! Who is your ISP? How many mbps is your plan? And
                  finally, what is your monthly cost?
                </label>
                <div className="flex items-center gap-2">
                  <textarea
                    value={subAnswer}
                    onChange={(e) => setSubAnswer(e.target.value)}
                    placeholder="Type your answers here"
                    className="flex-1 p-2 border rounded-md h-[3rem]"
                  />
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={handlePreferNotToSay}
                  >
                    I prefer not to say
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="InContract"
                id="incontract"
                checked={selectedOption === "InContract"}
                onChange={() => handleMainOptionChange("InContract")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="incontract"
                className="text-sm font-medium text-gray-700"
              >
                We’re in Contract w/ Another ISP.
              </label>
            </div>
            {selectedOption === "InContract" && (
              <div className="space-y-2">
                <label
                  htmlFor="contractdetails"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  We often assist with early termination fees! What ISP are you
                  in contract with? How many months are remaining on your
                  contract? Do you know the early termination fee? If yes, how
                  much to end your contract early?
                </label>
                <div className="flex items-center gap-2">
                  <textarea
                    value={subAnswer}
                    onChange={(e) => setSubAnswer(e.target.value)}
                    placeholder="Type your answers here"
                    className="flex-1 p-2 border rounded-md h-[3rem]"
                  />
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={handlePreferNotToSay}
                  >
                    I prefer not to say
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="DecisionMaker"
                id="decisionmaker"
                checked={selectedOption === "DecisionMaker"}
                onChange={() => handleMainOptionChange("DecisionMaker")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="decisionmaker"
                className="text-sm font-medium text-gray-700"
              >
                Spouse or Another Decision Maker Doesn’t Want to Switch
                Providers.
              </label>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="MovingSoon"
                id="movingsoon"
                checked={selectedOption === "MovingSoon"}
                onChange={() => handleMainOptionChange("MovingSoon")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="movingsoon"
                className="text-sm font-medium text-gray-700"
              >
                Moving Soon.
              </label>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="TooBusy"
                id="toobusy"
                checked={selectedOption === "TooBusy"}
                onChange={() => handleMainOptionChange("TooBusy")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="toobusy"
                className="text-sm font-medium text-gray-700"
              >
                Too Busy, Not Worth the Time.
              </label>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="OtherReason"
                id="otherreason"
                checked={selectedOption === "OtherReason"}
                onChange={() => handleMainOptionChange("OtherReason")}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="otherreason"
                className="text-sm font-medium text-gray-700"
              >
                Other Reason
              </label>
            </div>
            {selectedOption === "OtherReason" && (
              <div className="space-y-2">
                <label
                  htmlFor="otherdetails"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Please specify your reason(s):
                </label>
                <div className="flex items-center gap-2">
                  <textarea
                    value={subAnswer}
                    onChange={(e) => setSubAnswer(e.target.value)}
                    placeholder="Type your reason(s) here"
                    className="flex-1 p-2 border rounded-md h-[3rem]"
                  />
                  <button
                    className="bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={handlePreferNotToSay}
                  >
                    I prefer not to say
                  </button>
                </div>
              </div>
            )}
          </div>

          <button
            className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomerCancelForm;

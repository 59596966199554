import React, { useMemo, useState } from "react";


const TagsState = ({ tags, leadOrCandidateIds, onDisassociate, label }) => {
  const isSingleLead = useMemo(
    () => leadOrCandidateIds.length === 1,
    [leadOrCandidateIds]
  );
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  const filteredTags =
    tags?.filter((tag) =>
      tag.tagEntities?.some((te) =>
        leadOrCandidateIds.some((id) => id === te.userId)
      )
    ) || [];

  // handleSelectAll
  const handleSelectAll = () => {
    const allTagIds = filteredTags.map((tag) => tag.id);
    setSelectedTagIds(allTagIds);
  };

  // handleDeselectAll
  const handleDeselectAll = () => {
    setSelectedTagIds([]);
  };

  console.log({ leadOrCandidateIds });

  const toggleTag = (tagId) => {
    let newSelection;
    if (selectedTagIds.includes(tagId)) {
      newSelection = selectedTagIds.filter((id) => id !== tagId);
    } else {
      newSelection = [...selectedTagIds, tagId];
    }
    setSelectedTagIds(newSelection);
  };

  return (
    <div className="mb-5 border-t mt-6">
      <h1 className="lead_owners_title">Tags Associated with Selected Leads</h1>
      {/* <div className="font-bold mb-3 mt-3">
        Tags Associated with Selected Leads
      </div> */}

      {filteredTags.length > 0 && (
        <div className="select_deselect mb-2 flex gap-2 font-semibold">
          <button
            className=" py-1 rounded underline text-[#868686]"
            onClick={handleSelectAll}
          >
            Select All
          </button>
          <div className="px- text-[#868686]">|</div>
          <button
            className=" py-1 rounded underline text-[#868686]"
            onClick={handleDeselectAll}
          >
            Deselect All
          </button>
          {onDisassociate && (
            <button
            className={`ml-4 p-1 rounded ${
              selectedTagIds.length === 0
                ? 'bg-slate-300 text-slate-500 cursor-not-allowed'
                : 'bg-slate-700 text-white hover:bg-slate-600'
            }`}
            onClick={() => onDisassociate(selectedTagIds)}
            disabled={selectedTagIds.length === 0}
          >
            Dissociate
          </button>
          )}
        </div>
      )}

      <div className="flex flex-wrap gap-3">
        {filteredTags.length > 0 ? (
          filteredTags.map((tag) => {
            const leadCount = leadOrCandidateIds.filter((id) =>
              tag.tagEntities.some((te) => te.userId === id)
            ).length;
            const isSelected = selectedTagIds.includes(tag.id);
            return (
              <div
                key={tag.id}
                onClick={() => toggleTag(tag.id)}
                className={`relative border-[2px] rounded-md min-w-[80px] h-[110px] flex flex-col items-center justify-center p-2 cursor-pointer ${
                  isSelected ? "border-blue-500" : "border-gray-300"
                }`}
              >
                {/* Dissociate icon */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDisassociate([tag.id]);
                  }}
                  className="absolute top-1 right-1 p-1 hover:bg-slate-100 rounded-full text-red-500"
                  title="Dissociate tag"
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth={1.5} 
                    stroke="currentColor" 
                    className="h-4 w-4"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      d="M6 18L18 6M6 6l12 12" 
                    />
                  </svg>
                </button>
                
                <div className="text-center">
                  <span className="font-bold">#{tag.name}</span>
                </div>
                {!isSingleLead && (
                  <div className="mt-2">
                    <span>
                      {leadCount} {label}
                    </span>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-gray-500">
            No tags associated with selected leads.
          </p>
        )}
      </div>
    </div>
  );
};

export default TagsState;
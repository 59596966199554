import React from 'react'
import PublicLayout from '../../../Layouts/PublicLayout'
import { useParams } from 'react-router-dom'
import { Box, Card, CardContent, Typography } from '@mui/material'
import StringIntReq from '../../../Requests/StringIntReq'
import CustomerFeedbackApi from '../../../API/CustomerFeedbackApi'
import VersatileLoader from '../../../components/VersatileLoader'
import Swal from 'sweetalert2'
const Index = () => {

  const { id } = useParams()
  const [customerFeedback, setCustomerFeedback] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [rewardClaimed, setRewardClaimed] = React.useState(false)

  const fetchFeedback = async () => {
    setLoading(true);
    const req = new StringIntReq();
    req.string = id;


    const res = await CustomerFeedbackApi.GetCustomerFeedbackByGuid(req);
    if (res.status.success) {
      setCustomerFeedback(res.data);
      setLoading(false);
    }
    else {
      console.error(res.status.message);
      setErrorMessage(res.status.message);
      setLoading(false);
    }
  }
  const claimReward = async () => {

    Swal.fire({
      icon: "info",
      title: "Claiming Reward",
      text: "Please wait while we claim your reward",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })
    const req = new StringIntReq();
    req.int = customerFeedback.id;
    const res = await CustomerFeedbackApi.ClaimFeedbackReward(req);
    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Reward Claimed",
        text: "You have successfully claimed your reward",
        showConfirmButton: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        showCancelButton: false,
        didOpen: () => {
          Swal.hideLoading();
        }
      })
      setRewardClaimed(true);
    }
    else {
      console.error(res.status.message);
    }
  }
  React.useEffect(() => {
    fetchFeedback();
  }, [])
  return (
    <PublicLayout>
      <Box className='thank_you_container'>
        {
          loading ?
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              <VersatileLoader size='large' />
            </Typography>
            :
            <Card>
              {
                customerFeedback ?
                  rewardClaimed ?
                    <CardContent>
                      <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Thank you for your Feedback!
                      </Typography>
                      <Typography variant="body1" align="center">
                        You have already claimed your reward.
                      </Typography>
                    </CardContent>
                    :
                    <CardContent>
                      <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Thank you for your Feedback!
                      </Typography>
                      <Typography variant="body1" align="center">
                        You can claim your reward by clicking the button below.
                      </Typography>
                      <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'> Claim Reward </button>
                    </CardContent>
                  :
                  <CardContent>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                      Something went wrong!
                    </Typography>
                    <Typography variant="body1" align="center">
                      {errorMessage}
                    </Typography>
                  </CardContent>
              }
            </Card>
        }
      </Box>
    </PublicLayout>)
}

export default Index
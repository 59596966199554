import React, { useEffect, useState } from 'react';
import './CancelForm.scss';
import PhoneInput from 'react-phone-input-2';
import CancelationFormReq from '../../../Requests/Customer/CancelFormReq';
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';

import authorized from '../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png';
import authorizedFastwyre from '../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png';
import { CircularProgress } from '@mui/material';
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq';

const CustomerCancelForm = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [subAnswer, setSubAnswer] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [routePhone, setRoutePhone] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState(null);

    const getCustomer = async () => {
        let req = new SearchCustomerReq();
        req.cellPhone = routePhone;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        let res = await CustomerApi.SearchFidiumCustomers(req);
        if (res?.status?.success) {
            setCustomer(res.data.list[0]);
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const url = window.location.href;
        const phoneFromUrl = url.split("/").pop();
        setRoutePhone(phoneFromUrl);
        // setIsLoading(false);
    }, []);

    useEffect(() => {
        if (routePhone) {
            getCustomer();
        }
    }, [routePhone]);


    const handleMainOptionChange = (option) => {
        setSelectedOption(option);
        setSubAnswer("");
    };

    const resetForm = () => {
        setSelectedOption(null);
        setPhoneNumber("");
        setSubAnswer("");
    };

    const showEnterAnswerSwal = () => {
        Swal.fire({
            icon: "error",
            title: "Please enter an answer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    };

    const showSelectOptions = () => {
        Swal.fire({
            icon: "error",
            title: "Please select an option",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    };

    const handleSubmit = async () => {

        if (PhoneNumber === "" && routePhone === "") {
            Swal.fire({
                icon: "error",
                title: "Please enter a phone number",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }
        if (selectedOption === null) {
            showSelectOptions();
            return;
        }
        let req = new CancelationFormReq();
        if (routePhone) {
            if (routePhone.includes("+")) {
                req.phoneNumber = routePhone;
            } else {
                req.phoneNumber = "+" + routePhone;
            }
        }
        if (PhoneNumber) {
            if (PhoneNumber.includes("+")) {
                req.phoneNumber = PhoneNumber;
            } else {
                req.phoneNumber = "+" + PhoneNumber;
            }
        }


        switch (selectedOption) {
            case 'PayLess':
                req.payLess = true;
                if (subAnswer === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.payLessSubAnswer = subAnswer;
                break;
            case 'BundledService':
                req.bundledService = true;
                if (subAnswer === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.bundledServiceSubAnswer = subAnswer;
                break;
            case 'InContract':
                req.inContract = true;
                if (subAnswer === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.inContractSubAnswer = subAnswer;
                break;
            case 'OtherReason':
                req.other = true;
                if (subAnswer === "") {
                    showEnterAnswerSwal();
                    return;
                }
                req.otherSubAnswer = subAnswer;
                break;
            case 'TooBusy':
                req.tooBusy = true;
                break;
            case 'MovingSoon':
                req.movingSoon = true;
                break;
            case 'DecisionMaker':
                req.spouse = true;
                break;
            default:
                showSelectOptions();
                return;
        }
        setIsLoading(true);
        let res = await CustomerApi.CancelForm(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Form Submitted",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            resetForm();
            setIsLoading(false);
        } else {
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            setIsLoading(false);
        }
    };

    const handlePreferNotToSay = () => {
        setSubAnswer("I prefer not to say");
    };

    const getCompanyName = () => {
        if (customer?.fiberCompanyId === 2) {
            return "LightCUrve"
        }
        if (customer?.fiberCompanyId === 9) {
            return "Fastwyre"
        }
        if (customer?.fiberCompanyId === 10) {
            return "Point Broadband"
        }

        return "LightningOS"
    }

    return (
        <div style={{
            // height: "100vh",'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: "100%",
            height: "100vh"
        }}>
            {isLoading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "100%"
                }}>
                    <CircularProgress size={50} sx={{
                        color: "var(--primary-color)"
                    }} />
                </div>
                :
                <div className='cancel_form_container'>

                    {
                        customer?.fiberCompanyId === 2 &&
                        <img alt="Logo" style={{ marginBottom: "1rem" }} src={authorized} className='logo' />
                    }
                    {
                        customer?.fiberCompanyId === 9 &&
                        <img alt="Logo" style={{ marginBottom: "1rem" }} src={authorizedFastwyre} className='logo' />
                    }
                    {
                        customer?.fiberCompanyId === 10 &&
                        <img src='/images/offer/pointbroadband/logo.png' alt='Authorized Point Broadband' />
                    }

                    <div className='inputs'>
                        {!routePhone && (
                            <>
                                <label className='phoneLabel' htmlFor="phoneNumber">Enter Phone Number</label>
                                <PhoneInput
                                    country={'us'}
                                    value={PhoneNumber}
                                    onChange={setPhoneNumber}
                                    excludeCountries={['il']}
                                    style={{ width: "100%" }}
                                    specialLabel='Phone Number'
                                    inputStyle={{ paddingLeft: "3rem", width: "100%" }}
                                />
                            </>
                        )
                        }

                        <h1 className='subtitle'>What is your reason for canceling your Free {getCompanyName()} Installation?</h1>
                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="PayLess"
                                id="payless"
                                checked={selectedOption === 'PayLess'}
                                onChange={() => handleMainOptionChange('PayLess')}
                            />
                            <label htmlFor="payless">Paying Less w/ Another Internet Service Provider (ISP)</label>
                        </div>
                        {selectedOption === 'PayLess' && (
                            <>
                                <div className='subInput'>
                                    <label htmlFor="ispdetails">It’s possible we can beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" />
                                        <button className='preferBtn' onClick={handlePreferNotToSay}>I prefer not to say</button>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="BundledService"
                                id="bundledservice"
                                checked={selectedOption === 'BundledService'}
                                onChange={() => handleMainOptionChange('BundledService')}
                            />
                            <label htmlFor="bundledservice">Bundled Service w/ Another ISP</label>
                        </div>
                        {selectedOption === 'BundledService' && (
                            <>
                                <div className='subInput'>
                                    <label htmlFor="bundledispdetails">{getCompanyName()} also has bundled options, and can most likely beat their price! Who is your ISP? How many mbps is your plan? And finally, what is your monthly cost?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" />
                                        <button className='preferBtn' onClick={handlePreferNotToSay}>I prefer not to say</button>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="InContract"
                                id="incontract"
                                checked={selectedOption === 'InContract'}
                                onChange={() => handleMainOptionChange('InContract')}
                            />
                            <label htmlFor="incontract">We’re in Contract w/ Another ISP.</label>
                        </div>
                        {selectedOption === 'InContract' && (
                            <>
                                <div className='subInput'>
                                    <label htmlFor="contractdetails">We often assist with early termination fees! What ISP are you in contract with? How many months are remaining on your contract? Do you know the early termination fee? If yes, how much to end your contract early?</label>
                                    <div className='inputWrapper'>
                                        <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your answers here" />
                                        <button className='preferBtn' onClick={handlePreferNotToSay}>I prefer not to say</button>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="DecisionMaker"
                                id="decisionmaker"
                                checked={selectedOption === 'DecisionMaker'}
                                onChange={() => handleMainOptionChange('DecisionMaker')}
                            />
                            <label htmlFor="decisionmaker">Spouse or Another Decision Maker Doesn’t Want to Switch Providers.</label>
                        </div>

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="MovingSoon"
                                id="movingsoon"
                                checked={selectedOption === 'MovingSoon'}
                                onChange={() => handleMainOptionChange('MovingSoon')}
                            />
                            <label htmlFor="movingsoon">Moving Soon.</label>
                        </div>

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="TooBusy"
                                id="toobusy"
                                checked={selectedOption === 'TooBusy'}
                                onChange={() => handleMainOptionChange('TooBusy')}
                            />
                            <label htmlFor="toobusy">Too Busy, Not Worth the Time.</label>
                        </div>

                        <div className='inputDiv'>
                            <input
                                type="checkbox"
                                name="OtherReason"
                                id="otherreason"
                                checked={selectedOption === 'OtherReason'}
                                onChange={() => handleMainOptionChange('OtherReason')}
                            />
                            <label htmlFor="otherreason">OTHER REASON</label>
                        </div>
                        {selectedOption === 'OtherReason' && (
                            <div className='subSubInput'>
                                <label htmlFor="otherdetails">Please specify your reason(s):</label>
                                <div className='inputWrapper'>
                                    <input type="text" value={subAnswer} onChange={(e) => setSubAnswer(e.target.value)} placeholder="Type your reason(s) here" />
                                    <button className='preferBtn' onClick={handlePreferNotToSay}>I prefer not to say</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <button style={{ backgroundColor: "#2761D0" }} className='submitBtn' onClick={handleSubmit}>Submit</button>
                </div>
            }
        </div>
    );
};

export default CustomerCancelForm;

import { Suspense, useEffect, useState } from 'react'
import CustomFieldsList from './components/CustomFieldList'
import CreateCustomFieldButton from './components/CreateCustomFieldButton'
import Layout from '../../../Layouts/Layout'
import CustomerApi from '../../../API/CustomerApi'
import Swal from 'sweetalert2'
export default function Index() {

    const [forFilter, setForFilter] = useState('')
    const [name, setName] = useState('')
    const [searchType, setSearchType] = useState('')
    const [customAttributes, setCustomAttributes] = useState([])
    const handleFilterChange = () => {
        console.log(forFilter, searchType)
    }


    const getCustomAttributes = async () => {
        const req = {
            for: forFilter,
            name: name,
            dataType: searchType
        }

        const res = await CustomerApi.SearchCustomAttributes(req)

        if (res.status.success) {
            setCustomAttributes(res.data)
        }

    }


    useEffect(() => {
        const timer = setTimeout(() => {
            getCustomAttributes()
          }, 2000);
          return () => clearTimeout(timer);
        
    }, [forFilter, name, searchType])

    return (
        <Layout>
            <div className="min-h-screen bg-gray-50 py-6 sm:py-10 px-4 sm:px-6 lg:px-8">
                <div className="max-w-[90vw] sm:max-w-full mx-auto">
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="p-4 sm:p-6 md:p-8">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8">
                                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-0">Custom Fields</h1>
                                <CreateCustomFieldButton />
                            </div>
                            <div className="space-y-4">
                                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                    <div className="w-full sm:w-1/3">
                                        <label htmlFor="forFilter" className="block text-sm font-medium text-gray-700 mb-1">Filter by Type</label>
                                        <select
                                            id="forFilter"
                                            value={forFilter}
                                            onChange={(e) => setForFilter(e.target.value)}
                                            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        >
                                            <option value="">All Types</option>
                                            <option value="Customer">Customer</option>
                                            <option value="Lead">Lead</option>
                                            <option value="Order">Order</option>
                                        </select>
                                    </div>
                                    <div className="flex-1 space-y-4 sm:space-y-0 sm:flex sm:space-x-2">
                                        <div className="flex-1">
                                            <label htmlFor="searchFilter" className="block text-sm font-medium text-gray-700 mb-1">Search by Name</label>
                                            <input
                                                id="searchFilter"
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Search..."
                                                className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="w-full sm:w-1/3">
                                            <label htmlFor="searchType" className="block text-sm font-medium text-gray-700 mb-1">Search by DataType</label>
                                            <select
                                                id="searchType"
                                                value={searchType}
                                                onChange={(e) => setSearchType(e.target.value)}
                                                className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            >
                                                <option value="">All Types</option>
                                                <option value="Number">Number</option>
                                                <option value="Text">Text</option>
                                                <option value="DateTime">Date Time</option>
                                                <option value="Decimal" >Decimal</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 mt-4 sm:space-y-8">
                                {/* <Suspense fallback={<div className="text-center py-4 text-gray-500">Loading custom fields...</div>}> */}
                                <CustomFieldsList customFields={customAttributes} setCustomFields={setCustomAttributes} />
                                {/* </Suspense> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


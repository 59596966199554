import React, { useContext, useMemo } from "react";
import greyUser from "../../../assets/images/userGrey.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserContext } from "../../../App";
import {
  MdOutlineDirections,
  MdOutlineMail,
  MdOutlineMessage,
  MdOutlinePhone,
  MdDocumentScanner,
} from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { IoMapOutline } from "react-icons/io5";
import ContactButtons from "../../../Services/ContactButtons";
import { IoMdBriefcase, IoMdPerson } from "react-icons/io";

const LeadRecruitCard = ({
  lead,
  index,
  setSelectedLeads,
  setSelectedLead,
  isSelected,
}) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const contactButtons = new ContactButtons(user);

  console.log({ lead });

  const getImage = (lead) => {
    // TODO: GET CANDIDATE IMAGE  WHEN AVAILABLE
    return <img src={greyUser} alt="User" />;
  };

  const getMapIcon = (lead) => {
    if (lead.mapIconId != null) {
      return (
        <img
          src={"data:image/png;base64," + lead.mapIcon.image}
          alt="Map Icon"
        />
      );
    } else {
      return null;
    }
  };

  const getName = (lead) => {
    return lead.name;
  };

  const getSalesRepName = (lead) => {
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.salesRep != null &&
      (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin")
    ) {
      return (
        " | " +
        lead.fidiumCustomer.salesRep.firstName +
        " " +
        lead.fidiumCustomer.salesRep.lastName[0]
      );
    } else if (lead?.offer?.salesRep) {
      return (
        " | " +
        lead.offer.salesRep.firstName +
        " " +
        lead.offer.salesRep.lastName[0]
      );
    }
  };

  const getAddress = (lead) => {
    if (lead.mapString) {
      return lead.mapString;
    } else if (lead.address) {
      return (
        lead.address?.street +
        " " +
        lead.address?.city +
        ", " +
        lead.address?.state +
        " " +
        lead.address?.zip +
        ", " +
        lead.address?.country
      );
    } else {
      return "No Address";
    }
  };

  const openMap = (lead) => {
    if (lead && lead.coordinates) {
      navigate("/map", {
        state: {
          candidate: {
            ...lead,
            leadId: lead.id,
            lead: {
              ...lead,
              candidate: null,
            },
          },
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "No Coordinates",
        text: "This lead does not have any coordinates",
      });
    }
  };

  const alreadyApplied = useMemo(
    () =>
      lead.status !== "New" &&
      lead.status !== "New Candidate" &&
      lead.status !== "Job Ad Sent" &&
      lead.status !== "Contacted",
    [lead.status]
  );

  return (
    <React.Fragment key={index}>
      <div key={index} className="LeadCard">
        <div className="ImagesDiv">
          <input
            className="select"
            onChange={(e) => {
              if (isSelected) {
                setSelectedLeads((prev) => prev.filter((l) => l !== lead.id));
              } else {
                setSelectedLeads((prev) => [...prev, lead.id]);
              }
            }}
            checked={isSelected}
            type="checkbox"
          />
          <div className="Image">{getImage(lead)}</div>
          <div className="MapIcon">{getMapIcon(lead)}</div>
        </div>
        <div className="DataDiv">
          <div
            className="Name"
            style={{ cursor: "pointer" }}
            onClick={() => {
              openMap(lead);
            }}
          >
            <h3>
              {getName(lead)}{" "}
              <span
                style={{
                  fontSize: "0.8rem",
                  color: "#2761D0",
                }}
              >
                {getSalesRepName(lead)}
              </span>
            </h3>
          </div>
          {lead.personalEmail && (
            <div className="Phone">
              <p>{lead.personalEmail}</p>
            </div>
          )}
          <div
            className="Address"
            style={{ cursor: "pointer" }}
            onClick={() => {
              openMap(lead);
            }}
          >
            <p title={getAddress(lead)}>{getAddress(lead)}</p>
          </div>
          {lead.companyName && (
            <div className="Phone">
              <p>
                {lead.companyName}
                {lead.companyEmail && <> | {lead.companyEmail}</>}
              </p>
            </div>
          )}
        </div>

        <div className="MainLeadCard_Buttons">
          {/* <div className="MainLeadCard_Button" onClick={() => { lightningMessage(lead) }}>
            <TbMessage2Bolt color='#81848A' size={30} />
        </div> */}

          {alreadyApplied && (
            <div
              className="MainLeadCard_Button"
              onClick={() => {
                navigate(
                  lead.leadType == "Recruit"
                    ? "/manage-job-applications"
                    : "/manage-company-consultations",
                  {
                    state: {
                      candidateId: lead.id,
                    },
                  }
                );
              }}
            >
              {lead.leadType === "Recruit" ? (
                <IoMdBriefcase color="#81848A" size={30} />
              ) : (
                <IoMdPerson color="#81848A" size={30} />
              )}
            </div>
          )}
          <div
            className="MainLeadCard_Button"
            onClick={() => {
              setSelectedLead(lead);
            }}
          >
            <MdDocumentScanner color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.emailLead(lead)}
          >
            <MdOutlineMail color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.whatsappLead(lead)}
          >
            <FaWhatsapp color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.smsLead(lead)}
          >
            <MdOutlineMessage color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.callLead(lead)}
          >
            <MdOutlinePhone color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.directionsLead(lead)}
          >
            <MdOutlineDirections color="#81848A" size={30} />
          </div>
          <div
            onClick={() => {
              openMap(lead);
            }}
            className="MainLeadCard_Button"
          >
            <IoMapOutline color="#81848A" size={30} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadRecruitCard;

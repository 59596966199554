import React, { useState } from 'react'
import Swal from "sweetalert2"
import SalesOrgApi from '../../../API/OrgApi'
import UsersApi from '../../../API/UsersApi'
import Sidebar from '../../../Layouts/Sidebar'
import AddMemberOrgReq from '../../../Requests/Organizations/AddMemberOrgReq'
import CreateUserReq from '../../../Requests/Users/CreateUserReq'
import OrganizationSelector from '../../../components/OrganizationSelector'
import "./index.scss"
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import PhoneInput from 'react-phone-input-2'
import FiberCompanySelector from '../../../components/FiberCompanySelector'
import 'react-phone-input-2/lib/style.css'
const Index = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [userType, setUserType] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [salesOrgId, setSalesOrgId] = useState(null);
  const [fiberCompanyId, setFiberCompanyId] = useState(null);
  const SignUp = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Creating User',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    //check if email empty
    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Email is required',
        text: 'Please enter email',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check email is valid using regex
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check password > 6 characters and confirm password matches
    if (!password || password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Password must be at least 6 characters',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if (password !== cpassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please enter password',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check user type
    if (!userType) {
      Swal.fire({
        icon: 'error',
        title: 'User Type is required',
        text: 'Please select user type',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check first name
    if (!firstName) {
      Swal.fire({
        icon: 'error',
        title: 'First Name is required',
        text: 'Please enter first name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check last name
    if (!lastName) {
      Swal.fire({
        icon: 'error',
        title: 'Last Name is required',
        text: 'Please enter last name',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check phone
    if (!phone) {
      Swal.fire({
        icon: 'error',
        title: 'Phone is required',
        text: 'Please enter phone number',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    if(phone.length < 11){
      Swal.fire({
        icon: 'error',
        title: 'Phone is invalid',
        text: 'Please enter a valid phone number',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
      return;
    }

    // check address
    // if (!address) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Address is required',
    //     text: 'Please enter address',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     showConfirmButton: true,
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   });
    //   return;
    // }

    if (userType === "SalesOrgAdmin" && !salesOrgId) {
      Swal.fire({
        icon: 'error',
        title: 'Sales Organization is required',
        text: 'Please select sales organization',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });

      return;
    }
    // create user
    let req = new CreateUserReq();
    req.email = email;
    req.password = password;
    req.firstName = firstName;
    req.lastName = lastName;
    req.phone = phone;
    req.address = address;
    req.userType = userType;
    req.salesOrgId = salesOrgId;
    req.fiberCompanyId = fiberCompanyId;
    let res = await UsersApi.CreateUser(req);

    

    if (res?.status?.success) {
      // if (userType !== "SalesOrgAdmin") {
      //   Swal.fire({
      //     icon: 'success',
      //     title: 'User Created',
      //     text: "user has been created",
      //     // allowOutsideClick: false,
      //     allowEscapeKey: false,
      //     allowEnterKey: false,
      //     showConfirmButton: false,

      //   });
      // }
      // if (userType === "SalesOrgAdmin") {
      //   const req = new AddMemberOrgReq();
      //   req.id1 = salesOrgId;
      //   req.id2 = res.data.id;

      //   SalesOrgApi.AddUsersToSalesOrg(req).then(
      //     (res) => {
      //       if (res?.status?.success) {
      //         Swal.fire({
      //           icon: 'success',
      //           title: 'User Created',
      //           text: "user has been created",
      //           // allowOutsideClick: false,
      //           allowEscapeKey: false,
      //           allowEnterKey: false,
      //           showConfirmButton: false,

      //         });
      //       }
      //     }
      //   )
      // }

      Swal.fire({
        icon: 'success',
        title: 'User Created',
        text: "user has been created",
        // allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,

      });
      setAddress("")
      setEmail("")
      setFirstName("")
      setLastName("")
      setcPassword("")
      setPhone("")
      setUserType("")
      setPassword("")
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }

  }
  return (
    <Layout>
      <div className='createUserContainer'>
        <div className='createUserForm' >
        <h1 >Create User</h1>
          <input   onChange={(e) => { setFirstName(e.target.value.slice(0, 32)) }} value={firstName.slice(0, 32)} type='text' placeholder='FIRST NAME' />
          <input  onChange={(e) => { setLastName(e.target.value.slice(0, 32)) }} value={lastName} type='text' placeholder='LAST NAME' />
          {/* <input  onChange={(e) => { setPhone(e.target.value.slice(0, 32)) }} value={phone} type='tel' placeholder='PHONE' /> */}
          <PhoneInput
            country={'us'}
            value={phone}
            onChange={phone => setPhone(phone)}
            excludeCountries={['il']}
            />
          <input  onChange={(e) => { setEmail(e.target.value)}} value={email} type="email" placeholder='EMAIL' />
          <input  onChange={(e) => { setAddress(e.target.value.slice(0, 64)) }} value={address} type="text" placeholder='ADDRESS' />
          <select  value={userType} onChange={(e) => { setUserType(e.target.value) }}>
            <option  value={""}>USER TYPE</option>
            <option  value={"SuperAdmin"}>Super Admin</option>
            <option  value={"Admin"}>Admin</option>
            <option value={"CompanyUser"} >Company User</option>
            <option  value={"SalesOrgAdmin"}>Team Admin</option>
            <option  value={"Customer"}>Customer</option>
            <option  value={"SalesRep"}>SalesRep</option>
            <option value={"CustomerServiceRep"}>Customer Service Rep</option>
          </select>
          <OrganizationSelector setUser={setSalesOrgId} disabled={!(userType === "SalesOrgAdmin" || userType==="SalesRep")} />
          <FiberCompanySelector className={'selector'} placeholder='Select a Fiber Company' setUser={setSalesOrgId} disabled={!(userType === "CompanyUser")} />
          <input  onChange={(e) => { setPassword(e.target.value.slice(0, 32)) }} value={password} type="password" placeholder='PASSWORD' />
          <input  onChange={(e) => { setcPassword(e.target.value.slice(0, 32)) }} value={cpassword} type="password" placeholder='CONFIRM PASSWORD' />

        <button onClick={() => SignUp()} >CREATE</button>
        </div>
      </div>
    </Layout>
  )
}

export default Index
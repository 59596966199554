import React, { useState, useRef, memo } from "react";

const CardDetailsModal = memo(({ isOpen, onClose, onSave }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [emailError, setEmailError] = useState(""); // Error state for email
  const [phoneError, setPhoneError] = useState(""); // Error state for phone
  const debounceRef = useRef(null);

  if (!isOpen) return null;

  // ✅ **Phone Number Validation (Only + and Numbers)**
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^[0-9+]*$/.test(value)) {
      setPhone(value);
      setPhoneError(""); // Clear error if valid
    } else {
      setPhoneError("Only numbers and + are allowed.");
    }
  };

  // ✅ **Email Validation (Proper Format)**
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Basic email validation regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailError("Enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleSave = () => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    // ✅ **Final Validation Before Saving**
    if (emailError || phoneError) return;

    debounceRef.current = setTimeout(() => {
      onSave({ email, phoneNumber: phone, address });
      onClose();
    }, 500);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-6 rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg shadow-xl relative max-h-[90vh] overflow-y-auto">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-red-600 transition"
        >
          ✕
        </button>
        {/* Logo */}
        <div className="flex justify-center">
          <img
            src="/images/nav/nav-logo.png"
            alt="Lightning OS"
            className="h-12"
          />
        </div>
        <h3 className="text-xl font-semibold text-center text-black mt-2">
          Update Card Details
        </h3>

        {/* Input Fields */}
        <div className="mt-4 space-y-3">
          {/* ✅ Email Input */}
          <div>
            <input
              type="email"
              className="w-full border border-gray-300 rounded-md p-2 mt-1 text-black focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter email"
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </div>

          {/* ✅ Phone Number Input */}
          <div>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md p-2 mt-1 text-black focus:ring-2 focus:ring-blue-500"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
            />
            {phoneError && (
              <p className="text-red-500 text-sm mt-1">{phoneError}</p>
            )}
          </div>

          {/* Address Input (No Validation) */}
          <input
            type="text"
            className="w-full border border-gray-300 rounded-md p-2 mt-1 text-black focus:ring-2 focus:ring-blue-500"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter address"
          />

          {/* Save Button */}
          <button
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
});

export default CardDetailsModal;

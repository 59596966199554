import React from 'react';

const DetailsStep = ({ 
  csvData, 
  headersExtracted, 
  mappedData, 
  mapping, 
  onConfirm, 
  onCancel 
}) => {

  if (!csvData || csvData.length === 0) {
    return <p>No data to review. Please upload and map a valid CSV file.</p>;
  }



  const mappedHeaders = Object.keys(mappedData || {}).filter(
    (key) => mappedData[key] !== '' // Only include mapped headers
  );

  // Calculate the number of mapped and unmapped columns
 
  const unmappedColumns = mapping.length - mappedHeaders.length;
  //const unmappedColumns = headersExtracted.length - mappedHeaders.length;    
  
  // Filter the CSV data to include only the mapped columns
    const filteredCsvData = csvData.map((row) =>
      mappedHeaders.reduce((acc, header) => {
        acc[header] = row[header];
        return acc;
      }, {})
    );

  return (
    <div className="details-step">
      <h2>Details / Final Confirmation</h2>
      <p>Review the summary of your selections before proceeding:</p>

      <ul>
        <li><strong>Total Columns in File:</strong> {headersExtracted.length}</li>
        <li><strong>Total Columns in Mapping:</strong> {mapping.length}</li>
        <li><strong>Mapped Columns:</strong> {mappedHeaders.length}</li>
        <li><strong>Unmapped Columns:</strong> {unmappedColumns}</li>
   
      </ul>

      <h3>Preview of Selected Mappings</h3>
      <table>
        <thead>
          <tr>
            {mappedHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredCsvData.slice(0, 5).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {mappedHeaders.map((header, colIndex) => (
               <td key={colIndex}>{row[header] || 'No data'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <p>Note: Only the first 5 rows are shown as a preview.</p>

      

      <div className="actions">
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default DetailsStep;





//////////////////////////////////////////////////////////
// import React from 'react';

// const DetailsStep = ({ csvData, headers, mappedHeaders, onConfirm, onCancel, advancedSettings }) => {
//   if (!csvData || csvData.length === 0) {
//     return <p>No data to review. Please upload and map a valid CSV file.</p>;
//   }

//   // Calculate mapped and unmapped columns
//   const mappedCount = Object.values(mappedHeaders).filter((value) => value).length;
//   const unmappedCount = headers.length - mappedCount;

//   return (
//     <div className="details-step">
//       <h2>Details / Final Confirmation</h2>
//       <p>Review your settings before proceeding with the import.</p>

//       {/* Summary of mappings */}
//       <div className="mapping-summary">
//         <h3>Mapping Summary</h3>
//         <p>
//           <strong>Total Columns:</strong> {headers.length}
//         </p>
//         <p>
//           <strong>Mapped Columns:</strong> {mappedCount}
//         </p>
//         <p>
//           <strong>Unmapped Columns:</strong> {unmappedCount}
//         </p>
//       </div>

//       {/* Advanced Settings */}
//       {advancedSettings && (
//         <div className="advanced-settings">
//           <h3>Advanced Settings</h3>
//           {Object.entries(advancedSettings).map(([key, value]) => (
//             <p key={key}>
//               <strong>{key}:</strong> {value.toString()}
//             </p>
//           ))}
//         </div>
//       )}

//       {/* Data preview */}
//       <div className="data-preview">
//         <h3>Data Preview</h3>
//         <p>Here is a preview of the uploaded data:</p>
//         <table>
//           <thead>
//             <tr>
//               {headers.map((header, index) => (
//                 <th key={index}>{header}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {csvData.slice(0, 5).map((row, rowIndex) => (
//               <tr key={rowIndex}>
//                 {headers.map((header, colIndex) => (
//                   <td key={colIndex}>{row[header]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <p>Only the first 5 rows are shown as a preview.</p>
//       </div>

//       {/* Action buttons */}
//       <div className="confirmation-actions">
//         <button onClick={onConfirm} className="confirm-button">
//           Confirm and Import
//         </button>
//         <button onClick={onCancel} className="cancel-button">
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DetailsStep;

import React, { useEffect, useRef } from "react";

const Preview = ({
  showPreview,
  setShowPreview,
  title,
  message,
  receiverName,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowPreview(false);
      }
    };

    if (showPreview) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPreview]);

  if (!showPreview) return null;

  const emailTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f9f9f9;
        color: #333333;
        margin: 0;
        padding: 0;
      }
      .email-container {
        max-width: 800px;
        margin: 20px auto;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
      .email-header {
        background-color: #007BFF;
        color: #ffffff;
        text-align: center;
        padding: 20px 10px;
        font-size: 24px;
      }
      .email-body {
        padding: 20px;
        line-height: 1.6;
      }
      .email-body p {
        margin: 0 0 10px;
      }
      .email-footer {
        background-color: #f1f1f1;
        text-align: center;
        padding: 10px 20px;
        font-size: 12px;
        color: #888888;
      }
      .email-footer a {
        color: #007BFF;
        text-decoration: none;
      }
      .btn {
        display: inline-block;
        padding: 10px 20px;
        color: #ffffff;
        background-color: #007BFF;
        border-radius: 4px;
        text-decoration: none;
        font-size: 14px;
        margin: 10px 0;
      }
      .btn:hover {
        background-color: #0056b3;
      }
    </style>
    </head>
    <body>
      <div class='email-container'>
        <!-- Header -->
        <div class='email-header'>
          <h2>Lightning OS</h2>
        </div>
        <!-- Body -->
        <div class='email-body'>
          <p>Dear ${receiverName},</p>
          <p>${message}</p>
        </div>
        <!-- Footer -->
        <div class='email-footer'>
          <p>&copy; ${new Date().getFullYear()} LightningOS. All rights reserved.</p>
        </div>
      </div>
    </body>
    </html>
  `;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded shadow-lg w-3/4 h-[520px]"
      >
        <iframe
          title="Email Preview"
          className="w-full h-[400px] border border-gray-300 rounded"
          srcDoc={emailTemplate}
        />
        <button
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          onClick={() => setShowPreview(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Preview;

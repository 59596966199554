import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../Layouts/Layout';
import './AnnouncementCards.scss';
import UsersApi from '../../../API/UsersApi';
import StringIntReq from '../../../Requests/StringIntReq';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { UserContext } from '../../../App';
import { RiAttachmentFill } from 'react-icons/ri';
import { Modal } from '@mui/material';

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mediaUrl, setMediaUrl] = useState('');
    const [mediaType, setMediaType] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const user = useContext(UserContext);

    // Fetch all announcements
    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 50;
        if (user.userType === "SalesRep" && user.salesOrgId) {
            req.salesRepId = user.id
            req.salesOrgId = user.salesOrgId
            req.userType = "SalesRep"
        }
        const res = await UsersApi.GetAllAnnouncements(req);
        if (res?.status?.success) {
            const sortedAnnouncements = res.data.list
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .filter(announcement => announcement.isHidden === false)
                .filter(announcement => new Date(announcement.endDate) > new Date());
            setAnnouncements(sortedAnnouncements);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
            setLoading(false);
        }
    };


    const getDaysAgo = (startDate) => {
        const today = new Date();
        const date = new Date(startDate);
        const diffInTime = today.getTime() - date.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        if (diffInDays === 0) return 'Today';
        if (diffInDays === 1) return 'Yesterday';
        return `${diffInDays}d ago`;
    };


    const getBorderColor = (type) => {
        switch (type) {
            case 'Bug Fix':
                return '#ff0000';
            case 'Feature':
                return '#4caf50';
            case 'Announcement':
                return '#2196f3';
            case 'Update':
                return '#ff0000';
            default:
                return '#000';
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, []);

    const handleOpen = (url, type) => {
        if (url && type) {

            setMediaUrl(url)
            setMediaType(type)
            setOpenModal(true)
        }
    }

    const handleClose = () => {
        setOpenModal(false)
        setMediaType('')
        setMediaUrl('')
    }

    return (
        <Layout>
            <div className="announcement-cards-container">
                <h1>All Announcements</h1>
                {loading ? (
                    <div className="spinner">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="grid-container">
                        {announcements.length > 0 ? (
                            announcements.map((announcement) => (
                                <div className="announcement-card" key={announcement.id} style={{ borderLeftColor: getBorderColor(announcement.announcementType) }} onClick={() => handleOpen(
                                    announcement?.announcementImage ? announcement?.announcementImage?.url : announcement?.announcementVideo?.url,
                                    announcement?.announcementImage ? 'image' : 'video'
                                )}>
                                    <Typography className="announcement-title">
                                        {announcement.title}
                                    </Typography>
                                    <div className="announcement-header" >
                                        <Typography className="announcement-type">
                                            New {announcement.announcementType}
                                            {(announcement.announcementImageId || announcement.announcementVideoId) &&
                                                <RiAttachmentFill size={20} style={{ marginLeft: "1rem" }} />
                                            }
                                        </Typography>
                                    </div>
                                    <Typography className="announcement-date">
                                            {getDaysAgo(announcement.startDate)}
                                        </Typography>
                                    <Typography className="announcement-message">
                                        {announcement.message}
                                    </Typography>
                                </div>
                            ))
                        ) : (
                            <Typography className="no-announcements">No announcements available.</Typography>
                        )}

                        <Modal className='modal-wrapper' open={openModal} onClose={handleClose}>
                            <div className="modal-content">
                                {mediaType === 'image' ? (
                                    <img src={mediaUrl} alt="Announcement Media" style={{ maxWidth: '100%' }} />
                                ) : (
                                    <video controls style={{ maxWidth: '100%' }}>
                                        <source src={mediaUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                            </div>
                        </Modal>
                    </div>
                )}
            </div>
        </Layout>
    );

};

export default Announcements;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Typography,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Divider,
  Modal,
} from "@mui/material";
import {
  ContentCopy,
  EmailOutlined,
  PhoneOutlined,
  BusinessOutlined,
  WorkOutlined,
  LinkedIn,
  PersonOutline,
  CheckCircle,
  Cancel,
  SendOutlined,
  DoorFront,
  HistoryOutlined,
} from "@mui/icons-material";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";
import Call from "../../components/NewLeadCard/Images/Call.png";
import Email from "../../components/NewLeadCard/Images/Email.png";
import Message from "../../components/NewLeadCard/Images/Message.png";
import Directions from "../../components/NewLeadCard/Images/Directions.png";
import wa from "../../components/NewLeadCard/Images/whatsapp.svg";
import "./CandidateMainLeadCard.scss";
import MapIconDisplay from "../MapIconDisplay";
import { UserContext } from "../../../../../App";
import { MapContext } from "../..";
import InvitationModal from "./InviteForm";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import StringIntReq from "../../../../../Requests/StringIntReq";
import UpdateCandidateAppointmentReq from "../../../../../Requests/FiberPlan/UpdateCandidateAppointmentReq";
import EditCandidateReq from "../../../../../Requests/FiberHouse/EditCandidateReq";
import Swal from "sweetalert2";
import VersatileLoader from "../../../../../components/VersatileLoader";
import ContactButtons from "../../../../../Services/ContactButtons";
import CandidateJobApplicationReq from "../../../../../Requests/Users/CandidateJobApplicationReq";
import CalendlyUserSelection from "../../../../SuperAdmin/CalendlyUserSelection";
import UsersApi from "../../../../../API/UsersApi";
import { FaRegCopy } from "react-icons/fa6";
import bot from "./../../Images/bot.png";
import FiberCompanyApi from "../../../../../API/FiberCompanyApi";
import SelectionMenu from "../../../../SuperAdmin/Tags/components/SelectionMenu";
import TagsState from "../../../../SuperAdmin/Tags/components/TagsState";
import {
  assoicateTag,
  disAssoicateTag,
} from "../../../../SuperAdmin/Tags/api/tagsAPI";

const CandidateMainLeadCard = ({ selectedCandidate }) => {
  console.log({ selectedCandidate });
  const [notes, setNotes] = useState(selectedCandidate?.notes);
  const [mapIcon, setMapIcon] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [email, setEmail] = useState(selectedCandidate?.personalEmail);
  const [phone, setPhone] = useState(selectedCandidate?.phone);
  const [name, setName] = useState(selectedCandidate?.name);
  const shouldSaveAddress = useRef(false);
  const shouldSaveNotes = useRef(false);
  const initialRender = useRef(true);
  const [address, setAddress] = useState("");

  const [industry, setIndustry] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [linkedinUrl, setLinkedinUrl] = useState(null);
  const [status, setStatus] = useState(selectedCandidate?.status);

  const [positionTitle, setPositionTitle] = useState(null);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [doorToDoor, setDoorToDoor] = useState(null);
  const [statusAttachment, setStatusAttachment] = useState(null);
  const [modeSelected, setModeSelected] = useState("email");

  const User = useContext(UserContext);
  const { mapIcons, setIndex, tags, setTags } = useContext(MapContext);
  const [customMessage, setCustomMessage] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [action, setAction] = useState("");
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const getCandidateLeadDetails = async (id) => {
    const req = new StringIntReq();
    req.int = id;
    const res = await FiberHouseApi.GetCandidateLeadDetails(req);
    if (res?.status?.success) {
      setCandidateDetails(res?.data);
      setEmail(res.data.personalEmail);
      setPhone(res.data.phone);
      setName(res.data.name);
      setStatus(res.data.status);
      setNotes(res.data.notes);
      setAddress(res.data.mapString);
      setAppointmentDate(res.data.appointmentDateTime);

      setIndustry(res.data.industry || null);
      setCompanyName(res.data.companyName || null);
      setLinkedinUrl(res.data.linkedIn || null);
      setPositionTitle(res.data.positionTitle || null);
      setCompanyEmail(res.data.companyEmail || null);
      setEmail(res.data.personalEmail || null);
      setDoorToDoor(res.data.doorToDoor || false);
      setStatusAttachment(res.data.statusAttachment || null);
    }
    setLoading(false);
  };

  // const GetCommunicationTemplates = async () => {
  //     const req = new StringIntReq();
  //     req.string = candidateDetails.leadType;
  //     req.pagingParams.pageNumber = 1;
  //     req.pagingParams.pageSize = 1000;

  //     const res = await FiberCompanyApi.GetCommunicationTemplates(req);
  //     if (res?.status?.success) {
  //         setCommunicationTemplates(res.data.list);
  //         if (res.data.list.length > 0) {
  //             setSelectedCommunicationTemplate(res.data.list[0]);
  //         }
  //     }

  // }

  const updateAppointmentDate = async () => {
    handleCloseModal();
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Updating Appointment Date",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    const req = new UpdateCandidateAppointmentReq();
    req.candidateId = selectedCandidate.id;
    req.appointmentDateTime = appointmentDate;
    const res = await FiberHouseApi.UpdateCandidateLeadAppointment(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Appointment Date Updated Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willClose: () => {
          Swal.hideLoading();
        },
      });
      setReload(!reload);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  function copyToClipboard(text, message) {
    navigator.clipboard.writeText(text);
    Swal.fire({
      icon: "success",
      title: "Copied",
      text: message,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    });
  }

  useEffect(() => {
    if (selectedCandidate != null) {
      setLoading(true);
      getCandidateLeadDetails(selectedCandidate.id);
      setMapIcon(selectedCandidate.mapIconId);
    }
  }, [selectedCandidate, reload]);

  // useEffect(() => {
  //     if (candidateDetails) {
  //         GetCommunicationTemplates();
  //     }
  // }, [candidateDetails]);

  const hireCandidate = async () => {
    // Check if email is null or empty
    if (email === null || email === "") {
      Swal.fire({
        icon: "error",
        title: "Please Enter Email",
        text: "Please enter email to hire candidate",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    // Check if name is null or empty
    if (name === null || name === "") {
      Swal.fire({
        icon: "error",
        title: "Please Enter Name",
        text: "Please enter name to hire candidate",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    // Confirmation dialog with a dropdown selector for user type
    const result = await Swal.fire({
      title: "Are you sure?",
      html: `
                <p>You want to hire <b>${name}</b> with email <b>${email}</b>.</p>
                <label for="userType" style="display: block; margin-top: 10px;">Select User Type:</label>
                <select id="userType" class="swal2-input" style="width: 100%; box-sizing: border-box;">
                    <option value="SalesRep">Sales Rep</option>
                    <option value="SalesOrgAdmin">Sales Org Admin</option>
                </select>
            `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hire!",
      preConfirm: () => {
        // Get the selected user type
        const userType = document.getElementById("userType").value;
        if (!userType) {
          Swal.showValidationMessage("Please select a user type.");
        }
        return userType;
      },
    });

    if (result.isConfirmed) {
      const userType = result.value; // This is the selected user type
      const req = new CandidateJobApplicationReq();
      req.CandidateId = selectedCandidate.id;
      req.UserId = User.id;
      req.Usertype = userType; // Include the selected user type in the request

      const res = await FiberHouseApi.HireCandidate(req);
      setMapIcon(56);
      if (res?.status?.success) {
        Swal.fire("Hired!", "Candidate has been hired.", "success");
        setIndex(-1);
      } else {
        Swal.fire(
          "Error!",
          res?.status?.message || "Failed to hire candidate.",
          "error"
        );
      }
    }
  };

  const updateLead = async (type) => {
    let req = new EditCandidateReq();
    req.id = selectedCandidate.id;

    switch (type) {
      case "name":
        req.name = name;
        break;
      case "email":
        req.personalEmail = email;
        break;
      case "phone":
        req.phone = phone;
        break;
      case "address":
        req.mapString = address;
        break;
      case "notes":
        req.notes = notes;
        break;
      case "industry":
        req.industry = industry;
        break;
      case "companyName":
        req.companyName = companyName;
        break;
      case "linkedinUrl":
        req.linkedIn = linkedinUrl;
        break;
      case "positionTitle":
        req.positionTitle = positionTitle;
        break;
      case "companyEmail":
        req.companyEmail = companyEmail;
        break;
      case "doorToDoor":
        req.doorToDoor = doorToDoor;
        break;
      case "statusAttachment":
        req.statusAttachment = statusAttachment;
        break;
      default:
        req.name = name;
        req.personalEmail = email;
        req.phone = phone;
        req.mapString = address;
        req.notes = notes;
        req.industry = industry;
        req.companyName = companyName;
        req.linkedIn = linkedinUrl;
        req.positionTitle = positionTitle;
        req.companyEmail = companyEmail;
        req.doorToDoor = doorToDoor;
    }
    req.salesRepId = User.id;
    let res = await FiberHouseApi.EditCandidateLead(req);
    if (res?.status?.success) {
      //setReload(!reload);
    }
  };

  useEffect(() => {
    console.log(phone, candidateDetails?.phone);
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (loading == false && name !== candidateDetails?.name && name !== "") {
      const timer = setTimeout(() => {
        updateLead("name");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [name, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    console.log(email, candidateDetails?.personalEmail);
    if (
      loading == false &&
      email !== candidateDetails?.personalEmail &&
      email !== ""
    ) {
      const timer = setTimeout(() => {
        updateLead("email");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [email, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    console.log(phone, candidateDetails?.phone);
    if (loading == false && phone !== candidateDetails?.phone) {
      const timer = setTimeout(() => {
        updateLead("phone");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [phone, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else if (
      loading == false &&
      shouldSaveAddress.current &&
      address !== candidateDetails?.address
    ) {
      const timer = setTimeout(() => {
        updateLead("address");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [address, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else if (
      loading == false &&
      shouldSaveNotes.current &&
      notes !== candidateDetails?.notes
    ) {
      const timer = setTimeout(() => {
        updateLead("notes");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [notes, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (
      loading == false &&
      industry &&
      industry !== candidateDetails?.industry
    ) {
      const timer = setTimeout(() => {
        updateLead("industry");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [industry, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (
      loading == false &&
      companyName &&
      companyName !== candidateDetails?.companyName
    ) {
      const timer = setTimeout(() => {
        updateLead("companyName");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [companyName, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (
      loading == false &&
      linkedinUrl &&
      linkedinUrl !== candidateDetails?.linkedIn
    ) {
      const timer = setTimeout(() => {
        updateLead("linkedinUrl");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [linkedinUrl, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (
      loading == false &&
      companyEmail &&
      companyEmail !== candidateDetails?.companyEmail
    ) {
      const timer = setTimeout(() => {
        updateLead("companyEmail");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [companyEmail, loading]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (
      loading == false &&
      positionTitle &&
      positionTitle !== candidateDetails?.positionTitle
    ) {
      const timer = setTimeout(() => {
        updateLead("positionTitle");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [positionTitle, loading]);

  useEffect(() => {
    if (candidateDetails) {
      setAction(getActions(candidateDetails.status)[0]);
    }
  }, [candidateDetails]);

  const deleteCandidate = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      const req = new StringIntReq();
      req.int = selectedCandidate.id;
      const res = await FiberHouseApi.DeleteCandidate(req);

      if (res?.status?.success) {
        Swal.fire("Deleted!", "Candidate has been deleted.", "success");
        setIndex(-1);
      } else {
        Swal.fire(
          "Error!",
          res?.status?.message || "Failed to delete candidate.",
          "error"
        );
      }
    }
  };

  const handleScheduleInterview = async (
    candidateId,
    calendlyLink,
    communicationTemplate
  ) => {
    //swal loader
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Sending Schedule",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let req = new CandidateJobApplicationReq();
      req.CandidateId = candidateId;
      req.ScheduleLink = calendlyLink;
      req.UserId = User.id;
      req.subject = communicationTemplate.subject;
      req.body = communicationTemplate.body;
      console.log(req);
      const res = await UsersApi.scheduleRecruitInterview(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Interview scheduled successfully",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            Swal.hideLoading();
          },
        });
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message || "Failed to schedule interview",
          showConfirmButton: true,
          willClose: () => {
            Swal.hideLoading();
          },
        });
      }
    } catch (error) {
      console.error("Error scheduling interview:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred",
        showConfirmButton: true,
        willClose: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  let contactButtons = new ContactButtons(User);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <VersatileLoader size="large" color="#2761CF" />
      </div>
    );
  }

  const handleAssociate = async (associateSelectedTags) => {
    const data = {
      tagIds: associateSelectedTags.map((item) => item.id),
      leadIds: [selectedCandidate.id],
      candidateIds: null,
    };
    const res = await assoicateTag(data);

    if (res.status.success) {
      setTags((prevTags) =>
        prevTags.map((t) => ({
          ...t,
          tagEntities: [
            ...t.tagEntities,
            ...res.data.filter((tEntity) => tEntity.leadTagId === t.id),
          ],
        }))
      );

      console.log(data);

      Swal.fire({
        title: "Success!",
        text: "Tags have been associated successfully.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      throw new Error(res.status.message);
    }
  };

  const handleDisassociate = async (selectedTagIds) => {
    const tagEntities = tags
      .filter((t) => selectedTagIds.includes(t.id))
      .reduce(
        (prev, currT) => [
          ...prev,
          ...currT.tagEntities.filter(
            (tEntity) => selectedCandidate.id === tEntity.userId
          ),
        ],
        []
      );

    const res = await Promise.all(
      tagEntities.map(({ id }) => disAssoicateTag({ id }))
    );

    const numberOfSuccess = res.reduce(
      (prev, currRes) => (currRes.status.success ? prev + 1 : prev),
      0
    );

    console.log({ res });

    // setTags((prevTags) =>
    //   prevTags.map((t) => ({
    //     ...t,
    //     tagEntities: [
    //       ...t.tagEntities,
    //       ...res.data.filter((tEntity) => tEntity.leadTagId === t.id),
    //     ],
    //   }))
    // );

    // setAssociateSelectedTags([]);
    // console.log(data);

    const checkThisOut = tagEntities
      .filter((_, i) => res[i].status.success)
      .reduce(
        (prev, currTEntity) => ({
          ...prev,
          [currTEntity.leadTagId]: [
            ...(prev[currTEntity.leadTagId] ?? []),
            currTEntity.id,
          ],
        }),
        {}
      );

    console.log({ checkThisOut });

    setTags((prevTags) =>
      prevTags.map((t) => {
        console.log(
          t.id,
          Object.keys(checkThisOut),
          Object.keys(checkThisOut).includes(t.id.toString())
        );
        return Object.keys(checkThisOut).includes(t.id.toString())
          ? {
              ...t,
              tagEntities: t.tagEntities.filter(
                (tEntity) => !checkThisOut[t.id].includes(tEntity.id)
              ),
            }
          : t;
      })
    );

    console.log({ numberOfSuccess });

    if (numberOfSuccess === tagEntities.length) {
      Swal.fire({
        title: "Success!",
        text: `${numberOfSuccess} Tag Entit${
          numberOfSuccess > 1 ? "ies" : "y"
        } disassociated successfully.`,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Problem!",
        text: `${tagEntities.length - numberOfSuccess} Tag Entit${
          tagEntities.length - numberOfSuccess > 1 ? "ies" : "y"
        } faced problems.`,
        icon: "warning",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function getActions(status) {
    if (candidateDetails.leadType === "Recruit") {
      switch (status) {
        case "New Candidate":
          return ["Send Ad","Send Pitch Deck", "Send Custom Text"];
        case "Contacted":
          return ["Send Ad","Send Pitch Deck", "Send Custom Text"];
        case "Application Received":
          return ["Schedule Interview", "Send Custom Text"];
        case "Interview Scheduled":
          return ["Send Job Offer", "Send Custom Text"];
        case "Offer Extended":
          return ["Onboarding Reminder","Send Job Offer Reminder", "Send Custom Text"];
        case "Future Consideration":
          return ["Send Ad", "Send Custom Text"];
        case "Rejected":
          return [];
        default:
          return [];
      }
    } else if (candidateDetails.leadType === "Company") {
      switch (status) {
        case "New Candidate":
          return ["Send Consultation","Send Pitch Deck", "Send Custom Text"];
        case "Contacted":
          return ["Send Consultation","Send Pitch Deck","Send Consultation Reminder", "Send Custom Text"];
        case "Consultation Scheduled":
          return ["Send Proposal","Send NDA","Send Contract", "Send Custom Text"];
        default:
          return [];
      }
    }
  }

  return (
    <div className="MainLeadCard">
      <div className="MainLeadCard_LeadData">
        <div className="LeadData">
          <span
            className="see_activity"
            style={{
              marginLeft: "auto",
              marginBottom: "1rem",
            }}
            onClick={() => setIndex(20)}
          >
            View Activity
          </span>

          <div className="DataDiv">
            {/* <div className='owner_name'>
                            <img
                                src={"data:image/png;base64," + mapIcons.find(icon => icon.id === mapIcon)?.image}
                                alt="Map Icon"
                                style={{ width: "50px", height: "50px" }}
                            />
                            <p>{name}</p>
                        </div>

                        <div className='ButtonsDivTop'>
                            {candidateDetails?.leadType === "Recruit" && (candidateDetails.isHired === null || candidateDetails.isHired === false) && (
                                <div style={{
                                    width: "fit-content",
                                    cursor: "pointer",
                                }} onClick={() => hireCandidate()}>
                                    <FaHireAHelper size={24} color="green" />
                                </div>
                            )}

                            <div style={{
                                width: "fit-content",
                                cursor: "pointer",
                            }}
                                onClick={() => deleteCandidate()}
                            >
                                <MdDeleteOutline size={24} color="red" />
                            </div>



                        </div> */}

            <div className="DataDiv_MapIcon">
              <div className="part1">
                <div className="image-wrapper">
                  <img
                    src={
                      "data:image/png;base64," +
                      mapIcons.find(
                        (assignedMapIcons) => assignedMapIcons.id === mapIcon
                      )?.image
                    }
                    alt="Map Icon"
                  />
                </div>

                <input
                  placeholder="No Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  className="LeadName"
                />
                <div></div>
                <span>
                  {mapIcons.find(
                    (assignedMapIcons) => assignedMapIcons.id === mapIcon
                  )?.name + " "}
                  {mapIcons.find(
                    (assignedMapIcons) => assignedMapIcons.id === mapIcon
                  )?.abbreviation &&
                    "(" +
                      mapIcons.find(
                        (assignedMapIcons) => assignedMapIcons.id === mapIcon
                      )?.abbreviation +
                      ")"}
                </span>
              </div>
              <div className="icons">
                <div className="w-fit m-auto">
                  <div className="grid grid-cols-3 gap-0 px-2">
                    <button
                      style={{
                        backgroundColor:
                          modeSelected === "email" ? "#022780" : "",
                        color: modeSelected === "email" ? "white" : "",
                      }}
                      onClick={() => setModeSelected("email")}
                      className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tl-lg"
                    >
                      Email
                    </button>
                    <button
                      style={{
                        backgroundColor:
                          modeSelected === "sms" ? "#022780" : "",
                        color: modeSelected === "sms" ? "white" : "",
                      }}
                      onClick={() => setModeSelected("sms")}
                      className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white"
                    >
                      SMS
                    </button>
                    <button
                      style={{
                        backgroundColor:
                          modeSelected === "both" ? "#022780" : "",
                        color: modeSelected === "both" ? "white" : "",
                      }}
                      onClick={() => setModeSelected("both")}
                      className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tr-lg"
                    >
                      Both
                    </button>
                  </div>
                  {
                    <div className="flex gap-2 items-center justify-center border-2 m-auto bg-white cursor-pointer border-[#022780] rounded-xl  px-8 py-1">
                      <div
                        onClick={() => {
                          setIsInviteOpen(true);
                        }}
                      >
                        <img
                          src={bot}
                          alt="bot"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>

                      <select
                        onChange={(e) => {
                          setAction(e.target.value);
                          if (e.target.value === "Send Custom Text") {
                            setCustomMessage(true);
                          } else {
                            setCustomMessage(false);
                          }
                        }}
                        className="bg-transparent w-[12rem] outline-none focus:outline-none font-bold uppercase text-[#022780]"
                      >
                        {getActions(candidateDetails.status).map((option) => (
                          <option value={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  }
                </div>
                <MdDeleteOutline
                  onClick={() => deleteCandidate()}
                  size={24}
                  color="red"
                />
                {/* <img onClick={() => {
                                    setOpenDigitalToolBelt(true)
                                }} src="/images/digital_tool_belt/digital_belt.png" alt='Digital Tool Belt' />
                                <img onClick={() => {
                                    setOpenCustomerInfo(true)
                                }} src='/images/digital_tool_belt/customerInfo.svg' alt='Digital Tool Belt' /> */}
              </div>
            </div>

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />

            {
              <div
                style={{
                  gridColumn: "span 2/span 2",
                  display: "flex",
                }}
              >
                <textarea
                  rows={3}
                  style={{
                    width: "100%",
                    outline: "none",
                  }}
                  className=""
                  placeholder="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    shouldSaveAddress.current = true;
                  }}
                />

                <FaRegCopy
                  style={{
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                  size={20}
                  color="#84A1C8"
                  onClick={() =>
                    copyToClipboard(address, "Address Copied to Clipboard")
                  }
                />
              </div>
            }

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />

            {/* phone and email */}

            <div
              style={{
                gridColumn: "span 3/ span 3",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // gap: "1rem" // Add gap between grid items
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  placeholder="No Email"
                  className="email_phone_input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    fontSize: "1rem",
                  }}

                  // style={{ flex: 1 }}
                />
                {email && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(email, "Email Copied to Clipboard")
                    }
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <input
                  placeholder="No Phone"
                  className="email_phone_input"
                  style={{
                    fontSize: "1rem",
                  }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}

                  // style={{ flex: 1 }}
                />
                {phone && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(phone, "Phone Number Copied to Clipboard")
                    }
                  />
                )}
              </div>
            </div>

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />

            {/* industry and companyName */}

            <div
              style={{
                gridColumn: "span 3/ span 3",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // gap: "1rem" // Add gap between grid items
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  className="email_phone_input bg-white"
                  value={industry}
                  disabled={true}
                  onChange={(e) => setIndustry(e.target.value)}
                  placeholder="No industry"
                />
                {industry && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(industry, "Industry Copied to Clipboard")
                    }
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <input
                  type="text"
                  className="email_phone_input bg-white"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="No company name"
                  disabled={true}
                />
                {companyName && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(
                        companyName,
                        "Company Name Copied to Clipboard"
                      )
                    }
                  />
                )}
              </div>
            </div>

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />

            {/* linkedin and position title */}

            <div
              style={{
                gridColumn: "span 3/ span 3",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // gap: "1rem" // Add gap between grid items
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  placeholder="No LinkedIn"
                  className="email_phone_input bg-white"
                  disabled={true}
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                  style={{
                    fontSize: "1rem",
                  }}
                />
                {linkedinUrl && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(
                        linkedinUrl,
                        "LinkedIn URL Copied to Clipboard"
                      )
                    }
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <input
                  placeholder="No Position Title"
                  className="email_phone_input bg-white"
                  disabled={true}
                  value={positionTitle}
                  onChange={(e) => setPositionTitle(e.target.value)}
                  style={{
                    fontSize: "1rem",
                  }}
                />
                {positionTitle && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(
                        positionTitle,
                        "Position Title Copied to Clipboard"
                      )
                    }
                  />
                )}
              </div>
            </div>

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />

            {/* company email and door to door */}
            <div
              style={{
                gridColumn: "span 3/ span 3",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // gap: "1rem" // Add gap between grid items
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  placeholder="No Company Email"
                  className="email_phone_input bg-white"
                  disabled={true}
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  style={{
                    fontSize: "1rem",
                  }}
                />
                {companyEmail && (
                  <FaRegCopy
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                    color="#84A1C8"
                    onClick={() =>
                      copyToClipboard(
                        companyEmail,
                        "Company Email Copied to Clipboard"
                      )
                    }
                  />
                )}
              </div>
              <div
                className="gap-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <span>Door to Door</span>
                <Checkbox
                  checked={doorToDoor}
                  onChange={(e) => {
                    setDoorToDoor(e.target.checked);
                    updateLead("doorToDoor");
                  }}
                />
              </div>
            </div>

            <hr
              style={{
                gridColumn: "span 3/span 3",
              }}
            />
          </div>
          {
            // communicationTemplates.length > 0 &&
          }
          <hr />
          <div className="MainLeadCard_MapIcons">
            {/* <MapIconDisplay isCustomer={selectedLead.fidiumCustomer != null} removeLightningBolts={true} setMapIcon={setMapIcon} selectedFiberHouse={selectedFiberHouse} /> */}
            <MapIconDisplay
              isCandidate={true}
              candidateType={candidateDetails?.leadType}
              removeLightningBolts={true}
              setMapIcon={setMapIcon}
            />
          </div>

          <TagsState
            label="Candidates"
            leadOrCandidateIds={[selectedCandidate.id]}
            onDisassociate={handleDisassociate}
            tags={tags}
          />
          <SelectionMenu tags={tags} onButtonClick={handleAssociate} />
          {/* Note */}

          <div
            style={{
              overflowY: "auto",

              height: "100%",
              padding: "1rem",
              // boxShadow: "0px 0px 5px rgba(0,0,0,0.1) inset",

              borderRadius: "10px",
            }}
            className="scrollable-content"
          >
            <div className="MainLeadCard_Notes">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1em",
                }}
              >
                <h2
                  style={{
                    marginPTop: "0em",
                  }}
                >
                  Notes
                </h2>
                <FaRegCopy
                  size={20}
                  color="#84A1C8"
                  onClick={() =>
                    copyToClipboard(notes, "Notes Copied to Clipboard")
                  }
                />
              </div>
              <div className="textArea">
                <textarea
                  ref={shouldSaveNotes}
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="MainLeadCard_Buttons">
            <div
              className="MainLeadCard_Button"
              onClick={() =>
                contactButtons.email(
                  candidateDetails.personalEmail
                    ? candidateDetails.personalEmail
                    : candidateDetails.companyEmail
                )
              }
            >
              <img src={Email} alt="Email" />
              <span>Email</span>
            </div>

            <div
              className="MainLeadCard_Button"
              onClick={() => contactButtons.sms(candidateDetails.phone)}
            >
              <img src={Message} alt="Message" />
              <span>SMS</span>
            </div>

            <div
              className="MainLeadCard_Button"
              onClick={() => contactButtons.whatsapp(candidateDetails.phone)}
            >
              <img
                src={wa}
                alt="
                                    Message"
              />
              <span>WA</span>
            </div>

            <div
              className="MainLeadCard_Button"
              onClick={() => contactButtons.call(candidateDetails.phone)}
            >
              <img src={Call} alt="Call" />
              <span>Call</span>
            </div>

            <div
              className="MainLeadCard_Button"
              onClick={() =>
                contactButtons.directions(candidateDetails.coordinates)
              }
            >
              <img src={Directions} alt="Directions" />
              <span>Directions</span>
            </div>
          </div>
        </div>
      </div>
      <InvitationModal
        candidate={candidateDetails}
        custom={customMessage}
        mode={modeSelected}
        open={isInviteOpen}
        action={action}
        onClose={() => setIsInviteOpen(false)}
        candidateType={candidateDetails.leadType}
        candidateId={candidateDetails.id}
      />

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="custom_modal">
          <IoCloseCircle
            onClick={() => {
              handleCloseModal();
            }}
            size={30}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            color="#2761d0"
          />
          <h1>Send Interview Calendar</h1>
          <CalendlyUserSelection
            candidateId={candidateDetails.id}
            onSchedule={handleScheduleInterview}
            type={candidateDetails?.leadType}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CandidateMainLeadCard;

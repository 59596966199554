import { useContext, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
// import { EditorTools } from "../components/editor/EditorTools";
import { getEditorJsTools } from "../../../Shared/ManageSellingCards/constants/constant";
import { UserContext } from "../../../../App";

export const useEditor = (initialData = null, init) => {
  const editorInstance = useRef(null);
  const user = useContext(UserContext);

  useEffect(() => {
    const initializeEditor = async () => {
      if (!init) return;
      editorInstance.current?.destroy();
      const tools = getEditorJsTools(user);
      editorInstance.current = new EditorJS({
        holder: "editorjs",
        placeholder: "Start writing your post...",
        tools: tools,
        data: initialData == null ? "" : initialData,
      });
    };

    initializeEditor();

    return () => {};
  }, [initialData, init, user]);

  return editorInstance;
};

import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Edit,
  Delete,
  Add,
  CloudUpload,
  SentimentDissatisfied,
  Close,
  VisibilityOffOutlined,
  Visibility,
} from "@mui/icons-material";
import Layout from "../../../Layouts/Layout";
import VersatileLoader from "../../../components/VersatileLoader";
import FiberCompanyApi from "../../../API/FiberCompanyApi";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import { uploadFileToFirebase } from "../../../Services/DisplayFile";
import StringIntReq from "../../../Requests/StringIntReq";
import Select from "react-select";
import CommandTextArea from "../../../components/CommandTextArea";
import { UserContext } from "../../../App";
import CustomerApi from "../../../API/CustomerApi";
import Preview from "./Preview";
const Index = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editedText, setEditedText] = useState(null);
  const [editedName, setEditedName] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [adTemplates, setAdTemplates] = useState([]);
  const [selectedAdTemplate, setSelectedAdTemplate] = useState(null);
  // New fields
  const [recruitOrCompany, setRecruitOrCompany] = useState("");
  const [subject, setSubject] = useState(null);
  const [customMessage, setCustomMessage] = useState(null);
  const [category, setCategory] = useState("");
  const [position, setPosition] = useState(null);
  const [smsText, setSmsText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    fetchCommunicationTemplates();
  }, []);

  const fetchCommunicationTemplates = async () => {
    try {
      const req = new StringIntReq();
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 1000;
      const response = await FiberCompanyApi.GetCommunicationTemplates(req);
      if (response.status.success) {
        setTemplates(response.data.list);
        setIsLoaded(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.status.message,
        });
        setIsLoaded(true);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      setIsLoaded(true);
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setIsEditing(false);
    setIsAdding(false);
    setEditedName(template.name);
    setIsVisible(template.isVisible);
    console.log(template.attachments);
    setEditedImage(
      template?.attachments?.length > 0
        ? template?.attachments[0]?.url
        : "/placeholder.svg?height=300&width=400"
    );

    console.log(template);
    setRecruitOrCompany(template.type || "");
    setCategory(template.category || "");
    setSubject(template.subject || "");
    setCustomMessage(template.body || "");
    setSmsText(template.textBody || "");
    setPosition(template.position || "");
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = async () => {
    try {
      Swal.fire({
        title: "Updating Template",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let cloudFile = null;

      if (selectedFile) {
        const uniqueId = uuidv4();
        cloudFile = await uploadFileToFirebase(
          selectedFile,
          `recruitTemplates/${uniqueId}`
        );
      }

      if (
        recruitOrCompany === "Recruit" &&
        (!recruitOrCompany || !editedName)
      ) {
        Swal.fire({
          title: "Error Updating Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
        });
        return;
      }

      if (recruitOrCompany === "Company" && (!editedText || !editedName)) {
        Swal.fire({
          title: "Error Updating Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      const updatedTemplateReq = {
        id: selectedTemplate.id,
        name: editedName,
        text: editedText,
        type: recruitOrCompany,
        body: customMessage,
        textBody: smsText,
        subject: subject,
        category: category,
        position: position,
      };

      if (cloudFile) {
        updatedTemplateReq.attachments = [
          {
            Name: cloudFile.name,
            Url: cloudFile.url,
            Path: cloudFile.path,
            Extension: cloudFile.extension,
            FileSize: cloudFile.fileSize,
            FileType: cloudFile.fileType,
            Caption: "Template Image",
          },
        ];
      }

      console.log(updatedTemplateReq);
      const response = await FiberCompanyApi.UpdateCommunicationTemplate(
        updatedTemplateReq
      );

      if (response.status.success) {
        Swal.fire({
          title: "Template Updated Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        fetchCommunicationTemplates();

        handleTemplateSelect({
          id: response.data.id,
          templateName: newTemplateName,
          templateImage: cloudFile,
          type: recruitOrCompany,
          content: customMessage,
          subject: subject,
          category: category,
          position: position,
        });
        setSelectedFile(null);
      } else {
        Swal.fire({
          title: "Error Updating Template",
          text: response.status.message,
          icon: "error",
          showConfirmButton: true,
        });
      }

      setIsEditing(false);
    } catch (error) {
      Swal.fire({
        title: "Error Updating Template",
        text: error.message,
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Delete Template",
        text: "Are you sure you want to delete this template?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!isConfirmed) return;

      Swal.fire({
        title: "Deleting Template",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let req = new StringIntReq();
      req.int = id;
      let response = await FiberCompanyApi.DeleteCommunicationTemplate(req);
      if (response.status.success) {
        Swal.fire({
          title: "Template Deleted Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        fetchCommunicationTemplates();
        setSelectedTemplate(null);
        setSelectedFile(null);
      } else {
        Swal.fire({
          title: "Error Deleting Template",
          text: response.status.message,
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error Deleting Template",
        text: error.message,
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const handleAddTemplate = () => {
    setIsAdding(true);
    setIsEditing(true);
    setSelectedTemplate(null);
    setEditedText(null);
    setEditedImage("/placeholder.svg?height=300&width=400");
    setNewTemplateName(null);
    setSelectedFile(null);
    setRecruitOrCompany("");
  };

  const handleSaveNewTemplate = async () => {
    try {
      Swal.fire({
        title: "Adding New Template",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let cloudFile = null;

      if (selectedFile) {
        const uniqueId = uuidv4();
        cloudFile = await uploadFileToFirebase(
          selectedFile,
          `communication-templates/${uniqueId}`
        );
      }

      console.log(newTemplateName, editedText, recruitOrCompany, cloudFile);
      if (
        recruitOrCompany === "Recruit" &&
        (!newTemplateName || !recruitOrCompany)
      ) {
        console.log(newTemplateName, editedText, recruitOrCompany, cloudFile);
        Swal.fire({
          title: "Error Saving Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      if (recruitOrCompany === "Company" && !newTemplateName) {
        Swal.fire({
          title: "Error Saving Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      if (smsText === "" || customMessage === "") {
        Swal.fire({
          title: "Error Saving Template",
          text: "SMS or Email Body is required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      const newTemplateReq = {
        name: newTemplateName,
        type: recruitOrCompany,
        attachments: [],
        body: customMessage,
        subject: subject,
        category: category,
        adTemplateId: selectedAdTemplate?.id,
        textBody: smsText,
        poistion: position,
      };

      if (cloudFile) {
        newTemplateReq.attachments.push({
          Name: cloudFile.name,
          Url: cloudFile.url,
          Path: cloudFile.path,
          Extension: cloudFile.extension,
          FileSize: cloudFile.fileSize,
          FileType: cloudFile.fileType,
          Caption: "Template Image",
        });
      }

      console.log(newTemplateReq);
      const response = await FiberCompanyApi.CreateCommunicationTemplate(
        newTemplateReq
      );

      if (response.status.success) {
        Swal.fire({
          title: "Template Added Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        fetchCommunicationTemplates();
        handleTemplateSelect({
          id: response.data.id,
          templateName: newTemplateName,
          templateImage: cloudFile,
          type: recruitOrCompany,
          content: customMessage,
          subject: subject,
          category: category,
          position: position,
        });
      } else {
        Swal.fire({
          title: "Error Saving Templatee",
          text: response.status.message,
          icon: "error",
          showConfirmButton: true,
        });
      }

      setIsAdding(false);
      setIsEditing(false);
    } catch (error) {
      Swal.fire({
        title: "Error Saving Template",
        text: error.message,
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setEditedImage(URL.createObjectURL(file));
    }
  };

  const fetchRecruitTemplates = async () => {
    try {
      let response = await FiberCompanyApi.GetRecruitTemplates();
      if (response.status.success) {
        const filteredTemplates = response.data.filter(
          (template) =>
            template.isVisible === true &&
            template.candidateType === recruitOrCompany
        );

        const formattedTemplates = filteredTemplates.map((template) => ({
          label: template.templateName,
          value: template,
        }));
        setAdTemplates(formattedTemplates);
        // if(isAdding){
        //     setSelectedAdTemplate(formattedTemplates[0].value)
        // }

        console.log(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeVisibility = async (id, visible) => {
    try {
      let req = new StringIntReq();
      req.int = id;
      req.bool = visible;
      let response =
        await FiberCompanyApi.UpdateCommunicationTemplateVisibility(req);
      if (response.status.success) {
        fetchRecruitTemplates();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRecruitTemplates();
  }, [recruitOrCompany]);

  const handleCandidateTypeChange = (e) => {
    setEditedText(null);
    setRecruitOrCompany(e.target.value);
    setCategory("");
  };

  const onPreview = () => {
    setShowPreview(true);
    console.log("preview");
  };

  if (!isLoaded) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <VersatileLoader size="large" color="#2761CF" />
        </div>
      </Layout>
    );
  }

  const handleTemplateChange = (selected) => {
    setSelectedAdTemplate(selected.value);
  };

  const options = [
    {
      label: "Candidate Name",
      value: "{{candidate}}",
    },
    {
      label: "Link",
      value: "{{link}}",
    },
  ];

  const getOptions = () => {
    if (
      category === "Job AD" ||
      category === "Job Offer" ||
      category === "Schedule Interview"
    ) {
      return [
        {
          label: "Candidate's First Name",
          value: "[Candidate's First Name]",
        },
        {
          label: "Candidate's Last Name",
          value: "[Candidate's Last Name]",
        },
        {
          label: "Candidate's Full Name",
          value: "[Candidate's Full Name]",
        },
        {
          label: "Sales Rep's Name",
          value: "[Sales Rep's Name]",
        },
        {
          label: "Link",
          value: "[Link]",
        },
      ];
    }
    if (category === "Consultation" || category === "Proposal") {
      return [
        {
          label: "Company Name",
          value: "[Company Name]",
        },
        {
          label: "Link",
          value: "[Link]",
        },
      ];
    }
    if (category === "ReviewRequest" || category === "ReferralRequest") {
      return [
        {
          label: "Customer's First Name",
          value: "[Customer's First Name]",
        },
        {
          label: "Customer's Last Name",
          value: "[Customer's Last Name]",
        },
        {
          label: "Customer's Full Name",
          value: "[Customer's Full Name]",
        },
        {
          label: "Sales Rep's Name",
          value: "[Sales Rep's Name]",
        },
        {
          label: "Link",
          value: "[Link]",
        },
      ];
    }
    return [];
  };

  const sendTest = async () => {
    Swal.fire({
      title: "Send Test",
      text: "Sending test email to your email address and test SMS to your phone number",
      icon: "info",
      // showCancelButton: true,
      confirmButtonText: "Send",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = {
      salesRepId: user.id,
      emailSubject: selectedTemplate.subject,
      emailBody: selectedTemplate.body,
      smsBody: selectedTemplate.textBody,
    };

    const res = await CustomerApi.TestCommunicationTemplate(req);

    if (res.status.success) {
      Swal.fire({
        title: "Test Sent",
        text: res.status.message,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: res.status.message,
        icon: "error",
      });
    }
  };

  return (
    <Layout>
      <div className="manage-recruit-templates">
        {/* <h1 className="page-header">Manage Communication Templates</h1> */}
        <div className="template-content">
          <div className="template-list-container">
            <div className="template-list">
              {templates.length === 0 ? (
                <div style={{ alignContent: "center", textAlign: "center" }}>
                  <SentimentDissatisfied
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  <h5>No templates available</h5>
                </div>
              ) : (
                templates.map((template) => (
                  <div
                    onClick={() => handleTemplateSelect(template)}
                    key={template.id}
                    className={`template-item flex items-center justify-between ${
                      selectedTemplate?.id === template.id ? "active" : ""
                    }`}
                  >
                    <span>{template.name}</span>
                    <Delete
                      className="hover:text-red-500 "
                      fontSize="medium"
                      onClick={() => handleDelete(template.id)}
                    />
                  </div>
                ))
              )}
            </div>
            <div
              className="template-item add-template"
              onClick={handleAddTemplate}
            >
              <Add /> Add Template
            </div>
          </div>
          <div className="template-details">
            {(selectedTemplate || isAdding) && (
              <>
                <Preview
                  message={customMessage}
                  setShowPreview={setShowPreview}
                  showPreview={showPreview}
                />
                <div className="template-actions items-center gap-2">
                  {!isAdding && (
                    <>
                      <button
                        className="bg-[var(--primary-color)] text-white rounded-lg px-4 py-[7px] cursor-pointer"
                        onClick={onPreview}
                      >
                        Preview
                      </button>
                      <buton
                        onClick={() => {
                          sendTest();
                        }}
                        className="bg-[var(--primary-color)] text-white rounded-lg px-4 py-2 cursor-pointer"
                      >
                        Send Test
                      </buton>
                      <Edit fontSize="medium" onClick={handleEdit} />
                      <div
                        style={{
                          gridGap: "1rem",
                          alignItems: "center",
                          gridTemplateColumns: "repeat(3, min-content)",
                        }}
                        className="switch"
                      >
                        <label>Draft</label>
                        <div className="switch__2">
                          <input
                            onChange={(e) => {
                              changeVisibility(
                                selectedTemplate.id,
                                e.target.checked
                              );
                              setIsVisible(e.target.checked);
                            }}
                            id="switch-20"
                            type="checkbox"
                            checked={isVisible}
                          />
                          <label for="switch-20"></label>
                        </div>
                        <label>Publish</label>
                      </div>
                    </>
                  )}
                </div>
                {isAdding ? (
                  <input
                    type="text"
                    value={newTemplateName}
                    onChange={(e) => setNewTemplateName(e.target.value)}
                    placeholder="Enter template name"
                    className="template-name-input"
                  />
                ) : (
                  <>
                    <label>Template Name</label>
                    <input
                      type="text"
                      value={isEditing ? editedName : selectedTemplate?.name}
                      onChange={(e) => setEditedName(e.target.value)}
                      placeholder="Enter template name"
                      disabled={!isEditing}
                    />
                  </>
                )}

                <label>Lead Type</label>
                <select
                  value={recruitOrCompany}
                  onChange={(e) => handleCandidateTypeChange(e)}
                  disabled={!isAdding}
                  className={`template-name-input -z-20 ${
                    showPreview ? "bg-[#9a989800] !border-none" : ""
                  }`}
                >
                  <option value="">Select type</option>
                  <option value="Recruit">Recruit</option>
                  <option value="Company">Company</option>
                  <option value="Customer">Customer</option>
                </select>

                <label>Actions</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  disabled={!isEditing && !isAdding}
                  className={`template-name-input -z-20 ${
                    showPreview ? "bg-[#9a989800] !border-none" : ""
                  }`}
                >
                  <option value="">Select Action</option>

                  {recruitOrCompany === "Recruit" ? (
                    <>
                      <option value="Job AD">Job AD</option>
                      <option value="Pitch Deck">Pitch Deck</option>
                      <option value="Job Offer">Job Offer</option>
                      <option value="Job Offer Reminder">Job Offer Reminder</option>
                      <option value="Schedule Interview">
                        Schedule Interview
                      </option>
                    </>
                  ) : recruitOrCompany === "Company" ? (
                    <>
                      <option value="Consultation">Consultation</option>
                      <option value="Consultation Reminder">Consultation Reminder</option>
                      <option value="Proposal">Proposal</option>
                      <option value="NDA">NDA</option>
                      <option value="Contract">Contract</option>
                      <option value="Pitch Deck">Pitch Deck</option>
                      {/* <option value="Invitation">Invitation</option>
                                                <option value="Recruit Accept Offer">Recruit Accept Offer</option> */}
                    </>
                  ) : (
                    <>
                      <option value="ReviewRequest">Review Request</option>
                      <option value="ReferralRequest">Referral Request</option>
                    </>
                  )}
                </select>

                {recruitOrCompany === "Recruit" && (
                  <>
                    <label>Select Position</label>
                    <select
                      onChange={(e) => setPosition(e.target.value)}
                      disabled={!isEditing && !isAdding}
                      className="template-name-input"
                      value={position}
                    >
                      <option value="">Select Position</option>
                      <option value={"Sales Rep"}>Sales Rep</option>
                      <option value="Sales Manager">Sales Manager</option>
                    </select>
                  </>
                )}

                {(category === "Job AD" || category === "Consultation") && (
                  <>
                    <label>InApp Job Ad Template</label>
                    <select
                      onChange={handleTemplateChange}
                      placeholder="Choose a template"
                      className="template-name-input"
                      disabled={!isEditing && !isAdding}
                    >
                      {adTemplates.map((template) => (
                        <option
                          key={template.value.id}
                          value={template.value.id}
                        >
                          {template.label}
                        </option>
                      ))}
                    </select>
                  </>
                )}

                <label>Attachment (For Email Only)</label>
                <div
                  className={`image-upload ${
                    showPreview ? "!bg-transparent" : ""
                  }`}
                >
                  <img
                    src={
                      isEditing
                        ? editedImage
                        : selectedTemplate?.attachments?.length > 0
                        ? selectedTemplate?.attachments[0]?.url
                        : ""
                    }
                    alt={`${showPreview ? "" : "Template Preview"}`}
                  />
                  {(isEditing || isAdding) && (
                    <button
                      onClick={triggerImageUpload}
                      className="upload-button"
                    >
                      <CloudUpload />
                      <span>{isAdding ? "Upload Image" : "Upload"}</span>
                    </button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>

                <label>Email Subject</label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter Mesage Subject"
                  disabled={!isEditing && !isAdding}
                />

                <label>Email Body</label>
                <div className="relative">
                  {/* <textarea
                                        value={customMessage}
                                        onChange={(e) => setCustomMessage(e.target.value)}
                                        placeholder="Enter Message Body"
                                        disabled={!isEditing && !isAdding}
                                        className="template-name-input"
                                        rows={8}
                                    /> */}
                  <CommandTextArea
                    disabled={!isEditing && !isAdding}
                    options={getOptions()}
                    text={customMessage}
                    setText={setCustomMessage}
                  />
                  <p className="absolute bottom-10 right-0 bg-gray-700 text-white px-4 py-2 rounded-3xl">
                    {customMessage?.length} characters
                  </p>
                </div>
                <label>SMS Body</label>
                <div className="relative">
                  <CommandTextArea
                    disabled={!isEditing && !isAdding}
                    options={getOptions()}
                    text={smsText}
                    setText={setSmsText}
                  />
                  <p className="absolute bottom-10 right-0 bg-gray-700 text-white px-4 py-2 rounded-3xl">
                    {smsText?.length} characters
                  </p>
                </div>

                {(isEditing || isAdding) && (
                  <button
                    onClick={isAdding ? handleSaveNewTemplate : handleUpdate}
                    className="save-button"
                  >
                    {isAdding ? "Add Template" : "Update"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import StringIntReq from "../../../../Requests/StringIntReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import VersatileLoader from "../../../../components/VersatileLoader";
import { MapPin, Star } from "lucide-react";
import { Tooltip } from "antd";
const AppointmentPopup = ({ open, setOpen, fiberHouse, mapIcons }) => {
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);

  const getLeadData = async () => {
    setLoading(true);

    const req = new StringIntReq();
    req.int = fiberHouse.id;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1;
    const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
    if (res?.status?.success) {
      console.log("Lead", res.data);
      setLead(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fiberHouse) {
      getLeadData();
    }
  }, [fiberHouse]);

  if (loading) {
    return (
      <div className="w-full sm:max-w-xs max-w-[250px] bg-white rounded-3xl shadow-lg p-6">
        <VersatileLoader color="#2761D0" size="medium" />
      </div>
    );
  }

  return (
    <div className="sm:max-w-xs min-w-[250px] max-w-[250px] bg-white rounded-3xl shadow-lg p-4 relative">
      <div className="flex items-center justify-end ">
        <button
          className="p-1 rounded-full bg-gray-200 hover:bg-gray-300"
          onClick={() => setOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M14.707 5.293a1 1 0 00-1.414 0L10 8.586 6.707 5.293a1 1 0 00-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 001.414 1.414L10 11.414l3.293 3.293a1 1 0 001.414-1.414L11.414 10l3.293-3.293a1 1 0 000-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center text-xl font-bold">
          <img
            src={
              "data:image/png;base64," +
              mapIcons?.find((icon) => icon.id === fiberHouse?.mapIconId)?.image
            }
            alt="Icon"
            className="w-12 h-12"
          />
        </div>
        {
          <div>
            {/* {
              // (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
              salesReps
                ?.filter((rep) =>
                  lead?.fiberHouse?.salesRepFiberHouses.some(
                    (salesRep) => salesRep.salesRepId === rep.id
                  )
                )
                .map((rep, index) => {
                  return (
                    <div className="flex gap-1 items-center ml-0" key={index}>
                      <MdBolt style={{ color: "#a0a4ac" }} size={30} />
                      <p className="sm:text-base text-xs">
                        {rep.firstName + " " + rep.lastName.charAt(0)}
                      </p>
                    </div>
                  );
                })
            } */}
            <h4 className="font-bold sm:text-md text-sm">
              {lead?.name ? lead?.name : "No Name"}
            </h4>
            {/* Rating */}
            <div className="flex gap-1 mt-1 mb-2">
              {[...Array(5)].map((_, i) => (
                <Star key={i} className="w-5 h-5 text-gray-400 fill-gray-300" />
              ))}
            </div>
            <div className="flex items-center gap-1 text-gray-500 text-sm">
              <MapPin className="w-4 h-4 text-blue-500" />
              {/* <Tooltip title={lead?.fiberHouse?.mapString || ""}> */}
                <span className=" w-32 ">
                  {lead?.fiberHouse?.address}
                </span>
              {/* </Tooltip> */}
            </div>
          </div>
        }
      </div>
      <div className="flex items-center justify-start my-2">
        <h1 className="text-base font-bold">Appointment</h1>
      </div>
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-sm font-semibold">Date</h2>
            <p className="text-xs text-gray-600">
              {new Date(lead?.appointmentDateTime).toDateString()}
            </p>
          </div>
          <div>
            <h2 className="text-sm font-semibold">Time</h2>
            <p className="text-xs text-gray-600">
              {new Date(lead?.appointmentDateTime).toLocaleTimeString()}
            </p>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-sm font-semibold">Appointment Notes</h2>
        <p className="text-xs text-gray-600">
          {lead?.appointmentNotes || "No notes available for this appointment."}
        </p>
      </div>
    </div>
  );
};

export default AppointmentPopup;

import React from 'react'
import FiberCompanySelector from  "../../FiberCompanySelector"
import FiberPlanSelector from  "../../FiberPlanSelector"
import Swal from 'sweetalert2'
import UpdateCustomerInstallDateReq from '../../../Requests/Customer/UpdateCustomerInstallDateReq'
import CustomerApi from '../../../API/CustomerApi'
const ShceduleForm = ({lead}) => {
    const [scheduleDate, setScheduleDate] = React.useState('')
    const [scheduleDateTo, setScheduleDateTo] = React.useState('')
    const [installCompany, setInstallCompany] = React.useState('')
    const [installPlan, setInstallPlan] = React.useState('')
    
    
    const updateInstallDate = async () => {

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Install Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        // the day should be the same in the install date and install date to and the time in the install date should be less than the time in the install date to
        if (new Date(scheduleDate).toLocaleDateString() !== new Date(scheduleDateTo).toLocaleDateString()) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "The Install Date and Install Date To should be on the same day",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (new Date(scheduleDate).getTime() > new Date(scheduleDateTo).getTime()) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "The Install Time should be less than the Install Time To",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!installPlan) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a plan",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        const req = new UpdateCustomerInstallDateReq();
        req.customerId = lead.fidiumCustomer.id;
        req.installDateTime = scheduleDate;
        req.installDateTimeTo = scheduleDateTo;
        req.fiberCompanyId = installCompany;
        req.fiberPlanId = installPlan?.id;
        const res = await CustomerApi.EditCustomerInstallDate(req);
        if (res?.status?.success) {
            // setOpenInstallTimeModal(false)
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Install Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            // setReload(
            //     (prev) => {
            //         return !prev
            //     }
            // )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }

  return (
    <div className='form'>
        <h2>Schedule Installation Form</h2>
        <div className='input_div'>
            <label>Install Date </label>
            <input type='datetime-local' value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
        </div>
        <div className='input_div'>
            <label>Install Date To</label>
            <input type='datetime-local' value={scheduleDateTo} onChange={(e) => setScheduleDateTo(e.target.value)} />
        </div>
        <FiberCompanySelector setUser={setInstallCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" />
        <FiberPlanSelector setUser={setInstallPlan} disabled={installCompany ? false : true} placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={installCompany} />
        <button onClick={()=>{
            updateInstallDate()
        }}>Schedule Instalk</button>
    </div>
  )
}

export default ShceduleForm
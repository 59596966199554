import { useEffect, useState } from "react";
import { fetchPosts } from "../../api/blogApi";
import Blog from "./Blog";

function BlogList() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function LoadBlogs() {
      const blogs = await fetchPosts();
      const publishBlogs = blogs.data.filter((blog) => blog.isPublished === true)
      console.log(publishBlogs)
      setBlogs(publishBlogs|| []);
    }
    LoadBlogs();
  }, []);

  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {blogs.map((post) => (
        <Blog key={post.id} post={post} />
      ))}
    </div>
  );
}
export default BlogList;

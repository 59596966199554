import React, { useEffect ,useContext } from "react";
import { useAudioRecorder } from "./RecordingHooks";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import MicIcon from "@mui/icons-material/Mic";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { RecordingContext } from "./RecordingContext";

export function AudioRecorder({ leadId, pitch, setReload }) {
  const {
    isRecording,
    isPaused,
    duration,
    audioUrl,
    error,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    clearRecording,
  } = useAudioRecorder(leadId, pitch, setReload);

  const AudioRecording  = useContext(RecordingContext);
  

  useEffect(() => {

    AudioRecording?.setAudioRecording({
      startRecording,
      pauseRecording,
      resumeRecording,
      stopRecording,
      clearRecording,
    });



  }, [leadId]);

  const formatDuration = (ms = 0) => {
    const seconds = Math.floor(ms / 1000) || 0;
    const minutes = Math.floor(seconds / 60);
    return `${minutes}:${String(seconds % 60).padStart(2, "0")}`;
  };

  const handleClick = () => {
    if (isRecording) {
      isPaused ? resumeRecording() : pauseRecording();
    } else {
      startRecording();
      AudioRecording?.setIsRecording(true);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {error && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          {error}
        </Alert>
      )}

      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {!audioUrl && (
          <Tooltip
            title={
              isRecording ? (
                <Typography
                  variant="body2"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <AccessTimeIcon fontSize="small" />
                  {formatDuration(duration)}
                </Typography>
              ) : (
                "Start Recording"
              )
            }
          >
            <IconButton onClick={handleClick} aria-label="Record">
              {isRecording ? (
                isPaused ? (
                  <PlayArrowIcon color="primary" />
                ) : (
                  <PauseIcon color="primary" />
                )
              ) : (
                <MicIcon color="primary" />
              )}
            </IconButton>
          </Tooltip>
        )}

        {isRecording && (
          <Tooltip title="Stop Recording">
            <IconButton onClick={() =>{ stopRecording(); 
            AudioRecording?.setIsRecording(false);
            }} aria-label="Stop Recording">
              <StopIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}

        {audioUrl && (
          <>
            <audio controls src={audioUrl} style={{ width: "100px", maxWidth: "200px", height: "32px" }} />
            <Tooltip title="Clear Recording">
              <IconButton onClick={clearRecording} aria-label="Clear Recording">
                <DeleteIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

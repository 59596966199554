import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import authorized from "./../../Offer/components/authorized.png";
import authorizedFastwyre from "./../../Offer/components/authorizedFastwyre.png";
import StringIntReq from "../../../../Requests/StringIntReq";
import Swal from "sweetalert2";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import PhoneInput from "react-phone-input-2";
import CreateLightCustomerReq from "../../../../Requests/Customer/CreateLightCustomerReq";
const RequestCall = ({ offer, open, setOpen, color, lead, salesRepId,fiberCompanyId }) => {
  const [lightFirstName, setLightFirstName] = useState(
    lead?.name?.split(" ")[0]
  );
  const [lightLastName, setLightLastName] = useState(
    lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ")[1] : ""
  );
  const [lightEmail, setLightEmail] = useState(lead?.email);
  const [lightPhone, setLightPhone] = useState(lead?.phone);
  const [lightConsent, setLightConsent] = useState(false);
  const [timeSlot, setTimeSlot] = useState("9:00 AM - 11:00 AM");

  const setAppointmentTime = async () => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Setting Appointment Time",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: "custom-swal",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (!lightFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "First Name is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightPhone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightEmail) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (lightPhone.length < 11) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is invalid",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightConsent) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Consent is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    console.log("Setting Appointment Time");
    var req = new CreateLightCustomerReq();
    req.createNewLead = false;
    if (window.location.href.includes("digital-business-center")) {
      req.name = `${lightFirstName} ${lightLastName}`;
      req.email = lightEmail;
      req.phone = lightPhone;
      req.createNewLead = true;
      req.salesRepId = salesRepId;
      req.companyId = fiberCompanyId;
      req.string = timeSlot;
    } else {
      req.int = offer?.leadId;
      req.string = timeSlot;
    }

    const res = await FiberHouseApi.SetAppointmentTimeForRequestCall(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Expect a call from a representative in the selected time slot",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      setOpen(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  return (
    <div className="custom_modal">
      <div style={{ width: "100%" }}>
        <IoMdCloseCircle
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => {
            setOpen(false);
          }}
          size={30}
          color={color}
        />
      </div>
      {offer?.fiberCompanyId === 9 && (
        <img alt="Logo" src={authorizedFastwyre} className="logo" />
      )}
      {offer?.fiberCompanyId === 2 && (
        <img alt="Logo" src={authorized} className="logo" />
      )}
      {(offer?.fiberCompanyId === 10 || offer?.fiberCompanyId === 11) && (
        <img
          src="/images/offer/pointbroadband/logo.png"
          alt="Logo"
          className="logo"
        />
      )}
      {offer?.fiberCompanyId === 12 && (
        <img src="/images/offer/wyyerd/logo.png" alt="Logo" className="logo" />
      )}
      <div className="inputs">
        <p className="font-bold text-center">
          Select the Time Slot in which you would like to receive a call from
          Rep.
        </p>
        <select
          onChange={(e) => {
            setTimeSlot(e.target.value);
          }}
        >
          <option value="9:00 AM - 11:00 AM">9:00 AM - 11:00 AM</option>
          <option value="11:00 AM - 1:00 PM">11:00 AM - 1:00 PM</option>
          <option value="1:00 PM - 3:00 PM">1:00 PM - 3:00 PM</option>
          <option value="3:00 PM - 5:00 PM">3:00 PM - 5:00 PM</option>
          <option value="5:00 PM - 7:00 PM">5:00 PM - 7:00 PM</option>
        </select>
        {window.location.href.includes("digital-business-center") && (
          <>
            <input
              placeholder="*First Name"
              value={lightFirstName}
              onChange={(e) => {
                setLightFirstName(e.target.value);
              }}
            />
            <input
              placeholder="Last Name"
              value={lightLastName}
              onChange={(e) => {
                setLightLastName(e.target.value);
              }}
            />
            <input
              placeholder="*Email"
              value={lightEmail}
              onChange={(e) => {
                setLightEmail(e.target.value);
              }}
            />
            <PhoneInput
              placeholder="* Cellphone"
              country={"us"}
              containerStyle={{
                width: "100%",
                borderRadius: "10px",
              }}
              inputClass="custom_input"
              value={lightPhone}
              onChange={(e) => {
                setLightPhone(e);
              }}
            />
          </>
        )}
        <div className="consent">
          <input
            type="checkbox"
            checked={lightConsent}
            onChange={(e) => {
              setLightConsent(e.target.checked);
            }}
          />
          <label>
            I agree to receive email and/or sms messages from Lightning OS LLC.
          </label>
        </div>
        <button onClick={setAppointmentTime} style={{ backgroundColor: color }}>
          Request a Call
        </button>
      </div>
    </div>
  );
};

export default RequestCall;

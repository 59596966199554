// 'use client';

import { useState, useRef, useCallback } from 'react';

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../../../../App';
import { useContext } from 'react';
import FiberHouseApi from '../../../../../API/FiberHouseApi';
import UsersApi from '../../../../../API/UsersApi';
import StringIntReq from '../../../../../Requests/StringIntReq';
// import { url } from 'inspector';
// import { FileType } from 'lucide-react';
// import { Add, Extension } from '@mui/icons-material';



export function useAudioRecorder(leadId , pitch , setReload) {
    const user = useContext(UserContext);
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [duration, setDuration] = useState(0);
    const [audioUrl, setAudioUrl] = useState(pitch?.url);
    const [error, setError] = useState(null);
    const mediaRecorder = useRef(null);
    const chunks = useRef([]);
    const timerRef = useRef(null);
    const startTimeRef = useRef(0);
    const pausedTimeRef = useRef(0);


    // console.log(leadId);

    const deletePitch = async (PitchId) => {
        let req = new StringIntReq();

        req.int = PitchId;

        const res = await UsersApi.DeleteUserBizCardVideo(req);
        console.log(res);

        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "File Deleted",
                // position: 'top',
                showConfirmButton: true,
                // timer: 1500,

                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            setReload(prev=>!prev);

        } else {
            Swal.fire({
                icon: "error",
                title: "File Not Deleted",

                text: "Please try again",
                // position: 'top',
                showConfirmButton: true,
                // timer: 1500,

                customClass: {
                    container: "custom-swal"
                },

                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
    }
        


    const AddPitch = async ( Url, FileType, FileSize, Extension, Caption, Path, Name) => {

        let req = {
            LeadId: leadId,
            Name: Name,
            Url: Url,
            FileType: FileType,
            FileSize: FileSize,
            Extension: Extension,
            Caption: Caption,
            Path: Path
        }
        const res = await FiberHouseApi.AddPitch(req);
        console.log(res);

        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "File Added",
                // position: 'top',
                showConfirmButton: true,
                // timer: 1500,

                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
            setReload(prev=>!prev);

        } else {
            Swal.fire({
                icon: "error",
                title: "File Not Added",

                text: "Please try again",
                // position: 'top',
                showConfirmButton: true,
                // timer: 1500,

                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });

        }


    }

    const uploadFileToFirebase = async (file, path) => {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    Swal.update({
                        text: `Uploading File ${progress.toFixed(0)}%`,
                    });
                },
                (error) => {
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Upload Failed',
                        text: `Failed to upload ${file.name}.`,
                        customClass: {
                            container: "custom-swal"
                        },
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    resolve({
                        name: file.name,
                        url: downloadURL,
                        path: path,
                        extension: file.name.split('.').pop(),
                        fileSize: file.size.toString(),
                        fileType: file.type,
                        caption: file.name,
                    });
                }
            );
        });
    };
    const handleAudioUpload = async (audioBlob) => {
        Swal.fire({
            icon: 'info',
            title: 'Uploading Audio',
            text: 'Please wait...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => Swal.showLoading(),
        });

        const file = new File([audioBlob], `audio_${uuidv4()}.webm`, { type: 'audio/webm' });
        const path = `/pitches/${user.firebaseId}/${leadId}/${uuidv4()}`;

        try {
            const uploadedFile = await uploadFileToFirebase(file, path);
            // setAudioUrl(uploadedFile.url);  // Update the state with the new file info
            Swal.fire({
                icon: "success",
                title: "File Uploaded",
                position: 'top',
                showConfirmButton: false,
                timer: 1500,

                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading();
                }


            });

            AddPitch( uploadedFile.url, uploadedFile.fileType, uploadedFile.fileSize, uploadedFile.extension, uploadedFile.caption, uploadedFile.path,uploadedFile.name);


            







        } catch (error) {
            console.error('Audio upload failed:', error);
        }
    };

   
    const pauseRecording = useCallback(() => {
        if (mediaRecorder.current && isRecording) {
            mediaRecorder.current.pause();
            setIsPaused(true);
            pausedTimeRef.current = Date.now() - startTimeRef.current;
            if (timerRef.current) clearInterval(timerRef.current);
        }
    }, [isRecording]);

    const resumeRecording = useCallback(() => {
        if (mediaRecorder.current && isPaused) {
            mediaRecorder.current.resume();
            setIsPaused(false);
            startTimeRef.current = Date.now() - pausedTimeRef.current;

            timerRef.current = setInterval(() => {
                setDuration(Date.now() - startTimeRef.current);
            }, 100);
        }
    }, [isPaused]);

    const stopRecording = useCallback(() => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            mediaRecorder.current.stream.getTracks().forEach((track) => track.stop());
            setIsRecording(false);
            setIsPaused(false);

            if (timerRef.current) clearInterval(timerRef.current);
        }
    }, []);

    const clearRecording = useCallback(() => {
        if (audioUrl) {
            URL.revokeObjectURL(audioUrl);

            deletePitch(pitch?.id);

            // setAudioUrl(null);




        }
        chunks.current = [];
        setDuration(0);
    }, [audioUrl]);


    const startRecording = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream);

            mediaRecorder.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.current.push(e.data);
                }
            };

            mediaRecorder.current.onstop = async () => {
                const blob = new Blob(chunks.current, { type: 'audio/webm' });
                const url = URL.createObjectURL(blob);
                // setAudioUrl(url);
                await handleAudioUpload(blob);  // Upload the audio file to Firebase
            };

            mediaRecorder.current.onerror = (event) => {
                setError('Recording failed: ' + event.error);
            };

            mediaRecorder.current.start();
            setIsRecording(true);
            setError(null);
            // setAudioUrl(null);
            startTimeRef.current = Date.now() - pausedTimeRef.current;

            timerRef.current = setInterval(() => {
                setDuration(Date.now() - startTimeRef.current);
            }, 100);
        } catch (err) {
            setError('Error accessing microphone. Please ensure microphone permissions are granted.');
            console.error('Error accessing microphone:', err);
        }
    }, []);
    return {
        isRecording,
        isPaused,
        duration,
        audioUrl,
        error,
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        clearRecording,
        // handleRecordingUpload,
    };
}


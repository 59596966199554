// import type React from "react"
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { IoMdCloseCircle } from "react-icons/io";
import Swal from "sweetalert2";
import authorized from "./../../Offer/components/authorized.png";
import authorizedFastwyre from "./../../Offer/components/authorizedFastwyre.png";
import LightAcceptOfferFromAffiliateCodeReq from "../../../../Requests/Customer/LightAcceptOfferFromAffiliateCodeReq";
import { UserContext } from "../../../../App";
import CustomerApi from "../../../../API/CustomerApi";

// interface FormField {
//   label: string
//   value: string | boolean
// }

// interface FormData {
//   firstName: FormField
//   lastName: FormField
//   email: FormField
//   phone: FormField
//   state: FormField
//   city: FormField
//   street: FormField
//   zipcode: FormField
//   consent: FormField
// }

// interface LightAcceptOfferFormProps {
//   setOpen: (open: boolean) => void
//   setOpenRequestCall: (open: boolean) => void
//   setReload: (reload: boolean) => void
//   offerTemplate: any
//   fiberPlan: any
// }

export default function LightAcceptOfferForm({
  setOpen,
  setOpenRequestCall,
  setReload,
  offerTemplate,
  affiliateCode,
  fiberPlan,
}) {
  const user = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstName: { label: "First Name", value: "" },
    lastName: { label: "Last Name", value: "" },
    email: { label: "Email", value: "" },
    phone: { label: "Phone", value: "" },
    state: { label: "State", value: "" },
    city: { label: "City", value: "" },
    street: { label: "Street", value: "" },
    zipcode: { label: "Zipcode", value: "" },
    consent: { label: "Consent", value: false },
  });

  const navigate = useNavigate();
  const color = fiberPlan.color;

  const createLightCustomer = async (e) => {
    e.preventDefault();

    const s = Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Processing your Request",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    for (const k in formData) {
      if (!formData[k].value) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${formData[k].label} is required`,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }
    }

    console.log({ user });

    const req = new LightAcceptOfferFromAffiliateCodeReq(
      affiliateCode.id,
      fiberPlan.id,
      formData.firstName.value,
      formData.lastName.value,
      formData.email.value,
      formData.phone.value,
      formData.street.value,
      formData.city.value,
      formData.state.value,
      formData.zipcode.value
    );

    console.log({ req });

    const res = await CustomerApi.LightAcceptOfferFromAffiliateCode(req);

    if (!res.status.success) {
      Swal.fire({
        icon: "error",
        title: "...Oops",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }

    Swal.fire({
      icon: "success",
      title: "Success",
      text: "We will get in touch with you soon!",
      didOpen: () => {
        Swal.hideLoading();
      },
    });

    setOpen(false);
    setFormData({
      firstName: { ...formData.firstName, value: "" },
      lastName: { ...formData.lastName, value: "" },
      email: { ...formData.email, value: "" },
      phone: { ...formData.phone, value: "" },
      city: { ...formData.city, value: "" },
      street: { ...formData.street, value: "" },
      state: { ...formData.state, value: "" },
      zipcode: { ...formData.zipcode, value: "" },
      consent: { ...formData.consent, value: false },
    });
  };

  return (
    <div className="bg-white rounded-xl p-6 max-w-md w-full mx-auto relative">
      <div className="w-full flex justify-end mb-4">
        <button
          onClick={() => setOpen(false)}
          className="text-gray-500 hover:text-gray-700"
        >
          <IoMdCloseCircle size={30} style={{ color }} />
        </button>
      </div>

      {offerTemplate?.fiberCompanyId === 9 && (
        <img
          alt="Fastwyre Authorized Dealer"
          src={authorizedFastwyre}
          className="h-12 mx-auto mb-6"
        />
      )}
      {offerTemplate?.fiberCompanyId === 2 && (
        <img
          alt="Lightcurve Authorized Dealer"
          src={authorized}
          className="h-12 mx-auto mb-6"
        />
      )}

      <form onSubmit={createLightCustomer} className="space-y-4">
        <input
          required
          type="text"
          placeholder={`*${formData.firstName.label}`}
          value={formData.firstName.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              firstName: { ...prev.firstName, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <input
          required
          type="text"
          placeholder={`*${formData.lastName.label}`}
          value={formData.lastName.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              lastName: { ...prev.lastName, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <input
          required
          type="email"
          placeholder={`*${formData.email.label}`}
          value={formData.email.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              email: { ...prev.email, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <div className="w-full">
          <PhoneInput
            inputProps={{
              required: true,
              className:
                "w-full pl-[calc(38px+1rem)] pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400",
            }}
            placeholder={`*${formData.phone.label}`}
            country="us"
            containerClass="w-full"
            value={formData.phone.value}
            onChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                phone: { ...prev.phone, value },
              }))
            }
          />
        </div>
        <input
          required
          type="text"
          placeholder={`*${formData.state.label}`}
          value={formData.state.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              state: { ...prev.state, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <input
          required
          type="text"
          placeholder={`*${formData.city.label}`}
          value={formData.city.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              city: { ...prev.city, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <input
          required
          type="text"
          placeholder={`*${formData.street.label}`}
          value={formData.street.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              street: { ...prev.street, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />
        <input
          required
          type="text"
          placeholder={`*${formData.zipcode.label}`}
          value={formData.zipcode.value}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              zipcode: { ...prev.zipcode, value: e.target.value },
            }))
          }
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-400"
        />

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="consent"
            checked={formData.consent.value}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                consent: { ...prev.consent, value: e.target.checked },
              }))
            }
            className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="consent" className="text-sm text-gray-600">
            I agree to receive email and/or sms messages from Lightning OS LLC.
          </label>
        </div>

        <div className="space-y-2">
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-[#2761d0] border-none border-gray-300 rounded-lg font-medium hover:bg-[#1f4fa0] transition-colors"
            style={{ backgroundColor: color }}
          >
            ORDER FREE INSTALL NOW
          </button>
          <button
            type="button"
            className="w-full px-4 py-2 text-white bg-[#2761d0] border-none border-gray-300 rounded-lg font-medium hover:bg-[#1f4fa0] transition-colors"
            onClick={() => {
              setOpenRequestCall(true);
              setOpen(false);
            }}
          >
            REQUEST A CALL
          </button>
        </div>
      </form>
    </div>
  );
}

// button {
//   background-color: #2761d0;
//   color: white;
//   border: none;
//   padding: 0.8rem 2rem;
//   border-radius: 8px;
//   cursor: pointer;
//   margin-top: 1.5rem;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
//   width: 100%;
//   text-align: center;

//   &:hover {
//       background-color: #1f4fa0;
//   }
// }

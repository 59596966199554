import React from "react";
import Swal from "sweetalert2";

import { v4 as uuidv4 } from "uuid";
import CreateIssue from "../../../../Requests/Issues/CreateIssue";
import { uploadFileToFirebase } from "../../../../Services/DisplayFile";
import IssueApi from "../../../../API/IssueApi";
import { Upload, X } from "lucide-react";

const IssueTicket = ({ lead }) => {
  const [priority, setPriority] = React.useState("");
  const [issueDescription, setIssueDescription] = React.useState("");
  const [screenshot, setScreenshot] = React.useState("");
  const [issueType, setIssueType] = React.useState("");
  const [fileName, setFileName] = React.useState("")

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setScreenshot(file)
      setFileName(file.name)
    }
  }

  const removeFile = () => {
    setScreenshot("");
    setFileName("");
  };

  const submitIssue = async () => {
    Swal.fire({
      icon: "info",
      title: "Submitting Issue",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (issueDescription === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter issue description",
      });
      return;
    }

    if (priority === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select priority level",
      });
      return;
    }


    const req = new CreateIssue();
    req.description = issueDescription;
    req.priorityLevel = priority;
    req.saleId = lead.fidiumCustomer?.id;
    req.issueType = issueType;

    if (screenshot !== "") {
      const file = await uploadFileToFirebase(
        screenshot,
        `/Issues/${lead.fidiumCustomer?.id}/${uuidv4()}`
      );
      req.file = file;
    }

    const res = await IssueApi.CreateIssue(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Issue submitted successfully",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to submit issue",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }

  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-sm">
      <h2 className="text-2xl font-semibold mb-6">Issue Ticket</h2>

      <div className="space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Issue Type</label>
          <select
            onChange={(event) => {
              setIssueType(event.target.value)
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select type</option>
            <option value="General">General</option>
            <option value="Installation">Installation</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Issue Description</label>
          <textarea
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Describe the issue..."
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Upload Screenshot/Document (Optional)</label>
          <div className="relative">
            <label className="flex items-center justify-center w-full px-4 py-3 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 transition-colors group">
              <input type="file" onChange={handleFileChange} className="hidden" />
              <div className="flex flex-col items-center">
                <Upload className="w-6 h-6 text-gray-400 group-hover:text-blue-500 transition-colors" />
                <span className="mt-2 text-sm text-gray-500 group-hover:text-blue-500 transition-colors">
                  {fileName || "Click to upload file"}
                </span>
                <span className="mt-1 text-xs text-gray-400">PNG, JPG up to 10MB</span>
              </div>
            </label>
            {fileName && (
              <button
                onClick={removeFile}
                className="absolute top-2 right-2 text-gray-400 hover:text-red-500 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Priority Level</label>
          <div className="flex flex-wrap gap-3">
            {["Low", "Medium", "High"].map((level) => (
              <button
                key={level}
                onClick={() => setPriority(level)}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                  ${priority === level ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"}`}
              >
                {level}
              </button>
            ))}
          </div>
        </div>

        <button
          onClick={submitIssue}
          className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default IssueTicket;

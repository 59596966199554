import TeamAdminMenuItems from "../MenuItems/TeamAdminMenuItems";
import ContactCenter from "../Views/Shared/ContactCenter";
import Dashboard from "../Views/Shared/Dashboard";
import EditAccountDetails from "../Views/Shared/EditAccountDetails";
import ManageSellingCards from "../Views/Shared/ManageSellingCards";
import SellingCardTutorial from "../Views/Shared/SellingCardTutorial";
import ComissionPlanMaker from "../Views/TeamAdmin/ComissionPlanMaker";
import CreateTeamMember from "../Views/TeamAdmin/CreateTeamMember";
import CustomerFeedbackAnalysis from "../Views/TeamAdmin/CustomerFeedbackAnalysis";
import FiberFeedback from "../Views/TeamAdmin/FiberFeedback";
import ManageCustomers from "../Views/TeamAdmin/ManageCustomers";
import ManageTeamMembers from "../Views/TeamAdmin/ManageTeamMembers";
import MapPage from "../Views/TeamAdmin/MapPage";
import OrderHistory from "../Views/TeamAdmin/OrderHistory";
import CustomerSupport from "./../Views/Shared/CustomerSupport";
import Profile from "./../Views/TeamAdmin/Profile";
import ChatNew from "../Views/Shared/ChatNew";
import ChatList from "../Views/Shared/ChatList";
import DigitalBusinessCenter from "../Views/Reps/DigitalBusinessCenter";
import CustomerFeedback from "./../Views/Reps/CustomerFeedback";
import UserCommission from "../Views/Shared/UserCommission";
import LeaderBoard from "./../Views/Reps/LeaderBoard";
import SellingCardDetails from "../Views/Shared/ManageSellingCards/SellingCardDetails";
import SellingCardDetailsNew from "../Views/Shared/ManageSellingCards/SellingCardDetails";
import CreateBugTicketForm from "../Views/Public/BugTicket";
import SalesReportingPage from "../Views/Shared/SalesReport";
import SalesGeneralReport from "../Views/Shared/SalesGeneralReport";
import OffersReport from "../Views/Shared/OffersReport";
import CustomerDetails from "./../Views/SuperAdmin/CustomerDetails";
import SetReferalCompany from "../Views/Shared/SetReferalCompany";
import MyCalendar from "../Views/Shared/MyCalendar";
import Announcements from "../Views/Shared/Announcements";
import ManageAnnouncements from "../Views/SuperAdmin/ManageAnnouncements";
import BusinessCenter from "./../Views/Shared/BusinessCenter";
import MasterSheet from "../Views/Shared/MasterSheet";
import NewOffer from "../Views/Public/NewOffer";
const TeamAdminRoutes = [
  {
    name: "Dashboard",
    key: "Dashboard",
    route: "/",
    component: <Dashboard />,
  },
  {
    name: "Create Team Member",
    key: "CreateTeamMember",
    route: "/team-member",
    component: <CreateTeamMember />,
  },
  {
    name: "Manage Team Members",
    key: "ManageTeamMembers",
    route: "/manage-team-members",
    component: <ManageTeamMembers />,
  },
  {
    name: "Map",
    key: "FiberHouses",
    route: "/map",
    component: <MapPage />,
  },
  {
    name: "Profile",
    key: "Profile",
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Manage Customers",
    key: "ManageCustomers",
    route: "/manage-customers",
    component: <ManageCustomers />,
  },
  {
    name: "Customer Details",
    key: "CustomerDetails",
    route: "/CustomerDetails/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Customer Support",
    key: "CustomerSupport",
    route: "/customer-support",
    component: <CustomerSupport />,
  },
  {
    name: "Customer Feedback Analysis",
    key: "CustomerFeedbackAnalysis",
    route: "/customer-feedback-analysis",
    component: <CustomerFeedback />,
  },
  {
    name: "Comission Plan Maker",
    key: "ComissionPlanMaker",
    route: "/comission-plan-maker",
    component: <ComissionPlanMaker />,
  },
  {
    name: "Edit Account Details",
    key: "EditAccountDetails",
    route: "/edit-account-details",
    component: <EditAccountDetails />,
  },
  {
    name: "Fiber Feedback",
    key: "FiberFeedback",
    route: "/fiber-feedback",
    component: <FiberFeedback />,
  },
  {
    name: "Order History",
    key: "OrderHistory",
    route: "/order-history",
    component: <OrderHistory />,
  },
  {
    name: "Private Conversations",
    key: "privatechatpage",
    route: "/chats",
    component: <ChatList />,
  },
  {
    name: "Manage Selling Cards",
    key: "ManageSellingCards",
    route:
      "manage-training-modules/training-module-tutorial/:trainingModuleId/:trainingModuleName/:index",
    component: <SellingCardTutorial />,
  },
  {
    name: "Manage Selling Cards",
    key: "ManageSellingCards",
    route: "/manage-training-modules",
    component: <ManageSellingCards />,
  },
  {
    name: "Contact Center",
    key: "ContactCenter",
    route: "/contact-center",
    component: <ContactCenter />,
  },
  {
    name: "Chat",
    key: "chat",
    route: "/chat",
    component: <ChatNew />,
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center/:id",
    component: <DigitalBusinessCenter />,
  },
  {
    name: "User Commissions",
    key: "UserCommissions",
    route: "/user-commissions",
    component: <UserCommission />,
  },
  {
    name: "My Commissions",
    key: "MyCommissions",
    route: "/my-commissions",
    component: <UserCommission />,
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center",
    component: <DigitalBusinessCenter />,
  },
  {
    name: "Leader Board",
    key: "LeaderBoard",
    route: "/leader-board",
    component: <LeaderBoard />,
  },
  {
    name: "Report A Bug",
    key: "CreateBugTicket",
    route: "/create-bug-ticket",
    component: <CreateBugTicketForm />,
  },
  {
    name: "Selling Card Details",
    key: "SellingCardDetails",
    route: "manage-training-modules/selling-card-details/:trainingModuleId",
    component: <SellingCardDetailsNew />,
  },
  {
    name: "Training Module Details",
    key: "TrainingModuleDetails",
    route: "manage-training-modules/training-module-details/:trainingModuleId",
    component: <SellingCardDetailsNew />,
  },
  {
    name: "Sales Reporting Page",
    key: "SalesReportingPage",
    route: "/sales-reporting-page",
    component: <SalesReportingPage />,
  },
  {
    name: "Sales Reporting Page",
    key: "SalesReportingPage",
    route: "/general-sales-reporting-page",
    component: <SalesGeneralReport />,
  },
  {
    name: "Offers Reporting Page",
    key: "OffersReportingPage",
    route: "/offers-reporting-page",
    component: <OffersReport />,
  },
  {
    name: "My Calendar",
    key: "MyCalendar",
    route: "/my-calendar",
    component: <MyCalendar />,
  },
  {
    name: "Manage Announcements Board",
    key: "ManageAnnouncementsBoard",
    route: "/manage-announcements-board",
    component: <ManageAnnouncements />,
  },
  {
    name: "Work Sheet",
    key: "WorkSheet",
    route: "/master-sheet",
    component: <MasterSheet />,
  },
  {
    name: "Fiber Offer",
    key: "FiberOffer",
    route: "/fiber-offer/:id",
    component: <NewOffer />,
  },
  {
    name: "Preview Offer",
    key: "PreviewOffer",
    route: "/preview-offer/:repId/:id",
    component: <NewOffer />,
  },
  {
    name: "Preview Offer",
    key: "PreviewOffer",
    route: "/preview-offer/:repId/:id/:leadId",
    component: <NewOffer />,
  }
];

export default TeamAdminRoutes;

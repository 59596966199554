import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { PostActions } from "./PostActions";
import { useNavigate } from "react-router-dom";
import { fetchPosts } from "../../api/blogApi";
import { deletePost, archiveBlog, publishBlog } from "../../api/blogApi";
import Swal from "sweetalert2";

export function PostTable() {
  const [activeTab, setActiveTab] = useState("all");
  const [isEditing, setIsEditing] = useState(null);
  const [editingPost, setEditingPost] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  // handleDelete
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setSelected(false);
      await deletePost(id);
      console.log("Deleted post:", id);
      setBlogs((prev) => prev.filter((b) => b.id !== id));
      Swal.fire("Deleted!", "Your post has been deleted.", "success");
    }
  };
  // handleArchive
  const handleArchive = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to archive this blog?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setSelected(false);
      await archiveBlog(id);
      console.log("Blog Archived:", id);
      setBlogs((prev) => {
        return prev.map((b) => (b.id === id ? { ...b, isArchived: true } : b));
      });
      console.log(blogs);
      Swal.fire("Archived!", "Your blog has been archived.", "success");
    }
  };
  // handlePublish
  const handlePublish = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to publish this blog?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, publish it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setSelected(false);
      await publishBlog(id);
      console.log("Blog Published:", id);
      setBlogs((prev) => {
        return prev.map((b) => (b.id === id ? { ...b, isPublished: true } : b));
      });
      console.log(blogs)
      Swal.fire("Published!", "Your blog has been published.", "success");
    }
  };
  useEffect(() => {
    console.log("Updated blogs:", blogs);
  }, [blogs]);
  
  useEffect(() => {
    async function LoadPosts() {
      try {
        const posts = await fetchPosts();
        setBlogs(posts.data);
      } catch {
        console.log("Error fetching posts");
      }
    }

    LoadPosts();
  }, []);

  const handleNewPost = () => {
    navigate("/create-blog");
    setEditingPost(null);
    setIsEditing(true);
  };

  const handleEditPost = (post) => {
    navigate(`/edit/${post.id}`);
    setEditingPost(post);
    setIsEditing(true);
  };
  const filteredPosts = (blogs || []).filter((post) => {
    if (activeTab === "all") return true;
    if (activeTab === "published") return post.isPublished;
    if (activeTab === "draft") return post.isDraft;
    if (activeTab === "archived") return post.isArchived;
    return false;
  });
  

  const getStatus = (post) => {
    if (post.isDraft) return "Draft";
    if (post.isPublished) return "Published";
    if (post.isArchived) return "Archived";
    return "Unknown";
  };

  return (
    <div className="pb-10 min-w-[450px]">
      <div className="flex justify-between items-center mb-4">
        <div className="inline-flex h-10 items-center justify-center rounded-md bg-gray-100 p-1 text-gray-500 dark:bg-gray-800">
          {["all", "published", "draft", "archived"].map((tab) => (
            <button
              key={tab}
              className={`inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${
                activeTab === tab
                  ? "bg-white text-gray-900 shadow-sm dark:bg-gray-700 dark:text-gray-100"
                  : "hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-gray-100"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <button
          className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-gray-900 text-white hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-900 dark:hover:bg-gray-200 h-10 px-4 py-2"
          onClick={handleNewPost}
        >
          <FaPlus className="mr-2 h-4 w-4" /> New Post
        </button>
      </div>
      <div className="rounded-md  border-gray-200 dark:border-gray-700">
        <table className="w-full caption-bottom text-sm border ">
          <thead className="[&_tr]:border-b bg-gray-100">
            <tr className="border-b transition-colors hover:bg-gray-50 data-[state=selected]:bg-gray-100 dark:hover:bg-gray-800 dark:data-[state=selected]:bg-gray-900">
              <th className="h-12 px-4 text-left align-middle font-medium text-gray-500 dark:text-gray-400 [&:has([role=checkbox])]:pr-0">
                Title
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-gray-500 dark:text-gray-400 [&:has([role=checkbox])]:pr-0">
                Status
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-gray-500 dark:text-gray-400 [&:has([role=checkbox])]:pr-0">
                Date
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-gray-500 dark:text-gray-400 [&:has([role=checkbox])]:pr-0">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="[&_tr:last-child]:border-0">
            {filteredPosts.map((post) => (
              <tr
                key={post.id}
                className="border-b transition-colors hover:bg-gray-50 data-[state=selected]:bg-gray-100 dark:hover:bg-gray-800 dark:data-[state=selected]:bg-gray-900"
              >
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  {post.title}
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  {getStatus(post)}
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  {post.date}
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  <PostActions
                    blog={post}
                    isOpen={selected === post.id}
                    setSelected={setSelected}
                    onEdit={() => handleEditPost(post, post.id)}
                    onDelete={() => handleDelete(post.id)}
                    onArchive={() => handleArchive(post.id)}
                    onPublish={() => handlePublish(post.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination /> */}
    </div>
  );
}

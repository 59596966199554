import clsx from "clsx";

const variants = {
  variant: {
    default: "bg-zinc-900 text-white shadow-sm hover:bg-zinc-900/90",
    destructive:
      "bg-destructive text-destructive-foreground shadow-xs hover:bg-destructive/90",
    outline:
      "border border-input bg-white shadow-xs hover:bg-zinc-100 hover:text-zinc-900",
    secondary: "bg-zinc-100 text-zinc-900 shadow-xs hover:bg-zinc-100/80",
    ghost: "hover:bg-accent hover:text-accent-foreground",
    link: "text-zinc-900 underline-offset-4 hover:underline",
    primaryBlue: "bg-blue-600 text-white hover: bg-blue-600/80 shadow-sm",
  },
  size: {
    default: "h-9 px-4 py-2",
    sm: "h-8 rounded-md px-3 text-xs",
    lg: "h-10 rounded-md px-8",
    icon: "size-9",
  },
};

const defaultVariants = {
  variant: "default",
  size: "default",
};

const defaultClassName =
  "focus:outline-none inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg:not([class*='size-'])]:size-4 [&_svg]:shrink-0";

export default function NewButton({
  variant,
  size,
  className,
  children,
  onClick,
}) {
  const v = variant
    ? variants.variant[variant]
    : variants.variant[defaultVariants.variant];
  const s = size ? variants.size[size] : variants.size[defaultVariants.size];

  return (
    <button
      onClick={onClick}
      className={clsx(s, v, defaultClassName, className)}
    >
      {children}
    </button>
  );
}

// import { Mail, Phone, Star, WhatsApp } from "@mui/icons-material";
import React, { useEffect, useState, useContext } from "react";
import { BiMapPin, BiMessageSquare } from "react-icons/bi";
import StringIntReq from "../../../../Requests/StringIntReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import VersatileLoader from "../../../../components/VersatileLoader";
import ContactButtons from "../../../../Services/ContactButtons";
import { Star, Mail, Phone, MessageCircle, MapPin, ArrowLeft, Send } from "lucide-react";
import { IoCarOutline, IoPersonOutline, IoTrendingDownSharp } from "react-icons/io5";
import LocalCarWashOutlinedIcon from '@mui/icons-material/LocalCarWashOutlined';
import { FaArrowLeft, FaArrowRight, FaSearch } from "react-icons/fa";
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import { IoIosSearch } from "react-icons/io";
import { MdBolt, MdOutlineDirections } from "react-icons/md";
import { TbMessage } from "react-icons/tb";
import bot from "./../Images/bot.png"
import FiberCompanyApi from "../../../../API/FiberCompanyApi";
// import ContactButtons from "../../../../Services/ContactButtons";

// import { MapContext } from "..";
import { CircularProgress, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import EditCandidateReq from "../../../../Requests/FiberHouse/EditCandidateReq";
import EditFiberHouseReq from "../../../../Requests/FiberHouse/EditFiberHouseReq";
import CustomerApi from "../../../../API/CustomerApi";
import { get } from "sortablejs";
import { FaCarCrash, FaUser, FaUserTie, FaQuestionCircle, FaShieldAlt, FaDollarSign, FaDog } from "react-icons/fa";
import { MdOutlineSecurity, MdOutlineFamilyRestroom, MdPets } from "react-icons/md";
import { IoSearch, IoCalendarOutline, IoChatbubblesOutline } from "react-icons/io5";
import { AiOutlineWarning, AiOutlineClockCircle } from "react-icons/ai";
import { GiFamilyHouse, GiPoliceOfficerHead } from "react-icons/gi";
import { FaTv, FaCar, FaRegHeart, FaBaby, FaChild } from 'react-icons/fa';
import { IoReceiptOutline } from 'react-icons/io5';
import { useSwipeable } from "react-swipeable";

const iconDisplayMapping = {
    3: [],
    16: [
        { component: CarCrashOutlinedIcon, label: "No Car" },
        { component: FaCar, label: "1 Car" },
        { component: FaCar, label: "2 Cars" },
        { component: GiFamilyHouse, label: "Definitely Home" },
    ],
    17: [
        { component: CarCrashOutlinedIcon, label: "No Car" },
        { component: FaCar, label: "1 Car" },
        { component: FaCar, label: "2 Cars" },
        { component: GiFamilyHouse, label: "Definitely Home" },
    ],

    21: [
        { component: FaRegHeart, label: "Talk to Spouse" },
        { component: IoSearch, label: "Research" },
        { component: IoReceiptOutline, label: "Check Bill" },
        { component: FaTv, label: "TV Bundle" },
        { component: MdOutlineSecurity, label: "Security Bundle" },
        { component: FaDollarSign, label: "Paying Less" },
        { component: IoPersonOutline, label: "Fiber Competitor" },
        { component: AiOutlineWarning, label: "Too Busy" },
    ],
    18: [
        { component: FaChild, label: "Kid" },
        { component: FaUser, label: "Elder" },
        { component: FaBaby, label: "Babysitter" },
        { component: FaQuestionCircle, label: "Other" },

    ],
    33: [
        { component: FaRegHeart, label: "Talk to Spouse" },
        { component: IoSearch, label: "Research" },
        { component: IoReceiptOutline, label: "Check Bill" },
        { component: FaTv, label: "TV Bundle" },
        { component: MdOutlineSecurity, label: "Security Bundle" },
        { component: FaDollarSign, label: "Paying Less" },
        { component: IoPersonOutline, label: "Fiber Competitor" },
        { component: AiOutlineWarning, label: "Too Busy" },

    ],
    22: [
        { component: MdOutlineSecurity, label: "TV Bundle" },
        { component: MdOutlineSecurity, label: "Security Bundle" },
        { component: FaDollarSign, label: "Paying Less" },
        { component: FaUser, label: "Fiber Competitor" },
        { component: IoTrendingDownSharp, label: "Negative Reputation" },
        { component: IoReceiptOutline, label: "Company Pays Bill " },
        { component: IoIosSearch, label: "Too Busy" },

    ],
    5: [

        { component: MdOutlineSecurity, label: "TV Bundle" },
        { component: MdOutlineSecurity, label: "Security Bundle" },
        { component: FaDollarSign, label: "Paying Less" },
        { component: IoPersonOutline, label: "Fiber Competitor" },
        { component: AiOutlineWarning, label: "Negative Reputation" },
        { component: IoReceiptOutline, label: "Company Pays Bill" },
        { component: AiOutlineWarning, label: "Too Busy" },
    ],
    6: [

        { component: AiOutlineWarning, label: "Got Aggressive" },
        { component: AiOutlineWarning, label: "Got Violent" },
        { component: FaDog, label: "Dangerous Dogs" },
        { component: FaQuestionCircle, label: "Other" },

    ],
    24: [

        { component: IoCalendarOutline, label: "1 Month" },
        { component: IoCalendarOutline, label: "2 Months" },
        { component: IoCalendarOutline, label: "3 Months" },
        { component: IoCalendarOutline, label: "6 Months" },
        { component: IoCalendarOutline, label: "1 Year +++" },


    ],
    19: [


        { component: IoChatbubblesOutline, label: "Speak Zero Spanish" },
        { component: IoChatbubblesOutline, label: "Speak Little Spanish" },
        { component: IoChatbubblesOutline, label: "Translator Attempted" },

    ],
    23: [],
    31: [],
    39: [

        { component: GiPoliceOfficerHead, label: "Marked Vehicle" },
        { component: GiPoliceOfficerHead, label: "Unmarked Vehicle" },


    ],
    8: [
        { component: FaRegHeart, label: "Talk to Spouse" },
        { component: IoSearch, label: "Research" },
        { component: IoReceiptOutline, label: "Check Bill" },
        { component: FaTv, label: "TV Bundle" },
        { component: MdOutlineSecurity, label: "Security Bundle" },
        { component: FaDollarSign, label: "Paying Less" },
        { component: IoPersonOutline, label: "Fiber Competitor" },
        { component: AiOutlineWarning, label: "Too Busy" },
    ],
    9: [],
    40: [],
    36: [],
    28: [],
    10: [],
    11: [],
    35: [],
    12: [],
};
const MiniLeadCard = ({ openNextNearestFiberHouse, openPreviousNearestFiberHouse, mapIcons, mapObject, fiberHouses, setSelectedFiberHouse, setFiberHouses, user, shouldReRenderMap, isVisible, onClose, children, fiberHouse, selectedFiberHouseFeature, salesReps, boltMode }) => {

    const [lead, setLead] = useState(null)
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState([]);
    const [isQuickNotesOpen, setIsQuickNotesOpen] = useState(true);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState("");
    const [isViewNotesOpen, setIsViewNotesOpen] = useState(false);
    const [selectedMapIcon, setSelectedMapIcon] = useState(null);
    const [coordinates, setCoordinates] = useState(null);

    const [disabled, setDisabled] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 2; // Adjust visible notes per page
    const totalPages = Math.ceil(notes.length / itemsPerPage);
    const maxVisibleDots = 3; // Only show 3 dots at a time

    // Sorting and slicing notes based on pagination
    const sortedNotes = notes.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    const visibleNotes = sortedNotes.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    // Handle Swipe Gestures
    const handlers = useSwipeable({
        onSwipedUp: () => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1)),
        onSwipedDown: () => setCurrentPage((prev) => Math.max(prev - 1, 0)),
        preventScrollOnSwipe: true,
        trackMouse: true,
    });
    // Logic to show a maximum of 3 dots at a time
    let start = Math.max(0, currentPage - 1);
    let end = Math.min(totalPages, start + maxVisibleDots);
    if (end - start < maxVisibleDots) {
        start = Math.max(0, end - maxVisibleDots);
    }
    const visibleDots = Array.from({ length: end - start }, (_, i) => start + i);


    // const [lead] = useState({0
    //     name: "Shasta W.",
    //     address: "910 S White St, Tacoma, WA",
    //     lastContacted: "2 Days Ago",
    // });
    // const map = useContext(MapContext);

    const contactButtons = new ContactButtons();

    const getDisplayComponents = (iconName) => {
        if (lead.fidiumCustomer) {
            //             Sold, Pending lead.fidiumCustomer.accountStatus === "Sold" || "Scheduled" || "Rescheduled"
            // Quick Notes
            // No Quick Notes, just Previous Notes - and/or option to leave a note.
            // Sold, Missing Account lead.fidiumCustomer.isMissing === true
            // No Quick Notes, just Previous Notes - and/or option to leave a note.
            // Sold, Invalidated lead.fidiumCustomer.isInvalid === true
            // No Quick Notes, just Previous Notes - and/or option to leave a note.
            // Sold, Pending Cancel 
            // Instead of Note Section, Add Reason for Cancel 
            // Sold, Canceled  lead.fidiumCustomer.accountStatus === "Canceled"
            // Instead of Note Section, Add Reason for Cancel 

            // Sold, Installed  lead.fidiumCustomer.accountStatus === "Completed"
            // Instead of Note Section, Add 3 Data Points
            // Requested Action w/ Drop Down Arrow
            // Review
            // Referral 
            // Last Action w/ Drop Down Arrow
            // SMS
            // Email
            // Phone
            // Last Result w/ Drop Down Arrow
            // No Reply
            // Not Interested 
            // Will Consider

            // Sold, Installed, Left Feedback (Note to Dev: 1-3 Stars; Non-Google Review)  lead.fidiumCustomer.accountStatus === "Completed" && lead.fidiumCustomer.customerFeedbacks!=null
            // Instead of Note Section, Add Negative Feedback

            //  Sold, Installed, Left Google Review (Note to Dev: 4 or 5 Stars; Google Review) 
            // Instead of Note Section, Add 3 Data Points
            // Requested Action w/ Drop Down Arrow
            // Review
            // Referral 
            // Last Action w/ Drop Down Arrow
            // SMS
            // Email
            // Phone
            // Last Result w/ Drop Down Arrow
            // No Reply
            // Not Interested 
            // Will Consider
            // Sold, Installed, Yes or No Review, Referral Partner 
            // Instead of Note Section, Add 3 Data Points
            // Requested Action w/ Drop Down Arrow
            // Review
            // Referral 
            // Last Action w/ Drop Down Arrow
            // SMS
            // Email
            // Phone
            // Last Result w/ Drop Down Arrow
            // No Reply
            // Not Interested 
            // Will Consider
            // Sold, Installed, Yes Review, Yes Referral Partner 
            // Instead of Note Section, Add 3 Data Points
            // Requested Action w/ Drop Down Arrow
            // Review
            // Referral 
            // Last Action w/ Drop Down Arrow
            // SMS
            // Email
            // Phone
            // Last Result w/ Drop Down Arrow
            // No Reply
            // Not Interested 
            // Will Consider
            if (lead.fidiumCustomer.accountStatus === "Sold" || lead.fidiumCustomer.accountStatus === "Scheduled" || lead.fidiumCustomer.accountStatus === "Rescheduled") {
                return iconDisplayMapping[9]
            }
            else if (lead.fidiumCustomer.isMissing === true) {
                return iconDisplayMapping[40]
            }
            else if (lead.fidiumCustomer.isInvalid === true) {
                return iconDisplayMapping[36]
            }
            else if (lead.fidiumCustomer.accountStatus === "Canceled") {
                return iconDisplayMapping[28]
            }
            else if (lead.fidiumCustomer.accountStatus === "Completed") {
                return iconDisplayMapping[10]
            }
            else if (lead.fidiumCustomer.accountStatus === "Completed" && lead.fidiumCustomer.customerFeedbacks != null) {
                return iconDisplayMapping[11]
            }
        }
        else
            return iconDisplayMapping[iconName] || [];

    };
    const getLeadData = async () => {
        setLoading(true)

        const req = new StringIntReq()
        req.int = fiberHouse.id;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
        if (res?.status?.success) {
            console.log("Lead", res.data)
            setLead(res.data);
            setLoading(false);
        }

    }


    useEffect(
        () => {
            if (lead) {
                if (boltMode) {
                    let shouldDisable = true;
                    if (user.userType === "SuperAdmin") {
                        shouldDisable = false;
                    }
                    else {
                        shouldDisable = true;
                        if (lead?.fiberHouse?.salesRepFiberHouses.some(
                            (salesRep) => salesRep.salesRepId === user.id
                        )) {
                            shouldDisable = false;
                        }
                    }
                    setDisabled(shouldDisable);
                }
                else {
                    setDisabled(false);
                }
            }
        }, [boltMode, lead]
    )

    const getFiberCompanies = async () => {
        let req = {
            hasOfferTemplate: true,
            pagingParams: {
                pageNumber: 1,
                pageSize: 100
            }
        }
        const res = await FiberCompanyApi.SearchFiberCompany(req)
        if (res?.status?.success) {
            setCompanies(res.data.list)
            console.log("Companies", res.data.list)
        }


    }


    const changeMapIcon = async (mapIcon) => {

        console.log("mapIcon", mapIcon)

        if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === fiberHouse.id) {
            console.log("Fiber House Feature Found")
            const newStyle = new Style({
                image: new Icon({
                    scale: 1.5, // Adjust this value to change the scale of the icon
                    src: "data:image/png;;base64," + mapIcon?.image,
                }),
            });

            const circleStyle = new Style({
                image: new CircleStyle({
                    radius: 15, // This is now in pixels
                    stroke: new Stroke({
                        color: "black", // Border color
                        width: 4, // Border width
                    }),
                    fill: new Fill({
                        color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                }),
            });
            selectedFiberHouseFeature.setStyle([
                newStyle, // This is the icon style
                circleStyle, // This is the circle style
            ]);
            selectedFiberHouseFeature.values_.mapIconId = mapIcon.id
            console.log(mapIcon.id)
            console.log(selectedFiberHouseFeature)

            mapObject.getView().setZoom(
                mapObject.getView().getZoom() + 0.000001
            )






        }
        // shouldReRenderMap.current = false;
        const index = binarySearchFiberHouses(fiberHouses, fiberHouse.id);
        console.log(index)
        const fh = fiberHouses
        if (index !== -1) {
            fh[index].mapIconId = mapIcon.id;
            setFiberHouses([...fh]);
        }
        else {
            const fh = fiberHouses.map((fiberHouse) => {
                if (fiberHouse.id === fiberHouse.id) {
                    return {
                        ...fiberHouse,
                        mapIconId: mapIcon.id
                    }
                }
                return fiberHouse;
            });
            setFiberHouses([...fh]);
        }
        navigator.geolocation.getCurrentPosition((position) => {
            let coordinates = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            editMapIcon(mapIcon, coordinates)
        })
        setSelectedFiberHouse(
            {
                ...fiberHouse,
                mapIconId: mapIcon.id
            }
        )
    }

    const editMapIcon = async (mapIcon, position) => {


        const req = new EditFiberHouseReq()
        req.id = fiberHouse.id

        req.coordinate = null
        req.mapIconId = mapIcon.id
        req.salesRepId = user?.id
        req.repCoordinates = position
        FiberHouseApi.EditFiberHouse(req).then(
            (res) => {
                if (res?.status?.success) {

                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        toast: true,
                        timer: 1500,
                        position: "bottom-end",
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        )
    }


    function binarySearchFiberHouses(fiberHouses, targetId) {
        let left = 0;
        let right = fiberHouses.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midId = fiberHouses[mid].id;

            if (midId < targetId) {
                left = mid + 1;
            } else if (midId > targetId) {
                right = mid - 1;
            } else {
                return mid; // Found the target, return its index
            }
        }

        return -1; // Target not found
    }

    const GetQuickNotes = async () => {
        let req = {
            LeadId: lead.id
        }
        const res = await CustomerApi.GetQuickNotes(req)
        if (res?.status?.success) {
            console.log("Quick Notes", res.data)
            setNotes(res.data)
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                toast: true,
                timer: 1500,
                position: "bottom-end",
                didOpen: () => {
                    Swal.hideLoading()
                }
            });

        }
    }


    useEffect(() => {
        // GetQuickNotes();
        getFiberCompanies()
    }, [])

    useEffect(
        () => {
            getLeadData()
        }, [fiberHouse]
    )


    if (!isVisible) return null;
    const name = "JOHN WILSON"
    const initial = name.charAt(0)

    const handleQuickNoteClick = async (item) => {
        console.log(item)
        let req = {
            Note: item,
            LeadId: lead.id
        }

        const res = await CustomerApi.CreateQuickNote(req)

        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Quick Note Added',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                toast: true,
                timer: 1500,
                position: "bottom-end",
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                toast: true,
                timer: 1500,
                position: "bottom-end",
                didOpen: () => {
                    Swal.hideLoading()
                }
            });

        }

    }


    const lightningBolts = [
        "Buy1",
        "Inst1",
        "Inst2",
        "Inst3",
        "CNL",
        "PC",
        "Inst4",
        "IC",
        "OSL",
        "MA"
    ]

    return (

        loading ?
            <div className="w-full sm:max-w-xs max-w-[250px] bg-white rounded-3xl shadow-lg p-6">
                <VersatileLoader color="#2761D0" size="medium" />
            </div>
            :
            <div className="sm:max-w-xs max-w-[250px] bg-white rounded-3xl shadow-lg p-3 relative">

                {/* Header */}
                <div className="flex justify-between ">

                    {/* Left: Avatar & Name */}
                    <div className="flex items-center gap-3">
                        <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center text-xl font-bold">
                            <img src={"data:image/png;base64," + mapIcons?.find(icon => icon.id === fiberHouse.mapIconId)?.image} alt="Icon" className="w-12 h-12" />
                        </div>
                        {
                            !disabled &&
                            <div>
                                {
                                    // (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                    salesReps?.filter(rep => lead?.fiberHouse?.salesRepFiberHouses.some(salesRep => salesRep.salesRepId === rep.id)).map((rep, index) => {
                                        return (
                                            <div className="flex gap-1 items-center ml-0" key={index}>
                                                <MdBolt style={{ color: "#a0a4ac" }} size={30} />
                                                <p className="sm:text-base text-xs">{rep.firstName + " " + rep.lastName.charAt(0)}</p>
                                            </div>
                                        )
                                    })
                                }
                                <h4 className="font-bold sm:text-md text-sm">{
                                    lead?.name ? lead?.name : "No Name"

                                }</h4>
                                {/* Rating */}
                                <div className="flex gap-1 mt-1 mb-2">
                                    {[...Array(5)].map((_, i) => (
                                        <Star key={i} className="w-5 h-5 text-gray-400 fill-gray-300" />
                                    ))}
                                </div>
                                <div className="flex items-center gap-1 text-gray-500 text-sm">
                                    <MapPin className="w-4 h-4 text-blue-500" />
                                    <Tooltip title={lead?.fiberHouse?.mapString || ''}>
                                        <span className=" w-24 truncate">
                                            {lead?.fiberHouse?.mapString}</span>
                                    </Tooltip>
                                </div>
                            </div>}
                    </div>

                    {/* Right: Robot Icon */}
                    {/* <img src={bot} alt="Robot" className="h-10" /> */}
                </div>



                {/* Company Logos */}

                {
                    lead?.fidiumCustomer?.fiberCompanyId &&
                    <div className="flex gap-4 mt-4">
                        {/* show the customer's company logo */}
                        <img className="h-10"
                            src={"data:image/png;base64," + companies?.find(company => company.id === lead?.fidiumCustomer.fiberCompanyId)?.logo}
                            alt={lead?.fiberCompany?.name}
                        />
                    </div>
                }

                { /*<div className="flex gap-4 mt-4 overflow-x-auto"
                    style={{
                        scrollbarWidth: "none",
                        // msOverflowStyle: "none",
                        // "&::-webkit-scrollbar": {
                        //     display: "none"
                        // }
                    }}
                >
                    {
                        companies?.map((company, index) => {
                            return (
                                <fieldset className={`${lead.fiberCompanyId == company.id && "border-2 border-[#0038FF]"} p-2 rounded-lg`}>
                                    {
                                        lead.fiberCompanyId == company.id ?
                                            <legend className="text-xs bg-[#0038FF] p-1 rounded-3xl  text-white">primary</legend>
                                            :
                                            <legend className="text-xs p-1 rounded-3xl  text-white">legend </legend>
                                    }
                                    <img className={`min-w-16 h-7`}
                                        key={index}
                                        src={"data:image/png;base64," + company?.logo}
                                        alt={company.name}
                                    // className="h-7"
                                    />
                                </fieldset>
                            )
                        })

                    }

                </div>*/}



                {/* offers */}

                {

                    (lead?.fidiumCustomer == null && lead?.offer != null) ?


                        <div className="flex justify-start gap-2 mt-4 items-center">
                            <fieldset className="border border-gray-200 p-2 rounded-lg w-1/3">
                                <legend className="sm:text-xs text-[8px] text-center text-gray-500" position="center">Offer Sent</legend>
                                <h2 className="sm:text-sm text-[10px] text-center font-bold text-gray-700 m-auto">
                                    {lead?.offer?.timeStamp ?
                                        new Date(lead?.offer?.timeStamp).toLocaleDateString() : "No Date"

                                    }

                                </h2>


                            </fieldset>
                            <fieldset className="border border-gray-200 p-2 rounded-lg  w-1/3" >
                                <legend className=" text-gray-500 sm:text-xs text-[8px] text-center"> Opened</legend>
                                <h2 className="sm:text-sm text-[10px] text-center font-bold text-gray-700 m-auto">
                                    {lead?.offer?.opened == true ? "Yes" : "No"}
                                </h2>
                            </fieldset>

                            <fieldset className="border border-gray-200 p-2 rounded-lg w-1/3" >
                                <legend className="sm:text-xs text-[8px] text-center text-gray-500">Follow Up</legend>
                                <h2 className="sm:text-sm text-[10px] text-center font-bold text-gray-700 m-auto">
                                    {lead?.appointmentDateString ?
                                        new Date(lead?.appointmentDateString).toLocaleDateString() : "No Appt"
                                    }
                                </h2>
                            </fieldset>


                        </div>


                        : null


                }
                {
                    (lead?.fidiumCustomer != null) ?

                        <div className="flex justify-start gap-2 mt-4 items-center">
                            <fieldset className="border border-gray-200 p-2 rounded-lg w-1/3">
                                <legend className="sm:text-xs text-[8px] text-center text-gray-500" >Fiber Plan</legend>
                                <Tooltip title={lead?.fidiumCustomer?.fiberPlan?.name || ''}>
                                    <h2 className="sm:text-sm text-[10px]  text-center font-bold text-gray-700 m-auto w-full truncate">
                                        {lead?.fidiumCustomer?.fiberPlan?.name || "No Plan"}
                                    </h2>
                                </Tooltip>


                            </fieldset>
                            <fieldset className="border border-gray-200 p-2 rounded-lg  w-1/3" >
                                <legend className=" text-gray-500 text-center sm:text-xs text-[8px]">Status</legend>
                                <h2 className="sm:text-sm text-[10px]  text-center font-bold text-gray-700 m-auto">
                                    {lead?.fidiumCustomer?.accountStatus}
                                </h2>
                            </fieldset>

                            <fieldset className="border border-gray-200 p-2 rounded-lg  w-1/3" >
                                <legend className=" text-gray-500 text-center sm:text-xs text-[8px]"> Install Date</legend>
                                <h2 className="sm:text-sm text-[10px]  text-center font-bold text-gray-700 m-auto">
                                    {lead?.fidiumCustomer?.installDateTime ? new Date(lead?.fidiumCustomer?.installDateTime).toLocaleDateString() : "No Date"}
                                </h2>
                            </fieldset>
                        </div>
                        : null
                }



                {/* Lead Status */}
                <div className="mt-4">
                    <div className="flex items-center justify-between">
                        <h3 className="font-bold text-gray-700">Lead Status</h3>
                        {/* <p className="text-sm text-gray-500">
                            Last Contacted | <span className="text-blue-600 font-semibold"> 2 days ago</span>
                        </p> */}
                    </div>


                    <div className="flex gap-2 mt-2 overflow-x-auto"
                        style={{
                            scrollbarWidth: "none",
                        }}
                    >
                        {
                            mapIcons?.length > 0 ?
                                <div className="flex gap-2">
                                    {mapIcons.filter(icon => icon.iconType === null && !lightningBolts.includes(icon.abbreviation))
                                        .map((icon, index) => {
                                            if (icon.iconType == null) {
                                                return (
                                                    <div onClick={() => {

                                                        if (disabled) {
                                                            return
                                                        }
                                                        if (lead?.fidiumCustomer) {
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: "Cannot change map icon for a sold lead",
                                                                allowOutsideClick: false,
                                                                allowEscapeKey: false,
                                                                allowEnterKey: false,
                                                                showConfirmButton: true,
                                                                toast: true,
                                                                timer: 1500,
                                                                position: "bottom-end",
                                                                didOpen: () => {
                                                                    Swal.hideLoading()
                                                                }
                                                            });

                                                            return
                                                        }


                                                        changeMapIcon(icon)
                                                    }} className={` p-3 flex flex-col items-center w-16 text-gray-700 border ${fiberHouse.mapIconId === icon.id && "border-[#0038FF] border-2"} rounded-lg`}
                                                        key={index}>
                                                        <img alt='map_icon' style={{
                                                            width: "2rem",
                                                            height: "2rem"
                                                        }} src={"data:image/png;base64," + icon.image} />
                                                        <p className="text-xs">{icon.abbreviation}</p>
                                                    </div>
                                                )
                                            }
                                        })}

                                </div>
                                :
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    width: "100%"

                                }}>
                                    <CircularProgress size={50} sx={{
                                        color: "var(--primary-color)"
                                    }} />
                                </div>
                        }


                    </div>
                </div>

                {/* Quick Notes */}
                <div className="mt-4">
                    <div className="flex justify-between items-center">
                        <h3 className="font-bold sm:text-sm text-[12px] text-gray-700">Quick Notes</h3>
                        <div className="flex justify-between gap-1">
                            <button
                                onClick={() => {
                                    if (disabled) {
                                        return
                                    }
                                    setIsQuickNotesOpen(false)
                                    setIsViewNotesOpen(false)
                                }}
                                className="border rounded-lg text-gray-600 text-xs p-1">+ Create Notes</button>
                            {isQuickNotesOpen ? (
                                <button
                                    onClick={() => {
                                        if (disabled) {
                                            return
                                        }
                                        setIsQuickNotesOpen(false)
                                        setIsViewNotesOpen(true)
                                        GetQuickNotes()
                                    }}
                                    className="border rounded-lg text-gray-600 text-xs p-1">
                                    View Notes
                                </button>
                            ) : (
                                <button onClick={() => {
                                    if (disabled) {
                                        return
                                    }
                                    setIsQuickNotesOpen(true)
                                    setIsViewNotesOpen(false)
                                }} className="border rounded-lg text-gray-600 text-xs p-1">
                                    Quick Notes
                                </button>
                            )}
                        </div>
                    </div>
                    {isQuickNotesOpen && (
                        <div className="flex gap-3 mt-1 overflow-x-auto">

                            {mapIcons?.map((icon, index) => (
                                icon.id === fiberHouse?.mapIconId ? (
                                    icon.name === "Cancelled" || icon.name === "Pending Cancel" ? (
                                        <p className="p-3 flex flex-col items-center w-full text-gray-700 border border-gray-200 rounded-lg">
                                            {lead?.fidiumCustomer?.issueDescription}
                                        </p>
                                    )


                                        :
                                        getDisplayComponents(icon.id)?.map((item, idx) => (
                                            <button
                                                onClick={() => {
                                                    handleQuickNoteClick(item.label)
                                                }}
                                                key={`${index}-${idx}`} className="p-3 flex flex-col items-center w-20 text-gray-700">
                                                <item.component className="w-5 h-5" />
                                                <span className="text-sm mt-1">{item.label}</span>
                                            </button>
                                        ))
                                ) :
                                    null

                            ))}
                        </div>
                    )}
                    {isViewNotesOpen && (

                        <div {...handlers}
                            className="relative mt-2 w-full rounded-lg p-2 text-gray-600 border border-gray-200 h-24 overflow-y-auto"
                            style={{ scrollbarWidth: "none" }}
                        >
                            {
                                visibleNotes.map((note, index) => (
                                    <Tooltip key={index} title={`${new Date(note.timestamp).toLocaleDateString()} @ ${new Date(note.timestamp).toLocaleTimeString()} | ${note.note}`}>
                                        <div className="border-b border-gray-200 p-2 h-8 overflow-hidden whitespace-nowrap">
                                            {new Date(note.timestamp).toLocaleDateString()} @ {new Date(note.timestamp).toLocaleTimeString()} | {note.note}
                                        </div>
                                    </Tooltip>
                                ))
                            }

                            {/* Vertical Pagination Dots (Max 3 Visible) */}
                            {totalPages > 1 && (
                                <div className="absolute top-1/2 right-2 transform -translate-y-1/2 flex flex-col gap-1">
                                    {visibleDots.map((index) => (
                                        <span
                                            key={index}
                                            className={`h-2 w-2 rounded-full ${index === currentPage ? "bg-blue-600" : "bg-blue-300"}`}
                                            onClick={() => setCurrentPage(index)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    {
                        !isQuickNotesOpen && !isViewNotesOpen && (
                            <div className="mt-2 w-full  rounded-lg p-2 border-gray-200 h-16 ">
                                <textarea
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    className="w-full h-full border-none outline-none" placeholder="Type your notes here..."></textarea>

                                <button
                                    onClick={() => {
                                        if (disabled) {
                                            return
                                        }
                                        handleQuickNoteClick(note)
                                        setNote("")
                                        setIsQuickNotesOpen(true)
                                    }}
                                    className="bg-blue-600 text-white rounded-full text-lg  
                                absolute bottom-20 right-10 px-2 ">+</button>
                            </div>
                        )
                    }


                </div>



                {/* Action Buttons */}
                <div className="flex justify-between items-center mt-1">

                    <button
                        onClick={() => { openPreviousNearestFiberHouse() }}
                        className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
                        <FaArrowLeft className="w-5 h-5 text-gray-600" />
                    </button>
                    <button
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            contactButtons.smsLead(lead)
                        }}


                        className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
                        <TbMessage className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            contactButtons.emailLead(lead)
                        }}
                        style={{
                            cursor: lead?.fidiumCustomer?.email == null ? "not-allowed" : "pointer",
                            backgroundColor: lead?.fidiumCustomer?.email == null ? "red !important" : ""
                        }}
                        className="p-2  rounded-full hover:bg-gray-200 border border-gray-200">
                        <Mail className="w-5 h-5 text-gray-600" />
                    </button>
                    <button
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            contactButtons.callLead(lead)
                        }}
                        style={
                            {
                                cursor: lead?.fidiumCustomer?.cellPhone != null ? "pointer" : "not-allowed",
                                color: lead.fidiumCustomer?.cellPhone ? "red !important" : ""
                            }
                        }
                        className="p-2  rounded-full hover:bg-gray-200 border border-gray-200">
                        <Phone className="w-5 h-5 text-gray-600" />
                    </button>
                    <button
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            contactButtons.directionsLead(lead)
                        }}
                        className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
                        <MdOutlineDirections className="w-5 h-5 text-gray-600" />
                    </button>
                    <button onClick={() => {
                        openNextNearestFiberHouse()
                    }} className="p-2  rounded-full hover:bg-gray-200 border border-gray-200">
                        <FaArrowRight className="w-5 h-5 text-gray-600" />
                    </button>
                </div>
            </div>

    );
};

export default MiniLeadCard;

import React, { useContext, useEffect, useRef, useState } from "react";
import SellingCardApi from "../../../../API/SellingCardApi";
import Swal from "sweetalert2";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import StringIntReq from "../../../../Requests/StringIntReq";
import VersatileLoader from "../../../../components/VersatileLoader";
import EditorJS from "@editorjs/editorjs";  
import { getEditorJsTools } from "../constants/constant";
import { UserContext } from "../../../../App";

const columns = [
  {
    width: 100,
    label: "User",
    dataKey: "user",
    align: "center",
  },
  {
    width: 200,
    label: "Timestamp",
    dataKey: "timestamp",
    align: "center",
  },
  {
    width: 150,
    label: "Actions",
    dataKey: "actions",
    align: "center",
  },
  {
    width: 150,
    label: "Delete",
    dataKey: "delete",
    align: "center",
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead sx={{ width: "100%", display: "table-header-group" }} {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow style={{ width: "100%" }}>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.align}
          style={{ width: column.width }}
          sx={{ backgroundColor: "background.paper" }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}
const showSubmission = (setEditorData, data) => {
  console.log({data})
  const blocks = data.map((block) => {
    return {
      id: block.id.toString(),
          type: 'quiz',
          data: JSON.parse(block.data),
      };
  });
  console.log({blocks})
  const editorData = {
    time: 1550476186479,
    blocks: blocks ,
    version: "2.8.1",
  };
  setEditorData(editorData);
};
function rowContent(_index, row, setReload, reload, setEditorData) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.align}
        >
          {column.dataKey === "actions" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => showSubmission(setEditorData, row.quizAnswers)}
            >
              View Answers
            </Button>
          ) : column.dataKey === "delete" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteSubmission(row.id, setReload, reload)}
            >
              Delete
            </Button>
          ) : column.dataKey === "timestamp" ? (
            new Date(row[column.dataKey]).toLocaleString()
          ) : (
            row[column.dataKey].firstName + " " + row[column.dataKey].lastName
          )}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

const deleteSubmission = async (submissionId, setReload, reload) => {
  try {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      const req = new StringIntReq();
      req.int = submissionId;
      const response = await SellingCardApi.DeleteQuizSubmission(req);
      if (response.status.success) {
        Swal.fire("Deleted!", "The submission has been deleted.", "success");
        setReload(!reload);
      } else {
        Swal.fire("Error", response.status.message, "error");
      }
    }
  } catch (error) {
    Swal.fire("Error", error.message, "error");
  }
};

const QuizSubmissionView = ({ quizId }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const editorRef = useRef(null);
  const user = useContext(UserContext);
  const [editorData, setEditorData] = useState(null);

  useEffect(() => {
    if(editorData){
      if(editorRef.current){
        if(editorRef.current.destroy)
        {
          editorRef.current.destroy(); 
        }
        editorRef.current = null;
        
      }
      initializeEditor();
    }
    
  }, [editorData]);

  const initializeEditor = async () => {
    if (editorRef.current) {
      if (editorRef.current.destroy) {
        editorRef.current.destroy();
      }
      editorRef.current = null;
    }
    
    const tools ={
            // paragraph:false,
            quiz: getEditorJsTools(user, true).quiz,
            question: getEditorJsTools(user, true).question,
            // image: getEditorJsTools(user).image,
            // video: getEditorJsTools(user).video,
          };

    editorRef.current = new EditorJS({
      holder: "submission",
      tools: tools,
      data: editorData,
  
      readOnly: true,
    });
  };

  const fetchSubmissions = async () => {
    setLoading(true);
    try {
      const req = new StringIntReq();
      req.int = quizId;
      const response = await SellingCardApi.GetQuizSubmissions(req);
      if (response.status.success) {
        setSubmissions(response.data);
      } else {
        Swal.fire("Error", response.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubmissions();
  }, [quizId, reload]);

  return (
     loading ? (
      <div className="spinner">
          <VersatileLoader color="#2761D0" size="large" />
        </div>
      ) :( editorData ? <div id="submission" className="bg-white overflow-y-auto w-full flex-1 p-4 rounded-lg h-full transition-all duration-300 flex flex-col justify-between pt-0" style={{height:"80vh"}}></div> :
    <Paper style={{ height: 400, width: "100%" }}>
      <TableVirtuoso
        data={submissions}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, row) => rowContent(index, row, setReload,reload, setEditorData)}
      />
    </Paper> )
  );
};

export default QuizSubmissionView;

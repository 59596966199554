import { Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import UserSelector from "./../../../components/UserSelector";
import { UserContext } from "../../../App";

const ExportToWorksheetModal = ({ onExport, open, setOpen }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [salesRepId, setSalesRepId] = useState("");
  const [listType, setListType] = useState("");
  const user = useContext(UserContext);
  const handleExport = () => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "We are exporting the data to the worksheet",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (name === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Name is required",
      });
      return;
    }

    if (listType === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "List Type is required",
      });
      return;
    }

    onExport(name, description, [...salesRepId, user?.id], listType);
    setOpen(false);
  };

    return (
        <Modal open={open} onClose={() => {
            setOpen(false)
        }}>
            <div style={{
                width: "min(500px,80%)"
            }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg'>
                <div className='flex flex-col gap-4'>
                    <h1 className='text-2xl font-bold text-center'>Export to Worksheet</h1>
                    <input type="text" placeholder="Name*" value={name} onChange={(e) => setName(e.target.value)} className='w-full p-2 border border-gray-300 rounded-lg mt-4' />
                    <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} className='w-full p-2 border border-gray-300 rounded-lg mt-4' />
                    {
                        (user?.userType === 'SuperAdmin' || (user.userType==="SalesOrgAdmin")) &&
                        <UserSelector placeholder='Select Sales Reps' setUser={setSalesRepId} conversationType={'Group'} userTypes={["SalesRep", "SalesOrgAdmin"]} />
                    }
                    <select value={listType} onChange={(e) => setListType(e.target.value)} className='w-full p-2 border border-gray-300 rounded-lg mt-4'>
                        <option value="">Type</option>
                        <option value="Static">Static</option>
                        <option value="Dynamic">Live</option>
                    </select>
                    <div className='flex gap-4 mt-4'>
                        <button onClick={() => {
                            handleExport()
                        }} className='bg-blue-500 text-white p-2 rounded-lg w-full'>Export</button>
                        <button onClick={() => {
                            setOpen(false)
                        }} className='bg-gray-200 text-black p-2 rounded-lg w-full'>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ExportToWorksheetModal;

import React, { useContext, useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import { gapi } from 'gapi-script';
import { MapContext } from '../..'
import { Overlay } from 'ol'

const AppointmentForm = ({ open, setOpen, lead, setReload }) => {

    const [appointmentDateTime, setAppointmentDateTime] = useState(lead?.appointmentDateTime)
    const [appointmentNotes, setAppointmentNotes] = useState(lead?.appointmentNotes)
    const {selectedFiberHouseFeature,setSelectedFiberHouse,setSelectedFiberHouseFeature,setCalendarPopup,setIndex,mapObject} = useContext(MapContext)

    const generateICalEvent = () => {
        const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
        const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
        const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:${lead?.name} Appointment
        DTSTART:${start}
        DTEND:${end}
        DESCRIPTION:Appointment Details
        LOCATION:Appointment Location
        END:VEVENT
        END:VCALENDAR
    `;
    
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'appointment.ics';
        link.click();
    
        Swal.fire('iCalendar Event Generated!');
    };

    const addGoogleCalendarEvent = () => {
        const base = 'https://calendar.google.com/calendar/r/eventedit?';
        
        const startTime = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d\d\d/g, '');
        const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    
        // Creating URL parameters
        const params = new URLSearchParams({
            text: `${lead.name} Appointment`,  
            dates: `${startTime}/${endTime}`,  
        });
    

        return `${base}${params.toString()}`;
    };
    

    const handleAddToCalendar = () => {
        const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
        window.open(googleCalendarUrl, '_blank', 'noopener,noreferrer'); 
    };
    

    const promptAddToCalendar = async () => {
        const { value } = await Swal.fire({
            title: 'Add Appointment to Calendar',
            text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Google Calendar',
            denyButtonText: 'iCalendar',
            cancelButtonText: 'No, thanks',
        });
    
        if (value === true) {
            // User chose Google Calendar
            handleAddToCalendar(appointmentDateTime);
        } else if (value === false) {
            // User chose iCalendar
            generateICalEvent();
        }
    };


    const updateAppointmentDate = async () => {
        setOpen(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })
        const req = new UpdateLeadAppointmentReq();
        req.leadId = lead.id;
        req.appointmentDateTime = appointmentDateTime;
        req.appointmentType = null;
        req.appointmentNotes = appointmentNotes;
        const res = await FiberHouseApi.UpdateLeadAppointment(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            promptAddToCalendar();
            setReload(
                (prev) => {
                    return !prev
                }
            )

            if(selectedFiberHouseFeature){
                selectedFiberHouseFeature.set('appointment', appointmentDateTime)
                const iconElement = document.createElement('div');
                iconElement.innerHTML = '📅'; // Calendar emoji
                iconElement.style.cssText = `
                  cursor: pointer;
                  font-size: 20px;
                  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
                `;
              
                // Create overlay for the appointment
                const appointmentOverlay = new Overlay({
                  element: iconElement,
                  positioning: 'bottom-center',
                  stopEvent: true, // Allow interaction
                  offset: [10, 25],
                  position: selectedFiberHouseFeature.getGeometry().getCoordinates(),
                  
                });
              
                // Add click handler to the icon
                iconElement.addEventListener('click', (e) => {
                  e.stopPropagation();
                  setSelectedFiberHouse(
                    {
                      id: selectedFiberHouseFeature.get("id"),
                      mapIconId: selectedFiberHouseFeature.get("mapIconId"),
                    }
                  )
                  setIndex(-1);
                  
                  mapObject.getView().setCenter(selectedFiberHouseFeature.getGeometry().getCoordinates());
                  setCalendarPopup(true);
                  // Add your click handler logic here
                });
              
                mapObject.addOverlay(appointmentOverlay);
            }

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    return (
        <Modal setOpen={setOpen} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='custom_modal'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                <h1 >Appointment Date</h1>
                <input type="datetime-local" value={appointmentDateTime} onChange={(e) => {
                    setAppointmentDateTime(e.target.value)
                }} />
                <h1>Appointment Notes</h1>
                <textarea className="w-full border-2 rounded-lg p-2" rows={4} value={appointmentNotes} onChange={(e) => {
                    setAppointmentNotes(e.target.value)
                }} />
                <button
                    onClick={() => {
                        updateAppointmentDate()
                    }
                    }>Update</button>
            </div>
        </Modal>
    )
}

export default AppointmentForm
import React, { useState } from "react";
import Swal from "sweetalert2";
import UpdateLeadAppointmentReq from "../../../../Requests/Customer/UpdateLeadAppointmentReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import authorized from "../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png";
import authorizedFastwyre from "../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png";

const ScheduleFollowUp = ({ lead }) => {

  const [appointmentDateTime, setAppointmentDateTime] = useState(
    lead?.appointmentDateTime
  );

  const generateICalEvent = (appointmentDateTime) => {
    const start = new Date(appointmentDateTime)
      .toISOString()
      .replace(/-|:|\.\d+/g, "");
    const end = new Date(
      new Date(appointmentDateTime).getTime() + 60 * 60 * 1000
    )
      .toISOString()
      .replace(/-|:|\.\d+/g, ""); // Adding 1 hour to end time
    const icsContent = `
      BEGIN:VCALENDAR
      VERSION:2.0
      BEGIN:VEVENT
      SUMMARY:Consultation
      DTSTART:${start}
      DTEND:${end}
      DESCRIPTION:Appointment Details
      LOCATION:Appointment Location
      END:VEVENT
      END:VCALENDAR
  `;

    const blob = new Blob([icsContent], { type: "text/calendar" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "appointment.ics";
    link.click();

    Swal.fire("iCalendar Event Generated!");
  };

  const addGoogleCalendarEvent = (appointmentDateTime) => {
    const base = "https://calendar.google.com/calendar/r/eventedit?";

    const startTime = new Date(appointmentDateTime)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const endTime = new Date(
      new Date(appointmentDateTime).getTime() + 60 * 60 * 1000
    )
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");

    // Creating URL parameters
    const params = new URLSearchParams({
      text: `Consultation`,
      dates: `${startTime}/${endTime}`,
    });

    return `${base}${params.toString()}`;
  };

  const handleAddToCalendar = (appointmentDateTime) => {
    const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
    window.open(googleCalendarUrl, "_blank", "noopener,noreferrer");
  };

  const promptAddToCalendar = async (appointmentDateTime) => {
    const { value } = await Swal.fire({
      title: "Add Appointment to Calendar",
      text: "Would you like to add this appointment to Google Calendar or iCalendar?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Google Calendar",
      denyButtonText: "iCalendar",
      cancelButtonText: "No, thanks",
    });

    if (value === true) {
      // User chose Google Calendar
      handleAddToCalendar(appointmentDateTime);
    } else if (value === false) {
      // User chose iCalendar
      generateICalEvent(appointmentDateTime);
    }
  };

  const updateAppointmentDate = async () => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Updating Appointment Date",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    const req = new UpdateLeadAppointmentReq();
    req.leadId = lead.id;
    req.appointmentDateTime = appointmentDateTime;
    req.appointmentType = null;
    const res = await FiberHouseApi.CreateConsultation(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Appointment Date Updated Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willClose: () => {
          Swal.hideLoading();
        },
      });

      promptAddToCalendar(appointmentDateTime);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  return (
    <div className="max-w-xl mx-auto p-8 bg-white">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Schedule Follow-Up Date</h1>

      <div className="space-y-6">
        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">Select Date and Time</label>
          <input
            type="datetime-local"
            value={appointmentDateTime}
            onChange={(e) => setAppointmentDateTime(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
        </div>

        <button
          onClick={updateAppointmentDate}
          className="w-full bg-blue-500 text-white py-3 px-6 rounded-xl font-medium text-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Schedule
        </button>
      </div>
    </div>
  );
};

export default ScheduleFollowUp;

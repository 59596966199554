import React, { useEffect, useState, useCallback } from 'react';
import { 
  List, 
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  Typography, 
  Chip,
  Collapse,
  Divider,
  CircularProgress,
  TextField,
  Pagination,
  Button,
  Grid,
  Box,
  Skeleton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { ExpandMore, ExpandLess, Edit, CheckCircleOutline, Search, Person } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { alpha } from '@mui/material/styles';
import './styles/SalesRepActivityHistory.scss';
import MapIconApi from '../../../../../API/MapIconApi';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const SalesRepActivityHistory = ({ salesRepId, currentSalesRepDate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedId, setExpandedId] = useState(null);
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  const getActivityIcon = (activityType) => {
    const iconStyle = { fontSize: 20 };
    switch(activityType) {
      case 'Lead Edit': return <Edit sx={iconStyle} />;
      case 'Lead Converted': return <CheckCircle sx={iconStyle} />; // Using CheckCircle
      default: return <Person sx={iconStyle} />;
    }
  };

  const parseNoteChanges = (notes) => {
    if (!notes) return null;
    return notes.split(/!*\*!/).filter(p => p)?.map((part, i) => {
      const [label, ...changes] = part.split(':')?.map(s => s.trim());
      return (
        <Box component="div" key={i} sx={{ mb: 1.5 }}>
          <Typography variant="subtitle2" component="strong" color="text.primary">
            {label}
          </Typography>
          {changes?.map((change, j) => (
            <Typography 
              key={j} 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                ml: 2,
                position: 'relative',
                '&:before': {
                  content: '"•"',
                  position: 'absolute',
                  left: -16,
                  color: theme.palette.text.disabled
                }
              }}
            >
              {change}
            </Typography>
          ))}
        </Box>
      );
    });
  };

  const loadActivities = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const req = {
        int: salesRepId,
        // string: "2025-02-08T22:00:00.000Z", // using current date instead of hardcoded
        string: currentSalesRepDate, 
        SearchTerm: searchTerm,
      };

      const response = await MapIconApi.GetLeadActivityHistoryBySalesRepIdAndDate(
        req, 
        page, 
        10
      );

      setActivities(response.data || []);
      setTotalPages(response.totalPages);
      setTotalItems(response.totalItems || 0);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error loading activities:", error);
    } finally {
      setLoading(false);
    }
  }, [salesRepId, searchTerm]);

  const handlePageChange = (event, page) => {
    loadActivities(page);
  };

  const handleSearch = useCallback(debounce(() => {
    loadActivities(1);
  }, 500), [loadActivities]);

  useEffect(() => {
    loadActivities(1);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, handleSearch]);

  return (
    <Box className="paginated-activity-container">
      <Box className="search-controls">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search activities..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <Search color="action" sx={{ mr: 1.5 }} />,
            sx: { 
              borderRadius: '12px',
              '&:hover fieldset': { borderColor: theme.palette.primary.light },
              '&.Mui-focused fieldset': { borderWidth: 2 }
            }
          }}
          sx={{ mb: 3 }}
        />
      </Box>

      <Box className="activity-list-container">
        {loading ? (
          <Box className="loading-overlay">
            {[...Array(3)].map((_, i) => (
              <Skeleton 
                key={i} 
                variant="rectangular" 
                width="100%" 
                height={120} 
                sx={{ 
                  mb: 2, 
                  borderRadius: 2,
                  bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900], 0.3) : 'rgba(0, 0, 0, 0.04)'
                }} 
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 3,
              px: isMobile ? 1 : 2
            }}>
              <Typography variant="subtitle1" color="text.secondary">
                {/* {totalItems} Activities Found */}
              </Typography>
              <Chip 
                label={`Page ${currentPage} of ${totalPages}`}
                variant="outlined"
                size="small"
              />
            </Box>

            <List className="activity-list" disablePadding>
              {activities.map((activity) => (
                <React.Fragment key={activity.id}>
                  <ListItem className="activity-item" alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar className="activity-avatar" sx={{
                        background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`
                      }}>
                        {getActivityIcon(activity.activityType)}
                      </Avatar>
                    </ListItemAvatar>
                    
                    <ListItemText
                      primary={
                        <Box className="activity-header">
                          <Typography variant="h6" component="div" fontWeight="500">
                            {activity.activityType}
                          </Typography>
                          <Chip 
                            label={formatDistanceToNow(new Date(activity.timestamp)) + ' ago'}
                            color="primary"
                            size="small"
                            sx={{ 
                              ml: 2,
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                              color: theme.palette.primary.dark
                            }}
                          />
                        </Box>
                      }
                      secondary={
                        <>
                          <Box className="activity-content">
                            {parseNoteChanges(activity.notes)}
                            
                            {activity.lead && (
                              <>
                                <Button
                                  startIcon={expandedId === activity.id ? <ExpandLess /> : <ExpandMore />}
                                  onClick={() => setExpandedId(expandedId === activity.id ? null : activity.id)}
                                  size="small"
                                  sx={{ 
                                    mt: 1,
                                    color: theme.palette.text.secondary,
                                    '&:hover': {
                                      backgroundColor: alpha(theme.palette.primary.main, 0.05)
                                    }
                                  }}
                                  aria-label={expandedId === activity.id ? 'Collapse details' : 'Expand details'}
                                >
                                  {expandedId === activity.id ? 'Hide' : 'View'} Lead Details
                                </Button>

                                <Collapse in={expandedId === activity.id} sx={{ mt: 1 }}>
                                  <Box className="lead-details">
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <Person color="disabled" fontSize="small" />
                                          <Typography variant="body2">
                                            {activity.lead.name}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <Chip 
                                            label={activity.lead.convertedToCustomer ? 'Converted' : 'Active'}
                                            size="small"
                                            color={activity.lead.convertedToCustomer ? 'success' : 'primary'}
                                            variant="outlined"
                                            sx={{ 
                                              fontWeight: 500,
                                              borderWidth: 1.5,
                                              borderColor: 'currentColor'
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <CheckCircleOutline color="disabled" fontSize="small" />
                                          <Typography variant="body2">
                                            Updated {formatDistanceToNow(new Date(activity.lead.lastUpdatedAt))} ago
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Collapse>
                              </>
                            )}
                          </Box>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider className="activity-divider" variant="middle" />
                </React.Fragment>
              ))}
            </List>

            {activities.length === 0 && !loading && (
              <Box className="empty-state" sx={{ py: 6 }}>
                <Search sx={{ fontSize: 48, color: 'text.disabled', mb: 2 }} />
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No activities found
                </Typography>
                {searchTerm && (
                  <Typography variant="body2" color="text.disabled">
                    No results matching "{searchTerm}"
                  </Typography>
                )}
              </Box>
            )}

            {totalPages > 1 && (
              <Box className="pagination-controls" sx={{ py: 3 }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  color="primary"
                  showFirstButton
                  showLastButton
                  siblingCount={isMobile ? 0 : 1}
                  boundaryCount={isMobile ? 1 : 2}
                  size={isMobile ? 'small' : 'medium'}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SalesRepActivityHistory;

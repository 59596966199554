import React, { useState } from 'react'
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import SoftTypography from '../SoftTypography';

import SearchShopItemCategory from '../../Requests/ShopItems/SearchShopItemCategory';
import ShopItemsApi from '../../API/ShopItemsApi';
const Index = ({setUser,disabled=false,placeholder="Select a parent Organization"}) => {
    const [UsersSearch, setUserSearch] = useState()

    const GetOrg = async () => {
        let searchReq = new SearchShopItemCategory();
        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.string = UsersSearch;

        
        let res = await ShopItemsApi.SearchShopItemsCategory(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name,id:item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }


    return (
        <div className='selector'>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {setUser(e.id)}}
                    placeholder={placeholder}
                    isDisabled={disabled}
                />
            </SoftTypography>

        </div>
    )
}

export default Index
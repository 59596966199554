import React, { createContext, useContext, useEffect, useState } from 'react'
import StringIntReq from '../../../Requests/StringIntReq';
import MasterSheetApi from '../../../API/MasterSheetApi';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq';
import { list } from 'firebase/storage';
import { UserContext } from '../../../App';

const TabContext = createContext(undefined)

export function TabProvider({ children }) {
  const [tabs, setTabs] = useState([{ id: '1', openFile: null }])
  const [activeTabId, setActiveTabId] = useState('1')
  const [activeListId, setActiveListId] = useState(null)
  const [fileData, setFileData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [files, setFiles] = useState(null);
  const [headers, setHeaders] = useState({});
  const user = useContext(UserContext)
  const getAllDynamicLists = async () => {
    const req = new StringIntReq();
    req.int = user.id
    const res = await MasterSheetApi.GetAllDynamicLists(req)
    if (res?.status?.success) {
      setFiles(res.data)
    }

  }
  const getName = (lead) => {
    if (lead.fidiumCustomer) {
      return lead.fidiumCustomer.firstName + ' ' + lead.fidiumCustomer.lastName
    }
    else {
      return lead.name
    }
  }

  const getRepName = (lead) => {
    if (lead.fidiumCustomer) {
      return lead.fidiumCustomer.salesRep ? lead.fidiumCustomer.salesRep.firstName + ' ' + lead.fidiumCustomer.salesRep.lastName : ''
    }
    else if (lead.offer) {
      return lead.offer.salesRep ? lead.offer.salesRep.firstName + ' ' + lead.offer.salesRep.lastName : ''
    }
    else {
      return ''
    }
  }

  const getCompanyName = (lead) => {
    if (lead.fidiumCustomer) {
      return companies?.find(company => company.id === lead?.fidiumCustomer?.fiberCompanyId)?.name
    }
    else if (lead.offer) {
      return lead.offer.fiberCompanyId ? companies?.find(company => company.id === lead.offer.fiberCompanyId)?.name : ''
    }
    else {
      return ''
    }
  }

  const fetchColumns = async (listId) => {
    const req = {
      userId: user.id,
      dynamicListId: listId
    }

    const res = await MasterSheetApi.GetDynamicListColumns(req)
    if (res?.status?.success) {

      let returnData = {}
      returnData[listId] = res.data

      setHeaders((prev) => ({ ...prev, ...returnData }))
      return returnData
    }
    else {
      return null
    }

  }

  const fetchFileData = async (listId, type, page) => {
    const req = new StringIntReq();
    req.pagingParams.pageSize = 100;
    req.pagingParams.pageNumber = page;
    req.int = listId;
    req.optionalInt = user.userType === "SuperAdmin" || user.userType === "CustomerServiceRep"  ? null : user.id
    if (type === "Candidate") {
      // Get Candidates For MasterSheet
      const res = await MasterSheetApi.GetCandidatesByDynmicListId(req)
      if(res?.status?.success){
        let data = [];
        res?.data?.list?.forEach(
          (item,index) => {
              let obj = {
                name : item.name,
                email : item.personalEmail,
                phone : item.phone,
                address : item.address,
                city : item.city,
                state : item.state,
                zip : item.zip,
                country: item.country,
                mapIconName : item.mapIcon?.name,
                notes : item.notes,
                companyName : item.companyName,
                industry : item.industry,
                position : item.positionTitle,
                timeStamp : item.createAt ? new Date(item.createAt).toLocaleString() : '',
                lastUpdatedAt : item.lastUpdatedAt ? new Date(item.lastUpdatedAt).toLocaleString() : '',
                status : item.status,
                companyEmail : item.companyEmail,
                linkedIn : item.linkedIn,
                message : item.jobApplicant?.message,
                notesByRecruiter: item.jobApplicant?.notes,
                applicationTimestamp : item?.jobApplicant?.timeStamp ? new Date(item.jobApplicant.timeStamp).toLocaleString() : '',
                howDidYouHear : item.jobApplicant?.howDidYouHear,
                experience : item.jobApplicant?.experience,
                payExpectation : item.jobApplicant?.payExpectation,
                payReference : item.jobApplicant?.payReference,
                preferNotToTravel : item.jobApplicant?.preferNotToTravel ? "Yes" : "No",
                travelOutOfState : item.jobApplicant?.travelOutOfState ? "Yes" : "No",
                validDriverLicense : item.jobApplicant?.validDriverLicense ? "Yes" : "No",
                workShcedule : item.jobApplicant?.workShcedule,
                rating : item.jobApplicant?.rating ? item.jobApplicant?.rating :"Not Rated",
                index: index + 1, 
              }

              data.push(obj)
          }
        )

        let final = {
          ...res.data,
          list: data
        }

        setFileData(prev => ({ ...prev, [listId]: final }))
        return final
      }

    }
    else {

      // Get Leads for MasterSheet
      const res = await MasterSheetApi.GetLeadsByDynamicListId(req)
      if (res?.status?.success) {
        let data = [];

        res.data.list.forEach((item, index) => {
          let issue = null;

          if (item?.fidiumCustomer?.issueType === 'Cancel') {
            issue = item?.fidiumCustomer?.cancelationForms[0]
          }
          else if (item?.fidiumCustomer?.issueType === 'Reschedule') {
            issue = item?.fidiumCustomer?.rescheduleForms[0]
          }

          let action = item.dynamicListLeads.find(action => action.dynamicListId === listId)
          console.log(action)
          let obj = {
            leadId: item.id,
            customerId: item?.fidiumCustomer?.id,
            fiberHouseId: item?.fiberHouseId,
            fiberCompanyName: getCompanyName(item),
            fiberPlan: item.fidiumCustomer ? item.fidiumCustomer?.fiberPlan?.name : "",
            salesOrgName: item?.fidiumCustomer?.salesOrg?.name,
            salesRepName: getRepName(item),
            leadType: item.fidiumCustomer ? "Customer" : item.offer ? "Prospect" : "Lead",
            mapIconName: item.fiberHouse?.mapIcon?.name,
            notes: item.notes,
            accountStatus: item?.fidiumCustomer?.accountStatus,
            timeStamp: item?.fidiumCustomer?.timeStamp ? new Date(item?.fidiumCustomer?.timeStamp).toLocaleString() : '',
            name: getName(item),
            issueType: item?.fidiumCustomer?.issueType,
            address: item?.fiberHouse?.address,
            originalInstallDate: item?.fidiumCustomer?.originallInstallDateTimeFrom ? new Date(item?.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleString() : '',
            updatedInstallDate: item?.fidiumCustomer?.updatedInstallDateTimeFrom ? new Date(item?.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleString() : '',
            accountNotes: item?.fidiumCustomer?.accountNotes,
            issueDescription: item?.fidiumCustomer?.issueDescription,
            issueTimestamp: issue?.timestamp ? new Date(issue?.timestamp).toLocaleString() : '',
            issueResolved: issue?.isResolved === true ? "Yes" : issue ? 'No' : '',
            action: action?.action ? action?.action?.trim('\n').split('\n') : [],
            action2: action?.action2 ? action?.action2?.trim('\n').split('\n') : [],
            results: action?.results ? action?.results?.trim('\n').split('\n') : [],
            id: action?.id,
            index: index + 1,
            crmId: item?.fidiumCustomer ? item?.fidiumCustomer?.crmId : item.offer ? item.offer.crmId : null,
            dealSaved: item?.fidiumCustomer ? item?.fidiumCustomer?.dealSaved : null,
            dealSavedDate: item?.fidiumCustomer ? new Date(item?.fidiumCustomer?.dealSavedDate).toLocaleString() : null,
            dealSavedBy: item?.fidiumCustomer ? item?.fidiumCustomer?.savedBy?.firstName : null,
            lead: item,
            //   ...item
          }

          data.push(obj)
        })
        console.log(data)
        let final = {
          ...res.data,
          list: data
        }
        setFileData(prev => ({ ...prev, [listId]: final }))
        return final
      }
    }
  }

  const getAllCompaniesForDisplay = async () => {
    const req = new SearchFiberCompanyReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 100;
    const res = await FiberCompanyApi.SearchFiberCompany(req)
    if (res?.status?.success) {
      setCompanies(res.data.list)
    }
  }

  const openNewTab = () => {
    const newTab = { id: Date.now().toString(), openFile: null }
    setTabs(prev => [...prev, newTab])
    setActiveTabId(newTab.id)
  }

  const openFileInNewTab = (file) => {
    const newTab = { id: Date.now().toString(), openFile: file }
    setTabs(prev => [...prev, newTab])
    setActiveTabId(newTab.id)
  }

  const getOpenFile = () => {
    return tabs.find(tab => tab.id === activeTabId)?.openFile || null
  }

  const closeTab = (id) => {
    setTabs(prev => prev.filter(tab => tab.id !== id))
    if (activeTabId === id) {
      setActiveTabId(tabs[tabs.length - 2]?.id || null)
    }
  }

  const getFileHeaders = async (listId) => {
    if (headers[listId]) {
      return headers[listId]
    }
    else {
      const data = await fetchColumns(listId)
      return data[listId]
    }
  }

  const getFileData = async (listId, page, first) => {
    console.log(page)
    if (fileData[listId] && (fileData[listId].pageNumber === page || first)) {
      return fileData[listId]
    }
    else {
      const data = await fetchFileData(listId, page)
      return data
    }
  }

  const setActiveTab = (id) => {
    setActiveTabId(id)
  }

  const getListId = (id) => {
    return tabs.find(tab => tab.id === id)?.openFile?.id || null
  }

  useEffect(
    () => {
      getAllCompaniesForDisplay()
      getAllDynamicLists()
    }, []
  )

  useEffect(
    () => {
      setActiveListId(
        tabs.find(tab => tab.id === activeTabId)?.openFile?.id || null
      )
    }, [activeTabId]
  )

  return (
    <TabContext.Provider value={{
      tabs,
      activeTabId,
      openNewTab,
      openFileInNewTab,
      closeTab,
      setActiveTab,
      getListId,
      getFileData,
      companies,
      setFileData,
      files,
      setFiles,
      getAllDynamicLists,
      getFileHeaders,
      activeListId,
      getOpenFile
    }}>
      {children}
    </TabContext.Provider>
  )
}

export function useTabs() {
  const context = useContext(TabContext)
  if (context === undefined) {
    throw new Error('useTabs must be used within a TabProvider')
  }
  return context
}

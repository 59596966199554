import React, { useEffect, useState } from 'react'
import StringIntReq from '../../../../Requests/StringIntReq';
import FiberHouseApi from '../../../../API/FiberHouseApi';
import bot from "./../Images/bot.png"
// import { Button, Image, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@mui/material';
import { Zap, FileText, Mail, Phone, RotateCw, MoveRight, MoveLeft, MapPin } from "lucide-react"
import { CircularProgress, Tooltip } from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineDirections } from "react-icons/md";
import { TbMessage } from "react-icons/tb";
import FiberCompanyApi from '../../../../API/FiberCompanyApi';
import ContactButtons from "../../../../Services/ContactButtons";
import CircleStyle from "ol/style/Circle";
import { Fill, Icon, Stroke, Style } from "ol/style";
import MapIconApi from '../../../../API/MapIconApi';

import MapIconDisplay from './MapIconDisplay';
import Swal from 'sweetalert2';
import UsersApi from '../../../../API/UsersApi';
import { use } from 'react';
import EditCandidateReq from '../../../../Requests/FiberHouse/EditCandidateReq';
import { set } from 'ol/transform';
import { get } from 'sortablejs';



const MiniCandidateLeadCard = ({ isVisible,openPreviousNearestCandidate,openNextNearestCandidate,setSelectedCandidateLead,setSelectedCandidateFeature, onClose, candidates, setCandidates, mapObject, selectedCandidateFeature, selectedCandidateLead, children, candidate, mapIcons, setSelectedFiberHouse, setFiberHouses, fiberHouses, selectedFiberHouseFeature, fiberHouse, user }) => {

  const [candidateDetails, setCandidateDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([])
  const [rating, setRating] = useState(candidateDetails?.jobApplicant?.rating)
  const [lastAction, setLastAction] = useState("SMS")
  const [lastResult, setLastResult] = useState("No Answer")

  let contactButtons = new ContactButtons();




  const getFiberCompanies = async () => {
    let req = {
      hasOfferTemplate: true,
      pagingParams: {
        pageNumber: 1,
        pageSize: 100
      }
    }
    const res = await FiberCompanyApi.SearchFiberCompany(req)
    if (res?.status?.success) {
      setCompanies(res.data.list)
      console.log("Companies", res.data.list)
    }


  }

  const getLastAction = async() => {
  
    const res = await MapIconApi.GetLastAction(candidateDetails?.id)
    if (res?.status?.success) {
      setLastAction(res.data)
    }
  }

  const getLastResult = async() => {

    const res = await MapIconApi.GetLastResult(candidateDetails?.id)
    if (res?.status?.success) {
      setLastResult(res.data)
    }
  }

  const addLastAction = async (action) => {
    const req = new StringIntReq()
    req.int = candidateDetails?.id
    req.string = action
    const res = await MapIconApi.SetLastAction(req)
    if (res?.status?.success) {
      console.log("Last Action Set")

      Swal.fire({
        icon: 'success',
        title: 'Last Action Set',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()
        }
      });

      setLastAction(res.data)
      getLastAction()
    }
    else{
      console.log("Last Action Failed")
      Swal.fire({
        icon: 'error',
        title: 'Last Action Failed',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }
  }

  const addLastResult = async (result) => {
    const req = new StringIntReq()
    req.int = candidateDetails?.id
    req.string = result
    const res = await MapIconApi.SetLastResult(req)
    if (res?.status?.success) {
      console.log("Last Result Set")

      Swal.fire({
        icon: 'success',
        title: 'Last Result Set',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()
        }
      });

      setLastResult(res.data)
      getLastResult()
      
    }
    else {
      console.log("Last Result Failed")
      Swal.fire({
        icon: 'error',
        title: 'Last Result Failed',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }
  }








  useEffect(() => {
    // getFiberCompanies()
    if(candidateDetails)
    {
      getLastAction()
      getLastResult()
    }
   
  }, [candidateDetails])

  // console.log("Candidate", candidate);
  const getCandidateLeadDetails = async () => {
    const req = new StringIntReq();
    req.int = candidate.id;
    const res = await FiberHouseApi.GetCandidateLeadDetails(req);
    if (res?.status?.success) {
      setCandidateDetails(res.data);
      console.log("Candidate Details", res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCandidateLeadDetails();
  }, [candidate]);



  const onClick = async (mapIcon) => {

    let featureData = selectedCandidateFeature.get('data')
    console.log(selectedCandidateLead)
    if (selectedCandidateFeature && featureData.id === selectedCandidateLead.id) {
      console.log("Candidate Feature Found")
      const newStyle = new Style({
        image: new Icon({
          scale: 1.5, // Adjust this value to change the scale of the icon
          src: "data:image/png;;base64," + mapIcon?.image,
        }),
      });

      const circleStyle = new Style({
        image: new CircleStyle({
          radius: 15, // This is now in pixels
          stroke: new Stroke({
            color: "black", // Border color
            width: 4, // Border width
          }),
          fill: new Fill({
            color: "rgba(0, 0, 0, 0)", // Transparent fill
          }),
        }),
      });
      selectedCandidateFeature.setStyle([
        newStyle, // This is the icon style
        circleStyle, // This is the circle style
      ]);
      selectedCandidateFeature.values_.mapIconId = mapIcon.id
      console.log(mapIcon.id)
      console.log(selectedCandidateFeature)

      mapObject.getView().setZoom(
        mapObject.getView().getZoom() + 0.000001
      )
    }

    const index = binarySearchCandidates(candidates, selectedCandidateLead.id);
    console.log(index)
    const fh = candidates
    if (index !== -1) {
      fh[index].mapIconId = mapIcon.id;
      setCandidates([...fh]);
    }
    else {
      const fh = candidates.map((candidate) => {
        if (candidate.id === selectedCandidateLead.id) {
          return {
            ...candidate,
            mapIconId: mapIcon.id
          }
        }
        return candidate;
      });
      setCandidates([...fh]);
    }

    editMapIcon(mapIcon)
    setSelectedCandidateLead(
      {
        ...selectedCandidateLead,
        mapIconId: mapIcon.id
      }
    )

  }

  const editMapIcon = async (mapIcon) => {
    const req = new EditCandidateReq()




    req.id = selectedCandidateLead.id

    req.coordinate = null
    req.mapIconId = mapIcon.id
    req.salesRepId = user?.id
    //req.repCoordinates = currentLocationCoordinates;
    FiberHouseApi.EditCandidateLead(req).then(
      (res) => {
        if (res?.status?.success) {

        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res?.status?.message,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            toast: true,
            timer: 1500,
            position: "bottom-end",
            didOpen: () => {
              Swal.hideLoading()
            }
          });
        }
      }
    )
  }


  function binarySearchCandidates(candidates, targetId) {
    let left = 0;
    let right = candidates.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const midId = candidates[mid].id;

      if (midId < targetId) {
        left = mid + 1;
      } else if (midId > targetId) {
        right = mid - 1;
      } else {
        return mid; // Found the target, return its index
      }
    }

    return -1; // Target not found
  }



  const handleRating = async (rating) => {



    let req = new StringIntReq()
    req.int = candidateDetails?.jobApplicant?.id
    req.string = rating

    const res = await UsersApi.RateApplication(req)
    if (res?.status?.success) {
      console.log("Rating Success")

      Swal.fire({
        icon: 'success',
        title: 'Rating Success',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()
        }
      });

    }
    else {
      console.log("Rating Failed")
      Swal.fire({
        icon: 'error',
        title: 'Rating Failed',
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading()

        }
      });
    }
  }




  return (
    <div className="max-w-sm bg-white rounded-3xl shadow-lg p-6 relative">


      {/* Header Section */}
      <div className="flex items-start justify-between">
        <div className="flex gap-3">
          <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center text-xl font-bold">
            {
              candidateDetails?.name != null ? candidateDetails?.name[0] : "N"
            }
          </div>
          <div className="space-y-1">
            <h1 className="text-2xl font-semibold">{candidateDetails?.name != null ? candidateDetails.name : "No Name"}</h1>
            <div className="flex text-gray-400">{"★".repeat(5)}</div>

            <div className="flex items-center gap-1 text-gray-500 text-sm">
              <MapPin className="w-4 h-4 text-blue-500" />
              <Tooltip title={candidateDetails?.mapString} position="top" trigger="mouseenter focus">
                <span className=" w-24 truncate">
                  {candidateDetails?.mapString != null ? candidateDetails.mapString : "No Location"}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        {/* <div className="h-12 w-12">
          <img
            src={bot}
            alt="Robot avatar"
            width={48}
            height={48}
          />
        </div> */}
      </div>

      {/* Location */}
      {/* <div className="flex items-center gap-2 text-blue-600">
        <div className="h-6 w-6 rounded-full bg-blue-600 flex items-center justify-center">
          <div className="h-2 w-2 rounded-full bg-white" />
        </div>
        <span>2016 Ash St, Seattle, Washington</span>
      </div> */}


      {/* Company Logos */}
      {/* <div className="flex gap-4 mt-4 overflow-x-auto"
        style={{
          scrollbarWidth: "none",
          // msOverflowStyle: "none",
          // "&::-webkit-scrollbar": {
          //     display: "none"
          // }
        }}
      >
        {
          companies?.map((company, index) => {
            return (
              <img className=" h-7"
                key={index}
                src={"data:image/png;base64," + company?.logo}
                alt={company.name}
              // className="h-7"
              />
            )
          })

        }

      </div> */}

      {/* Status Section */}
      <div className="flex justify-start gap-2 mt-3 items-center">

        <fieldset className="border border-gray-200 p-2 rounded-lg w-1/3">
          <legend className="text-xs text-gray-500" position="center">Status</legend>
          <h2 className="text-xs font-bold text-gray-700 m-auto">
            {candidateDetails?.status != null ? candidateDetails.status : "New Status"}

          </h2>
        </fieldset>



        <fieldset className='border border-gray-200 p-2 rounded-lg w-1/3'>
          <legend className="text-xs text-gray-500" position="center">Rate Candidate</legend>
          <select className=" rounded-lg w-24 text-sm  outline-none "
            value={rating}
            onChange={(e) => handleRating(e.target.value)}

            disabled={!candidateDetails?.jobApplicant}
          >
            <option value="unrated">UnRated</option>
            <option value="maybefit">Maybe a Fit </option>
            <option value="goodfit">Good Fit</option>
            <option value="besrfit">Best Fit</option>
            <option value="notfit">Not a Fit</option>


          </select>
        </fieldset>

        <fieldset className='border border-gray-200 p-2 rounded-lg w-1/3'>
          <legend className="text-xs text-gray-500" position="center">
            Resume
          </legend>
          {
            candidateDetails?.jobApplicant?.file != null ?
              <a href={candidateDetails?.jobApplicant?.file?.url} target="_blank" rel="noreferrer">
                View
              </a> : <p className='text-xs'>No Rsume</p>

          }
        </fieldset>
      </div>





      {/* Lead Status */}
      <div>
        <div className="flex items-center justify-between mt-3">
          <h2 className="text-xl font-semibold">Lead Status</h2>
          {/* <div className="text-gray-500 text-sm">
            Last Contacted | <span className="text-blue-600">2 Days Ago</span>
          </div> */}
        </div>


        <div className="flex gap-2 mt-2 overflow-x-auto"
          style={{
            scrollbarWidth: "none",
          }}
        >
          {
            mapIcons?.length > 0 ?
              <div className="flex gap-2">
                {mapIcons.filter(icon => icon.iconType == candidateDetails?.leadType)
                  .map((icon, index) => {
                    return (
                      <div onClick={()=>{
                        onClick(icon)
                      }} className={`${candidate.mapIconId === icon.id && "border-[#0038FF] border-2"}  p-3 flex flex-col items-center w-16 text-gray-700 border rounded-lg`}
                        key={index}>
                        <img alt='map_icon' style={{
                          width: "2rem",
                          height: "2rem"
                        }} src={"data:image/png;base64," + icon.image} />
                        <p>{icon.abbreviation}</p>
                      </div>
                    )
                  })}

              </div>
              :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"

              }}>
                <CircularProgress size={50} sx={{
                  color: "var(--primary-color)"
                }} />
              </div>
          }


        </div>

        {/* <div className="flex gap-2 mt-2 overflow-x-auto"
          style={{
            scrollbarWidth: "none",
          }}
        >
          {
            mapIcons?.length > 0 ?
              <div className="flex gap-2">
                {mapIcons.filter(icon => icon.iconType === null && !lightningBolts.includes(icon.abbreviation))
                  .map((icon, index) => {
                    if (icon.iconType == null) {
                      return (
                        <div onClick={() => {
                          changeMapIcon(icon)
                        }} 
                        className={` p-3 flex flex-col items-center w-16 text-gray-700 border ${fiberHouse.mapIconId === icon.id && "border-[#0038FF] border-2"} rounded-lg`}
                          key={index}>
                          <img alt='map_icon' style={{
                            width: "2rem",
                            height: "2rem"
                          }} src={"data:image/png;base64," + icon.image} />
                          <p className="text-xs">{icon.abbreviation}</p>
                        </div>
                      )
                    }
                  })}

              </div>
              :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"

              }}>
                <CircularProgress size={50} sx={{
                  color: "var(--primary-color)"
                }} />
              </div>
          }


        </div> */}

      </div>

      {/* <div>
        <MapIconDisplay
          selectedFiberHouse={selectedFiberHouse}
          isCustomer=  {false}
          isCandidate = {true}
          candidateType = {candidateDetails?.leadType}
         />
      </div> */}

      {/* Contact Info */}
      <div className="grid grid-cols-3 gap-4 mt-3">
        <fieldset className="border border-gray-200 p-2 rounded-lg">
          <legend className="text-xs text-gray-500" position="center">Attempted</legend>
          <h2 className="text-sm font-bold text-gray-700 m-auto">0</h2>
        </fieldset>
        <fieldset className="border border-gray-200 p-2 rounded-lg">
          <legend className="text-xs text-gray-500" position="center">Last Action</legend>
          <select className="text-xs font-bold text-gray-700 m-auto"
            value={lastAction}
            onChange={(e) => addLastAction(e.target.value)}
          >
            <option value="SMS">SMS</option>
            <option value="Email">Email</option>
            <option value="Call">Call</option>
          </select>
        </fieldset>
        <fieldset className="border border-gray-200 p-2 rounded-lg">
          <legend className="text-xs text-gray-500" position="center">Last Result</legend>
          <select className="text-xs font-bold text-gray-700 m-auto"
            value={lastResult}
            onChange={(e) => addLastResult(e.target.value)}
          >
            <option value="No Answer">No Answer</option>
            <option value="No Reply">No Reply</option>
            <option value="Answered ">Answered </option>
            <option value="Replied">Replied</option>
           
          </select>
        </fieldset>


      </div>

      {/* Action Buttons */}
      {/* <div className="flex justify-between pt-4">
        <button variant="outline" className="rounded-full" size="icon">
          <MoveLeft className="h-4 w-4" />
        </button>
        <div className="flex gap-4">
          <button variant="outline" className="rounded-full" size="icon">
            <FileText className="h-4 w-4" />
          </button>
          <button variant="outline" className="rounded-full" size="icon">
            <Mail className="h-4 w-4" />
          </button>
          <button variant="outline" className="rounded-full" size="icon">
            <Phone className="h-4 w-4" />
          </button>
          <button variant="outline" className="rounded-full" size="icon">
            <RotateCw className="h-4 w-4" />
          </button>
        </div>
        <button className="rounded-full" size="icon">
          <MoveRight className="h-4 w-4" />
        </button>
      </div> */}


      {/* Action Buttons */}
      <div className="flex justify-between items-center mt-3">

        <button
          onClick={() => openPreviousNearestCandidate()}
          className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
          <FaArrowLeft className="w-5 h-5 text-gray-600" />
        </button>
        <button
          onClick={() => contactButtons.sms(candidateDetails?.phone)}
          className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
          <TbMessage className="w-5 h-5 text-gray-600" />
        </button>

        <button
          onClick={() => contactButtons.email(candidateDetails?.personalEmail)}
          className="p-2  rounded-full hover:bg-gray-200 border border-gray-200">
          <Mail className="w-5 h-5 text-gray-600" />
        </button>
        <button
          onClick={() => contactButtons.call(candidateDetails?.phone)}
          className="p-2  rounded-full hover:bg-gray-200 border border-gray-200">
          <Phone className="w-5 h-5 text-gray-600" />
        </button>
        <button
          onClick={() => contactButtons.directions(candidateDetails?.coordinates)}
          className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
          <MdOutlineDirections className="w-5 h-5 text-gray-600" />
        </button>
        <button onClick={()=>{
          openNextNearestCandidate()
        }} className="p-2 rounded-full hover:bg-gray-200 border border-gray-200">
          <FaArrowRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  )
}

export default MiniCandidateLeadCard
import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";


class BrandApi {
    baseUrl = EnvSettings.url;
    GetBrandsUrl = "GetBrands"
    UpdateBrandUrl = "UpdateBrand"
    DeleteBrandUrl = "DeleteBrand"
    CreateBrandUrl = "CreateBrand"


        async GetBrands(req) {
            const res = await fetch(this.baseUrl + this.GetBrandsUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${UsersApi.GetToken()}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(req)
            })
            const data = await res.json();

            return data;
        }

        async DeleteBrand(req) {
            const res = await fetch(this.baseUrl + this.DeleteBrandUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${UsersApi.GetToken()}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(req)
            })
            const data = await res.json();
            return data;
        }


        async CreateBrand(req) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(req)) {
                formData.append(key, value);
            }
            const res = await fetch(this.baseUrl + this.CreateBrandUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${UsersApi.GetToken()}`
                },
                body: formData
            })
            const data = await res.json();
            return data;

        }

        async UpdateBrand(req) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(req)) {
                formData.append(key, value);
            }
            const res = await fetch(this.baseUrl + this.UpdateBrandUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${UsersApi.GetToken()}`
                },
                body: formData
            })
            const data = await res.json();
            return data;

        }


}

export default new BrandApi();
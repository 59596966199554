import Swal from "sweetalert2";
import MessagingApi from "../API/MessagingApi";
import CreateConversationReq from "../Requests/Messaging/CreateConversationReq";
class ContactButtons {
  constructor(_currentUser) {
    this.currentUser = _currentUser;
  }
  directionsLead = (lead) => {
    const coordinates = lead.fiberHouse?.coordinates ?? lead.coordinates;
    if (!coordinates) {
      Swal.fire({
        icon: "error",
        title: "No Coordinates",
        text: "We do not have location data for this lead",
      });
      return;
    }

    let lonlat = [coordinates.longitude, coordinates.latitude];
    let url =
      "https://www.google.com/maps/search/?api=1&query=" +
      lonlat[1] +
      "," +
      lonlat[0];
    window.open(url, "_blank");
  };

  directions = (coordinates) => {
    if (coordinates == null) {
      Swal.fire({
        icon: "error",
        title: "No Coordinates",
        text: "We do not have location data for this lead",
      });
      return;
    }
    let lonlat = [coordinates.longitude, coordinates.latitude];
    let url =
      "https://www.google.com/maps/search/?api=1&query=" +
      lonlat[1] +
      "," +
      lonlat[0];
    window.open(url, "_blank");
  };

  callLead = (lead) => {
    const phone = lead.fidiumCustomer?.cellPhone ?? lead.phone;

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
      return;
    }

    // window.open("tel:" + phone)
    window.location.href = "tel:" + phone;
  };

  call = (phone) => {
    if (phone != null) {
      // window.open("tel:" + phone)
      window.location.href = "tel:" + phone;
    } else {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
    }
  };

  emailLead = (lead) => {
    const email =
      lead.fidiumCustomer?.email ?? lead.email ?? lead.personalEmail;

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "No Email",
        text: "We do not have an email for this lead",
      });
      return;
    }
    // window.open("mailto:" + email)
    window.location.href = "mailto:" + email;
  };

  email = (email) => {
    if (email != null) {
      // window.open("mailto:" + email)
      window.location.href = "mailto:" + email;
    } else {
      Swal.fire({
        icon: "error",
        title: "No Email",
        text: "We do not have an email for this lead",
      });
    }
  };

  smsLead = (lead) => {
    const phone = lead.fidiumCustomer?.cellPhone ?? lead.phone;

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
      return;
    }

    // window.open("tel:" + phone)
    window.location.href = "sms:" + phone;
  };

  sms = (phone, msg) => {
    if (phone != null) {
      // window.open("sms:" + phone)
      window.location.href = "sms:" + phone;
    } else {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
    }
  };

  whatsappLead = (lead) => {
    const phone = lead.fidiumCustomer?.cellPhone ?? lead.phone;

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
      return;
    }

    window.open("https://wa.me/" + phone);
  };

  whatsapp = (phone) => {
    if (phone != null) {
      window.open("https://wa.me/" + phone);
    } else {
      Swal.fire({
        icon: "error",
        title: "No Phone",
        text: "We do not have a phone number for this lead",
      });
    }
  };

  lightningMessageLead = async (lead) => {
    // console.log(lead)
    // console.log(this.currentUser)
    if (this.currentUser == null) {
      Swal.fire({
        icon: "error",
        title: "No User",
        text: "User is not logged in",
      });
      return;
    }
    const userAccount = lead.fidiumCustomer?.user ?? lead.user;

    if (!userAccount) {
      Swal.fire({
        icon: "error",
        title: "No User",
        text: "This lead does not have a user account",
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Creating/Loading Conversation",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let req = new CreateConversationReq();
    req.salesOrgId = this.currentUser.salesOrgId;
    req.creatorId = this.currentUser.id;
    req.conversationType = "CustomerService";
    req.name =
      "Customer Service for " +
      userAccount.firstName +
      " " +
      userAccount.lastName;
    req.userIds = [this.currentUser.id, userAccount.id];

    let res = await MessagingApi.CreateConversation(req);
    // console.log(res)
    Swal.close();

    if (res?.status?.success) {
      return res.data;
    } else if (res?.status?.message === "Conversation already exists") {
      return res.data;
    } else {
      return null;
    }
  };

  lightningMessage = async (user) => {
    // console.log(this.currentUser)
    if (this.currentUser == null) {
      Swal.fire({
        icon: "error",
        title: "No User",
        text: "User is not logged in",
      });
      return;
    }
    if (user == null) {
      Swal.fire({
        icon: "error",
        title: "No User",
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Creating/Loading Conversation",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let req = new CreateConversationReq();
    req.salesOrgId = this.currentUser.salesOrgId;
    req.creatorId = this.currentUser.id;
    req.conversationType = "Private";
    req.name =
      this.currentUser.firstName +
      " " +
      this.currentUser.lastName +
      " || " +
      user.firstName +
      " " +
      user.lastName;
    req.userIds = [this.currentUser.id, user.id];

    let res = await MessagingApi.CreateConversation(req);

    Swal.close();

    // console.log(res)
    if (res?.status?.success) {
      return res.data;
    } else if (res?.status?.message === "Conversation already exists") {
      return res.data;
    } else {
      return null;
    }
  };
}
export default ContactButtons;

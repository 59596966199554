import { useTabs } from './TabContext'
import { FaFile, FaPlus } from 'react-icons/fa6'
import VersatileLoader from '../../../components/VersatileLoader';
import { BiGroup } from 'react-icons/bi';
import { useContext, useEffect, useState } from 'react';
import ManageAccess from './ManageAccess';
import CreateSheetModal from './CreateSheetModal';
import { FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import StringIntReq from '../../../Requests/StringIntReq';
import MasterSheetApi from '../../../API/MasterSheetApi';
import { ConnectionContext } from '../../../App';
import { Edit } from '@mui/icons-material';


export function FileList() {
  const { openFileInNewTab, files, getAllDynamicLists, setFileData } = useTabs()
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [createSheetModalOpen, setCreateSheetModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [listType, setListType] = useState('')


  const onDelete = async (file) => {

    const option = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2761CF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    if (!option.isConfirmed) {
      return
    }

    Swal.fire({
      title: 'Please Wait',
      text: 'Deleting File',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    const req = new StringIntReq()
    req.int = file.id
    const res = await MasterSheetApi.DeleteDynamicList(req)
    if (res?.status?.success) {
      Swal.fire({
        title: 'Success',
        text: 'File deleted successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      getAllDynamicLists()
    }
    else {
      Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

  }

  return (
    <div className="flex-1 bg-white">
      <div className="p-6 space-y-6">
        <CreateSheetModal open={createSheetModalOpen} setOpen={setCreateSheetModalOpen} />
        <ManageAccess open={openModal} setOpen={setOpenModal} file={selectedFile} />
        <div className="space-y-2 h-[calc(100vh-20rem)] overflow-y-auto">
          {
            files === null ?
              <VersatileLoader size='large' color='#2761CF' />
              :
              <>
                <div className='grid grid-cols-3 p-2 gap-2'>
                  <input value={name} onChange={(e) => {
                    setName(e.target.value)
                  }} type="text" placeholder="Search for a Sheet" className=" col-span-2 w-full p-4 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" />
                  <select value={listType} onChange={(e) => {
                    setListType(e.target.value)
                  }} className="w-full p-4 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <option value="">Select Type</option>
                    <option value="Static">Static</option>
                    <option value="Dynamic">Live</option>
                  </select>
                </div>
                {
                  files?.filter(x => x.name.includes(name) && (listType === '' || x.type === listType)).map((file) => (
                    <button
                      key={file.id}
                      className="w-full flex items-center justify-between p-4 hover:bg-blue-50 hover:text-[#2761d0] transition-colors rounded-md"
                      onClick={() => openFileInNewTab(file)}
                    >
                      <div className="flex items-center space-x-3">
                        <FaFile className="h-5 w-5 text-blue-600" />
                        <div className="flex items-start justify-start flex-col">
                          <span className="font-medium">{file.name} <span className='px-1 text-xs rounded-3xl bg-blue-600 text-white'>{file.type === "Static" ? "Static" : "Live"}</span></span>
                          <span className="text-gray-400 text-xs">{file.description}</span>
                        </div>
                      </div>
                      <div>
                        {/* <button
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          className="rounded-full p-2 text-gray-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          <Edit className="h-5 w-5" />
                          <span className="sr-only">Manage access for {file.name}</span>
                        </button> */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedFile(file)
                            setOpenModal(true)
                          }}
                          className="rounded-full p-2 text-gray-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          <BiGroup className="h-5 w-5" />
                          <span className="sr-only">Manage access for {file.name}</span>
                        </button>
                        {/* A delete button */}
                        <button onClick={(e) => {
                          e.stopPropagation()
                          onDelete(file)
                        }} className="rounded-full p-2 text-gray-400  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" >
                          <FaTrash className="h-5 w-5 hover:text-red-500" />
                          <span className="sr-only">Delete {file.name}</span>
                        </button>
                      </div>
                    </button>
                  ))
                }
                {/* button to create new sheet */}
                <button
                  className="w-full flex items-center justify-between p-4 hover:bg-blue-50 hover:text-[#2761d0] transition-colors rounded-md"
                  onClick={() => {
                    setCreateSheetModalOpen(true)
                  }}
                >
                  <div className="flex items-center space-x-3">
                    <FaPlus className="h-5 w-5 text-blue-600" />
                    <span className="font-medium">New Sheet</span>
                  </div>
                </button>
              </>
          }

        </div>
      </div>
    </div>
  )
}
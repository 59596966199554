class SearchTrainingModuleReq {
  constructor() {
    this.name = null;
    this.description = null;
    this.userId = null;
    this.salesOrgId = null;
    this.pagingParams = {
      pageNumber: 1,
      pageSize: 100
    };
  }
}

export default SearchTrainingModuleReq;

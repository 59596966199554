import { useState } from "react";
import { FiChevronDown, FiChevronRight, FiTrash2 } from "react-icons/fi";
import TabPanel from "./TabPanel";

export default function TagRow({ tag, expandedTagId, toggleExpand, onDelete, onEdit }) {
  const [activeTab, setActiveTab] = useState("lead");

  return (
    <>
      <tr className="border-b border-gray-300">
        <td className="px-2 py-4">
          <button
            onClick={() => toggleExpand(tag.id)}
            className="text-gray-600 hover:text-gray-800"
          >
            {expandedTagId === tag.id ? (
              <FiChevronDown size={20} />
            ) : (
              <FiChevronRight size={20} />
            )}
          </button>
        </td>
        <td className="px-2 py-4">
          <span className="rounded-md py-2 px-3 bg-gray-200">{tag.name}</span>
        </td>
        <td className="px-2 py-4">{tag.description}</td>
        <td className="px-2 py-4">
          <div className="flex space-x-2">
            <button onClick={() => onDelete(tag.id)} className="text-[#6b6b67]">
              <FiTrash2 size={18} />
            </button>
          </div>
        </td>
      </tr>

      {expandedTagId === tag.id && (
        <tr>
          <td colSpan="4" className="p-6">
            <TabPanel
              tag={tag}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onEdit={onEdit}
            />
          </td>
        </tr>
      )}
    </>
  );
}

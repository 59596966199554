import { useState, useEffect, useCallback } from "react";
import QRCode from "qrcode.react";
import NewButton from "../../../components/Button/NewButton";
import Input from "../../../components/Input";
import UsersApi from "../../../API/UsersApi.js";
import CheckAffiliateCodeAvailabilityReq from "../../../Requests/ReferralCode/CheckAffiliateCodeAvailabilityReq.js";
import { Check, X, Loader2, RefreshCw, Shuffle } from "lucide-react";
import { debounce } from "lodash";

export default function ReferralCompanyDetails({
  company,
  affiliateCode,
  handleGenerate,
  onAffiliateCodeChange,
}) {
  const [customCode, setCustomCode] = useState("");
  const [isCodeAvailable, setIsCodeAvailable] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const referralLink = `${window.location.origin}${
    affiliateCode ? `/signup/${affiliateCode.code}` : ""
  }`;

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleShare = () => {
    navigator.share({
      title: "Referral Link",
      url: referralLink,
    });
  };

  const checkCodeAvailability = useCallback(
    async (code) => {
      if (!code) {
        setIsCodeAvailable(null);
        return;
      }

      setIsChecking(true);
      const req = new CheckAffiliateCodeAvailabilityReq(code, company.id);
      const response = await UsersApi.CheckAffiliateCodeAvailability(req);

      setIsCodeAvailable(response.data);
      setIsChecking(false);
      if (response.data) {
        onAffiliateCodeChange(code);
      }
    },
    [company.id, onAffiliateCodeChange]
  );

  const debouncedCheckCodeAvailability = useCallback(
    debounce(checkCodeAvailability, 500),
    [checkCodeAvailability]
  );

  useEffect(() => {
    if (!customCode) {
      setIsCodeAvailable(null);
      return;
    }
    setIsChecking(true);
    debouncedCheckCodeAvailability(customCode);
    return () => debouncedCheckCodeAvailability.cancel();
  }, [customCode, debouncedCheckCodeAvailability]);

  const handleCustomCodeChange = (e) => {
    setCustomCode(e.target.value);
  };

  const handleGenerateRandomCode = () => {
    const randomCode = crypto.randomUUID();
    setCustomCode(randomCode);
  };

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="flex-1">
        {company.fiberPlans.map((plan, i) => (
          <div key={i} className="mb-4 last:mb-0">
            <h3 className="font-semibold text-secondary">{plan.name}</h3>
            <p className="text-sm text-muted-foreground">{plan.reward}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col self-center items-center space-y-4 md:w-1/3">
        <div className="bg-white p-4 rounded-lg border border-border shadow-sm">
          {affiliateCode ? (
            <QRCode size={200} value={referralLink} />
          ) : (
            <div>No Affiliate Code has been generated</div>
          )}
        </div>

        {affiliateCode ? (
          <>
            <NewButton
              onClick={handleCopy}
              className="w-full max-w-md hover:bg-primary/90 text-white"
            >
              {isCopied ? "Copied!" : "Copy Link"}
            </NewButton>
            <NewButton
              onClick={handleShare}
              className="w-full max-w-md hover:bg-primary/90 text-white"
              variant="secondary"
            >
              Send Referral Link
            </NewButton>
          </>
        ) : (
          <div className="w-full max-w-md space-y-4">
            <div className="relative">
              <Input
                type="text"
                placeholder="Enter custom affiliate code"
                value={customCode}
                onChange={handleCustomCodeChange}
                className="w-full pr-12"
              />
              <div className="absolute inset-y-0 right-2 flex items-center gap-1">
                {isChecking ? (
                  <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                ) : isCodeAvailable === true ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : isCodeAvailable === false ? (
                  <X className="h-4 w-4 text-red-500" />
                ) : null}
                <button
                  onClick={handleGenerateRandomCode}
                  className="rounded-full bg-primary text-black disabled:opacity-50"
                >
                  <Shuffle className="h-4 w-4" />
                </button>
              </div>
            </div>
            <NewButton
              onClick={() => handleGenerate(customCode)}
              className="w-full hover:bg-primary/90 text-white"
            >
              Create Affiliate Code
            </NewButton>
          </div>
        )}
      </div>
    </div>
  );
}

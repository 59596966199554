import env from "../../../../API/EnvSettings";
// import env from "../../../../../API/EnvSettings";

const API_URL = env.url;


// Fetch Posts
export const fetchPosts = async () => {
  const response = await fetch(`${API_URL}user`, {
    method: "POST",
    body: JSON.stringify({
      userId: 153,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};
// Get Blog
export const getBlog = async (id) => {
  const response = await fetch(`${API_URL}GetBlog`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      userId: 153,
      blogId: id,
    }),
  });
  return response.json();
};
// Update Post
export const updatePost = async (id, updatedPost) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updatedPost),
  });
  return response.json();
};
// Delete Post
export const deletePost = async (id) => {
  await fetch(`${API_URL}DeleteBlog`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      blogId: id,
      userId: 153,
    }),
  });
};
// Archive Blog
export const archiveBlog = async (id) => {
  await fetch(`${API_URL}ArchiveBlog`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      blogId: id,
      userId: 153,
    }),
  });
};
// Publish Blog
export const publishBlog = async (id) => {
  await fetch(`${API_URL}PublishBlog`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      blogId: id,
      userId: 153,
    }),
  });
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import StringIntReq from '../../Requests/StringIntReq';
import SoftTypography from '../SoftTypography';
import ServicableAreaApi from '../../API/ServicableAreaApi';
const Index = ({ setLabelId,setLabelObject, labelObject, disabled = false, placeholder = "Select a parent Organization", className, multiple, value}) => {
    const [label, setLabel] = useState("")
    const [options, setOptions] = useState([])

    const getAreaLabels = async () => {

        let res = null;
        let searchReq = new StringIntReq();
        searchReq.string = label;

        res = await ServicableAreaApi.SearchAreaLabels(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];

            res?.data?.forEach((item) => {
                optionsArr.push({ value: item, label: <div className='flex items-center justify-start gap-2'>
                    <div style={{backgroundColor:item.color}} className={` rounded-full h-5 w-5`}></div>
                    <span className="ml-2">{item.label}</span>
                </div>, id: item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }



    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await getAreaLabels();
            setOptions(optionsArr);
        };
        getOptions();
    }, []);

    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
                        <AsyncSelect
                            loadOptions={getAreaLabels}
                            onInputChange={setLabel}
                            onChange={(e) => {
                                if (multiple) {
                                    setLabelId(e.map((item) => item.id));
                                    if (setLabelObject) {
                                        setLabelObject(e);
                                    }
                                } else {
                                    setLabelId(e.id);
                                    if (setLabelObject) {
                                        setLabelObject(e.value);
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            isDisabled={disabled}
                            isMulti={multiple}
                            defaultValue={value}
                            defaultOptions={options}
                            classNamePrefix={'async_select'}
                            value={value}
                        />
            </SoftTypography>

        </div>
    )

}

export default Index
import React from "react";
import { MapContext as MP } from "..";
import { useState, useContext, useEffect } from "react";
import { Accordion, AccordionDetails } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Swal from "sweetalert2";
import { Typography } from "antd";
import styled from "@emotion/styled";
import { RiTeamFill } from "react-icons/ri";
import { UserContext } from "../../../../App";
import QuickDate from "../../../../components/QuickDate";
import greyUser from "./../../../../assets/images/userGrey.png";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import SelectionMenu from "../../../SuperAdmin/Tags/components/SelectionMenu";
import MapHelper from "../MapHelper";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp color="black" size={20} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, 0)",
  border: "none",
  borderBottom: "1px solid black",
  boxShadow: "none",
  flexDirection: "row-reverse",
  padding: "0px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const SearchFilters = ({
  unassignedConvertedToCustomer,
  setUnassignedConvertedToCustomer,
  unassignedCreatedFrom,
  setUnassignedCreatedFrom,
  unassignedCreatedTo,
  setUnassignedCreatedTo,
  unassignedAcountStatus,
  setUnassignedAcountStatus,
  unassignedMapIcons,
  setUnassignedMapIcons,
  assignedMapIcons,
  setAssignedMapIcons,
  assignedConvertedToCustomer,
  setAssignedConvertedToCustomer,
  searchFiberHouses,
  assignedAcountStatus,
  setAssignedAcountStatus,
  assignedCreatedFrom,
  assignedCreatedTo,
  setAssignedCreatedFrom,
  setAssignedCreatedTo,
  assignedDateType,
  setAssignedDateType,
  unassignedDateType,
  setUnassignedDateType,
  defaultFiberCompanies,
  tags,
  setTags,
  mySelectedTags,
  setMySelectedTags,
  assignedSelectedTags,
  setAssignedSelectedTags,
  unassignedSelectedTags,
  setUnassignedSelectedTags,
  searchCriteria,
  setSearchCriteria,
}) => {
  const map = useContext(MP);
  const user = useContext(UserContext);
  const [areaCreated, setAreaCreated] = useState(false);
  const initialRender = React.useRef(true);
  const ready = React.useRef(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const helper = new MapHelper();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function search() {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "We are Searching Fiber Houses",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    await searchFiberHouses(true);
    setAreaCreated(false);
    Swal.close();
  }

  useEffect(() => {
    if (areaCreated && map.searchArea && map.searchArea.length > 0) {
      search();
    }
  }, [map.searchArea, areaCreated]);

  const quickDate = (date, setFrom, setTo) => {
    const formatDate = (date) => date.toLocaleDateString("en-CA"); // 'en-CA' format is YYYY-MM-DD

    if (date === "Today") {
      const today = new Date();
      setFrom(formatDate(today));
      setTo(formatDate(today));
    } else if (date === "Tomorrow") {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setFrom(formatDate(tomorrow));
      setTo(formatDate(tomorrow));
    } else if (date === "Yesterday") {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setFrom(formatDate(yesterday));
      setTo(formatDate(yesterday));
    } else if (date === "This Week") {
      let today = new Date();
      let firstDay = new Date(
        today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      );
      let lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      );
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "Next Week") {
      let today = new Date();
      let firstDay = new Date(
        today.setDate(today.getDate() - ((today.getDay() + 6) % 7) + 7)
      );
      let lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      );
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "Last Week") {
      let today = new Date();
      let firstDay = new Date(
        today.setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7)
      );
      let lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      );
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "This Month") {
      let today = new Date();
      let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "Next Month") {
      let today = new Date();
      let firstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      let lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "Last Month") {
      let today = new Date();
      let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      let lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "This Year") {
      let today = new Date();
      let firstDay = new Date(today.getFullYear(), 0, 1);
      let lastDay = new Date(today.getFullYear(), 11, 31);
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else if (date === "Last Year") {
      let today = new Date();
      let firstDay = new Date(today.getFullYear() - 1, 0, 1);
      let lastDay = new Date(today.getFullYear() - 1, 11, 31);
      setFrom(formatDate(firstDay));
      setTo(formatDate(lastDay));
    } else {
      setFrom("");
      setTo("");
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      map.setTriggerSearch(false);
      initialRender.current = false;

      return;
    }

    const timer = setTimeout(
      () => {
        if (screenWidth > 1000 && !helper.isIpad()) {
          searchFiberHouses();
        } else {
          map.setTriggerSearch(true);
        }
      },
      screenWidth > 1000 && !helper.isIpad() ? 1000 : 100
    );

    return () => clearTimeout(timer);
  }, [
    unassignedConvertedToCustomer,
    map.includeInvalid,
    map.unassignedFiberCompanyIds,
    map.assignedFiberCompanyIds,
    map.mapIconToggle,
    map.assignedProspectSold,
    assignedDateType,
    unassignedDateType,
    map.assignedLeadsEnabled,
    map.unassignedLeadsEnabled,
    map.unassignedUpdatedFrom,
    map.unassignedUpdatedTo,
    unassignedAcountStatus,
    unassignedMapIcons,
    map.unassignedSalesOrgs,
    assignedMapIcons,
    assignedConvertedToCustomer,
    assignedAcountStatus,
    map.assignedUpdatedFrom,
    map.assignedUpdatedTo,
    map.assignedSalesOrgs,
    map.assignedSalesRepIds,
    map.assignedRepToggle,
    mySelectedTags,
    assignedSelectedTags,
    unassignedSelectedTags,
    map.myLeadsEnable,
  ]);

  const resetMyLeadFilters = async () => {
    const option = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will reset all My Leads search filters",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!option.isConfirmed) {
      return;
    }

    map.setMyLeadsEnable(false);
    setAssignedConvertedToCustomer(null);
    map.setAssignedAcountStatus("");
    setAssignedDateType("LastUpdated");
    setAssignedMapIcons(map.mapIcons.map((icon) => icon.id));
    map.setAssignedUpdatedFrom("");
    map.setAssignedUpdatedTo("");
    map.setIncludeInvalid(false);
    map.setAssignedQuickDate("All Time");
    map.setAssignedSalesRepIds(map.salesReps.map((rep) => rep.id));
    map.setAssignedRepToggle("AssignedTo");
    map.setMapIconToggle("CurrentMapIcon");
    map.setAssignedFiberCompanyIds([]);
    map.setAssignedCompanyWithName([]);
    setMySelectedTags([]);
  };

  const resetAssignedFilters = async () => {
    const option = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will reset all assigned search filters",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!option.isConfirmed) {
      return;
    }

    map.setAssignedLeadsEnabled(true);
    setAssignedConvertedToCustomer(null);
    map.setAssignedAcountStatus("");
    setAssignedDateType("LastUpdated");
    setAssignedMapIcons(map.mapIcons.map((icon) => icon.id));
    map.setAssignedUpdatedFrom("");
    map.setAssignedUpdatedTo("");
    map.setAssignedSalesOrgs(map.salesOrg.map((org) => org.id));
    map.setShowDeactivatedAccounts(false);
    map.setIncludeInvalid(false);
    map.setAssignedQuickDate("All Time");
    map.setAssignedSalesRepIds(map.salesReps.map((rep) => rep.id));
    map.setAssignedRepToggle("AssignedTo");
    map.setMapIconToggle("CurrentMapIcon");
    map.setAssignedFiberCompanyIds([]);
    map.setAssignedCompanyWithName([]);
    setAssignedSelectedTags([]);
  };

  const resetUnassignedFilters = async () => {
    const option = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will reset all unassigned search filters",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!option.isConfirmed) {
      return;
    }

    map.setUnassignedLeadsEnabled(true);
    setUnassignedConvertedToCustomer(null);
    map.setUnassignedAcountStatus("");
    setUnassignedDateType("LastUpdated");
    setUnassignedMapIcons(map.mapIcons.map((icon) => icon.id));
    map.setIncludeInvalid(false);
    map.setUnassignedUpdatedFrom("");
    map.setUnassignedUpdatedTo("");
    map.setUnassignedSalesOrgs(map.salesOrg.map((org) => org.id));
    map.setUnassignedQuickDate("All Time");
    map.setUnassignedFiberCompanyIds([]);
    map.setUnassignedCompanyWithName([]);
    setUnassignedSelectedTags([]);
  };

  const unassignedColor = () => {
    if (
      !map.unassignedLeadsEnabled ||
      map.unassignedSalesOrgs.length !== map.salesOrg.length ||
      unassignedMapIcons.length !== map.mapIcons.length ||
      unassignedConvertedToCustomer !== null ||
      unassignedAcountStatus !== null ||
      map.unassignedUpdatedFrom !== "" ||
      map.unassignedUpdatedTo !== "" ||
      map.unassignedQuickDate !== "All Time"
    ) {
      return "red";
    } else {
      return "#E6E6E6";
    }
  };

  const assignedColor = () => {
    if (
      !map.assignedLeadsEnabled ||
      map.assignedSalesOrgs.length !== map.salesOrg.length ||
      map.assignedSalesRepIds.length !== map.salesReps.length ||
      assignedMapIcons.length !== map.mapIcons.length ||
      assignedConvertedToCustomer !== null ||
      assignedAcountStatus !== null ||
      map.assignedUpdatedFrom !== "" ||
      map.assignedUpdatedTo !== "" ||
      map.assignedQuickDate !== "All Time"
    ) {
      return "red";
    } else {
      return "#E6E6E6";
    }
  };

  return (
    <div className="SearchFiltersDiv">
      <div className="TitleDiv">
        <h3 className="text-4xl font-bold">Search Filters</h3>
      </div>

      {(user.userType === "SuperAdmin" ||
        user.userType === "SalesOrgAdmin") && (
        <>
          <div
            style={{ marginBottom: "1rem" }}
            className="search_filter_container"
          >
            <button
              disabled={assignedColor() === "#E6E6E6"}
              style={{
                backgroundColor: assignedColor(),
              }}
              onClick={() => {
                resetMyLeadFilters();
              }}
              className="reset_all_filters"
            >
              Reset Filters
            </button>
            <Accordion
              sx={{
                boxShadow: "none",
                width: "100%",
              }}
            >
              <AccordionSummary
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography> My Leads</Typography>
                <div className="switch">
                  <div className="switch__2">
                    <input
                      onChange={(e) => {
                        if (e.target.checked) {
                          map.setAssignedLeadsEnabled(false);
                          map.setUnassignedLeadsEnabled(false);
                        }
                        map.setMyLeadsEnable(e.target.checked);
                        console.log(e.target.checked);
                      }}
                      id="switch-11"
                      type="checkbox"
                      checked={map.myLeadsEnable}
                    />
                    <label for="switch-11"></label>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="InputsDiv">
                  <div className="InputDiv">
                    <label htmlFor="assignedConvertedToCustomer">
                      Lead Type
                    </label>
                    <select
                      className="p-2 border-2 outline-none rounded-lg"
                      name="assignedConvertedToCustomer"
                      value={assignedConvertedToCustomer ?? "null"}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAssignedConvertedToCustomer(
                          value !== "0"
                            ? value === "true"
                              ? true
                              : value === "false"
                              ? false
                              : null
                            : null
                        );
                      }}
                    >
                      <option value="null">All</option>
                      <option value="true">Customer</option>
                      <option value="false">Hot Lead</option>
                    </select>
                  </div>
                  {assignedConvertedToCustomer !== false && (
                    <div className="InputDiv">
                      <label htmlFor="accountStatus">Account Status</label>
                      <select
                        className="p-2 border-2 outline-none rounded-lg"
                        name="accountStatus"
                        value={assignedAcountStatus}
                        onChange={(e) => {
                          e.target.value !== "0"
                            ? setAssignedAcountStatus(e.target.value)
                            : setAssignedAcountStatus(null);
                        }}
                      >
                        <option value="0">All</option>
                        <option value="Sold">Sold</option>
                        <option value="VerifiedSold">Verified Sold</option>
                        <option value="PendingCancels">Pending Cancels</option>
                        <option value="PendinReshcedules">
                          Pending Reschedules
                        </option>
                        <option value="Missing">Missing</option>
                        <option value={"Completed"}>Completed</option>
                        <option value="Canceled">Canceled</option>
                        <option value={"Scheduled"}>Pending Installs</option>
                      </select>
                    </div>
                  )}
                  {assignedConvertedToCustomer === false && (
                    <div className="InputDiv">
                      <label>Sold</label>
                      <div
                        style={{
                          gridColumn: "span 1",
                          width: "100%",
                        }}
                        className="switch"
                      >
                        <div className="switch__2">
                          <input
                            onChange={(e) => {
                              map.setAssignedProspectSold(e.target.checked);
                            }}
                            id="switch-5"
                            type="checkbox"
                            checked={map.assignedProspectSold}
                          />
                          <label for="switch-5"></label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="InputDiv">
                    <label htmlFor="DateType">Date Type</label>
                    <select
                      value={assignedDateType}
                      onChange={(e) => {
                        setAssignedDateType(e.target.value);
                      }}
                    >
                      <option value={"LastUpdated"}>Last Updated</option>
                      <option value={"DateSold"}>Date Sold</option>
                      <option value={"InstallDate"}>Install Date</option>
                      <option value={"AppointmentDate"}>
                        Appointment Date
                      </option>
                      <option value={"CreatedAt"}>Created At</option>
                    </select>
                  </div>

                  <SelectionMenu
                    tags={tags}
                    onChange={(selected) => setMySelectedTags(selected)}
                    selectedItems = {mySelectedTags}
                    setSelectedItems = {setMySelectedTags}
                    searchCriteria = {searchCriteria}
                setSearchCriteria = {setSearchCriteria}
                  />


                  <div className="InputDiv">
                    <label hmltFor="include_invalid">Include Invalids</label>
                    <div
                      style={{
                        gridColumn: "span 1",
                        width: "100%",
                      }}
                      className="switch"
                    >
                      <div className="switch__2">
                        <input
                          onChange={(e) => {
                            map.setIncludeInvalid(e.target.checked);
                          }}
                          id="switch-20"
                          type="checkbox"
                          checked={map.includeInvalid}
                        />
                        <label for="switch-20"></label>
                      </div>
                    </div>
                  </div>

                  <QuickDate
                    future={assignedAcountStatus === "Scheduled"}
                    selectQuickDate={map.assignedQuickDate}
                    setQuickDateValue={map.setAssignedQuickDate}
                    setFrom={map.setAssignedUpdatedFrom}
                    setTo={map.setAssignedUpdatedTo}
                  />
                  <div className="company_selector">
                    <FiberCompanySelector
                      companyObject={map.assignedCompanyWithName}
                      setCompanyObject={map.setAssignedCompanyWithName}
                      multiple={true}
                      setUser={map.setAssignedFiberCompanyIds}
                      placeholder="Select Fiber Company"
                    />
                  </div>

                  <h1 className="lead_type_title">Lead Status</h1>
                  <div className="select_deselect">
                    <button
                      className="select"
                      onClick={() => {
                        setAssignedMapIcons(
                          map.mapIcons.map((icon) => icon.id)
                        );
                      }}
                    >
                      Select All
                    </button>
                    <button
                      className="deselect"
                      onClick={() => {
                        setAssignedMapIcons([]);
                      }}
                    >
                      Deselect All
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "1rem",
                      width: "100%",
                    }}
                  >
                    <div className="switch" style={{ gridGap: "1rem" }}>
                      <div className="switch__2">
                        <input
                          onChange={(e) => {
                            if (map.mapIconToggle === "CurrentMapIcon") {
                              map.setMapIconToggle("OnceWas");
                            } else {
                              map.setMapIconToggle("CurrentMapIcon");
                            }
                          }}
                          id="switch-5"
                          type="checkbox"
                          checked={map.mapIconToggle !== "CurrentMapIcon"}
                        />
                        <label for="switch-5"></label>
                      </div>
                    </div>
                    <p>
                      {map.mapIconToggle === "CurrentMapIcon"
                        ? "Current Map Icon"
                        : "Past Map Icon"}
                    </p>
                  </div>
                  <div className="MapIconStats">
                    {map.mapIcons
                      ?.filter((icon) => icon.iconType === null)
                      ?.map((icon, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              if (assignedMapIcons.includes(icon.id)) {
                                setAssignedMapIcons(
                                  assignedMapIcons?.filter(
                                    (id) => id !== icon.id
                                  )
                                );
                              } else {
                                setAssignedMapIcons([
                                  ...assignedMapIcons,
                                  icon.id,
                                ]);
                              }
                            }}
                            className={`MapIconStat  ${
                              assignedMapIcons?.includes(icon.id)
                                ? "active"
                                : null
                            }`}
                          >
                            <h3>
                              <img
                                src={"data:image/png;base64," + icon.image}
                                alt="Map Icon"
                              />
                            </h3>
                            <p>{icon.abbreviation}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <h1 className="action_title">Quick Search</h1>
            <div className="action_buttons">
              <button
                className={`action_button ${
                  assignedAcountStatus === "" ? "active" : null
                }`}
                onClick={() => {
                  setAssignedAcountStatus("");
                }}
              >
                All
              </button>
              <button
                className={`action_button ${
                  assignedAcountStatus === "Sold" ? "active" : null
                }`}
                onClick={() => {
                  setAssignedAcountStatus("Sold");
                }}
              >
                Sold
              </button>
              <button
                className={`action_button ${
                  assignedAcountStatus === "Completed" ? "active" : null
                }`}
                onClick={() => {
                  setAssignedAcountStatus("Completed");
                }}
              >
                Completed
              </button>
              <button
                className={`action_button ${
                  assignedAcountStatus === "Canceled" ? "active" : null
                }`}
                onClick={() => {
                  setAssignedAcountStatus("Canceled");
                }}
              >
                Canceled
              </button>
              <button
                className={`action_button ${
                  assignedAcountStatus === "Scheduled" ? "active" : null
                }`}
                onClick={() => {
                  if (
                    assignedAcountStatus !== "Scheduled" &&
                    map.assignedQuickDate !== "Today"
                  ) {
                    map.setAssignedQuickDate("");
                    map.setAssignedUpdatedFrom("");
                    map.setAssignedUpdatedTo("");
                  }
                  setAssignedAcountStatus("Scheduled");
                }}
              >
                Installs Pending
              </button>
              <button
                className={`action_button ${
                  assignedAcountStatus === "CancelsPending" ? "active" : null
                }`}
                onClick={() => {
                  setAssignedAcountStatus("CancelsPending");
                }}
              >
                Cancels Pending
              </button>
            </div>
            <div className="action_date InputsDiv">
              <QuickDate
                future={assignedAcountStatus === "Scheduled"}
                selectQuickDate={map.assignedQuickDate}
                setQuickDateValue={map.setAssignedQuickDate}
                setFrom={map.setAssignedUpdatedFrom}
                setTo={map.setAssignedUpdatedTo}
                from={map.assignedUpdatedFrom}
                to={map.assignedUpdatedTo}
              />
            </div>
          </div>
        </>
      )}

      <div style={{ marginBottom: "1rem" }} className="search_filter_container">
        <button
          disabled={assignedColor() === "#E6E6E6"}
          style={{
            backgroundColor: assignedColor(),
          }}
          onClick={() => {
            resetAssignedFilters();
          }}
          className="reset_all_filters"
        >
          Reset Filters
        </button>
        <Accordion
          sx={{
            boxShadow: "none",
            width: "100%",
          }}
        >
          <AccordionSummary
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              {" "}
              {user.userType === "SalesRep"
                ? "My Leads"
                : "Assigned Leads"}{" "}
            </Typography>
            <div className="switch">
              <div className="switch__2">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      map.setMyLeadsEnable(false);
                    }
                    map.setAssignedLeadsEnabled(e.target.checked);
                  }}
                  id="switch-2"
                  type="checkbox"
                  checked={map.assignedLeadsEnabled}
                />
                <label for="switch-2"></label>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="InputsDiv">
              <div className="InputDiv">
                <label htmlFor="assignedConvertedToCustomer">Lead Type</label>
                <select
                  className="p-2 border-2 outline-none rounded-lg"
                  name="assignedConvertedToCustomer"
                  value={assignedConvertedToCustomer ?? "null"}
                  onChange={(e) => {
                    const value = e.target.value;
                    setAssignedConvertedToCustomer(
                      value !== "0"
                        ? value === "true"
                          ? true
                          : value === "false"
                          ? false
                          : null
                        : null
                    );
                  }}
                >
                  <option value="null">All</option>
                  <option value="true">Customer</option>
                  <option value="false">Hot Lead</option>
                </select>
              </div>
              {assignedConvertedToCustomer !== false && (
                <div className="InputDiv">
                  <label htmlFor="accountStatus">Account Status</label>
                  <select
                    className="p-2 border-2 outline-none rounded-lg"
                    name="accountStatus"
                    value={assignedAcountStatus}
                    onChange={(e) => {
                      e.target.value !== "0"
                        ? setAssignedAcountStatus(e.target.value)
                        : setAssignedAcountStatus(null);
                    }}
                  >
                    <option value="0">All</option>
                    <option value="Sold">Sold</option>
                    <option value="VerifiedSold">Verified Sold</option>
                    <option value="PendingCancels">Pending Cancels</option>
                    <option value="PendinReshcedules">
                      Pending Reschedules
                    </option>
                    <option value="Missing">Missing</option>
                    <option value={"Completed"}>Completed</option>
                    <option value="Canceled">Canceled</option>
                    <option value={"Scheduled"}>Pending Installs</option>
                  </select>
                </div>
              )}
              {assignedConvertedToCustomer === false && (
                <div className="InputDiv">
                  <label>Sold</label>
                  <div
                    style={{
                      gridColumn: "span 1",
                      width: "100%",
                    }}
                    className="switch"
                  >
                    <div className="switch__2">
                      <input
                        onChange={(e) => {
                          map.setAssignedProspectSold(e.target.checked);
                        }}
                        id="switch-5"
                        type="checkbox"
                        checked={map.assignedProspectSold}
                      />
                      <label for="switch-5"></label>
                    </div>
                  </div>
                </div>
              )}

              <div className="InputDiv">
                <label htmlFor="DateType">Date Type</label>
                <select
                  value={assignedDateType}
                  onChange={(e) => {
                    setAssignedDateType(e.target.value);
                  }}
                >
                  <option value={"LastUpdated"}>Last Updated</option>
                  <option value={"DateSold"}>Date Sold</option>
                  <option value={"InstallDate"}>Install Date</option>
                  <option value={"AppointmentDate"}>Appointment Date</option>
                  <option value={"CreatedAt"}>Created At</option>
                  {/* <option value={"CancelRequestDate"}>Cancel Request Date</option> */}
                </select>
              </div>

              <SelectionMenu
                tags={tags}
                onChange={(selected) => setAssignedSelectedTags(selected)}
                value={assignedSelectedTags}
                selectedItems = {assignedSelectedTags}
                setSelectedItems = {setAssignedSelectedTags}
                searchCriteria = {searchCriteria}
                setSearchCriteria = {setSearchCriteria}
              />


              <div className="InputDiv">
                <label hmltFor="include_invalid">Include Invalids</label>
                <div
                  style={{
                    gridColumn: "span 1",
                    width: "100%",
                  }}
                  className="switch"
                >
                  <div className="switch__2">
                    <input
                      onChange={(e) => {
                        map.setIncludeInvalid(e.target.checked);
                      }}
                      id="switch-20"
                      type="checkbox"
                      checked={map.includeInvalid}
                    />
                    <label for="switch-20"></label>
                  </div>
                </div>
              </div>

              <QuickDate
                future={assignedAcountStatus === "Scheduled"}
                selectQuickDate={map.assignedQuickDate}
                setQuickDateValue={map.setAssignedQuickDate}
                setFrom={map.setAssignedUpdatedFrom}
                setTo={map.setAssignedUpdatedTo}
              />
              <div className="company_selector">
                <FiberCompanySelector
                  companyObject={map.assignedCompanyWithName}
                  setCompanyObject={map.setAssignedCompanyWithName}
                  multiple={true}
                  setUser={map.setAssignedFiberCompanyIds}
                  placeholder="Select Fiber Company"
                />
              </div>
              {user.userType === "SuperAdmin" && (
                <>
                  <h1 className="lead_type_title">Sales Orgs</h1>
                  <div className="select_deselect">
                    <button
                      className="select"
                      onClick={() => {
                        map.setAssignedSalesOrgs(
                          map.salesOrg.map((org) => org.id)
                        );
                      }}
                    >
                      Select All
                    </button>
                    <button
                      className="deselect"
                      onClick={() => {
                        map.setAssignedSalesOrgs([]);
                      }}
                    >
                      Deselect All
                    </button>
                  </div>
                  <div className="MapIconStats">
                    {map.salesOrg?.map((org, index) => {
                      return (
                        <div
                          style={{
                            // borderRadius: "10px",
                            width: "5rem",
                            height: "5rem",
                          }}
                          onClick={() => {
                            if (map.assignedSalesOrgs.includes(org.id)) {
                              map.setAssignedSalesOrgs(
                                map.assignedSalesOrgs?.filter(
                                  (id) => id !== org.id
                                )
                              );
                            } else {
                              map.setAssignedSalesOrgs([
                                ...map.assignedSalesOrgs,
                                org.id,
                              ]);
                            }
                          }}
                          className={`MapIconStat ${
                            map.assignedSalesOrgs.includes(org.id)
                              ? "active"
                              : null
                          }`}
                          key={index}
                        >
                          <h3>
                            {org.icon ? (
                              <img
                                src={"data:image/png;base64," + org.icon}
                                alt="Map Icon"
                              />
                            ) : (
                              <RiTeamFill size={30} color="black" />
                            )}
                          </h3>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "0.6rem",
                            }}
                          >
                            {org.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {(user.userType === "SuperAdmin" ||
                user.userType === "SalesOrgAdmin") && (
                <>
                  <h1 className="lead_type_title">Sales Reps</h1>
                  <div className="select_deselect">
                    <button
                      className="select"
                      onClick={() => {
                        map.setAssignedSalesRepIds(
                          map.salesReps.map((rep) => rep.id)
                        );
                      }}
                    >
                      Select All
                    </button>
                    <button
                      className="deselect"
                      onClick={() => {
                        map.setAssignedSalesRepIds([]);
                      }}
                    >
                      Deselect All
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "1rem",
                      width: "100%",
                    }}
                  >
                    <div className="switch" style={{ gridGap: "1rem" }}>
                      <div className="switch__2">
                        <input
                          onChange={(e) => {
                            if (map.assignedRepToggle === "AssignedTo") {
                              map.setAssignedRepToggle("UpdatedBy");
                            } else {
                              map.setAssignedRepToggle("AssignedTo");
                            }
                          }}
                          id="switch-3"
                          type="checkbox"
                          checked={map.assignedRepToggle !== "AssignedTo"}
                        />
                        <label for="switch-3"></label>
                      </div>
                    </div>
                    <p>
                      {map.assignedRepToggle === "AssignedTo"
                        ? "Owned By"
                        : "Updated By"}
                    </p>
                  </div>

                  <div className="MapIconStats">
                    {map.salesReps?.map((rep, index) => {
                      if (
                        rep.status !== "Inactive" &&
                        map.assignedSalesOrgs.includes(rep.salesOrgId)
                      )
                        return (
                          <div
                            style={{
                              // borderRadius: "10px",
                              width: "5rem",
                              height: "5rem",
                            }}
                            onClick={() => {
                              if (map.assignedSalesRepIds.includes(rep.id)) {
                                map.setAssignedSalesRepIds(
                                  map.assignedSalesRepIds?.filter(
                                    (id) => id !== rep.id
                                  )
                                );
                              } else {
                                map.setAssignedSalesRepIds([
                                  ...map.assignedSalesRepIds,
                                  rep.id,
                                ]);
                              }
                            }}
                            className={`MapIconStat ${
                              map.assignedSalesRepIds.includes(rep.id)
                                ? "active"
                                : null
                            }`}
                            key={index}
                          >
                            <h3>
                              {rep.profileImage ? (
                                <img
                                  style={{ objectFit: "cover" }}
                                  src={rep.profileImage?.url}
                                  alt="ProfileImage"
                                />
                              ) : (
                                <img
                                  style={{ objectFit: "cover" }}
                                  src={greyUser}
                                  alt="ProfileImage"
                                />
                              )}
                            </h3>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.6rem",
                              }}
                            >
                              {rep.firstName + " " + rep.lastName}
                            </p>
                          </div>
                        );
                    })}
                  </div>
                  {user.userType === "SuperAdmin" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: "1rem",
                        width: "100%",
                      }}
                    >
                      <div className="switch" style={{ gridGap: "1rem" }}>
                        <div className="switch__2">
                          <input
                            onChange={(e) => {
                              if (map.showDeactivatedAccounts) {
                                map.setShowDeactivatedAccounts(false);
                              } else {
                                map.setShowDeactivatedAccounts(true);
                              }
                            }}
                            id="switch-10"
                            type="checkbox"
                            checked={map.showDeactivatedAccounts}
                          />
                          <label for="switch-10"></label>
                        </div>
                      </div>
                      <p>Show Deactivated Accounts</p>
                    </div>
                  )}
                  <div className="MapIconStats">
                    {map.showDeactivatedAccounts &&
                      map.salesReps?.map((rep, index) => {
                        if (
                          rep.status === "Inactive" &&
                          map.assignedSalesOrgs.includes(rep.salesOrgId)
                        )
                          return (
                            <div
                              style={{
                                // borderRadius: "10px",
                                width: "5rem",
                                height: "5rem",
                              }}
                              onClick={() => {
                                if (map.assignedSalesRepIds.includes(rep.id)) {
                                  map.setAssignedSalesRepIds(
                                    map.assignedSalesRepIds?.filter(
                                      (id) => id !== rep.id
                                    )
                                  );
                                } else {
                                  map.setAssignedSalesRepIds([
                                    ...map.assignedSalesRepIds,
                                    rep.id,
                                  ]);
                                }
                              }}
                              className={`MapIconStat ${
                                map.assignedSalesRepIds.includes(rep.id)
                                  ? "active"
                                  : null
                              }`}
                              key={index}
                            >
                              <h3>
                                {rep.profileImage ? (
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={rep.profileImage?.url}
                                    alt="ProfileImage"
                                  />
                                ) : (
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={greyUser}
                                    alt="ProfileImage"
                                  />
                                )}
                              </h3>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.6rem",
                                }}
                              >
                                {rep.firstName + " " + rep.lastName}
                              </p>
                            </div>
                          );
                      })}
                  </div>
                </>
              )}
              <h1 className="lead_type_title">Lead Status</h1>
              <div className="select_deselect">
                <button
                  className="select"
                  onClick={() => {
                    setAssignedMapIcons(map.mapIcons.map((icon) => icon.id));
                  }}
                >
                  Select All
                </button>
                <button
                  className="deselect"
                  onClick={() => {
                    setAssignedMapIcons([]);
                  }}
                >
                  Deselect All
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <div className="switch" style={{ gridGap: "1rem" }}>
                  <div className="switch__2">
                    <input
                      onChange={(e) => {
                        if (map.mapIconToggle === "CurrentMapIcon") {
                          map.setMapIconToggle("OnceWas");
                        } else {
                          map.setMapIconToggle("CurrentMapIcon");
                        }
                      }}
                      id="switch-5"
                      type="checkbox"
                      checked={map.mapIconToggle !== "CurrentMapIcon"}
                    />
                    <label for="switch-5"></label>
                  </div>
                </div>
                <p>
                  {map.mapIconToggle === "CurrentMapIcon"
                    ? "Current Map Icon"
                    : "Past Map Icon"}
                </p>
              </div>
              <div className="MapIconStats">
                {map.mapIcons
                  ?.filter((icon) => icon.iconType === null)
                  ?.map((icon, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          if (assignedMapIcons.includes(icon.id)) {
                            setAssignedMapIcons(
                              assignedMapIcons?.filter((id) => id !== icon.id)
                            );
                          } else {
                            setAssignedMapIcons([...assignedMapIcons, icon.id]);
                          }
                        }}
                        className={`MapIconStat  ${
                          assignedMapIcons?.includes(icon.id) ? "active" : null
                        }`}
                      >
                        <h3>
                          <img
                            src={"data:image/png;base64," + icon.image}
                            alt="Map Icon"
                          />
                        </h3>
                        <p>{icon.abbreviation}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <h1 className="action_title">Quick Search</h1>
        <div className="action_buttons">
          <button
            className={`action_button ${
              assignedAcountStatus === "" ? "active" : null
            }`}
            onClick={() => {
              setAssignedAcountStatus("");
            }}
          >
            All
          </button>
          <button
            className={`action_button ${
              assignedAcountStatus === "Sold" ? "active" : null
            }`}
            onClick={() => {
              setAssignedAcountStatus("Sold");
            }}
          >
            Sold
          </button>
          <button
            className={`action_button ${
              assignedAcountStatus === "Completed" ? "active" : null
            }`}
            onClick={() => {
              setAssignedAcountStatus("Completed");
            }}
          >
            Completed
          </button>
          <button
            className={`action_button ${
              assignedAcountStatus === "Canceled" ? "active" : null
            }`}
            onClick={() => {
              setAssignedAcountStatus("Canceled");
            }}
          >
            Canceled
          </button>
          <button
            className={`action_button ${
              assignedAcountStatus === "Scheduled" ? "active" : null
            }`}
            onClick={() => {
              if (
                assignedAcountStatus !== "Scheduled" &&
                map.assignedQuickDate !== "Today"
              ) {
                map.setAssignedQuickDate("");
                map.setAssignedUpdatedFrom("");
                map.setAssignedUpdatedTo("");
              }
              setAssignedAcountStatus("Scheduled");
            }}
          >
            Installs Pending
          </button>
          <button
            className={`action_button ${
              assignedAcountStatus === "CancelsPending" ? "active" : null
            }`}
            onClick={() => {
              setAssignedAcountStatus("CancelsPending");
            }}
          >
            Cancels Pending
          </button>
        </div>
        <div className="action_date InputsDiv">
          <QuickDate
            future={assignedAcountStatus === "Scheduled"}
            selectQuickDate={map.assignedQuickDate}
            setQuickDateValue={map.setAssignedQuickDate}
            setFrom={map.setAssignedUpdatedFrom}
            setTo={map.setAssignedUpdatedTo}
            from={map.assignedUpdatedFrom}
            to={map.assignedUpdatedTo}
          />
        </div>
      </div>
      {(user.userType === "SuperAdmin" ||
        user.userType === "SalesOrgAdmin") && (
        <div className="search_filter_container">
          <button
            disabled={unassignedColor() === "#E6E6E6"}
            style={{
              backgroundColor: unassignedColor(),
            }}
            onClick={() => {
              resetUnassignedFilters();
            }}
            className="reset_all_filters"
          >
            Reset Filters
          </button>
          <Accordion
            sx={{
              boxShadow: "none",
              width: "100%",
            }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Unassigned Leads</Typography>
              <div className="switch">
                <div className="switch__2">
                  <input
                    onChange={(e) => {
                      map.setUnassignedLeadsEnabled(e.target.checked);
                    }}
                    id="switch-4"
                    type="checkbox"
                    checked={map.unassignedLeadsEnabled}
                  />
                  <label for="switch-4"></label>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="InputsDiv">
                <div className="InputDiv">
                  <label htmlFor="assignedConvertedToCustomer">Lead Type</label>
                  <select
                    className="p-2 border-2 outline-none rounded-lg"
                    name="assignedConvertedToCustomer"
                    value={
                      unassignedConvertedToCustomer === true
                        ? "true"
                        : unassignedConvertedToCustomer === false
                        ? "false"
                        : "null"
                    }
                    onChange={(e) => {
                      setUnassignedConvertedToCustomer(
                        e.target.value === "true"
                          ? true
                          : e.target.value === "false"
                          ? false
                          : null
                      );
                    }}
                  >
                    <option value="null">All</option>
                    <option value="true">Customer</option>
                    <option value="false">Hot Lead</option>
                  </select>
                </div>
                {unassignedConvertedToCustomer !== false && (
                  <div className="InputDiv">
                    <label htmlFor="accountStatus">Account Status</label>
                    <select
                      className="p-2 border-2 outline-none rounded-lg"
                      name="accountStatus"
                      value={unassignedAcountStatus}
                      onChange={(e) => {
                        e.target.value !== "0"
                          ? setUnassignedAcountStatus(e.target.value)
                          : setUnassignedAcountStatus(null);
                      }}
                    >
                      <option value="0">All</option>
                      <option value="Sold">Sold</option>
                      <option value={"Completed"}>Completed</option>
                      <option value="Canceled">Canceled</option>
                      <option value={"Scheduled"}>Pending Installs</option>
                    </select>
                  </div>
                )}
                <div className="InputDiv">
                  <label htmlFor="DateType">Date Type</label>
                  <select
                    value={unassignedDateType}
                    onChange={(e) => {
                      setUnassignedDateType(e.target.value);
                    }}
                  >
                    <option value={"LastUpdated"}>Last Updated</option>
                    <option value={"DateSold"}>Date Sold</option>
                    <option value={"InstallDate"}>Install Date</option>
                    <option value={"AppointmentDate"}>Appointment Date</option>
                  </select>
                </div>

                <SelectionMenu
                  tags={tags}
                  onChange={(selected) => setUnassignedSelectedTags(selected)}
                  value={unassignedSelectedTags}
                  selectedItems = {unassignedSelectedTags}
                  setSelectedItems = {setUnassignedSelectedTags}
                  searchCriteria = {searchCriteria}
                setSearchCriteria = {setSearchCriteria}
                />


                <QuickDate
                  future={unassignedAcountStatus === "Scheduled"}
                  selectQuickDate={map.unassignedQuickdate}
                  setQuickDateValue={map.setUnassignedQuickDate}
                  from={map.unassignedUpdatedFrom}
                  to={map.unassignedUpdatedTo}
                  setFrom={map.setUnassignedUpdatedFrom}
                  setTo={map.setUnassignedUpdatedTo}
                />

                <div className="company_selector">
                  <FiberCompanySelector
                    companyObject={map.unassignedCompanyWithName}
                    setCompanyObject={map.setUnassignedCompanyWithName}
                    multiple={true}
                    setUser={map.setUnassignedFiberCompanyIds}
                    placeholder="Select Fiber Company"
                  />
                </div>

                {user.userType === "SuperAdmin" && (
                  <>
                    <h1 className="lead_type_title">Sales Orgs</h1>
                    <div className="select_deselect">
                      <button
                        className="select"
                        onClick={() => {
                          map.setUnassignedSalesOrgs(
                            map.salesOrg.map((org) => org.id)
                          );
                        }}
                      >
                        Select All
                      </button>
                      <button
                        className="deselect"
                        onClick={() => {
                          map.setUnassignedSalesOrgs([]);
                        }}
                      >
                        Deselect All
                      </button>
                    </div>
                    <div className="MapIconStats">
                      {map.salesOrg?.map((org, index) => {
                        return (
                          <div
                            style={{
                              // borderRadius: "10px",
                              width: "5rem",
                              height: "5rem",
                            }}
                            onClick={() => {
                              if (map.unassignedSalesOrgs.includes(org.id)) {
                                map.setUnassignedSalesOrgs(
                                  map.unassignedSalesOrgs?.filter(
                                    (id) => id !== org.id
                                  )
                                );
                              } else {
                                map.setUnassignedSalesOrgs([
                                  ...map.unassignedSalesOrgs,
                                  org.id,
                                ]);
                              }
                            }}
                            className={`MapIconStat ${
                              map.unassignedSalesOrgs.includes(org.id)
                                ? "active"
                                : null
                            }`}
                            key={index}
                          >
                            <h3>
                              {org.icon ? (
                                <img
                                  src={"data:image/png;base64," + org.icon}
                                  alt="Map Icon"
                                />
                              ) : (
                                <RiTeamFill size={30} color="black" />
                              )}
                            </h3>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.6rem",
                              }}
                            >
                              {org.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <h1 className="lead_type_title">Lead Status</h1>
                <div className="select_deselect">
                  <button
                    className="select"
                    onClick={() => {
                      setUnassignedMapIcons(
                        map.mapIcons.map((icon) => icon.id)
                      );
                    }}
                  >
                    Select All
                  </button>
                  <button
                    className="deselect"
                    onClick={() => {
                      setUnassignedMapIcons([]);
                    }}
                  >
                    Deselect All
                  </button>
                </div>

                <div className="MapIconStats">
                  {map.mapIcons
                    ?.filter((icon) => icon.iconType === null)
                    .map((icon, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (unassignedMapIcons.includes(icon.id)) {
                              setUnassignedMapIcons(
                                unassignedMapIcons.filter(
                                  (id) => id !== icon.id
                                )
                              );
                            } else {
                              setUnassignedMapIcons([
                                ...unassignedMapIcons,
                                icon.id,
                              ]);
                            }
                          }}
                          className={`MapIconStat  ${
                            unassignedMapIcons.includes(icon.id)
                              ? "active"
                              : null
                          }`}
                        >
                          <h3>
                            <img
                              src={"data:image/png;base64," + icon.image}
                              alt="Map Icon"
                            />
                          </h3>
                          <p>{icon.abbreviation}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <h1 className="action_title">Quick Search</h1>
          <div className="action_buttons">
            <button
              className={`action_button ${
                unassignedAcountStatus === "" ? "active" : null
              }`}
              onClick={() => {
                setUnassignedAcountStatus("");
              }}
            >
              All
            </button>
            <button
              className={`action_button ${
                unassignedAcountStatus === "Sold" ? "active" : null
              }`}
              onClick={() => {
                setUnassignedAcountStatus("Sold");
              }}
            >
              Sold
            </button>
            <button
              className={`action_button ${
                unassignedAcountStatus === "Completed" ? "active" : null
              }`}
              onClick={() => {
                setUnassignedAcountStatus("Completed");
              }}
            >
              Completed
            </button>
            <button
              className={`action_button ${
                unassignedAcountStatus === "Canceled" ? "active" : null
              }`}
              onClick={() => {
                setUnassignedAcountStatus("Canceled");
              }}
            >
              Canceled
            </button>
            <button
              className={`action_button ${
                unassignedAcountStatus === "Scheduled" ? "active" : null
              }`}
              onClick={() => {
                if (
                  unassignedAcountStatus !== "Scheduled" &&
                  map.unassignedQuickDate !== "Today"
                ) {
                  map.setUnassignedQuickDate("");
                  map.setUnassignedUpdatedFrom("");
                  map.setUnassignedUpdatedTo("");
                }
                setUnassignedAcountStatus("Scheduled");
              }}
            >
              Installs Pending
            </button>
            <button
              className={`action_button ${
                unassignedAcountStatus === "CancelsPending" ? "active" : null
              }`}
              onClick={() => {
                setUnassignedAcountStatus("CancelsPending");
              }}
            >
              Cancels Pending
            </button>
          </div>
          <div className="action_date InputsDiv">
            <QuickDate
              future={unassignedAcountStatus === "Scheduled"}
              selectQuickDate={map.unassignedQuickDate}
              setQuickDateValue={map.setUnassignedQuickDate}
              setFrom={map.setUnassignedUpdatedFrom}
              setTo={map.setUnassignedUpdatedTo}
              from={map.unassignedUpdatedFrom}
              to={map.unassignedUpdatedTo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilters;

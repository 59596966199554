import clsx from "clsx";

export default function NewCard({
  title,
  description,
  content,
  footerChildren,
  contentLarge = false,
  center = false,
  img,
  className,
}) {
  return (
    <Card className={className}>
      {(title || description || img) && (
        <CardHeader
          className={
            (contentLarge ? "flex justify-center items-center" : "") +
            (center ? "text-center" : "")
          }
        >
          {img && <img src={img} className="mx-auto mb-4" />}
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription> {description}</CardDescription>}
        </CardHeader>
      )}

      <CardContent className={contentLarge ? "text-4xl text-center" : ""}>
        {content}
      </CardContent>
      {footerChildren && <CardFooter>{footerChildren}</CardFooter>}
    </Card>
  );
}

function Card({ className, ...props }) {
  return (
    <div
      data-slot="card"
      className={clsx(
        "rounded-lg border bg-white text-black  shadow-sm",
        className
      )}
      {...props}
    />
  );
}

function CardHeader({ className, ...props }) {
  return (
    <div
      data-slot="card-header"
      className={clsx("flex flex-col space-y-1.5 p-6", className)}
      {...props}
    />
  );
}

function CardTitle({ className, ...props }) {
  return (
    <div
      data-slot="card-title"
      className={clsx(
        "text-2xl font-semibold leading-none tracking-tight",
        className
      )}
      {...props}
    />
  );
}

function CardDescription({ className, ...props }) {
  return (
    <div
      data-slot="card-description"
      className={clsx("text-sm text-muted-foreground", className)}
      {...props}
    />
  );
}

function CardContent({ className, ...props }) {
  return (
    <div
      data-slot="card-content"
      className={clsx("p-6 pt-0", className)}
      {...props}
    />
  );
}

function CardFooter({ className, ...props }) {
  return (
    <div
      data-slot="card-footer"
      className={clsx("flex items-center p-6 pt-0", className)}
      {...props}
    />
  );
}

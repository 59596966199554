class GetCompanyConsultationsReq {
  constructor() {
    this.statuses = [];
    this.industries = [];
    this.pagingParams = {
      pageNumber: 1,
      pageSize: 30,
    };
    this.candidateId = null;
  }
}
export default GetCompanyConsultationsReq;

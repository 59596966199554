import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../Layouts/Layout";
import Leads from "./Leads";
import TeamMembers from "./TeamMembers";
import "./ContactCenter.scss";
import { SearchFiltersContext } from "../../../App";
import VersatileLoader from "../../../components/VersatileLoader";
import { ContactCenterWrapperContext } from "../ContactCenterWrapper";
const ContactCenter = () => {
  const [tab, setTab] = useState("Leads");

  const searchFilter = useContext(SearchFiltersContext);
  const { ToggleComponent } = useContext(ContactCenterWrapperContext);

  const { contactCenter } = searchFilter.current || {};
  const {
    fromDate,
    toDate,
    salesReps,
    quickDateValue,
    type,
    passedOrgIds,
    knocked,
    leadType,
    dateType,
    fiberCompanies,
    customerName,
    source,
  } = contactCenter || {};

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [searchFilter.current]);
  return (
    <Layout>
      <ToggleComponent />
      <div className="ContactCenter">
        {
          <div className="HeaderDiv">
            <button
              className={`${tab === "Leads" && "active"}`}
              onClick={() => setTab("Leads")}
            >
              Leads
            </button>
            <button
              className={`${tab === "Team" && "active"}`}
              onClick={() => setTab("Team")}
            >
              Team
            </button>
          </div>
        }
        <div className="ContentDiv">
          {tab === "Leads" ? (
            !loading ? (
              <Leads
                fiberCompanyIds={fiberCompanies}
                knocked={knocked}
                leadType={leadType}
                dateTypeProp={dateType}
                fromDate={fromDate}
                toDate={toDate}
                salesReps={salesReps}
                quickDateValue={quickDateValue}
                type={type}
                passedOrgIds={passedOrgIds}
                customerName={customerName}
                source={source}
              />
            ) : (
              <VersatileLoader color="#2761D0" size="large" />
            )
          ) : (
            <TeamMembers />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ContactCenter;

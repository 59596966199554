import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

const PointPlan = ({ plan, offer, setSpeedOpen,setSelectedPlan,setOpen,setColor, bulletPoints }) => {

    const getPlanSpeed = () => {
        if (plan?.speed === 1) {
            return <>1 GIG <span className='text-xl '>1000 Mbps</span> </>
        } else {
            return `${plan?.speed * 1000} Mbps`
        }
    }

    function onClick() {
        setSelectedPlan(plan)
        setOpen(true)
        setColor("#F47920")
    }

    return (
        <div className='bg-white max-w-[600px] text-black p-8 rounded-3xl z-[10] relative flex flex-col justify-center items-center'>
            <div className='sm:h-36 h-24 w-auto'>
                <img src="/images/offer/pointbroadband/logoforplan.png" alt='banner' className='sm:h-36 h-24 object-contain' />
            </div>
            <div>
                <p className='sm:text-9xl text-7xl font-[800] text-black mt-4'>${plan?.price} <span className='sm:text-5xl text-4xl'>/mo*</span> </p>
                <p className='sm:text-4xl text-3xl text-[#F47920] font-[700]'>Speeds Up<br />to <span className='text-black text-5xl sm:text-7xl'>{getPlanSpeed()}</span></p>
                <p className='text-2xl my-4 font-medium'>Upload/Download Speeds</p>
                <ul className='flex flex-col gap-2'>
                    {bulletPoints.split('\n').map((bulletPoint, index) => <li className='flex items-center justify-start gap-4' key={index}>
                        <FaCheckCircle color={"#F47920"} size={25} />
                        <p>{bulletPoint}</p>
                    </li>)}
                </ul>
                <div className='flex w-full my-4 flex-col gap-2'>
                    <button onClick={()=>{onClick()}} className='bg-black py-4 w-full rounded-3xl text-white text-3xl font-[800]'>Accept Offer</button>
                    <p className='text-sm font-medium text-gray-400'>* eBill/ Autopay Required otherwise additional $10 per month</p>
                </div>
            </div>
        </div>
    )
}

export default PointPlan
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
// import LinkTool from "@editorjs/link";
import ImageTool from "@editorjs/image";
// import SimpleImage from "@editorjs/simple-image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Paragraph from "@editorjs/paragraph";
// import SimpleVideo from "simple-video-editorjs";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import CreateFile from "../../../../Requests/CloudFile/CreateFileReq";
import VideoTool from "@weekwood/editorjs-video";
import Quiz from "../components/QuizTool";
import Question from "../components/QuestionTool";
import "./constants.scss";
import { CheckboxIcon, QuizIcon, RadioInputIcon } from "./icons";
import FontFamilyTool from "./FontFamilyTool"; // Add this import
// const VideoTool = window.VideoTool;


const storage = getStorage();

const submitCallback = (data) => {
  Swal.fire({
    title: "Quiz Data",
    html: JSON.stringify(data, null, 2),
    confirmButtonText: "Close",
  });
};
const uploadAndGetData = async (file, user) => {
  const uniqueId = uuidv4();
  let p = `/ManageTrainingModule/${user.firebaseId}/${uniqueId}`;

  const storageRef = ref(storage, p);
  const task = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    task.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        Swal.update({
          text: `Uploading File ${progress.toFixed(0)}%`,
          customClass: { container: "custom-swal" },
        });
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            console.log("Unknown state: " + snapshot.state);
            break;
        }
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      async () => {
        console.log("Upload is completed");
        const downloadURL = await getDownloadURL(storageRef);
        const createFile = new CreateFile();
        createFile.name = file.name;
        createFile.extension = createFile.name.split(".").pop();
        createFile.url =
          file.type === "video/mp4" ? `${downloadURL}.mp4` : downloadURL;
        createFile.path = p;
        createFile.fileSize = file.size.toString();
        createFile.userId = user.id;
        createFile.fileType = file.type;

        resolve({
          success: 1,
          file: {
            url: createFile.url,
            name: createFile.name,
            extension: createFile.extension,
            size: createFile.fileSize,
            type: createFile.fileType,
          },
        });
      }
    );
  });
};

const uploadAndGetVideoData = async (file, user) => {
  const uniqueId = uuidv4();
  let p = `/ManageTrainingModule/${user.firebaseId}/${uniqueId}`;

  const storageRef = ref(storage, p);
  const task = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    task.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        Swal.update({
          text: `Uploading Video ${progress.toFixed(0)}%`,
          customClass: { container: "custom-swal" },
        });
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            console.log("Unknown state: " + snapshot.state);
            break;
        }
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      async () => {
        console.log("Upload is completed");
        const downloadURL = await getDownloadURL(storageRef);
        const createFile = new CreateFile();
        createFile.name = file.name;
        createFile.extension = createFile.name.split(".").pop();
        createFile.url = `${downloadURL}.mp4`;
        createFile.path = p;
        createFile.fileSize = file.size.toString();
        createFile.userId = user.id;
        createFile.fileType = file.type;

        resolve({
          success: 1,
          file: {
            url: createFile.url,
            name: createFile.name,
            extension: createFile.extension,
            size: createFile.fileSize,
            type: createFile.fileType,
          },
        });
      }
    );
  });
};

export const getEditorJsTools = (user, makeReadOnly = false) => {
  return {
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeo: true,
          twitter: true,
          instagram: true,
        },
      },
    },
    quiz: {
      class: Quiz,
      config: {
        onSubmit: submitCallback,
        language : "en",
        makeReadOnly: makeReadOnly,
      },
      toolbox: {
        title: "MCQ",
        icon: QuizIcon,
      },
    },
    question:{
      class: Question,
      config: {
        labelPlaceholder: "Label",
        placeholder: "Placeholder",
        helpPlaceholder: "Help",
        required: true,
        makeReadOnly: makeReadOnly,
      },
    },
    video:{
      class: VideoTool,
      config: {
        types: "video/*",
        uploader: {
          async uploadByFile(file) {
            try {
              const result = await uploadAndGetVideoData(file, user);
              return result;
            } catch (error) {
              console.error("Error uploading video:", error);
              return { success: 0 };
            }
          },
          async uploadByUrl(url) {
            try {
              const response = await fetch(url);
              const blob = await response.blob();
              const file = new File([blob], blob.name, { type: blob.type });
              const result = await uploadAndGetVideoData(file, user);
              return result;
            } catch (error) {
              console.error("Error uploading video by URL:", error);
              return { success: 0 };
            }
          },
        },
        player: {
          pip: false,
          controls: true,
          light: false,
          playing: false,
        }
      },
    },
    table: {
      class: Table,
      config: {
        rows: 2,
        cols: 3,
      },
    },
    list: {
      class: List,
      inlineToolbar: true,
    },
    warning: {
      class: Warning,
      inlineToolbar: true,
      config: {
        titlePlaceholder: "Title",
        messagePlaceholder: "Message",
      },
    },
    code: {
      class: Code,
    },
    image: {
      class: ImageTool,
      config: {
        types: "image/*,video/mp4",
        uploader: {
          async uploadByFile(file) {
            try {
              const result = await uploadAndGetData(file, user);
              return result;
            } catch (error) {
              console.error("Error uploading file:", error);
              return { success: 0 };
            }
          },
          async uploadByUrl(url) {
            try {
              const response = await fetch(url);
              const blob = await response.blob();
              const file = new File([blob], "image.jpg", { type: blob.type });
              const result = await uploadAndGetData(file, user);
              return result;
            } catch (error) {
              console.error("Error uploading file by URL:", error);
              return { success: 0 };
            }
          },
        },
      },
      features: {
        border: true,
        caption: "optional",
        stretch: false,
      },
    },
    // simpleImage: {
    //   class: SimpleImage,
    // },
    raw: {
      class: Raw,
    },
    header: {
      class: Header,
      inlineToolbar: true,
      config: {
        levels: [1, 2, 3, 4, 5, 6],
        defaultLevel: 2,
      },
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      config: {
        quotePlaceholder: "Enter a quote",
        captionPlaceholder: "Quote's author",
      },
    },
    marker: {
      class: Marker,
    },
    checklist: {
      class: CheckList,
      inlineToolbar: true,
    },
    delimiter: {
      class: Delimiter,
    },
    inlineCode: {
      class: InlineCode,
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
      config: {
        placeholder: "Enter your text here...",
        inlineToolbar: ['bold', 'italic', 'underline', 'fontFamily'], // Add 'fontFamily' here
      },
    },
    // fontFamily: FontFamilyTool, // Add this line
    
  };
};



export const TYPES = {
  singleSelect: "singleSelect",
  multiSelect: "multiSelect",
};

export const settings = [
  {
    title: "Single Select",
    name: "single-select",
    type: TYPES.singleSelect,
    icon: RadioInputIcon,
    className: "qt-settings-icon__single",
  },
  {
    title: "Multi Select",
    name: "multi-select",
    type: TYPES.multiSelect,
    icon: CheckboxIcon,
    className: "qt-settings-icon__multi",
  },
];

export const Toolbox = {
  title: "Quiz",
  icon: QuizIcon,
};

export const LANGUAGES = ["uz", "en"];

export const TEXTS = {
  uz: {
    errors: {
      required: "Iltimos javobni tanlang",
    },
    footer: {
      submit: "Tekshirish",
    },
  },
  en: {
    errors: {
      required: "Please pick the answer",
    },
    footer: {
      submit: "Submit",
    },
  },
};
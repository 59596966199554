const BASE_URL = "https://lightningos.org";
// getAllTags
export const getAllTags = async () => {
  try {
    const response = await fetch(`${BASE_URL}/Tags`, {
      method: "POST",
    });

    return await response.json();
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};
// getAllTagsWithAssoication
export const getAllTagsWithAssoication = async () => {
  try {
    const response = await fetch(`${BASE_URL}/AllTagsWithLeadsAndCandidates`, {
      method: "POST",
    });
    return await response.json();
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

// Delete a Tag
export const deleteTag = async (tagId) => {
  try {
    const response = await fetch(`${BASE_URL}/DeleteTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: tagId }),
    });
    // console.log("Tag successfully deleted:", response.data);
    return await response.json();
  } catch (error) {
    console.error("Failed to delete the tag:", error.message);
  }
};

// Create a Tag
export const createTag = async (tagData) => {
  try {
    const response = await fetch(`${BASE_URL}/CreateTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagData),
    });
    // console.log("Tag successfully created:", response.data);
    return await response.json();
  } catch (error) {
    console.error("Failed to create the tag:", error.message);
    throw error;
  }
};

// Assoicate Tag
export const assoicateTag = async (data) => {
  console.log({ data });
  try {
    const response = await fetch(`${BASE_URL}/AssociateTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    // console.log("Tag successfully associated", response.data);
    return await response.json();
  } catch (error) {
    console.error("Failed to associate the tag:", error.message);
    throw error;
  }
};
// Assoicate Tag
export const disAssoicateTag = async (data) => {
  console.log({ data });
  try {
    const response = await fetch(`${BASE_URL}/DisassociateTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    // console.log("Tag successfully disAssociated", response.data);
    return await response.json();
  } catch (error) {
    console.error("Failed to disAssociate the tag:", error.message);
    throw error;
  }
};

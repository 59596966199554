import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class FiberHouseApi{
    baseUrl = EnvSettings.url;
    CreateFiberHouseUrl = "CreateFiberHouseWithLead";
    BatchCreateFiberHouseWithLeadUrl = "BatchCreateFiberHousesWithLead";
    BatchCreateCandidateLeadsUrl = "BatchCreateCandidates";
    SearchFiberHouseUrl = "SearchFiberHouses";
    SearchFiberHousesOptimizedUrl = "SearchFiberHousesOptimized";
    searchFiberHousesOptimizedLightUrl = "SearchFiberHousesOptimizedLight";
    SearchFiberHousesOptimizedLightAssignedUnassignedUrl = "SearchFiberHousesOptimizedLightAssignedUnassigned";
    EditFiberHouseUrl = "EditFiberHouse";
    EditCandidateLeadUrl = "EditCandidate";
    DeleteFiberHouseUrl = "DeleteFiberHouse";
    EditLeadUrl = "EditLead";
    AssignRepsToLeadsUrl = "AssignRepsToLeads";
    RemoveRepsFromLeadsUrl = "RemoveRepsFromLeads";
    AddFiberHouseToSalesOrgUrl = "AddFiberHousesToSalesOrg";
    GetLeadByIdUrl = "GetLeadById";
    GetLeadByFiberHouseIdUrl = "GetLeadByFiberHouseId";
    BulkUpdateFiberHouseCoordinatesUrl = "BulkUpdateFiberHouseCoordinates";
    UpdateLeadAppointmentUrl = "UpdateLeadAppointment";
    RemoveFiberHousesFromSalesOrgUrl = "RemoveFiberHousesFromSalesOrg";
    MarkLeadAsBundledUrl = "MarkLeadAsBundled";
    MarkLeadHasVideoDoorBellUrl = "MarkLeadHasVideoDoorBell";
    UpdateSpeedTestImagesUrl = "UpdateSpeedTestImages";
    AddLeadsToCompanyUrl = "AddLeadsToCompany";
    GetNumberOfRequestedCustomersUrl = "GetNumberOfRequestedCustomers";
    CreateConsultationUrl = "CreateConsultation";
    IgnoreRequestedCustomersUrl = "IgnoreRequestedCustomers";
    GetCandidateLeadDetailsUrl = "GetCandidateById";
    UpdateCandidateLeadAppointmentUrl = "UpdateCandidateAppointmentDateTime";
    getCandidateCompaniesUrl = "GetCandidateCompanies";
    GetCandidateIndustriesUrl = "GetCandidateIndustries";
    GetCandidatePositionTitlesUrl = "GetCandidatePositionTitles";
    DeleteCandidateUrl = "DeleteCandidate";
    GetCandidatesByIdsUrl = "GetCandidatesByIds";
    CreateCandidateUrl = "CreateCandidate";
    HireCandidateUrl = "HireCandidate";
    SendCandidateInvitationUrl = "SendCandidateInvitation";
    SetAppointmentTimeForRequestCallUrl = "SetAppointmentTimeForRequestCall";    
    BulkSendCandidateInvitationsUrl = "BulkSendCandidateInvitations";
    RemoveProspectUrl = "RemoveProspect";
    UploadFidiumCustomersUrl = "UploadFidiumCustomers";
    SendCandidateInvitationWithSMSUrl = "SendCandidateInvitationWithSMS";
    BulkSendInvitationWithSMSUrl = "BulkSendInvitationWithSMS";
    AddPitchUrl = "AddPitch";  
    GetPitchesUrl = "GetPitches";
    DeletePitchUrl = "DeletePitch";
    AddPitchUrl = "AddPitch";   
    SearchSoldFiberHousesForBoltModeUrl = "SearchSoldFiberHousesForBoltMode";
  
    async SearchSoldFiberHousesForBoltMode(req)
    {
        const res = await fetch(this.baseUrl + this.SearchSoldFiberHousesForBoltModeUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async BulkSendInvitationWithSMS(req)
    {
        const res = await fetch(this.baseUrl + this.BulkSendInvitationWithSMSUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    } 

    async SendCandidateInvitationWithSMS(req)
    {
        const res = await fetch(this.baseUrl + this.SendCandidateInvitationWithSMSUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async UploadFidiumCustomers(req)
    {
        const res = await fetch(this.baseUrl + this.UploadFidiumCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    async RemoveProspect(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveProspectUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        const data = await res.json();
        return data;
    }

    //CreateCandidate
    async CreateCandidate(req)
    {
        const res = await fetch(this.baseUrl + this.CreateCandidateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    async GetCandidateCompanies()
    {
        const res = await fetch(this.baseUrl + this.getCandidateCompaniesUrl, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${UsersApi.GetToken()}`
            }
        })
        const data = await res.json();
        return data;
    }

    async GetCandidateIndustries()
    {
        const res = await fetch(this.baseUrl + this.GetCandidateIndustriesUrl, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${UsersApi.GetToken()}`
            }
        })
        const data = await res.json();
        return data;
    }

    async GetCandidatePositionTitles()
    {
        const res = await fetch(this.baseUrl + this.GetCandidatePositionTitlesUrl, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${UsersApi.GetToken()}`
            }
        })
        const data = await res.json();
        return data;
    }    

    async SetAppointmentTimeForRequestCall(req)
    {
        const res = await fetch(this.baseUrl + this.SetAppointmentTimeForRequestCallUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async IgnoreRequestedCustomers(req)
    {
        const res = await fetch(this.baseUrl + this.IgnoreRequestedCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateConsultation(req)
    {
        const res = await fetch(this.baseUrl + this.CreateConsultationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    

    async GetNumberOfRequestedCustomers(req)
    {
        const res = await fetch(this.baseUrl + this.GetNumberOfRequestedCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async AddLeadsToCompany(req)
    {
        const res = await fetch(this.baseUrl + this.AddLeadsToCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkLeadHasVideoDoorBell(req)
    {
        const res = await fetch(this.baseUrl + this.MarkLeadHasVideoDoorBellUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkLeadAsBundled(req)
    {
        const res = await fetch(this.baseUrl + this.MarkLeadAsBundledUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async RemoveFiberHousesFromSalesOrg(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveFiberHousesFromSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UpdateLeadAppointment(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateLeadAppointmentUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    

    async GetLeadByFiberHouseId(req)
    {
        const res = await fetch(this.baseUrl + this.GetLeadByFiberHouseIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHousesOptimizedLight(req)
    {
        const res = await fetch(this.baseUrl + this.searchFiberHousesOptimizedLightUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async SearchFiberHousesOptimizedLightAssignedUnassigned(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHousesOptimizedLightAssignedUnassignedUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async BulkUpdateFiberHouseCoordinates(req)
    {
        const res = await fetch(this.baseUrl + this.BulkUpdateFiberHouseCoordinatesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetLeadById(req)
    {
        const res = await fetch(this.baseUrl + this.GetLeadByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddFiberHouseToSalesOrg(req)
    {
        const res = await fetch(this.baseUrl + this.AddFiberHouseToSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async RemoveRepsFromLeads(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveRepsFromLeadsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AssignRepsToLeads(req)
    {
        const res = await fetch(this.baseUrl + this.AssignRepsToLeadsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.CreateFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async BatchCreateFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.BatchCreateFiberHouseWithLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.EditFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHousesOptimized(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHousesOptimizedUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditLead(req)
    {
        const res = await fetch(this.baseUrl + this.EditLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //UpdateSpeedTestImages
    async UpdateSpeedTestImages(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateSpeedTestImagesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //GetCandidateLeadDetails
    async GetCandidateLeadDetails(req)
    {
        const res = await fetch(this.baseUrl + this.GetCandidateLeadDetailsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //updateCandidateLeadAppointment
    async UpdateCandidateLeadAppointment(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateCandidateLeadAppointmentUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //EditCandidateLead
    async EditCandidateLead(req)
    {
        const res = await fetch(this.baseUrl + this.EditCandidateLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //BatchCreateCandidateLeads
    async BatchCreateCandidateLeads(req)
    {
        const res = await fetch(this.baseUrl + this.BatchCreateCandidateLeadsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //DeleteCandidate
    async DeleteCandidate(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteCandidateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //getCandidatesByIds
    async GetCandidatesByIds(req)
    {
        const res = await fetch(this.baseUrl + this.GetCandidatesByIdsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //HireCandidate
    async HireCandidate(req)
    {
        const res = await fetch(this.baseUrl + this.HireCandidateUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //SendCandidateInvitation
    async SendCandidateInvitation(req)
    {
        const res = await fetch(this.baseUrl + this.SendCandidateInvitationUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }
    //BulkSendCandidateInvitations
    async BulkSendCandidateInvitations(req)
    {
        const res = await fetch(this.baseUrl + this.BulkSendCandidateInvitationsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    //AddPitch
    async AddPitch(req)
    {
        const res = await fetch(this.baseUrl + this.AddPitchUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    //GetPitches
    async GetPitches(req)
    {
        const res = await fetch(this.baseUrl + this.GetPitchesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }

    //DeletePitch
    async DeletePitch(req)
    {
        const res = await fetch(this.baseUrl + this.DeletePitchUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        const data = await res.json();
        return data;
    }
    
}

export default new FiberHouseApi();
class SearchFiberHousesReq{
      constructor() {
        this.id = null;
        this.notes = null;
        this.mapIconId = null;
        this.latitudeFrom = null;
        this.longitudeFrom = null;
        this.latitudeTo = null;
        this.longitudeTo = null;
        this.status = null;
        this.salesOrgId = null;
        this.servicableAreaId = null;
        this.country = null;
        this.state = null;
        this.zipCode = null;
        this.city = null;
        this.address = null;
        this.mapString = null;
        this.leadId = null;
        this.salesRepId = null;
        this.assignedConvertedToCustomer = null;
        this.customerInterestFrom = null;
        this.customerInterestTo = null;
        this.leadStatusId = null;
        this.doorKnockingStatus = null;
        this.mapIconIds = null;
        this.salesRepIds = null;
        this.pagingParams = {
          pageNumber: null,
          pageSize: null
        };
        this.userType = null;
      }
}
export default SearchFiberHousesReq;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { MdExpandLess, MdExpandMore, MdOutlineDirections, MdOutlineMail, MdOutlineMessage, MdOutlinePhone } from "react-icons/md";
// import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq';
import Datatable from '../../../components/Datatable';
import Creatable from 'react-select/creatable';
import './ManageIssueTypes.scss';
import Layout from '../../../Layouts/Layout';
import Swal from 'sweetalert2';
import { TbMapBolt } from 'react-icons/tb';
import ContextMenu from '../../../components/ContextMenu';
import ExportData from '../../../Services/ExportData';
import CustomerInfo from '../../../components/CustomerInfo';
import { FaWhatsapp } from 'react-icons/fa6';
import { IoMapOutline } from 'react-icons/io5';
import ContactButtons from '../../../Services/ContactButtons';
import { UserContext } from '../../../App';
import { SiTheconversation } from 'react-icons/si';
import ExportModal from '../../../components/ExportModal';
import SearchMapIconReq from '../../../Requests/MapIcon/SearchMapIconReq';
import MapIconApi from '../../../API/MapIconApi';
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { IoIosSwap } from 'react-icons/io';
const IssuesPage = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [issues, setIssues] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataExport, setDataExport] = useState([]);
    const exportData = new ExportData();
    const [accountStatuses, setAccountStatuses] = useState("");
    const [issueTypes, setIssueTypes] = useState("");
    const [pageSize, setPageSize] = useState(20);
    const [totalIssues, setTotalIssues] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const [selectedCustoemr, setSelectedCustomer] = useState(null);
    const [openCustomerInfo, setOpenCustomerInfo] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [mapIcons, setMapIcons] = useState([]);
    const [fiberCompaniesForDisplay, setFiberCompaniesForDisplay] = useState([]);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [notesSwapped, setNotesSwapped] = useState(true);
    const user = useContext(UserContext)

    const getMapIcons = async () => {
        const req = new SearchMapIconReq()
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 1000

        const res = await MapIconApi.SearchMapIcon(req)

        if (res.status.success) {
            setMapIcons(res.data.list)
        }
        else {
            console.error(res.status.message)
        }
    }

    const getFiberCompanies = async () => {
        const req = new SearchFiberCompanyReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1000;

        const res = await FiberCompanyApi.SearchFiberCompany(req)

        if (res.status.success) {
            setFiberCompaniesForDisplay(res.data.list)
        }
        else {
            console.error(res.status.message)
        }

    }

    useEffect(
        () => {
            getFiberCompanies();
            getMapIcons();
        }, []
    )

    const handleHeaderClick = (key) => {
        if (sortColumn === key) {
            // Toggle the sorting direction if the same column is clicked
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set the sorting column and default to ascending order
            setSortColumn(key);
            setSortDirection('asc');
        }
    };

    const sortedData = React.useMemo(() => {
        if (!sortColumn) return issues;
    
        return issues ? [...issues].sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
    
            if (aValue === null && bValue === null) return 0;
            if (aValue === null) return sortDirection === 'asc' ? -1 : 1;
            if (bValue === null) return sortDirection === 'asc' ? 1 : -1;
    
            if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        }) : [];
    }, [issues, sortColumn, sortDirection]);


    const searchIssues = () => {
        setLoading(true);
        setHasSearched(true);
        let req = new SearchCustomerReq();
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = pageSize;
        req.searchString = search;
        req.hasIssue = true;
        req.accountStatus = accountStatuses !== "" ? accountStatuses : null;
        req.issueType = issueTypes
        console.log(req);
        req.sortBy = 'Issues'
        const contactButtons = new ContactButtons(user);
        CustomerApi.SearchFidiumCustomers(req).then(res => {
            console.log(res?.data?.list);
            const issuesData = res?.data?.list.map(issue => {
                const cancelationForms = issue.cancelationForms || [];
                const rescheduleForms = issue.rescheduleForms || [];
                const numOfIssues = cancelationForms.length + rescheduleForms.length;
                let latestTimeStamp = null;
                if (cancelationForms.length > 0) {
                    cancelationForms.forEach(form => {
                        if (!latestTimeStamp || form.timestamp > latestTimeStamp) {
                            latestTimeStamp = form.timestamp;
                        }
                    });
                }
                if (rescheduleForms.length > 0) {
                    rescheduleForms.forEach(form => {
                        if (!latestTimeStamp || form.timestamp > latestTimeStamp) {
                            latestTimeStamp = form.timestamp;
                        }
                    });
                }
                console.log(cancelationForms, rescheduleForms, latestTimeStamp, numOfIssues)

                return {
                    ...issue,
                    cancelationForms,
                    rescheduleForms,
                    numOfIssues,
                    notes: issue?.lead?.notes,
                    fiberCompanyName: fiberCompaniesForDisplay.find(company => company.id === issue.fiberCompanyId)?.name,
                    timeStamp: latestTimeStamp ? new Date(latestTimeStamp).toLocaleString(
                        'en-US',
                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                    ) : "N/A",
                    map: <button onClick={() => openMap(issue.lead)}><TbMapBolt size={30} color='#2761D0' /></button>,
                    installDateTime: issue.installDateTime ? new Date(issue.installDateTime).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                    originallInstallDateTimeFrom: issue.originallInstallDateTimeFrom ? new Date(issue.originallInstallDateTimeFrom).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                    updatedInstallDateTimeFrom: issue.updatedInstallDateTimeFrom ? new Date(issue.updatedInstallDateTimeFrom).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : "N/A",
                    customerInfo: <img onClick={() => [
                        setSelectedCustomer(issue),
                        setOpenCustomerInfo(true)
                    ]} className='w-8 h-8 cursor-pointer' src="/images/digital_tool_belt/customerInfo.svg" alt='customer info' />,
                    contactButtons: <div className='flex gap-4'>
                        <div className="MainLeadCard_Button" onClick={() => contactButtons.emailLead(issue.lead)}>
                            <MdOutlineMail className='cursor-pointer' color='#81848A' size={30} />
                        </div>
                        <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsappLead(issue.lead)}>
                            <FaWhatsapp className='cursor-pointer' color='#81848A' size={30} />
                        </div>
                        <div className="MainLeadCard_Button" onClick={() => contactButtons.callLead(issue.lead)}>
                            <MdOutlinePhone className='cursor-pointer' color='#81848A' size={30} />
                        </div>
                    </div>,
                    goHighLevelLink: <div className='flex items-center justify-center'>
                        <img src='/highlevelLogo.png' alt='HighLevel' style={{
                            width: '30px',
                            cursor: 'pointer'
                        }} className='cursor-pointer' size={30} color='#2761D0' onClick={() => {
                            let crmId = issue?.crmId ? issue?.crmId : issue.lead.offer.crmId
                            window.open(`https://app.gohighlevel.com/v2/location/2bpuwFoxpdxZs2Oq2Z8a/contacts/detail/${crmId}`)
                        }} />
                    </div>,
                    desiredReshceduleDate: issue?.rescheduleForms?.length > 0 ? issue.rescheduleForms[0].newInstallDate ? new Date(issue.rescheduleForms[0].newInstallDate).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : null : null,
                    contacted: <div className='flex justify-center content-center'>
                        <input type="checkbox" onClick={(e)=>{
                            markCustomerAsContacted(issue.leadId,!issue?.lead?.contacted)
                        }} defaultChecked={issue?.lead?.contacted}  className='w-4 h-4' />
                    </div>

                };
            });

            setIssues(issuesData);
            console.log(issuesData)
            setTotalIssues(res?.data?.totalItems);
            setTotalPages(res?.data?.totalPages);
            setLoading(false);
            setDataExport(res?.data?.list);

        });
    };

    useEffect(() => {
        searchIssues();

    }, [currentPage, pageSize]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchIssues();
        }
    };

    const openMap = (lead) => {
        if (lead.fiberHouse && lead.fiberHouse.coordinates) {
            navigate('/map', {
                state: {
                    fiberHouse: {
                        ...lead.fiberHouse,
                        leadId: lead.id,
                        lead: {
                            ...lead,
                            fiberHouse: null
                        }
                    }
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No Coordinates',
                text: 'This lead does not have any coordinates'
            })

        }

    }
    const renderForms = (forms, type,timestamp) => {
        console.log(type);
        return forms.map((form, index) => {
            const route = type === 'Cancellation' ? `/issue-cancel-details/${form.id}` : `/issue-reschedule-details/${form.id}`;
            return (
                <span
                    key={form.id}
                    onClick={() => navigate(route)}
                    style={{ color: form.isResolved ? 'green' : 'red', cursor: 'pointer', marginBottom: '0.5rem',width:"max-content" }}
                >
                   {timestamp} {type} {index + 1}
                </span>
            );
        });
    };

    function showIcon(key) {
        if (sortColumn === key) {
            return sortDirection === 'asc' ?
                <TiArrowSortedUp size={20} /> :
                <TiArrowSortedDown size={20} />
        }
        else {
            return null
        }
    }

    const headers = [
        {
            name:<p className='cursor-pointer' onClick={() => {
                handleHeaderClick('fiberCompanyName')
            }}>
                {showIcon('fiberCompanyName')}
                Fiber Company
            </p>,
            key: "fiberCompanyName",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick('timeStamp')
            }}>
                {
                    showIcon('timeStamp')
                }
                Time Stamp
            </p>,
            key: "timeStamp",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("issueType")
            }}>
                {showIcon('issueType')}
                Issue Type
            </p>,
            key: "issueType",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("issueDescription")
            }}>
                {showIcon('issueDescription')}
                Issue Description
            </p>,
            key: "issueDescription",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("desiredReshceduleDate")
            }}>
                {showIcon('desiredReshceduleDate')}
                Desired Reschedule Date
            </p>,
            key: "desiredReshceduleDate",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick('firstName')
            }}>
                {showIcon('firstName')}
                First Name
            </p>,
            key: "firstName"
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick('lastName')
            }}>
                {showIcon('lastName')}
                Last Name
            </p>,
            key: "lastName",
        },
      
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("accountStatus")
            }}>
                {showIcon('accountStatus')}
                Account Status
            </p>,
            key: "accountStatus",
        },
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("installDateTime")
            }}>
                {showIcon('installDateTime')}
                Install Date
            </p>,
            key: "installDateTime",
        },
   
        {
            name: <p className='cursor-pointer' onClick={() => {
                handleHeaderClick("numOfIssues")
            }}>
                {showIcon('numOfIssues')}
                Number of Issues
            </p>,
            key: "numOfIssues",
        },
        {
            name: <p><IoIosSwap className='cursor-pointer' onClick={()=>{
                setNotesSwapped(!notesSwapped)
            }} size={20}/> {notesSwapped?"Account Notes":"Rep Notes"}</p>,
            key: notesSwapped?"accountNotes":"notes",
        },
        {
            name: "Contacted",
            key: "contacted"
        },
        {
            name: "GoHighLevel Link",
            key: "goHighLevelLink",
        },
        {
            name: "Customer Info",
            key: "customerInfo",
        },
        {
            name: "Contact Buttons",
            key: "contactButtons"
        },
        {
            name: "Map",
            key: "map",
        },
        {
            name: "Issues",
            key: "issues",
            render: (row) => (

                <td style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderForms(row.cancelationForms, 'Cancellation',row.timeStamp)}
                    {renderForms(row.rescheduleForms, 'Reschedule',row.timeStamp)}
                </td>
            )
        }
    ];


    const excludeFields = ['map', 'contactButtons', 'customerInfo', 'goHighLevelLink'];



    const getDataToExport = async (type) => {
        Swal.fire({
            icon: "info",
            title: "Generating Report",
            text: "Please Wait ...",
            showConfirmButton: false,

            didOpen: () => {
                Swal.showLoading()
            }
        })

        let data = dataExport.map(
            (c) => {

                return {
                    ...c.lead,
                    fidiumCustomer: {
                        ...c,
                        lead: null,
                    }
                }
            }
        )



        console.log(data)
        let l = exportData.dataToExport(data, fiberCompaniesForDisplay, mapIcons,)
        let ids = issues?.map(c => c.leadId)
        if (type === "csv") {
            exportData.ExportToCsv("Customers.csv", l, ids)
        }
        else {
            exportData.ExportToXLSX("Customers.xlsx", l, ids)
        }

        Swal.fire({
            icon: "success",
            title: "Data Exported",
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    }

    const markCustomerAsContacted = async (id,checked) => {
        Swal.fire({
            icon:"info",
            title:"Updating Contacted Status",
            text:"Please Wait...",
            showConfirmButton:false,
            toast:true,
            position:"top-right",
            didOpen:()=>{
                Swal.showLoading()
            }
        })
        const req = {
            int : id,
            bool: checked
        }

        const res = await CustomerApi.MarkLeadAsContacted(req)
        if(res.status.success){
            Swal.fire({
                icon:"success",
                title:"Contacted Status Updated",
                toast:true,
                position:"top-right",
                showConfirmButton:false,
                didOpen:()=>{
                    Swal.hideLoading()
                }
            })
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Failed to Update Contacted Status",
                text:res.status.message,
                toast:true,
                position:"top-right",
                showConfirmButton:false,
                didOpen:()=>{
                    Swal.hideLoading()
                }
            })
        }
    }

    return (
        <Layout>
            <div className='SearchTableLayout ManageCustomersDiv'>
                <CustomerInfo editable={true} open={openCustomerInfo} setOpen={setOpenCustomerInfo} customer={selectedCustoemr} />
                {
                    issues &&
                    <ExportModal excludeFields={excludeFields} open={openExportModal} setOpen={setOpenExportModal} fields={headers} dataToExport={issues} />
                }
                <div className='SearchCard'>
                    <div className='SearchDiv'>

                        <h3 className='SearchTitle'>Issues Page</h3>
                        <div className='SearchInputs'>
                            <input
                                className='SearchInput search_input'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyPress}
                                placeholder='Search'
                            />
                            {expanded && (
                                <>
                                    <select className='SearchInput' onChange={(e) => setAccountStatuses(e.target.value)}>
                                        <option value={""}>Account Status</option>
                                        <option value={"Scheduled"}>Scheduled</option>
                                        <option value={"Rescheduled"}>Rescheduled</option>
                                        <option value={"Canceled"}>Canceled</option>
                                    </select>
                                    <select className='SearchInput' onChange={(e) => setIssueTypes(e.target.value)}>
                                        <option value={""}>Issue Types</option>
                                        <option value={"Cancel"} >Cancel</option>
                                        <option value={"Reschedule"} >Reschedule</option>
                                    </select>
                                </>
                            )}
                            <div className='expand_icon'>
                                {expanded ? (
                                    <MdExpandLess size={30} className='ExpandIcon' onClick={() => setExpanded(false)} />
                                ) : (
                                    <MdExpandMore size={30} className='ExpandIcon' onClick={() => setExpanded(true)} />
                                )}
                            </div>
                        </div>
                        <button className='SearchButton' onClick={searchIssues}>Search</button>
                    </div>
                </div>
                {loading ? (
                    <div className='spinner'>
                        <CircularProgress sx={{ color: "var(--color-icon)" }} />
                    </div>
                ) : (
                    hasSearched && (
                        <>
                            <div className='TableDiv'>
                                <div className='top_button'>
                                    <button className='filter_btn export_button' onClick={(e) => {
                                        setAnchorEl(e.currentTarget)
                                    }}>Export | <MdExpandMore color='white' size={20} /></button>
                                </div>
                                <Datatable headers={headers} list={sortedData} currentPage={currentPage} totalPages={totalPages} />
                                <div className='pagination'>
                                    <Pagination
                                        color='light'
                                        renderItem={(item) => (
                                            <PaginationItem
                                                components={{
                                                    next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                                                    previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                                                }}
                                                {...item}
                                                sx={{ color: "white" }}
                                            />
                                        )}
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={(e, v) => setCurrentPage(v)}
                                    />
                                </div>
                            </div>
                            <ContextMenu anchorEl={anchorEl} handleClose={() => {
                                setAnchorEl(null)
                            }} setAnchorEl={setAnchorEl} menuItems={[
                                {
                                    title: "Export to XLSX",
                                    onClick: () => {
                                       getDataToExport("xlsx")
                                    }
                                },
                                {
                                    title: "Export to CSV",
                                    onClick: () => {
                                        getDataToExport("csv")
                                    }
                                }
                            ]} />
                        </>
                    )
                )}
            </div>
        </Layout>
    );
};

export default IssuesPage;

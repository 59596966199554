import Layout from "../../../Layouts/Layout";
import TagsTable from "./components/TagsTables";

function TagsPage() {
  return (
    <Layout>
      <TagsTable />
    </Layout>
  );
}

export default TagsPage;

import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../Layouts/Layout';
import './ManageAnnouncements.scss';
import Datatable from '../../../components/Datatable';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { BiRightArrow, BiLeftArrow, BiEdit, BiPlus } from 'react-icons/bi';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import UsersApi from '../../../API/UsersApi';
import StringIntReq from '../../../Requests/StringIntReq';
import AnnouncementReq from '../../../Requests/Users/AnnouncementReq';
import OrganizationSelector from '../../../components/OrganizationSelector';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import UserSelector from '../../../components/UserSelector';
import VersatileLoader from '../../../components/VersatileLoader';
import { UserContext } from '../../../App';

const ManageAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newAnnouncement, setNewAnnouncement] = useState(new AnnouncementReq);
    const [orgIds, setOrgIds] = useState(null);
    const [salesRepIds, setSalesRepIds] = useState(null);
    const [userWithName, setUserWithName] = useState(null);
    const [orgWithName, setOrgWithName] = useState(null)
    const [isHidden, setIsHidden] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const user = useContext(UserContext);



    const uploadFileToFirebase = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `announcements/${uuidv4()}`); // Set path and unique filename
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    Swal.update({
                        text: `Uploading ${file.name} ${progress.toFixed(0)}%`,
                    });
                },
                (error) => {
                    console.error(error);
                    Swal.fire('Error', 'Upload failed. Please try again.', 'error');
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    resolve({
                        name: file.name,
                        url: downloadURL,
                        path: storageRef.fullPath,
                        extension: file.name.split('.').pop(),
                        fileSize: file.size.toString(),
                        fileType: file.type,
                        caption: file.type.startsWith('image/') ? 'Announcement Image' : 'Announcement Video'
                    });
                }
            );
        });
    };

    const handleFileUpload = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            Swal.fire({
                icon: 'info',
                title: 'Uploading File',
                text: 'Please wait...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
            });
            console.log(selectedFile)
            try {
                const uploaded = await uploadFileToFirebase(selectedFile);
                console.log(uploaded)
                if (uploaded && uploaded.fileType) {
                    console.log(uploaded)
                    setUploadedFile(uploaded); // Store CloudFile object
                    setFile(URL.createObjectURL(selectedFile)); // Preview the image/video
                    Swal.fire({
                        icon: "success",
                        title: "File Uploaded",
                        customClass: {
                            container: "custom-swal"
                        },
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                } else {
                    Swal.fire('Error', 'Failed to determine file type.', 'error');
                }
            } catch (error) {
                console.error('File upload failed:', error);
            }
        }
    };

    const getAnnouncements = async () => {
        setLoading(true);
        let req = new StringIntReq();
    
        if (search) {
            req.string = search;
        }
        if (user.userType === "SalesOrgAdmin") {
            req.salesOrgId = user.salesOrgId;
            req.userType = "SalesOrgAdmin";
        }
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 10;
    
        const res = await UsersApi.GetAllAnnouncements(req);
    
        if (res?.status?.success) {
            console.log(res?.data.list);
    
            const processedAnnouncements = res?.data.list
                .map(announcement => ({
                    ...announcement,
                    startDate: announcement.startDate.substring(0, 10),
                    endDate: announcement.endDate.substring(0, 10),
                }))
                .filter(announcement => new Date(announcement.endDate) > new Date()); // Filter expired announcements
    
            setAnnouncements(processedAnnouncements);
            setTotalPages(res?.data.totalPages);
            setLoading(false);
        } else {
            console.log(res?.status?.message);
        }
    };
    

    useEffect(() => {
        getAnnouncements();
    }, [currentPage]);

    const handleEdit = (announcement) => {
        console.log(announcement)
        if (announcement.salesOrgAnnouncements && announcement.salesOrgAnnouncements.length > 0) {
            // Loop through salesOrgAnnouncements and extract id and name
            const orgsWithNames = announcement.salesOrgAnnouncements.map(orgAnn => ({
                id: orgAnn.salesOrgId,
                label: orgAnn.salesOrg.name
            }));
            setOrgWithName(orgsWithNames); // Store the array of orgs
        } else {
            setOrgWithName(null); // Set to null if no salesOrgAnnouncements
        }

        if (announcement.salesRepAnnouncments && announcement.salesRepAnnouncments.length > 0) {
            // Loop through salesOrgAnnouncements and extract id and name
            const repsWithNames = announcement.salesRepAnnouncments.map(orgAnn => ({
                id: orgAnn.salesRepId,
                label: orgAnn.salesRep.firstName + " " + orgAnn.salesRep.lastName
            }));
            setUserWithName(repsWithNames); // Store the array of orgs
        } else {
            setUserWithName(null); // Set to null if no salesOrgAnnouncements
        }
        setEditingAnnouncement(announcement);
        setFile(announcement?.announcementImage?.url || announcement?.AnnouncementVideo?.Url);
        setUploadedFile(announcement?.announcementImage || announcement?.AnnouncementVideo); // Prepopulate the file
        setIsEditModalOpen(true);
    };

    const saveAnnouncement = async () => {
        if (!editingAnnouncement?.id || !editingAnnouncement?.message || !editingAnnouncement?.announcementType || !editingAnnouncement?.title) {
            Swal.fire('Error', 'All fields are required!', 'error');
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Updating Announcement",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        // Standardize date handling
        let req = new AnnouncementReq();

        // Convert the date to ISO format without adjusting for timezone
        req.startDate = new Date(new Date(editingAnnouncement.startDate).toISOString().substring(0, 10)); // Ensures the date is correct
        req.endDate = new Date(new Date(editingAnnouncement.endDate).toISOString().substring(0, 10)); // Same for end date

        req.id = editingAnnouncement.id;
        req.message = editingAnnouncement.message;
        req.announcementType = editingAnnouncement.announcementType;
        req.isHidden = editingAnnouncement.isHidden;
        req.title = editingAnnouncement.title;

        if (user.userType === "SalesOrgAdmin") {
            req.salesOrgId = user.salesOrgId;
        } else {
            req.salesOrgIds = orgIds;
        }
        req.salesRepIds = salesRepIds;
        req.announcementImage = uploadedFile?.fileType.startsWith('image/') ? uploadedFile : null;
        req.announcementVideo = uploadedFile?.fileType.startsWith('video/') ? uploadedFile : null;


        try {
            const res = await UsersApi.EditAnnouncement(req);

            if (res?.status?.success) {
                clearFileState();
                setIsEditModalOpen(false);
                getAnnouncements();
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Announcement updated successfully",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } else {
                Swal.fire('Error', res?.status?.message, 'error');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error', 'Failed to save the announcement. Try again.', 'error');
        }
    };

    const addAnnouncement = async () => {
        // Ensure all fields are filled in

        if (!newAnnouncement?.title) {
            Swal.fire('Error', 'Please enter announcement title', 'error');
            return;
        }

        if (!newAnnouncement?.message) {
            Swal.fire('Error', 'Please enter announcement message', 'error');
            return;
        }

        if (!newAnnouncement?.announcementType) {
            Swal.fire('Error', 'Please choose announcement type', 'error');
            return;
        }

        if (!newAnnouncement?.startDate) {
            Swal.fire('Error', 'Please choose a start date', 'error');
            return;
        }

        if (!newAnnouncement?.endDate) {
            Swal.fire('Error', 'Please choose an end date', 'error');
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Adding Announcement",
            text: "Please wait",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            // Convert input dates (in string form) to Date objects
            const startDate = new Date(newAnnouncement.startDate);
            const endDate = new Date(newAnnouncement.endDate);

            // Set the correct times (start of the day for start date, end of the day for end date)
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);

            // Convert back to ISO strings for saving to database
            newAnnouncement.startDate = startDate.toISOString();
            newAnnouncement.endDate = endDate.toISOString();

            // Ensure the organization ID and rep IDs are set if applicable
            if (user.userType === "SuperAdmin") {
                if (orgIds != null) {
                    newAnnouncement.salesOrgIds = orgIds; // Array of org IDs
                }
            } else if (user.userType === "SalesOrgAdmin") {
                newAnnouncement.salesOrgIds = [user.salesOrgId]; // Single org ID wrapped in an array
            }
            if (salesRepIds != null) {
                newAnnouncement.salesRepIds = salesRepIds; // Array of rep IDs
            }

            // Set the hidden state
            newAnnouncement.isHidden = isHidden;

            // Handle the uploaded file, differentiate between image and video
            if (uploadedFile) {
                if (uploadedFile.fileType.startsWith('image/')) {
                    newAnnouncement.announcementImage = uploadedFile;
                    newAnnouncement.announcementVideo = null; // Ensure video is null if image is selected
                } else if (uploadedFile.fileType.startsWith('video/')) {
                    newAnnouncement.announcementVideo = uploadedFile;
                    newAnnouncement.announcementImage = null; // Ensure image is null if video is selected
                } else {
                    newAnnouncement.announcementImage = null;
                    newAnnouncement.announcementVideo = null;
                }
            }

            // Send request to add the announcement
            const res = await UsersApi.AddAnnouncement(newAnnouncement);

            if (res?.status?.success) {
                // Close the modal and refresh the list
                clearFileState();
                setIsAddModalOpen(false);
                getAnnouncements();

                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Announcement added successfully",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } else {
                Swal.fire('Error', res?.status?.message, 'error');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error', 'Failed to add the announcement. Try again.', 'error');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getAnnouncements();
        }
    };

    const clearFileState = () => {
        setUploadedFile(null);  // Clear uploaded file
        setFile(null);          // Clear file URL or preview
    };

    const handleCloseModal = () => {
        clearFileState();      // Clear file state when modal is closed
        setEditingAnnouncement(null);
        setIsEditModalOpen(false); // Close the edit modal

    };

    const headers = [
        { key: 'id', name: 'ID' },
        { key: 'title', name: 'Title' },
        { key: 'message', name: 'Message' },
        { key: 'announcementType', name: 'Type' },
        {
            key: 'salesOrg',
            name: 'Sales Organization',
            render: (row) => {
                if ((!row.salesOrgAnnouncements || row.salesOrgAnnouncements.length === 0) && (!row.salesRepAnnouncments || row.salesRepAnnouncments.length === 0)) {
                    return 'All'; // No salesOrgAnnouncements or salesrepsAnnouncements
                } else if (!row.salesOrgAnnouncements || row.salesOrgAnnouncements.length === 0) {
                    return 'None'; // No salesOrgAnnouncements but salesrepsAnnouncements exists
                } else {
                    const orgNames = row.salesOrgAnnouncements
                        .map(orgAnn => orgAnn.salesOrg?.name || 'Unknown') // Default to 'Unknown' if no name is found
                        .join(', '); // Join the names with a comma separator
                    return orgNames;
                }
            }
        },
        {
            key: 'salesRep',
            name: 'Sales Rep',
            render: (row) => {
                console.log(row)
                if (!row.salesRepAnnouncments || row.salesRepAnnouncments.length === 0) {
                    return ''; // No salesrepsAnnouncements
                } else {
                    const repNames = row.salesRepAnnouncments
                        .map(repAnn => repAnn.salesRep?.firstName + " " + repAnn.salesRep?.lastName || 'Unknown') // Default to 'Unknown' if no name is found
                        .join(', '); // Join the names with a comma separator
                    return repNames;
                }
            }
        },
        { key: 'startDate', name: 'Start Date' },
        { key: 'endDate', name: 'End Date' },
        {
            key: 'action',
            name: 'Action',
            render: (row) => (
                console.log(row),
                user.userType === "SuperAdmin" ? (
                    <button className="edit-btn" onClick={() => handleEdit(row)}>
                        <BiEdit />
                    </button>
                ) : user.userType === "SalesOrgAdmin" && row.salesOrgAnnouncements?.some(orgAnn => orgAnn.salesOrgId === user.salesOrgId) ? (
                    <button className="edit-btn" onClick={() => handleEdit(row)}>
                        <BiEdit />
                    </button>
                ) : null
            ),
        },
    ];

    return (
        <Layout>
            <div className="manage-announcements-container">

                <div className="header">
                    <h1>Manage Announcements</h1>
                    <div className="search-filters">
                        <button onClick={() => setIsAddModalOpen(true)}>
                            + Add Announcement
                        </button>
                        <input
                            type="text"
                            placeholder="Search"
                            value={search}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <button onClick={getAnnouncements}>Search</button>
                    </div>

                </div>

                {loading ? (
                    <div className="spinner">
                            <VersatileLoader size='large' color='#2761CF' />
                    </div>
                ) : (
                    <Datatable headers={headers} list={announcements} />
                )}

                <Pagination
                    sx={{
                        margin:"auto"
                    }}
                    count={totalPages}
                    onChange={(e, pageNo) => setCurrentPage(pageNo)}
                    renderItem={(item) => (
                        <PaginationItem
                            components={{
                                next: (props) => <button {...props}><BiRightArrow /></button>,
                                previous: (props) => <button {...props}><BiLeftArrow /></button>,
                            }}
                            {...item}
                        />
                    )}
                />

                {/* Edit Modal */}
                <Modal
                    open={isEditModalOpen}
                    onClose={() => handleCloseModal()}
                    className='modal-wrapper'
                >
                    <div className="modal-content">
                        <h3>Edit Announcement</h3>
                        <label>Announcement Type</label>
                        <input
                            type="text"
                            disabled={true}
                            placeholder="Type"
                            value={editingAnnouncement?.announcementType}
                        />

                        <input
                            type='text'
                            placeholder="Title"
                            value={newAnnouncement.title}
                            onChange={(e) =>
                                setEditingAnnouncement({ ...editingAnnouncement, title: e.target.value })
                            }
                        />

                        {user.userType !== "SalesOrgAdmin" &&

                            <OrganizationSelector allOption={false} multiple={true} setOrgWithName={setOrgWithName} orgWithName={orgWithName} setUser={setOrgIds} placeholder='Select Organization' />
                        }
                        {user.userType !== "SalesOrgAdmin" &&
                            <div style={{ marginTop: "10px" }}>

                                <UserSelector includeYourself={true} setUser={setSalesRepIds} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} userWithName={userWithName} setUserWithName={setUserWithName} conversationType={"Group"} placeholder='Select Sales Reps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
                            </div>

                        }
                        <input
                            style={{ marginTop: "1rem" }}
                            type="file"
                            accept="image/*, video/*"
                            onChange={handleFileUpload}
                        />

                        {/* Display the uploaded file */}
                        {file && (uploadedFile?.fileType.startsWith('image/') ? (
                            <img src={file} alt="Uploaded" style={{ maxWidth: '100%', marginTop: '10px' }} />
                        ) : (
                            <video controls src={file} style={{ maxWidth: '100%', marginTop: '10px' }} />
                        ))}

                        <label>Announcement Message</label>
                        <textarea
                            type="textarea"
                            rows="5"
                            placeholder="Message"
                            value={editingAnnouncement?.message}
                            onChange={(e) =>
                                setEditingAnnouncement({ ...editingAnnouncement, message: e.target.value })
                            }
                        ></textarea>
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={
                                editingAnnouncement?.startDate
                                    ? new Date(editingAnnouncement.startDate).toISOString().substring(0, 10)
                                    : ''
                            }
                            onChange={(e) =>
                                setEditingAnnouncement({
                                    ...editingAnnouncement,
                                    startDate: new Date(e.target.value),
                                })
                            }
                        />
                        <label>End Date</label>
                        <input
                            type="date"
                            value={
                                editingAnnouncement?.endDate
                                    ? new Date(editingAnnouncement.endDate).toISOString().substring(0, 10)
                                    : ''
                            }
                            onChange={(e) =>
                                setEditingAnnouncement({
                                    ...editingAnnouncement,
                                    endDate: new Date(e.target.value),
                                })
                            }
                        />
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>


                            <input
                                type="checkbox"
                                checked={isHidden}
                                onChange={(e) =>
                                    setIsHidden(e.target.checked)
                                }
                            />
                            <label className="checkbox-label">Hide Announcement</label>

                        </div>


                        <button onClick={saveAnnouncement}>Save</button>
                    </div>
                </Modal>

                {/* Add Modal */}
                <Modal
                    open={isAddModalOpen}
                    onClose={() => setIsAddModalOpen(false)}
                    className='modal-wrapper'
                >
                    <div className="modal-content">
                        <h3>Add Announcement</h3>
                        <select
                            value={newAnnouncement.announcementType}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, announcementType: e.target.value })
                            }
                        >
                            <option value="">Select Type</option>
                            <option value="Bug Fix">Bug Fix</option>
                            <option value="Feature">Feature</option>
                            <option value="Announcement">Announcement</option>
                            <option value="Update">Update</option>
                        </select>
                        <input
                            type='text'
                            placeholder="Title"
                            value={newAnnouncement.title}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, title: e.target.value })
                            }
                        />
                        {user.userType !== "SalesOrgAdmin" &&

                            <OrganizationSelector allOption={false} multiple={true} setUser={setOrgIds} placeholder='Select Organization' />
                        }
                        {user.userType !== "SalesOrgAdmin" &&
                            <div style={{ marginTop: "10px" }}>
                                <UserSelector includeYourself={true} setUser={setSalesRepIds} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} conversationType={"Group"} placeholder='Select Sales Reps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
                            </div>
                        }
                        <input
                            style={{ marginTop: "1rem" }}
                            type="file"
                            accept="image/*, video/*"
                            onChange={handleFileUpload}
                        />

                        {/* Display the uploaded file */}
                        {file && (uploadedFile?.fileType.startsWith('image/') ? (
                            <img src={file} alt="Uploaded" style={{ maxWidth: '100%', marginTop: '10px' }} />
                        ) : (
                            <video controls src={file} style={{ maxWidth: '100%', marginTop: '10px' }} />
                        ))}

                        <textarea
                            type="textarea"
                            rows="5"
                            placeholder="Message"
                            value={newAnnouncement.message}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, message: e.target.value })
                            }
                        ></textarea>
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={newAnnouncement.startDate ? newAnnouncement.startDate.substring(0, 10) : ''}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, startDate: e.target.value })
                            }
                        />
                        <label>End Date</label>
                        <input
                            type="date"
                            value={newAnnouncement.endDate ? newAnnouncement.endDate.substring(0, 10) : ''}
                            onChange={(e) =>
                                setNewAnnouncement({ ...newAnnouncement, endDate: e.target.value })
                            }
                        />
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>


                            <input
                                type="checkbox"
                                checked={isHidden}
                                onChange={(e) =>
                                    setIsHidden(e.target.hidden)
                                }
                            />
                            <label className="checkbox-label">Hide Announcement</label>

                        </div>
                        <button onClick={addAnnouncement}>Add</button>
                    </div>
                </Modal>
            </div>
        </Layout >
    );
};

export default ManageAnnouncements;

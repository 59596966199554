import React, { useRef, useState, useEffect, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useColumnOrder } from './hooks/useColumnOrder';
import { MultiSelector } from '../MultiSelector';
import { FaEdit, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useTabs } from '../TabContext';
import { UserContext } from '../../../../App';
import MasterSheetApi from '../../../../API/MasterSheetApi';
import { ColumnManager } from './ColumnManager';
import ChangeAccountStatusModal from '../AccountStatusChangeForm';
import { ColorPicker } from './ColorPicker';
import { Settings } from '@mui/icons-material';
import { BsClock } from 'react-icons/bs';
import { HistoryPopup } from './HistoryUpdate';
import { ClickAwayListener } from '@mui/material';
import ExportToWorksheetModal from '../../../TeamAdmin/MapPage/components/ExportToWorkSheetModal';

const Column = ({ header, index, moveColumn, sortData, sortConfig }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      moveColumn(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'column',
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isSorted = sortConfig?.key === header.key;
  const sortDirection = isSorted ? sortConfig.direction : null;

  return (
    <th
      ref={ref}
      className={`p-2 font-bold text-left cursor-pointer border border-gray-300 ${header.key === "digitalActionBar"?'min-w-96':'min-w-36'}  whitespace-nowrap ${isDragging ? 'opacity-50' : ''
        }`}
      onClick={() => sortData(header.key)}
    >
      <div className="flex items-center">
        {header.label}
        {header.editable && (
          <FaEdit className="ml-1 text-blue-500" />
        )}
        <span className="ml-1">
          {isSorted && sortDirection === 'asc' && <FaSortUp size={30} color='#2761d0' />}
          {isSorted && sortDirection === 'desc' && <FaSortDown size={30} color='#2761d0' />}
          {
            !isSorted && <FaSort size={20} />
          }
        </span>
      </div>
    </th>
  );
};



const Cell = ({ value, rowIndex,width, columnKey, header, updateData, leadId, customerId, setCustomer, lead, setOpenChangeStatusModal }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [showHistory, setShowHistory] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    if (header.editable) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editValue !== value && header.type !== 'selector') {
      updateData(rowIndex, columnKey, editValue);
      if (header.onChange) {
        // console.log(editValue)
        header.onChange(editValue, customerId)
      }
    } else {
      setEditValue(value);
    }
  };

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      if (editValue !== value) {
        updateData(rowIndex, columnKey, editValue);
        if (header.onChange) {
          header.onChange(editValue, customerId)
        }
      }
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditValue(value);
    }
  };



  const renderEditInput = () => {
    switch (header.type) {
      case 'number':
        return (
          <input
            ref={inputRef}
            type="number"
            className="w-full p-1 border-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={editValue}
            onChange={(e) => setEditValue(parseFloat(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        );
      case 'selector':
        return (
          <MultiSelector
            options={header.options || []}
            value={editValue}
            onChange={(newValue) => {

              setEditValue(newValue);
              updateData(rowIndex, columnKey, newValue);
              if (header.onChange) {
                header.onChange(newValue, leadId, header.key)
              }
            }}
            multiSelect={header.multiSelect || false}
            onClose={() => setIsEditing(false)}
          />
        );
      case 'button':
        return (
            lead?.accountStatus ==="Canceled" ?  lead.dealSaved ?
            <p className='text-green-500'>Deal Saved</p>
            :
            <button className="w-full p-1 bg-blue-500 text-white rounded-lg" onClick={
              () => {
                header.onClick(lead.customerId)
              }}>
              Save Deal
            </button>
            : null
        );
      default:
        return (
          <textarea
            ref={inputRef}
            type="text"
            className="w-full p-1 border-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={editValue}
            defaultValue={editValue}
            onChange={(e) => {
              setEditValue(e.target.value)
            }}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            rows={3}
          />
        );
    }
  };

  const accountStatusColor = (status) => {
    switch (status) {
      case 'Sold':
        return 'text-blue-500'
      case 'Scheduled':
        return 'text-green-500'
      case 'Canceled':
        return 'text-red-500'
      case 'Completed':
        return 'text-[#54BCFC]'
      case 'Rescheduled':
        return 'text-[#800080]'
      default:
        return 'text-gray-500'
    }
  }

  const renderHistoryButton = () => {
    if (!header.editable) return null;
    return (
      <button
        onClick={() => setShowHistory(!showHistory)}
        className="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none transition-colors duration-200"
        aria-label="Show update history"
      >
        <BsClock size={16} />
      </button>
    );
  };
  const renderEditField = () => {
    return (
      <div className={`flex flex-col ${header.key==="accountNotes"?"min-w-64":"min-w-40"} items-start gap-2 p-2 `}>
        {renderEditInput()}
        {header.showHistory ? renderHistoryButton() : null}
      </div>
    );
  };

  const renderValue = () => {
    if (Array.isArray(value)) {
      return value.map((option) => (
        <span key={option} className="bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs mr-1 inline-block">
          {option}
        </span>
      ))
    }

    // if(header.type === 'component') {
    //   return <header.component lead={lead?.lead} />
    // }

    return value;
  };

  // if (header.editable) {
  //   return renderEditField();
  // }
  return (
    <td key={header.key} onDoubleClick={handleDoubleClick} className={`${header.editable ? 'cursor-text' : ''} relative group border border-gray-300 ${header.type==="component"?"min-w-60":"max-w-xs"}`}>
      {
        header.type === "component"?
        <header.component lead={lead?.lead} />
        :
        header.editable ?
          renderEditField() :
          <div
            className={` h-full  min-w-40 max-h-40 overflow-y-auto `}

          >
            <div onClick={() => {
              if (header.key === 'accountStatus') {
                setCustomer(lead)
                setOpenChangeStatusModal(true)
              }
              if (header.key === "name") {
                if (lead?.crmId) {
                  window.open(`https://app.gohighlevel.com/v2/location/2bpuwFoxpdxZs2Oq2Z8a/contacts/detail/${lead?.crmId}`)
                }
              }
            }}

              className={`max-w-xs ${header.key === "name" ? "font-bold underline cursor-pointer" : ""} p-2 ${header.key === 'accountStatus' ? accountStatusColor(value) + " font-bold underline cursor-pointer" : ''}`}>
              {
                renderValue()
              }
            </div>
          </div>
      }
      {showHistory && (
        <ClickAwayListener onClickAway={() => setShowHistory(false)}>
          <div className="absolute left-0 z-20">
            <HistoryPopup type={columnKey} leadId={leadId} onClose={() => setShowHistory(false)} />
          </div>
        </ClickAwayListener>
      )}
    </td>
  );
};

export const DataTable = ({ headers: initialHeaders, data: initialData, onDataChange, totalPages, setCurrentPage }) => {


  const [visibleColumns, setVisibleColumns] = useState(
    new Set(initialHeaders.filter(h => h.isHidden !== true).map(h => h.key))
  );
  const [data, setData] = useState(initialData);
  const user = useContext(UserContext)
  const { headers, setHeaders, data: orderedData, moveColumn, sortData, sortConfig, updateData } = useColumnOrder(initialHeaders, data);
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);
  const { getListId, activeTabId, getFileHeaders } = useTabs();
  const [customer, setCustomer] = useState(null)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [rowColors, setRowColors] = useState({});
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [highlightColor, setHighlightColor] = useState('#FFB3BA');
  const [openExportSheetModal, setOpenExportSheetModal] = useState(false)

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (onDataChange) {
      onDataChange(data);
    }
  }, [data, onDataChange]);



  const getHeaders = async () => {
    const listId = await getListId(activeTabId);
    const headers2 = await getFileHeaders(listId)
    if (headers2 && headers2.length > 0) {
      setVisibleColumns(new Set(headers2.filter(h => h.isHidden !== true).map(h => h.columnKey)))
      // also set the ids of the headers
      setHeaders(
        (prev) => {
          let newHeaders = []
          prev.map((header, index) => {
            headers2.map((header2) => {
              if (header.key === header2.columnKey) {
                newHeaders.push({ ...header, id: header2.id, columnNumber: header2.columnNumber })
              }
            })
          })

          // sort the headers based on the columnNumber
          newHeaders = newHeaders.sort((a, b) => a.columnNumber - b.columnNumber)
          // console.log(newHeaders)
          return newHeaders
        })
    }
  }

  const editColumns = async (newHeaders, newVisibleColumns) => {
    const columns = newHeaders.map(
      (header, index) => ({
        columnkey: header.key,
        columnName: header.label,
        isHidden: !newVisibleColumns.has(header.key),
        columnNumber: index,
        id: header.id
      })
    )

    const listId = await getListId(activeTabId);
    const req = {
      userId: user.id,
      dynamicListId: listId,
      columns: columns
    }
    const res = await MasterSheetApi.EditDynamicListColumns(req)
    if (res.success) {
      // console.log('columns updated')
    }
  }

  useEffect(
    () => {
      getHeaders()
    }, []
  )

  const visibleHeaders = headers.filter(header => visibleColumns.has(header.key));

  const toggleRowSelection = (rowIndex) => {
    setSelectedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };


  return (
    <>
      <ExportToWorksheetModal showModal={false} open={openExportSheetModal} setOpen={setOpenExportSheetModal} leadIds={[...selectedRows]} />
      <ChangeAccountStatusModal open={openChangeStatusModal} setOpen={setOpenChangeStatusModal} customer={customer} />
      {isColumnMenuOpen && (
        <ColumnManager
          headers={headers}
          visibleColumns={visibleColumns}
          onApplyChanges={(newHeaders, newVisibleColumns) => {
            setHeaders(newHeaders);
            setVisibleColumns(newVisibleColumns);
            editColumns(newHeaders, newVisibleColumns)
          }}
          onClose={() => setIsColumnMenuOpen(false)}
        />
      )}
      <div>
        {/* <div className="mb-4 relative">

          <ColorPicker color={highlightColor} onChange={setHighlightColor} />
          <button
            onClick={highlightSelectedRows}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out"
          >
            Highlight Selected
          </button>
          <button
            onClick={clearHighlights}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors duration-200 ease-in-out"
          >
            Clear Highlights
          </button>

          <button
            onClick={() => {
              // if (isColumnMenuOpen) {
              //   editColumns()
              // }
              setIsColumnMenuOpen(!isColumnMenuOpen)

            }
            }
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Manage Columns
          </button>
         
        </div> */}

        <div className="my-4 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <button onClick={() => {
            setOpenExportSheetModal(true)
          }} disabled={selectedRows.size === 0} className='bg-blue-700 text-white rounded-lg px-4 py-2 disabled:bg-grey' >Create WorkSheet</button>
          <div className="flex flex-wrap items-center justify-center  sm:justify-end space-y-2 sm:space-y-0 space-x-0 sm:space-x-4">
            <div className="w-full sm:w-auto mr-2">
              <button
                onClick={() => setIsColumnMenuOpen(true)}
                className="w-full sm:w-auto px-4 py-2 bg-white text-gray-700 rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out flex items-center justify-center sm:justify-start space-x-2"
              >
                <Settings size={18} />
                <span>Manage Columns</span>
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto h-[62vh]">
          <table className="w-full bg-white table-auto min-h-96">
            <thead>

              <tr>
                <th className={`p-2 font-bold text-left cursor-pointer border border-gray-300 min-w-36 whitespace-nowrap`}>
                  Select
                </th>
                {visibleHeaders.map((header, index) => (
                  <Column
                    key={header.key}
                    header={header}
                    index={index}
                    moveColumn={moveColumn}
                    sortData={sortData}
                    sortConfig={sortConfig}
                    width ={header.width}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {orderedData?.map((row, rowIndex) => (
                <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}>
                  <td className={`border border-gray-300 max-w-10`}>
                    <div
                      className={` h-full flex  min-w-10 max-h-40 overflow-y-auto `}>
                      <input
                        type="checkbox"
                        checked={selectedRows.has(row.leadId)}
                        onChange={() => toggleRowSelection(row.leadId)}
                        className="form-checkbox m-auto h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
                      />
                    </div>
                  </td>
                  {visibleHeaders.map((header) => (

                    <Cell
                      value={row[header.key]}
                      rowIndex={rowIndex}
                      columnKey={header.key}
                      header={header}
                      updateData={updateData}
                      leadId={row.leadId}
                      customerId={row.customerId}
                      setCustomer={setCustomer}
                      lead={row}
                      setOpenChangeStatusModal={setOpenChangeStatusModal}
                      width={header.width}
                    />

                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FiberHouseApi from '../../../../../API/FiberHouseApi';

const IndustriesFilter = ({ 
  candidateIndustries,// 
  setCandidateIndustries,//

}) => {

  const [industryOptions, setIndustryOptions] = useState([]);//



  const getIndustryOptions = async () => {
    let res = await FiberHouseApi.GetCandidateIndustries();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) => a.label.localeCompare(b.label)); // Sort by 'label'
      setIndustryOptions(sortedData);
    }
  };
  
 
  
  
  useEffect(() => {
    
    getIndustryOptions();
    
   
  }, []);

   
  
  

 


  return (
  
               
              
                <div className='InputDiv'>
                  <label htmlFor='assignedConvertedToCustomer'>Industries</label>
                  <Select
                    isMulti
                    value={candidateIndustries}
                    onChange={(options) => setCandidateIndustries(options)}
                    options={industryOptions}
                    placeholder="Select Industries"
                  />
                </div>
 
    
  );
};

export default IndustriesFilter;
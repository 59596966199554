import env from "../../../../../API/EnvSettings";
const API_URL = env.url;

export const EditPost = async (editorInstance, title, blog, description) => {
  if (!editorInstance) {
    console.error("Editor not initialized yet.");
    return;
  }
  try {
    const content = await editorInstance.save();

    console.log("content", content);
    console.log("blog", blog);
    console.log("check block length ", blog);
    const blocks = content.blocks.map((bl, i) => {
      const newBl = {
        type: bl.type,
        data: JSON.stringify(bl.data),
        blockNumber: i + 1,
      };
      if (typeof bl.id === "number") newBl.id = bl.id;
      return newBl;
    });
    console.log("check block length ", blocks);
    const updatedBlog = {
      id: blog.id,
      Title: title.trim(),
      description,
      isDraft: true,
      userId: 153,
      blogBlocks: blocks,
    };

    await fetch(`${API_URL}SaveBlogData`, {
      method: "POST",
      body: JSON.stringify(updatedBlog),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    console.log("Blog Updated Succesfully", blog);
    return updatedBlog;
  } catch (error) {
    console.error("Saving failed:", error);
    throw error;
  }
};

require('./FontFamilyTool.css').toString();

const fontFamiliesType = ['Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold',
  'Avant Garde', 'Baskerville', 'Bodoni MT', 'Book Antiqua', 'Big Caslon', 'Calibri', 'Calisto MT', 'Cambria', 'Candara', 'Century Gothic',
  'Charcoal', 'Copperplate',
  'Comic Sans MS', 'Courier New',
  'Didot',
  'Franklin Gothic Medium',
  'Futura', 'Geneva', 'Gill Sans', 'Garamond', 'Georgia', 'Goudy Old Style',
  'Hoefler Text',
  'Helvetica',
  'Helvetica Neue', 'Impact', 'Lucida Sans Unicode', 'Lato', 'Lucida Grande', 'Lucida Bright', 'Monaco', 'Optima', 'Papyrus',
  'PT Mono', 'Palatino', 'Perpetua', 'Rockwell', 'Roboto', 'Rockwell Extra Bold', 'Segoe UI', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana',
];
class FontFamilyTool {
   
   static title = 'Font Family';
   isDropDownOpen = false;
   emptyString = '&nbsp;&nbsp';
   fontFamilyDropDown = 'fontFamilyDropDown';
   fontFamilyBtn = 'fontFamilyBtn';

   static get isInline() {
       return true
   }
   static get sanitize() {
    return {
      font: {
        size: true,
        face: true
      },
    };
  }
  constructor({api, config}) {
    this.api = api
    this.config = config
  }
   commandName= 'fontName';

    CSS = {
    button: 'ce-inline-tool',
    buttonActive: 'ce-font-family-tool--active',
    buttonModifier: 'ce-inline-tool--font',
  }
    selectedFontFamily = null;

    nodes = {
    button: undefined
  }
    selectionList = undefined;

    buttonWrapperText = undefined;

    togglingCallback = null;

  createButton() {
    this.nodes.button = document.createElement('button');
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.setAttribute('id', this.fontFamilyBtn);
    this.nodes.button.innerHTML = "  F ";
    this.nodes.button.appendChild(this.svg('toggler-down', 13, 13));
  }

  createDropdown() {
    this.selectionList = document.createElement('select');
    this.selectionList.setAttribute('class', 'selectionList-font-family');
    this.selectionList.setAttribute('id', this.fontFamilyDropDown);
    this.selectionList.style.appearance = 'none';
    this.selectionList.style.border = '1px solid #ccc';
    this.selectionList.style.padding = '5px 10px';
    this.selectionList.style.borderRadius = '4px';
    this.selectionList.style.background = 'white url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNNSA1bC01LTUgMS0xIDQgNCA0LTR6Ii8+PC9zdmc+") no-repeat right 10px center';
    this.selectionList.style.backgroundSize = '10px 6px';
    const FontFamilyOptions = this.config.fontFamilyList ? this.config.fontFamilyList : fontFamiliesType;
    for (const value of FontFamilyOptions) {
      const option = document.createElement('option');
      option.setAttribute('value', value);
      option.setAttribute('style', `font-family:${value}`);
      option.innerHTML = value;
      this.selectionList.appendChild(option);
    }
    this.selectionList.addEventListener('change', this.toggleFontFamilySelector);
  }

  toggleFontFamilySelector = (event) => {
    this.selectedFontFamily = event.target.value;
    this.surround();
  }

  render() {
    this.createDropdown();
    return this.selectionList;
  }

  surround() {
    if (this.selectedFontFamily) {
      document.execCommand(this.commandName, false, this.selectedFontFamily);
    }
  }

   removeFontOptions() {
    if (this.selectionList) {
      this.isDropDownOpen = false;
      this.selectionList = this.selectionList.remove();
    }
    if (typeof this.togglingCallback === 'function') {
      this.togglingCallback(false);
    }
  }

   toggleDropDown = ($event) => {
    if ((($event.target).id === this.fontFamilyDropDown || ($event.target.parentNode.id === this.fontFamilyBtn) || (($event.target).id === this.fontFamilyBtn))) {
      this.toggle((toolbarOpened) => {
        if (toolbarOpened) {
          this.isDropDownOpen = true;
        }
      });
    }
  }

   toggle(togglingCallback) {
    if (!this.isDropDownOpen && togglingCallback) {
      this.addFontFamilyOptions();
    } else {
      this.removeFontOptions();
    }
    if (typeof togglingCallback === 'function') {
      this.togglingCallback = togglingCallback;
    }
  }

   getComputedFontFamilyStyle(node) {
    return window.getComputedStyle(node.parentElement, null).getPropertyValue('font-family');
  }

   checkState(selection) {
    const isActive = document.queryCommandState(this.commandName);
    let anchoredElementSelectedFont = this.getComputedFontFamilyStyle(selection.anchorNode);
    const focusElementSelectedFont = this.getComputedFontFamilyStyle(selection.focusNode);
    if (anchoredElementSelectedFont === focusElementSelectedFont) {
      if (anchoredElementSelectedFont.slice(0, 1) === '"') {
        anchoredElementSelectedFont = anchoredElementSelectedFont.slice(1, -1);
      }
      else if (anchoredElementSelectedFont.slice(0, 1) === '-') {
        const updatedFont = anchoredElementSelectedFont.slice(anchoredElementSelectedFont.indexOf('"') + 1, anchoredElementSelectedFont.indexOf('"', anchoredElementSelectedFont.indexOf('"') + 1));
        anchoredElementSelectedFont = updatedFont;
      }
      else if (anchoredElementSelectedFont.indexOf(',') !== -1) {
        anchoredElementSelectedFont = anchoredElementSelectedFont.slice(0, anchoredElementSelectedFont.indexOf(','));
      }
      this.replaceFontSizeInWrapper(anchoredElementSelectedFont);
    }
    else {
      const emptyWrapper = this.emptyString;
      this.replaceFontSizeInWrapper(emptyWrapper);
    }
    return isActive;
  }

   replaceFontSizeInWrapper(fontFamily) {
    const displaySelectedFontFamily = document.getElementById(this.fontFamilyDropDown)
    if(!displaySelectedFontFamily) return;
    displaySelectedFontFamily.innerHTML = fontFamily;
  }

   clear() {
    this.toggle();
    this.selectedFontFamily = null;
  }

  svg(name, width = 12, height = 14) {
    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    icon.classList.add('icon', 'icon--' + name);
    icon.setAttribute('width', width + 'px');
    icon.setAttribute('height', height + 'px');
    icon.innerHTML = `<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#${name}"></use>`;

    return icon;
  }
}
export default FontFamilyTool;
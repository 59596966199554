/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowRight } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FiberHouseApi from "../../../API/FiberHouseApi";
import MapIconApi from "../../../API/MapIconApi";
import {
  SearchFiltersContext,
  ConnectionContext,
  UserContext,
  CandidateFiltersContext,
  FCCDataFiltersContext,
} from "../../../App"; //Importing FccDataFiltersContext
import SearchFiberHousesReq from "../../../Requests/FiberHouse/SearchFiberHousesReq";
import CreateFiberHouse from "./components/CreateFiberHouse";
import CreateMapIcon from "./components/CreateMapIcon";
import CreateServicableAreaForm from "./components/CreateServicableAreaForm";
import CreateUsingCSV from "./components/CreateUsingCSV";
import EditMapIcons from "./components/EditMapIcons";
import ManageLeadStatuses from "./components/ManageLeadStatuses";
import { Map } from "./components/Map";
import SearchFilters from "./components/SearchFilters";
import Stats from "./components/Stats";
import Popup from "./components/Popup";
import Layout from "../../../Layouts/Layout";
import ContextMenu from "../../../components/ContextMenu";
import MainLeadCard from "./components/NewLeadCard/MainLeadCard";
import HotLeadForm from "./components/NewLeadCard/HotLeadForm";
import FreeMonthForm from "./components/NewLeadCard/FreeMonthForm";
import ActivityHistoryNew from "./components/NewLeadCard/ActivityHistoryNew";
import MainBizCard from "./components/DigitalBizCard/MainBizCard";
import SearchLocation from "./components/SearchLocation";
import ReviewContainer from "../../Reps/DigitalBusinessCenter/components/ReviewContainer";
import Legends from "./components/Legends";
import BizCardTab from "./components/DigitalBizCard/BizCardTab";
import { useLocation } from "react-router-dom";
import Modify from "ol/interaction/Modify";
import { fromLonLat, toLonLat } from "ol/proj";
import { Feature, Geolocation } from "ol";
import { Point } from "ol/geom";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import SearchServicableAreaReq from "../../../Requests/ServicableArea/SearchServicableAreaReq";
import ServicableAreaApi from "../../../API/ServicableAreaApi";
import SearchSalesOrgReq from "../../../Requests/Organizations/SearchSalesOrgsReq";
import OrgApi from "../../../API/OrgApi";
import SearchUserReq from "../../../Requests/Users/SearchUserReq";
import UsersApi from "../../../API/UsersApi";
import { getDistance } from "ol/sphere";
import ConnectionService from "../../../Services/ConnectionService";
import LiveTrackingTab from "./components/LiveTrackingTab";
import { get } from "sortablejs";
import CandidateMainLeadCard from "./components/CandidateMainLeadCard";
import CandidateActivityHistory from "./components/CandidateActivityHistory";
import TabbedCreateLead from "./components/TabbedCreateLead";
import TabbedSearchFilters from "./components/TabbedSearchFilters";
import TabbedStatsPage from "./components/TabbedStats";
import CreateCandidate from "./components/CreateCandidate";
import ManageAreas from "./components/ManageAreas";
import MapHelper from "./MapHelper";
import UploadCoverageArea from "./components/UploadCoverageArea";
import RBush from "rbush";
import { getAllTags } from "../../SuperAdmin/Tags/api/tagsAPI";
import { useMode } from "../../../Layouts/ModeContext";
import SalesRepActivityHistory from "./components/ActivityHistory/SalesRepActivityHistory";
// import * as ol from 'ol'
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MapContext = createContext();
const Index = ({ showLayout = true }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [tags, setTags] = useState();

  useEffect(() => {
    const loadTags = async () => {
      const tags = await getAllTags();
      setTags(tags.data.items);
    };
    loadTags();
  }, []);

  const location = useLocation();

  const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [fiberHouses, setFiberHouses] = useState([]);
  const [totalFiberHouses, setTotalFiberHouses] = useState([]);
  const shouldReRenderMap = useRef(true);
  // const [selectedServicableArea, setSelectedServicableArea] = useState(null)
  const [color, setColor] = useState("#000000");
  const user = useContext(UserContext);
  const searchFilter = useContext(SearchFiltersContext);
  const candidateSearchFilter = useContext(CandidateFiltersContext);
  const fccDataSearchFilters = useContext(FCCDataFiltersContext); // Declear fccDataSearchFilters
  const [mapLayers, setMapLayers] = useState([]);
  // const [layerss, setLayers] = useState([])
  // const [membersLoaded, setMembersLoaded] = useState(false);
  // const [availableMembersLoaded, setAvailableMembersLoaded] = useState(false);
  const [shape, setShape] = useState(null);
  const polygonRefs = useRef([]);
  const [selectedFiberHouse, setSelectedFiberHouse] = useState(null);
  const [selectedCandidateLead, setSelectedCandidateLead] = useState(null);
  const [selectedFiberHouseFeature, setSelectedFiberHouseFeature] =
    useState(null);
  const [selectedCandidateFeature, setSelectedCandidateFeature] =
    useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  // const [coordinates, setCoordinates] = useState(null);
  const cluster = useRef(null);
  const [servicableAreas, setServicableAreas] = useState([]);

  const [mapObject, setMapObject] = useState(null);
  const [index, setIndex] = useState(-1);
  const [currentPositiionLayer, setCurrentPositionLayer] = useState(null);


  //Search Filters
  const [salesRepId, setSalesRepId] = useState(null);

  const [currentSalesRepId, setCurrentSalesRepId] = useState(null);
  const [currentSalesRepDate, setCurrentSalesRepDate] = useState(new Date().toISOString().split('T')[0]);

  const [doorKnockingStatus, setDoorKnockingStatus] = useState(null);
  const [notes, setNotes] = useState(null);

  const [mapString, setMapString] = useState(null);

  const [customerInterestFrom, setCustomerInterestFrom] = useState(null);
  const [customerInterestTo, setCustomerInterestTo] = useState(null);
  const [salesOrgId, setSalesOrgId] = useState(null);
  const [leadStatus, setLeadStatus] = useState(null);
  const [openMobileMenu, setOpenMobileMenu] = useState(null);
  const [mapIcons, setMapIcons] = useState([]);
  const [mobileMenu, setMobileMenu] = useState([]);
  // const [fiberHouseTotalPages, setFiberHouseTotalPages] = useState(1);
  const [fiberHouseCurrentPage, setFiberHouseCurrentPage] = useState(1);
  const [searchArea, setSearchArea] = useState(null);
  const [searchAreaFeature, setSearchAreaFeature] = useState([]);
  // const [searchAreaFiberHouses, setSearchAreaFiberHouses] = useState(null);
  // const lightHouses = useRef([])
  const [fiberHousesLoading, setFiberHousesLoading] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [salesReps, setSalesReps] = useState([]);
  // fiberHouse Ids for stats
  const [fiberHouseIds, setFiberHouseIds] = useState([]);
  const [salesOrg, setSalesOrg] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState(false);

  // tags
  const [mySelectedTags, setMySelectedTags] = useState([]);
  const [assignedSelectedTags, setAssignedSelectedTags] = useState([]);
  const [unassignedSelectedTags, setUnassignedSelectedTags] = useState([]);
  const [candidateSelectedTags, setCandidateSelectedTags] = useState([]);

  // Assigned Leads Filters
  const [assignedLeadsEnabled, setAssignedLeadsEnabled] = useState(
    searchFilter.current?.map?.assignedEnabled != null
      ? searchFilter.current?.map?.assignedEnabled
      : true
  );
  const [assignedCreatedFrom, setAssignedCreatedFrom] = useState(null);
  const [assignedCreatedTo, setAssignedCreatedTo] = useState(null);
  const [assignedConvertedToCustomer, setAssignedConvertedToCustomer] =
    useState(
      searchFilter.current?.map?.assignedConvertedToCustomer
        ? searchFilter.current?.map?.assignedConvertedToCustomer
        : null
    );
  const [assignedAcountStatus, setAssignedAcountStatus] = useState(
    searchFilter.current?.map?.assignedAcountStatus
      ? searchFilter.current?.map?.assignedAcountStatus
      : ""
  );
  const [assignedMapIcons, setAssignedMapIcons] = useState(
    searchFilter.current?.map?.assignedMapIcons
      ? searchFilter.current?.map?.assignedMapIcons
      : []
  );
  const [assignedSalesOrgId, setAssignedSalesOrgId] = useState(null);
  const [assignedUpdatedFrom, setAssignedUpdatedFrom] = useState(
    searchFilter.current?.map?.assignedFromDate
      ? searchFilter.current?.map?.assignedFromDate
      : null
  );
  const [assignedUpdatedTo, setAssignedUpdatedTo] = useState(
    searchFilter.current?.map?.assignedToDate
      ? searchFilter.current?.map?.assignedToDate
      : null
  );
  const [assignedReviewType, setAssignedReviewType] = useState(null);
  const [assignedSalesOrgs, setAssignedSalesOrgs] = useState(
    searchFilter.current?.map?.assignedOrgIds
      ? searchFilter.current?.map?.assignedOrgIds
      : []
  );
  const [assignedQuickDate, setAssignedQuickDate] = useState(
    searchFilter.current?.map?.assignedQuickDateValue
      ? searchFilter.current?.map?.assignedQuickDateValue
      : null
  );
  const [assignedSalesRepIds, setAssignedSalesRepIds] = useState(
    searchFilter.current?.map?.salesReps
      ? searchFilter.current?.map?.salesReps
      : []
  );
  const [assignedDateType, setAssignedDateType] = useState(
    searchFilter.current?.map?.assignedDateType
      ? searchFilter.current?.map?.assignedDateType
      : "LastUpdated"
  );
  const [assignedRepToggle, setAssignedRepToggle] = useState("AssignedTo");
  const [mapIconToggle, setMapIconToggle] = useState("CurrentMapIcon");
  const [assignedProspectSold, setAssignedProspectSold] = useState(null);
  const [assignedFiberCompanyIds, setAssignedFiberCompanyIds] = useState(
    searchFilter.current?.map?.assignedFiberCompanies
      ? searchFilter.current?.map?.assignedFiberCompanies
      : []
  );
  const [showDeactivatedAccounts, setShowDeactivatedAccounts] = useState(false);
  const [includeInvalid, setIncludeInvalid] = useState(false);
  // Unassigned Leads Filters
  const [unassignedLeadsEnabled, setUnassignedLeadsEnabled] = useState(
    searchFilter.current?.map?.unassignedEnabled != null
      ? searchFilter.current?.map?.unassignedEnabled
      : true
  );
  const [unassignedConvertedToCustomer, setUnassignedConvertedToCustomer] =
    useState(
      searchFilter.current?.map?.unassignedConvertedToCustomer
        ? searchFilter.current?.map?.unassignedConvertedToCustomer
        : null
    );
  const [unassignedCreatedFrom, setUnassignedCreatedFrom] = useState(null);
  const [unassignedCreatedTo, setUnassignedCreatedTo] = useState(null);
  const [unassignedAcountStatus, setUnassignedAcountStatus] = useState(
    searchFilter.current?.map?.unassignedAcountStatus
      ? searchFilter.current?.map?.unassignedAcountStatus
      : ""
  );
  const [unassignedMapIcons, setUnassignedMapIcons] = useState([]);
  const [unassignedSalesOrgId, setUnassignedSalesOrgId] = useState(null);
  const [unassignedUpdatedFrom, setUnassignedUpdatedFrom] = useState(
    searchFilter.current?.map?.unassignedFromDate
      ? searchFilter.current?.map?.unassignedFromDate
      : null
  );
  const [unassignedUpdatedTo, setUnassignedUpdatedTo] = useState(
    searchFilter.current?.map?.unassignedToDate
      ? searchFilter.current?.map?.unassignedToDate
      : null
  );
  const [unassignedReviewType, setUnassignedReviewType] = useState(null);
  const [unassignedSalesOrgs, setUnassignedSalesOrgs] = useState([]);
  const [unassignedQuickDate, setUnassignedQuickDate] = useState(
    searchFilter.current?.map?.unassignedQuickDateValue
      ? searchFilter.current?.map?.unassignedQuickDateValue
      : null
  );
  const [unassignedDateType, setUnassignedDateType] = useState(
    searchFilter.current?.map?.unassignedDateType
      ? searchFilter.current?.map?.unassignedDateType
      : "LastUpdated"
  );
  const [unassignedFiberCompanyIds, setUnassignedFiberCompanyIds] = useState(
    []
  );
  const [assignedCompanyWithName, setAssignedCompanyWithName] = useState(
    searchFilter.current?.map?.assignedFiberCompanies
      ? searchFilter.current?.map?.assignedFiberCompanies
      : null
  );
  const [unassignedCompanyWithName, setUnassignedCompanyWithName] = useState(
    searchFilter.current?.map?.unassignedFiberCompanies
      ? searchFilter.current?.map?.unassignedFiberCompanies
      : null
  );
  const [locationHistories, setLocationHistories] = useState([{}]);
  const connectionService = useContext(ConnectionContext);
  const [currentLocationCoordinates, setCurrentLocationCoordinates] =
    useState(null);
  // const [lastRequestTimeStamp, setLastRequestTimeStamp] = useState(null);
  // save
  // const currentCoordinates = useRef(null)
  const [playTime, setPlayTime] = useState(1);
  const [userPathColor, setUserPathColor] = useState(null);
  const [targetUserId, setTargetUserId] = useState(null);
  const [pathFeature, setPathFeature] = useState(null);
  const [locationQuickDate, setLocationQuickDate] = useState("Today");
  const [locationFromDate, setLocationFromDate] = useState(null);
  const [locationToDate, setLocationToDate] = useState(null);
  const [toggleState, setToggleState] = useState({
    currentLocation: false,
    path: false,
    activity: false,
  });
  const initialRender = useRef(true);
  const [usingLight, setUsingLight] = useState(true);
  const [activityCoords, setActivityCoords] = useState(null);
  const [repLatestLocation, setRepLatestLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [boltMode, setBoltMode] = useState(false);
  const [candidateIds, setCandidateIds] = useState([]);
  //CANDIDATES FILTERS
  const [candidateAssignedQuickDate, setCandidateAssignedQuickDate] = useState(
    candidateSearchFilter.current?.map?.assignedQuickDate
      ? candidateSearchFilter.current?.map?.assignedQuickDate
      : null
  );
  const [candidateName, setCandidateName] = useState(
    candidateSearchFilter.current?.map?.name
      ? candidateSearchFilter.current?.map?.name
      : null
  );
  const [candidateEmail, setCandidateEmail] = useState(
    candidateSearchFilter.current?.map?.email
      ? candidateSearchFilter.current?.map?.email
      : null
  );
  const [candidateLeadType, setCandidateLeadType] = useState(
    candidateSearchFilter.current?.map?.leadType
      ? candidateSearchFilter.current?.map?.leadType
      : []
  );
  const [candidateStatus, setCandidateStatus] = useState(
    candidateSearchFilter.current?.map?.status
      ? candidateSearchFilter.current?.map?.status
      : null
  );
  const [candidateIndustries, setCandidateIndustries] = useState(
    candidateSearchFilter.current?.map?.industries
      ? candidateSearchFilter.current?.map?.industries
      : []
  );
  const [candidateCompanies, setCandidateCompanies] = useState(
    candidateSearchFilter.current?.map?.companies
      ? candidateSearchFilter.current?.map?.companies
      : []
  );
  const [candidatePositionTitles, setCandidatePositionTitles] = useState(
    candidateSearchFilter.current?.map?.positionTitles
      ? candidateSearchFilter.current?.map?.positionTitles
      : []
  );
  const [candidateCreatedFrom, setCandidateCreatedFrom] = useState(
    candidateSearchFilter.current?.map?.createdFrom
      ? candidateSearchFilter.current?.map?.createdFrom
      : null
  );
  const [candidateCreatedTo, setCandidateCreatedTo] = useState(
    candidateSearchFilter.current?.map?.createdTo
      ? candidateSearchFilter.current?.map?.createdTo
      : null
  );
  const [candidateAppointmentFrom, setCandidateAppointmentFrom] = useState(
    candidateSearchFilter.current?.map?.appointmentFrom
      ? candidateSearchFilter.current?.map?.appointmentFrom
      : null
  );
  const [candidateAppointmentTo, setCandidateAppointmentTo] = useState(
    candidateSearchFilter.current?.map?.appointmentTo
      ? candidateSearchFilter.current?.map?.appointmentTo
      : null
  );
  const [candidateLastUpdatedFrom, setCandidateLastUpdatedFrom] = useState(
    candidateSearchFilter.current?.map?.lastUpdatedFrom
      ? candidateSearchFilter.current?.map?.lastUpdatedFrom
      : null
  );
  const [candidateLastUpdatedTo, setCandidateLastUpdatedTo] = useState(
    candidateSearchFilter.current?.map?.lastUpdatedTo
      ? candidateSearchFilter.current?.map?.lastUpdatedTo
      : null
  );
  const [candidateMapIcons, setCandidateMapIcons] = useState(
    candidateSearchFilter.current?.map?.mapIcons
      ? candidateSearchFilter.current?.map?.mapIcons
      : []
  );

  const [candidates, setCandidates] = useState([]);
  const [salesMode, setSalesMode] = useState(true);
  const [recruitMode, setRecruitMode] = useState(false);
  const [salesRepIds, setSalesRepIds] = useState([]);
  const { mode, setMode } = useMode();
  const distanceRef = useRef(0);
  const shouldReRenderServicableAreas = useRef(true);
  const createMode = useRef(false);

  // Intailizing the fccdata fileration data attributes
  const [fccSearchType, setFccSearchType] = useState("provider");
  const [fccDataGeoType, setFccDataGeoType] = useState(
    fccDataSearchFilters.current?.map?.geoType
      ? fccDataSearchFilters.current?.map?.geoType
      : "STATE"
  );
  const [fccDataTecType, setFccDataTecType] = useState(
    fccDataSearchFilters.current?.map?.tecType
      ? fccDataSearchFilters.current?.map?.tecType
      : null
  );
  const [fccDataProviders, setFccDataProviders] = useState(
    fccDataSearchFilters.current?.map?.providers
      ? fccDataSearchFilters.current?.map?.providers
      : null
  );
  const [fccDataPhyTecType, setFccDataPhyTecType] = useState(
    fccDataSearchFilters.current?.map?.phyTecType
      ? fccDataSearchFilters.current?.map?.phyTecType
      : null
  );

  const [fccData, setFccData] = useState([]);
  // Intailizing the FCC Mode attribute
  const [fccMode, setFCCMode] = useState(false);

  // Company Coverage Area
  const [enableCoverageArea, setEnableCoverageArea] = useState(false);
  const [companyIdForCoverageArea, setCompanyIdForCoverageArea] =
    useState(null);

  const housesSortedByLatitude = useRef([]);
  const housesSortedByLongitude = useRef([]);

  const [myLeadsEnable, setMyLeadsEnable] = useState(false);
  const [myLeadsToggle, setMyLeadsToggle] = useState("MyLeads");
  const [calendarPopup, setCalendarPopup] = useState(false);
  const rTree = useRef(null);
  const mapHelper = new MapHelper();

  useEffect(() => {
    if (mapLayers.length > 0) {
      polygonRefs.current[polygonRefs.current.length - 1].setStyle({
        color: color,
      });
    }
  }, [color]);

  const getLastKnownRepLocations = async () => {
    if (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") {
      let res = await UsersApi.getLastKnownRepLocation();
      if (res.status.success) {
        setRepLatestLocation(res.data);
      }
    }
  };

  useEffect(() => {
    console.log("Mode is: ", mode);
    if (mode === "SalesMode") {
      setSalesMode(true);
      setRecruitMode(false);
      setCandidates([]);
    } else if (mode === "RecruitMode") {
      setRecruitMode(true);
      setSalesMode(false);
      setFiberHouses([]);
    } else if (mode === "BothMode") {
      setRecruitMode(true);
      setSalesMode(true);
    }
  }, [mode]);

  //Get actuall FCC data by calling endpint
  const getFccData = async () => {
    // alert(`The fcc Mode is: ${fccMode}`);
    if (!fccMode) return;

    // setLoading(true);
    try {
      const req = {
        geoType: fccDataGeoType,
        tecType: fccDataTecType,
        providers: fccDataProviders,
      };
      // alert(`Req geoType: ${req.geoType}`);
      // alert(`Req tecType: ${req.tecType}`);
      // alert(`Req providers: ${req.providers}`);
      const res = await UsersApi.GetFccDataAsync(req);

      if (res) {
        setFccData(res.data);
      } else {
        console.warn("FCC Data request failed:", res);
      }
    } catch (error) {
      console.error("Error fetching FCC data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCandidates = async () => {
    if (recruitMode) {
      setFiberHousesLoading(true);
      if (user.userType === "SuperAdmin") {
        setLoading(true);
        let req = {
          ids: null,
          country: null,
          state: null,
          name: candidateName,
          email: candidateEmail,
          leadType:
            candidateLeadType.length === 0
              ? null
              : candidateLeadType.map((type) => type.value),
          status: candidateStatus?.value,
          industries:
            candidateIndustries.length === 0
              ? null
              : candidateIndustries.map((industry) => industry.value),
          companies:
            candidateCompanies.length === 0
              ? null
              : candidateCompanies.map((company) => company.value),
          positionTitles: candidatePositionTitles.map((title) => title.value),
          createdFrom:
            candidateCreatedFrom === "" ? null : candidateCreatedFrom,
          createdTo: candidateCreatedTo === "" ? null : candidateCreatedTo,
          appointmentFrom:
            candidateAppointmentFrom === "" ? null : candidateAppointmentFrom,
          appointmentTo:
            candidateAppointmentTo === "" ? null : candidateAppointmentTo,
          lastUpdatedFrom:
            candidateLastUpdatedFrom === "" ? null : candidateLastUpdatedFrom,
          lastUpdatedTo:
            candidateLastUpdatedTo === "" ? null : candidateLastUpdatedTo,
          mapIcons: candidateMapIcons.length === 0 ? null : candidateMapIcons,
          selectedTagIds: candidateSelectedTags.map((t) => t.id),
        };
        let res = await UsersApi.getCandidates(req);
        if (res?.status?.success) {
          setCandidates(res.data);
          setLoading(false);
          setFiberHousesLoading(false);
        }
      }
    }
  };

  const getPaths = async () => {
    setLoading(true);
    if (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") {
      let req = {
        userId: user.id,
        startDate: locationFromDate,
        repIds: salesRepIds,
        endDate: locationToDate,
      };

      let res = await UsersApi.getRepLocationPath(req);
      if (res.status.success) {
        setLocationHistories(res.data);

        connectionService.SendRepLocation = (data) => {
          setLocationHistories((prev) => {
            return prev.map((item) => {
              if (item.userId === data.userId) {
                return {
                  ...item,
                  locations: [
                    ...item.locations,
                    {
                      timestamp: data.timestamp,
                      lon: data.coordinates.longitude,
                      lat: data.coordinates.latitude,
                      activityHistory: data.activity,
                    },
                  ],
                };
              } else {
                return item;
              }
            });
          });

          setRepLatestLocation((prev) => {
            const existingRep = prev.find(
              (location) => location.userId === data.userId
            );

            if (existingRep) {
              return prev.map((location) =>
                location.userId === data.userId
                  ? {
                      ...location,
                      latitude: data.coordinates.latitude,
                      longitude: data.coordinates.longitude,
                      recordedAt: data.timestamp,
                    }
                  : location
              );
            } else {
              return [
                ...prev,
                {
                  userId: data.userId,
                  latitude: data.coordinates.latitude,
                  longitude: data.coordinates.longitude,
                  recordedAt: data.timestamp,
                },
              ];
            }
          });
        };
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.status.message,
          confirmButtonText: "Ok",
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      getPaths();
      getLastKnownRepLocations();
    }
    getPaths();
  }, [locationFromDate, locationToDate]);

  useEffect(() => {
    if (recruitMode) {
      getCandidates();
    } else {
      setCandidates([]);
    }
  }, [recruitMode]);

  // Adding Fcc useEffect
  useEffect(() => {
    const fetchData = async () => {
      // alert(`Fcc Mode is: ${fccMode}`);
      if (fccMode) {
        // await getFccData();
      } else {
        setFccData([]);
      }
    };

    fetchData();

    return () => {
      // Optional cleanup function if necessary
      setFccData([]);
    };
  }, [fccMode]);

  const getMapIcons = async () => {
    const res = await MapIconApi.GetAllMapIcons();
    if (res?.status?.success) {
      // Sort all the icons by the order, placing those with null order at the end
      res.data.sort((a, b) => {
        if (a.order === null) return 1;
        if (b.order === null) return -1;
        return a.order - b.order;
      });
      // console.log(res.data)
      setMapIcons(res.data);
      if (
        !searchFilter.current?.map?.assignedMapIcons ||
        searchFilter.current?.map?.assignedMapIcons?.length === 0
      ) {
        setAssignedMapIcons(res.data.map((m) => m.id));
      }
      if (
        !searchFilter.current?.map?.unassignedMapIcons ||
        searchFilter.current?.map?.unassignedMapIcons?.length === 0
      ) {
        setUnassignedMapIcons(res.data.map((m) => m.id));
      }
      if (
        !candidateSearchFilter.current?.mapIcons ||
        candidateSearchFilter.current?.mapIcons?.length === 0
      ) {
        setCandidateMapIcons(res.data.map((m) => m.id));
      }
    }
  };

  const getSalesOrg = async () => {
    const req = new SearchSalesOrgReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 100;
    const res = await OrgApi.SearchOrg(req);
    if (res?.status?.success) {
      setSalesOrg(res.data.list);
      if (
        !searchFilter.current?.map?.assignedOrgIds ||
        searchFilter.current?.map?.assignedOrgIds?.length === 0
      ) {
        setAssignedSalesOrgs(res.data.list.map((m) => m.id));
      }

      if (
        !searchFilter.current?.map?.unassignedOrgIds ||
        searchFilter.current?.map?.unassignedOrgIds?.length === 0
      ) {
        setUnassignedSalesOrgs(res.data.list.map((m) => m.id));
      }
    }
  };

  async function getSalesReps() {
    const req = new SearchUserReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 100;
    req.userTypes = ["SalesRep", "SalesOrgAdmin"];
    req.includeDeactivated = true;
    if (user?.userType !== "SuperAdmin") {
      req.salesOrgId = user.salesOrgId;
    }

    const res = await UsersApi.SearchUser(req);
    if (res?.status?.success) {
      setSalesReps(res.data.list);
      if (
        !searchFilter.current?.map?.salesReps ||
        searchFilter?.current?.map?.salesReps?.length === 0
      ) {
        setAssignedSalesRepIds(res.data.list.map((m) => m.id));
      }
    }
  }

  useEffect(() => {
    if (mapIcons == null || mapIcons.length === 0) {
      getMapIcons();
    }
  }, []);

  useEffect(() => {
    if (salesOrg == null || salesOrg.length === 0) {
      getSalesOrg();
    }
  }, []);

  useEffect(() => {
    if (salesReps == null || salesReps.length === 0) {
      getSalesReps();
    }
  }, []);

  const servicableAreaClicked = (id, points, area) => {
    if (index !== 3) {
      setIndex(4);
      // setSelectedServicableArea(area)
      // setMembersLoaded(false)
      // setAvailableMembersLoaded(false)
    }
  };

  useEffect(() => {
    if (location.state?.fiberHouse) {
        setSelectedFiberHouse(location.state.fiberHouse);
        setSalesMode(true);
        setIndex(10);
    } else if (location.state?.location && mapObject) {
        // Handle new location (from search result)
        console.log("Moving map to:", location.state.location.lat, location.state.location.lng);

        mapObject.getView().setCenter(fromLonLat([location.state.location.lng, location.state.location.lat]));
        mapObject.getView().setZoom(location.state.zoom || 16);
    }
}, [location, mapObject]); // Ensure this runs when location or mapObject updates


  useEffect(() => {
    if (location.state?.candidate) {
      setSelectedCandidateLead(location.state.candidate);
      setRecruitMode(true);
      setIndex(19);
    }
  }, []);

  // Adding useEffect When FccMode changing.
  useEffect(() => {}, []);

  // useEffect(
  //     () => {
  //         if (location.state?.fiberHouse && mapObject) {
  //             mapObject.getView().setCenter(fromLonLat([location.state.fiberHouse.coordinates.longitude, location.state.fiberHouse.coordinates.latitude]))
  //             mapObject.getView().setZoom(18)
  //             console.log("fiber house", location.state.fiberHouse)
  //         }
  //         else if (!location.state?.fiberHouse.id && mapObject) {
  //             navigator.geolocation.getCurrentPosition(
  //                 (position) => {
  //                     const center = fromLonLat([
  //                         position.coords.longitude,
  //                         position.coords.latitude,
  //                     ])
  //                     mapObject.getView().animate({ zoom: 15, duration: 1000, center: center });

  //                     const geolocation = new Geolocation({
  //                         trackingOptions: {
  //                             enableHighAccuracy: true,

  //                         },
  //                         projection: mapObject?.getView().getProjection(),
  //                     })

  //                     if(currentPositiionLayer){
  //                         mapObject.removeLayer(currentPositiionLayer);
  //                         setCurrentPositionLayer(null)
  //                     }

  //                     console.log("geolocation", geolocation.getPosition())
  //                     const positionFeature = new Feature(
  //                         new Point(center),
  //                     );
  //                     const iconStyle = new Style({
  //                         image: new CircleStyle({
  //                             radius: 12,
  //                             stroke: new Stroke({
  //                                 color: "#fff",
  //                                 width: 2,
  //                             }),
  //                             fill: new Fill({
  //                                 color: "#3399CC",
  //                             }),
  //                         }),
  //                     });
  //                     positionFeature.setStyle(iconStyle);
  //                     // const geolocationControl = map.getControlsByType('Geolocation')[0];
  //                     // if (geolocationControl) {
  //                     //   // If it exists, we don't need to create a new one
  //                     //   console.log("Using existing Geolocation control");
  //                     //   return;
  //                     // }

  //                     const vectorSource = new VectorSource({
  //                         features: [positionFeature],
  //                     });

  //                     const vectorLayer = new VectorLayer({
  //                         source: vectorSource,
  //                     });
  //                     vectorLayer.setZIndex(1000);

  //                     mapObject.addLayer(vectorLayer);
  //                     setCurrentPositionLayer(vectorLayer)
  //                     geolocation.on("change", function () {
  //                         const coordinates = geolocation.getPosition();
  //                         console.log("coordinates", coordinates)
  //                         if(currentPositiionLayer && coordinates){
  //                             positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
  //                         }
  //                     });
  //                     geolocation.setTracking(true);
  //                 })
  //         }
  //     }, [mapObject]
  // )

  useEffect(() => {
    if (initialRender.current) {
      return;
    }

    if (location.state?.fiberHouse?.coordinates && mapObject) {
      mapObject
        .getView()
        .setCenter(
          fromLonLat([
            location?.state?.fiberHouse?.coordinates?.longitude,
            location?.state?.fiberHouse?.coordinates?.latitude,
          ])
        );
      mapObject.getView().setZoom(18);
    }
    if (location.state?.candidate && mapObject) {
      mapObject
        .getView()
        .setCenter(
          fromLonLat([
            location.state.candidate.coordinates.longitude,
            location.state.candidate.coordinates.latitude,
          ])
        );
      mapObject.getView().setZoom(18);
    } else if (!location.state?.fiberHouse?.id && !location.state?.location && mapObject) {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = fromLonLat([
          position.coords.longitude,
          position.coords.latitude,
        ]);
        setCurrentLocationCoordinates({
          longitude: toLonLat(center)[0],
          latitude: toLonLat(center)[1],
        });

        // currentCoordinates.current = {
        //     longitude: toLonLat(center)[0],
        //     latitude: toLonLat(center)[1]
        // }

        localStorage.setItem("currentLocation", JSON.stringify(center));

        // mapObject.getView().setCenter(center)
        // mapObject.getView().setZoom(18)
        try {
          mapObject
            .getView()
            .animate({ center: center, zoom: 18, duration: 500 });
        } catch (e) {
          console.error(e);
        }
        const geolocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: mapObject?.getView().getProjection(),
        });
        if (currentPositiionLayer) {
          mapObject.removeLayer(currentPositiionLayer);
          setCurrentPositionLayer(null);
        }

        const positionFeature = new Feature(new Point(center));
        const iconStyle = new Style({
          image: new CircleStyle({
            radius: 12,
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
            fill: new Fill({
              color: "#0038FF",
            }),
          }),
        });
        positionFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
          features: [positionFeature],
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        vectorLayer.setZIndex(100000);

        mapObject.addLayer(vectorLayer);
        setCurrentPositionLayer(vectorLayer);

        geolocation.on("change:position", async function () {
          let point1 = localStorage.getItem("currentLocation")
            ? JSON.parse(localStorage.getItem("currentLocation"))
            : null;
          // console.log("point1", point1);
          const coordinates = geolocation.getPosition();
          let distance = getDistance(toLonLat(point1), toLonLat(coordinates));
          // console.log("distance", distance)
          if (
            point1 == null ||
            getDistance(toLonLat(point1), toLonLat(coordinates)) >= 15
          ) {
            setCurrentLocationCoordinates({
              longitude: toLonLat(coordinates)[0],
              latitude: toLonLat(coordinates)[1],
            });
            // currentCoordinates.current = {
            //     longitude: toLonLat(coordinates)[0],
            //     latitude: toLonLat(coordinates)[1]
            // }

            localStorage.setItem(
              "currentLocation",
              JSON.stringify(coordinates)
            );

            let req = {
              repId: user.id,
              coordinate: {
                longitude: toLonLat(coordinates)[0],
                latitude: toLonLat(coordinates)[1],
              },
            };
            let res = await UsersApi.saveSalesRepLocationHistory(req);
            if (res.status.success) {
              console.log("Saved Sales Rep Location");
            } else {
              console.log("FAILED to Save Sales Rep Location");
            }
          }

          positionFeature.setGeometry(
            coordinates ? new Point(coordinates) : null
          );
        });
        geolocation.setTracking(true);
      });
    }
    return () => {
      if (currentPositiionLayer) {
        mapObject.removeLayer(currentPositiionLayer);
        setCurrentPositionLayer(null);
      }
    };
  }, [mapObject, location]);

  const fiberHouseClicked = async (fh) => {
    setSelectedFiberHouse(fh);
    setIndex(10);
  };

  const candidateClicked = async (candidate) => {
    setSelectedCandidateLead(candidate);

    setIndex(19);
  };

  const searchFiberHouses = async (
    isFirstSearch,
    pageSize = 500,
    pageNumber = 1
  ) => {
    // if(!usingLight){
    //     return
    // }
    if (salesMode) {
      setFiberHousesLoading(true);

      const req = new SearchFiberHousesReq();
      req.pagingParams.pageNumber = pageNumber;
      req.pagingParams.pageSize = pageSize;

      req.includeInvalids = includeInvalid;

      if (user?.userType === "SalesOrgAdmin") {
        req.salesOrgId = user.salesOrgId;
        req.teamAdminId = user.id;
      }

      if (user?.userType === "SalesRep") {
        req.salesRepId = user.id;
        req.salesOrgId = user.salesOrgId;
      }

      // assigned filters
      req.assignedEnabled = assignedLeadsEnabled || myLeadsEnable;
      req.assignedConvertedToCustomer = assignedLeadsEnabled
        ? assignedConvertedToCustomer
        : null;
      if (assignedAcountStatus === "CancelsPending") {
        req.assignedIssueType = "Cancel";
      } else {
        req.assignedAccountStatus =
          assignedAcountStatus !== "" ? assignedAcountStatus : null;
      }
      // req.assignedSalesOrgId = assignedSalesOrgId
      req.assignedSalesRepIds = myLeadsEnable
        ? [user.id]
        : assignedSalesRepIds.length === salesReps.length ||
          salesReps.length === 0
        ? null
        : assignedSalesRepIds;
      req.assignedSalesOrgIds = assignedLeadsEnabled
        ? assignedSalesOrgs.length === salesOrg.length || salesOrg.length === 0
          ? null
          : assignedSalesOrgs
        : null;
      req.assignedMapIconIds =
        assignedLeadsEnabled || myLeadsEnable
          ? assignedMapIcons.length === mapIcons.length || mapIcons.length === 0
            ? null
            : assignedMapIcons
          : null;
      req.assignedUpdatedBy = assignedRepToggle !== "AssignedTo" ? true : false;
      req.assignedProspectSold = assignedProspectSold;
      req.assignedFiberCompanyIds = assignedCompanyWithName
        ? assignedCompanyWithName.map((c) => c.id)
        : assignedFiberCompanyIds;
      req.assignedMapIconOnceWas = mapIconToggle === "OnceWas" ? true : false;

      req.mySelectedTagIds = mySelectedTags.map((t) => t.id);
      req.assignedSelectedTagIds = assignedSelectedTags.map((t) => t.id);
      req.unassignedSelectedTagIds = unassignedSelectedTags.map((t) => t.id);

      switch (assignedDateType) {
        case "LastUpdated":
          if (assignedUpdatedFrom) {
            req.assignedLeadUpdatedFrom = assignedUpdatedFrom;
          }
          if (assignedUpdatedTo) {
            req.assignedLeadUpdatedTo = assignedUpdatedTo;
          }
          break;
        case "DateSold":
          if (assignedUpdatedFrom) {
            req.assignedLeadTimeStampFrom = assignedUpdatedFrom;
          }
          if (assignedUpdatedTo) {
            req.assignedLeadTimeStampTo = assignedUpdatedTo;
          }
          break;
        case "InstallDate":
          if (assignedUpdatedFrom) {
            req.assignedLeadInstallDateFrom = assignedUpdatedFrom;
          }
          if (assignedUpdatedTo) {
            req.assignedLeadInstallDateTo = assignedUpdatedTo;
          }

          break;
        case "AppointmentDate":
          if (assignedUpdatedFrom) {
            req.assignedLeadAppointmentDateFrom = assignedUpdatedFrom;
          }
          if (assignedUpdatedTo) {
            req.assignedLeadAppointmentDateTo = assignedUpdatedTo;
          }
          break;
        case "CreatedAt":
          if (assignedUpdatedFrom) {
            req.assignedCreatedFrom = assignedUpdatedFrom;
          }
          if (assignedUpdatedTo) {
            req.assignedCreatedTo = assignedUpdatedTo;
          }
          break;
        default:
          break;
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.assignedMapIcons?.length > 0
      ) {
        req.assignedMapIconIds = searchFilter.current?.map?.assignedMapIcons;
        setAssignedMapIcons(searchFilter.current?.map?.assignedMapIcons);
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedFromDate) {
        req.assignedLeadUpdatedFrom =
          searchFilter.current?.map?.assignedFromDate;

        setAssignedUpdatedFrom(searchFilter.current?.map?.assignedFromDate);
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedToDate) {
        req.assignedLeadUpdatedTo = searchFilter.current?.map?.assignedToDate;
        setAssignedUpdatedTo(searchFilter.current?.map?.assignedToDate);
      }

      if (isFirstSearch && searchFilter.current?.map?.salesReps?.length > 0) {
        req.assignedSalesRepIds = myLeadsEnable
          ? [user.id]
          : searchFilter.current?.map?.salesReps;
        setAssignedSalesRepIds(searchFilter.current?.map?.salesReps);
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.assignedOrgIds?.length > 0
      ) {
        req.assignedSalesOrgIds = searchFilter.current?.map?.assignedOrgIds;
        setAssignedSalesOrgs(searchFilter.current?.map?.assignedOrgIds);
      }

      if (isFirstSearch && searchFilter.current?.map?.iconOnceWas) {
        req.assignedMapIconOnceWas = searchFilter.current?.map?.iconOnceWas;
        setMapIconToggle("OnceWas");
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedFiberCompanies) {
        req.assignedFiberCompanyIds =
          searchFilter.current?.map?.assignedFiberCompanies.map((f) => f.id);
        setAssignedFiberCompanyIds(
          searchFilter.current?.map?.assignedFiberCompanies.map((f) => f.id)
        );
        setAssignedCompanyWithName(
          searchFilter.current?.map?.assignedFiberCompanies
        );
        // console.log("fiber companies", req.assignedFiberCompanyIds)
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.assignedConvertedToCustomer
      ) {
        req.assignedConvertedToCustomer =
          searchFilter.current?.map?.assignedConvertedToCustomer;
        setAssignedConvertedToCustomer(
          searchFilter.current?.map?.assignedConvertedToCustomer
        );
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedDateType) {
        req.assignedDateType = searchFilter.current?.map?.assignedDateType;
        setAssignedDateType(searchFilter.current?.map?.assignedDateType);
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedAcountStatus) {
        req.assignedAccountStatus =
          searchFilter.current?.map?.assignedAcountStatus;
        setAssignedAcountStatus(
          searchFilter.current?.map?.assignedAcountStatus
        );
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedQuickDateValue) {
        req.assignedQuickDate =
          searchFilter.current?.map?.assignedQuickDateValue;
        setAssignedQuickDate(searchFilter.current?.map?.assignedQuickDateValue);
      }

      // unassigned filters
      req.unassignedEnabled = unassignedLeadsEnabled;

      req.unassignedConvertedToCustomer = unassignedLeadsEnabled
        ? unassignedConvertedToCustomer
        : null;
      req.unassignedSalesOrgId = unassignedSalesOrgId;
      if (unassignedAcountStatus === "CancelsPending") {
        req.unassignedIssueType = "Cancel";
      } else {
        req.unassignedAccountStatus =
          unassignedAcountStatus !== "" ? unassignedAcountStatus : null;
      }
      req.unassignedSalesOrgIds = unassignedLeadsEnabled
        ? unassignedSalesOrgs.length === salesOrg.length ||
          salesOrg.length === 0
          ? null
          : unassignedSalesOrgs
        : null;
      req.unassignedMapIconIds = unassignedLeadsEnabled
        ? unassignedMapIcons.length === mapIcons.length || mapIcons.length === 0
          ? null
          : unassignedMapIcons
        : null;
      req.unassignedFiberCompanyIds = unassignedCompanyWithName
        ? unassignedCompanyWithName.map((c) => c.id)
        : unassignedFiberCompanyIds;
      req.userType = user.userType;

      switch (unassignedDateType) {
        case "LastUpdated":
          if (unassignedUpdatedFrom) {
            req.unassignedLeadUpdatedFrom = unassignedUpdatedFrom;
          }
          if (unassignedUpdatedTo) {
            req.unassignedLeadUpdatedTo = unassignedUpdatedTo;
          }
          break;
        case "DateSold":
          if (unassignedUpdatedFrom) {
            req.unassignedLeadTimeStampFrom = unassignedUpdatedFrom;
          }
          if (unassignedUpdatedTo) {
            req.unassignedLeadTimeStampTo = unassignedUpdatedTo;
          }
          break;
        case "InstallDate":
          if (unassignedUpdatedFrom) {
            req.unassignedLeadInstallDateFrom = unassignedUpdatedFrom;
          }
          if (unassignedUpdatedTo) {
            req.unassignedLeadInstallDateTo = unassignedUpdatedTo;
          }

          break;
        case "AppointmentDate":
          if (unassignedUpdatedFrom) {
            req.unassignedLeadAppointmentDateFrom = unassignedUpdatedFrom;
          }
          if (unassignedUpdatedTo) {
            req.unassignedLeadAppointmentDateTo = unassignedUpdatedTo;
          }
          break;
        default:
          break;
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.unassignedMapIcons?.length > 0
      ) {
        req.unassignedMapIconIds =
          searchFilter.current?.map?.unassignedMapIcons;
        setUnassignedMapIcons(searchFilter.current?.map?.unassignedMapIcons);
      }

      if (isFirstSearch && searchFilter?.current?.map?.unassignedFromDate) {
        req.unassignedLeadUpdatedFrom =
          searchFilter?.current?.map?.unassignedFromDate;

        setUnassignedUpdatedFrom(
          searchFilter?.current?.map?.unassignedFromDate
        );
      }

      if (isFirstSearch && searchFilter.current?.map?.unassignedToDate) {
        req.unassignedLeadUpdatedTo =
          searchFilter.current?.map?.unassignedToDate;
        setUnassignedUpdatedTo(searchFilter.current?.map?.unassignedToDate);
        // req.unassignedEnabled = false
        // setUnassignedLeadsEnabled(false)
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.unassignedFiberCompanies
      ) {
        req.unassignedFiberCompanyIds =
          searchFilter.current?.map?.unassignedFiberCompanies.map((f) => f.id);
        setUnassignedFiberCompanyIds(
          searchFilter.current?.map?.unassignedFiberCompanies.map((f) => f.id)
        );
        setUnassignedCompanyWithName(
          searchFilter.current?.map?.unassignedFiberCompanies
        );
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.unassignedConvertedToCustomer
      ) {
        req.unassignedConvertedToCustomer =
          searchFilter.current?.map?.unassignedConvertedToCustomer;
        setUnassignedConvertedToCustomer(
          searchFilter.current?.map?.unassignedConvertedToCustomer
        );
      }

      if (isFirstSearch && searchFilter.current?.map?.unassignedDateType) {
        req.unassignedDateType = searchFilter.current?.map?.unassignedDateType;
        setUnassignedDateType(searchFilter.current?.map?.unassignedDateType);
      }

      if (isFirstSearch && searchFilter.current?.map?.unassignedAcountStatus) {
        req.unassignedAccountStatus =
          searchFilter.current?.map?.unassignedAcountStatus;
        setUnassignedAcountStatus(
          searchFilter.current?.map?.unassignedAcountStatus
        );
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.unassignedQuickDateValue
      ) {
        req.unassignedQuickDate =
          searchFilter.current?.map?.unassignedQuickDateValue;
        setUnassignedQuickDate(
          searchFilter.current?.map?.unassignedQuickDateValue
        );
      }

      if (
        isFirstSearch &&
        searchFilter.current?.map?.unassignedOrgIds?.length > 0
      ) {
        req.unassignedSalesOrgIds = searchFilter.current?.map?.unassignedOrgIds;
        setUnassignedSalesOrgs(searchFilter.current?.map?.unassignedOrgIds);
      }

      if (isFirstSearch && searchFilter.current?.map?.unassignedEnabled) {
        req.unassignedEnabled = searchFilter.current?.map?.unassignedEnabled;
        setUnassignedLeadsEnabled(searchFilter.current?.map?.unassignedEnabled);
      }

      if (isFirstSearch && searchFilter.current?.map?.assignedEnabled) {
        req.assignedEnabled =
          myLeadsEnable || searchFilter.current?.map?.assignedEnabled;
        setAssignedLeadsEnabled(searchFilter.current?.map?.assignedEnabled);
      }

      const res =
        await FiberHouseApi.SearchFiberHousesOptimizedLightAssignedUnassigned(
          req
        ); // SEARCHING x4 TIMES???
      if (res?.status?.success) {
        setFiberHouses(res.data);

        // sort the fiber houses by latitude and longitude

        setFiberHousesLoading(false);
        searchFilter.current = {
          ...searchFilter.current,
          map: {
            assignedMapIcons: assignedMapIcons,
            unassignedMapIcons: unassignedMapIcons,
            assignedFromDate: assignedUpdatedFrom,
            assignedToDate: assignedUpdatedTo,
            unassignedFromDate: unassignedUpdatedFrom,
            unassignedToDate: unassignedUpdatedTo,
            salesReps: assignedSalesRepIds,
            assignedOrgIds: assignedSalesOrgs,
            iconOnceWas: mapIconToggle === "OnceWas" ? true : false,
            assignedFiberCompanies: assignedCompanyWithName,
            unassignedFiberCompanies: unassignedCompanyWithName,
            unassignedOrgIds: unassignedSalesOrgs,
            assignedEnabled: assignedLeadsEnabled,
            unassignedEnabled: unassignedLeadsEnabled,
            assignedDateType: assignedDateType,
            unassignedDateType: unassignedDateType,
            assignedAcountStatus: assignedAcountStatus,
            unassignedAcountStatus: unassignedAcountStatus,
            assignedConvertedToCustomer: assignedConvertedToCustomer,
            unassignedConvertedToCustomer: unassignedConvertedToCustomer,
            assignedQuickDateValue: assignedQuickDate,
            unassignedQuickDateValue: unassignedQuickDate,
          },
        };
      }
    }
  };

  useEffect(() => {
    if (salesMode) {
      searchFiberHouses(true);
    } else {
      setFiberHouses([]);
    }
  }, [salesMode]);

  async function searchServicableAreas() {
    const req = new SearchServicableAreaReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 100;

    if (user?.userType == "SalesRep") {
      req.salesRepId = user.id;
    }

    if (user?.userType == "SalesOrgAdmin") {
      req.salesOrgId = user.salesOrgId;
    }

    const res = await ServicableAreaApi.SearchServicableArea(req);
    if (res?.status?.success) {
      const list = res?.data?.map((area, index) => {
        return {
          latlngs: area.coordinates.map((point) => {
            return [point.latitude, point.longitude];
          }),
          color: area.color,
          id: area.id,
          name: area.name,
          fiberCompanyId: area.fiberCompanyId,
          salesOrgId: area.salesOrgId,
          type: area.servicableAreaType,
          label: area.areaLabel,
          createdAt: area.createdAt,
          createdById: area.createdById,
        };
      });
      setServicableAreas(list);
    }
  }

  useEffect(() => {
    if (initialRender.current) {
      searchServicableAreas();
      initialRender.current = false;
    }
  }, []);

  useEffect(() => {
    setMapLayers([]);
    polygonRefs.current[polygonRefs.current.length - 1]?.setStyle({
      display: "none",
    });
  }, [index]);

  const updateFiberHouseInContext = (id, newProperties) => {
    setFiberHouses((prevFiberHouses) =>
      prevFiberHouses.map((fiberHouse) =>
        fiberHouse.id === id ? { ...fiberHouse, ...newProperties } : fiberHouse
      )
    );
  };

  const getSubTab = () => {
    switch (index) {
      // case 0:
      //     return <CreateServicableAreaForm searchServicableAreas={searchServicableAreas} layers={shape} setLayers={setShape} color={color} setColor={setColor} fiberHouses={fiberHouses} />
      case 1:
        return <CreateMapIcon />;
      case 2:
        return <CreateFiberHouse />;

      case 5:
        return <EditMapIcons />;
      case 6:
        return <TabbedCreateLead user={user} />;
      case 7:
        return (
          <TabbedSearchFilters
            defaultFiberCompanies={location.state?.fiberCompanies}
            assignedDateType={assignedDateType}
            setAssignedDateType={setAssignedDateType}
            unassignedDateType={unassignedDateType}
            setUnassignedDateType={setUnassignedDateType}
            unassignedConvertedToCustomer={unassignedConvertedToCustomer}
            setUnassignedConvertedToCustomer={setUnassignedConvertedToCustomer}
            unassignedCreatedFrom={unassignedCreatedFrom}
            setUnassignedCreatedFrom={setUnassignedCreatedFrom}
            unassignedCreatedTo={unassignedCreatedTo}
            setUnassignedCreatedTo={setUnassignedCreatedTo}
            unassignedAcountStatus={unassignedAcountStatus}
            setUnassignedAcountStatus={setUnassignedAcountStatus}
            unassignedMapIcons={unassignedMapIcons}
            setUnassignedMapIcons={setUnassignedMapIcons}
            assignedCreatedFrom={assignedCreatedFrom}
            setAssignedCreatedFrom={setAssignedCreatedFrom}
            assignedCreatedTo={assignedCreatedTo}
            setAssignedCreatedTo={setAssignedCreatedTo}
            assignedAcountStatus={assignedAcountStatus}
            setAssignedAcountStatus={setAssignedAcountStatus}
            fiberHouses={fiberHouses}
            searchFiberHouses={searchFiberHouses}
            salesRepId={salesRepId}
            setSalesRepId={setSalesRepId}
            doorKnockingStatus={doorKnockingStatus}
            setDoorKnockingStatus={setDoorKnockingStatus}
            setSalesOrgId={setSalesOrgId}
            salesOrgId={salesOrgId}
            leadStatus={leadStatus}
            setLeadStatus={setLeadStatus}
            notes={notes}
            setNotes={setNotes}
            assignedMapIcons={assignedMapIcons}
            setAssignedMapIcons={setAssignedMapIcons}
            mapString={mapString}
            setMapString={setMapString}
            assignedConvertedToCustomer={assignedConvertedToCustomer}
            setAssignedConvertedToCustomer={setAssignedConvertedToCustomer}
            customerInterestFrom={customerInterestFrom}
            setCustomerInterestFrom={setCustomerInterestFrom}
            customerInterestTo={customerInterestTo}
            setCustomerInterestTo={setCustomerInterestTo}
            tags={tags}
            setTags={setTags}
            mySelectedTags={mySelectedTags}
            setMySelectedTags={setMySelectedTags}
            assignedSelectedTags={assignedSelectedTags}
            setAssignedSelectedTags={setAssignedSelectedTags}
            unassignedSelectedTags={unassignedSelectedTags}
            setUnassignedSelectedTags={setUnassignedSelectedTags}
            candidateSelectedTags={candidateSelectedTags}
            setCandidateSelectedTags={setCandidateSelectedTags}
            candidateAssignedQuickDate={candidateAssignedQuickDate}
            setCandidateAssignedQuickDate={setCandidateAssignedQuickDate}
            candidateName={candidateName}
            setCandidateName={setCandidateName}
            candidateEmail={candidateEmail}
            setCandidateEmail={setCandidateEmail}
            candidateLeadType={candidateLeadType}
            setCandidateLeadType={setCandidateLeadType}
            candidateStatus={candidateStatus}
            setCandidateStatus={setCandidateStatus}
            candidateIndustries={candidateIndustries}
            setCandidateIndustries={setCandidateIndustries}
            candidateCompanies={candidateCompanies}
            setCandidateCompanies={setCandidateCompanies}
            candidatePositionTitles={candidatePositionTitles}
            setCandidatePositionTitles={setCandidatePositionTitles}
            candidateCreatedFrom={candidateCreatedFrom}
            setCandidateCreatedFrom={setCandidateCreatedFrom}
            candidateCreatedTo={candidateCreatedTo}
            setCandidateCreatedTo={setCandidateCreatedTo}
            candidateAppointmentFrom={candidateAppointmentFrom}
            setCandidateAppointmentFrom={setCandidateAppointmentFrom}
            candidateAppointmentTo={candidateAppointmentTo}
            setCandidateAppointmentTo={setCandidateAppointmentTo}
            candidateLastUpdatedFrom={candidateLastUpdatedFrom}
            setCandidateLastUpdatedFrom={setCandidateLastUpdatedFrom}
            candidateLastUpdatedTo={candidateLastUpdatedTo}
            setCandidateLastUpdatedTo={setCandidateLastUpdatedTo}
            candidateMapIcons={candidateMapIcons}
            setCandidateMapIcons={setCandidateMapIcons}
            recruitMode={recruitMode}
            // Passing FccFilteration attributes.
            fccDataGeoType={fccDataGeoType}
            setFccDataGeoType={setFccDataGeoType}
            fccDataTecType={fccDataTecType}
            setFccDataTecType={setFccDataTecType}
            fccDataPhyTecType={fccDataPhyTecType}
            setFccDataPhyTecType={setFccDataPhyTecType}
            fccDataProviders={fccDataProviders}
            setFccDataProviders={setFccDataProviders}
            fccData={fccData}
            setFccData={setFccData}
            fccSearchType={fccSearchType}
            setFccSearchType={setFccSearchType}
            // Passing Fcc Mode
            fccMode={fccMode}
          />
        );
      case 8:
        // if (searchAreaFeature.length === 0) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: "Please Mark an Area on the Map",
        //         showConfirmButton: true,
        //         confirmButtonText: "OK"
        //     })
        //     setIndex(-1)
        //     return
        // }
        return (
          <TabbedStatsPage
            fiberHouses={fiberHouses}
            candidates={candidates}
            recruitMode={recruitMode}
            fccMode={fccMode}
          />
        );
      // case 9:
      //     return <ManageLeadStatuses />
      case 10:
        return (
          <MainLeadCard
            searchFiberHouses={searchFiberHouses}
            lead={selectedFiberHouse?.lead}
            selectedFiberHouse={selectedFiberHouse}
            user={user}
            setIndex={setIndex}
          />
        );
      case 11:
        return (
          <HotLeadForm
            lead={selectedLead}
            searchFiberHouses={searchFiberHouses}
            fiberHouse={selectedFiberHouse}
            user={user}
          />
        );
      case 12:
        return (
          <FreeMonthForm
            lead={selectedLead}
            searchFiberHouses={searchFiberHouses}
            fiberHouse={selectedFiberHouse}
            user={user}
            setIndex={setIndex}
          />
        );
      case 13:
        return (
          <ActivityHistoryNew
            lead={selectedLead}
            searchFiberHouses={searchFiberHouses}
            fiberHouse={selectedFiberHouse}
            user={user}
            setIndex={setIndex}
          />
        );
      case 14:
        return (
          <MainBizCard
            lead={selectedLead}
            searchFiberHouses={searchFiberHouses}
            fiberHouse={selectedFiberHouse}
            user={user}
            setIndex={setIndex}
          />
        );
      case 15:
        return <ReviewContainer searchArea={searchArea} />;
      case 16:
        return <Legends />;
      case 17:
        return <BizCardTab lead={selectedLead} />;
      case 18:
        return <LiveTrackingTab />;
      case 19:
        return (
          <CandidateMainLeadCard selectedCandidate={selectedCandidateLead} />
        );
      case 20:
        return (
          <CandidateActivityHistory
            selectedCandidate={selectedCandidateLead}
            user={user}
          />
        );

      case 21:
        return <CreateCandidate />;
      case 22:
        return <ManageAreas />;
      // Assign FccDataComponent to index 23 and passing there attributes.
      // case 23:
      case 23:
        return <UploadCoverageArea />;
      case 24:
        return <SalesRepActivityHistory 
        salesRepId={currentSalesRepId}
        currentSalesRepDate={currentSalesRepDate}
        />

      default:
        return <></>;
    }
  };

  const closeTab = () => {
    setIndex(-1);
  };

  const handleClosePopup = useCallback(() => {
    setIndex(-1);

        if(index === 7){
            if(!loading){
                searchFiberHouses()
            }
        }

    if (index === 10) {
      if (selectedFiberHouseFeature) {
        const style = new Style({
          image: new Icon({
            src:
              "data:image/svg+xml;utf8," +
              mapIcons.find(
                (m) => m.id === selectedFiberHouseFeature.get("mapIconId")
              )?.image,
            scale: 1.2,
          }),
        });
        selectedFiberHouseFeature.setStyle(style);
        // mapObject?.getView().setZoom(mapObject?.getView().getZoom() - 0.000001);
      }
      setSelectedFiberHouse(null);
      setSelectedFiberHouseFeature(null);
    }
  }, [index, selectedFiberHouseFeature, mapIcons, mapObject]);

  const mM = [
    {
      title: "Create Map Icon",
      key: "createMapIcon",
      onClick: () => {
        setIndex(1);
      },
      user: ["SuperAdmin", "Admin"],
    },
    {
      title: "Edit Map Icons",
      key: "editMapIcons",
      onClick: () => {
        setIndex(5);
      },
      user: ["SuperAdmin", "Admin"],
    },
    {
      title: "Bulk Create FiberHouses",
      key: "bulkCreateFiberHouses",
      onClick: () => {
        setIndex(6);
      },
      user: ["SuperAdmin"],
    },
    {
      title: "Stats",
      key: "stats",
      onClick: () => {
        if (searchAreaFeature.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Please Mark an Area on the Map",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          // setIndex(-1)
          return;
        }
        setIndex(8);
      },
      user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"],
    },
    // {
    //   title: "Live Tracking",
    //   key: "liveTracking",
    //   onClick: () => {
    //     setIndex(18);
    //   },
    //   user: ["SuperAdmin", "Admin"],
    // },

    {
      title: "Legends",
      key: "legends",
      onClick: () => {
        setIndex(16);
      },
      user: ["SuperAdmin", "Admin", "SalesOrgAdmin", "SalesRep"],
    },
    {
      title: "Upload Coverage Area",
      onClick: () => {
        setIndex(23);
      },
      user: ["SuperAdmin"],
    },
  ];

  const filteredMenu = useMemo(() => {
    return mM.filter((item) => item.user.includes(user.userType));
  }, [user.userType]);

  useEffect(() => {
    setMobileMenu(filteredMenu);
  }, [filteredMenu]);

  useEffect(() => {
    return () => {
      setMapObject(null);
      setFiberHouses([]);
    };
  }, []);

  function isGalaxyTablet() {
    const ua = navigator.userAgent.toLowerCase();

    // Check for Galaxy Tablet identifiers in User-Agent
    const isAndroid = /android/.test(ua);
    const isSamsung = /samsung/.test(ua);
    const isGalaxyModel = /sm-t[0-9]+|sm-p[0-9]+/i.test(ua); // Common Galaxy Tab model prefixes

    return isAndroid && (isSamsung || isGalaxyModel);
  }

  function isIpad() {
    const ua = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();

    // Check for "iPad" in User-Agent or platform
    const hasIpadKeyword = /ipad/.test(ua) || /ipad/.test(platform);

    // Check for Macintosh User-Agent with touch support (iPadOS 13+)
    const isMacDesktop = /macintosh|macintel/.test(ua);
    const hasTouch = navigator.maxTouchPoints > 0;

    return hasIpadKeyword || (isMacDesktop && hasTouch) || isGalaxyTablet();
  }

  const content = (
    <MapContext.Provider
      value={{
        includeInvalid,
        setIncludeInvalid,
        showDeactivatedAccounts,
        setShowDeactivatedAccounts,
        setSalesMode,
        salesMode,
        setRecruitMode,
        recruitMode,
        rTree,
        boltMode,
        setBoltMode,
        // Passing fccData
        fccData,
        setFccData,
        fccDataProviders,
        setFccDataProviders,
        fccDataGeoType,
        setFccDataGeoType,
        fccDataPhyTecType,
        setFccDataPhyTecType,
        fccDataTecType,
        setFccDataTecType,
        // Passing fcc Mode
        fccMode,
        setFCCMode,
        enableCoverageArea,
        setEnableCoverageArea,
        companyIdForCoverageArea,
        setCompanyIdForCoverageArea,
        housesSortedByLatitude,
        housesSortedByLongitude,
        cluster,
        playTime,
        setPlayTime,
        pathFeature,
        setPathFeature,
        locationQuickDate,
        setLocationQuickDate,
        locationFromDate,
        setLocationFromDate,
        locationToDate,
        setLocationToDate,
        assignedCompanyWithName,
        setAssignedCompanyWithName,
        unassignedCompanyWithName,
        setUnassignedCompanyWithName,
        salesRepIds,
        setSalesRepIds,
        assignedFiberCompanyIds,
        setAssignedFiberCompanyIds,
        unassignedFiberCompanyIds,
        setUnassignedFiberCompanyIds,
        mapIconToggle,
        setMapIconToggle,
        triggerSearch,
        setTriggerSearch,
        assignedRepToggle,
        setAssignedRepToggle,
        assignedProspectSold,
        setAssignedProspectSold,
        shouldReRenderMap,
        salesReps,
        assignedQuickDate,
        setAssignedQuickDate,
        unassignedQuickDate,
        setUnassignedQuickDate,
        assignedAcountStatus,
        setAssignedAcountStatus,
        unassignedAcountStatus,
        setUnassignedAcountStatus,
        salesOrg,
        assignedSalesOrgs,
        setAssignedSalesOrgs,
        unassignedSalesOrgs,
        setUnassignedSalesOrgs,
        assignedMapIcons,
        unassignedMapIcons,
        assignedSalesRepIds,
        setAssignedSalesRepIds,
        setFiberHousesLoading,
        servicableAreas,
        setServicableAreas,
        searchServicableAreas,
        updateFiberHouseInContext,
        assignedReviewType,
        setAssignedReviewType,
        unassignedReviewType,
        setUnassignedReviewType,
        selectedCandidateLead,
        setSelectedCandidateLead,
        candidateIds,
        setCandidateIds,
        totalFiberHouses,
        setTotalFiberHouses,
        usingLight,
        setUsingLight,
        fiberHouseIds,
        setAssignedUpdatedFrom,
        assignedUpdatedFrom,
        setAssignedUpdatedTo,
        assignedUpdatedTo,
        setUnassignedUpdatedFrom,
        unassignedUpdatedFrom,
        setUnassignedUpdatedTo,
        unassignedUpdatedTo,
        setFiberHouseIds,
        assignedSalesOrgId,
        setAssignedSalesOrgId,
        unassignedSalesOrgId,
        setUnassignedSalesOrgId,
        setSelectedFiberHouseFeature,
        selectedFiberHouseFeature,
        assignedLeadsEnabled,
        setAssignedLeadsEnabled,
        unassignedLeadsEnabled,
        setUnassignedLeadsEnabled,
        setSelectedLead,
        selectedLead,
        setOpenMobileMenu,
        searchFiberHouses,
        searchAreaFeature,
        setSearchAreaFeature,
        searchArea,
        setSearchArea,
        selectedFiberHouse,
        setSelectedFiberHouse,
        fiberHouses,
        fiberHouseCurrentPage,
        setFiberHouseCurrentPage,
        index,
        setFiberHouses,
        mapObject,
        setMapObject,
        closeTab,
        Index,
        setIndex,
        mapIcons,
        getMapIcons,
        fiberHouseClicked,
        createMode,
        locationHistories,
        shouldReRenderServicableAreas,
        setLocationHistories,
        currentLocationCoordinates,
        setCurrentLocationCoordinates,
        targetUserId,
        setTargetUserId,
        userPathColor,
        setUserPathColor,
        toggleState,
        setToggleState,
        activityCoords,
        setActivityCoords,
        repLatestLocation,
        setRepLatestLocation,
        loading,
        distanceRef,
        candidates,
        setCandidates,
        candidateClicked,
        selectedCandidateFeature,
        setSelectedCandidateFeature,
        getCandidates,
        setMyLeadsEnable,
        myLeadsEnable,
        setMyLeadsToggle,
        tags,
        setTags,
        calendarPopup,
        setCalendarPopup,
        currentSalesRepId,
        setCurrentSalesRepId,
        setCurrentSalesRepDate,
        currentSalesRepDate,
        fccSearchType,
        setFccSearchType,
      }}
    >
      <div
        style={{
          overscrollBehavior: "none",
          position:
             "absolute",
          top: "0px" ,
          height: "100dvh" ,
          width:"100vw",
          touchAction:  "none",
        }}
        className="map_page_container"
      >
        <div
          className="relative"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {fiberHousesLoading && (
            <div className="transparent_loader">
              <div className="loader_text">
                <p>Loading Leads</p>
                <CircularProgress sx={{ color: "#333" }} size={30} />
              </div>
            </div>
          )}

          {/* <SearchLocation
            mapObject={mapObject}
            servicableAreas={servicableAreas}
            setIndex={setIndex}
            setSelectedFiberHouse={setSelectedFiberHouse}
            fiberHouses={fiberHouses}
          /> */}
          <Map
            currentPositionLayer={currentPositiionLayer}
            setCurrentPositionLayer={setCurrentPositionLayer}
            // shared leads filters
            unassignedConvertedToCustomer={unassignedConvertedToCustomer}
            unassignedCreatedFrom={unassignedCreatedFrom}
            unassignedCreatedTo={unassignedCreatedTo}
            unassignedAcountStatus={unassignedAcountStatus}
            unassignedMapIcons={unassignedMapIcons}
            // leads filters
            leadCustomer={assignedConvertedToCustomer}
            leadFrom={assignedCreatedFrom}
            leadTo={assignedCreatedTo}
            leadHasReviews={assignedAcountStatus}
            leadMapIcons={assignedMapIcons}
            propFiberHouse={location.state?.fiberHouse}
            displayMarker={index === 2}
            shape={shape}
            setShape={setShape}
            servicableAreaClicked={servicableAreaClicked}
            fiberHouseClicked={fiberHouseClicked}
            candidateClicked={candidateClicked}
            color={color}
            fiberHouses={fiberHouses}
            setFiberHouses={setFiberHouses}
            index={index}
            setIndex={setIndex}
          />

          {
          // (screenWidth <= 1000 || mapHelper.isIpad()) && 
          (

            <>
              <ContextMenu
                anchorEl={openMobileMenu}
                handleClose={() => setOpenMobileMenu(null)}
                menuItems={mobileMenu}
              />
              {index >= 0 && (
                <Popup
                  open={true}
                  onClose={() => {
                    if (index === 7) {
                      if (!loading) {
                        searchFiberHouses();
                      }
                    }
                    setIndex(-1);
                    if (index === 10) {
                      if (selectedFiberHouseFeature) {
                        const style = new Style({
                          image: new Icon({
                            src:
                              "data:image/svg+xml;utf8," +
                              mapIcons.find(
                                (m) =>
                                  m.id ===
                                  selectedFiberHouseFeature.get("mapIconId")
                              )?.image,
                            scale: 1.2,
                          }),
                        });
                        selectedFiberHouseFeature.setStyle(style);
                        mapObject
                          ?.getView()
                          .setZoom(mapObject?.getView().getZoom() - 0.000001);
                      }

                      setSelectedFiberHouse(null);
                      setSelectedFiberHouseFeature(null);
                      // mapObject?.render()
                    }
                  }}
                >
                  {getSubTab()}
                </Popup>
              )}
            </>
          )}
        </div>
        
      </div>
    </MapContext.Provider>
  );
  if (showLayout) return <Layout>{content}</Layout>;
  else return content;
};
export { MapContext };
export default Index;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { MapContext } from '..';
import './LiveTrackingTab.scss';
import RepList from './RepsTrackingList';
import VersatileLoader from '../../../../components/VersatileLoader'
import QuickDate from '../../../../components/QuickDate';
import { FaSearch } from 'react-icons/fa';
import UserSelector from '../../../../components/UserSelector';
import { UserContext } from '../../../../App';
import IndustrySelector from '../../../../components/IndustrySelector/IndustrySelector';


const formatDateToISOString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  // Concatenate without timezone offset
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const LiveTrackingTab = () => {
  const map = useContext(MapContext);
  const user = useContext(UserContext);
  const [fromDate, setFromDate] = useState(formatDateToISOString(new Date(new Date().setHours(0, 0, 0, 0))));
  const [toDate, setToDate] = useState(formatDateToISOString(new Date(new Date().setHours(23, 59, 59, 999))));
  const [quickDateValue, setQuickDateValue] = useState('Today');
  const [salesReps, setSalesReps] = useState([]);
  const [userWithName, setUserWithName] = useState(null);

  const reps = map.locationHistories.filter((rep) => Array.isArray(rep.locations) && rep.locations.length > 0);

  const updateLocationHistories = (updateFn) => {
    map.setLocationHistories((prevHistories) => updateFn(prevHistories));
  };


  const handleDateChange = () => {

      map.setLocationFromDate(fromDate);
      map.setLocationToDate(toDate);
      map.setLocationQuickDate(quickDateValue);
      map.setSalesRepIds(salesReps)
    
  };
  const handleIndustryChange = (selected) => {
        console.log('Selected Industry:', selected);
    };


  return (
    <div className="live-tracking-tab">
      <h1 className="title">Live Tracking</h1>
      <div className="date-picker-container">


      <QuickDate selectQuickDate="Today" setFrom={setFromDate} setTo={setToDate} setQuickDateValue={setQuickDateValue} />
      </div>

      <div className="selector_con">

        <div className='single'>
            <UserSelector setUserWithName={setUserWithName} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} setUser={setSalesReps} conversationType={"Group"} placeholder='Select Sales Reps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
            </div>
        <div className='single'>

            <IndustrySelector onChange={handleIndustryChange} />
        </div>
       
      </div>
      <div className='input'>

        <button className='searchBtn' onClick={handleDateChange} >
          Search
        </button>
      </div>
      {map.loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: "3rem"
        }}>
          <VersatileLoader size='large' color='#2761CF' />
        </div>
      ) : (
        <div className="rep-list-container">
          <RepList reps={reps} setTargetUserId={map.setTargetUserId} setUserPathColor={map.setUserPathColor} updateLocationHistories={updateLocationHistories} setToggleState={map.setToggleState} toggleState={map.toggleState} locationFromDate={map.locationFromDate} setActivityCoords={map.setActivityCoords} distanceRef={map.distanceRef}/>
        </div>
      )}
    </div>
  );
};

export default LiveTrackingTab;
import React from "react";
import BusinessCard from "../../../Shared/BusinessCenter/components/BusinessCard";
import PointPlan from "./PointPlan";
import { IoWifi } from "react-icons/io5";
import { IoMdTv } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import ContactButtons from "../../../../Services/ContactButtons";
import MobileVersionBusinessCard from "../../../Reps/DigitalBusinessCenter/components/MobileVersionBusinessCard";
import PlanCard from "./PlanCard";
import { FaCheckCircle } from "react-icons/fa";
const Index = ({
  offer,
  setOpenConsultation,
  setOpenShareModal,
  setColor,
  setOpen,
  setSelectedPlan,
}) => {
  console.log("offer", offer);
  return (
    <div>
      <div
        style={{
          background: "none",
          backgroundColor: "white",
        }}
        className="bg-white new_offer_page"
      >
        <div
          style={{
            maxWidth: "800px",
          }}
          className="inner_container"
        >
          <div className="banner">
            <img
              style={{ width: "100%", height: "auto" }}
              src={`/images/offer/astound/astound_banner.png`}
              alt="banner"
              className="banner_image first"
            />
            <div className="top_accept_offer">
              <div className="inner_div shadow-xl">
                <img
                  style={{ width: "250px" }}
                  src={"data:image/png;base64," + offer?.fiberCompany?.logo}
                  alt="logo"
                  className="logo"
                />
                {
                  <h1 className="text-[2.375rem] font-[800] text-center text-black">
                    ULTRA-FAST,
                    <br />
                    RELIABLE
                    <br />
                    INTERNET
                  </h1>
                }

                <button
                  className="text-white bg-gradient-to-r from-[#483092] via-[#019A86] to-[#C1DE2A] p-4 rounded-2xl text-center font-open-sans text-3xl font-extrabold uppercase "
                  onClick={() => {
                    document
                      .getElementById("plan1")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  VIEW PLANS{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="company_details">
            <p className="limited_offer text-black">ASTOUND INTERNET</p>
            <p className="text-5xl font-[800] text-[#3AB54A]">LIMITED TIME</p>
            <p className="text-5xl font-[800] text-[#483092]">HOT OFFER</p>
            <div className="promo_offer_border">
              <h1
                className="bg-white promo_offer"
                style={{ borderColor: "#483092" }}
              >
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.5rem",
                    fontWeight: "800",
                    color: "#3AB54A",
                  }}
                >
                  1-YEAR WITH<br></br>
                </span>
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.5rem",
                    fontWeight: "800",
                    color: "#483092",
                  }}
                >
                  PRICES SHOWN
                </span>
              </h1>
            </div>
            <p className="available text-[#81848A]">AVAILABLE WITH</p>
            <img
              src={`/images/offer/astound/logo.png`}
              alt="astound"
              className="company_logo"
            />
            <img
              src="/images/offer/logo.png"
              alt="lightning"
              className="company_logo max-w-[80vw]"
            />
          </div>
          {offer?.salesRep && (
            <div
              style={{
                padding: "1rem",
                marginTop: "2rem",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "10px",
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MobileVersionBusinessCard
                  displayContactButtons={true}
                  displayQr={false}
                  reviewButton={false}
                  displayOffer={false}
                  salesRep={offer?.salesRep}
                  salesRepIsUser={false}
                />
              </div>
            </div>
          )}
          {/* {offer?.salesRep && (
            <div className="bg-white">
              <BusinessCard
                salesRep={offer?.salesRep}
                salesRepId={offer?.salesRepId}
                displayOffer={false}
                fiberCompany={offer?.fiberCompanyId}
              />
            </div>
          )} */}

          <div className="banner" id="plan1">
            <img
              style={{ width: "100%", height: "auto" }}
              src="/images/offer/astound/friends_banner.png"
              alt="banner"
              className="banner_image"
            />
            {/* <div className='flex justify-center items-center' style={{
                                backgroundColor: "#F47920",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",

                                // borderRadius:"20px"
                            }}>
                                <PointPlan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[0].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[1]?.promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[0].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div> */}
          </div>
          {offer?.offerTemplate && (
            <div className="company_details margin-top-4">
              <p className="limited_offer text-black">FIBER PLANS</p>
              <PlanCard
                offer={offer}
                plan={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]?.fiberPlan
                }
                color={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]?.color
                    ? offer?.offerTemplate?.offerTemplateFiberPlans[0].color
                    : "#483092"
                }
                setSelectedPlan={setSelectedPlan}
                setOpen={setOpen}
                name={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]
                    ?.promotionalName
                }
              />
              <PlanCard
                offer={offer}
                plan={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]?.fiberPlan
                }
                color={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]?.color
                    ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color
                    : "#206C8E"
                }
                setSelectedPlan={setSelectedPlan}
                setOpen={setOpen}
                name={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]
                    ?.promotionalName
                }
              />
              {offer?.offerTemplate?.offerTemplateFiberPlans?.length > 2 && (
                <PlanCard
                  offer={offer}
                  plan={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]?.fiberPlan
                  }
                  color={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]?.color
                      ? offer?.offerTemplate?.offerTemplateFiberPlans[2].color
                      : "#30B151"
                  }
                  setSelectedPlan={setSelectedPlan}
                  setOpen={setOpen}
                  name={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]
                      ?.promotionalName
                  }
                />
              )}
              {offer?.offerTemplate?.bulletPoints && (
                <div
                  style={{
                    marginTop: "2rem",
                    padding: "0rem",
                  }}
                  className=" offer_services_container"
                >
                  <div
                    className="min-w-[350px] text-black inner_container"
                    style={{
                      border: "4px solid #483092",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1rem",
                      gap: "2rem",
                    }}
                  >
                    <h1 className="text-[#483092]">What You Get</h1>
                    <ul className="flex flex-col gap-4">
                      {offer?.offerTemplate?.bulletPoints
                        .split("\n")
                        .map((bulletPoint, index) => (
                          <li
                            className="flex items-center justify-start gap-4"
                            key={index}
                          >
                            <FaCheckCircle color={"#3AB54A"} size={25} />
                            <p className="text-black font-bold">
                              {bulletPoint}
                            </p>
                          </li>
                        ))}
                    </ul>
                    <img
                      style={{ width: "250px" }}
                      src={"data:image/png;base64," + offer?.fiberCompany?.logo}
                      alt="logo"
                      className="logo"
                    />
                    <p className="max-w-sm flex flex-wrap text-xl font-semibold p-10">
                      *Price includes internet service and autopay/e-bill
                      discount. Excludes equipment, taxes, surcharges, and fees.
                      Available in select areas.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* {
                        offer?.offerTemplate && offer?.fiberCompanyId !== 10 &&
                        <div style={{
                            marginTop: "-8rem"
                        }} className=' banner' id="plan1">
                            <img style={{ width: "100%", height: "auto" }} src="/images/offer/pointbroadband/family.png" alt='banner' className='banner_image' />
                            <div className='flex justify-center items-center' style={{
                                backgroundColor: "#F47920",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",

                                // borderRadius:"20px"
                            }}>
                                <PointPlan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[1].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[1].promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[1].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div>
                        </div>
                    } */}

          <div
            style={{
              marginTop: "-6rem",
            }}
            className="banner"
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src="/images/offer/astound/smiling_customer.png"
              alt="banner"
              className="banner_image"
            />
            <div
              style={{
                marginTop: "-4rem",
                width: "100%",
                backgroundColor: "white",
                padding: "2rem 0rem",
              }}
            >
              <button
                style={{
                  backgroundColor: "#483092",
                }}
                onClick={() => setOpenConsultation(true)}
                className=" schedule_button"
              >
                Schedule A<br />
                Consultation
              </button>
              <h1 className="customer_service">
                Great Customer
                <br />
                Service is Built In.
              </h1>
              <p className="customer_service_p font-semibold">
                We understand the importance
                <br /> of the services we provide to <br />
                your home – that’s why we
                <br />
                treat you like family.
              </p>
            </div>
          </div>
          {/* <div className=' banner'> */}
          {/* <div className='flex justify-center items-center flex-col p-4 gap-4' style={{ marginTop: "-4rem", width: "100%", backgroundColor: "black", padding: "2rem 0rem" }}>
                            <p className='text-5xl font-extrabold text-[#F47920]'>GIVE $100</p>
                            <p className='text-5xl font-extrabold'>GET $100</p>
                            <p className='text-lg text-center'>Give your friends a $100 gift card when they use your unique link to sign up for Point Broadband service online, and you'll get a $100 gift card*, too, after their service is installed.</p>
                            <p className="text-3xl text-center font-bold">GET $100 FOR EVERY<br />FRIEND YOU REFER</p>
                        </div> */}

          {/* </div> */}
          <div
            style={
              {
                // marginTop: "-8rem"
              }
            }
            className="banner"
          >
            {/* <button
              onClick={() => {
                // createSharedOffer()
                setOpenShareModal(true);
              }}
              style={{ backgroundColor: "#F47920" }}
              className="schedule_button"
            >
              Share Offer
            </button> */}
            <img
              style={{ marginTop: "-3rem", width: "100%", height: "auto" }}
              src="/images/offer/astound/man_working.png"
              alt="banner"
              className="banner_image"
            />
          </div>
          <div
            style={{
              marginTop: "-14rem",
              border: "4px solid #3AB54A",
            }}
            className=" offer_services_container"
          >
            <div className="min-w-[350px] text-black inner_container">
              <h1>
                FIBER INTERNET
                <br />
                PROVIDER & TV
              </h1>
              <div className="services">
                <IoWifi color="#483092" size={100} />
                <h3>Internet</h3>
                <p
                  style={{
                    color: "#483092",
                  }}
                >
                  See Plans
                </p>
              </div>
              <div className="services">
                <IoMdTv color="#483092" size={100} />
                <h3>TV</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Start Streaming now
                </p>
              </div>
              <div className="services">
                <FaPhone color="#483092" size={100} />
                <h3>Voice</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Talk More. Pay Less
                </p>
              </div>
            </div>
          </div>
          <div className="banner">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                borderTopLeftRadius: "0px",
                marginTop: "2rem",
                borderTopRightRadius: "0px",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="quick_h1">Quick Links</h1>
              <img
                style={{ margin: "1rem 0rem" }}
                src={`/images/offer/astound/astound_phone.png`}
                alt="astound"
                className="company_logo"
              />
              <h3 className="quick_h3">Contact</h3>
              <p className="quick_p">Lightning Support</p>
              <p
                onClick={() => {
                  let contactButton = new ContactButtons();
                  contactButton.call("+12818689760");
                }}
                className="quick_h3 sm:quick_h1 cursor-pointer underline"
              >
                +1 281-868-9760
              </p>
              <p className="quick_p">Austin, TX, USA</p>
              <img
                style={{ margin: "1rem 0rem" }}
                src={`/images/offer/astound/logo.png`}
                alt="fastwyre"
                className="company_logo"
              />
              <img
                style={{ margin: "1rem 0rem" }}
                src="/images/offer/logo.png"
                alt="lightning"
                className="company_logo"
              />
              <p className="quick_copy">
                Copyright Lightning OS 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { MapContext } from "../../..";
import UserApi from "../../../../../../API/UsersApi";
import FccDataDetails from "./FccDataDetails";
import debounce from "lodash.debounce";
import Swal from "sweetalert2";

const FccDataSearchFilters = ({
  fccDataGeoType,
  setFccDataGeoType,
  fccDataTecType,
  setFccDataTecType,
  fccDataProviders,
  setFccDataProviders,
  fccDataPhyTecType,
  setFccDataPhyTecType,
  fccData,
  setFccData,
  setFccSearchType,
}) => {
  const map = useContext(MapContext);
  const [activeTab, setActiveTab] = useState("provider");
  const [allProviders, setAllProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchProviders, setSearchProviders] = useState([]);

  const [allStates, setAllStates] = useState([]); // For state dropdown
  const [selectedState, setSelectedState] = useState(null); // Selected state
  const [geoEntitiesOptions, setGeoEntitiesOptions] = useState([]); // Geo entities (counties, etc.)
  const [selectedGeoEntity, setSelectedGeoEntity] = useState(null); // Selected geo entity
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [isLoadingGeoEntities, setIsLoadingGeoEntities] = useState(false);
  const [searchProvider, setSearchProvider] = useState(null);
  const PAGE_SIZE = 10;

  const geoTypeOptions = ["STATE", "COUNTY", "CBSA", "Census Place"].map(
    (type) => ({
      value: type.trim().toUpperCase().replace(/\s+/g, ""),
      label: type,
    })
  );

  const techTypeOptions = ["FIXED"].map((type) => ({
    value: type,
    label: type,
  }));

  const techPhysicalTypeOptions = [
    "Copper",
    "Cable",
    "Fiber to the Premises",
    "GSO Satellite",
    "NGSO Satellite",
    "Unlicensed Fixed Wireless",
    "Licensed Fixed Wireless",
    "LBR Fixed Wireless",
    "Other",
  ].map((type) => ({
    value: type,
    label: type,
  }));

  // Initialize undefined props with default values
  const initializedGeoType =
    fccDataGeoType || geoTypeOptions.map((opt) => opt.value);

  // Fetch FCC Providers data on component mount
  useEffect(() => {
    const fetchFccProviders = async () => {
      try {
        setIsLoading(true);
        if (allProviders.length > 0) {
          // Skip fetching if data is already available
          console.log(allProviders.length);
          setFilteredProviders(allProviders.slice(0, PAGE_SIZE)); // Show first page
        } else {
          const res = await UserApi.GetFccProvidersDataAsync();
          if (res) {
            const providerOptions = res.map((provider) => ({
              value: provider.providerId,
              label: provider.name.replace(/"/g, ""),
            }));
            // Cache all providers
            setAllProviders(providerOptions);
            setFilteredProviders(providerOptions.slice(0, PAGE_SIZE)); // Show first page
          } else {
            console.warn("FCC Providers Data request failed:", res);
          }
        }
      } catch (error) {
        console.error("Error fetching FCC Providers data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFccProviders();
  }, []);

  // Fetch all states when geoType is COUNTY, CBSA, or Census Place
  useEffect(() => {
    const fetchStates = async () => {
      if (
        activeTab === "geography" &&
        ["COUNTY", "CBSA", "CENSUSPLACE"].includes(fccDataGeoType)
      ) {
        try {
          setIsLoadingStates(true);
          const res = await UserApi.GetGeoEntities({ type: "STATE" });
          const options = res.map((entity) => ({
            value: entity.id,
            label: entity.name,
          }));
          setAllStates(options);
        } catch (error) {
          console.error("Error fetching states:", error);
        } finally {
          setIsLoadingStates(false);
        }
      }
    };

    fetchStates();
  }, [activeTab, fccDataGeoType]);

  // Fetch geographic entities when geoType or selectedState changes
  useEffect(() => {
    const fetchGeoEntities = async () => {
      if (activeTab === "geography" && fccDataGeoType) {
        try {
          setIsLoadingGeoEntities(true);
          const req = {
            type: fccDataGeoType,
            id: selectedState === null ? null : selectedState.value,
            // ...(selectedState && { parentGeoId: selectedState.value })
          };

          const res = await UserApi.GetGeoEntities(req);
          const options = res.map((entity) => ({
            value: entity.id,
            label: entity.name,
          }));
          setGeoEntitiesOptions(options);
        } catch (error) {
          console.error("Error fetching geographic entities:", error);
        } finally {
          setIsLoadingGeoEntities(false);
        }
      }
    };

    fetchGeoEntities();
  }, [activeTab, fccDataGeoType, selectedState]);

  // Reset selected state and geo entity when geoType changes
  useEffect(() => {
    setSelectedState(null);
    setSelectedGeoEntity(null);
  }, [fccDataGeoType]);

  // Load more providers for pagination
  const loadMore = () => {
    const nextPage = page + 1;
    const nextProviders = allProviders.slice(
      (nextPage - 1) * PAGE_SIZE,
      nextPage * PAGE_SIZE
    );
    setFilteredProviders((prev) => [...prev, ...nextProviders]);
    setPage(nextPage);
  };

  // const handleSearch = debounce((inputValue) => {
  //   if (inputValue.length === 0) return inputValue;

  //   setIsLoading(true);
  //   UserApi.GetFccProvidersDataFCCSearchAsync(inputValue)
  //     .then((res) => {
  //       if (res) {
  //         const providerOptions = res.map((provider) => ({
  //           value: provider.providerId,
  //           label: provider.alternative.replace(/"/g, ""),
  //         }));
  //         setSearchProviders(providerOptions);
  //         setFilteredProviders(providerOptions.slice(0, PAGE_SIZE));
  //         setPage(1);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching FCC Providers data:", error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });

  //   return inputValue;
  // }, 400);
  useEffect(() => {
    let intervalId = null
    if (searchProvider) {
      intervalId = setTimeout(() => {
        setIsLoading(true);
    UserApi.GetFccProvidersDataFCCSearchAsync(searchProvider)
      .then((res) => {
        if (res && Array.isArray(res)) {  // Added null & array check
          const providerOptions = res.map((provider) => ({
            value: provider.providerId,
            label: provider.alternative !== null ? provider.alternative.replace(/"/g, "") : provider.name.replace(/"/g, ""),
          }));
          setSearchProviders(providerOptions);
          setFilteredProviders(providerOptions.slice(0, PAGE_SIZE));
          setPage(1);
        } else {
          console.warn("Unexpected response format:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching FCC Providers data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      }, 2000)
    }

    return () => {
      if(intervalId)
      clearInterval(intervalId)
    }

  }, [searchProvider]);


  const handleSearchButtonClick = async () => {
    if (!isSearchButtonClicked) {

      if(activeTab === "provider"){
        if (!fccDataProviders ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select a provider",
          });
          return;
        }


      }

      if (activeTab === "geography") {
        if (!fccDataGeoType) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select a geography type",
          });
          return;
        }
        if (selectedState==null && !selectedGeoEntity) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select a state",
          });
          return;
        }

        if (
          ["COUNTY", "CBSA", "CENSUSPLACE"].includes(fccDataGeoType) &&
          selectedGeoEntity == null
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select a geography entity",
          });
          return;
        }


        
      }
      
      if(!fccDataPhyTecType || fccDataPhyTecType.length === 0){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select a Physical Technology Type",
        });
        return;
      }

      if(!fccDataTecType || fccDataTecType.length === 0){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select a Technology Type",
        });
        return;
      }

      setIsSearchButtonClicked(true);
      try {
        const req = {
          geoType: fccDataGeoType || "STATE",
          tecType: fccDataTecType || [],
          phyTecType: fccDataPhyTecType || [],
          geoTypeAreadId:
            selectedGeoEntity === null ? null : selectedGeoEntity.value,
          ...(activeTab === "provider" && {
            providers: fccDataProviders || [],
          }),
          ...(activeTab === "geography" && {
            geoCode: selectedGeoEntity?.value || "",
          }),
        };
        console.log("Req", req);

        const result = await UserApi.GetFccDataWithPhysicalTechnologyAsync(req);
        setFccData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsSearchButtonClicked(false);
      }
    }
  };

  const getGeoEntityLabel = (geoType) => {
    switch (geoType) {
      case "STATE":
        return "State";
      case "COUNTY":
        return "County";
      case "CBSA":
        return "CBSA";
      case "CENSUSPLACE":
        return "Census Place";
      default:
        return "Geographic Entity";
    }
  };

  return (
    <>
      <div className="SearchFiltersDiv">
        {isSearchButtonClicked && (
          <div className="LoadingOverlay">
            <div className="LoadingContent">
              <div className="Spinner" />
              <p className="LoadingText">Fetching Data...</p>
            </div>
          </div>
        )}
        <div className="TitleDiv">
          <h3 className="text-4xl font-bold">FCC Filters</h3>
        </div>

        {/* Tabs */}
        <div
          className="tabs"
          style={{ marginBottom: "1rem", display: "flex", gap: "0.5rem" }}
        >
          <button
            onClick={() => {
              if (activeTab !== "provider") {
                console.log("Resetting FCC Data");
                setFccData(null);
              }
              setActiveTab("provider");
              setFccSearchType("provider");
            }}
            className={`tab-button ${activeTab === "provider" ? "active" : ""}`}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: activeTab === "provider" ? "#007bff" : "#e9ecef",
              color: activeTab === "provider" ? "white" : "black",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Search by Provider
          </button>
          <button
            onClick={() => {
              if (activeTab !== "geography") {
                console.log("Resetting FCC Data");
                setFccData(null);
              }
              setActiveTab("geography");
              setFccSearchType("geography");
            }}
            className={`tab-button ${
              activeTab === "geography" ? "active" : ""
            }`}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor:
                activeTab === "geography" ? "#007bff" : "#e9ecef",
              color: activeTab === "geography" ? "white" : "black",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Search by Geography
          </button>
        </div>

        <div className="search_filter_container w-full">
          <div className="InputsDiv">
            {/* Geography Type Selector */}
            <div className="InputDiv">
              <label>Geography Type</label>
              <Select
                value={geoTypeOptions.find(
                  (option) => option.value === fccDataGeoType
                )}
                onChange={(selectedOption) =>
                  setFccDataGeoType(selectedOption.value)
                }
                options={geoTypeOptions}
                placeholder="Select Geo Type"
              />
            </div>

            {activeTab === "provider" ? (
              /* Provider Search Filters */
              <div className="InputDiv">
                <label htmlFor="fccProviders">FCC Providers</label>
                <Select
                  id="fccProviders"
                  aria-label="FCC Providers"
                  isSearchable
                  isLoading={isLoading}
                  value={
                    allProviders.find(
                      (option) => fccDataProviders?.toString() === option.value?.toString()
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    setFccDataProviders(selectedOption?.value || null)
                  }

                  options={filteredProviders}
                  placeholder="Search and select a Provider"
                  onInputChange={(inputValue) => {
                    if (inputValue.trim()) setSearchProvider(inputValue)// Prevent empty string calls
                  }}
                  onMenuScrollToBottom={loadMore}
                  noOptionsMessage={() => "No providers found"}
                  
                />
              </div>

            ) : (
              /* Geography Search Filters */
              <>
                {/* State Dropdown for COUNTY, CBSA, CENSUSPLACE */}
                {["COUNTY", "CBSA", "CENSUSPLACE"].includes(fccDataGeoType) && (
                  <div className="InputDiv">
                    <label>State</label>
                    <Select
                      isLoading={isLoadingStates}
                      options={allStates}
                      value={selectedState}
                      onChange={setSelectedState}
                      placeholder="Select State"
                      isDisabled={!fccDataGeoType || isLoadingStates}
                    />
                  </div>
                )}
                {/* Main Geo Entity Dropdown */}
                <div className="InputDiv">
                  <label>{getGeoEntityLabel(fccDataGeoType)}</label>
                  <Select
                    isLoading={isLoadingGeoEntities}
                    options={geoEntitiesOptions}
                    value={selectedGeoEntity}
                    onChange={setSelectedGeoEntity}
                    placeholder={`Select ${getGeoEntityLabel(fccDataGeoType)}`}
                    isDisabled={
                      !fccDataGeoType ||
                      isLoadingGeoEntities ||
                      (["COUNTY", "CBSA", "CENSUSPLACE"].includes(
                        fccDataGeoType
                      ) &&
                        !selectedState)
                    }
                  />
                </div>
              </>
            )}

            {/* Common Technology Filters */}
            <div className="InputDiv">
              <label>Technology Type</label>
              <Select
                isMulti
                onChange={(selectedOptions) =>
                  setFccDataTecType(selectedOptions.map((opt) => opt.value))
                }
                options={techTypeOptions}
                placeholder="Select Technology Type"
              />
            </div>

            {/* Common Physical Technology Filter */}
            <div className="InputDiv">
              <label>Physical Technology Type</label>
              <Select
                isMulti
                onChange={(selectedOptions) =>
                  setFccDataPhyTecType(selectedOptions.map((opt) => opt.value))
                }
                options={techPhysicalTypeOptions}
                placeholder="Select Physical Technology Type"
              />
            </div>
          </div>

          <button
            onClick={handleSearchButtonClick}
            disabled={isSearchButtonClicked}
            className="SearchButton"
          >
            Search
          </button>
        </div>
      </div>
      <FccDataDetails fccData={fccData} />
    </>
  );
};

export default FccDataSearchFilters;

import React from "react";

function Blog({ post }) {
  return (
    <article className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src="https://picsum.photos/400/200"
        alt={`Cover for ${post.title}`}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-2">
          <a
            href={`/blog/${post.id}`}
            className="text-blue-600 transition-colors duration-200"
          >
            {post.title}
          </a>
        </h2>
        {/* <p className="text-gray-600 mb-4">{post.description}</p> */}
        <p className="text-gray-600 mb-4">
         dkof Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Dignissimos
        </p>
        <div className="text-sm text-gray-500">
          Published on {new Date(post.date).toLocaleDateString()}
        </div>
      </div>
    </article>
  );
}

export default Blog;

import Dashboard from "./../Views/Shared/Dashboard";
import EditAccountDetails from "./../Views/Shared/EditAccountDetails";
import UserCommission from "./../Views/Shared/UserCommission";
import MasterSheet from "./../Views/Shared/MasterSheet";
import IssuesPage from "../Views/SuperAdmin/ManageIssueTypes";
import CancelDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueCancelDetails";
import RescheduleDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueRescheduleDetails";
const CustomerServiceRepRoutes = [
    {
        name: "Dashboard",
        key: "Dashboard",
        route: "/",
        component: <Dashboard/>
    },
    {
        name: "Manage Issue Types",
        key: "ManageIssueTypes",
        route: "/manage-issue-types",
        component: <IssuesPage />,
    },
    {
        name: "Issue Cancel Details",
        key: "IssueCancelDetails",
        route: "/issue-cancel-details/:id",
        component: <CancelDetailsPage />,
    },
    {
        name: "Issue Reschedule Details",
        key: "IssueRescheduleDetails",
        route: "/issue-reschedule-details/:id",
        component: <RescheduleDetailsPage />,
    },
    // {
    //     name: "Edit Account Details",
    //     key: "EditAccountDetails",
    //     route: "/edit-account-details",
    //     component: <EditAccountDetails />
    // },
    // {
    //     name:"My Commissions",
    //     key:"MyCommissions",
    //     route:"/my-commissions",
    //     component:<UserCommission />
    // },
    {
        name:"Work Sheet",
        key:"WorkSheet",
        route:"/master-sheet",
        component: <MasterSheet />
    }
]

export default CustomerServiceRepRoutes;
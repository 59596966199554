import { HiOutlineDocumentText } from "react-icons/hi2";
import { MdOutlinePayments, MdOutlineReportProblem } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";

const CustomerServiceRepMenuItems = [
    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color, size = 40) => <RxDashboard size={size} color={color} />
    },
    {
        name: "Manage Issue Types",
        key: "manageIssueTypes",
        route: "/manage-issue-types",
        icon: (color, size = 40) => <MdOutlineReportProblem size={size} color={color} />
      },
    {
        name: "Work Sheets",
        key: "MasterSheet",
        route: "/master-sheet",
        icon: (color, size = 40) => <HiOutlineDocumentText size={size} color={color} />,
    },
    // {
    //     name: "My Commissions",
    //     key: "MyCommissions",
    //     route: "/my-commissions",
    //     icon: (color, size = 40) => <MdOutlinePayments size={size} color={color} />,
    //     disabled: true
    // },
]

export default CustomerServiceRepMenuItems;
const FilteredDataList = ({ data, onSelect }) => {
  if (data?.length === 0) return null;

  return (
    <div className="absolute left-[0%] w-full bg-white max-h-60 overflow-y-auto top-16 border rounded-lg shadow-lg z-10">
      <ul>
        {data?.map((item) => (
          <li
            key={item.id}
            className="p-4 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              onSelect(item);
            }}
          >
            {`${item.name}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilteredDataList;

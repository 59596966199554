class CreateTrainingModuleReq {
  constructor() {
    this.name = null;
    this.description = null;
    this.creatorId = null;
    this.files = [];
  }
}

export default CreateTrainingModuleReq;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "./RecordingHooks";

export const RecordingContext = createContext();

export const RecordingProvider = ({ children }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const [audioUrl, setAudioUrl] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [pitch, setPitch] = useState(null);
  const [reload, setReload] = useState(null);
  const [AudioRecording, setAudioRecording] = useState(null);

 

  useEffect(() => {
    if (AudioRecording) {

    
      // const {
      //   startRecording,
      //   pauseRecording,
      //   resumeRecording,
      //   stopRecording,
      //   clearRecording,
      // } = AudioRecording;
    }

  }, [AudioRecording]);

  const handleStartRecording = () => {
    console.log("Recording started");
    AudioRecording?.startRecording();
    setIsRecording(true);
    setIsPaused(false);
  };

  const handlePauseRecording = () => {
    AudioRecording?.pauseRecording();
    setIsPaused(true);
  };

  const handleResumeRecording = () => {
    AudioRecording?.resumeRecording();
    setIsPaused(false);
  };

  const handleStopRecording = () => {
    console.log("Recording stopped");
    AudioRecording?.stopRecording();
    setIsRecording(false);
  };

  return (
    <RecordingContext.Provider
      value={{
        isRecording,
        isPaused,
        duration,
        audioUrl,
        AudioRecording,
        setIsRecording,
        setAudioRecording,
        handleStartRecording,
        handlePauseRecording,
        handleResumeRecording,
        handleStopRecording,
        // AudioRecording?.clearRecording,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
};

export const useRecording = () => useContext(RecordingContext);

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Select from "react-select";
import QuickDate from "../../../../../components/QuickDate";
import "./candidate-filters.scss";
import { MapContext } from "../..";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import IndustryFilter from "../Filters/IndustryFilter";
import CompanyFilter from "../Filters/CompanyFilter";
import PositionTitleFilter from "../Filters/PositionTitleFilter";
import SelectionMenu from "../../../../SuperAdmin/Tags/components/SelectionMenu";

const CandidateSearchFilter = ({
  candidateName,
  setCandidateName,
  candidateEmail,
  setCandidateEmail,
  candidateLeadType,
  setCandidateLeadType,
  candidateStatus,
  setCandidateStatus,
  candidateIndustries,
  setCandidateIndustries,
  candidateCompanies,
  setCandidateCompanies,
  candidatePositionTitles,
  setCandidatePositionTitles,
  candidateCreatedFrom,
  setCandidateCreatedFrom,
  candidateCreatedTo,
  setCandidateCreatedTo,
  candidateAppointmentFrom,
  setCandidateAppointmentFrom,
  candidateAppointmentTo,
  setCandidateAppointmentTo,
  candidateLastUpdatedFrom,
  setCandidateLastUpdatedFrom,
  candidateLastUpdatedTo,
  setCandidateLastUpdatedTo,
  candidateMapIcons,
  setCandidateMapIcons,
  candidateAssignedQuickDate,
  setCandidateAssignedQuickDate,
  candidateSelectedTags,
  setCandidateSelectedTags,
  tags,
}) => {
  const map = React.useContext(MapContext);

  console.log({ map });
  const initialRender = React.useRef(true);

  const [dateRangeType, setDateRangeType] = useState("created");
  const [industryOptions, setIndustryOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [positionTitleOptions, setPositionTitleOptions] = useState([]);
  const [leadTypeOptions, setLeadTypeOptions] = useState([
    { value: "Recruit", label: "Recruit" },
    { value: "Company", label: "Company" },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "New", label: "New" },
    { value: "Invitation Sent", label: "Job Ad Sent" },
    { value: "Applied", label: "Applied" },
    // { value: 'Prospect', label: 'Contacted' },
    { value: "1st Interview Sent", label: "Interview Link Sent" },
    { value: "1st Interview Scheduled", label: "Interview Scheduled" },
    { value: "2nd Interview Sent", label: "2nd Interview Link Sent" },
    { value: "2nd Interview Scheduled", label: "2nd Interview Scheduled" },
    { value: "Rejected", label: "Rejected" },
    { value: "Hired", label: "Hired" },
    // { value: 'ShortListed', label: 'ShortList' },
    // { value: 'Lost', label: 'Lost' },
  ]);

  const dateRangeOptions = [
    { value: "created", label: "Created Date" },
    { value: "appointment", label: "Appointment Date" },
    { value: "lastUpdated", label: "Last Updated Date" },
  ];

  useEffect(() => {
    map.getCandidates();
  }, [
    candidateName,
    candidateEmail,
    candidateLeadType,
    candidateStatus,
    candidateIndustries,
    candidateCompanies,
    candidatePositionTitles,
    candidateCreatedFrom,
    candidateCreatedTo,
    candidateAppointmentFrom,
    candidateAppointmentTo,
    candidateLastUpdatedFrom,
    candidateLastUpdatedTo,
    candidateMapIcons,
    dateRangeType,
    candidateSelectedTags,
  ]);

  const handleSetFromDate = (from) => {
    console.log("Setting From Date:", dateRangeType, from);

    if (dateRangeType === "created") {
      setCandidateCreatedFrom(from);
    } else if (dateRangeType === "appointment") {
      setCandidateAppointmentFrom(from);
    } else if (dateRangeType === "lastUpdated") {
      setCandidateLastUpdatedFrom(from);
    }
  };

  // Function to handle setting the to date
  const handleSetToDate = (to) => {
    console.log("Setting To Date:", dateRangeType, to);

    if (dateRangeType === "created") {
      setCandidateCreatedTo(to);
    } else if (dateRangeType === "appointment") {
      setCandidateAppointmentTo(to);
    } else if (dateRangeType === "lastUpdated") {
      setCandidateLastUpdatedTo(to);
    }
  };

  const getDateRangeValues = () => {
    switch (dateRangeType) {
      case "created":
        return { from: candidateCreatedFrom, to: candidateCreatedTo };
      case "appointment":
        return { from: candidateAppointmentFrom, to: candidateAppointmentTo };
      case "lastUpdated":
        return { from: candidateLastUpdatedFrom, to: candidateLastUpdatedTo };
    }
  };

  const handleDateRangeTypeChange = (newType) => {
    // Store the current `from` and `to` values
    const { from, to } = getDateRangeValues();
    console.log(from, to);

    //reset all to null

    setCandidateCreatedFrom(null);
    setCandidateCreatedTo(null);
    setCandidateAppointmentFrom(null);
    setCandidateAppointmentTo(null);
    setCandidateLastUpdatedFrom(null);
    setCandidateLastUpdatedTo(null);

    // Update the date values based on the new type
    if (newType === "created") {
      setCandidateCreatedFrom(from);
      setCandidateCreatedTo(to);
    } else if (newType === "appointment") {
      setCandidateAppointmentFrom(from);
      setCandidateAppointmentTo(to);
    } else if (newType === "lastUpdated") {
      setCandidateLastUpdatedFrom(from);
      setCandidateLastUpdatedTo(to);
    }

    // Update the date range type
    setDateRangeType(newType);
  };

  const showCompanyFilters = candidateLeadType?.filter(
    (type) => type.value === "Company"
  );

  return (
    <>
      <div className="SearchFiltersDiv">
        <div className="TitleDiv">
          <h3 className="text-4xl font-bold">Search Filters</h3>
        </div>

        <div
          style={{ marginBottom: "1rem" }}
          className="search_filter_container w-full"
        >
          <div className="InputsDiv">
            <div className="InputDiv">
              <label htmlFor="assignedConvertedToCustomer">Lead Type</label>
              <Select
                isMulti
                value={candidateLeadType}
                defaultValue={leadTypeOptions[0]}
                onChange={(options) => setCandidateLeadType(options)}
                options={leadTypeOptions}
                placeholder="Select Lead Type"
              />
            </div>
            <div className="InputDiv">
              <label>Status</label>
              <Select
                value={candidateStatus}
                onChange={(option) => setCandidateStatus(option)}
                options={statusOptions}
                placeholder="Select Status"
                isClearable
              />
            </div>

            {showCompanyFilters && (
              <>
                {/* Industries */}

                <IndustryFilter
                  candidateIndustries={candidateIndustries}
                  setCandidateIndustries={setCandidateIndustries}
                />

                {/* Companies */}

                <CompanyFilter
                  candidateCompanies={candidateCompanies}
                  setCandidateCompanies={setCandidateCompanies}
                />

                {/* Position Titles */}

                <PositionTitleFilter
                  candidatePositionTitles={candidatePositionTitles}
                  setCandidatePositionTitles={setCandidatePositionTitles}
                />
              </>
            )}

            <div className="InputDiv">
              <label>Date Range Type</label>
              <Select
                value={dateRangeOptions.find(
                  (option) => option.value === dateRangeType
                )}
                onChange={(option) => handleDateRangeTypeChange(option.value)}
                options={dateRangeOptions}
                placeholder="Select Date Range Type"
              />
            </div>

            <QuickDate
              label={`${
                dateRangeOptions.find(
                  (option) => option.value === dateRangeType
                ).label
              } Range`}
              selectQuickDate={candidateAssignedQuickDate}
              setQuickDateValue={setCandidateAssignedQuickDate}
              setFrom={handleSetFromDate}
              setTo={handleSetToDate}
              from={getDateRangeValues().from}
              to={getDateRangeValues().to}
              future={dateRangeType === "appointment"}
            />
            <div className="InputDiv">
              <label>Associated Tags</label>

              <SelectionMenu
                tags={tags}
                onChange={(selected) => setCandidateSelectedTags(selected)}
              />
            </div>

            <h1 className="lead_type_title">Lead Status</h1>
            <div className="select_deselect">
              <button
                className="select"
                onClick={() =>
                  setCandidateMapIcons(map.mapIcons.map((icon) => icon.id))
                }
              >
                Select All
              </button>
              <button
                className="deselect"
                onClick={() => setCandidateMapIcons([])}
              >
                Deselect All
              </button>
            </div>

            <div className="MapIconStats">
              {map.mapIcons
                ?.filter(
                  (icon) =>
                    icon.iconType === "Recruit" || icon.iconType === "Company"
                )
                .map((icon, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      const newMapIcons = candidateMapIcons?.includes(icon.id)
                        ? candidateMapIcons.filter((id) => id !== icon.id)
                        : [...candidateMapIcons, icon.id];
                      setCandidateMapIcons(newMapIcons);
                    }}
                    className={`MapIconStat ${
                      candidateMapIcons?.includes(icon.id) ? "active" : ""
                    }`}
                  >
                    <h3>
                      <img
                        src={`data:image/png;base64,${icon.image}`}
                        alt="Map Icon"
                      />
                    </h3>
                    <p>{icon.abbreviation}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSearchFilter;

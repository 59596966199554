import React from 'react'
import PublicLayout from '../../../Layouts/PublicLayout'
import './Opportunity.scss'
import banner from './images/opportunity_banner.jpg'
import MissionBox from './components/MissionBox'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ExpandedTab from './components/ExpandedTab'
import ReviewContainer from '../Home/components/ReviewContainer'
import { useParams } from 'react-router-dom'
const Index = () => {
    const {form} = useParams();
    const [expanded, setExpanded] = React.useState(form?true:false)
    const [expanded2, setExpanded2] = React.useState(false)
    return (
        <PublicLayout>
            <div className='opportunity_container'>
                <h1>Join Lightning OS In America's<br /> Fastest Growing Fiber Network!</h1>
                <div className='banner rounded-lg overflow-hidden'>
                    <img src={banner} alt='banner' />
                </div>
                <h1>Current Positions Available (2)</h1>
                <button onClick={() => setExpanded2(!expanded2)} className='expander_button'>join lightning os team as a Fiber Manager! {expanded2 ? <FaChevronUp color='white' size={20} /> : <FaChevronDown color='white' size={20} />}</button>
                {
                    expanded2 && <ExpandedTab
                    position='Fiber Manager'
                    requirements={[
                        'Manage a team of Fiber Consultants to drive sales and revenue growth.',
                        'Develop and implement strategies to promote Lightning OS fiber internet solutions.',
                        'Manage Leads and ensure a high level of customer satisfaction.',
                        'Collaborate with the marketing team to develop effective sales and marketing strategies.'
                    ]}
                    jobDescription={`Join us as a Fiber Manager, where you'll lead a team of Fiber Consultants to drive sales and revenue growth. You'll develop and implement strategies to promote Lightning OS fiber internet solutions, manage leads, and ensure a high level of customer satisfaction. Collaborating with the marketing team, you'll develop effective sales and marketing strategies. This role offers an exciting opportunity to make an impact and grow with us. Apply now to join our dynamic team!`}
                    />
                }
                <button onClick={() => setExpanded(!expanded)} className='expander_button'>join lightning os team as a Fiber Consultant! {expanded ? <FaChevronUp color='white' size={20} /> : <FaChevronDown color='white' size={20} />}</button>
                {expanded && <ExpandedTab 
                position='Fiber Consultant'
                openForm={form}
                requirements={[
                    'Conduct field visits to promote Lightning OS fiber internet solutions.',
                    'Forge meaningful connections with potential customers and provide comprehensive product knowledge.',
                    'Collaborate closely with the sales team to exceed targets and drive sustainable revenue growth.'
                ]}
                jobDescription={`Join us as a Fiber Consultant, where you'll play a vital role in promoting Lightning OS fiber internet solutions through door-to-door visits, forging strong connections with potential customers. Collaborating closely with our sales team, you'll surpass targets and drive sustainable revenue growth. this role offers an exciting chance to make an impact and grow with us. Apply now to join our dynamic team!`}
                 />}
                <MissionBox />
                {/* <div style={{
                    display:"grid",
                    placeItems:"center",
                    width:"100vw"
                }}> */}
                <h1>Success Stories</h1>
                <ReviewContainer salesRep={true} />
                {/* </div> */}
            </div>
        </PublicLayout>
    )
}

export default Index

import React, { useEffect } from "react";
import { X } from "lucide-react";
import { set } from "ol/transform";

export default function EmailTemplate({
  selectedTemplate,
  setSelectedTemplate,
  enableEditing,
  files,
  handleFileUpload,
  handleDrop,
  toggleSelectAll,
  selectAllMedia,
  setFiles,
  isImage,
  body,
  setBody,
  subject,
  setSubject,
}) {
  const removeFile = (fileId) => {
    setFiles(files.filter((file) => file.id !== fileId));
  };


  const fetchBase64ForAttachment = async (attachment) => {
    const response = await fetch(attachment.url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };


  const changeFiles = async () => {
    const newFiles = [];

    for (let i = 0; i < selectedTemplate.attachments?.length; i++) {
      const attachment = selectedTemplate.attachments[i];
      const base64 = await fetchBase64ForAttachment(attachment);
      newFiles.push({
        id: attachment.id,
        name: attachment.name,
        url: base64,
        type: attachment.fileType,
        selected: true,
      });
    }

    setFiles(newFiles);

  }

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate.attachments) {
        changeFiles()
      }

    }
  }, [selectedTemplate])

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <label className="text-sm text-gray-500">Subject</label>
        <div className="relative w-full">
          <textarea
            className="w-full min-h-[20px] p-3 border rounded-lg focus:outline-none disabled:bg-gray-50 disabled:text-gray-500"
            placeholder={"Get Blazing Fast Internet for $69.99/Month!"}
            value={selectedTemplate?.subject ?? subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setSelectedTemplate({ ...selectedTemplate, subject: e.target.value })
            }}
            disabled={!enableEditing}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm text-gray-500">Description</label>
        <textarea
          className="w-full min-h-[120px] p-3 border rounded-lg focus:outline-none disabled:bg-gray-50 disabled:text-gray-500"
          placeholder="Hi [First Name], don't miss our exclusive 2 GIG plan at $69.99/month! Unlimited data, no contracts, and free installation. Call me at [Your Number] or reply now to sign up!"
          value={selectedTemplate?.body ?? body}
          onChange={(e) => {
            setBody(e.target.value);
            setSelectedTemplate({ ...selectedTemplate, body: e.target.value })
          }}
          disabled={!enableEditing}
        />
      </div>

      <div className="space-y-4">
        <p className="text-sm text-gray-500">Choose media to send via Email</p>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="selectAll"
            checked={selectAllMedia}
            onChange={toggleSelectAll}
            className="w-4 h-4 rounded border-gray-300"
          />
          <label htmlFor="selectAll" className="text-sm font-medium">
            Select All / Deselect All
          </label>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
          {files.map((file) => (
            <div key={file.id} className="relative">
              {isImage(file.type) ? (
                <img
                  src={file.url}
                  alt={file.name}
                  className="w-full h-auto rounded-lg"
                />
              ) : (
                <div className="w-full h-full rounded-lg bg-gray-100 p-2 flex items-center justify-center">
                  <p className="text-sm text-gray-700">{file.name}</p>
                </div>
              )}
              <input
                type="checkbox"
                checked={selectAllMedia || file.selected}
                onChange={() => {
                  const newFiles = files.map((f) =>
                    f.id === file.id ? { ...f, selected: !f.selected } : f,
                  );
                  setFiles(newFiles);
                }}
                className="absolute top-2 left-2 w-4 h-4 rounded border-gray-300"
              />
              <button
                onClick={() => removeFile(file.id)}
                className="absolute top-2 right-2 text-gray-400 hover:text-red-500 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          ))}
          <div className="flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4 min-h-[150px]">
            <div className="relative text-center w-full h-full">
              <p className="text-blue-600 font-medium">Select Media</p>
              <p className="text-sm text-gray-500">or drag and drop</p>
              <input
                type="file"
                accept="*/*"
                onChange={handleFileUpload}
                className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import SuperAdminMenuItems from "../MenuItems/SuperAdminMenuItems";
import ManageRefferalCustomers from "../Views/Shared/AffiliateCode/ManageRefferalCustomers";
import Dashboard from "../Views/Shared/Dashboard";
import EditAccountDetails from "../Views/Shared/EditAccountDetails";
import ManageSellingCards from "../Views/Shared/ManageSellingCards";
import CreateFiberCompany from "../Views/SuperAdmin/CreateFiberCompany";
import CreateFiberPlan from "../Views/SuperAdmin/CreateFiberPlan";
// import CreateSellingCard from "../Views/SuperAdmin/CreateSellingCard";
import DigitalBusinessCenter from "../Views/Reps/DigitalBusinessCenter";
import LeaderBoard from "../Views/Reps/LeaderBoard";
import ChatList from "../Views/Shared/ChatList";
import ChatNew from "../Views/Shared/ChatNew";
import ContactCenter from "../Views/Shared/ContactCenter";
import ContactCenterRecruitLeads from "../Views/Shared/ContactCenterRecruitLeads";

import UserCommission from "../Views/Shared/UserCommission";
import AvailabilityCheckDetail from "../Views/SuperAdmin/AvailabilityCheckDetail";
import CommissionPlanDetails from "../Views/SuperAdmin/CommissionPlanDetails";
import CreateComissionPlan from "../Views/SuperAdmin/CreateComissionPlan";
import CreateUser from "../Views/SuperAdmin/CreateUser";
import CustomerDetails from "../Views/SuperAdmin/CustomerDetails";
import EditFiberCompany from "../Views/SuperAdmin/EditFiberCompany";
import EditFiberPlan from "../Views/SuperAdmin/EditFiberPlan";
import JobApplicationDetails from "../Views/SuperAdmin/JobApplicationDetails";
import ManageAccountingEntries from "../Views/SuperAdmin/ManageAccountingEntries";
import ManageCheckAvailability from "../Views/SuperAdmin/ManageCheckAvailability";
import ManageCommissionPlans from "../Views/SuperAdmin/ManageCommissionPlans";
import ManageCommissionTracker from "../Views/SuperAdmin/ManageCommissionTracker";
import ManageCustomerFeedbacks from "../Views/SuperAdmin/ManageCustomerFeedbacks";
import ManageCustomers from "../Views/SuperAdmin/ManageCustomers";
import ManageDebtSheet from "../Views/SuperAdmin/ManageDebtSheet";
import ManageFiberCompanies from "../Views/SuperAdmin/ManageFiberCompanies";
import IssuesPage from "../Views/SuperAdmin/ManageIssueTypes";
import ManageJobApplications from "../Views/SuperAdmin/ManageJobApplications";
import ManageLeads from "../Views/SuperAdmin/ManageLeads";
import AddUserToOrg from "../Views/SuperAdmin/ManageMembers";
import SearchUser from "../Views/SuperAdmin/SearchUser";
import MapPage from "../Views/TeamAdmin/MapPage";
import CustomerSupport from "./../Views/Shared/CustomerSupport";
import SellingCardTutorial from "./../Views/Shared/SellingCardTutorial";
import CreateShopItem from "./../Views/SuperAdmin/CreateShopItem";
import CustomMerch from "./../Views/SuperAdmin/CustomMerch";
import ManageShopItems from "./../Views/SuperAdmin/ManageShopItems";
import OrganizationsTreeView from "./../Views/SuperAdmin/OrganizationsTreeView";
import Profile from "./../Views/SuperAdmin/Profile";
import SellingCardDetails from "./../Views/SuperAdmin/SellingCardDetails";
import ShopItemDetails from "./../Views/SuperAdmin/ShopItemDetails";
import UserDetails from "./../Views/SuperAdmin/UserDetails";
// import IssueDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueCancelDetails";
import CancelDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueCancelDetails";
import RescheduleDetailsPage from "../Views/SuperAdmin/ManageIssueTypes/issueRescheduleDetails";
// import SellingCardDetailsNew from "../Views/Shared/ManageSellingCards/SellingCardDetails";
import CreateBugTicketForm from "../Views/Public/BugTicket";
import MyCalendar from "../Views/Shared/MyCalendar";
import SellingCardDetailsNew from "../Views/Shared/ManageSellingCards/SellingCardDetails";
import SalesGeneralReport from "../Views/Shared/SalesGeneralReport";
import SalesReport from "../Views/Shared/SalesReport";
import ManageBugTickets from "../Views/SuperAdmin/ManageBugTickets";
import OffersReport from "../Views/Shared/OffersReport";
import SetReferalCompany from "../Views/Shared/SetReferalCompany";
import ManageAnnouncements from "../Views/SuperAdmin/ManageAnnouncements";
import Announcements from "../Views/Shared/Announcements";
import CustomerFeedback from "./../Views/Reps/CustomerFeedback";
import ManageRecruitTemplates from "../Views/SuperAdmin/ManageRecruitTemplates";
import MasterSheet from "../Views/Shared/MasterSheet";
import ManageCompanyConsultation from "../Views/SuperAdmin/CompanyConsultations";
import CommissionTree from "./../Views/SuperAdmin/CommissionTree";
import ClawbackCustomer from "./../Views/SuperAdmin/ClawbackCustomer";
import ManageCustomFields from "./../Views/SuperAdmin/ManageCustomFields";
import BradingPage from "./../Views/SuperAdmin/BrandigPage/ManageBrands";
import ManageBrand from "./../Views/SuperAdmin/BrandigPage/ManageBrand";
import { components } from "react-select";
import ManageCommunicationTemplates from "./../Views/SuperAdmin/ManageCommunicationTemplates";
import PostEditor from "../Views/SuperAdmin/Blogs/components/editor/PostEditor.jsx";

import MappingFilter from "./../Views/SuperAdmin/MappingFilter/test/FileUploadWizard";
import TagsPage from "../Views/SuperAdmin/Tags";
import BlogsPage from "../Views/SuperAdmin/Blogs";

import BusinessCenter from "./../Views/Shared/BusinessCenter";
import ManagePitches from "../Views/Shared/ManagePitches";
import ContactCenterWrapper from "../Views/Shared/ContactCenterWrapper";
import NewOffer from "../Views/Public/NewOffer";
import EmailReport from "../Views/Shared/EmailReport";

const SuperAdminRoutes = [
  {
    name: "Dashboard",
    key: "Dashboard",
    route: "/",
    component: <Dashboard />,
  },
  {
    name: "Customer Details",
    key: "CustomerDetails",
    route: "/CustomerDetails/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Map",
    key: "FiberHouses",
    route: "/map",
    component: <MapPage />,
  },
  {
    name: "Contact Center",
    key: "ContactCenter",
    route: "/contact-center",
    component: <ContactCenterWrapper />,
  },
  {
    name: "Contact Center Recruit Leads",
    key: "ContactCenterRecruitLeads",
    route: "/contact-center-recruit-leads",
    component: <ContactCenterWrapper />,
  },
  {
    name: "Dashboard",
    key: "Dashboard",
    route: "*",
    component: <Dashboard />,
  },
  {
    name: "Search Users",
    key: "SearchUsers",
    route: "/searchUser",
    component: <SearchUser />,
  },
  {
    name: "Add User",
    key: "adduser",
    route: "/addUser",
    component: <CreateUser />,
  },
  {
    name: "Manage Organizations",
    key: "manageorg",
    route: "/organizationList",
    component: <OrganizationsTreeView />,
  },
  {
    name: "Add Users to Organization",
    key: "AddUserToOrg",
    route: "/organizationList/ManageMembers",
    component: <AddUserToOrg />,
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center",
    component: <BusinessCenter />,
  },
  {
    name: "Private Conversations",
    key: "privatechatpage",
    route: "/chats",
    component: <ChatList menuItems={SuperAdminMenuItems} />,
  },
  // {
  //   name: "Create Training Module",
  //   key: "createSellingCard",
  //   route: "/createSellingCard",
  //   component: <CreateSellingCard />
  // },
  {
    name: "Manage Training Modules",
    key: "manageSellingCard",
    route: "/manage-training-modules",
    component: <ManageSellingCards />,
  },
  {
    name: "Create Shop Items",
    key: "createShopItems",
    route: "/createShopItems",
    component: <CreateShopItem />,
  },
  {
    name: "Manage Shop Items",
    key: "manageShopItems",
    route: "/manageShopItems",
    component: <ManageShopItems />,
  },
  {
    name: "Shop Items Details",
    key: "shopItemsDetails",
    route: "/manageShopItems/details",
    component: <ShopItemDetails />,
  },
  {
    name: "Selling Card Details",
    key: "sellingCardDetails",
    route: "/manage-training-modules/details",
    component: <SellingCardDetails />,
  },
  {
    name: "Custom Merch",
    key: "custommerch",
    route: "/customMerch",
    component: <CustomMerch />,
  },
  {
    name: "Profile",
    key: "Profile",
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Tutorial",
    key: "Tutorial",
    route:
      "manage-training-modules/training-module-tutorial/:trainingModuleId/:trainingModuleName/:index",
    component: <SellingCardTutorial />,
  },
  {
    name: "User Details",
    key: "userDetails",
    route: "searchUser/userDetails",
    component: <UserDetails />,
  },
  {
    name: "Customer Support",
    key: "CustomerSupport",
    route: "/customer-support",
    component: <CustomerSupport routes={SuperAdminMenuItems} />,
  },
  {
    name: "Create Fiber Company",
    key: "createFiberCompany",
    route: "/createFiberCompany",
    component: <CreateFiberCompany />,
  },
  {
    name: "Manage Fiber Company",
    key: "manageFiberCompany",
    route: "/manageFiberCompany",
    component: <ManageFiberCompanies />,
  },
  {
    name: "Fiber Company Details",
    key: "editFiberCompany",
    route: "/manageFiberCompany/details",
    component: <EditFiberCompany />,
  },
  {
    name: "Create Fiber Plan",
    key: "createFiberPlan",
    route: "/manageFiberCompany/details/createFiberPlan",
    component: <CreateFiberPlan />,
  },
  {
    name: "Edit Fiber Plan",
    key: "editFiberPlan",
    route: "/manageFiberCompany/details/editFiberPlan",
    component: <EditFiberPlan />,
  },
  {
    name: "Manage Leads",
    key: "ManageLeads",
    route: "/manage-leads",
    component: <ManageLeads />,
  },
  {
    name: "My Calendar",
    key: "MyCalendar",
    route: "/my-calendar",
    component: <MyCalendar />,
  },
  {
    name: "Manage Customers",
    key: "manageCustomers",
    route: "/manage-customers",
    component: <ManageCustomers />,
  },
  {
    name: "Manage Referral Customers",
    key: "manageReferralCustomers",
    route: "/manage-referral-customers",
    component: <ManageRefferalCustomers />,
  },
  {
    name: "Edit Account Details",
    key: "editAccountDetails",
    route: "/edit-account-details",
    component: <EditAccountDetails menuItems={SuperAdminMenuItems} />,
  },
  {
    name: "Chat",
    key: "chat",
    route: "/chat",
    component: <ChatNew />,
  },
  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center/:id",
    component: <BusinessCenter />,
  },
  {
    name: "Manage Check Availability",
    key: "manage-check-availability",
    route: "/manage-check-availability",
    component: <ManageCheckAvailability />,
  },
  {
    name: "Availability Check Detail",
    key: "AvailabilityCheckDetail",
    route: "/manage-check-availability/details/:id",
    component: <AvailabilityCheckDetail />,
  },
  {
    name: "Manage Job Applications",
    key: "ManageJobApplications",
    route: "/manage-job-applications",
    component: <ManageJobApplications />,
  },
  {
    name: "Report A Bug",
    key: "CreateBugTicket",
    route: "/create-bug-ticket",
    component: <CreateBugTicketForm />,
  },
  {
    name: "Selling Card Details",
    key: "SellingCardDetails",
    route: "manage-training-modules/selling-card-details/:trainingModuleId",
    component: <SellingCardDetailsNew />,
  },
  {
    name: "Training Module Details",
    key: "TrainingModuleDetails",
    route: "manage-training-modules/training-module-details/:trainingModuleId",
    component: <SellingCardDetailsNew />,
  },
  {
    name: "Manage Bug Tickets",
    key: "ManageBugTickets",
    route: "/manage-bug-tickets",
    component: <ManageBugTickets />,
  },
  {
    name: "Job Application Details",
    key: "JobApplicationDetails",
    route: "/manage-job-applications/details/:id",
    component: <JobApplicationDetails />,
  },
  {
    name: "Create Comission Plan",
    key: "CreateComissionPlan",
    route: "/create-comission-plan",
    component: <CreateComissionPlan />,
  },
  {
    name: "Manage Commission Plans",
    key: "ManageCommissionPlans",
    route: "/manage-commission-plans",
    component: <ManageCommissionPlans />,
  },
  {
    name: "Commission Plan Details",
    key: "CommissionPlanDetails",
    route: "/manage-commission-plan/details/:id",
    component: <CommissionPlanDetails />,
  },
  {
    name: "User Commission",
    key: "UserCommission",
    route: "/commission-details",
    component: <UserCommission />,
  },
  {
    name: "Manage Commission Trackers",
    key: "ManageCommissionTrackers",
    route: "/manage-commission-trackers",
    component: <ManageCommissionTracker />,
  },
  {
    name: "Leaderboard",
    key: "Leaderboard",
    route: "/leaderboard",
    component: <LeaderBoard />,
  },
  {
    name: "Manage Debt Sheet",
    key: "ManageDebtSheet",
    route: "/manage-debt-sheet",
    component: <ManageDebtSheet />,
  },
  {
    name: "Manage Accounting Entries",
    key: "ManageAccountingEntries",
    route: "/manage-accounting-entries",
    component: <ManageAccountingEntries />,
  },
  {
    name: "Manage Customer Feedbacks",
    key: "ManageCustomerFeedbacks",
    route: "/manage-customer-feedbacks",
    component: <ManageCustomerFeedbacks />,
  },
  {
    name: "Manage Issue Types",
    key: "ManageIssueTypes",
    route: "/manage-issue-types",
    component: <IssuesPage />,
  },
  {
    name: "Issue Cancel Details",
    key: "IssueCancelDetails",
    route: "/issue-cancel-details/:id",
    component: <CancelDetailsPage />,
  },
  {
    name: "Issue Reschedule Details",
    key: "IssueRescheduleDetails",
    route: "/issue-reschedule-details/:id",
    component: <RescheduleDetailsPage />,
  },
  {
    name: "Sales Reporting Page",
    key: "SalesReportingPage",
    route: "/sales-reporting-page",
    component: <SalesReport />,
  },
  {
    name: "Sales Reporting Page",
    key: "SalesReportingPage",
    route: "/general-sales-reporting-page",
    component: <SalesGeneralReport />,
  },
  {
    name: "Offers Reporting Page",
    key: "OffersReportingPage",
    route: "/offers-reporting-page",
    component: <OffersReport />,
  },
  {
    name: "Manage Announcements Board",
    key: "ManageAnnouncementsBoard",
    route: "/manage-announcements-board",
    component: <ManageAnnouncements />,
  },
  {
    name: "Announcements Board",
    key: "AnnouncementsBoard",
    route: "/announcements-board",
    component: <Announcements />,
  },
  {
    name: "Customer Feedback Analysis",
    key: "CustomerFeedbackAnalysis",
    route: "/customer-feedback-analysis",
    component: <CustomerFeedback />,
  },
  {
    name: "Manage Recruit Templates",
    key: "ManageRecruitTemplates",
    route: "/manage-recruit-templates",
    component: <ManageRecruitTemplates />,
  },
  {
    name: "Master Sheet",
    key: "MasterSheet",
    route: "/master-sheet",
    component: <MasterSheet />,
  },
  {
    name: "Manage Company Consultations",
    key: "ManageCompanyConsultations",
    route: "/manage-company-consultations",
    component: <ManageCompanyConsultation />,
  },
  {
    name: "Commission Tree",
    key: "CommissionTree",
    route: "/commission-tree",
    component: <CommissionTree />,
  },
  {
    name: "Clawback Customer",
    key: "ClawbackCustomer",
    route: "/clawback-customer",
    component: <ClawbackCustomer />,
  },
  {
    name: "Custom Fields",
    key: "CustomFields",
    route: "/manage-custom-fields",
    component: <ManageCustomFields />,
  },

  {
    name: "Manage Brands",
    key: "ManageBrands",
    route: "/manage-brands",
    component: <BradingPage />,
  },
  {
    name: "Manage Brand",
    key: "ManageBrand",
    route: "/manage-brand",
    component: <ManageBrand />,
  },
  {
    name: "Communication Templates",
    key: "CommunicationTemplates",
    route: "/communication-templates",
    component: <ManageCommunicationTemplates />,
  },

  {
    name: "Mapping Filter",
    key: "MappingFilter",
    route: "/mapping-filter",
    component: <MappingFilter />,
  },
  {
    name: "Tags System",
    key: "Tags",
    route: "/tags",
    component: <TagsPage />,
  },
  {
    name: "Business Center",
    key: "BusinessCenter",
    route: "/business-center",
    component: <BusinessCenter />,
  },
  {
    name: "Manage Pitches",
    key: "ManagePitches",
    route: "/manage-pitches",
    component: <ManagePitches />,
  },
  {
    name: "Blogs",
    key: "Blogs",
    route: "/blogs",
    component: <BlogsPage />,
  },
  {
    name: "Create Blog",
    key: "Create",
    route: "/create-blog",
    component: <PostEditor action={"create"} />,
  },
  {
    name: "Blog Posts",
    key: "Blogs",
    route: "/edit/:id",
    component: <PostEditor action={"edit"} />,
  },
  {
    name: "Fiber Offer",
    key: "FiberOffer",
    route: "/fiber-offer/:id",
    component: <NewOffer />,
  },
  {
    name: "Preview Offer",
    key: "PreviewOffer",
    route: "/preview-offer/:repId/:id",
    component: <NewOffer />,
  },
  {
    name: "Preview Offer",
    key: "PreviewOffer",
    route: "/preview-offer/:repId/:id/:leadId",
    component: <NewOffer />,
  },
  {
    name: "Email Report",
    key: "EmailReport",
    route: "/email-report",
    component: <EmailReport />,
  },
];

export default SuperAdminRoutes;

import React, { useEffect,useState } from 'react'
import darkLogo from "./../images/navbar_logo_dark.png"
import lightLogo from "./../images/light-nav.png"
import { Link, useNavigate } from 'react-router-dom'
import "./Navbar.scss"
import DarkMode from './Toggle/DarkMode';
import { IoClose, IoMenu } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
const Navbar = ({lightTheme,setLightTheme}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const route = useLocation().pathname.split("/").slice(1);
    // console.log(route[0])
    useEffect(
        () => {
            const changeWidth = () => {
                setScreenWidth(window.innerWidth);
            }
            window.addEventListener('resize', changeWidth);
            return () => {
                window.removeEventListener('resize', changeWidth);
            }
        }, []
    )
    const navItems = [
        {
            title: 'Home',
            to: '/'
        },
        {
            title: 'Our Services',
            to: '/services'
        },
        {
            title: 'Referral Program',
            to: '/referral-program'
        },
        // {
        //     title: 'Blogs',
        //     to: '/blogs'
        // },
        {
            title: "Fiber Technology",
            to: "/fiber-technology"
        },
        {
            title: 'Opportunity',
            to: '/opportunity'
        },
        {
            title:"Summer Sales Program",
            to:"/summer-sales-program"
        },
        {
            title: 'Sign Up',
            to: '/signup'
        },
    ]

    return (
        <div id='nav' className='public_navbar_container'>
            <div className='nav_logo'>
                <img src={lightTheme?lightLogo:darkLogo} />
            </div>
            {
                screenWidth > 1460?
                <div className='nav_items'>
                    {navItems.map((item, index) => {
                        //  console.log(route[0],item.to.slice(1))
                        return (
                            <Link to={item.to} className={`link ${item.to.slice(1)==route[0] && 'active'}`} key={index}>{item.title}</Link>
                        )
                    }
                    )}
                    <button onClick={()=>{navigate('/signin')}}>
                        SIGN IN
                    </button>
                    <DarkMode lightTheme={lightTheme} setLightTheme={setLightTheme} />
                </div>
                :
                <div>
                   {isOpen?<IoClose style={{cursor:"pointer"}} color='var(--primary-text)' size={40} onClick={() => setIsOpen(!isOpen)} /> :<IoMenu style={{cursor:"pointer"}} color='var(--primary-text)' size={40} onClick={() => setIsOpen(!isOpen)} />}
                    {
                        isOpen?
                        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <div className='mobile_nav_items'>
                            {navItems.map((item, index) => {
                                // console.log(route[0],item.to.slice(1))
                                return (
                                    <Link to={item.to} className={`link ${item.to.slice(1)==route[0] && 'active'}`} key={index}>{item.title}</Link>
                                )
                            }
                            )}
                            <button  onClick={()=>{navigate('/signin'); setIsOpen(false)}}>
                                SIGN IN
                            </button>
                            <DarkMode lightTheme={lightTheme} setLightTheme={setLightTheme} />
                        </div>
                        </ClickAwayListener>
                        :null
                    }
                </div>
            }
        </div>
    )
}

export default Navbar
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from "@mui/material";
import ReactDOM from "react-dom";

class ExportData {
  // Function to convert camel case to capitalized words
  capitalizeKeys(data) {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const capitalizedKey = key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
          newItem[capitalizedKey] = item[key];
        }
      }
      return newItem;
    });
  }

  ExportToCsv(filename, data, selectedIds) {
    console.log("Data to export:", data); // Debug line
    console.log("Selected IDs:", selectedIds); // Debug line
    const dataToExport = data.filter((item) => selectedIds.includes(item.id));
    if (dataToExport.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Customers Selected",
        text: "Please select customers to download",
      });
      return;
    }
    const capitalizedData = this.capitalizeKeys(dataToExport);
    const ws = XLSX.utils.json_to_sheet(capitalizedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, filename);
  }

  ExportToXLSX(filename, data, selectedIds) {
    console.log("Data to export:", data); // Debug line
    console.log("Selected IDs:", selectedIds); // Debug line
    const selected = data.filter((item) => selectedIds.includes(item.id));
    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Customers Selected",
        text: "Please select customers to download",
      });
      return;
    }
    const capitalizedData = this.capitalizeKeys(selected);
    const ws = XLSX.utils.json_to_sheet(capitalizedData);

    // Calculate column widths
    const columnWidths = Object.keys(capitalizedData[0])
      .filter((key) => key !== "id")
      .map((key) => {
        const maxLength = Math.max(
          key.length,
          ...capitalizedData.map((item) =>
            item[key] ? item[key].toString().length : 0
          )
        );
        return { wch: maxLength + 5 }; // Add extra space for padding
      });
    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, filename);
  }

  // CSV Export Method
  ExportIssuesToCsv(filename, data) {
    if (!Array.isArray(data) || data.length === 0) {
      console.log("Data to export:", data); // Debug line
      Swal.fire({
        icon: "error",
        title: "No Data",
        text: "There is no data to export.",
      });
      return;
    }

    const capitalizedData = this.capitalizeKeys(data);
    const ws = XLSX.utils.json_to_sheet(capitalizedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Issues");
    XLSX.writeFile(wb, filename);
  }

  ExportIssuesToXLSX(filename, data) {
    if (!Array.isArray(data) || data.length === 0) {
      console.log("Data to export:", data); // Debug line
      Swal.fire({
        icon: "error",
        title: "No Data",
        text: "There is no data to export.",
      });
      return;
    }

    const capitalizedData = this.capitalizeKeys(data);
    const ws = XLSX.utils.json_to_sheet(capitalizedData);

    // Calculate column widths
    const columnWidths = Object.keys(capitalizedData[0]).map((key) => {
      const maxLength = Math.max(
        key.length,
        ...capitalizedData.map((item) =>
          item[key] ? item[key].toString().length : 0
        )
      );
      return { wch: maxLength + 5 }; // Add extra space for padding
    });
    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Issues");
    XLSX.writeFile(wb, filename);
  }

  exportCandidateStatsLeads(data) {
    //show swal loading
    Swal.fire({
      title: "Exporting Data",
      text: "Please wait while the data is being exported...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (!Array.isArray(data) || data.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Data",
        text: "There are no candidates to export.",
      });
      return;
    }

    // Extract necessary fields from the data
    const formattedData = data.map((candidate) => ({
      ID: candidate.id,
      Name: candidate.name,
      "Company Email": candidate.companyEmail,
      "Personal Email": candidate.personalEmail,
      Country: candidate.country,
      State: candidate.state,
      City: candidate.city,
      "Zip Code": candidate.zipCode,
      Address: candidate.address,
      "Map String": candidate.mapString,
      Phone: candidate.phone,
      "Lead Type": candidate.leadType,
      Status: candidate.status,
      "Company Name": candidate.companyName,
      "Position Title": candidate.positionTitle,
      Industry: candidate.industry,
      "Door to Door": candidate.doorToDoor,
      LinkedIn: candidate.linkedIn,
      "Created At": candidate.createdAt,
      "Last Updated At": candidate.lastUpdatedAt,
      Latitude: candidate.coordinates?.latitude,
      Longitude: candidate.coordinates?.longitude,
    }));

    // Create a worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Calculate column widths for better presentation
    const columnWidths = Object.keys(formattedData[0]).map((key) => {
      const maxLength = Math.max(
        key.length,
        ...formattedData.map((item) =>
          item[key] ? item[key].toString().length : 0
        )
      );
      return { wch: maxLength + 5 }; // Add extra space for padding
    });
    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");

    // Close the loading swal
    Swal.close();

    // Write the workbook to a file
    XLSX.writeFile(wb, "Candidate_Stats_Leads.xlsx");
  }

  // dataToExport(data,fiberCompaniesForDisplay,mapIcons){
  //     let l = data.map(
  //         (lead) => {

  //             let latestIssue = lead.fidiumCustomer?.issueType === "Cancel"? lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
  //                 lead?.fidiumCustomer?.issueType === "Reschedule"? lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
  //                 null

  //             let latestIssueTimestamp = lead.fidiumCustomer?.issueType === "Cancel"?
  //               lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
  //                 lead?.fidiumCustomer?.issueType === "Reschedule"?
  //                 lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
  //                 "N/A"

  //             let noOfIssues = lead.fidiumCustomer?.cancelationForms?.length + lead.fidiumCustomer?.rescheduleForms?.length

  //             return {
  //                 fiberCompany: fiberCompaniesForDisplay.find(company => company.id === lead.fidiumCustomer?.fiberCompanyId)?.name,
  //                 // sales agency.
  //                 salesOrgName: lead.fidiumCustomer?.salesOrg?.name,
  //                 salesRep: lead.fidiumCustomer?.salesRep?.firstName + " " + lead.fidiumCustomer?.salesRep?.lastName,
  //                 leadType: lead.fidiumCustomer? "Customer" : lead.isProspect? "Prospect" : "",
  //                 mapIcon: mapIcons.find(mapIcon => mapIcon.id === lead.fiberHouse?.mapIconId)?.name,
  //                 lightningNotes: lead.notes,
  //                 accountStatus: lead.fidiumCustomer?.accountStatus,
  //                 soldTimestamp: lead.fidiumCustomer?.timeStamp? new Date(lead.fidiumCustomer?.timeStamp).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.timeStamp).toLocaleTimeString() : null,
  //                 contactName: getName(lead),
  //                 seocndaryContactName: lead?.fidiumCustomer?.secondaryAccountHolderFullName,
  //                 phone: getPhone(lead),
  //                 email: lead.fidiumCustomer?.email ? lead.fidiumCustomer.email : lead.email,
  //                 address: getAddress(lead),
  //                 originalInstallDate: lead.fidiumCustomer?.originallInstallDateTimeFrom? new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleTimeString() : null,
  //                 updatedInstallDate: lead.fidiumCustomer?.updatedInstallDateTimeFrom? new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleTimeString() : null,
  //                 internetType: lead.fidiumCustomer.fiberPlan?.name?.includes("HFC")? "HFC" : "FTTH",
  //                 fiberPlan: lead.fidiumCustomer?.fiberPlan?.name,
  //                 // original fiber plan
  //                 // updated fiber plan
  //                 promos: lead.fidiumCustomer?.promo,
  //                 noOfReviews : lead.fidiumCustomer?.feedbacks?.length>0? lead.fidiumCustomer.feedbacks.length : "",
  //                 typeOfReview:lead.fidiumCustomer?.feedbacks.length>0? lead.fidiumCustomer?.feedbacks[0]?.content?"Lightning":"Google":"",
  //                 locationOfReview: lead.fidiumCustomer?.feedbacks[0]?.feedbackType,
  //                 noOfIssues :noOfIssues>0 && lead?.fidiumCustomer?.issueType ? noOfIssues : "",
  //                 accountIssue: lead?.fidiumCustomer?.issueType,
  //                 issueDescription : lead?.fidiumCustomer?.issueDescription,
  //                 issueSubmittedTimestamp: latestIssueTimestamp !== "N/A"? new Date(latestIssueTimestamp).toLocaleDateString() + " " + new Date(latestIssueTimestamp).toLocaleTimeString() : "",
  //                 issueResolved: latestIssue?latestIssue.resolved?"Y":latestIssue.isResolved?"Y":"N" : "",
  //                 id: lead.id,
  //             }
  //         }
  //     )
  //     return l
  // }

  dataToExport = (data, fiberCompaniesForDisplay, mapIcons, selectedFields) => {
    let l = data.map((lead) => {
      let latestIssue =
        lead.fidiumCustomer?.issueType === "Cancel"
          ? lead?.fidiumCustomer?.cancelationForms?.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            )[0]
          : lead?.fidiumCustomer?.issueType === "Reschedule"
          ? lead?.fidiumCustomer?.rescheduleForms?.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            )[0]
          : null;

      let latestIssueTimestamp =
        lead.fidiumCustomer?.issueType === "Cancel"
          ? lead?.fidiumCustomer?.cancelationForms?.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            )[0]?.timestamp
          : lead?.fidiumCustomer?.issueType === "Reschedule"
          ? lead?.fidiumCustomer?.rescheduleForms?.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            )[0]?.timestamp
          : "N/A";

      let noOfIssues =
        lead.fidiumCustomer?.cancelationForms?.length +
        lead.fidiumCustomer?.rescheduleForms?.length;

      let allData = {
        fiberCompany: fiberCompaniesForDisplay.find(
          (company) => company.id === lead.fidiumCustomer?.fiberCompanyId
        )?.name,
        salesOrgName: lead.fidiumCustomer?.salesOrg?.name,
        salesRep:
          lead.fidiumCustomer?.salesRep?.firstName +
          " " +
          lead.fidiumCustomer?.salesRep?.lastName,
        leadType: lead.fidiumCustomer
          ? "Customer"
          : lead.isProspect
          ? "Prospect"
          : "",
        mapIcon: mapIcons.find(
          (mapIcon) => mapIcon.id === lead.fiberHouse?.mapIconId
        )?.name,
        lightningNotes: lead.notes,
        accountStatus: lead.fidiumCustomer?.accountStatus,
        soldTimestamp: lead.fidiumCustomer?.timeStamp
          ? new Date(lead.fidiumCustomer?.timeStamp).toLocaleDateString() +
            " " +
            new Date(lead.fidiumCustomer?.timeStamp).toLocaleTimeString()
          : null,
        contactName: getName(lead),
        seocndaryContactName:
          lead?.fidiumCustomer?.secondaryAccountHolderFullName,
        phone: getPhone(lead),
        email: lead.fidiumCustomer?.email
          ? lead.fidiumCustomer.email
          : lead.email,
        address: getAddress(lead),
        originalInstallDate: lead.fidiumCustomer?.originallInstallDateTimeFrom
          ? new Date(
              lead.fidiumCustomer?.originallInstallDateTimeFrom
            ).toLocaleDateString() +
            " " +
            new Date(
              lead.fidiumCustomer?.originallInstallDateTimeFrom
            ).toLocaleTimeString()
          : null,
        updatedInstallDate: lead.fidiumCustomer?.updatedInstallDateTimeFrom
          ? new Date(
              lead.fidiumCustomer?.updatedInstallDateTimeFrom
            ).toLocaleDateString() +
            " " +
            new Date(
              lead.fidiumCustomer?.updatedInstallDateTimeFrom
            ).toLocaleTimeString()
          : null,
        internetType: lead.fidiumCustomer.fiberPlan?.name?.includes("HFC")
          ? "HFC"
          : "FTTH",
        fiberPlan: lead.fidiumCustomer?.fiberPlan?.name,
        promos: lead.fidiumCustomer?.promo,
        noOfReviews:
          lead.fidiumCustomer?.feedbacks?.length > 0
            ? lead.fidiumCustomer.feedbacks.length
            : "",
        typeOfReview:
          lead.fidiumCustomer?.feedbacks.length > 0
            ? lead.fidiumCustomer?.feedbacks[0]?.content
              ? "Lightning"
              : "Google"
            : "",
        locationOfReview: lead.fidiumCustomer?.feedbacks[0]?.feedbackType,
        noOfIssues:
          noOfIssues > 0 && lead?.fidiumCustomer?.issueType ? noOfIssues : "",
        accountIssue: lead?.fidiumCustomer?.issueType,
        issueDescription: lead?.fidiumCustomer?.issueDescription,
        issueSubmittedTimestamp:
          latestIssueTimestamp !== "N/A"
            ? new Date(latestIssueTimestamp).toLocaleDateString() +
              " " +
              new Date(latestIssueTimestamp).toLocaleTimeString()
            : "",
        issueResolved: latestIssue
          ? latestIssue.resolved
            ? "Y"
            : latestIssue.isResolved
            ? "Y"
            : "N"
          : "",
        id: lead.id,
      };

      // Filter the data to include only the selected fields
      // let filteredData = {};
      // selectedFields.forEach((field) => {
      //   if (allData.hasOwnProperty(field)) {
      //     filteredData[field] = allData[field];
      //   }
      // });

      return allData;
    });

    return l;
  };

  promptFieldSelection = (allFields) => {
    return new Promise((resolve) => {
      const ModalComponent = () => {
        const [open, setOpen] = useState(true);
        const [selectedFields, setSelectedFields] = useState([
          "id",
          ...allFields.map((field) => field.key),
        ]);

        const handleClose = () => {
          setOpen(false);
          // resolve([]);
        };

        const handleExport = () => {
          resolve(selectedFields);
          setOpen(false);
        };

        const handleCheckboxChange = (event) => {
          const { value, checked } = event.target;
          if (value === "id") return; // Prevent unchecking the 'id' field
          setSelectedFields((prev) =>
            checked ? [...prev, value] : prev.filter((field) => field !== value)
          );
        };
        const handleSelectAll = () => {
          setSelectedFields(allFields.map((field) => field.key));
        };

        const handleDeselectAll = () => {
          setSelectedFields(["id"]); // Only keep 'id' field selected
        };

        // const handleSelectAllToggle = () => {
        //     if (selectedFields.length === allFields.length + 1) {
        //         setSelectedFields(['id']); // Deselect all except 'id'
        //     } else {
        //         setSelectedFields(['id', ...allFields.map(field => field.key)]); // Select all
        //     }
        // };

        return (
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: 600,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                scrollbarWidth: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{
                  color: "black !important",
                }}
              >
                Select Fields to Export
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  gap: 2,
                  padding: "0px !important",
                }}
              >
                <Button
                  onClick={handleSelectAll}
                  sx={{
                    textAlign: "left",
                    textDecoration: "underline",
                    justifyContent: "left",
                    color: "grey !important",
                  }}
                >
                  Select All
                </Button>
                <Button
                  onClick={handleDeselectAll}
                  sx={{
                    textAlign: "left",
                    textDecoration: "underline",
                    justifyContent: "left",
                    color: "grey !important",
                  }}
                >
                  Deselect All
                </Button>
              </Box>
              <Box
                sx={{
                  height: 450,
                  overflowY: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  border: "1px solid grey",
                  width: "100%",
                }}
              >
                <FormGroup
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  {allFields
                    .filter((field) => field.key !== "id")
                    .map((field) => (
                      <FormControlLabel
                        key={field.key}
                        control={
                          <Checkbox
                            value={field.key}
                            checked={selectedFields.includes(field.key)}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={field.label}
                        sx={{
                          display: "inline-block",
                        }}
                      />
                    ))}
                </FormGroup>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  width: "100%",
                  padding: "0px !important",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExport}
                  sx={{
                    width: "50%",
                  }}
                >
                  Export
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleClose}
                  sx={{
                    width: "50%",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        );
      };

      // Render the modal component
      const modalContainer = document.createElement("div");
      document.body.appendChild(modalContainer);
      ReactDOM.render(<ModalComponent />, modalContainer);
    });
  };
}
const getName = (lead) => {
  if (lead.fidiumCustomer != null) {
    return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
  } else if (lead.name != null) {
    return lead.name;
  } else {
    return "No Name";
  }
};

const getAddress = (lead) => {
  if (lead.fiberHouse?.mapString) {
    return lead.fiberHouse?.mapString;
  } else if (lead.fiberHouse?.address) {
    return (
      lead.fiberHouse?.address?.street +
      " " +
      lead.fiberHouse?.address?.city +
      ", " +
      lead.fiberHouse?.address?.state +
      " " +
      lead.fiberHouse?.address?.zip +
      ", " +
      lead.fiberHouse?.address?.country
    );
  } else {
    return "No Address";
  }
};
const getPhone = (lead) => {
  if (lead.fidiumCustomer?.cellPhone != null) {
    return lead.fidiumCustomer.cellPhone;
  } else if (lead.phone != null) {
    return lead.phone;
  } else {
    return "";
  }
};
export default ExportData;

import { Modal } from '@mui/material'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import UserSelector from "./../../../../components/UserSelector"
import MasterSheetApi from "./../../../../API/MasterSheetApi"
import { useNavigate } from 'react-router-dom'

const ExportToWorksheetModal =  ({open,setOpen,leadIds,showModal = true}) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [salesRepIds, setSalesRepIds] = useState('')
    const navigate = useNavigate()
    console.log(leadIds)
    const handleExport = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are exporting the data to the worksheet",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        if(name === ''){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Name is required"
            })
            return
        }

        const req = {
            name : name,
            description : description,
            salesRepIds : salesRepIds,
            leadIds : leadIds,
        }

        const res = await MasterSheetApi.CreateStaticList(req)

        if(res.status.success === true){


            if(showModal){
                let option = await Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Data has been exported to worksheet! Do you want to go to the Worksheets page?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No"
                })
    
                if(!option.isConfirmed){
                    setOpen(false)
                }
                else{
                    setOpen(false)
                    navigate('/master-sheet')
                }
            }
            else{
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Data has been exported to worksheet",
                    showConfirmButton: true
                })
            }

        }

       
      

    }   

    return (
        <Modal open={open} onClose={()=>{
            setOpen(false)
        }}>
            <div style={{
                    width: "min(500px,80%)"
                }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg'>
                <div className='flex flex-col gap-4'>
                    <h1 className='text-2xl font-bold text-center'>Export to Worksheet</h1>
                    <input type="text" placeholder="Name*" value={name} onChange={(e) => setName(e.target.value)} className='w-full p-2 border border-gray-300 rounded-lg mt-4' />
                    <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} className='w-full p-2 border border-gray-300 rounded-lg mt-4' />
                    <UserSelector placeholder='Select Sales Reps' setUser={setSalesRepIds} conversationType={'Group'} userTypes={["SalesRep","SalesOrgAdmin"]}  />
                    <div className='flex gap-4 mt-4'>
                        <button onClick={()=>{
                            handleExport()
                        }} className='bg-blue-500 text-white p-2 rounded-lg w-full'>Export</button>
                        <button onClick={()=>{
                            setOpen(false)
                        }} className='bg-gray-200 text-black p-2 rounded-lg w-full'>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ExportToWorksheetModal
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Layout from "../../../Layouts/Layout";
// import "./ContactCenterRecruitLeads.scss";
// import "./candidate-filters.scss";
import Select from "react-select";
import FiberHouseApi from "../../../API/FiberHouseApi";
import UsersApi from "../../../API/UsersApi";
import MapIconApi from "../../../API/MapIconApi";
import QuickDate from "../../../components/QuickDate";
import LeadRecruitCard from "./LeadRecruitCard";
import VersatileLoader from "../../../components/VersatileLoader";
import InvitationModal from "../../TeamAdmin/MapPage/components/CandidateMainLeadCard/InviteForm";
import { Input } from "@mui/material";
import ExportToWorksheetModal from "../ContactCenter/ExportToWorksheetModal";
import { MdExpandMore } from "react-icons/md";
import ContextMenu from "../../../components/ContextMenu";
import ExportData from "../../../Services/ExportData";
import Swal from "sweetalert2";
import MasterSheetApi from "../../../API/MasterSheetApi";
import { useNavigate } from "react-router-dom";
import { ContactCenterWrapperContext } from "../ContactCenterWrapper";

const exportData = new ExportData();

const leadTypeOptions = [
  { value: "Recruit", label: "Recruit" },
  { value: "Company", label: "Company" },
];

const pageSizeOptions = [20, 50, 100, 200, 500, 1000, 2000, 5000];

const statusOptions = [
  { value: "New Candidate", label: "New Candidate" },
  { value: "Invitation Sent", label: "Job Ad Sent" },
  { value: "Applied", label: "Applied" },
  // { value: 'Prospect', label: 'Contacted' },
  { value: "1st Interview Sent", label: "Interview Link Sent" },
  { value: "1st Interview Scheduled", label: "Interview Scheduled" },
  { value: "2nd Interview Sent", label: "2nd Interview Link Sent" },
  { value: "2nd Interview Scheduled", label: "2nd Interview Scheduled" },
  { value: "Rejected", label: "Rejected" },
  { value: "Hired", label: "Hired" },
  // { value: 'ShortListed', label: 'ShortList' },
  // { value: 'Lost', label: 'Lost' },
];

const dateRangeOptions = [
  { value: "created", label: "Created Date" },
  { value: "appointment", label: "Appointment Date" },
  { value: "lastUpdated", label: "Last Updated Date" },
];

const initSearchFilters = {
  searchString: null,
  leadType: leadTypeOptions[0].value,
  iconIds: [],
  status: statusOptions[0].value,
  industries: [],
  companies: [],
  positionTitles: [],
  date: {
    rangeType: dateRangeOptions[0].value,
    quickDate: null,
    to: null,
    from: null,
  },
};

const exportMenuItems = [
  {
    title: "Export to XLSX",
    type: "xlsx",
  },
  {
    title: "Export to CSV",
    type: "csv",
  },
  {
    title: "Export All to XLSX",
    type: "all",
  },
];

const ContactCenterRecruitLeads = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [exportModal, setExportModal] = useState(false);

  const [searchFilters, setSearchFilters] = useState(initSearchFilters);
  const [anchorEl, setAnchorEl] = useState(null);

  const [
    { industryOptions, companyOptions, positionTitleOptions, iconOptions },
    setFetchedOptions,
  ] = useState({
    industryOptions: [],
    companyOptions: [],
    positionTitleOptions: [],
    iconOptions: [],
  });

  const { ToggleComponent } = useContext(ContactCenterWrapperContext);

  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(1);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [resultLeads, setResultLeads] = useState([]);
  const [init, setInit] = useState(false);
  const currentFetchId = useRef(null);

  const [totalCount, setTotalCount] = useState(0);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);

  const containerRef = useRef(null);

  const fetchIndustryOptions = async () => {
    let res = await FiberHouseApi.GetCandidateIndustries();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      return sortedData;
    }
  };

  const fetchCompanyOptions = async () => {
    const res = await FiberHouseApi.GetCandidateCompanies();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      return sortedData;
    }
  };

  const fetchPositionTitleOptions = async () => {
    const res = await FiberHouseApi.GetCandidatePositionTitles();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      return sortedData;
    }
  };

  const fetchMapIcons = async () => {
    const res = await MapIconApi.GetAllMapIcons();
    if (res?.status?.success) {
      res.data.sort((a, b) => a.order - b.order);

      return res.data.filter(
        (icon) => icon.iconType === "Recruit" || icon.iconType === "Company"
      );
    }
  };

  // SET FETCHED OPTIONS THEN SET INIT
  useEffect(() => {
    Promise.all([
      fetchIndustryOptions(),
      fetchCompanyOptions(),
      fetchPositionTitleOptions(),
      fetchMapIcons(),
    ]).then(
      ([
        industryOptions,
        companyOptions,
        positionTitleOptions,
        iconOptions,
      ]) => {
        setFetchedOptions({
          industryOptions,
          companyOptions,
          positionTitleOptions,
          iconOptions,
        });
        setInit(true);
      }
    );
  }, []);

  // GET CANDIDATES BASED ON SEARCH FILTERS
  const getCandidates = useCallback(async () => {
    const thisFetchId = Date.now();
    currentFetchId.current = thisFetchId;
    // if(!shouldRun){setShouldRun(true)}
    let req = {
      ids: null,
      searchString: searchFilters.searchString,
      country: null,
      state: null,
      name: null, // TODO: CHECK IF NEEDED
      email: null, // TODO: CHECK IF NEEDED
      leadType: [searchFilters.leadType],
      status: searchFilters.status,
      industries:
        searchFilters.industries.length === 0 ? null : searchFilters.industries,
      companies:
        searchFilters.companies.length === 0 ? null : searchFilters.companies,
      positionTitles:
        searchFilters.positionTitles.length === 0
          ? null
          : searchFilters.positionTitles,
      [`${searchFilters.date.rangeType}From`]:
        searchFilters.date.from === "" ? null : searchFilters.date.from,
      [`${searchFilters.date.rangeType}To`]:
        searchFilters.date.to === "" ? null : searchFilters.date.to,

      mapIcons:
        searchFilters.iconIds.length === 0 ? null : searchFilters.iconIds,
    };

    try {
      const res = await UsersApi.getCandidatesFull({
        ...req,
        pagingParams: {
          pageNumber: pageNum,
          pageSize,
        },
      });

      if (currentFetchId.current !== thisFetchId) return;
      if (res?.status?.success) {
        setResultLeads((prev) =>
          pageNum > 1 ? [...prev, ...res.data.list] : res.data.list
        );
        setTotalCount(res.data.totalItems);
        setNextPageAvailable(res.data.hasNextPage);
      }
    } catch (error) {
       console.log(error);
    } finally {
      if (currentFetchId.current === thisFetchId) {
        setLoading(false);
      }
    }
  }, [searchFilters, pageNum, pageSize]);

  // EXECUTE GET CANDIDATES WHEN SEARCH FILTERS CHANGE
  useEffect(() => {
    console.log({ searchFilters, init, pageSize, pageNum });
    if (!init) return;

    setLoading(true);

    const delay = 500;

    const handler = setTimeout(() => {
      getCandidates();
    }, delay);

    return () => clearTimeout(handler);
  }, [searchFilters, pageSize, pageNum, init, getCandidates]);

  const camelCaseToNormal = useCallback((text) => {
    if (!text) return text;

    // Insert a space before all capital letters except the first one
    let result = text.replace(/([a-z])([A-Z])/g, "$1 $2");

    // Capitalize the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
  }, []);

  const exportTo = useCallback(
    async (ext, worksheetOptions) => {
      try {
        let req = {
          ids: null,
          searchString: searchFilters.searchString,
          country: null,
          state: null,
          name: null, // TODO: CHECK IF NEEDED
          email: null, // TODO: CHECK IF NEEDED
          leadType: [searchFilters.leadType],
          status: searchFilters.status,
          industries:
            searchFilters.industries.length === 0
              ? null
              : searchFilters.industries,
          companies:
            searchFilters.companies.length === 0
              ? null
              : searchFilters.companies,
          positionTitles:
            searchFilters.positionTitles.length === 0
              ? null
              : searchFilters.positionTitles,
          [`${searchFilters.date.rangeType}From`]:
            searchFilters.date.from === "" ? null : searchFilters.date.from,
          [`${searchFilters.date.rangeType}To`]:
            searchFilters.date.to === "" ? null : searchFilters.date.to,

          mapIcons:
            searchFilters.iconIds.length === 0 ? null : searchFilters.iconIds,
        };

        const fieldTypes = resultLeads.reduce((prevAllFields, lead) => {
          const resFields = Object.keys(lead).reduce(
            (prev, leadKey) => ({
              ...prev,
              [leadKey]: typeof lead[leadKey] !== "object",
            }),
            {}
          );
          return { ...prevAllFields, ...resFields };
        }, {});

        const fields = Object.keys(fieldTypes)
          .filter((f) => fieldTypes[f])
          .map((f) => ({ key: f, label: camelCaseToNormal(f) }));

        const promptedFields =
          ext === "worksheet"
            ? []
            : [...new Set(await exportData.promptFieldSelection(fields))];

        console.log({ fields });
        console.log({ promptedFields });

        if (ext !== "worksheet" && promptedFields.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No fields selected for export.",
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        }

        let finalLeads = resultLeads;

        if (ext === "all") {
          const res = await UsersApi.getCandidatesFull({
            ...req,
            PageNumber: 1,
            PageSize: totalCount + 1000,
          });

          finalLeads = res.data.list;
        }

        const selectedLeads =
          ext === "all" ? finalLeads.map((l) => l.id) : selectedLeadIds;

        const leadsFilteredFields = finalLeads.map((l) =>
          Object.keys(l)
            .filter((key) => promptedFields.includes(key))
            .reduce((prev, key) => ({ ...prev, [key]: l[key] }), {})
        );

        switch (ext) {
          case "csv":
            exportData.ExportToCsv(
              "Leads.csv",
              leadsFilteredFields,
              selectedLeads
            );
            break;
          case "xlsx":
          case "all":
            exportData.ExportToXLSX(
              "Leads.xlsx",
              leadsFilteredFields,
              selectedLeads
            );
            break;

          case "worksheet":
            req.PageSize = 1000000;
            req.PageNumber = 1;
            req.listName = worksheetOptions.name;
            req.listDescription = worksheetOptions.description;
            req.userIds = worksheetOptions.repIds;
            req.listType = worksheetOptions.type;

            const res = await MasterSheetApi.ExportCandidates(req);
            if (res?.status?.success) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Leads exported to worksheet successfully",
                didOpen: () => {
                  Swal.hideLoading();
                },
              });

              navigate("/master-sheet");
            }
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    [
      resultLeads,
      selectedLeadIds,
      searchFilters,
      totalCount,
      camelCaseToNormal,
      navigate,
    ]
  );

  console.log(resultLeads.map((l) => l.user));

  // SET PAGE NUMBER TO ONE AND EMPTY RESULT LEADS WHEN SEARCH FILTERS CHANGE
  useEffect(() => {
    setPageNum(1);
    setResultLeads([]);
    setSelectedLeadIds([]);
    console.log({ searchFilters });
  }, [searchFilters]);

  // WHEN TAB CHANGES (LEAD TYPE), RESET ALL OTHER SEARCH FILTERS
  useEffect(() => {
    setSearchFilters((prev) => ({
      ...initSearchFilters,
      leadType: prev.leadType,
    }));
  }, [searchFilters.leadType]);

  // SHOW ICONS BASED ON LEAD TYPE
  const iconsToShow = useMemo(
    () =>
      iconOptions.filter((icon) => icon.iconType === searchFilters.leadType),
    [iconOptions, searchFilters.leadType]
  );

  useEffect(() => {
    if (loading || !init || !nextPageAvailable) return () => {};

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (nextPageAvailable) {
              setPageNum((prev) => prev + 1);
            }
          }
        });
      },
      { threshold: 1.0 } // 1.0 means fully visible
    );

    if (containerRef.current) {
      intersectionObserver.observe(containerRef.current.lastChild);
    }
    return () => {
      intersectionObserver.disconnect();
    };
  }, [loading, init, nextPageAvailable]);

  return (
    <Layout>
      <ToggleComponent />
      {selectedLead && (
        <InvitationModal
          open={selectedLead}
          candidate={selectedLead}
          candidateId={selectedLead.id}
          candidateType={searchFilters.leadType}
          onClose={() => setSelectedLead(null)}
          mode={"email"}
          isModeSettable={true}
        />
      )}
      <div className="ContactCenter">
        <div className="HeaderDiv">
          {leadTypeOptions.map((leadType) => (
            <button
              className={`${
                searchFilters.leadType === leadType.value && "active"
              }`}
              onClick={() =>
                setSearchFilters((prev) => ({
                  ...prev,
                  leadType: leadType.value,
                }))
              }
            >
              {leadType.label}
            </button>
          ))}
        </div>

        <div className="ContentDiv">
          <div className="Leads">
            <div className="SearchFilters">
              <ExportToWorksheetModal
                open={exportModal}
                setOpen={setExportModal}
                onExport={(name, description, repIds, type) =>
                  exportTo("worksheet", {
                    name,
                    description,
                    repIds,
                    type,
                  })
                }
              />
              <div className="SearchFiltersDiv">
                <div
                  style={{ marginBottom: "1rem" }}
                  className="mb-4 p-4 rounded-[10px] w-full"
                >
                  <div className="InputsDiv">
                    <div className="InputDiv">
                      <label>Search</label>
                      <Input
                        placeholder="Search"
                        value={searchFilters.searchString ?? ""}
                        onChange={(e) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            searchString: e.target.value || null,
                          }))
                        }
                      />
                    </div>
                    <div className="InputDiv">
                      <label>Status</label>
                      <Select
                        value={statusOptions.find(
                          (o) => o.value === searchFilters.status
                        )}
                        onChange={(option) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            status: option.value,
                          }))
                        }
                        options={statusOptions}
                        placeholder="Select Status"
                        isClearable
                      />
                    </div>

                    {/* Industries */}
                    <div className="InputDiv">
                      <label htmlFor="assignedConvertedToCustomer">
                        Industries
                      </label>
                      <Select
                        isMulti
                        value={industryOptions.filter((o) =>
                          searchFilters.industries.find((i) => i === o.value)
                        )}
                        onChange={(options) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            industries: options.map((o) => o.value),
                          }))
                        }
                        options={industryOptions}
                        placeholder="Select Industries"
                      />
                    </div>

                    {/* Companies */}

                    <div className="InputDiv">
                      <label htmlFor="companies">Companies</label>
                      <Select
                        isMulti
                        value={companyOptions.filter((o) =>
                          searchFilters.companies.find((i) => i === o.value)
                        )}
                        onChange={(options) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            companies: options.map((o) => o.value),
                          }))
                        }
                        options={companyOptions}
                        placeholder="Select Companies"
                      />
                    </div>

                    {/* Position Titles */}

                    <div className="InputDiv">
                      <label htmlFor="assignedConvertedToCustomer">
                        Position Titles
                      </label>
                      <Select
                        isMulti
                        value={positionTitleOptions.filter((o) =>
                          searchFilters.positionTitles.find(
                            (i) => i === o.value
                          )
                        )}
                        onChange={(options) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            positionTitles: options.map((o) => o.value),
                          }))
                        }
                        options={positionTitleOptions}
                        placeholder="Select Position Titles"
                      />
                    </div>

                    <div className="InputDiv">
                      <label>Date Type</label>
                      <Select
                        value={dateRangeOptions.find(
                          (o) => o.value === searchFilters.date.rangeType
                        )}
                        onChange={(option) =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            date: { ...prev.date, rangeType: option.value },
                          }))
                        }
                        options={dateRangeOptions}
                        placeholder="Select Date Range Type"
                      />
                    </div>

                    <QuickDate
                      label="Quick Date"
                      selectQuickDate={searchFilters.date.quickDate}
                      setQuickDateValue={(quickDate) =>
                        setSearchFilters((prev) => ({
                          ...prev,
                          date: { ...prev.date, quickDate },
                        }))
                      }
                      setFrom={(from) =>
                        setSearchFilters((prev) => ({
                          ...prev,
                          date: { ...prev.date, from },
                        }))
                      }
                      setTo={(to) =>
                        setSearchFilters((prev) => ({
                          ...prev,
                          date: { ...prev.date, to },
                        }))
                      }
                      from={searchFilters.date.from}
                      to={searchFilters.date.from}
                      future={searchFilters.date.rangeType === "appointment"}
                    />

                    <h1 className="lead_type_title">Lead Status</h1>
                    <div className="select_deselect">
                      <button
                        className="select"
                        onClick={() =>
                          setSearchFilters((prev) => ({
                            ...prev,
                            iconIds: iconsToShow.map((icon) => icon.id),
                          }))
                        }
                      >
                        Select All
                      </button>
                      <button
                        className="deselect"
                        onClick={() =>
                          setSearchFilters((prev) => ({ ...prev, iconIds: [] }))
                        }
                      >
                        Deselect All
                      </button>
                    </div>

                    <div className="MapIconStats">
                      {iconsToShow.map((icon, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setSearchFilters((prev) => ({
                              ...prev,
                              iconIds: prev.iconIds.includes(icon.id)
                                ? prev.iconIds.filter((id) => id !== icon.id)
                                : [...prev.iconIds, icon.id],
                            }));
                          }}
                          className={`MapIconStat ${
                            searchFilters.iconIds.includes(icon.id)
                              ? "active"
                              : ""
                          }`}
                        >
                          <h3>
                            <img
                              src={`data:image/png;base64,${icon.image}`}
                              alt="Map Icon"
                            />
                          </h3>
                          <p>{icon.abbreviation}</p>
                        </button>
                      ))}
                    </div>

                    <div className="flex justify-end items-center w-full">
                      <button
                        className="filter_btn bg-[#2761D0] py-2 px-4 rounded-2xl text-white"
                        onClick={() =>
                          setSearchFilters((prev) => ({
                            ...initSearchFilters,
                            leadType: prev.leadType,
                          }))
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="LeadsList" ref={containerRef}>
              <div className="leads_top_button">
                <h3>RESULTS ({totalCount})</h3>

                <div className="page_size_container">
                  <p>Page Size</p>
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(+e.target.value);
                    }}
                  >
                    {pageSizeOptions.map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  className="filter_btn export_button"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Export | <MdExpandMore color="white" size={20} />
                </button>
                <button
                  onClick={() => {
                    setExportModal(true);
                  }}
                  className="filter_btn bg-[#2761D0]"
                >
                  Export To Worksheet
                </button>

                <ContextMenu
                  anchorEl={anchorEl}
                  handleClose={() => {
                    setAnchorEl(null);
                  }}
                  setAnchorEl={setAnchorEl}
                  menuItems={exportMenuItems.map((mi) => ({
                    ...mi,
                    onClick: () => exportTo(mi.type),
                  }))}
                />
              </div>

              <div
                style={{
                  marginTop: "1rem",
                  // gap: "0.5rem"
                }}
                className="select_deselect"
              >
                <button
                  className="select"
                  onClick={() => {
                    setSelectedLeadIds(resultLeads.map((l) => l.id));
                  }}
                >
                  Select All
                </button>
                <button
                  className="deselect"
                  onClick={() => {
                    setSelectedLeadIds([]);
                  }}
                >
                  Deselect All
                </button>
              </div>

              {/* <ContextMenu
                anchorEl={anchorEl}
                handleClose={() => {
                  setAnchorEl(null);
                }}
                setAnchorEl={setAnchorEl}
                menuItems={exportMenuItems}
              /> */}

              {/* <DigitalToolBelt
                open={openToolBelt}
                setOpen={setOpenToolBelt}
                lead={selectedLead}
              />
              <CustomerInfo
                lead={selectedLead}
                notes={selectedLead?.notes}
                customer={selectedLead?.fidiumCustomer}
                open={openCustomerInfo}
                setOpen={setOpenCustomerInfo}
              /> */}
              {resultLeads.map((lead, index) => (
                <LeadRecruitCard
                  key={index}
                  lead={lead}
                  totalLeads={totalCount}
                  index={index}
                  lastCard={() => {}}
                  setOpenCustomerInfo={() => {}}
                  setOpenToolBelt={() => {}}
                  setSelectedLeads={setSelectedLeadIds}
                  icons={iconOptions}
                  isSelected={selectedLeadIds.includes(lead.id)}
                  setSelectedLead={setSelectedLead}
                />
              ))}
              {(loading || !init) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    padding: "3rem",
                  }}
                >
                  <VersatileLoader size="large" color="#2761CF" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactCenterRecruitLeads;

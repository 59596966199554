function ListBlock({ data }) {
  const { style, items } = JSON.parse(data);
  const ListTag = style === "ordered" ? "ol" : "ul";
  
  if (!items || items.length === 0) {
      return null;
  }

  return (
    <ListTag className="list-inside list-disc my-4">
      {items.map((item, index) => (
        <li key={index}>{item.content}</li>
      ))}
    </ListTag>
  );
}

export default ListBlock;
import { createContext, useEffect, useState } from "react";
import ContactCenterRecruitLeads from "../ContactCenterRecruitLeads";
import ContactCenter from "../ContactCenter";
import clsx from "clsx";
import { useMode } from "../../../Layouts/ModeContext";

const contactCenterToggleOptions = ["leads", "candidates"];

export const ContactCenterWrapperContext = createContext({
  ToggleComponent: () => <></>,
});

export default function ContactCenterWrapper() {
  const [toggle, setToggle] = useState(contactCenterToggleOptions[0]);


  const {mode, setMode} = useMode();


  useEffect(
    () => {
      if(mode === "RecruitMode") setToggle(contactCenterToggleOptions[1]);
      else setToggle(contactCenterToggleOptions[0]);
    },[mode]
  )

  //   .sales-report-container .filter-container .toggle-switch input {
  //     width: 50px;
  //     height: 25px;
  //     appearance: none;
  //     background-color: #c6c6c6;
  //     outline: none;
  //     border-radius: 50px;
  //     color: #002A8D;
  //     position: relative;
  //     cursor: pointer;
  //     transition: background-color 0.3s ease;
  // }

  //   .sales-report-container .filter-container .toggle-switch input::before {
  //     content: "";
  //     position: absolute;
  //     width: 23px;
  //     height: 23px;
  //     background-color: white;
  //     border-radius: 50%;
  //     top: 1px;
  //     left: 1px;
  //     transition: transform 0.3s ease;
  // }

  const ToggleComponent = () => (
    <div className="flex w-full justify-end items-center p-1">
      {/* <label className="capitalize">{"Viewing " + toggle}</label>
      <input
        className={clsx(
          "transition-all duration-300 ease-in appearance-none w-[50px] h-[25px] bg-neutral-300 outline-none rounded-[50px] text-indigo-900 relative cursor-pointer checked:bg-indigo-900",
          "before:content-[''] before:transition-all before:duration-300 before:ease-in before:absolute before:w-[25px] before:h-[25px] before:bg-white before:rounded-full before:top-0 before:left-0 before:checked:translate-x-[25px]"
        )}
        type="checkbox"
        value={toggle}
        checked={toggle === contactCenterToggleOptions[1]}
        onChange={() =>
          setToggle((prev) =>
            prev === contactCenterToggleOptions[0]
              ? contactCenterToggleOptions[1]
              : contactCenterToggleOptions[0]
          )
        }
      /> */}
    </div>
  );

  return (
    <ContactCenterWrapperContext.Provider
      value={{
        ToggleComponent,
      }}
    >
      {toggle === "candidates" ? (
        <ContactCenterRecruitLeads />
      ) : (
        <ContactCenter />
      )}
    </ContactCenterWrapperContext.Provider>
  );
}

class LightAcceptOfferFromAffiliateCodeReq {
  constructor(
    affiliateCodeId,
    fiberPlanId,
    firstName,
    lastName,
    email,
    phone,
    street,
    city,
    state,
    zipcode
  ) {
    this.affiliateCodeId = affiliateCodeId;
    this.fiberPlanId = fiberPlanId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.street = street;
    this.city = city;
    this.state = state;
    this.zipcode = zipcode;
  }
}

export default LightAcceptOfferFromAffiliateCodeReq;

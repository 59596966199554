import { FaEllipsisH, FaPencilAlt, FaTrash, FaEye, FaArchive } from "react-icons/fa";

export function PostActions({
  blog,
  onEdit,
  onDelete,
  isOpen,
  setSelected,
  onPublish,
  onArchive,
}) {
  return (
    <div className="relative">
      <button
        onClick={() => (isOpen ? setSelected(null) : setSelected(blog.id))}
        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-gray-300 bg-white hover:bg-gray-100 h-10 w-10 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-800"
      >
        <FaEllipsisH className="h-4 w-4" color="#797878" />
        <span className="sr-only">Open menu</span>
      </button>
      {isOpen && (
        <div
          className="absolute right-[70%] mt-2 w-[10rem] rounded-md shadow-lg bg-white dark:bg-gray-800 z-50"
          onMouseLeave={() => isOpen && setSelected(null)}
        >
          <div className="py-1">
            <button
              onClick={() => {
                onEdit();
              }}
              className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
            >
              <FaPencilAlt className="mr-2 h-4 w-4" /> Edit
            </button>
            {blog.status !== "published" && (
              <button
                onClick={() => onPublish(blog.id)}
                className="flex w-full items-center px-4 py-2 text-sm  text-gray-700  dark:hover:bg-gray-700"
              >
                <FaEye className="mr-2 h-4 w-4" /> Publish
              </button>
            )}
            {blog.status !== "archived" && (
              <button
                onClick={() => onArchive(blog.id)}
                className="flex w-full items-center px-4 py-2 text-sm text-gray-700 dark:hover:bg-gray-700"
              >
                <FaArchive className="mr-2 h-4 w-4" /> Archive
              </button>
            )}
            <button
              onClick={() => onDelete(blog.id)}
              className="flex w-full items-center px-4 py-2 text-sm text-gray-700 dark:hover:bg-gray-700"
            >
              <FaTrash className="mr-2 h-4 w-4" /> Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

import { useState, useEffect } from "react";
import { getAllTags } from "../api/tagsAPI";
import FilteredDataList from "./FilteredDataList";
import Swal from "sweetalert2";

const SelectionMenu = ({
  buttonLabel = "Associate",
  onButtonClick,
  onChange,
  tags,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  // const [tags, setTags] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // useEffect(() => {
  //   const loadTags = async () => {
  //     const tags = await getAllTags();
  //     setTags(tags.data.items);
  //     setFilteredData(tags.data.items);
  //     console.log("Tags successfully fetched", tags.data.items);
  //   };
  //   loadTags();
  // }, []);

  useEffect(() => {
    const lowerQuery = searchQuery.toLowerCase();
    setFilteredData(
      tags?.filter(
        (item) =>
          item?.name?.toLowerCase().includes(lowerQuery) &&
          !selectedItems?.find((selected) => selected.id === item.id)
      )
    );
  }, [searchQuery, selectedItems, tags]);

  const handleSelectItem = (item) => {
    console.log({ item });
    setSelectedItems((prev) => [...prev, item]);
    setSearchQuery("");
  };

  const handleRemoveItem = (itemId) => {
    console.log({ itemId });
    setSelectedItems((prev) => prev.filter((item) => item.id !== itemId));
  };

  useEffect(() => {
    if (onChange) onChange(selectedItems);
  }, [selectedItems]);

  return (
    <div className="py-3 w-full  flex gap-3">
      <div className="flex flex-wrap items-center px-1 py-[3px] gap-2 border  rounded-lg w-full relative">
        {selectedItems?.map((item) => (
          <div
            key={item.id}
            className="border px-2 py-1 rounded-lg flex items-center space-x-1"
          >
            <span>{item.name}</span>
            <button
              onClick={() => handleRemoveItem(item.id)}
              className="text-[#897979] font-bold"
            >
              X
            </button>
          </div>
        ))}
        {/* <input 
          
               
          type="text"
          placeholder="Search by tag name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-1 outline-none px-2 py-1 focus:outline-none placeholder:text-sm"
          onFocus={() => setShow((prev) => !prev)}
          onBlur={() => setTimeout(() => setShow(false), 200)}
        /> */}
        <input
          type="text"
          placeholder="Search by tag name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-1 outline-none px-2 py-1 focus:outline-none placeholder:text-sm min-w-0 w-full mobile:flex-[0_0_100%] mobile:order-1"
          onFocus={() => setShow((prev) => !prev)}
          onBlur={() => setTimeout(() => setShow(false), 200)}
          
        />

        {show && (
          <FilteredDataList data={filteredData} onSelect={handleSelectItem} />
        )}
      </div>

      {onButtonClick && (
        <button
          className=" bg-[#181e4b] text-white rounded-md w-fit px-3"
          onClick={() => {
            onButtonClick(selectedItems);
            setSelectedItems([]);
          }}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default SelectionMenu;

import React, { useContext } from "react";
import greyUser from "../../../assets/images/userGrey.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserContext } from "../../../App";
import { Rating } from "@mui/material";
import {
  MdOutlineDirections,
  MdOutlineMail,
  MdOutlineMessage,
  MdOutlinePhone,
} from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { IoMapOutline } from "react-icons/io5";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import ContactButtons from "../../../Services/ContactButtons";
import StringIntReq from "../../../Requests/StringIntReq";
import CustomerApi from "../../../API/CustomerApi";
import { IoIosCloseCircle } from "react-icons/io";
import FiberHouseApi from "../../../API/FiberHouseApi";
import DigitalActionBar from "../../../components/DigitalActionBar";
const LeadCard = ({
  lead,
  includeInvalidCustomers,
  setTotalItems,
  totalLeads,
  index,
  lastCard,
  setSelectedLeads,
  mapIcons,
  fiberCompaniesForDisplay,
  setSelectedLead,
  setLeads,
  setOpenCustomerInfo,
  setOpenToolBelt,
}) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const contactButtons = new ContactButtons(user);

  const getImage = (lead) => {
    if (lead.fidiumCustomer != null) {
      if (lead.fidiumCustomer.user?.profileImage != null) {
        return (
          <img src={lead.fidiumCustomer.user.profileImage.url} alt="User" />
        );
      } else {
        return <img src={greyUser} alt="User" />;
      }
    } else {
      return <img src={greyUser} alt="User" />;
    }
  };

  const getMapIcon = (lead) => {
    if (lead.fiberHouse != null) {
      if (lead.fiberHouse.mapIconId != null) {
        return (
          <img
            src={
              "data:image/png;base64," +
              mapIcons.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === lead.fiberHouse.mapIconId
              )?.image
            }
            alt="Map Icon"
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getInstallDateTime = (lead) => {
    let data = "Install : ";
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.installDateTime != null
    ) {
      const installDateTime = new Date(lead.fidiumCustomer.installDateTime);
      const installDate = installDateTime.toLocaleDateString();
      const installTime = installDateTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      data += installDate;
      if (installTime !== "12:00 AM" && installTime !== "00:00") {
        data += " | " + installTime;
      }
    }
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.originalIntstallDateTimeTo
    ) {
      let timeTo = lead.fidiumCustomer.updatedInstallTimeTo
        ? lead.fidiumCustomer.updatedInstallTimeTo
        : lead.fidiumCustomer.originalIntstallDateTimeTo;
      const installDateTimeTo = new Date(timeTo);
      const installTimeTo = installDateTimeTo.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      if (installTimeTo !== "12:00:00 AM" && installTimeTo !== "00:00:00") {
        data += "-" + installTimeTo;
      }
    }
    return data;
  };

  const getSoldDateTime = (lead) => {
    let data = "Sold : ";
    if (lead.fidiumCustomer != null && lead.fidiumCustomer.timeStamp != null) {
      const soldDateTime = new Date(lead.fidiumCustomer.timeStamp);
      const soldDate = soldDateTime.toLocaleDateString();
      const soldTime = soldDateTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      data += soldDate;
      if (soldTime !== "12:00 AM" && soldTime !== "00:00") {
        data += " | " + soldTime;
      }
    }
    return data;
  };

  const getCancelReason = (lead) => {
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.cancelationForms != null &&
      lead.fidiumCustomer.cancelationForms.length > 0
    ) {
      let str = "Cancel Reason : ";
      let cancelForm = lead.fidiumCustomer.cancelationForms[0];

      if (cancelForm.payLess) {
        str += "Pay Less";
        str += " | " + cancelForm.payLessSubAnswer;
      } else if (cancelForm.bundledService) {
        str += "Bundled Service";
        str += " | " + cancelForm.bundledServiceSubAnswer;
      } else if (cancelForm.inContract) {
        str += "In Contract";
        str += " | " + cancelForm.inContractSubAnswer;
      } else if (cancelForm.other) {
        str += "Other";
        str += " | " + cancelForm.otherSubAnswer;
      } else if (cancelForm.spouse) {
        str +=
          "Spouse or Another Decision Maker Doesn’t Want to Switch Providers.";
      } else if (cancelForm.movingSoon) {
        str += "Moving Soon";
      } else if (cancelForm.tooBusy) {
        str += "Too Busy";
      } else {
        str += "No Data Submitted at this time";
      }

      return str;
    }
  };

  const getName = (lead) => {
    if (lead.fidiumCustomer != null) {
      return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
    } else if (lead.name != null) {
      return lead.name;
    } else {
      return "No Name";
    }
  };

  const getSalesRepName = (lead) => {
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.salesRep != null &&
      (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin")
    ) {
      return (
        " | " +
        lead.fidiumCustomer.salesRep.firstName +
        " " +
        lead.fidiumCustomer.salesRep.lastName[0]
      );
    } else if (lead?.offer?.salesRep) {
      return (
        " | " +
        lead.offer.salesRep.firstName +
        " " +
        lead.offer.salesRep.lastName[0]
      );
    }
  };

  const getAppointmentDateTime = (lead) => {
    let data = "Follow-Up Scheduled : ";
    if (lead.appointmentDateTime != null) {
      const appointmentDateTime = new Date(lead.appointmentDateTime);
      const appointmentDate = appointmentDateTime.toLocaleDateString();
      const appointmentTime = appointmentDateTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      data += appointmentDate;
      if (appointmentTime !== "12:00 AM" && appointmentTime !== "00:00") {
        data += " | " + appointmentTime;
      }
    }

    return data;
  };

  const getFiberCompany = (lead) => {
    let name = "";
    if (lead?.fidiumCustomer != null && fiberCompaniesForDisplay) {
      name = fiberCompaniesForDisplay?.filter(
        (f) => f.id === lead?.fidiumCustomer?.fiberCompanyId
      )[0]?.name;
    } else if (lead?.fiberCompanyId && fiberCompaniesForDisplay) {
      name = fiberCompaniesForDisplay?.filter(
        (f) => f.id === lead.fiberCompanyId
      )[0]?.name;
    } else if (lead?.offer?.fiberCompanyId && fiberCompaniesForDisplay) {
      name = fiberCompaniesForDisplay?.filter(
        (f) => f.id === lead?.offer?.fiberCompanyId
      )[0]?.name;
    }
    if (lead.fidiumCustomer?.fiberPlan?.name) {
      name += " <br> " + lead?.fidiumCustomer?.fiberPlan?.name;
    } else if (lead.offer?.fiberPlan?.name) {
      name += " <br> " + lead?.offer?.fiberPlan?.name;
    }
    return name;
  };

  const getAcceptedDate = (lead) => {
    let str = "Offer Accepted : ";
    if (lead.offer?.accepted) {
      str += new Date(lead?.offer?.acceptedAt).toLocaleDateString();
      str +=
        " | " +
        new Date(lead?.offer?.acceptedAt).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
    }
    return str;
  };

  const getAddress = (lead) => {
    if (lead.fiberHouse?.mapString) {
      return lead.fiberHouse?.mapString;
    } else if (lead.fiberHouse?.address) {
      return (
        lead.fiberHouse?.address?.street +
        " " +
        lead.fiberHouse?.address?.city +
        ", " +
        lead.fiberHouse?.address?.state +
        " " +
        lead.fiberHouse?.address?.zip +
        ", " +
        lead.fiberHouse?.address?.country
      );
    } else {
      return "No Address";
    }
  };

  const openMap = (lead) => {
    if (lead.fiberHouse && lead.fiberHouse.coordinates) {
      navigate("/map", {
        state: {
          fiberHouse: {
            ...lead.fiberHouse,
            leadId: lead.id,
            lead: {
              ...lead,
              fiberHouse: null,
            },
          },
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "No Coordinates",
        text: "This lead does not have any coordinates",
      });
    }
  };

  const markCustomerAsInvalid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Invalid",
      text: "Are you sure you want to mark this customer as invalid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      text: "Marking Customer As Invalid",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsInvalid(req);
    if (res?.status?.success) {
      setLeads((prev) => {
        if (!includeInvalidCustomers)
          return prev.filter((l) => l.id !== lead.id);
        else {
          return prev.map((l) => {
            if (l.id === lead.id) {
              l.fidiumCustomer.isInvalid = true;
            }
            return l;
          });
        }
      });

      Swal.fire({
        icon: "success",
        title: "Customer Marked As Invalid",
        text: "The customer has been marked as invalid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const markCustomerAsValid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Valid",
      text: "Are you sure you want to mark this customer as valid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      icon: "info",
      text: "Marking Customer As Valid",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsValid(req);
    if (res?.status?.success) {
      setLeads((prev) => {
        return prev.map((l) => {
          if (l.id === lead.id) {
            l.fidiumCustomer.isInvalid = false;
          }
          return l;
        });
      });

      Swal.fire({
        icon: "success",
        title: "Customer Marked As Valid",
        text: "The customer has been marked as valid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const removeProspect = async (leadId) => {
    let option = await Swal.fire({
      icon: "warning",
      title: "Remove Prospect",
      text: "Are you sure you want to remove this prospect?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!option.isConfirmed) {
      return;
    }

    Swal.fire({
      title: "Please Wait",
      text: "Removing Prospect",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = leadId;
    const res = await FiberHouseApi.RemoveProspect(req);

    if (res?.status?.success) {
      setTotalItems((prev) => prev - 1);
      setLeads((prev) => {
        return prev.filter((l) => l.id !== leadId);
      });
      Swal.fire({
        icon: "success",
        title: "Prospect Removed",
        text: "The prospect has been removed",
        onOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  return (
    <React.Fragment key={index}>
      <div
        key={index}
        ref={totalLeads - 1 === index ? lastCard : null}
        className="LeadCard"
      >
        <div className="ImagesDiv">
          <input
            className="select"
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedLeads((prev) => {
                  return [...prev, lead.id];
                });
              } else {
                setSelectedLeads((prev) => prev.filter((l) => l !== lead.id));
              }
            }}
            type="checkbox"
          />
          <div className="Image">{getImage(lead)}</div>
          <div className="MapIcon">{getMapIcon(lead)}</div>
        </div>
        <div className="DataDiv">
          <div
            className="Name"
            style={{ cursor: "pointer" }}
            onClick={() => {
              openMap(lead);
            }}
          >
            <h3>
              {getName(lead)}{" "}
              <span
                style={{
                  fontSize: "0.8rem",
                  color: "#2761D0",
                }}
              >
                {getSalesRepName(lead)}
              </span>
            </h3>
          </div>
          <div className="phone">
            <p dangerouslySetInnerHTML={{ __html: getFiberCompany(lead) }} />
          </div>
          <div
            className="Address"
            style={{ cursor: "pointer" }}
            onClick={() => {
              openMap(lead);
            }}
          >
            <p title={getAddress(lead)}>{getAddress(lead)}</p>
          </div>
          {/* <div className='Phone'>
                <p>{getPhone(lead)}</p>
            </div> */}
                    <div className='additional_info'>
                        {
                            (lead.fidiumCustomer !== null) &&
                            <p>Account Status : {lead?.fidiumCustomer?.accountStatus}</p>
                        }
                        {
                            lead.offer &&
                            <p>Offer Sent : {new Date(lead?.offer?.timeStamp).toLocaleDateString(
                                "en-US",
                                {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                }
                            )
                            }</p>
                        }
                        {
                            lead.offer && lead.offer.openedAt &&
                            <p>Offer Viewed :
                                {new Date(lead?.offer?.openedAt).toLocaleDateString(
                                    "en-US",
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    }
                                )
                                }
                            </p>
                        }
                        {
                            lead.offer && lead.offer.accepted &&
                            <p>{getAcceptedDate(lead)}</p>
                        }
                        {
                            lead.appointmentDateTime != null &&
                            <p>{getAppointmentDateTime(lead)}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.timeStamp != null &&
                            <p>{getSoldDateTime(lead)}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.installDateTime != null &&
                            <p>{getInstallDateTime(lead)}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.companyNotes != null &&
                            <p>Company Notes : {lead.fidiumCustomer.companyNotes}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.rescheduleForms !== null && lead.fidiumCustomer.rescheduleForms.length > 0 &&
                            lead.fidiumCustomer.rescheduleForms[0].isResolved !== true &&
                            <p>
                                Reschedule Request : {
                                    new Date(lead.fidiumCustomer.rescheduleForms[0].timestamp).toLocaleDateString(
                                        "en-US",
                                        {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        }
                                    )
                                }
                            </p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.rescheduleForms !== null && lead.fidiumCustomer.rescheduleForms.length > 0 &&
                            lead.fidiumCustomer.rescheduleForms[0].isResolved !== true &&
                            <p>Rescheduled : {
                                lead.fidiumCustomer.rescheduleForms[0].newInstallDate ?
                                    new Date(lead.fidiumCustomer.rescheduleForms[0].newInstallDate).toLocaleDateString() +
                                    "|" + new Date(lead.fidiumCustomer.rescheduleForms[0].newInstallDate).toLocaleTimeString() + "-" +
                                    new Date(lead.fidiumCustomer.rescheduleForms[0].newInstallDateTo).toLocaleTimeString()
                                    : "Form Not Submitted Only Text"
                            }</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.cancelationForms !== null && lead.fidiumCustomer.cancelationForms.length > 0 &&
                            lead.fidiumCustomer.cancelationForms[0].isResolved !== true &&
                            <p>
                                Cancel Request : {
                                    new Date(lead.fidiumCustomer.cancelationForms[0].timestamp).toLocaleDateString(
                                        "en-US",
                                        {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        }
                                    )
                                }
                            </p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.accountStatus === "Canceled" && lead.fidiumCustomer.cancellationTime !== null &&
                            <p>Canceled : {new Date(lead.fidiumCustomer.cancellationTime).toLocaleDateString()}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.cancelationForms !== null && lead.fidiumCustomer.cancelationForms.length > 0 &&
                            lead.fidiumCustomer.cancelationForms[0].isResolved !== true &&
                            <p>{getCancelReason(lead)}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.accountStatus === "Completed" && lead.fidiumCustomer.closedDt !== null &&
                            <p>Completed : {new Date(lead.fidiumCustomer.closedDt).toLocaleDateString()}</p>
                        }
                        {
                            lead.fidiumCustomer !== null && lead.fidiumCustomer.feedbacks != null && lead.fidiumCustomer.feedbacks.length > 0 &&
                            <p style={{ display: "flex", alignItems: "center" }}>Rating :<Rating value={lead.fidiumCustomer.feedbacks[0].rating} readOnly /></p>
                        }

                    </div>
                </div>
                <div className='dbt_1' style={{
                    // display:"flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    flexDirection: "column"
                }}>

                    {/* <div className='dbt_1' onClick={() => {
                        setSelectedLead(lead); setOpenToolBelt(true)
                    }
                    } style={{
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "2rem",
                        height: "2rem",

                    }}>
                        <img src={'/images/digital_tool_belt/digital_belt.png'} alt='Digital Tool Belt' />
                    </div>
                    */}
          {lead.fidiumCustomer != null && (
            <div
              className="dbt_1"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "2rem",
                height: "2rem",
              }}
              onClick={() => {
                setSelectedLead(lead);
                setOpenCustomerInfo(true);
              }}
            >
              <img
                src={"/images/digital_tool_belt/customerInfo.svg"}
                alt="info"
              />
            </div>
          )}
          {lead.fidiumCustomer === null && lead.formData && (
            <div
              className="dbt_1"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "2rem",
                height: "2rem",
              }}
              onClick={() => {
                setSelectedLead(lead);
                setOpenCustomerInfo(true);
              }}
            >
              <img
                src={"/images/digital_tool_belt/customerInfo.svg"}
                alt="info"
              />
            </div>
          )}
        </div>

        <div className="MainLeadCard_Buttons flex flex-wrap gap-2">
          {/* <div className="MainLeadCard_Button" onClick={() => { lightningMessage(lead) }}>
            <TbMessage2Bolt color='#81848A' size={30} />
        </div> */}

          <div className="w-full flex justify-end mb-2">
            <DigitalActionBar
              onReload={(lead) => {
                setLeads((prev) => {
                  return prev.map((l) => {
                    if (l.id === lead.id) {
                      return lead;
                    }
                    return l;
                  });
                });
              }}
              lead={lead}
            />
          </div>

          {lead.fidiumCustomer != null && (
            <div
              className="dbt_2"
              style={{
                width: "2rem",
                height: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedLead(lead);
                setOpenCustomerInfo(true);
              }}
            >
              <img
                src={"/images/digital_tool_belt/customerInfo.svg"}
                alt="info"
              />
            </div>
          )}
          {lead.fidiumCustomer === null && lead.formData && (
            <div
              className="dbt_2"
              style={{
                width: "2rem",
                height: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedLead(lead);
                setOpenCustomerInfo(true);
              }}
            >
              <img
                src={"/images/digital_tool_belt/customerInfo.svg"}
                alt="info"
              />
            </div>
          )}
          {/* <div className="dbt_2" style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: 'pointer'
                    }} onClick={() => { setSelectedLead(lead); setOpenToolBelt(true) }} >
                        <img src={'/images/digital_tool_belt/digital_belt.png'} alt='Digital Tool Belt' />
                    </div>*/}
          {user.userType === "SuperAdmin" &&
            (lead.fidiumCustomer != null || lead.offer != null) && (
              <div
                className="MainLeadCard_Button"
                onClick={() => {
                  let crmId = lead?.fidiumCustomer?.crmId
                    ? lead?.fidiumCustomer?.crmId
                    : lead.offer.crmId;
                  window.open(
                    `https://app.gohighlevel.com/v2/location/2bpuwFoxpdxZs2Oq2Z8a/contacts/detail/${crmId}`
                  );
                }}
              >
                <img
                  src="/highlevelLogo.png"
                  alt="HighLevel"
                  color="#81848A"
                  style={{
                    width: "30px",
                  }}
                />
              </div>
            )}
          {user.userType === "SuperAdmin" &&
            lead.fidiumCustomer != null &&
            (lead.fidiumCustomer.isInvalid === true ? (
              <div
                onClick={() => {
                  markCustomerAsValid(lead);
                }}
                className="MainLeadCard_Button"
              >
                <IoIosAddCircle color="#008000" size={30} />
              </div>
            ) : (
              <div
                onClick={() => {
                  markCustomerAsInvalid(lead);
                }}
                className="MainLeadCard_Button"
              >
                <IoIosRemoveCircle color="#000000" size={30} />
              </div>
            ))}
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.emailLead(lead)}
          >
            <MdOutlineMail color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.whatsappLead(lead)}
          >
            <FaWhatsapp color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.smsLead(lead)}
          >
            <MdOutlineMessage color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.callLead(lead)}
          >
            <MdOutlinePhone color="#81848A" size={30} />
          </div>
          <div
            className="MainLeadCard_Button"
            onClick={() => contactButtons.directionsLead(lead)}
          >
            <MdOutlineDirections color="#81848A" size={30} />
          </div>
          <div
            onClick={() => {
              openMap(lead);
            }}
            className="MainLeadCard_Button"
          >
            <IoMapOutline color="#81848A" size={30} />
          </div>

          {lead?.offer != null && lead?.fidiumCustomer === null && (
            <div
              className="MainLeadCard_Button"
              onClick={() => {
                removeProspect(lead.id);
              }}
            >
              <IoIosCloseCircle color="#FF0000" size={30} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadCard;

import { useContext, useEffect, useState } from "react";
import { Search } from "lucide-react";
import SearchLeadsForContactCenterReq from "../../../../Requests/Customer/SearchLeadsForContactCenterReq";
import MapIconApi from "../../../../API/MapIconApi";
import { Pagination, PaginationItem } from "@mui/material";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import DigitalActionBar from "../../../../components/DigitalActionBar";
import Map from "./Map";
import VersatileLoader from "../../../../components/VersatileLoader";
import { UserContext } from "../../../../App";
import UsersApi from "../../../../API/UsersApi";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import bot from "../../../TeamAdmin/MapPage/Images/bot.png";
import InvitationModal from "../../../TeamAdmin/MapPage/components/CandidateMainLeadCard/InviteForm";
import Swal from "sweetalert2";

export default function ContactList({
  selectedContacts,
  setSelectedContacts,
  setSelectedAction,
  view,
  setView,
  mode,
  setMode,
  filterFiberCompanyId,
}) {
  const [type, setType] = useState("highlighted");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setEndDatetalPages] = useState(1);
  const [leads, setLeads] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [leadType, setLeadType] = useState("Customer");
  const [cLeadType, setCLeadType] = useState("Recruit");
  const [cStatus, setCStatus] = useState("New");
  const [industries, setIndustries] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [positionTitles, setPositionTitles] = useState([]);
  const [cDateType, setCDateType] = useState("Created Date");
  const [candidates, setCandidates] = useState([]);
  const [modeSelected, setModeSelected] = useState("email");
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [
    { industryOptions, companyOptions, positionTitleOptions, iconOptions },
    setFetchedOptions,
  ] = useState({
    industryOptions: [],
    companyOptions: [],
    positionTitleOptions: [],
    iconOptions: [],
  });
  const [accountStatus, setAccountStatus] = useState("Sold");
  const [quickDate, setQuickDate] = useState("All Time");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const [fromDateDisplay, setFromDateDisplay] = useState(
    startDate ? startDate.split("T")[0] : ""
  );
  const [toDateDisplay, setToDateDisplay] = useState(
    endDate ? endDate.split("T")[0] : ""
  );
  const [dateType, setDateType] = useState("Date Sold");
  const [action, setAction] = useState("");
  const [customMessage, setCustomMessage] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [mapIcons, setMapIcons] = useState(null);
  const [selectedFiberHouseIds, setSelectedFiberHouseIds] = useState([]);
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);

  const fetchMapIcons = async () => {
    const res = await MapIconApi.GetAllMapIcons();
    if (res?.status?.success) {
      setMapIcons(res.data);
    }
  };

  useEffect(() => {
    setSelectedContacts([]);
  }, [pageSize]);

  useEffect(() => {
    fetchMapIcons();
  }, []);

  const renderSelect = (label, value, options, setter) => (
    <div className="flex flex-col items-start gap-1 relative">
      <select
        value={value}
        onChange={(e) => setter(e.target.value)}
        className="py-1 w-28"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <span className="text-sm font-medium text-blue-600">{label}</span>
    </div>
  );

  const handleCheckboxChange = (contact) => {
    setSelectedContacts((prev) =>
      prev.some((c) => c.id === contact.id)
        ? prev.filter((c) => c.id !== contact.id)
        : [...prev, contact]
    );
  };

  const handleSelectAll = (items) => {
    if (selectedContacts.length === items.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(items);
    }
  };

  const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const fetchLeads = async () => {
    setLoading(true);
    const req = new SearchLeadsForContactCenterReq();
    req.PagingParams.PageNumber = currentPage;
    req.PagingParams.PageSize = pageSize;
    req.SearchString = searchString;
    req.CustomersOnly =
      leadType === "Customer" ? true : leadType === "All" ? null : false;
    let tempStatus;
    switch (accountStatus) {
      case "Offers Sent":
        tempStatus = "OffersSent";
        break;
      case "Offers Accepted":
        tempStatus = "OffersAccepted";
        break;
      default:
        tempStatus = accountStatus;
    }

    req.CustomerStatus = tempStatus;
    req.CustomerType = leadType === "KnockedDoors" ? "All" : leadType;

    switch (dateType) {
      case "Last Updated":
        if (startDate) {
          req.startDate = startDate;
        }
        if (endDate) {
          req.endDate = endDate;
        }
        break;
      case "Install Date":
        if (startDate) {
          req.installDateFrom = startDate;
        }
        if (endDate) {
          req.installDateTo = endDate;
        }
        break;
      case "Date Sold":
        if (startDate) {
          req.timeStampFrom = startDate;
        }
        if (endDate) {
          req.timeStampTo = endDate;
        }
        break;
      case "Appointment Date":
        if (startDate) {
          req.appointmentTimeFrom = startDate;
        }
        if (endDate) {
          req.appointmentTimeTo = endDate;
        }
        break;
      case "Cancel Request Time":
        if (startDate) {
          req.cancelRequestTimeFrom = startDate;
        }
        if (endDate) {
          req.cancelRequestTimeTo = endDate;
        }
        break;
      case "Reschedule Request Time":
        if (startDate) {
          req.rescheduleRequestTimeFrom = startDate;
        }
        if (endDate) {
          req.rescheduleRequestTimeTo = endDate;
        }
        break;

      case "Knocked Date":
        if (startDate) {
          req.knockedDateFrom = startDate;
        }
        if (endDate) {
          req.knockedDateTo = endDate;
        }
        break;
      case "Offer Sent Time":
        if (startDate) {
          req.offerTimeFrom = startDate;
        }
        if (endDate) {
          req.offerTimeTo = endDate;
        }
        break;
      case "Offer Accepted Time":
        if (startDate) {
          req.offerAcceptedTimeFrom = startDate;
        }
        if (endDate) {
          req.offerAcceptedTimeTo = endDate;
        }
        break;
      case "Paid Date":
        if (startDate) {
          req.paidDateFrom = startDate;
        }
        if (endDate) {
          req.paidDateTo = endDate;
        }
        break;
      case "Cancel Time":
        if (startDate) {
          req.cancelTimeFrom = startDate;
        }
        if (endDate) {
          req.cancelTimeTo = endDate;
        }
        break;
      default:
        break;
    }

    if (user.userType === "SalesRep" || user.userType === "SalesOrgAdmin") {
      req.SalesOrgId = user?.salesOrgId;
    }
    if (user.userType === "SalesOrgAdmin") {
      req.TeamAdminId = user.id;
    } else if (user.userType === "SalesRep") {
      req.SalesRepId = user.id;
    }

    if (filterFiberCompanyId) {
      console.log("filterFiberCompanyId", filterFiberCompanyId);
      req.FiberCompanyIds = [filterFiberCompanyId];
    }

    const res = await MapIconApi.SearchLeadsForContactCenter(req);
    if (res?.status?.success) {
      setLeads(res.data.list);
      setEndDatetalPages(res.data.totalPages);
      setLoading(false);
    }
  };

  const fetchCandidates = async () => {
    setLoading(true);
    let req = {
      ids: null,
      country: null,
      state: null,
      name: null,
      email: null,
      SearchString: searchString,
      leadType: [cLeadType],
      status: "New",
      pageNumber: currentPage,
      pageSize: pageSize,
      createdFrom: null,
      createdTo: null,
      appointmentFrom: null,
      appointmentTo: null,
      lastUpdatedFrom: null,
      lastUpdatedTo: null,
      industries: industries.length === 0 ? null : [industries],
      companies: companies.length === 0 ? null : companies?.map((c) => c),
      positionTitles:
        positionTitles.length === 0 ? null : positionTitles?.map((p) => p),
    };
    switch (cStatus) {
      case "New":
        req.status = "New";
        break;
      case "New Candidate":
        req.status = "New Candidate";
        break;
      case "Job Ad Sent":
        req.status = "Invitation Sent";
        break;
      case "Applied":
        req.status = "Applied";
        break;
      case "Interview Link Sent":
        req.status = "1st Interview Sent";
        break;
      case "Interview Scheduled":
        req.status = "1st Interview Scheduled";
        break;
      case "2nd Interview Link Sent":
        req.status = "2nd Interview Sent";
        break;
      case "2nd Interview Scheduled":
        req.status = "2nd Interview Scheduled";
        break;
      case "Hired":
        req.status = "Hired";
        break;
      case "Rejected":
        req.status = "Rejected";
        break;
      default:
        break;
    }
    switch (cDateType) {
      case "Created Date":
        if (startDate) {
          req.createdFrom = startDate;
        }
        if (endDate) {
          req.createdTo = endDate;
        }
        break;
      case "Appointment Date":
        if (startDate) {
          req.appointmentFrom = startDate;
        }
        if (endDate) {
          req.appointmentTo = endDate;
        }
        break;
      case "Last Updated Date":
        if (startDate) {
          req.lastUpdatedFrom = startDate;
        }
        if (endDate) {
          req.lastUpdatedTo = endDate;
        }
        break;
      default:
        break;
    }

    const res = await UsersApi.getCandidatesFull(req);
    if (res?.status?.success) {
      setCandidates(res.data.list);
      setEndDatetalPages(res.data.totalPages);
      setLoading(false);
    }
  };

  const fetchIndustryOptions = async () => {
    let res = await FiberHouseApi.GetCandidateIndustries();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      return sortedData;
    }
  };

  // const fetchCompanyOptions = async () => {
  //   const res = await FiberHouseApi.GetCandidateCompanies();
  //   if (res.status.success) {
  //     const sortedData = res.data.sort((a, b) =>
  //       a.label.localeCompare(b.label)
  //     );
  //     return sortedData;
  //   }
  // };

  // const fetchPositionTitleOptions = async () => {
  //   const res = await FiberHouseApi.GetCandidatePositionTitles();
  //   if (res.status.success) {
  //     const sortedData = res.data.sort((a, b) =>
  //       a.label.localeCompare(b.label)
  //     );

  //     return sortedData;
  //   }
  // };

  // SET FETCHED OPTIONS THEN SET INIT
  useEffect(() => {
    Promise.all([
      fetchIndustryOptions(),
      // fetchCompanyOptions(),
      // fetchPositionTitleOptions(),
    ]).then(
      ([
        industryOptions,
        // companyOptions,
        // positionTitleOptions,
        // iconOptions,
      ]) => {
        setFetchedOptions({
          industryOptions,
          // companyOptions,
          // positionTitleOptions,
          // iconOptions,
        });
        // setInit(true);
      }
    );
  }, []);

  useEffect(() => {
    if (mode === "Sales") {
      fetchLeads();
    } else {
      fetchCandidates();
    }
  }, [currentPage]);

  useEffect(() => {
    if (selectedContacts.length > 0) {
      setSelectedAction({ id: 0, label: "" });
      setSelectedContacts([]);
    }
    if (mode === "Sales") {
      fetchLeads();
    } else {
      fetchCandidates();
    }
  }, [mode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (mode === "Sales") {
        fetchLeads();
      } else {
        fetchCandidates();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [
    searchString,
    accountStatus,
    leadType,
    startDate,
    endDate,
    cStatus,
    cLeadType,
    industries,
    cDateType,
    pageSize,
    filterFiberCompanyId,
  ]);

  const getStatusStyles = (contact) => {
    if (mode === "Sales") {
      if (contact.fidiumCustomer != null) {
        switch (contact.fidiumCustomer.accountStatus) {
          case "Completed":
            return "bg-green-100 text-green-800";
          case "Scheduled":
            return "bg-blue-100 text-blue-800";
          case "Rescheduled":
            return "bg-blue-200 text-blue-700";
          case "Sold":
            return "bg-orange-100 text-orange-800";
          case "Canceled":
            return "bg-red-100 text-red-800";
          default:
            return "bg-gray-100 text-gray-800";
        }
      } else if (contact.offer != null && contact.fidiumCustomer == null) {
        return "bg-purple-100 text-purple-800";
      } else {
        return "bg-yellow-100 text-yellow-800";
      }
    } else {
      switch (contact?.status) {
        case "New":
          return "bg-purple-100 text-purple-800";
        case "New Candidate":
          return "bg-green-100 text-green-800";
        case "Invitation Sent":
          return "bg-blue-100 text-blue-800";
        case "Contacted":
          return "bg-yellow-200 text-yellow-700";
        case "Applicant":
          return "bg-brown-300 text-brown-600";
        case "Interview Scheduled":
          return "bg-red-400 text-red-500";
        case "Offer Extended":
          return "bg-red-500 text-red-400";
        case "Verified Rep":
          return "bg-red-600 text-red-300";
        // case "Applied":
        //   return "bg-blue-300 text-blue-600";
        // case "1st Interview Sent":
        //   return "bg-blue-400 text-blue-500";
        // case "1st Interview Scheduled":
        //   return "bg-blue-500 text-blue-400";
        // case "2nd Interview Sent":
        //   return "bg-blue-600 text-blue-300";
        // case "2nd Interview Scheduled":
        //   return "bg-blue-700 text-blue-200";
        // case "Hired":
        //   return "bg-green-100 text-green-800";
        // case "Rejected":
        //   return "bg-red-100 text-red-800";
        default:
          return "bg-gray-100 text-gray-800";
      }
    }
  };

  const getStatusText = (contact) => {
    if (mode === "Sales") {
      if (contact.fidiumCustomer != null) {
        return contact.fidiumCustomer?.accountStatus;
      } else if (contact.offer != null && contact.fidiumCustomer == null) {
        return "Hot Lead";
      } else {
        return "Lead";
      }
    } else {
      return contact.status;
    }
  };

  const handleQuickDateChange = (quickDate) => {
    switch (quickDate) {
      case "Today":
        const today = new Date();
        let todayStart = new Date(today.setHours(0, 0, 0, 0));
        let todayEnd = new Date(today.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(todayStart)); // 'en-CA' format is YYYY-MM-DD
        setEndDate(formatDateToISOString(todayEnd));

        setFromDateDisplay(todayStart.toLocaleDateString("en-CA"));
        setToDateDisplay(todayEnd.toLocaleDateString("en-CA"));
        break;
      case "Tomorrow":
        let tomorrow = new Date();
        let tomorrowStart = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        let tomorrowEnd = new Date(tomorrow.setHours(23, 59, 59, 999));
        setFromDateDisplay(tomorrowStart.toLocaleDateString("en-CA"));
        setToDateDisplay(tomorrowEnd.toLocaleDateString("en-CA"));
        setStartDate(formatDateToISOString(tomorrowStart));
        setEndDate(formatDateToISOString(tomorrowEnd));
        break;
      case "Yesterday":
        let yesterday = new Date();
        let yesterdayStart = new Date(
          new Date(yesterday.setDate(yesterday.getDate() - 1)).setHours(
            0,
            0,
            0,
            0
          )
        );
        let yesterdayEnd = new Date(yesterday.setHours(23, 59, 59, 999));
        setFromDateDisplay(yesterdayStart.toLocaleDateString("en-CA"));
        setToDateDisplay(yesterdayEnd.toLocaleDateString("en-CA"));
        setStartDate(formatDateToISOString(yesterdayStart));
        setEndDate(formatDateToISOString(yesterdayEnd));
        break;
      case "This Week":
        const currentDay = new Date();
        const firstDayOfWeek = new Date(
          currentDay.setDate(
            currentDay.getDate() - ((currentDay.getDay() + 6) % 7)
          )
        );
        const lastDayOfWeek = new Date(
          currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 7)
        );
        let from = new Date(firstDayOfWeek.setHours(0, 0, 0, 0));
        let to = new Date(lastDayOfWeek.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(from));
        setEndDate(formatDateToISOString(to));
        setFromDateDisplay(firstDayOfWeek.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfWeek.toLocaleDateString("en-CA"));
        break;
      case "Next Week":
        const nextWeekDay = new Date();
        const firstDayOfNextWeek = new Date(
          nextWeekDay.setDate(
            nextWeekDay.getDate() - ((nextWeekDay.getDay() + 6) % 7) + 7
          )
        );
        const lastDayOfNextWeek = new Date(
          nextWeekDay.setDate(nextWeekDay.getDate() - nextWeekDay.getDay() + 7)
        );
        let fromNextWeek = new Date(firstDayOfNextWeek.setHours(0, 0, 0, 0));
        let toNextWeek = new Date(lastDayOfNextWeek.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(fromNextWeek));
        setEndDate(formatDateToISOString(toNextWeek));
        setFromDateDisplay(firstDayOfNextWeek.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfNextWeek.toLocaleDateString("en-CA"));
        break;
      case "Last Week":
        const lastWeekDay = new Date();
        const firstDayOfLastWeek = new Date(
          lastWeekDay.setDate(
            lastWeekDay.getDate() - ((lastWeekDay.getDay() + 6) % 7) - 7
          )
        );
        const lastDayOfLastWeek = new Date(
          lastWeekDay.setDate(lastWeekDay.getDate() - lastWeekDay.getDay() + 7)
        );
        let fromLastWeek = new Date(firstDayOfLastWeek.setHours(0, 0, 0, 0));
        let toLastWeek = new Date(lastDayOfLastWeek.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(fromLastWeek));
        setEndDate(formatDateToISOString(toLastWeek));
        setFromDateDisplay(firstDayOfLastWeek.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfLastWeek.toLocaleDateString("en-CA"));
        break;
      case "This Month":
        const currentMonth = new Date();
        const firstDayOfMonth = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth() + 1,
          0
        );
        let fromThisMonth = new Date(firstDayOfMonth.setHours(0, 0, 0, 0));
        let toThisMonth = new Date(lastDayOfMonth.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(fromThisMonth));
        setEndDate(formatDateToISOString(toThisMonth));
        setFromDateDisplay(firstDayOfMonth.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfMonth.toLocaleDateString("en-CA"));
        break;
      case "Next Month":
        const nextMonth = new Date();
        const firstDayOfNextMonth = new Date(
          nextMonth.getFullYear(),
          nextMonth.getMonth() + 1,
          1
        );
        const lastDayOfNextMonth = new Date(
          nextMonth.getFullYear(),
          nextMonth.getMonth() + 2,
          0
        );
        let fromNextMonth = new Date(firstDayOfNextMonth.setHours(0, 0, 0, 0));
        let toNextMonth = new Date(
          lastDayOfNextMonth.setHours(23, 59, 59, 999)
        );
        setStartDate(formatDateToISOString(fromNextMonth));
        setEndDate(formatDateToISOString(toNextMonth));
        setFromDateDisplay(firstDayOfNextMonth.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfNextMonth.toLocaleDateString("en-CA"));
        break;
      case "Last Month":
        const lastMonth = new Date();
        const firstDayOfLastMonth = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          0
        );
        let fromLastMonth = new Date(firstDayOfLastMonth.setHours(0, 0, 0, 0));
        let toLastMonth = new Date(
          lastDayOfLastMonth.setHours(23, 59, 59, 999)
        );
        setStartDate(formatDateToISOString(fromLastMonth));
        setEndDate(formatDateToISOString(toLastMonth));
        setFromDateDisplay(firstDayOfLastMonth.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfLastMonth.toLocaleDateString("en-CA"));
        break;
      case "This Year":
        const currentYear = new Date();
        const firstDayOfYear = new Date(currentYear.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(currentYear.getFullYear(), 11, 31);
        let fromThisYear = new Date(firstDayOfYear.setHours(0, 0, 0, 0));
        let toThisYear = new Date(lastDayOfYear.setHours(23, 59, 59, 999));
        setStartDate(formatDateToISOString(fromThisYear));
        setEndDate(formatDateToISOString(toThisYear));
        setFromDateDisplay(firstDayOfYear.toLocaleDateString("en-CA"));
        setToDateDisplay(lastDayOfYear.toLocaleDateString("en-CA"));
        break;
      case "All Time":
        setStartDate("");
        setEndDate("");
        setFromDateDisplay("");
        setToDateDisplay("");
        break;
      default:
        // setFromDateDisplay(from?from:"");
        // setToDateDisplay(to?to:"");
        break;
    }
  };
  useEffect(() => {
    handleQuickDateChange(quickDate);
  }, [quickDate]);

  function getActions(status, candidate) {
    if (candidate.leadType === "Recruit") {
      switch (status) {
        case "New Candidate":
          return ["Send Ad", "Send Custom Text"];
        case "Contacted":
          return ["Send Ad", "Send Custom Text"];
        case "Application Received":
          return ["Schedule Interview", "Send Custom Text"];
        case "Interview Scheduled":
          return ["Send Job Offer", "Send Custom Text"];
        case "Offer Extended":
          return ["Onboarding Reminder", "Send Custom Text"];
        case "Future Consideration":
          return ["Send Ad", "Send Custom Text"];
        case "Rejected":
          return [];
        default:
          return [];
      }
    } else if (candidate.leadType === "Company") {
      switch (status) {
        case "New Candidate":
          return ["Send Consultation", "Send Custom Text"];
        case "Contacted":
          return ["Send Consultation", "Send Custom Text"];
        case "Consultation Scheduled":
          return ["Send Proposal", "Send Custom Text"];
        default:
          return [];
      }
    }
  }

  return (
    <>
      {/* <FreeInstallForm lead={leads[0]} type={offerOrOrder} open={lightOpenModal} setOpen={setLightOpenModal} /> */}
      <div className="p-6 max-w-[1400px] mx-auto">
        <InvitationModal
          candidate={selectedCandidate}
          custom={customMessage}
          mode={modeSelected}
          open={isInviteOpen}
          action={action}
          onClose={() => setIsInviteOpen(false)}
          candidateType={selectedCandidate?.leadType}
          candidateId={selectedCandidate?.id}
        />
        <div className="flex justify-end items-center mb-8">
          {/* <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Create Contact
          </button> */}
        </div>

        <div className="flex flex-wrap justify-between items-center gap-4 mb-6">
          <div className="flex items-center justify-between flex-wrap gap-4">
            {mode === "Sales" ? (
              <>
                {renderSelect("MODE", mode, ["Sales", "Recruit"], setMode)}
                {renderSelect(
                  "LEAD TYPE",
                  leadType,
                  ["Customer", "Prospect", "All Leads"],
                  setLeadType
                )}
                {leadType !== "All Leads"
                  ? renderSelect(
                      leadType === "Customer" ? "ACCOUNT STATUS" : "Status",
                      accountStatus,
                      leadType === "Customer"
                        ? [
                            "Sold",
                            "Sold Only",
                            "Verified Sold",
                            "Pending Installs",
                            "Completed",
                            "Pending Cancels",
                            "Canceled",
                            "Pending Reschedules",
                            "Reviewed",
                            "Missing",
                            "Claw Back",
                            "Paid",
                          ]
                        : ["All", "Offers Sent", "Offers Accepted"],
                      setAccountStatus
                    )
                  : ""}
                {renderSelect(
                  "Date Type",
                  dateType,
                  [
                    "Last Updated",
                    "Install Date",
                    "Date Sold",
                    "Cancel Time",
                    "Appointment Date",
                    "Cancel Request Time",
                    "Reschedule Request Time",
                    "Knocked Date",
                    "Offer Sent Time",
                    "Offer Accepted Time",
                    "Paid Date",
                  ],
                  setQuickDate
                )}
                {renderSelect(
                  "QUICK DATE",
                  quickDate,
                  [
                    "Today",
                    "Tomorrow",
                    "This Week",
                    "Last Week",
                    "This Month",
                    "Last Month",
                    "This Year",
                    "All Time",
                    "Custom",
                  ],
                  setQuickDate
                )}

                <div className="flex flex-col items-start gap-1 relative">
                  <div className="flex items-center gap-1">
                    <input
                      type="date"
                      value={fromDateDisplay}
                      onChange={(e) => {
                        setFromDateDisplay(e.target.value);
                        if (e.target.value === "") {
                          setStartDate("");
                          return;
                        }
                        let date = new Date();

                        date = new Date(
                          date.setMonth(
                            parseInt(e.target.value.split("-")[1]) - 1
                          )
                        );
                        date = new Date(
                          date.setFullYear(
                            parseInt(e.target.value.split("-")[0])
                          )
                        );
                        date = new Date(
                          date.setDate(parseInt(e.target.value.split("-")[2]))
                        );
                        date.setHours(0, 0, 0, 0);
                        setStartDate(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                      }}
                      className="bg-white py-1 w-28"
                    />
                    <span>to</span>
                    <input
                      type="date"
                      value={toDateDisplay}
                      onChange={(e) => {
                        setToDateDisplay(e.target.value);
                        if (e.target.value === "") {
                          setEndDate("");
                          return;
                        }
                        let date = new Date();
                        date = new Date(
                          date.setMonth(
                            parseInt(e.target.value.split("-")[1]) - 1
                          )
                        );
                        date = new Date(
                          date.setFullYear(
                            parseInt(e.target.value.split("-")[0])
                          )
                        );
                        date = new Date(
                          date.setDate(parseInt(e.target.value.split("-")[2]))
                        );
                        date.setHours(23, 59, 59, 999);
                        setEndDate(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                      }}
                      className="bg-white py-1 w-28"
                    />
                  </div>
                  <span className="text-sm font-medium text-blue-600">
                    DATE RANGE
                  </span>
                </div>
              </>
            ) : (
              <>
                {renderSelect("MODE", mode, ["Sales", "Recruit"], setMode)}
                {renderSelect(
                  "LEAD TYPE",
                  cLeadType,
                  ["Recruit", "Company"],
                  setCLeadType
                )}
                {renderSelect(
                  "STATUS",
                  cStatus,
                  [
                    "New",
                    "New Candidate",
                    "Job Ad Sent",
                    "Contacted",
                    "Applied",
                    "Interview Link Sent",
                    "Interview Scheduled",
                    "2nd Interview Link Sent",
                    "2nd Interview Scheduled",
                    "Hired",
                    "Rejected",
                  ],
                  setCStatus
                )}
                {renderSelect(
                  "Date Type",
                  cDateType,
                  ["Created Date", "Appointment Date", "Last Updated Date"],
                  setCDateType
                )}
                {renderSelect(
                  "INDUSTRIES",
                  industries,
                  industryOptions.map((option) => option.label),
                  setIndustries
                )}
                {renderSelect(
                  "QUICK DATE",
                  quickDate,
                  [
                    "Today",
                    "Tomorrow",
                    "This Week",
                    "Last Week",
                    "This Month",
                    "Last Month",
                    "This Year",
                    "All Time",
                    "Custom",
                  ],
                  setQuickDate
                )}
                <div className="flex flex-col items-start gap-1 relative">
                  <div className="flex items-center gap-1">
                    <input
                      type="date"
                      value={fromDateDisplay}
                      onChange={(e) => {
                        setFromDateDisplay(e.target.value);
                        if (e.target.value === "") {
                          setStartDate("");
                          return;
                        }
                        let date = new Date();

                        date = new Date(
                          date.setMonth(
                            parseInt(e.target.value.split("-")[1]) - 1
                          )
                        );
                        date = new Date(
                          date.setFullYear(
                            parseInt(e.target.value.split("-")[0])
                          )
                        );
                        date = new Date(
                          date.setDate(parseInt(e.target.value.split("-")[2]))
                        );
                        date.setHours(0, 0, 0, 0);
                        setStartDate(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                      }}
                      className="bg-white py-1 w-28"
                    />
                    <span>to</span>
                    <input
                      type="date"
                      value={toDateDisplay}
                      onChange={(e) => {
                        setToDateDisplay(e.target.value);
                        if (e.target.value === "") {
                          setEndDate("");
                          return;
                        }
                        let date = new Date();
                        date = new Date(
                          date.setMonth(
                            parseInt(e.target.value.split("-")[1]) - 1
                          )
                        );
                        date = new Date(
                          date.setFullYear(
                            parseInt(e.target.value.split("-")[0])
                          )
                        );
                        date = new Date(
                          date.setDate(parseInt(e.target.value.split("-")[2]))
                        );
                        date.setHours(23, 59, 59, 999);
                        setEndDate(formatDateToISOString(new Date(date)));
                        setQuickDate("Custom");
                      }}
                      className="bg-white py-1 w-28"
                    />
                  </div>
                  <span className="text-sm font-medium text-blue-600">
                    DATE RANGE
                  </span>
                </div>

                {/* {renderSelect(
              "COMPANIES",
              companies,
              companyOptions.map((option) => option.label),
              setCompanies
            )}
            {renderSelect(
              "POSITION TITLES",
              positionTitles,
              positionTitleOptions.map((option) => option.label),
              setPositionTitles
            )} */}
              </>
            )}
          </div>

          {/* <div className="flex items-center gap-4 border-slate-200 border p-2 rounded-lg">
            <span className="text-sm font-medium">Type</span>
            <div className="inline-flex rounded-lg border border-gray-200">
              <button
                onClick={() => setType("highlighted")}
                className={`px-2 py-1 text-xs rounded-l-lg ${type === "highlighted"
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-500 hover:text-gray-700"
                  }`}
              >
                Highlighted Area
              </button>
              <button
                onClick={() => setType("saved")}
                className={`px-2 py-1 text-xs rounded-r-lg ${type === "saved"
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-500 hover:text-gray-700"
                  }`}
              >
                Saved Area
              </button>
            </div>
          </div> */}

          <div className="flex items-center gap-4 border-slate-200 border p-2 rounded-lg">
            <span className="text-sm font-medium">View</span>
            <div className="inline-flex rounded-lg border border-gray-200">
              <button
                onClick={() => {
                  setSelectedAction({ id: 0, label: "" });
                  setView("map");
                }}
                className={`px-2 py-1 text-xs rounded-l-lg ${
                  view === "map"
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Map View
              </button>
              <button
                onClick={() => setView("list")}
                className={`px-2 py-1 text-xs rounded-r-lg ${
                  view === "list"
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                List View
              </button>
            </div>
          </div>

          {/* <button className="px-2 py-3 text-xs border border-gray-200 rounded-lg hover:bg-gray-50 flex justify-between items-center gap-1">
            <IoFilterOutline /> Filters
          </button> */}
        </div>

        <div className="relative mb-6 flex justify-between w-full">
          <div>
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="text"
              placeholder="Search"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              className="pl-10 w-full max-w-sm h-10 rounded-lg border border-gray-200 focus:outline-none focus:border-transparent"
            />
          </div>
          {view === "list" && (
            <div className="flex items-center gap-4 mt-4 focus:outline-none">
              <span className="text-sm font-medium focus:outline-none">
                Page Size
              </span>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="py-1 w-28 focus:outline-none rounded-lg border border-gray-200"
              >
                {[20, 50, 100, 500, 1000].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        {view === "list" ? (
          <>
            {mode === "Sales" ? (
              <div className="border rounded-lg overflow-auto h-[50vh]">
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <VersatileLoader size="large" />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-slate-200">
                      <tr>
                        <th className="w-12 px-6 py-3">
                          {user.userType === "SuperAdmin" && (
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600"
                              checked={selectedContacts.length === leads.length}
                              onChange={() => handleSelectAll(leads)}
                            />
                          )}
                        </th>

                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Contact Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Install Date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Last Contacted
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Map Icon
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Preview Offfer
                        </th>
                        <th className="px-6 py-3 w-[20rem]"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {leads.map((contact) => (
                        <tr key={contact.id}>
                          <td className="px-6 py-4">
                            {user.userType === "SuperAdmin" && (
                              <input
                                type="checkbox"
                                checked={selectedContacts.some(
                                  (c) => c.id === contact.id
                                )}
                                onChange={() => handleCheckboxChange(contact)}
                                className="h-4 w-4 rounded border-gray-300 text-blue-600"
                              />
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center gap-3">
                              <div
                                className={`flex justify-center items-center w-10 h-10 rounded-full overflow-hidden ${getStatusStyles(
                                  contact
                                )}`}
                              >
                                <h1>{contact?.name ? contact.name[0] : ""}</h1>
                              </div>
                              <span className="font-medium text-gray-900">
                                {contact?.name}
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <span
                              className={`inline-flex text-center px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyles(
                                contact
                              )}`}
                            >
                              {getStatusText(contact)}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {contact.fidiumCustomer?.installDateTime
                              ? new Date(
                                  contact.fidiumCustomer?.installDateTime
                                ).toLocaleDateString()
                              : ""}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {contact.lastUpdatedAt
                              ? new Date(
                                  contact.lastUpdatedAt
                                ).toLocaleDateString()
                              : ""}
                          </td>
                          <td className="px-6 py-4">
                            {contact?.fiberHouse?.mapIconId ? (
                              <span>
                                {
                                  mapIcons.find(
                                    (icon) =>
                                      icon.id === contact?.fiberHouse?.mapIconId
                                  ).name
                                }
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          {contact.fidiumCustomer === null ? (
                            <td className="px-6 py-4">
                              <button
                                onClick={() => {
                                  if (contact.fiberCompanyId == null && filterFiberCompanyId == null) {
                                    Swal.fire({
                                      icon: "error",
                                      title: "Oops...",
                                      text: "The Lead is not assigned to a Company",
                                      confirmButtonText: "OK",
                                      confirmButtonColor: "#022780",
                                    });
                                    return;
                                  }
                                  window.open(
                                    window.location.origin +
                                      `/preview-offer/${user?.id}/${contact.fiberCompanyId ?? filterFiberCompanyId}/${contact.id}`
                                  );
                                }}
                                className="w-40 bg-blue-600 text-white px-2 py-2 rounded-lg"
                              >
                                Preview Offer
                              </button>
                            </td>
                          ) : (
                            <td className="px-6 py-4">
                              <button className="w-4 text-black px-2 py-2 rounded-lg cursor-default">
                                Customer
                              </button>
                            </td>
                          )}
                          <td className="px-6 py-4 ">
                            <div className="w-[20rem]">
                              <DigitalActionBar lead={contact} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <div className="border rounded-lg overflow-auto h-[50vh]">
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <VersatileLoader size="large" />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-slate-200">
                      <tr>
                        <th className="w-12 px-6 py-3">
                          {user.userType === "SuperAdmin" && (
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600"
                              checked={
                                selectedContacts.length === candidates.length
                              }
                              onChange={() => handleSelectAll(candidates)}
                            />
                          )}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Contact Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Lead Type
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Last Contacted
                        </th>
                        <th className="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {candidates.map((contact) => (
                        <tr key={contact.id}>
                          <td className="px-6 py-4">
                            {user.userType === "SuperAdmin" && (
                              <input
                                type="checkbox"
                                checked={selectedContacts.some(
                                  (c) => c.id === contact.id
                                )}
                                onChange={() => handleCheckboxChange(contact)}
                                className="h-4 w-4 rounded border-gray-300 text-blue-600"
                              />
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center gap-3">
                              <div
                                className={`flex justify-center items-center w-10 h-10 rounded-full overflow-hidden ${getStatusStyles(
                                  contact
                                )}`}
                              >
                                <h1>{contact.name ? contact.name[0] : ""}</h1>
                              </div>
                              <span className="font-medium text-gray-900">
                                {contact?.name}
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <span
                              className={`inline-flex text-center px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyles(
                                contact
                              )}`}
                            >
                              {getStatusText(contact)}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {contact.leadType}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {contact.lastUpdatedAt
                              ? new Date(
                                  contact.lastUpdatedAt
                                ).toLocaleDateString()
                              : ""}
                          </td>
                          <td className="px-6 py-4">
                            {mode === "Sales" ? (
                              <DigitalActionBar lead={contact} />
                            ) : (
                              <div className="w-fit m-auto">
                                <div className="grid grid-cols-3 gap-0 px-2">
                                  <button
                                    style={{
                                      backgroundColor:
                                        modeSelected === "email"
                                          ? "#022780"
                                          : "",
                                      color:
                                        modeSelected === "email" ? "white" : "",
                                    }}
                                    onClick={() => setModeSelected("email")}
                                    className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tl-lg"
                                  >
                                    Email
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor:
                                        modeSelected === "sms" ? "#022780" : "",
                                      color:
                                        modeSelected === "sms" ? "white" : "",
                                    }}
                                    onClick={() => setModeSelected("sms")}
                                    className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white"
                                  >
                                    SMS
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor:
                                        modeSelected === "both"
                                          ? "#022780"
                                          : "",
                                      color:
                                        modeSelected === "both" ? "white" : "",
                                    }}
                                    onClick={() => setModeSelected("both")}
                                    className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tr-lg"
                                  >
                                    Both
                                  </button>
                                </div>
                                {
                                  <div className="flex gap-2 items-center justify-center border-2 m-auto bg-white cursor-pointer border-[#022780] rounded-xl  px-8 py-1">
                                    <div
                                      onClick={() => {
                                        setIsInviteOpen(true);
                                      }}
                                    >
                                      <img
                                        src={bot}
                                        alt="bot"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>

                                    <select
                                      onChange={(e) => {
                                        setAction(e.target.value);
                                        setSelectedCandidate(contact);
                                        if (
                                          e.target.value === "Send Custom Text"
                                        ) {
                                          setCustomMessage(true);
                                        } else {
                                          setCustomMessage(false);
                                        }
                                      }}
                                      className="bg-transparent border-none w-[12rem] outline-none focus:outline-none font-bold uppercase text-[#022780]"
                                    >
                                      {getActions(contact.status, contact).map(
                                        (option) => (
                                          <option value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                }
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            {!loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem ",
                }}
              >
                <Pagination
                  color="light"
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        next: (props) => (
                          <button {...props}>
                            <BiRightArrow color="var(--color-icon)" />
                          </button>
                        ),
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => (
                          <button {...props}>
                            <BiLeftArrow color="var(--color-icon)" />
                          </button>
                        ),
                      }}
                      {...item}
                      sx={{ color: "white" }}
                    />
                  )}
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, pageNo) => {
                    setCurrentPage(pageNo);
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <Map
            leadType={leadType}
            accountStatus={accountStatus}
            dateType={dateType}
            from={startDate}
            to={endDate}
            setSelectedContacts={setSelectedContacts}
            selectedContacts={selectedContacts}
            setSelectedFiberHouseIds={setSelectedFiberHouseIds}
            selectedFiberHouseIds={selectedFiberHouseIds}
            selectedCandidateIds={selectedCandidateIds}
            setSelectedCandidateIds={setSelectedCandidateIds}
            cleadType={cLeadType}
            cAccountStatus={cStatus}
            cDateType={cDateType}
            cFrom={startDate}
            cTo={endDate}
            cIndustry={industries}
            mode={mode}
          />
        )}
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import FiberCompanySelector from "../../FiberCompanySelector";
import PhoneInput from "react-phone-input-2";
import authorized from "../../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png";
import authorizedFastwyre from "../../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png";
import logo from "../../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png";
import CreateLightCustomerReq from "../../../Requests/Customer/CreateLightCustomerReq";
import CustomerApi from "../../../API/CustomerApi";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";
import { UserContext } from "../../../App";
import CreateOrEditOfferReq from "../../../Requests/Offer/CreateOrEditOfferReq";
import EditFiberHouseReq from "../../../Requests/FiberHouse/EditFiberHouseReq";
import FiberHouseApi from "../../../API/FiberHouseApi";
import OfferApi from "../../../API/OfferApi";
import ContactButtons from "../../../Services/ContactButtons";
import FiberPlanSelector from "../../FiberPlanSelector";

const FreeInstallForm = ({
  open,
  onReload,
  setOpen,
  type,
  lead,
  fiberHouse,
  setReload,
}) => {
  const [lightFirstName, setLightFirstName] = useState(
    lead?.name?.split(" ")[0]
  );
  const [lightLastName, setLightLastName] = useState(
    lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ")[1] : ""
  );
  const [lightEmail, setLightEmail] = useState(lead?.email);
  const [lightPhone, setLightPhone] = useState(lead?.phone);
  const [lightConsent, setLightConsent] = useState(false);
  const [lightCompany, setLightCompany] = useState(lead?.fiberCompanyId);
  const [radioFree, setRadioFree] = useState(null);
  const [fiberCompanyObject, setFiberCompanyObject] = useState(
    lead?.fiberCompanyId
      ? {
          label: lead?.fiberCompanyId === 2 ? "LightCurve" : "Fastwyre",
          id: lead?.fiberCompanyId,
        }
      : null
  );
  // const [selectedTab, setSelectedTab] = useState("FreeInstall")
  const [fiberPlan, setFiberPlan] = useState(null);
  const [free, setFree] = useState(null);

  const user = useContext(UserContext);

  const contactButtons = new ContactButtons();
  const [isFiberLead, setIsFiberLead] = useState(
    fiberHouse?.mapIconId === 1 ? true : false
  );
  const auth = getAuth();

  useEffect(() => {
    setFiberCompanyObject(
      lead?.fiberCompanyId
        ? {
            label: lead?.fiberCompanyId === 2 ? "LightCurve" : "Fastwyre",
            id: lead?.fiberCompanyId,
          }
        : null
    );
    setLightCompany(lead?.fiberCompanyId);
  }, [lead]);

  const createLightCustomer = async () => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Creating Light Customer",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: "custom-swal",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (!lightFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "First Name is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightEmail) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (!pattern.test(lightEmail)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightPhone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (lightPhone.length < 11) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is invalid",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });

      return;
    }

    if (!lightCompany) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Company is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lightConsent) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Consent is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new CreateLightCustomerReq();
    req.leadId = lead.id;
    req.firstName = lightFirstName;
    req.lastName = lightLastName;
    req.email = lightEmail.toLowerCase();
    req.phoneNumber = lightPhone;
    req.companyId = lightCompany;
    req.salesRepId = user.id;
    req.signedUpBy = "SalesRep";
    req.salesOrgId = user?.salesOrgId;
    req.planId = fiberPlan?.id;
    req.isFiberLead = isFiberLead;
    const res = await CustomerApi.CreateLightCustomer(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Light Customer Created Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
      // sendPasswordResetEmail(auth, lightEmail)
      //     .then((value) => {
      //         console.log(value)
      //     })
      //     .catch((error) => {
      //         const errorMessage = error.message;

      //         Swal.fire({
      //             icon: 'error',
      //             title: 'Error',
      //             text: errorMessage,
      //         });
      //     });

      if (onReload) {
        onReload({
          ...res.data?.lead,
          fidiumCustomer: {
            ...res.data,
            lead: null,
          },
          fiberHouse: {
            ...res.data?.fiberHouse,
            lead: null,
          },
        });
      }

      setOpen(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          container: "custom-swal",
        },
        text: res?.status?.message,
      });
    }
  };

  const createOffer = async (method) => {
    if (!lightFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter First Name",
      });
      return;
    }

    if (!lightEmail && !lightPhone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Email OR Cell Phone",
      });
      return;
    }

    if (!fiberCompanyObject) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Fiber Company",
      });
      return;
    }
    console.log("Email", lightEmail);
    console.log("Phone", lightPhone);

    Swal.fire({
      icon: "info",
      title: "Creating Offer",
      html: "Please Wait",
      allowOutsideClick: false,
      customClass: "custom-swal",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (lightEmail && !pattern.test(lightEmail)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (lightPhone && lightPhone.length < 11) {
      Swal.fire({
        icon: "error",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new CreateOrEditOfferReq();
    req.firstName = lightFirstName;
    req.lastName = lightLastName;
    if (lightEmail === "null") {
      req.email = null;
    } else {
      req.email = lightEmail;
    }
    if (lightPhone === "null") {
      req.phone = null;
    } else {
      req.phone = lightPhone;
    }
    //req.fiberType = "1G";
    req.leadId = lead.id;
    req.salesRepId = user?.id;
    req.fiberCompanyId = lightCompany;
    req.fidiumCustomerId = lead.fidiumCustomerId ? lead.fidiumCustomerId : 0;
    req.promo = free + "\n" + radioFree;
    req.templateId = fiberCompanyObject?.offerTemplates[0]?.id;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 10;
    req.isFiberLead = isFiberLead;
    const res = await OfferApi.CreateEditOffer(req);
    if (res?.status?.success) {
      console.log(res);
      Swal.fire({
        icon: "success",
        title: "Offer Created Successfully",
      });
      const offerLink =
        window.location.origin + "/fiber-offer/" + res.data.offerGuid;
      const message = `Hey, ${lightFirstName}${
        lightLastName ? " " + lightLastName : ""
      }.\n\nIt was great to meet today. As promised, the link below is the ${
        fiberCompanyObject?.name
      }’s Internet Offer.\n\nRemember… this is a LIMITED TIME OFFER, and the link will expire in 7 days from now. Click below and fill out the form to reserve your spot on the Free Install Map - and receive your FIRST MONTH FREE.\n\n${offerLink}`;
      console.log("HII", offerLink);
      console.log("FirstName", lightFirstName);
      console.log("LastName", lightLastName);
      if (method === "email") {
        contactButtons.email(
          `${lightEmail}?subject=Digital Business Card&body=${encodeURIComponent(
            message
          )}`
        );
      } else if (method === "wa") {
        contactButtons.whatsapp(
          `${lightPhone}?text=${encodeURIComponent(message)}`
        );
      } else if (method === "sms") {
        contactButtons.sms(`${lightPhone}?body=${encodeURIComponent(message)}`);
      } else if (method === "copy") {
        Swal.fire({
          icon: "info",
          title: "Copying",
          text: "Please Keep The Window Open To Copy Link",
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
        try {
          navigator.clipboard.writeText(
            window.location.origin + "/fiber-offer/" + res.data.offerGuid
          );
          Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link Copied to Clipboard",
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Link Not Copied to Clipboard because browser window was closed",
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Offer Sent!",
          text: "The Offer has been sent to the Prospect.",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          customClass: {
            container: "custom-swal",
          },
        });
      }
      if (onReload) {
        onReload({
          ...res.data?.lead,
          offer: {
            ...res.data,
            lead: null,
          },
          fiberHouse: {
            ...res.data?.lead?.fiberHouse,
            lead: null,
          },
        });
      }
      const req = new EditFiberHouseReq();
      req.id = lead?.fiberHouse?.id;
      req.mapIconId = 8;
      req.coordinate = null;
      req.salesRepId = user?.id;

      try {
        const res = await FiberHouseApi.EditFiberHouse(req);
        if (res?.status?.success) {
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  function sendOffer() {
    if (!lightFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter First Name",
      });
      return;
    }

    if (!lightConsent) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Agree to Receive Email and/or SMS Messages",
      });
      return;
    }

    if (!lightEmail && !lightPhone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Email OR Cell Phone",
      });
      return;
    }

    if (!fiberCompanyObject) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Fiber Company",
      });
      return;
    }

    createOffer();
  }

  return (
    <Modal
      setOpen={setOpen}
      minHeight={"80vh"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div
        className="custom_modal"
        style={{
          maxHeight: "90vh",
        }}
      >
        {lightCompany === 2 ? (
          <img
            alt="Logo"
            style={{ width: "100%" }}
            src={authorized}
            className="logo"
          />
        ) : lightCompany === 9 ? (
          <img
            alt="Logo"
            style={{ width: "100%" }}
            src={authorizedFastwyre}
            className="logo"
          />
        ) : (
          <img
            alt="Logo"
            src={logo}
            style={{ width: "100%" }}
            className="logo"
          />
        )}
        <div className="am_pm">
          {type === "order" && (
            <p className={`active font-bold text-lg`}>{
                lightCompany === 13 ?
                "New Customer Form":
                "Free Install"
            }</p>
          )}
          {type === "offer" && (
            <p className={`active font-bold text-lg`}>Offer</p>
          )}
        </div>
        <div className="inputs">
          <input
            placeholder="*First Name"
            value={lightFirstName}
            onChange={(e) => {
              setLightFirstName(e.target.value);
            }}
          />
          <input
            placeholder="Last Name"
            value={lightLastName}
            onChange={(e) => {
              setLightLastName(e.target.value);
            }}
          />
          <input
            placeholder="Email"
            value={lightEmail}
            onChange={(e) => {
              setLightEmail(e.target.value.toLowerCase());
            }}
          />
          {
            <FiberCompanySelector
              value={fiberCompanyObject}
              companiesWithOfferTemplateOnly={true}
              setCompanyObject={setFiberCompanyObject}
              setUser={setLightCompany}
              disabled={false}
              placeholder="Select a Company"
              className="fiber_company_selector"
            />
          }
          {lightCompany && type === "order" && (
            <FiberPlanSelector
              fiberCompany={lightCompany}
              setUser={setFiberPlan}
              disabled={lightCompany ? false : true}
              placeholder="Select a Plan"
              className="fiber_plan_selector"
            />
          )}
          <PhoneInput
            placeholder="* Cellphone"
            country={"us"}
            containerStyle={{
              width: "100%",
              borderRadius: "10px",
            }}
            inputClass="custom_input"
            value={lightPhone}
            onChange={(e) => {
              setLightPhone(e);
            }}
          />

          {lightCompany === 2 && (
            <div className="">
              {/* <label>Include Invalid Customers</label> */}
              <div className="toggle-switch">
                <label>Fiber Lead</label>
                <input
                  type="checkbox"
                  checked={isFiberLead}
                  onChange={() => {
                    setIsFiberLead(!isFiberLead);
                  }}
                />
              </div>
            </div>
          )}
          {
            // (selectedTab === "FreeInstall" || (selectedTab === "Offer" && fiberCompanyObject?.offerTemplates?.length > 0)) &&
            <div className="consent">
              <input
                type="checkbox"
                checked={lightConsent}
                onChange={(e) => {
                  setLightConsent(e.target.checked);
                }}
              />
              <label>
                I agree to receive email and/or sms messages from Lightning OS
                LLC.
              </label>
            </div>
          }
          {fiberCompanyObject && type === "offer" && (
            <>
              <div className="promos">
                <label>Promos</label>
                <div className="promo_options">
                  {fiberCompanyObject?.offerTemplates?.length > 0
                    ? fiberCompanyObject.offerTemplates[0].promos
                        .split("\n")
                        .map((promoGroup, index) => {
                          return (
                            <div key={index}>
                              {promoGroup.split('"').map((promo, subIndex) => {
                                if (promo.trim() === "") return null;
                                if (promoGroup.includes('"')) {
                                  return (
                                    <label
                                      className="promo"
                                      htmlFor={`free${index}-${subIndex}`}
                                      key={subIndex}
                                    >
                                      <input
                                        type="radio"
                                        id={`free${index}-${subIndex}`}
                                        name="promo"
                                        onChange={(e) => {
                                          // if (radioFree) {
                                          //     let arr = radioFree.split('\n');
                                          //     if (arr.includes(promo)) {
                                          //         arr = arr.filter((item) => item !== promo);
                                          //         setRadioFree(arr.join('\n'));
                                          //     } else {
                                          //         setRadioFree((prev) => (prev ? prev + '\n' + promo : promo));
                                          //     }
                                          // } else {
                                          setRadioFree(promo);
                                          // }
                                        }}
                                      />
                                      <span>{promo}</span>
                                    </label>
                                  );
                                } else {
                                  return (
                                    <label
                                      className="promo"
                                      htmlFor={`free${index}-${subIndex}`}
                                      key={subIndex}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`free${index}-${subIndex}`}
                                        name={`free${index}-${subIndex}`}
                                        onChange={(e) => {
                                          // if (free) {
                                          //     let arr = free.split('\n');
                                          //     if (arr.includes(promo)) {
                                          //         arr = arr.filter((item) => item !== promo);
                                          //         setFree(arr.join('\n'));
                                          //     } else {
                                          //         setFree((prev) => (prev ? prev + '\n' + promo : promo));
                                          //     }
                                          // } else {
                                          setFree(promo);
                                          // }
                                        }}
                                      />
                                      <span>{promo}</span>
                                    </label>
                                  );
                                }
                              })}
                            </div>
                          );
                        })
                    : null}
                </div>
              </div>
            </>
          )}
          {type === "offer" && (
            <div
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "space-around",
              }}
              className="button"
            >
              <button onClick={() => sendOffer()}>Send Offer</button>
            </div>
          )}

          {type === "order" && (
            <button
              onClick={() => {
                createLightCustomer();
              }}
            >
              Reserve Free Install
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FreeInstallForm;

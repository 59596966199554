import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { 
  SkipPrevious, SkipNext, FastRewind, FastForward,
  PlayArrow, Pause, PanTool, Directions, Schedule, Speed 
} from '@mui/icons-material';
import Draggable from 'react-draggable';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format, differenceInSeconds, parseISO } from 'date-fns';
import { getDistance } from 'ol/sphere';
import './Player.scss';
import { useSwipeable } from 'react-swipeable';
import { clamp } from 'lodash';


const Player = ({ activeGroupIndex,
  speedsByGroup,
  pathGroups,
  onPlayPause,
  onSpeedChange,
  onPanToggle,
  playTimeMultiplier,
  isPanning,
  currentGroupSpeeds,
  animationState,
  onStateChange,
  onGroupChange,
  index,
  setIndex,
}) => {
  const [metrics, setMetrics] = useState({
    distance: '0 km',
    duration: '0m 0s',
    avgSpeed: '0 km/h',
    maxSpeed: '0 km/h'
  });

    // Add expanded state
    const [isExpanded, setIsExpanded] = useState(false);

      // New handler for show more/less
  const handleShowMoreButton = () => {
    setIndex(isExpanded ? -1 : 24);
    setIsExpanded(!isExpanded);
  };

  // Derive state from props
  // const { isPlaying, currentGroup, progress } = animationState;

  // Handle play/pause
  const handlePlayPause = () => {
    onStateChange(prev => ({
      ...prev,
      isPlaying: !prev?.isPlaying
    }));
    // Propagate to parent
    onPlayPause();
  };

  // Handle group navigation
  const handleGroupNavigation = (index) => {
    // const newGroup = clamp(
    //   currentGroup + direction,
    //   0,
    //   pathGroups.length - 1
    // );
    onGroupChange(index);
    // console.log("activeGroupIndex current: ", activeGroupIndex.current);
  };

  // // Handle seeker input
  // const handleSeekerChange = (value) => {
  //   const newProgress = parseFloat(value) / 100;
  //   onStateChange(prev => ({
  //     ...prev,
  //     progress: newProgress
  //   }));
  //   // Propagate to parent
  //   onProgressChange(newProgress);
  // };
  
  const [isPlaying, setIsPlaying] = useState(false);
  const [speedIndex, setSpeedIndex] = useState(1);
  const speedText = ["x0.5", "x1", "x2", "x4", "x6", "x8"];
  const [scrollPosition, setScrollPosition] = useState(0);

  // Use passed currentGroupSpeeds directly
  const currentGroupSpeedsNew = currentGroupSpeeds;

  const sectionsRef = useRef(null);


  const handleGroupIndexClick = (index) => {
    activeGroupIndex.current = index;
  };

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (sectionsRef.current) {
        const { deltaX } = eventData;
        const containerWidth = sectionsRef.current.offsetWidth;
        const contentWidth = sectionsRef.current.scrollWidth;
        const maxScroll = contentWidth - containerWidth;

        setScrollPosition((prevScroll) => {
          const newScroll = prevScroll - deltaX;
          return Math.max(0, Math.min(newScroll, maxScroll));
        });
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // Calculate metrics when group changes
  useEffect(() => {
    if (!pathGroups || activeGroupIndex.current === undefined) return;

    const group = pathGroups[activeGroupIndex.current];
    if (!group || group.length < 2) return;

    const calculateMetrics = () => {
      try {
        const startTime = parseISO(group[0].timestamp);
        const endTime = parseISO(group[group.length - 1].timestamp);
        const durationSec = differenceInSeconds(endTime, startTime);
        
        let totalDistance = 0;
        let maxSpeed = 0;
        const speeds = [];

        for (let i = 1; i < group.length; i++) {
          const from = group[i-1];
          const to = group[i];
          const dist = getDistance([from.lon, from.lat], [to.lon, to.lat]);
          totalDistance += dist;
          
          const timeDiff = differenceInSeconds(
            parseISO(to.timestamp), 
            parseISO(from.timestamp)
          );
          
          const speed = timeDiff > 0 ? (dist / timeDiff) * 3.6 : 0; // km/h
          speeds.push(speed);
          if (speed > maxSpeed) maxSpeed = speed;
        }

        setMetrics({
          distance: `${(totalDistance / 1000).toFixed(1)} km`,
          duration: formatDuration(durationSec),
          avgSpeed: `${(totalDistance / 1000 / (durationSec / 3600)).toFixed(1)} km/h`,
          maxSpeed: `${maxSpeed.toFixed(1)} km/h`
        });
        console.log("metrics from player: ", metrics, ", And activeGroupIndex: ", activeGroupIndex.current);
      } catch (error) {
        console.error('Error calculating metrics:', error);
      }
    };

    calculateMetrics();
  }, [activeGroupIndex.current, pathGroups]);

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  const getSpeedData = () => {
    if (!speedsByGroup || activeGroupIndex.current === undefined) return [];
    return speedsByGroup[activeGroupIndex.current]?.map((speed, index) => ({
      index,
      speed: speed * 3.6 // Convert m/s to km/h
    })) || [];
  };


  console.log("Play button from player: ", onPlayPause);
  return (
    <Draggable bounds="parent" handle=".player-handle">
      <div className="player">
        <div className="player-handle">
          <div className="handle-dots"></div>
        </div>
        <div className="player-content">

        <div className="metrics-grid">
            <div className="metric-card">
              <Directions fontSize="small" />
              <div>
                <span className="metric-label">Distance</span>
                <span className="metric-value">{metrics.distance}</span>
              </div>
            </div>
            
            <div className="metric-card">
              <Schedule fontSize="small" />
              <div>
                <span className="metric-label">Duration</span>
                <span className="metric-value">{metrics.duration}</span>
              </div>
            </div>

            <div className="metric-card">
              <Speed fontSize="small" />
              <div>
                <span className="metric-label">Avg Speed</span>
                <span className="metric-value">{metrics.avgSpeed}</span>
              </div>
            </div>

            <div className="metric-card">
              <Speed fontSize="small" />
              <div>
                <span className="metric-label">Max Speed</span>
                <span className="metric-value">{metrics.maxSpeed}</span>
              </div>
            </div>
          </div>
          <div className="controls-row">
          <Button
            id="showMoreBtn"
            variant="text"
            onClick={handleShowMoreButton}
            className="show-more-btn"
          >
            {isExpanded ? 'Hide Activities' : 'Show Activities'}
          </Button>
        </div>
          {/* Speed Chart */}
          <div className="speed-chart">
            <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={getSpeedData()} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                <defs>
                  <linearGradient id="speedGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#4CAF50" stopOpacity={0.2}/>
                  </linearGradient>
                </defs>
                
                <XAxis 
                  dataKey="index" 
                  tick={{ fontSize: 10 }}
                  label={{ 
                    value: 'Time Points', 
                    position: 'bottom', 
                    fontSize: 10 
                  }}
                />
                
                <YAxis
                  unit="km/h"
                  tick={{ fontSize: 10 }}
                  label={{
                    value: 'Speed',
                    angle: -90,
                    position: 'left',
                    fontSize: 10
                  }}
                />
                
                <Tooltip
                  formatter={(value) => [`${value.toFixed(1)} km/h`, 'Speed']}
                  labelFormatter={(index) => `Point ${index + 1}`}
                  contentStyle={{
                    padding: '4px 8px',
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '0.65rem' // Match your metric labels
                  }}
                  itemStyle={{
                    padding: 0,
                    fontSize: '0.7rem', // Slightly larger than label
                  }}
                  labelStyle={{
                    display: 'none' // Hide label if you want minimal design
                  }}
                />
                
                <Area
                  type="monotone"
                  dataKey="speed"
                  stroke="#4CAF50"
                  fill="url(#speedGradient)"
                  isAnimationActive={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/* <div className="controls-section">
          <ResponsiveContainer width="100%" height={60}>
          <AreaChart data={currentGroupSpeedsNew}>
            <defs>
              <linearGradient id="waveformGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#4CAF50" stopOpacity={0.2}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="index" hide />
            <YAxis hide domain={[0, 'dataMax']} />
            <Area
              type="monotone"
              dataKey="speed"
              stroke="#4CAF50"
              fill="url(#waveformGradient)"
              isAnimationActive={false}
            />
          </AreaChart>

            </ResponsiveContainer>
          </div> */}
          <div className="seeker-container">
            <input
              type="range"
              min="0"
              max="100"
              step="0.1"
              className="seeker"
              id="seeker"
            />
          </div>
          <div className="time-display-container">
            <span className="time-display"></span>
          </div>
          <div className="controls-row">
            <Button
              id="previousBtn"
              className="control-btn"
              variant="text"
              onClick={() => {/* Handle previous */}}
            >
              <SkipPrevious fontSize="small" />
            </Button>
            <Button
              id="rewindBtn"
              className="control-btn"
              variant="text"
              onClick={() => {/* Handle rewind */}}
            >
              <FastRewind fontSize="small" />
            </Button>
            <Button
              id="PlayPauseBtn"
              className="control-btn"
              variant="text"
              onClick={onPlayPause}
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </Button>
            <Button
              id="forwardBtn"
              className="control-btn"
              variant="text"
              onClick={() => {/* Handle fast forward */}}
            >
              <FastForward fontSize="small" />
            </Button>
            <Button
              id="nextBtn"
              className="control-btn"
              variant="text"
              onClick={() => {/* Handle next */}}
            >
              <SkipNext fontSize="small" />
            </Button>
          </div>
          <div className="controls-row">
          <Button
          id="speedBtn"
          variant="outlined"
          onClick={onSpeedChange}
          className="speed-btn"
        >
          {speedText[playTimeMultiplier - 1]}
        </Button>
        <Button
          id="panSwitch"
          variant="text"
          onClick={onPanToggle}
          className={`pan-btn ${isPanning ? 'active' : ''}`}
        >
          <PanTool fontSize="small" />
        </Button>
      </div>
      <div ref={sectionsRef} {...swipeHandlers} className="sections-container">
  {pathGroups.map((group, index) => (
    <Button
      key={index}
      id={`groupBtn-${index}`}
      className={`section-btn ${activeGroupIndex.current === index ? 'active' : ''}`}
      onClick={() => handleGroupNavigation(index)}
      variant="text"
      size="small"
    >
      {index + 1}
    </Button>
  ))}
</div>
        </div>
      </div>
    </Draggable>
  );
};

export default Player;
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import { FaPhone } from "react-icons/fa6";
import MobileVersionBusinessCard from "../../Reps/DigitalBusinessCenter/components/MobileVersionBusinessCard";
import LightningLoader from "../../../components/Loader";
import Plan from "./components/Plan";
import OurServices from "./components/OurServices";
import LightAcceptOfferForm from "./components/LightAcceptOfferForm";
import AppointmentForm from "./components/AppointmentForm";
import RequestCall from "./components/RequestCall";
import OfferModal from "./components/OfferModal";
import CustomerForm from "./components/CustomerForm";
import UsersApi from "../../../API/UsersApi";
import GetAffiliateCodeReq from "../../../Requests/ReferralCode/GetAffiliateCodeReq";

const ReferralLink = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConsultation, setOpenConsultation] = useState(false);
  const [openRequestCall, setOpenRequestCall] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [color, setColor] = useState("");
  const plansRef = useRef();

  const [offerTemplate, setOfferTemplate] = useState(null);
  const [affiliateCode, setAffiliateCode] = useState(null);

  const params = useParams();

  const getAffiliateCode = useCallback(async () => {
    try {
      var req = new GetAffiliateCodeReq(params.AffiliateCode);
      const response = await UsersApi.GetAffiliateCode(req);

      console.log({ response });

      if (!response.status.success) {
        throw new Error(response.status.message);
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }, [params.AffiliateCode]);

  useEffect(() => {
    getAffiliateCode()
      .then(({ offerTemplate, ...affiliateCode }) => {
        console.log({ offerTemplate, affiliateCode });
        setOfferTemplate(offerTemplate);
        setAffiliateCode(affiliateCode);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [params.AffiliateCode, getAffiliateCode]); // Added getAffiliateCode to dependencies

  console.log({ offerTemplate });

  return loading ? (
    <LightningLoader />
  ) : !offerTemplate ? (
    <div>No Offer Template Found</div>
  ) : (
    <div className="bg-gradient-to-r from-[#022780] to-[#000614] min-h-screen text-white p-2.5">
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <LightAcceptOfferForm
          setOpenRequestCall={setOpenRequestCall}
          setReload={setReload}
          setOpen={setOpen}
          fiberPlan={selectedPlan}
          offerTemplate={offerTemplate}
          affiliateCode={affiliateCode}
        />
      </Modal>
      <Modal
        open={openConsultation}
        onClose={() => {
          setOpenConsultation(false);
        }}
      >
        <AppointmentForm
          fiberCompanyId={offerTemplate.fiberCompanyId}
          open={openConsultation}
          setOpen={setOpenConsultation}
          color={
            offerTemplate.offerTemplateFiberPlans.find(
              (ofp) => ofp.fiberPlanId === selectedPlan?.id
            )?.color || "#01A7E1"
          }
        />
      </Modal>
      <Modal
        open={openRequestCall}
        onClose={() => {
          setOpenRequestCall(false);
        }}
      >
        <RequestCall
          offerTemplate={offerTemplate}
          open={openRequestCall}
          color={
            offerTemplate.offerTemplateFiberPlans.find(
              (ofp) => ofp.fiberPlanId === selectedPlan?.id
            )?.color || "#01A7E1"
          }
          setOpen={setOpenRequestCall}
        />
      </Modal>
      <Modal
        open={openShareModal}
        onClose={() => {
          setOpenShareModal(false);
        }}
      >
        <></>
      </Modal>

      <div className="max-w-[600px] mx-auto border-2 border-white rounded-3xl">
        <div className="relative">
          <img
            src={`/images/offer/${offerTemplate?.fiberCompany?.name?.toLowerCase()}_banner.png`}
            alt="banner"
            className="w-full h-auto rounded-3xl"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white text-black p-8 rounded-lg -mt-20 z-10">
              <img
                src={`data:image/png;base64,${offerTemplate?.fiberCompany?.logo}`}
                alt="logo"
                className="w-64 mb-4"
              />
              {offerTemplate?.fiberType === "Fiber" ? (
                <h1 className="text-2xl font-bold text-center uppercase">
                  <span className="text-[#01ab52]">
                    Making your holidays bright
                  </span>
                  <br />
                  with lightning - Fast Fiber internet
                </h1>
              ) : (
                <h1 className="text-2xl font-bold text-center uppercase">
                  <span className="text-[#01ab52]">
                    {offerTemplate?.fiberCompany?.description?.split("\n")[0]}
                  </span>
                  <br />
                  {offerTemplate?.fiberCompany?.description.split("\n")[1]}
                </h1>
              )}
              <button
                className="mt-4 bg-[#2761d0] text-white py-4 px-8 rounded-lg text-xl font-bold uppercase"
                onClick={() => {
                  plansRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                See Plans
              </button>
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <p className="text-3xl font-bold uppercase">Limited Time Offer</p>
          <div className="border-4 border-[#4DD4FF] rounded-3xl p-4 mx-4 mt-4">
            {offerTemplate.fiberCompanyId === 9 ? (
              <h1 className="text-4xl font-bold uppercase">
                Get Your
                <br />
                <span className="text-5xl text-shadow-lg shadow-[#4DD4FF]">
                  FIRST MONTH FREE
                </span>
              </h1>
            ) : (
              <h1 className="text-4xl font-bold uppercase">
                <span>Get up to $150 Credit &</span>
                <br />A Free Install
              </h1>
            )}
          </div>
          <p className="mt-4 uppercase">available with</p>
          <img
            src={`/images/offer/${offerTemplate?.fiberCompany?.name?.toLowerCase()}_authorized.png`}
            alt="fastwyre"
            className="mx-auto mt-4"
          />
          <img
            src="/images/offer/lightning_logo.png"
            alt="lightning"
            className="mx-auto mt-4"
          />
        </div>
        {offerTemplate?.salesRep && (
          <div className="p-4 mt-8">
            <div className="bg-white p-4 rounded-lg text-black">
              <MobileVersionBusinessCard
                displayContactButtons={false}
                displayQr={false}
                reviewButton={false}
                displayOffer={false}
                salesRep={offerTemplate.salesRep}
                salesRepIsUser={false}
              />
            </div>
          </div>
        )}
        <div ref={plansRef}>
          {offerTemplate.offerTemplateFiberPlans.map((ofp) => (
            <div key={ofp.id} className="mt-8">
              <img
                src="/images/offer/plan1.png"
                alt="banner"
                className="w-full h-auto"
              />
              <div
                className="mt-[-2rem] p-20 rounded-3xl"
                style={{ backgroundColor: ofp.color || "#01A7E1" }}
              >
                <Plan
                  setSelectedPlan={setSelectedPlan}
                  setOpen={setOpen}
                  offerTemplateFiberPlan={ofp}
                  offerTemplate={offerTemplate}
                  fiberCompany={offerTemplate.fiberCompany}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferralLink;

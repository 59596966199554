import React, { useContext, useEffect, useRef } from "react";
import SearchFiberHousesReq from "../../../../../Requests/FiberHouse/SearchFiberHousesReq";
import { UserContext } from "../../../../../App";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Cluster } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import LoadingIcon from "./../../../../TeamAdmin/MapPage/Images/loadingicon.png";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Text from "ol/style/Text.js";
import { CircularProgress } from "@mui/material";
const RenderFiberHouses = ({
  map,
  fiberHouses,
  setFiberHouses,
  setSelectedFiberHouseIds,
  selectedFiberHouseIds,
  accountStatus,
  leadType,
  dateType,
  from,
  to,
  mapIcons,
}) => {
  const user = useContext(UserContext);
  const [fiberHousesLoading, setFiberHousesLoading] = React.useState(false);
  const [zoom, setZoom] = React.useState("ZoomOut");
  const selectedFiberHouseRef = useRef([]);
  const cluster = useRef(null);
  const [clusterLayer, setClusterLayer] = React.useState(null);
  const SELECTED_MAPICON_SCALE = 1;
  const CIRCLE_RADIUS = 30;

  const searchFiberHouses = async () => {
    setFiberHousesLoading(true);

    const req = new SearchFiberHousesReq();

    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1000;

    if (user?.userType === "SalesOrgAdmin") {
      req.salesOrgId = user.salesOrgId;
      req.teamAdminId = user.id;
    }

    if (user?.userType === "SalesRep") {
      req.salesRepId = user.id;
      req.salesOrgId = user.salesOrgId;
    }

    // Common filters for both assigned and unassigned
    const setDateFilters = (prefix) => {
      switch (dateType) {
        case "Last Updated":
          if (from) req[`${prefix}LeadUpdatedFrom`] = from;
          if (to) req[`${prefix}LeadUpdatedTo`] = to;
          break;
        case "Date Sold":
          if (from) req[`${prefix}LeadTimeStampFrom`] = from;
          if (to) req[`${prefix}LeadTimeStampTo`] = to;
          break;
        case "Install Date":
          if (from) req[`${prefix}LeadInstallDateFrom`] = from;
          if (to) req[`${prefix}LeadInstallDateTo`] = to;
          break;
        case "Appointment Date":
          if (from) req[`${prefix}LeadAppointmentDateFrom`] = from;
          if (to) req[`${prefix}LeadAppointmentDateTo`] = to;
          break;
        case "Created At":
          if (from) req[`${prefix}CreatedFrom`] = from;
          if (to) req[`${prefix}CreatedTo`] = to;
          break;
        default:
          break;
      }
    };

    // Assigned filters
    req.assignedEnabled = true;
    req.assignedConvertedToCustomer = leadType === "Customer" ? true : null;
    req.assignedIssueType =
      accountStatus === "CancelsPending" ? "Cancel" : null;
    req.assignedAccountStatus =
      accountStatus !== "" && accountStatus !== "CancelsPending"
        ? accountStatus
        : null;
    setDateFilters("assigned");

    // Unassigned filters
    req.unassignedEnabled = true;
    req.unassignedConvertedToCustomer = leadType === "Customer" ? true : null;
    req.unassignedIssueType =
      accountStatus === "CancelsPending" ? "Cancel" : null;
    req.unassignedAccountStatus =
      accountStatus !== "" && accountStatus !== "CancelsPending"
        ? accountStatus
        : null;
    setDateFilters("unassigned");

    const res =
      await FiberHouseApi.SearchFiberHousesOptimizedLightAssignedUnassigned(
        req
      );
    if (res?.status?.success) {
      setFiberHouses(res.data);
      console.log(res.data.length);
      setFiberHousesLoading(false);
    }
  };

  useEffect(() => {
    searchFiberHouses();
  }, [accountStatus, leadType, dateType, from, to]);

  const renderFiberHousesOnMap = () => {
    if (cluster.current) {
      cluster.current.getSource().getSource().clear();
      cluster.current.getSource().clear();
      map.removeLayer(cluster.current);
      cluster.current = null;
      map.render();
      setClusterLayer(null);
    }

    const features = [];
    const coordinates = [];

    for (let i = 0; i < fiberHouses.length; i++) {
      const fiberHouse = fiberHouses[i];

      if (!fiberHouse.remove) {
        let coordinate = [
          fiberHouse.coordinates.longitude,
          fiberHouse.coordinates.latitude,
        ];
        let feature = new Feature(new Point(fromLonLat(coordinate)));
        coordinates.push(coordinate);

        if (fiberHouse.id) {
          feature.set("id", fiberHouse.id);
          feature.set("fiber_house", true);
          feature.set("mapIconId", fiberHouse.mapIconId);
          feature.set("selected", fiberHouse.selected);
          feature.set("isBundled", fiberHouse.isBundled);
        } else {
          console.error("fiberHouse.id is not defined");
        }

        feature.set("isNewHouse", !!fiberHouse.isNewHouse);

        features.push(feature);
      }
    }

    const vectorSource = new VectorSource({
      features: features,
    });

    const clusterSource = new Cluster({
      distance: zoom === "ZoomIn" ? 1 : 50,
      source: vectorSource,
    });
    const styleCache = {};
    let clusters = null;

    clusters = new VectorLayer({
      source: clusterSource,
      name: "fiberHouses",
      style: function (feature) {
        const size = feature.get("features").length;

        let style = styleCache[size];
        if (size === 1) {
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === feature.get("features")[0]?.get("mapIconId")
          );

          let imgScale = 1;
          if (!houseMapIcon) {
            houseMapIcon = LoadingIcon;
            imgScale = 0.1;
          } else {
            houseMapIcon = "data:image/png;base64," + houseMapIcon?.image;
            imgScale = 0.5;
          }
          if (
            selectedFiberHouseRef?.current?.length > 0 &&
            selectedFiberHouseRef.current.filter(
              (f) => f.id === feature.get("features")[0].get("id")
            ).length > 0
          ) {
            style = [
              new Style({
                image: new Icon({
                  scale: SELECTED_MAPICON_SCALE, // Adjust this value to change the scale of the icon
                  src: houseMapIcon,
                }),
                // zIndex: 11100,
              }),
              new Style({
                image: new CircleStyle({
                  radius: CIRCLE_RADIUS, // This is now in pixels
                  stroke: new Stroke({
                    color: "white", // Border color
                    width: 4, // Border width
                  }),
                  fill: new Fill({
                    color: "rgba(0, 0, 0, 0)", // Transparent fill
                  }),
                }),
              }),
            ];

            if (feature.get("features")[0].get("isBundled")) {
              console.log("isBundled");
              style.push(
                new Style({
                  text: new Text({
                    text: "TV",
                    fill: new Fill({
                      color: "#fff", // White text color
                    }),
                    font: "bold 12px Arial",
                    scale: 1.2,
                    textAlign: "center",
                    textBaseline: "middle",
                    offsetX: 10,
                    offsetY: 11,
                  }),
                })
              );
            }
            return style;
          }

          if (feature.get("features")[0].get("isBundled")) {
            style = [
              new Style({
                image: new Icon({
                  src: houseMapIcon,
                  scale: imgScale,
                  anchor: [0.5, 1],
                }),
              }),
              new Style({
                text: new Text({
                  text: "TV",
                  fill: new Fill({
                    color: "#fff", // White text color
                  }),
                  font: "bold 12px Arial", // Make the text bold and clear
                  scale: 1.2, // Scale the text for clarity
                  textAlign: "center", // Center align text
                  textBaseline: "middle", // Vertically center text
                  offsetX: 10, // Offset the text to match the circle's displacement
                  offsetY: -2, // Offset to ensure "B" stays in the middle of the circle
                }),
              }),
            ];
            return style;
          }

          style = new Style({
            image: new Icon({
              src: houseMapIcon,
              scale: imgScale,
              anchor: [0.5, 1],
            }),
          });
        } else {
          style = new Style({
            image: new CircleStyle({
              radius: 22,
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
              fill: new Fill({
                color: map.getView().getZoom() > 15.6 ? "#800080" : "#2761D0",
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: "#fff",
              }),
              font: "bold 10px Arial",
              scale: 1.2,
            }),
          });
        }

        return style;
      },
    });

    cluster.current = clusters;
    setClusterLayer(clusterSource);
    clusters.setZIndex(11000);
    map.addLayer(clusters);
  };

  const mapClick = (e) => {
    if (map) {
      let feature = map.forEachFeatureAtPixel(e.pixel, function (feature) {
        return feature;
      });
      if (feature) {
        let size = feature.get("features")?.length;
        const fiberHouseFeatures = feature.get("features");
        if (size === 1) {
          let fiberHouseId = fiberHouseFeatures[0].get("id");
          let selectedFeature = fiberHouseFeatures[0];

          if (!isNaN(fiberHouseId)) {
            let checkForAlreadySelected = selectedFiberHouseRef.current.filter(
              (f) => f.id === fiberHouseId
            ).length;
            if (checkForAlreadySelected > 0) {
              selectedFiberHouseRef.current =
                selectedFiberHouseRef.current.filter(
                  (f) => f.id !== fiberHouseId
                );
              setSelectedFiberHouseIds(
                selectedFiberHouseRef.current.map((f) => f.id)
              );
              map.getView().setZoom(map.getView().getZoom() - 0.0000001);
            } else {
              selectedFiberHouseRef.current.push({
                id: selectedFeature.get("id"),
                mapIconId: selectedFeature.get("mapIconId"),
              });

              setSelectedFiberHouseIds(
                selectedFiberHouseRef.current.map((f) => f.id)
              );

              let houseMapIcon = mapIcons?.find(
                (mi) => mi.id === selectedFeature.get("mapIconId")
              );

              const newStyle = new Style({
                image: new Icon({
                  scale: SELECTED_MAPICON_SCALE, // Adjust this value to change the scale of the icon
                  src: "data:image/png;base64," + houseMapIcon?.image,
                }),
              });

              if (clusterLayer) {
                const circleStyle = new Style({
                  image: new CircleStyle({
                    radius: CIRCLE_RADIUS, // This is now in pixels
                    stroke: new Stroke({
                      color: "white", // Border color
                      width: 4, // Border width
                    }),
                    fill: new Fill({
                      color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                  }),
                });
                feature.setStyle([
                  newStyle, // This is the icon style
                  circleStyle, // This is the circle style
                ]);
                map.getView().setZoom(map.getView().getZoom() - 0.0000001);
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (map) {
      map.on("click", mapClick);

      return () => {
        map.un("click", mapClick);
      };
    }
  }, [map]);

  useEffect(() => {
    if (fiberHouses && map) {
      renderFiberHousesOnMap();
    }

    return () => {
      if (cluster.current) {
        cluster.current.getSource().getSource().clear();
        cluster.current.getSource().clear();
        map.removeLayer(cluster.current);
        cluster.current = null;
        map.render();
        setClusterLayer(null);
      }
    };
  }, [fiberHouses, map]);

  return (
    <>
      {fiberHousesLoading && (
        <div className="transparent_loader">
          <div className="loader_text">
            <p>Loading Selected Leads</p>
            <CircularProgress sx={{ color: "#333" }} size={30} />
          </div>
        </div>
      )}
    </>
  );
};

export default RenderFiberHouses;

import React, { useState } from 'react';
import Select from 'react-select';

const IndustrySelector = ({ placeholder = "Select Industry", onChange }) => {
    // Static data for industries
    const data = [
        { label: 'Telecom 1', value: 1 },
        { label: 'Telecom 2', value: 2 },
        { label: 'Telecom 3', value: 3 }
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selected) => {
        setSelectedOption(selected || [] );
        if (onChange) onChange(selected);
    };

    return (
        <div className="industry-selector">
            <Select
                options={data}
                value={selectedOption}
                onChange={handleChange}
                placeholder={placeholder}
                isMulti 
                classNamePrefix="industry-select"
            />
        </div>
    );
};

export default IndustrySelector;

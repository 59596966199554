import React, { useState, useContext, useEffect } from 'react';
import { MapContext } from '../../index';
import { UserContext } from '../../../../../App';
import { 
    FaRoute, 
    FaCalendarAlt,
    FaTimes,      
    FaMinus       
  } from 'react-icons/fa';
import './styles/MiniLiveTrackingTab.scss';

const MiniLiveTrackingTab = ({ onClose, selectedRep, 
  setCurrentSalesRepDate,
  currentSalesRepDate }) => {

  // console.log("SalesRep from MiniLiveTrackingTab : ", selectedRep);

    const map = useContext(MapContext);
    const user = useContext(UserContext);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [autoLoaded, setAutoLoaded] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);

    useEffect(() => {
      setCurrentSalesRepDate(selectedDate);
    }, [selectedDate]);


    // console.log("SalesRep from MiniSalesRepCard map data: ", map.currentSalesRepId, "sales rep ids = ", map.salesRep);


  useEffect(() => {
    const loadData = async () => {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);
      
      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      map.setLocationFromDate(startDate.toISOString());
      map.setLocationToDate(endDate.toISOString());
      map.setSalesRepIds([selectedRep?.id]);

      
      if (!autoLoaded) {
        setAutoLoaded(true);
      }
    };

    loadData();
  }, [selectedDate, selectedRep]);

  const handleShowPath = () => {
    const startDate = new Date(selectedDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(selectedDate);
    endDate.setHours(23, 59, 59, 999);

    // 1. Set date range
    map.setLocationFromDate(startDate.toISOString());
    map.setLocationToDate(endDate.toISOString());

    // 2. Set target user
    map.setTargetUserId(selectedRep?.id);

    // 3. Activate path visualization
    map.setToggleState(prev => ({
      ...prev,
      path: true,
      currentLocation: true, // If you want to show current location
      playTime: 100 // Match your animation speed default
    }));

    // 4. Optional: Force refresh of location data
    // map.refreshTracking(); // If you have this method
  };

  return (
    <div className={`live-tracking-compact ${isMinimized ? 'minimized' : ''}`}>
      <div className="header">
        <div className="user-info">
          <img 
            src={selectedRep?.profileImage?.url || '/default-avatar.png'} 
            alt={selectedRep?.firstName} 
            className="avatar" 
          />
          <span className="name">{selectedRep?.firstName}</span>
        </div>
        
        <div className="controls">
          <button 
            className="icon-button"
            onClick={() => setIsMinimized(!isMinimized)}
            aria-label={isMinimized ? "Expand" : "Minimize"}
          >
            <FaMinus />
          </button>
          <button 
            className="icon-button"
            onClick={onClose}
            aria-label="Close"
          >
            <FaTimes />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <div className="content">
          <div className="date-section">
            <FaCalendarAlt className="calendar-icon" />
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className="date-input"
            />
          </div>
          
          <button 
            className="show-path-btn" 
            onClick={handleShowPath}
            disabled={!selectedRep}
          >
             <FaRoute className="btn-icon" />
             Show Route
             </button>
            </div>
        )}
    </div>
  );
};
export default MiniLiveTrackingTab;
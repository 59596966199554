import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../Layouts/Layout";
import "./index.scss";
import Datatable from "../../../components/Datatable";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Modal, PaginationItem } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";
import Swal from "sweetalert2";
import StringIntReq from "../../../Requests/StringIntReq";
import SearchJobApplicationReq from "../../../Requests/Users/SearchJobApplicationReq";
import UsersApi from "../../../API/UsersApi";
import { ref, deleteObject } from "firebase/storage";
import { getStorage } from "firebase/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "sortablejs";
import { MdStarRate } from "react-icons/md";
import { AiFillSchedule } from "react-icons/ai";
import { IoExpand, IoMapOutline } from "react-icons/io5";
import { FaBusinessTime, FaHireAHelper } from "react-icons/fa6";
import { HiOutlineArchiveBoxXMark } from "react-icons/hi2";
import EditJobApplicationReq from "../../../Requests/Users/EditJobApplication";
import CalendlyUserSelection from "../CalendlyUserSelection";
import CandidateJobApplicationReq from "../../../Requests/Users/CandidateJobApplicationReq";
import { UserContext } from "../../../App";
import FiberHouseApi from "../../../API/FiberHouseApi";
import { MapOutlined } from "@mui/icons-material";
import ExportData from "../../../Services/ExportData";
import RatingModal from "./RatingModal";
import ExportToWorkSheet from "./ExportToWorkSheet";
const Index = () => {
  const [jobApplications, setJobApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const storage = getStorage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [
    selectedCandidateFirstInterviewCompleted,
    setSelectedCandidateFirstInterviewCompleted,
  ] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [
    selectedCandidateSecondInterviewCompleted,
    setSelectedCandidateSecondInterviewCompleted,
  ] = useState(false);
  const [statePopulated, setStatePopulated] = useState(false);

  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const user = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (!location.state || !location.state.candidateId) {
      setStatePopulated(true);

      return;
    }

    const { candidateId } = location.state;

    if (!candidateId) return;

    setCandidateId(candidateId);
    setStatePopulated(true);
  }, [location.state]);

  const getJobApplications = async () => {
    setLoading(true);
    const req = new SearchJobApplicationReq();
    req.firstName = firstName;
    req.lastName = lastName;
    req.email = email;
    req.phone = phone;
    req.address = address;
    req.notes = notes;
    req.candidateId = candidateId;
    console.log(req);
    req.pagingParams = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    const res = await UsersApi.SearchJobApplication(req);
    if (res?.status?.success) {
      console.log(res.data);

      setTotalPages(res.data.totalPages);

      const list = [];
      for (let index = 0; index < res?.data?.list.length; index++) {
        list.push(res?.data?.list[index]);
        list[index].details = (
          <button
            style={{
              color: "white",
              backgroundColor: "#41B6E6",
              padding: "0.5rem 1rem",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(
                `/manage-job-applications/details/${res?.data?.list[index].id}`
              );
            }}
          >
            Details
          </button>
        );
        list[index].delete = (
          <button
            sx={{
              "&:hover": {
                backgroundColor: "red",
              },
              backgroundColor: "red",
              color: "white !important",
            }}
            onClick={() => {
              deleteJobApplication(res?.data?.list[index].id);
            }}
            color="error"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
            >
              <path
                d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V5H1V16Z"
                fill="#FF472E"
              />
            </svg>
          </button>
        );

        list[index].notes = (
          <textarea
            style={{
              width: "200px",
              height: "100px",
              resize: "none",
              outline: "none",
            }}
            value={res?.data?.list[index].notes}
            readOnly
          ></textarea>
        );
        list[index].message = (
          <textarea
            style={{
              width: "200px",
              height: "100px",
              resize: "none",
              outline: "none",
            }}
            value={res?.data?.list[index].message}
            readOnly
          ></textarea>
        );
        const date = new Date(res?.data?.list[index].timeStamp);
        const formattedDate = date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        list[index].timeStamp = `${formattedDate} ${formattedTime}`;
      }
      setJobApplications(res?.data?.list);
      setLoading(false);
    } else {
      console.log(res?.status?.message);
    }
  };

  useEffect(() => {
    if (!statePopulated) return;
    getJobApplications();
    setCandidateId(null);
  }, [currentPage, pageSize, statePopulated]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getJobApplications();
    }
  };

  async function deleteJobApplication(id) {
    const selected = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (selected.isConfirmed) {
      Swal.fire({
        icon: "info",
        title: "Deleting...",
        text: "Please wait",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const req = new StringIntReq();
      req.int = id;
      const deleteFiles = async () => {
        const f = jobApplications.find((j) => j.id === id).file;
        const fileRef = ref(storage, f.path);
        try {
          await deleteObject(fileRef);
        } catch (error) {
          console.error("An error occurred!", error);
        }
      };

      if (jobApplications.find((j) => j.id === id)?.file) await deleteFiles();
      const res = await UsersApi.DeleteJobApplication(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Job Application deleted successfully",
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        getJobApplications();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message,
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
    }
  }

  const editStatus = async (id, newStatus) => {
    //show swal confirmation with new status
    //if confirmed, send request to edit status
    //if success, get job applications

    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to change the status to ${newStatus}`,
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // loader
        Swal.fire({
          icon: "info",
          title: "Changing status...",
          text: "Please wait",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const req = new EditJobApplicationReq();
        let status;
        switch (newStatus) {
          case "Application Rated":
            status = "Application Rated";
            break;
          case "Offer Extended":
            status = "Offer Extended";
            break;
          case "Future Consideration":
            status = "Future Consideration";
            break;
          case "Rejected":
            status = "Rejected";
            break;
          default:
            status = "Pending";
        }
        req.id = id;
        req.status = status;
        const res = await UsersApi.EditJobApplication(req);
        if (res?.status?.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Status changed successfully",
            showConfirmButton: false,
            didOpen: () => {
              Swal.hideLoading();
            },
          });

          getJobApplications();
        }
      }
    });
  };

  const scheduleInterview = (
    id,
    firstInterviewCompleted,
    secondInterviewCompleted
  ) => {
    setSelectedCandidateId(id);
    setSelectedCandidateFirstInterviewCompleted(firstInterviewCompleted);
    setSelectedCandidateSecondInterviewCompleted(secondInterviewCompleted);
    setIsModalOpen(true);
    console.log("Scheduling interview for candidate:", id);
    console.log(isModalOpen);
  };

  const handleScheduleInterview = async (
    candidateId,
    calendlyLink,
    communicationTemplate
  ) => {
    try {
      Swal.fire({
        icon: "info",
        title: "Please Wait",
        text: "Sending Schedule",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      let req = new CandidateJobApplicationReq();
      req.CandidateId = candidateId;
      req.ScheduleLink = calendlyLink;
      req.UserId = user.id;
      req.body = communicationTemplate?.body;
      req.subject = communicationTemplate?.subject;
      console.log(req);
      const res = await UsersApi.scheduleRecruitInterview(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Interview scheduled successfully",
          showConfirmButton: false,
          timer: 1500,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        setIsModalOpen(false);
        getJobApplications();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message || "Failed to schedule interview",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
    } catch (error) {
      console.error("Error scheduling interview:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred",
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const hireApplicant = async (id, name, email) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      html: `
        <p>You want to hire <b>${name}</b> with email <b>${email}</b>.</p>
        <label for="userType" style="display: block; margin-top: 10px;">Select User Type:</label>
        <select id="userType" class="swal2-input" style="width: 100%; box-sizing: border-box;">
          <option value="SalesRep">Sales Rep</option>
          <option value="SalesOrgAdmin">Sales Org Admin</option>
        </select>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hire!",
      preConfirm: () => {
        // Get the selected user type
        const userType = document.getElementById("userType").value;
        if (!userType) {
          Swal.showValidationMessage("Please select a user type.");
        }
        return userType;
      },
    });

    if (result.isConfirmed) {
      //show swal loader
      Swal.fire({
        icon: "info",
        title: "Hiring...",
        text: "Please wait",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const userType = result.value; // This is the selected user type
      const req = new CandidateJobApplicationReq();
      req.candidateId = id;
      req.userId = user.id;
      req.userType = userType; // Include the selected user type in the request
      const res = await FiberHouseApi.HireCandidate(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Candidate hired successfully",
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          },
        }).then(() => {
          getJobApplications();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message || "Failed to hire candidate",
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
    }
  };

  const rejectApplicant = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to reject this applicant. An email will be sent to the applicant.`,
      showCancelButton: true,
      confirmButtonText: "Yes, reject it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // loader
        Swal.fire({
          icon: "info",
          title: "Rejecting applicant...",
          text: "Please wait",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let req = new CandidateJobApplicationReq();
        req.candidateId = id;
        req.userId = user.id;

        let res = await UsersApi.RejectJobApplication(req);
        if (res.status.success) {
          //show Swal succsess
          getJobApplications();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Applicant Rejected",
            showConfirmButton: false,
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        } else {
          //show Swal error
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.status.message,
            showConfirmButton: false,
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      }
    });
  };

  const openMap = (candidate) => {
    if (candidate.coordinates) {
      navigate("/map", {
        state: {
          candidate: {
            id: candidate.id,
            mapIconId: candidate.mapIconId,
            coordinates: candidate.coordinates,
            selected: true,
          },
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "No Coordinates",
        text: "This lead does not have any coordinates",
      });
    }
  };

  // 7243
  const headers = [
    {
      key: "timeStamp",
      name: "Submitted At",
    },
    {
      key: "id",
      name: "ID",
    },
    {
      key: "position",
      name: "Position",
    },
    {
      key: "firstName",
      name: "First Name",
    },
    {
      key: "lastName",
      name: "Last Name",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "phone",
      name: "Phone",
    },
    {
      key: "howDidYouHear",
      name: "How did you hear about us?",
    },
    {
      key: "address",
      name: "Address",
    },
    {
      key: "notes",
      name: "Notes",
    },
    {
      key: "rating",
      name: "Rating",
    },
    {
      key: "status",
      name: "Status",
    },
    {
      key: "firstInterviewDateTime",
      name: "1st Interview Date",
      render: (row) => {
        return row.firstInterviewDateTime
          ? new Date(row.firstInterviewDateTime).toLocaleString()
          : "Not set";
      },
    },
    {
      key: "secondInterviewDateTime",
      name: "2nd Interview Date",
      render: (row) => {
        return row.secondInterviewDateTime
          ? new Date(row.secondInterviewDateTime).toLocaleString()
          : "Not set";
      },
    },
    {
      key: "message",
      name: "Message",
    },
    {
      key: "actions",
      name: "Actions",
      render: (row) => (
        <>
          {row.candidateId ? (
            <div className="action-buttons">
              <button
                onClick={() => openMap(row.candidate)}
                title="View on Map"
              >
                <IoMapOutline size={28} color="blue" />
              </button>
              <button
                onClick={() => {
                  setCurrentRating(row.rating);
                  setOpenRatingModal(row.id);
                }}
                title="Rate Applicant"
              >
                <MdStarRate size={28} color="yellow" />
              </button>
              <button
                onClick={() =>
                  scheduleInterview(
                    row.candidateId,
                    row.firstInterviewCompleted,
                    row.secondInterviewCompleted
                  )
                }
                title="Schedule Interview"
              >
                <AiFillSchedule size={28} color="blue" />
              </button>
              <button
                onClick={() => editStatus(row.id, "Offer Extended")}
                title="Extend Offer"
              >
                <IoExpand size={28} />
              </button>
              <button
                onClick={() =>
                  hireApplicant(
                    row.candidateId,
                    row.firstName + " " + row.lastName,
                    row.email
                  )
                }
                title="Hire"
              >
                <FaHireAHelper size={28} color="green" />
              </button>
              <button
                onClick={() => editStatus(row.id, "Future Consideration")}
                title="Future Consideration"
              >
                <FaBusinessTime size={28} />
              </button>
              <button
                onClick={() => rejectApplicant(row.candidateId)}
                title="Reject"
              >
                <HiOutlineArchiveBoxXMark size={28} color="red" />
              </button>
            </div>
          ) : (
            <div className="action-buttons">
              <p>Not Candidate</p>
            </div>
          )}
        </>
      ),
    },
    {
      key: "details",
      name: "Details",
    },
  ];

  const handleExportCSV = () => {
    let dataToExport = jobApplications.map((item) => {
      return {
        id: item.id,
        "Submitted At": item.timeStamp,
        Position: item.position,
        "First Name": item.firstName,
        "Last Name": item.lastName,
        Email: item.email,
        Phone: item.phone,
        "How did you hear about us?": item.howDidYouHear,
        Address: item.address,
        Notes: item.notes,
        Status: item.status,
        "1st Interview Date": item.firstInterviewDateTime
          ? new Date(item.firstInterviewDateTime).toLocaleString()
          : "Not set",
        "2nd Interview Date": item.secondInterviewDateTime
          ? new Date(item.secondInterviewDateTime).toLocaleString()
          : "Not set",
        Message: item.message,
        Rating: item.rating,
      };
    });
    let ids = jobApplications.map((item) => item.id);
    const exportData = new ExportData();
    exportData.ExportToCsv("JobApplications.csv", dataToExport, ids);
  };

  return (
    <Layout>
      <div className="manageLeadsContainer">
        <RatingModal
          currentRating={currentRating}
          open={openRatingModal}
          setOpen={setOpenRatingModal}
        />
        <ExportToWorkSheet
          open={exportModalOpen}
          setOpen={setExportModalOpen}
        />
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="schedule-interview-modal"
          aria-describedby="modal-to-schedule-interview"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <h2 id="schedule-interview-modal">Schedule Interview</h2>
            <CalendlyUserSelection
              candidateId={selectedCandidateId}
              onSchedule={handleScheduleInterview}
              type={"Recruit"}
              firstInterviewCompleted={selectedCandidateFirstInterviewCompleted}
              secondInterviewCompleted={
                selectedCandidateSecondInterviewCompleted
              }
            />
          </Box>
        </Modal>

        <div className="manageLeadsHeader">
          <h1 className="manageLeadsTitle">Manage Job Applications</h1>
          <div className="searchFiltersInput">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onKeyDown={handleKeyPress}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onKeyDown={handleKeyPress}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onKeyDown={handleKeyPress}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Phone"
              value={phone}
              onKeyDown={handleKeyPress}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              placeholder="Address"
              value={address}
              onKeyDown={handleKeyPress}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              type="text"
              placeholder="Notes"
              value={notes}
              onKeyDown={handleKeyPress}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <button
            onClick={() => {
              setCurrentPage(1);
              getJobApplications();
            }}
          >
            Search
          </button>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2">
            <button
              onClick={handleExportCSV}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Export to CSV
            </button>
            <button
              onClick={() => setExportModalOpen(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Export to Worksheet
            </button>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Rows per page:</span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
              className="px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4rem",
            }}
          >
            <CircularProgress sx={{ color: "var(--color-icon)" }} size={50} />
          </div>
        ) : (
          <Datatable headers={headers} list={jobApplications} />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem ",
          }}
        >
          <Pagination
            color="light"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: (props) => (
                    <button {...props}>
                      <BiRightArrow color="var(--color-icon)" />
                    </button>
                  ),
                  first: (props) => <button {...props}>First</button>,
                  previous: (props) => (
                    <button {...props}>
                      <BiLeftArrow color="var(--color-icon)" />
                    </button>
                  ),
                }}
                {...item}
                sx={{ color: "white" }}
              />
            )}
            count={totalPages}
            onChange={(e, pageNo) => {
              setCurrentPage(pageNo);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Index;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import MapIconApi from '../../../../API/MapIconApi'
import Swal from 'sweetalert2'
import ManyToManyReq from '../../../../Requests/ManyToManyReq'
import StringIntReq from '../../../../Requests/StringIntReq'
const MapIconTags = ({ assignedMapIcons, getMapIcons }) => {
    const [mapIconTags, setMapIconTags] = useState([])
    const getMapIconTags = async () => {
        const res = await MapIconApi.GetAllTags()
        if (res?.status?.success) {
            console.log(res.data)   
            setMapIconTags(res.data)
        }
    }
    useEffect(() => {
        if (mapIconTags == null || mapIconTags.length === 0) {
            console.log("Getting Map Icon Tags")
            getMapIconTags()
        }
    }, [])

    const AddTagToMapIcon = async (tag) => {
        if (assignedMapIcons == null) {
            Swal.fire({
                icon: 'error',
                title: 'Please Select Map Icon First'
            })
            return;
        }

        if(tag == null){
            Swal.fire({
                icon: 'error',
                title: 'Please Select Tag First'
            })
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Adding Tag to Map Icon",
            text: "Please Wait ...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new ManyToManyReq()
        req.id1 = assignedMapIcons.id
        req.id2 = tag.id
        
        const res = await MapIconApi.AddTagToMapIcon(req)
        console.log(assignedMapIcons)
        console.log(res)
        Swal.close()
        if (res?.status?.success) {
            getMapIcons()
            Swal.fire({
                icon: 'success',
                title: 'Tag Added Successfully'
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message
            })
        }
        
    }

    const RemoveTagFromMapIcon = async (mpTag) => {
        if (assignedMapIcons == null) {
            Swal.fire({
                icon: 'error',
                title: 'Please Select Map Icon First'
            })
            return;
        }

        if(mpTag == null){
            Swal.fire({
                icon: 'error',
                title: 'Please Select Tag First'
            })
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Removing Tag from Map Icon",
            text: "Please Wait ...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq()
        req.int = mpTag.id

        const res = await MapIconApi.RemoveTagFromMapIcon(req)
        Swal.close()
        if (res?.status?.success) {
            getMapIcons()
            Swal.fire({
                icon: 'success',
                title: 'Tag Removed Successfully'
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res?.status?.message
            })
        }
    }

    return (
        <div>
            <div className="MapIconTags ">
                {mapIconTags.map((tag) => {
                    return (
                        <div key={tag.id} className="MapIconTag"
                            style={{ border: assignedMapIcons?.tags?.find(t => t.tagId === tag.id) ? "1px solid green" : "1px solid black",
                        }}
                        onClick={() => {
                            if (assignedMapIcons?.tags?.find(t => t.tagId === tag.id)) {
                                RemoveTagFromMapIcon(assignedMapIcons?.tags?.find(t => t.tagId === tag.id))
                            }
                            else {
                                AddTagToMapIcon(tag)
                            }
                        }}                   
                        >
                            <p style={{ color: assignedMapIcons?.tags?.find(t => t.tagId === tag.id) ? "green" : "black" }}>
                                {tag.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MapIconTags
import { CiViewList } from "react-icons/ci";
import {
  FaChalkboardTeacher,
  FaRegAddressCard,
  FaRegCheckSquare,
} from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { GoOrganization } from "react-icons/go";
import { IoBugSharp } from "react-icons/io5";
import { LuFolderCog, LuLayoutTemplate, LuSheet } from "react-icons/lu";
import {
  MdAccountBalance,
  MdDashboard,
  MdLeaderboard,
  MdOutlineBugReport,
  MdOutlineFeedback,
  MdOutlineManageAccounts,
  MdOutlineReportProblem,
} from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { LuUsers } from "react-icons/lu";
import { MdOutlineLeaderboard } from "react-icons/md";
import {
  PiCurrencyCircleDollar,
  PiMapTrifoldLight,
  PiSuitcaseSimpleThin,
} from "react-icons/pi";
import { RiContactsBook2Line, RiUserSearchLine } from "react-icons/ri";
import {
  SiAwsorganizations,
  SiBrandfolder,
  SiPivotaltracker,
} from "react-icons/si";
// import { TbMessage2Bolt, TbReportAnalytics } from "react-icons/tb";
import { TbReport, TbTemplate } from "react-icons/tb";
import { TiUserAddOutline } from "react-icons/ti";
import { VscFeedback, VscOrganization, VscSettings } from "react-icons/vsc";
import { FaTable, FaTags } from "react-icons/fa6";

// import { BiSolidOffer, BiSolidSpreadsheet } from 'react-icons/bi';
import { HiOutlineDocumentText } from "react-icons/hi2";
import { FaCalendarDays } from "react-icons/fa6";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { TfiAnnouncement } from "react-icons/tfi";
import { BiDollar } from "react-icons/bi";
// import icon from "../assets/theme/components/icon";
import { BsChatSquareQuote } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";


// const SuperAdminMenuItems = [

//     {

//         name: "Chat",
//         key: 'privatechat',
//         route: '/chats',
//         icon: (color,size=40) => <TbMessage2Bolt size={size} color={color} />,
//         disabled: true
//     },
//     // {
//     //     name: "Create Training Module",
//     //     key: 'createSellingCard',
//     //     route: '/createSellingCard',
//     //     icon: (color,size=40) => <TbFolderBolt size={size} color={color} />,
//     // },
//     {
//         name: "Manage Training Modules",
//         key: 'manageSellingCard',
//         route: '/manage-training-modules',
//         icon: (color,size=40) => <LuFolderCog size={size} color={color} />,
//         disabled: true
//     },
//     // {
//     //     name: "Create Shop Item",
//     //     key: 'createShopItems',
//     //     route: '/createShopItems',
//     //     icon: (color,size=40) => <MdOutlineAddShoppingCart size={size} color={color} />,
//     // },
//     // {
//     //     name: "Manage Shop Item",
//     //     key: 'manageShopItems',
//     //     route: '/manageShopItems',
//     //     icon: (color,size=40) => <TbShoppingCartCog size={size} color={color} />,
//     // },
//     // {
//     //     name: "customMerchs",
//     //     key: 'customMerchs',
//     //     route: '/customMerch',
//     //     icon: (color,size=40) => <TbShoppingCartBolt size={size} color={color} />,
//     // },

//     // {
//     //     name:"Manage Business Card",
//     //     key:"manageBusinessCard",
//     //     route:"/manage-business-card",
//     //     icon: (color,size=40) => <VscSettings size={size} color={color} />,
//     // },

// ];

const SuperAdminMenuItems = [
  {
    name: "Dashboard",
    key: "Index",
    route: "/",
    icon: (color, size = 40) => <RxDashboard size={size} color={color} />,
  },
  {
    name: "Work Sheets",
    key: "MasterSheet",
    route: "/master-sheet",
    icon: (color, size = 40) => (
      <HiOutlineDocumentText size={size} color={color} />
    ),
  },

  // Group Repoting & Analytics
  // {
  //   groupName: "Reporting & Analytics",
  //   icon: (color, size = 40) => <TbReport size={size} color={color} />,
  //   items: [
  {
    name: "General Sales Report",
    key: "GeneralSalesReport",
    route: "/general-sales-reporting-page",
    icon: (color, size = 40) => <TbReport size={size} color={color} />,
  },
  //   ],
  // },

  // Leader Board
  {
    name: "Leader Board",
    key: "LeaderBoard",
    route: "/leaderboard",
    icon: (color, size = 40) => (
      <MdOutlineLeaderboard size={size} color={color} />
    ),
    // disabled: true
  },

  //Digital Business Center

  {
    name: "Digital Business Center",
    key: "DigitalBusinessCenter",
    route: "/digital-business-center",
    icon: (color, size = 40) => (
      <HiOutlineCreditCard size={size} color={color} />
    ),
  },

  // Group Business Center Analytics
  // {
  //     groupName: "Business Center Analytics",
  //     icon: (color, size = 40) => <TbReportAnalytics size={size} color={color} />,
  //     items: [

  //     ],

  // },
  {
    name: "Map",
    key: "free-install-map",
    route: "/map",
    icon: (color, size = 40) => <PiMapTrifoldLight size={size} color={color} />,
  },

  // {
  //   name: "Blog Posts",
  //   key: "Blogs",
  //   route: "/blogs",
  //   icon: (color, size = 40) => <SiBlogger size={size} color={color} />,
  // },

  {
    type: "collapse",
    name: "Contact Center",
    key: "ContactCenter",
    route: "/contact-center",
    icon: (color, size = 40) => (
      <RiContactsBook2Line size={size} color={color} />
    ),
    noCollapse: true,
    // disabled: true
  },
  // {
  //     type: "collapse",
  //     name: "Contact Center Recruit Leads",
  //     key: 'ContactCenterRecruitLeads',
  //     route: '/contact-center-recruit-leads',
  //     icon: (color, size = 40) => <RiContactsBook2Line size={size} color={color} />,
  //     noCollapse: true,
  //     // disabled: true
  // },

  {
    groupName: "Manage Users",
    icon: (color, size = 40) => <LuUsers size={size} color={color} />,
    items: [
      {
        name: "Users",
        key: "manageUsers",
        route: "/searchUser",
        icon: (color, size = 40) => <LuUsers size={size} color={color} />,
      },

      {
        name: "Organizations",
        key: "manageOrganization",
        route: "/organizationList",
        icon: (color, size = 40) => (
          <SiAwsorganizations size={size} color={color} />
        ),
      },
      {
        name: "Customers",
        key: "manageCustomers",
        route: "/manage-customers",
        icon: (color, size = 40) => (
          <FaRegAddressCard size={size} color={color} />
        ),
      },
      {
        name: "Create Company",
        key: "createFiberCompany",
        route: "/createFiberCompany",
        icon: (color, size = 40) => (
          <GoOrganization size={size} color={color} />
        ),
      },
      {
        name: "Companies",
        key: "manageFiberCompany",
        route: "/manageFiberCompany",
        icon: (color, size = 40) => (
          <GoOrganization size={size} color={color} />
        ),
      },
      {
        name: "Applicants",
        key: "manageJobApplications",
        route: "/manage-job-applications",
        icon: (color, size = 40) => (
          <PiSuitcaseSimpleThin size={size} color={color} />
        ),
      },
      {
        name: "Create User",
        key: "createUser",
        route: "/addUser",
        icon: (color, size = 40) => (
          <TiUserAddOutline size={size} color={color} />
        ),
      },
      // {
      //   name: "Leads",
      //   key: 'manageLeads',
      //   route: '/manage-leads',
      //   icon: (color, size = 40) => <RiUserSearchLine size={size} color={color} />,
      // },


    ],
  },

  // Group Manage Financials
  {
    groupName: "Manage Financials",
    icon: (color, size = 40) => (
      <PiCurrencyCircleDollar size={size} color={color} />
    ),
    items: [
      {
        name: "Create Plan",
        key: "createComissionPlan",
        route: "/create-comission-plan",
        icon: (color, size = 40) => (
          <GiReceiveMoney size={size} color={color} />
        ),
      },
      {
        name: "Manage Plans",
        key: "manageCommissionPlans",
        route: "/manage-commission-plans",
        icon: (color, size = 40) => <CiViewList size={size} color={color} />,
      },
      {
        name: "Manage Trackers",
        key: "manageCommissionTrackers",
        route: "/manage-commission-trackers",
        icon: (color, size = 40) => (
          <SiPivotaltracker size={size} color={color} />
        ),
      },
      {
        name: "Manage Debt Sheet",
        key: "manageDebtSheet",
        route: "/manage-debt-sheet",
        icon: (color, size = 40) => <LuSheet size={size} color={color} />,
      },
      {
        name: "Accounting Entries",
        key: "manageAccountingEntries",
        route: "/manage-accounting-entries",
        icon: (color, size = 40) => (
          <MdAccountBalance size={size} color={color} />
        ),
      },
      {
        name: "Commission Tree",
        key: "CommissionTree",
        route: "/commission-tree",
        icon: (color, size = 40) => <BiDollar size={size} color={color} />,
      },
    ],
  },

  //// single items
  {
    name: "Training Modules",
    key: "manageSellingCard",
    route: "/manage-training-modules",
    icon: (color, size = 40) => (
      <FaChalkboardTeacher size={size} color={color} />
    ),
    // disabled: true
  },
  {
    name: "Customer Feedback",
    key: "CustomerFeedbackAnalysis",
    route: "/customer-feedback-analysis",
    icon: (color, size = 40) => <BsChatSquareQuote size={size} color={color} />,
  },
  {
    name: "Manage Customer Feedbacks",
    key: "manageCustomerFeedbacks",
    route: "/manage-customer-feedbacks",
    icon: (color, size = 40) => <MdOutlineFeedback size={size} color={color} />,
  },

  {
    name: "Manage Announcements",
    key: "manageAnnouncements",
    route: "/manage-announcements-board",
    icon: (color, size = 40) => <TfiAnnouncement size={size} color={color} />,
  },
  {
    name: "My Calendar",
    key: "MyCalendar",
    route: "/my-calendar",
    icon: (color, size = 40) => <FaCalendarDays size={size} color={color} />,
  },

  {
    name: "Report A Bug",
    key: "CreateBugTicket",
    route: "/create-bug-ticket",
    icon: (color, size = 40) => (
      <MdOutlineBugReport size={size} color={color} />
    ),
  },
  {
    name: "Manage Bug Tickets",
    key: "manageBugTickets",
    route: "/manage-bug-tickets",
    icon: (color, size = 40) => <IoBugSharp size={size} color={color} />,
  },
  {
    name: "Manage Issue Types",
    key: "manageIssueTypes",
    route: "/manage-issue-types",
    icon: (color, size = 40) => (
      <MdOutlineReportProblem size={size} color={color} />
    ),
  },
  {
    name: "Availability Checks",
    key: "manageCheckAvailability",
    route: "/manage-check-availability",
    icon: (color, size = 40) => <FaRegCheckSquare size={size} color={color} />,
  },
  {
    name: "Communication Templates",
    key: "manageCommunicationTemplates",
    route: "/communication-templates",
    icon: (color, size = 40) => <TbTemplate size={size} color={color} />,
  },
  {
    name: "Recruit Templates",
    key: "ManageRecruitTemplates",
    route: "/manage-recruit-templates",
    icon: (color, size = 80) => <LuLayoutTemplate size={size} color={color} />,
  },
  {
    name: "Company Consultations",
    key: "ManageCompanyConsultations",
    route: "/manage-company-consultations",
    icon: (color, size = 40) => <VscOrganization size={size} color={color} />,
  },
  // {
  //   name: "Mapping Filter",
  //   key: "MappingFilter",
  //   route: "/mapping-filter",
  //   icon: (color, size = 40) => <VscOrganization size={size} color={color} />,
  // },

  // {
  //     name: "Manage Brands",
  //     key: "ManageBrands",
  //     route: "/manage-brands",
  //     icon: (color, size = 40) => <SiBrandfolder size={size} color={color} />,
  // },

  {
    name: "Tags",
    key: "Tags",
    route: "/tags",
    icon: (color, size = 40) => <FaTags size={size} color={color} />,
  },

  {
    name: "Custom Fields",
    key: "CustomFields",
    route: "/manage-custom-fields",
    icon: (color, size = 40) => <FaTable size={size} color={color} />,
  },

  {
    name: "Email Report",
    key: "EmailReport",
    route: "/email-report",
    icon: (color, size = 40) => <HiOutlineDocumentReport  size={size} color={color} />,
  }
];

export default SuperAdminMenuItems;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import { MapContext } from "../..";
import { AudioRecorder } from "./AudioRecording";
// import Modal from './../../../../../components/Modal'
import { Modal, Rating, Tooltip } from "@mui/material";
import CreateCustomer from "./../CreateCustomer";
import { SearchFiltersContext, UserContext } from "../../../../../App";
import { FaFire, FaRegCopy, FaTag } from "react-icons/fa";
import { IoQrCode, IoShareSocial } from "react-icons/io5";
import QRCode from "qrcode.react";
import { FaMicrophone } from "react-icons/fa"; // Import the microphone icon
import { MdBolt, MdDeleteOutline, MdDoorbell, MdRoute } from "react-icons/md";
import Swal from "sweetalert2";
import DeleteFiberHouseReq from "./../../../../../Requests/FiberHouse/DeleteFiberHouseReq";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import { FaAddressCard } from "react-icons/fa6";
import EditLeadReq from "../../../../../Requests/FiberHouse/EditLeadReq";
import EditFiberHouseReq from "../../../../../Requests/FiberHouse/EditFiberHouseReq";
import bot from "./../../Images/bot.png";
import ContextMenu from "../../../../../components/ContextMenu";
import { RxActivityLog, RxReset } from "react-icons/rx";
import MapHelper from "../../MapHelper";
import { fromLonLat } from "ol/proj";
import { GiTv } from "react-icons/gi";
import MarkLeadAsBundledReq from "../../../../../Requests/FiberHouse/MarkLeadAsBundledReq";
import CustomNote from "./CustomNote";
import FiberCompanySelector from "../../../../../components/FiberCompanySelector";
import {
  User,
  Phone,
  Mail,
  Star,
  Clock,
  CheckCircle,
  Plus,
  Send,
  ChevronDown,
  MapPin,
  Calendar,
  QrCode,
} from "lucide-react";
import StarIcon from "@mui/icons-material/Star";
import { SiSpeedtest, SiTheconversation } from "react-icons/si";
import SpeedTestModal from "./SpeedTestModal";
import DigitalToolBelt from "./../../../../../components/DigitalToolBelt";
import CustomerInfo from "./../../../../../components/CustomerInfo";
import FreeInstallForm from "./FreeInstallForm";
import AppointmentForm from "./AppointmentForm";
import ScheduleForm from "./ScheduleForm";
import LightningMessage from "./Images/LightningMessage.png";
import Call from "./Images/Call.png";
import Email from "./Images/Email.png";
import Message from "./Images/Message.png";
import wa from "./Images/whatsapp.svg";
import Directions from "./Images/Directions.png";
import SearchFiberCompanyReq from "../../../../../Requests/FiberCompany/SearchFiberCompanyReq";
import FiberCompanyApi from "../../../../../API/FiberCompanyApi";
import ContactButtons from "../../../../../Services/ContactButtons";
import ReviewModal from "../../../../../components/DigitalActionBar/components/ReviewModal";
import { useNavigate } from "react-router-dom";
import DigitalBusineesImg from "./Images/DB.jpg";
import QrImage from "./Images/qr.svg";
import CustomerApi from "../../../../../API/CustomerApi";
import StringIntReq from "../../../../../Requests/StringIntReq";
import {
  IoIosAddCircle,
  IoIosRemoveCircle,
  IoIosSearch,
  IoMdClose,
} from "react-icons/io";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { RiContactsBook2Line, RiGroupFill, RiCameraFill } from "react-icons/ri";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { BusinessCenter, LocationOn } from "@mui/icons-material";
import ContactCenter from "../../../../Shared/ContactCenter";
import UserSelector from "../../../../../components/UserSelector";
import { FiX } from "react-icons/fi";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import AssignLeadsToRepsReq from "../../../../../Requests/FiberHouse/AssignLeadsToRepsReq";
import MapIconDisplay from "./../MapIconDisplay";
import Select from "react-select";

import {
  FaCarCrash,
  FaUser,
  FaUserTie,
  FaQuestionCircle,
  FaShieldAlt,
  FaDollarSign,
  FaDog,
} from "react-icons/fa";
import {
  MdOutlineSecurity,
  MdOutlineFamilyRestroom,
  MdPets,
} from "react-icons/md";
import {
  IoSearch,
  IoCalendarOutline,
  IoChatbubblesOutline,
  IoTrendingDownSharp,
  IoPersonOutline,
} from "react-icons/io5";
import { AiOutlineWarning, AiOutlineClockCircle } from "react-icons/ai";
import { GiFamilyHouse, GiPoliceOfficerHead } from "react-icons/gi";
import { FaTv, FaCar, FaRegHeart, FaBaby, FaChild } from "react-icons/fa";
import { IoReceiptOutline } from "react-icons/io5";
import CarCrashOutlinedIcon from "@mui/icons-material/CarCrashOutlined";

import SelfieUploadPopup from "./SelfieUploadPopup";
import { VscOpenPreview } from "react-icons/vsc";

const iconDisplayMapping = {
  3: [],
  16: [
    { component: CarCrashOutlinedIcon, label: "No Car" },
    { component: FaCar, label: "1 Car" },
    { component: FaCar, label: "2 Cars" },
    { component: GiFamilyHouse, label: "Definitely Home" },
  ],
  17: [
    { component: CarCrashOutlinedIcon, label: "No Car" },
    { component: FaCar, label: "1 Car" },
    { component: FaCar, label: "2 Cars" },
    { component: GiFamilyHouse, label: "Definitely Home" },
  ],

  21: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  18: [
    { component: FaChild, label: "Kid" },
    { component: FaUser, label: "Elder" },
    { component: FaBaby, label: "Babysitter" },
    { component: FaQuestionCircle, label: "Other" },
  ],
  33: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  22: [
    { component: MdOutlineSecurity, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: FaUser, label: "Fiber Competitor" },
    { component: IoTrendingDownSharp, label: "Negative Reputation" },
    { component: IoReceiptOutline, label: "Company Pays Bill " },
    { component: IoIosSearch, label: "Too Busy" },
  ],
  5: [
    { component: MdOutlineSecurity, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Negative Reputation" },
    { component: IoReceiptOutline, label: "Company Pays Bill" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  6: [
    { component: AiOutlineWarning, label: "Got Aggressive" },
    { component: AiOutlineWarning, label: "Got Violent" },
    { component: FaDog, label: "Dangerous Dogs" },
    { component: FaQuestionCircle, label: "Other" },
  ],
  24: [
    { component: IoCalendarOutline, label: "1 Month" },
    { component: IoCalendarOutline, label: "2 Months" },
    { component: IoCalendarOutline, label: "3 Months" },
    { component: IoCalendarOutline, label: "6 Months" },
    { component: IoCalendarOutline, label: "1 Year +++" },
  ],
  19: [
    { component: IoChatbubblesOutline, label: "Speak Zero Spanish" },
    { component: IoChatbubblesOutline, label: "Speak Little Spanish" },
    { component: IoChatbubblesOutline, label: "Translator Attempted" },
  ],
  23: [],
  31: [],
  39: [
    { component: GiPoliceOfficerHead, label: "Marked Vehicle" },
    { component: GiPoliceOfficerHead, label: "Unmarked Vehicle" },
  ],
  8: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  9: [],
  40: [],
  36: [],
  28: [],
  10: [],
  11: [],
  35: [],
  12: [],
};

const LeadData = ({
  mapIcon,
  setMapIcon,
  setReload,
  fidiumCustomer,
  lead,
  setEdit,
  fiberHouse,
  searchFiberHouses,
  setIndex,
  setFiberHouse,
  setAccountStatusModal,
}) => {
  const user = useContext(UserContext);
  const {
    mapIcons,
    cluster,
    mapObject,
    salesReps,
    setSelectedFiberHouse,
    selectedFiberHouseFeature,
    updateFiberHouseInContext,
    boltMode,
  } = useContext(MapContext);

  const mapContext = useContext(MapContext);
  console.log(boltMode);
  console.log(lead);
  // console.log(fiberHouse)
  // console.log(mapIcons)
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(() => {
    if (lead.fidiumCustomer != null) {
      return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
    } else if (lead.name != null) {
      return lead.name;
    } else {
      return "";
      return "";
    }
  });

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState(lead?.email);
  const [phone, setPhone] = useState(lead?.phone);
  const [openModal, setOpenModal] = useState(false);
  const [openInstallTimeModal, setOpenInstallTimeModal] = useState(false);
  const [address, setAddress] = useState(lead?.fiberHouse?.mapString);
  const initialRender = useRef(true);
  const shouldSaveAddress = useRef(false);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [markedInvalid, setMarkedInvalid] = useState(
    lead?.fidiumCustomer?.isInvalid
  );
  // const [mapIcon, setMapIcon] = useState(lead?.fiberHouse?.mapIconId);

  // const [lightSalesRep, setLightSalesRep] = useState(null)
  const [lightOpenModal, setLightOpenModal] = useState(false);
  const [offerOrOrder, setOfferOrOrder] = useState("offer");
  const [speedTestOpenModal, setSpeedTestOpenModal] = useState(false);
  const [beforeImageUrl, setBeforeImageUrl] = useState("");
  const [afterImageUrl, setAfterImageUrl] = useState("");

  const [isBundled, setIsBundled] = useState(lead?.isBundled);
  const [hasVideoDoorBell, setHasVideoDoorBell] = useState(
    lead?.hasVideoDoorBell
  );

  const [openDigitalToolBelt, setOpenDigitalToolBelt] = useState(false);
  const [openCustomerInfo, setOpenCustomerInfo] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const timerRef = useRef(null);
  const [action, setAction] = useState(
    getActions()?.length > 0 ? getActions()[0].label : ""
  );
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [isChangeOwnershipOpen, setIsChangeOwnershipOpen] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isSalesRepsModalOpen, setIsSalesRepsModalOpen] = useState(false);

  const [isFiberCompanyModalOpen, setIsFiberCompanyModalOpen] = useState(false);
  const [fiberCompanyId, setFiberCompanyId] = useState(null);

  let contactButtons = new ContactButtons(User);
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef(null);

  const [isQuickNotesOpen, setIsQuickNotesOpen] = useState(true);
  const [isCustomNoteOpen, setIsCustomNoteOpen] = useState(false);
  const [customNotes, setCustomNotes] = useState([]);
  const [notes, setNotes] = useState([]);
  // const [note, setNote] = useState("");
  const [note, setNote] = useState("📝 ");
  const [isViewNotesOpen, setIsViewNotesOpen] = useState(true);

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    let SR = salesReps?.filter((rep) =>
      lead?.fiberHouse?.salesRepFiberHouses.some(
        (salesRep) => salesRep.salesRepId === rep.id
      )
    );
    // console.log("SalesRep",SR);

    // if (SR.length === 0) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "No Sales Reps Found",
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     allowEnterKey: false,
    //     showConfirmButton: true,
    //   });
    // }
  }, []);

  useEffect(() => {
    if (mapContext.boltMode) {
      let shouldDisable = true;
      if (user.userType === "SuperAdmin") {
        shouldDisable = false;
      } else {
        shouldDisable = true;
        if (
          lead?.fiberHouse?.salesRepFiberHouses.some(
            (salesRep) => salesRep.salesRepId === user.id
          )
        ) {
          shouldDisable = false;
        }
      }
      setDisabled(shouldDisable);
    } else {
      setDisabled(false);
    }
  }, [mapContext.boltMode, lead]);

  const [showPopup, setShowPopup] = useState(false);

  // // Example pop-up component for uploading a selfie
  // const SelfieUploadPopup = ({ onClose }) => {
  // return (
  //   <div className="modal">
  //     <div className="modal-content">
  //       <h2>Upload Your Selfie</h2>
  //       {/* Add your file input and upload logic here */}
  //       <button onClick={onClose}>Close</button>
  //     </div>
  //   </div>
  // );
  // };

  // Function to open the selfie upload popup
  const goToSelfieUploadPopup = () => {
    setShowPopup(true);
  };

  const deleteFiberHouse = async () => {
    const result = await Swal.fire({
      icon: "question",
      title: "Are you sure?",
      text: "You want to delete this Lead",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, I'm Sure!",
    });

    if (result.isConfirmed) {
      Swal.fire({
        icon: "info",
        title: "Please Wait",
        text: "We are deleting the Lead",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const deleteFiberHouseReq = new DeleteFiberHouseReq();
      deleteFiberHouseReq.int = fiberHouse.id;
      deleteFiberHouseReq.pagingParams.pageNumber = 1;
      deleteFiberHouseReq.pagingParams.pageSize = 10;
      const res = await FiberHouseApi.DeleteFiberHouse(deleteFiberHouseReq);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Lead Deleted Successfully",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          willClose: () => {
            Swal.hideLoading();
          },
        });

        setSelectedFiberHouse(null);

        cluster.current
          .getSource()
          .getSource()
          .forEachFeature((feature) => {
            if (feature.get("id") === fiberHouse.id) {
              cluster.current.getSource().getSource().removeFeature(feature);
            }
          });

        setIndex(-1);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message,
        });
      }
    }
  };

  function copyToClipboard(text, message) {
    navigator.clipboard.writeText(text);
    Swal.fire({
      icon: "success",
      title: "Copied",
      text: message,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    });
  }

  const getDisplayComponents = (iconName) => {
    if (lead.fidiumCustomer) {
      if (
        lead.fidiumCustomer.accountStatus === "Sold" ||
        lead.fidiumCustomer.accountStatus === "Scheduled" ||
        lead.fidiumCustomer.accountStatus === "Rescheduled"
      ) {
        return iconDisplayMapping[9];
      } else if (lead.fidiumCustomer.isMissing === true) {
        return iconDisplayMapping[40];
      } else if (lead.fidiumCustomer.isInvalid === true) {
        return iconDisplayMapping[36];
      } else if (lead.fidiumCustomer.accountStatus === "Canceled") {
        return iconDisplayMapping[28];
      } else if (lead.fidiumCustomer.accountStatus === "Completed") {
        return iconDisplayMapping[10];
      } else if (
        lead.fidiumCustomer.accountStatus === "Completed" &&
        lead.fidiumCustomer.customerFeedbacks != null
      ) {
        return iconDisplayMapping[11];
      }
    } else return iconDisplayMapping[iconName] || [];
  };

  const GetQuickNotes = async () => {
    let req = {
      LeadId: lead?.id,
    };
    const res = await CustomerApi.GetQuickNotes(req);
    if (res?.status?.success) {
      setNotes(res.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };
  useEffect(() => {
    GetQuickNotes();
  }, [lead]);

  const updateLead = async (type) => {
    let req = new EditLeadReq();
    req.id = lead.id;

    switch (type) {
      case "name":
        req.name = name;
        break;
      case "email":
        req.email = email;
        break;
      case "phone":
        req.phone = phone;
        break;
      default:
        req.name = name;
        req.email = email;
        req.phone = phone;
    }
    req.salesRepId = user.id;
    let res = await FiberHouseApi.EditLead(req);
    if (res?.status?.success) {
      searchFiberHouses();
      setEdit(false);
    }
  };

  const updateFiberHouse = async () => {
    let req = new EditFiberHouseReq();
    req.id = lead?.fiberHouse?.id;
    req.mapString = address;
    req.coordinate = null;
    req.salesRepId = user?.id;
    const res = await FiberHouseApi.EditFiberHouse(req);
    if (res?.status?.success) {
      searchFiberHouses();
    }
  };

  useEffect(() => {
    if (name !== lead?.name && name !== "") {
      const timer = setTimeout(() => {
        updateLead("name");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [name]);

  useEffect(() => {
    if (email !== lead?.email && email !== "") {
      const timer = setTimeout(() => {
        updateLead("email");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [email]);

  useEffect(() => {
    if (phone !== lead?.phone) {
      const timer = setTimeout(() => {
        updateLead("phone");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [phone]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else if (
      shouldSaveAddress.current &&
      address !== lead?.fiberHouse?.mapString
    ) {
      const timer = setTimeout(() => {
        updateFiberHouse();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [address]);

  const menuItemsForConnector = [
    {
      title: `Go to LightCurve Portal`,
      onClick: () => {
        window.open(
          "https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:",
          "_blank"
        );
      },
      show: lead.fiberCompanyId
        ? lead.fiberCompanyId === 2
          ? true
          : false
        : true,
    },
    {
      title: `Go to Fastwyre Portal`,
      onClick: () => {
        window.open("https://fastwyre.com/lightning/", "_blank");
      },
      show: lead.fiberCompanyId
        ? lead.fiberCompanyId === 9
          ? true
          : false
        : true,
    },
    {
      title: `Copy LightCurve Link`,
      onClick: () => {
        navigator.clipboard.writeText(
          "https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:"
        );
        Swal.fire({
          icon: "success",
          title: "Copied",
          text: "Link Copied to Clipboard",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
      },
      show: lead.fiberCompanyId
        ? lead.fiberCompanyId === 2
          ? true
          : false
        : true,
    },
    {
      title: `Copy Fastwyre Link`,
      onClick: () => {
        navigator.clipboard.writeText("https://fastwyre.com/lightning/");
        Swal.fire({
          icon: "success",
          title: "Copied",
          text: "Link Copied to Clipboard",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
      },
      show: lead.fiberCompanyId
        ? lead.fiberCompanyId === 9
          ? true
          : false
        : true,
    },
  ];

  function extractPhoneNumberDetails(phoneNumber) {
    // if there is a + at the start of the phone number remove the first 2 characters
    if (phoneNumber.startsWith("+")) {
      return phoneNumber.substring(2);
    }
    // if there is a 1 at the start of the phone number remove the first character
    else if (phoneNumber.startsWith("1")) {
      return phoneNumber.substring(1);
    } else {
      return phoneNumber;
    }
  }

  async function updateCoordinates(fh) {
    // return
    const result = await Swal.fire({
      title: "Update Coordinates",
      text: "Are you sure you want to Reset the Position of the Lead (this can not be reversed)? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "green",
      cancelButtonColor: "red",
    });

    if (result.isConfirmed) {
      // let coordinatesFailed = 0
      // const list = []
      Swal.fire({
        icon: "info",
        title: "Please Wait",
        text: "We are updating the coordinates",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      const helper = new MapHelper();
      const mapstring = fh.mapString;

      const coordinates = await helper.searchLocation(mapstring);
      if (
        selectedFiberHouseFeature &&
        selectedFiberHouseFeature.get("id") === fh.id
      ) {
        selectedFiberHouseFeature
          .getGeometry()
          .setCoordinates(fromLonLat(coordinates));
        mapObject.getView().setCenter(fromLonLat(coordinates));
      }

      const req = new EditFiberHouseReq();
      req.coordinate.latitude = coordinates[1];
      req.coordinate.longitude = coordinates[0];
      req.id = fh.id;

      // update the coordiantes of the feature

      const res = await FiberHouseApi.EditFiberHouse(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Coordinates Updated Successfully",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          willClose: () => {
            Swal.hideLoading();
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          willClose: () => {
            Swal.hideLoading();
          },
        });
      }
    }
  }

  const markLeadAsBundled = async () => {
    const req = new MarkLeadAsBundledReq();
    req.id = lead.id;
    req.isBundled = !isBundled;
    setIsBundled(!isBundled);
    const res = await FiberHouseApi.MarkLeadAsBundled(req);
    if (res?.status?.success === true) {
      updateFiberHouseInContext(fiberHouse.id, { isBundled: req.isBundled });
    }
  };

  const markLeadHasVideoDoorBell = () => {
    const req = new MarkLeadAsBundledReq();
    req.id = lead.id;
    req.hasVideoDoorBell = !hasVideoDoorBell;
    setHasVideoDoorBell(!hasVideoDoorBell);
    const res = FiberHouseApi.MarkLeadHasVideoDoorBell(req);
    if (res?.status?.success) {
      console.log(res);
    }
  };

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the image as a base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  function getActions() {
    if (lead.fidiumCustomer == null) {
      if (lead.offer == null)
        return [
          {
            label: "Reserve Free Install",
            onClick: () => {
              setOfferOrOrder("order");
              setLightOpenModal(true);
            },
          },
          {
            label: "Send Offer",
            onClick: () => {
              setOfferOrOrder("offer");
              setLightOpenModal(true);
            },
          },
        ];
      else
        return [
          {
            label: "Reserve Free Install",
            onClick: () => {
              setOfferOrOrder("order");
              setLightOpenModal(true);
            },
          },
        ];
    } else if (lead.fidiumCustomer != null) {
      if (lead.fidiumCustomer.accountStatus === "Sold") {
        return [
          {
            label: "Schedule Install",
            onClick: () => {
              setOpenInstallTimeModal(true);
            },
          },
        ];
      } else if (
        lead.fidiumCustomer.accountStatus === "Scheduled" ||
        lead.fidiumCustomer.accountStatus === "Rescheduled" ||
        lead.fidiumCustomer.accountStatus === "Canceled"
      ) {
        return [
          {
            label: "Reschedule Install",
            onClick: () => {
              setOpenInstallTimeModal(true);
            },
          },
        ];
      } else if (lead.fidiumCustomer.accountStatus === "Completed") {
        return [
          {
            label: "Review Request",
            onClick: () => {
              setOpenReviewModal(true);
            },
          },
          // {
          //     label: "Referral Request",
          //     onClick: () => {
          //         console.log("Referral Request")
          //     }
          // }
        ];
      }
    }
  }

  const handleQuickNoteClick = async (item) => {
    // console.log("note:",item);

    let req = {
      Note: item,
      LeadId: lead.id,
    };

    const res = await CustomerApi.CreateQuickNote(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Quick Note Added",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      GetQuickNotes();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const markCustomerAsInvalid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Invalid",
      text: "Are you sure you want to mark this customer as invalid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!lead.fidiumCustomer) return;
    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      text: "Marking Customer As Invalid",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsInvalid(req);
    if (res?.status?.success) {
      let assignedMapIcons = mapIcons.find((x) => x.abbreviation === "IC");
      if (
        selectedFiberHouseFeature &&
        selectedFiberHouseFeature.get("id") === fiberHouse.id
      ) {
        const newStyle = new Style({
          image: new Icon({
            scale: 1.5, // Adjust this value to change the scale of the icon
            src: "data:image/png;;base64," + assignedMapIcons?.image,
          }),
        });

        const circleStyle = new Style({
          image: new CircleStyle({
            radius: 15, // This is now in pixels
            stroke: new Stroke({
              color: "black", // Border color
              width: 4, // Border width
            }),
            fill: new Fill({
              color: "rgba(0, 0, 0, 0)", // Transparent fill
            }),
          }),
        });
        selectedFiberHouseFeature.setStyle([
          newStyle, // This is the icon style
          circleStyle, // This is the circle style
        ]);
        selectedFiberHouseFeature.values_.mapIconId = assignedMapIcons.id;
        mapObject.getView().setZoom(mapObject.getView().getZoom() + 0.000001);
      }
      Swal.fire({
        icon: "success",
        title: "Customer Marked As Invalid",
        text: "The customer has been marked as invalid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });

      setMarkedInvalid(true);
      setMapIcon(assignedMapIcons.id);
      setReload((prev) => !prev);
      setReload((prev) => !prev);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const markCustomerAsValid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Valid",
      text: "Are you sure you want to mark this customer as valid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      icon: "info",
      text: "Marking Customer As Valid",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsValid(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Customer Marked As Valid",
        text: "The customer has been marked as valid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });
      setMarkedInvalid(false);
      setReload((prev) => !prev);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const getCustomerLine = () => {
    if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.orderNumber != null
    ) {
      return "Verified Customer";
    } else if (
      lead.fidiumCustomer != null &&
      lead.fidiumCustomer.orderNumber == null
    ) {
      return "Customer";
    } else {
      return "Lead";
    }
  };

  const searchFilter = useContext(SearchFiltersContext);

  // Function to navigate to the contact center for the specific customer
  const goToContactCenterForSingleCustomer = (lead) => {
    // Ensure searchFilter is updated with the searched customer
    let name = lead?.fiberHouse?.mapString;
    searchFilter.current = {
      ...searchFilter.current,
      contactCenter: {
        ...searchFilter?.current?.contactCenter,
        customerName: `${name}`, // Pass the searched customer
        leadType: "All",
      },
    };

    // Navigate to Contact Center
    navigate("/contact-center");
  };

  const downloadQrCode = () => {
    const canvas = document.getElementById("qrCode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    const fileName = `${user?.firstName} ${user?.lastName} QR Code.png`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const assignLeadsToReps = async (salesRep) => {
    if (salesRep === null) {
      return;
    }
    const option = await Swal.fire({
      title: "Assign Leads",
      text: "Are you sure you want to assign this user ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      customClass: {
        container: "custom-swal",
      },
    });

    if (!option.isConfirmed) {
      return;
    }

    const filteredFiberHouses = [lead.fiberHouseId];

    const req = new AssignLeadsToRepsReq();
    req.assignedBy = user.id;
    req.fiberHouseIds = filteredFiberHouses;
    req.repId = salesRep;

    const res = await FiberHouseApi.AssignRepsToLeads(req);
    if (res?.status?.success) {
      fetchAssignedUsers();
      searchFiberHouses();
    } else {
    }
  };
  const fetchAssignedUsers = async () => {
    const req = {
      ids: [lead.fiberHouseId],
    };
    const res = await FiberHouseApi.SearchFiberHousesOptimized(req);
    if (res?.status?.success) {
      setAssignedUsers(res.data[0].owners);
    }
  };
  const removeRepFromLeads = async (salesrep, id) => {
    const option = await Swal.fire({
      title: "Remove Sales Rep",
      text: "Are you sure you want to remove lead from this user ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "red",
      cancelButtonColor: "green",
      customClass: {
        container: "custom-swal",
      },
    });

    if (!option.isConfirmed) {
      return;
    }

    const req = new AssignLeadsToRepsReq();
    req.salesRepFiberHouseIds = [id];
    req.repId = salesrep;

    const res = await FiberHouseApi.RemoveRepsFromLeads(req);
    if (res?.status?.success) {
      fetchAssignedUsers();
      searchFiberHouses();
    } else {
    }
  };

  useEffect(() => {
    fetchAssignedUsers();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const executeMainAction = () => {
    if (disabled) {
      return;
    }
    const selectedAction = getActions().find((a) => a.label === action);
    if (selectedAction) {
      selectedAction.onClick();
    }
  };

  function extractPhoneNumberDetails(phoneNumber) {
    // if there is a + at the start of the phone number remove the first 2 characters
    if (phoneNumber.startsWith("+")) {
      return phoneNumber.substring(2);
    }
    // if there is a 1 at the start of the phone number remove the first character
    else if (phoneNumber.startsWith("1")) {
      return phoneNumber.substring(1);
    } else {
      return phoneNumber;
    }
  }

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Fiber Business Card",
          text:
            `Hey 👋 \n\nContact ${
              user.firstName + " " + user.lastName
            } to get your Fiber Offer. \n\n ` +
            window.location.origin +
            "/digital-business-center/",
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API not supported.");
    }
  };

  const getMenuItemsForConnector = () => {
    const lightCurve = [
      {
        title: `Go to LightCurve Portal`,
        onClick: () => {
          window.open(
            "https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:",
            "_blank"
          );
        },
      },
      {
        title: `Copy LightCurve Link`,
        onClick: () => {
          navigator.clipboard.writeText(
            "https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:"
          );
          Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link Copied to Clipboard",
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        },
      },
    ];

    const fastwyre = [
      {
        title: `Go to Fastwyre Portal`,
        onClick: () => {
          window.open("https://fastwyre.com/lightning/", "_blank");
        },
      },
      {
        title: `Copy Fastwyre Link`,
        onClick: () => {
          navigator.clipboard.writeText("https://fastwyre.com/lightning/");
          Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link Copied to Clipboard",
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        },
      },
    ];

    const pointBroadband = [
      {
        title: `Go to Point Broadband Portal`,
        onClick: () => {
          window.open(user.pointBroadbandLink, "_blank");
        },
      },
      {
        title: `Copy Point Broadband Link`,
        onClick: () => {
          navigator.clipboard.writeText(user.pointBroadbandLink);
          Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link Copied to Clipboard",
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        },
      },
    ];

    const astound = [
      {
        title: `Go to Astound Portal`,
        onClick: () => {
          window.open("https://shop.astound.com/dsr", "_blank");
        },
      },
      {
        title: `Copy Astound Link`,
        onClick: () => {
          navigator.clipboard.writeText("https://shop.astound.com/dsr");
          Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link Copied to Clipboard",
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
          });
        },
      },
    ];

    const items = user?.salesRepFiberCompanies
      ? user?.salesRepFiberCompanies?.map((company) => {
          switch (company.fiberCompanyId) {
            case 2:
              return lightCurve;
            case 9:
              return fastwyre;
            case 10:
              return pointBroadband;
            case 13:
              return astound;
            default:
              return [];
          }
        })
      : [];

    if (user.userType === "SalesOrgAdmin" || user.userType === "SalesRep") {
      return items.flat();
    }

    if (user.userType === "SuperAdmin") {
      return lightCurve.concat(fastwyre).concat(pointBroadband);
    }
  };
  const [isEmailEditable, setIsEmailEditable] = useState(false);

  const handleEmailClick = async (e) => {
    if (isEmailEditable) return; // Avoid showing alert if already editable

    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure you want to Edit this Email?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      setIsEmailEditable(true);
    } else {
      e.target.blur(); // Prevent focus if "No" is clicked
    }
  };

  const handlePreviewOffer = () => {
    if (!user.fiberCompanyId && !lead?.fiberCompanyId) {
      setIsFiberCompanyModalOpen(true);
    } else {
      window.open(
        `${window.location.origin}/preview-offer/${user.id}/${
          lead?.fiberCompanyId ? lead?.fiberCompanyId : user.fiberCompanyId
        }/${lead?.id}`
      );
    }
  };

  const confirmFiberCompanySelection = () => {
    if (fiberCompanyId) {
      setIsFiberCompanyModalOpen(false);
      window.open(
        `${window.location.origin}/preview-offer/${user.id}/${fiberCompanyId}/${lead?.id}`
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a fiber company before proceeding.",
      });
    }
  };

  return (
    <>
      <div className="sm:p-4 p-1 scrollable-content ">
        <DigitalToolBelt
          setOpen={setOpenDigitalToolBelt}
          open={openDigitalToolBelt}
          lead={lead}
        />
        <CustomerInfo
          lead={lead}
          open={openCustomerInfo}
          setOpen={setOpenCustomerInfo}
          customer={lead.fidiumCustomer}
          setReload={setReload}
        />
        <FreeInstallForm
          lead={lead}
          type={offerOrOrder}
          fiberHouse={fiberHouse}
          open={lightOpenModal}
          setOpen={setLightOpenModal}
          setReload={setReload}
        />
        <AppointmentForm
          lead={lead}
          open={openModal}
          setOpen={setOpenModal}
          setReload={setReload}
        />
        <ScheduleForm
          lead={lead}
          fidiumCustomer={fidiumCustomer}
          fiberCompany={fidiumCustomer?.fiberCompany}
          open={openInstallTimeModal}
          setOpen={setOpenInstallTimeModal}
          setReload={setReload}
        />
        <ReviewModal
          open={openReviewModal}
          setOpen={setOpenReviewModal}
          lead={lead}
        />
        <SpeedTestModal
          user={user}
          lead={lead}
          open={speedTestOpenModal}
          setOpen={setSpeedTestOpenModal}
          beforeImageUrl={beforeImageUrl}
          setBeforeImageUrl={setBeforeImageUrl}
          afterImageUrl={afterImageUrl}
          setAfterImageUrl={setAfterImageUrl}
          handleImageUpload={handleImageUpload}
        />
        <ContextMenu
          anchorEl={openContextMenu}
          setAnchorEl={setOpenContextMenu}
          menuItems={getMenuItemsForConnector()}
          handleClose={() => {
            setOpenContextMenu(null);
          }}
        />
        <CustomNote
          open={isCustomNoteOpen}
          setOpen={setIsCustomNoteOpen}
          lead={lead}
          setReload={setReload}
          setCustomNotes={setCustomNotes}
        />

        {/* Modal for Expanding QR */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-96 space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold">QR Code</h3>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-black font-bold text-xl"
                >
                  X
                </button>
              </div>
              {/* Display QR Code inside modal */}
              <QRCode
                id="qrCode"
                value={`${window.location.origin}/digital-business-center/${
                  user.id
                }${lead.fiberCompanyId ? "/" + lead.fiberCompanyId : ""}`}
                size={300}
                className="mx-auto shadow-md"
              />
              {/* Download Button */}
              <button
                onClick={downloadQrCode}
                className="w-full py-2 text-white bg-blue-600 rounded-full"
              >
                Download QR Code
              </button>
            </div>
          </div>
        )}

        {/* Lead Details */}
        <div className="flex md:flex-row flex-col md:space-x-4 md:justify-between justify-start">
          <div className=" p-3 mt-2 rounded-[1.5rem] border-4 border-gray-200 md:w-2/3 w-full">
            <div className="flex space-x-2 rounded-lg justify-between">
              <div className="flex space-x-1 items-center">
                <div className="h-10">
                  {/* {" "} */}
                  {lead?.fiberCompany?.logo && (
                    <>
                      {/* <h1 > logo</h1> */}
                      <img
                        src={
                          "data:image/png;base64," + lead?.fiberCompany?.logo
                        }
                        alt="logo"
                        className="h-full"
                      />
                    </>
                  )}
                </div>
                <div
                  style={{
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (disabled) {
                      return;
                    }
                    setOpenContextMenu(e.currentTarget);
                  }}
                >
                  <MdRoute className="icon" size={20} color="#2761D0" />
                </div>

                {!disabled && (
                  <div className="flex items-center space-x-1">
                    {/* <p>Owned By</p> */}
                    {
                      // (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                      salesReps
                        ?.filter((rep) =>
                          lead?.fiberHouse?.salesRepFiberHouses.some(
                            (salesRep) => salesRep.salesRepId === rep.id
                          )
                        )
                        .slice(0, 1)
                        .map((rep, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center space-x-0"
                            >
                              <MdBolt style={{ color: "#a0a4ac" }} size={30} />
                              <p className="sm:text-sm text-xs">
                                {rep.firstName + " " + rep.lastName}
                              </p>
                            </div>
                          );
                        })
                    }
                    {salesReps?.filter((rep) =>
                      lead?.fiberHouse?.salesRepFiberHouses.some(
                        (salesRep) => salesRep.salesRepId === rep.id
                      )
                    ).length > 1 && (
                      <p
                        className="sm:text-sm text-xs cursor-pointer"
                        onClick={() => setIsSalesRepsModalOpen(true)}
                      >
                        {"+" +
                          (salesReps?.filter((rep) =>
                            lead?.fiberHouse?.salesRepFiberHouses.some(
                              (salesRep) => salesRep.salesRepId === rep.id
                            )
                          ).length -
                            1) +
                          " other"}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="flex space-x-1 items-center">
                {/* {
                                    <div className=" h-6 rounded-full flex items-center justify-center cursor-pointer">
                                        <AudioRecorder leadId={lead.id} pitch={lead.pitch} setReload={setReload} />
                                    </div>
                                } */}

                <div className=" rounded-full flex items-center justify-center cursor-pointer">
                  {user.userType === "SuperAdmin" &&
                    ((lead?.fidiumCustomer && lead?.fidiumCustomer?.crmId) ||
                      (lead.offer && lead.offer.crmId)) && (
                      <div className="h-6 w-6 rounded-full flex items-center justify-center cursor-pointer">
                        <div
                          onClick={() => {
                            let crmId = lead?.fidiumCustomer?.crmId
                              ? lead?.fidiumCustomer?.crmId
                              : lead.offer.crmId;
                            window.open(
                              `https://app.gohighlevel.com/v2/location/2bpuwFoxpdxZs2Oq2Z8a/contacts/detail/${crmId}`
                            );
                          }}
                        >
                          <img
                            src="/highlevelLogo.png"
                            alt="HighLevel"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                            color="#2761D0"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (disabled) {
                      return;
                    }
                    goToContactCenterForSingleCustomer(lead);
                  }}
                  className="h-6 w-6 rounded-full flex items-center justify-center cursor-pointer"
                >
                  <RiContactsBook2Line fontSize="large" size={25} />
                </div>
                <div className="w-8 h-8 rounded-full flex items-center justify-center cursor-pointer">
                  <img
                    className="h-6 w-6"
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      setOpenCustomerInfo(true);
                    }}
                    src="/images/digital_tool_belt/customerInfo.svg"
                    alt="Digital Tool Belt"
                  />
                </div>
                {/* <div
                  onClick={(e) => {
                    e.stopPropagation();
                    goToSelfieUploadPopup();
                  }}
                  className="w-8 h-8 rounded-full flex items-center justify-center cursor-pointer ml-2"
                >
                  <RiCameraFill fontSize="large" height={100} width={100} />
                </div> */}

                {/* Conditional rendering of the selfie upload pop-up */}
                {showPopup && (
                  <SelfieUploadPopup onClose={() => setShowPopup(false)} />
                )}
                {/* <div>
                                    <button onClick={() => setShowPopup(true)}>
                                      Open Selfie Upload
                                    </button>

                                    {showPopup && (
                                      <SelfieUploadPopup onClose={() => setShowPopup(false)} />
                                    )}
                                </div> */}
              </div>
            </div>

            <div>
              <div className="flex items-center space-x-4 mt-2 ">
                <div className="w-20 h-20 rounded-full flex items-center relative justify-center ">
                  <img
                    src={
                      "data:image/png;base64," +
                      mapIcons.find(
                        (assignedMapIcons) => assignedMapIcons.id === mapIcon
                      )?.image
                    }
                    alt="Map Icon"
                    width={100}
                    height={100}
                  />
                  {isBundled && (
                    <span className="absolute bottom-0 right-0">TV</span>
                  )}
                </div>
                <div>
                  {/* <h3 className="font-semibold">{name}</h3> */}
                  <input
                    type="text"
                    value={name}
                    disabled={disabled}
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                    className=" outline-none focus:outline-none text-lg font-semibold w-40"
                  />
                  <span className=" flex items-center space-x-2">
                    <span>
                      {/* {" "} */}
                      {/* <Rating
                                                value={fidiumCustomer?.feedbacks[0]?.rating}

                                                sx={{ color: "#faaf00 !important" }}
                                                


                                                readOnly
                                            /> */}
                      <Rating
                        value={fidiumCustomer?.feedbacks[0]?.rating || 0}
                        readOnly
                        icon={
                          <StarIcon
                            fontSize="inherit"
                            style={{ color: "#faaf00" }}
                          />
                        }
                        emptyIcon={
                          <StarIcon
                            fontSize="inherit"
                            style={{ color: "#d3d3d3" }}
                          />
                        }
                      />
                    </span>
                  </span>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm text-gray-600">{getCustomerLine()}</p>
                    {lead?.fidiumCustomer?.isInvalid === true ||
                    markedInvalid ? (
                      <IoIosAddCircle
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          markCustomerAsValid(lead);
                        }}
                        color="#81848A"
                        size={25}
                      />
                    ) : lead.fidiumCustomer != null ? (
                      <IoIosRemoveCircle
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          markCustomerAsInvalid(lead);
                        }}
                        color="#000000"
                        size={25}
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 mt-2 text-sm text-gray-600">
                <div className="flex items-center space-x-2 border border-gray-100 rounded-lg p-1">
                  <MapPin size={16} />
                  <Tooltip title={address} placement="top">
                    <input
                      type="text"
                      value={address}
                      placeholder="No Address"
                      disabled={disabled}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        shouldSaveAddress.current = true;
                      }}
                      // className=" "
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  </Tooltip>
                  {address && (
                    <FaRegCopy
                      style={{
                        cursor: "pointer",
                        marginLeft: "0.5rem",
                      }}
                      size={15}
                      color="#84A1C8"
                      onClick={() => {
                        if (disabled) {
                          return;
                        }
                        copyToClipboard(address, "Address Copied to Clipboard");
                      }}
                    />
                  )}
                </div>
                <div className="flex items-center space-x-1 w-full">
                  <div className="w-1/2 flex items-center  space-x-2 border border-gray-100 rounded-lg p-1">
                    <Phone size={16} />
                    <input
                      type="text"
                      value={phone}
                      disabled={disabled}
                      onChange={(e) => setPhone(e.target.value)}
                      className="outline-none focus:outline-none"
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                    {phone && (
                      <FaRegCopy
                        style={{
                          cursor: "pointer",
                          marginLeft: "0.5rem",
                        }}
                        size={15}
                        color="#84A1C8"
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          copyToClipboard(
                            extractPhoneNumberDetails(phone),
                            "Phone Copied to Clipboard"
                          );
                        }}
                      />
                    )}
                  </div>
                  <div className="w-1/2 flex items-center space-x-2 border border-gray-100 rounded-lg p-1">
                    <Mail size={16} />
                    <input
                      disabled={disabled}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="outline-none focus:outline-none"
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />

                    {email && (
                      <FaRegCopy
                        style={{
                          cursor: "pointer",
                          marginLeft: "0.5rem",
                        }}
                        size={15}
                        color="#84A1C8"
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          copyToClipboard(email, "Email Copied to Clipboard");
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {
              <div className=" mt-4 flex  gap-1 items-center justify-center cursor-pointer w-full">
                <div
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className=" flex space-x-1 w-1/2 p-2 border border-black rounded-lg"
                >
                  <p>
                    {" "}
                    <Calendar
                      size={15}
                      className="text-[var(--primary-color)]"
                    />
                  </p>

                  <h1 className="text-sm text-[var(--primary-color)]">
                    {" "}
                    Set Follow-Up
                  </h1>
                </div>
                <div className=" flex space-x-1 w-1/2 border border-gray-200 p-2 rounded-lg">
                  {lead?.appointmentDateTime ? (
                    <p className="whitespace-nowrap text-sm">
                      {new Date(
                        lead?.appointmentDateTime
                      ).toLocaleDateString() +
                        " " +
                        new Date(
                          lead?.appointmentDateTime
                        ).toLocaleTimeString()}
                    </p>
                  ) : (
                    <p className=" text-sm">No Appointment</p>
                  )}
                </div>
              </div>
            }
            {lead?.fidiumCustomer?.installDateTime && (
              <div className="flex items-center justify-between mt-4">
                <div className=" mt-4 flex  gap-1 items-center justify-center w-full">
                  <div className=" flex space-x-1 w-1/2 p-2 border border-black rounded-lg">
                    <p>
                      {" "}
                      <Calendar
                        size={15}
                        className="text-[var(--primary-color)]"
                      />
                    </p>

                    <h1 className="text-sm text-[var(--primary-color)]">
                      {" "}
                      Install Date
                    </h1>
                  </div>
                  <div className=" flex space-x-1 w-1/2 border border-gray-200 p-2 rounded-lg">
                    {lead?.fidiumCustomer?.installDateTime ? (
                      <p className="whitespace-nowrap text-sm">
                        {new Date(
                          lead?.fidiumCustomer?.installDateTime
                        ).toLocaleDateString() +
                          " " +
                          new Date(
                            lead?.fidiumCustomer?.installDateTime
                          ).toLocaleTimeString()}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            <div
              className="flex gap-2 mt-4 items-center justify-center border-2 bg-white cursor-pointer border-[#022780] rounded-xl px-4 py-1 relative"
              ref={containerRef}
            >
              <div
                className="flex w-full items-center justify-between gap-8"
                onClick={executeMainAction}
              >
                <img
                  src={bot}
                  alt="bot"
                  style={{ width: "40px", height: "40px" }}
                />
                <span className="font-bold uppercase sm:text-base text-sm text-[#022780]">
                  {action}
                </span>

                <button
                  onClick={(e) => {
                    if (disabled) {
                      return;
                    }
                    e.stopPropagation();
                    setShowDropdown(!showDropdown);
                  }}
                  className="relative focus:outline-none px-2 hover:bg-gray-100 rounded"
                >
                  ▼
                  {showDropdown && (
                    <div className="absolute top-full right-0 mt-1 bg-white border w-[15rem] border-[#022780] rounded-lg shadow-lg z-50">
                      {getActions().map((option) => (
                        <div
                          key={option.label}
                          onClick={() => {
                            setAction(option.label);
                            option.onClick();
                            setShowDropdown(false);
                          }}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-[#022780]"
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          {lead.fidiumCustomer == null && (
            <div
              className="sm:hidden block MainLeadCard_MapIcons "
              style={{
                marginTop: "1em",
                boxShadow: "none",
              }}
            >
              <h1 className="text-lg font-bold">Lead Status</h1>
              <MapIconDisplay
                isCustomer={lead.fidiumCustomer != null}
                removeLightningBolts={true}
                setMapIcon={setMapIcon}
                selectedFiberHouse={fiberHouse}
              />
            </div>
          )}

          <div className=" sm:hidden flex md:flex-row flex-col items-center md:justify-between md:space-x-1 w-full">
            {mapIcons?.map((icon, index) =>
              icon.id === mapIcon ? (
                getDisplayComponents(icon.id)?.length > 0 ? (
                  <div className="mt-4  border-2 border-gray-200 rounded-lg p-2 md:w-1/2 w-full">
                    <div className="flex justify-between items-center ">
                      <div className="flex justify-between gap-1">
                        <h3 className="font-bold text-gary-700">Quick Notes</h3>
                        <button
                          onClick={() => {
                            setIsCustomNoteOpen(true);
                          }}
                          className="border  border-[var(--primary-color)] rounded-lg text-[var(--primary-color)] text-sm p-1"
                        >
                          + Custom Note
                        </button>
                      </div>
                      <div className="flex justify-between gap-1">
                        <button
                          onClick={() => {
                            setIsQuickNotesOpen(false);
                            setIsViewNotesOpen(false);
                          }}
                          className="border rounded-lg text-gray-600 text-sm p-1"
                        >
                          + Create Notes
                        </button>
                      </div>
                    </div>

                    <div
                      className="flex  gap-3 mt-3 overflow-x-auto  h-24 "
                      style={{
                        scrollbarWidth: "none",
                      }}
                    >
                      {mapIcons?.map((icon, index) =>
                        icon.id === mapIcon ? (
                          icon.name === "Cancelled" ||
                          icon.name === "Pending Cancel" ? (
                            <p className="p-3 flex flex-col items-center w-full text-gray-700 border border-gray-200 rounded-lg">
                              {lead?.fidiumCustomer?.issueDescription}
                            </p>
                          ) : (
                            customNotes?.map(
                              (note, index) =>
                                icon.id == note.mapIconId && (
                                  <button
                                    onClick={() => {
                                      handleQuickNoteClick(note.note);
                                    }}
                                    key={index}
                                    className="p-3 flex flex-col items-center w-20 text-gray-700"
                                  >
                                    <img
                                      className="w-5 h-5"
                                      src={note?.icon?.url}
                                    />
                                    <span className="text-sm mt-1">
                                      {note.note}
                                    </span>
                                  </button>
                                )
                            )
                          )
                        ) : null
                      )}
                      {mapIcons?.map((icon, index) =>
                        icon.id === mapIcon ? (
                          icon.name === "Cancelled" ||
                          icon.name === "Pending Cancel" ? (
                            <p className="p-3 flex flex-col items-center w-full text-gray-700 border border-gray-200 rounded-lg">
                              {lead?.fidiumCustomer?.issueDescription}
                            </p>
                          ) : (
                            getDisplayComponents(icon.id)?.map((item, idx) => (
                              <button
                                onClick={() => {
                                  if (disabled) {
                                    return;
                                  }
                                  handleQuickNoteClick(item.label);
                                }}
                                key={`${index}-${idx}`}
                                className="p-3 flex flex-col items-center w-20 text-gray-700"
                              >
                                <item.component className="w-5 h-5" />
                                <span className="text-sm mt-1">
                                  {item.label}
                                </span>
                              </button>
                            ))
                          )
                        ) : null
                      )}
                    </div>
                  </div>
                ) : null
              ) : null
            )}

            <div className="mt-4  border-2 p-2 border-gray-200 rounded-lg md:w-1/2 w-full">
              <div className="flex justify-between items-center ">
                <h3 className="font-bold text-gray-700">Notes</h3>
                <div className="flex justify-between gap-1">
                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      setIsViewNotesOpen(false);
                    }}
                    className="border border-[var(--primary-color)] rounded-lg text-[var(--primary-color)] text-sm p-1"
                  >
                    + Create Notes
                  </button>

                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      setIsViewNotesOpen(true);
                      GetQuickNotes();
                    }}
                    className="border border-[var(--primary-color)] rounded-lg text-[var(--primary-color)] text-sm p-1"
                  >
                    View All Notes
                  </button>
                </div>
              </div>

              {isViewNotesOpen && (
                <div
                  //  {...QuickNotehandlers}
                  className="relative mt-2 w-full rounded-lg  text-gray-600  h-24 overflow-y-auto space-y-2"
                  // style={{ scrollbarWidth: "none" }}
                >
                  {notes.map((note, index) => (
                    <Tooltip
                      key={index}
                      title={`${new Date(
                        note.timestamp
                      ).toLocaleDateString()} @ ${new Date(
                        note.timestamp
                      ).toLocaleTimeString()} | ${note.note}`}
                    >
                      <div className="border border-gray-200 p-3  h-8 overflow-hidden whitespace-nowrap rounded-lg flex items-center">
                        {new Date(note.timestamp).toLocaleDateString()} @{" "}
                        {new Date(note.timestamp).toLocaleTimeString()} |{" "}
                        {note.note}
                      </div>
                    </Tooltip>
                  ))}

                  {/* {totalPages > 1 && (
                                        <div className="absolute top-1/2 right-2 transform -translate-y-1/2 flex flex-col gap-1">
                                          {visibleDots.map((index) => (
                                            <span
                                              key={index}
                                              className={`h-2 w-2 rounded-full ${index === currentPage ? "bg-blue-600" : "bg-blue-300"}`}
                                              onClick={() => setCurrentPage(index)}
                                              style={{ cursor: "pointer" }}
                                            />
                                          ))}
                                        </div>
                                      )} */}
                </div>
              )}

              {!isViewNotesOpen && (
                <div className="mt-2 w-full relative rounded-lg p-2 border-gray-200 h-24 ">
                  <textarea
                    value={note}
                    onChange={(e) => {
                      // Ensure the icon stays at the start
                      if (!e.target.value.startsWith("📝 ")) {
                        setNote("📝 " + e.target.value.replace(/^📝\s*/, ""));
                      } else {
                        setNote(e.target.value);
                      }
                    }}
                    className="w-full h-full border-none outline-none"
                    placeholder="Type your notes here..."
                  ></textarea>

                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      handleQuickNoteClick(note);
                      setNote("");
                      setIsQuickNotesOpen(true);
                    }}
                    className="bg-blue-600 text-white rounded-full text-lg  
                                              absolute bottom-2 right-2 px-2 "
                  >
                    +
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Manage Lead */}
          <div className=" mt-4 rounded-lg md:w-1/3 w-full min-w-[300px] ">
            <div className="flex items-center justify-between">
              <h3 className="font-semibold">Manage Lead</h3>
              <div
                style={{
                  width: "fit-content",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  deleteFiberHouse();
                }}
              >
                <MdDeleteOutline className="icon" size={25} color="red" />
              </div>
            </div>
            <div className="space-y-2 mt-2">
              <button
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setIndex(13);
                }}
                className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
              >
                See Activity <RxActivityLog />
              </button>
              {user.userType === "SuperAdmin" && (
                <button
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    setAccountStatusModal(true);
                  }}
                  className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
                >
                  Change Account Status <BsFillLightningChargeFill />
                </button>
              )}
              {user.userType !== "SalesRep" && (
                <button
                  className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    setIsChangeOwnershipOpen(true);
                  }}
                >
                  Change Ownership
                  <AdminPanelSettingsIcon />
                </button>
              )}
              <button
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  updateCoordinates(lead?.fiberHouse);
                }}
                className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
              >
                Reset Lead Location <LocationOn />
              </button>
              {lead?.fidiumCustomer == null && (
                <button
                  onClick={handlePreviewOffer}
                  className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
                >
                  Preview Offer <VscOpenPreview />
                </button>
              )}
              {/* Fiber Company Modal */}
              <Modal
                open={isFiberCompanyModalOpen}
                onClose={() => setIsFiberCompanyModalOpen(false)}
                minHeight={"80vh"}
              >
                <div
                  className="custom_modal"
                  style={{
                    minHeight: "70vh",
                    maxHeight: "90vh",
                    paddingTop: "20px",
                  }}
                >
                  <h2 className="text-lg font-semibold mb-4">
                    Select Fiber Company
                  </h2>
                  <div className="inputs">
                    <FiberCompanySelector
                      companiesWithOfferTemplateOnly={true}
                      setUser={setFiberCompanyId}
                      disabled={false}
                      placeholder="Select a Company"
                      className="fiber_company_selector"
                    />
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={confirmFiberCompanySelection}
                      className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
              {/* <button className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black" >Create Tag <FaTag /></button> */}
              {user.userType === "SalesRep" && (
                <button
                  className="w-full bg-gray-200 p-2 rounded-lg flex justify-between border border-black"
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    setIsChangeOwnershipOpen(true);
                  }}
                  disabled={lead.fidiumCustomer != null}
                >
                  Share Lead
                  <IoShareSocial />
                </button>
              )}
            </div>
            <div
              onClick={() => {
                // window.open("/digital-business-center", "_blank");
              }}
              className=" mt-4 flex gap-1 items-center justify-between cursor-pointer w-full h-20 rounded-lg p-1"
              style={{
                backgroundImage: `url(${DigitalBusineesImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h1 className="md:text-md text-white md:font-bold font-semibold text-md">
                {" "}
                Digital Business Card
              </h1>
              <div className=" flex space-x-1 items-center h-[3rem] object-contain ">
                {/* <img
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsModalOpen(true);
                                    }}
                                    src={QrImage}
                                    alt="QrImage"
                                    className=" bg-white p-1 rounded-lg h-full "
                                /> */}
                <div className="bg-white p-2 rounded-lg">
                  <QrCode
                    size={30}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (disabled) {
                        return;
                      }
                      setIsModalOpen(true);
                    }}
                  />
                </div>
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    if (disabled) {
                      return;
                    }
                    share();
                  }}
                  className="text-[var(--primary-color)] bg-white rounded-lg flex  space-x-1 items-center h-[3rem] sm:p-1 p-4 gap-2 text-xl sm:text-xs "
                >
                  {" "}
                  <Send size={20} /> Send{" "}
                </p>
              </div>
            </div>

            {/* {(
                            <div className=" mt-4 sm:flex hidden gap-1 items-center justify-center cursor-pointer w-full">
                                <div
                                    onClick={() => {
                                        setOpenModal(true);
                                    }}
                                    className=" flex space-x-1 w-1/2 p-2 border border-black rounded-lg"
                                >
                                    <p>
                                        {" "}
                                        <Calendar
                                            size={15}
                                            className="text-[var(--primary-color)]"
                                        />
                                    </p>

                                    <h1 className="text-sm text-[var(--primary-color)]">
                                        {" "}
                                        Set Follow-Up
                                    </h1>
                                </div>
                                <div className=" flex space-x-1 w-1/2 border border-gray-200 p-2 rounded-lg">
                                    {lead?.appointmentDateTime ? (
                                        <p className="whitespace-nowrap text-sm">
                                            {new Date(
                                                lead?.appointmentDateTime
                                            ).toLocaleDateString() +
                                                " " +
                                                new Date(
                                                    lead?.appointmentDateTime
                                                ).toLocaleTimeString()}
                                        </p>
                                    ) : (
                                        <p className=" text-sm">No Appointment</p>
                                    )}
                                </div>
                            </div>
                        )} */}

            {/* {
                            lead.fidiumCustomer &&
                            <div className=" mt-4 flex gap-1 items-center justify-center cursor-pointer  w-full">
                                <div
                                    onClick={() => {
                                        setOpenInstallTimeModal(true);
                                    }}
                                    className=" flex space-x-2 w-1/2 p-2 border border-black  rounded-lg"
                                >
                                    <p>
                                        {" "}
                                        <Calendar size={15} className="text-[var(--primary-color)]" />
                                    </p>

                                    <h1 className="text-sm text-[var(--primary-color)]">
                                        {" "}
                                        Set Installation
                                    </h1>
                                </div>

                                <div className=" flex space-x-2 w-1/2 border border-gray-200 p-2 rounded-lg">
                                    {lead?.fidiumCustomer?.installDateTime ? (
                                        <p className=" text-xs whitespace-nowrap ">
                                            {" "}
                                            {new Date(
                                                lead?.fidiumCustomer?.installDateTime
                                            ).toLocaleDateString() +
                                                " " +
                                                new Date(
                                                    lead?.fidiumCustomer?.installDateTime
                                                ).toLocaleTimeString("en-US", {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                })}{" "}
                                            -{" "}
                                            {lead?.fidiumCustomer?.updatedInstallDateTimeTo
                                                ? new Date(
                                                    lead?.fidiumCustomer?.updatedInstallDateTimeTo
                                                ).toLocaleTimeString("en-US", {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                })
                                                : new Date(
                                                    lead?.fidiumCustomer?.originalIntstallDateTimeTo
                                                ).toLocaleTimeString("en-US", {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                })}{" "}
                                        </p>
                                    ) : (
                                        <p className=" text-sm">Not Scheduled</p>
                                    )}
                                </div>
                            </div>
                        } */}
          </div>
        </div>
      </div>
      <Modal
        open={isChangeOwnershipOpen}
        onClose={() => {
          setIsChangeOwnershipOpen(false);
        }}
      >
        <div
          style={{
            overflow: "unset",
          }}
          className="custom_modal"
        >
          <div className="flex flex-col gap-2 w-full">
            <div className="relative flex justify-between items-center">
              <h1> Manage Permissions</h1>
              <IoMdClose
                className="absolute top-0 right-0 w-6 h-6 hover:cursor-pointer hover:text-red-500"
                onClick={() => {
                  setIsChangeOwnershipOpen(false);
                }}
              />
            </div>

            {/* Search Input */}
            <div className="p-6">
              <div className="relative">
                <UserSelector
                  setUser={assignLeadsToReps}
                  conversationType="Private"
                  SalesOrgId={
                    user.userType === "SuperAdmin" ? null : user.salesOrgId
                  }
                  placeholder="Select Reps To Assign"
                  customStyles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused
                        ? "var(--primary-color)"
                        : provided.backgroundColor,
                      color: state.isFocused ? "white" : provided.color,
                    }),
                  }}
                />
              </div>

              {/* Assigned Users */}
              <div className="mt-6">
                <h3 className="text-sm text-left font-medium text-gray-500 mb-3">
                  Assigned Users
                </h3>
                <div className="max-h-[280px] overflow-y-auto border border-gray-200 rounded-lg">
                  {assignedUsers?.map((user) => (
                    <div
                      key={user.userId}
                      className="flex items-center justify-between p-3 group hover:bg-gray-50 transition-colors"
                    >
                      <div className="flex items-center gap-3">
                        <span className="font-medium text-gray-700">
                          {user.name}
                        </span>
                      </div>
                      <IoMdClose
                        className="w-6 h-6 hover:cursor-pointer hover:text-red-500"
                        onClick={() => {
                          removeRepFromLeads(
                            user.userId,
                            user.salesRepFiberHouseId
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isSalesRepsModalOpen}
        onClose={() => setIsSalesRepsModalOpen(false)}
      >
        <div className="custom_modal">
          <div className="flex flex-col gap-2 w-full">
            <div className="relative flex justify-between items-center">
              <h1>Assigned Sales Reps</h1>
              <IoMdClose
                className="absolute top-0 right-0 w-6 h-6 hover:cursor-pointer hover:text-red-500"
                onClick={() => setIsSalesRepsModalOpen(false)}
              />
            </div>
            <div className="p-6">
              <div className="max-h-[280px] overflow-y-auto border border-gray-200 rounded-lg">
                {salesReps
                  ?.filter((rep) =>
                    lead?.fiberHouse?.salesRepFiberHouses.some(
                      (salesRep) => salesRep.salesRepId === rep.id
                    )
                  )
                  .map((rep, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 group hover:bg-gray-50 transition-colors"
                    >
                      <span className="font-medium text-gray-700">
                        {rep.firstName + " " + rep.lastName}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LeadData;

import React, { useEffect } from "react";
import Swal from "sweetalert2";
import CreateOfferTemplateReq from "../../../Requests/OfferTemplate/CreateOfferTemplateReq";
import OfferApi from "../../../API/OfferApi";
import SearchOfferTemplatesReq from "../../../Requests/OfferTemplate/SearchOfferTemplatesReq";
import { useState } from "react";
import { Navigation, A11y, EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import "./ReviewComponent.scss";
import "swiper/css";
import "swiper/css/navigation";
import { set } from "lodash";
const CreateOfferTemplateModal = ({
  company,
  offerTemplate,
  setOfferTemplate,
  reload,
}) => {
  const [name, setName] = useState("");
  const [offers, setOffers] = useState([]);
  const [discount, setDiscount] = useState("");
  const [bulletPoints, setBulletPoints] = useState("");
  const [promos, setPromos] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [id, setId] = useState("");
  const[offer,setOffer]=useState(null)

  const createOfferTemplate = async () => {
    Swal.fire({
      title: offerTemplate
        ? "Updating Offer Template"
        : "Creating Offer Template",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!name) {
      Swal.fire({
        icon: "error",
        title: "Offer Template Name is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!discount) {
      Swal.fire({
        icon: "error",
        title: "Discount is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!bulletPoints) {
      Swal.fire({
        icon: "error",
        title: "Bullet Points is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!promos) {
      Swal.fire({
        icon: "error",
        title: "Promos is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new CreateOfferTemplateReq();
    req.name = name;
    req.discount = discount;
    req.bulletPoints = bulletPoints;
    req.promos = promos;
    req.id = id ? id : null;
    req.fiberCompanyId = company?.id;
    const res = id
      ? await OfferApi.EditOfferTemplate(req)
      : await OfferApi.CreateOfferTemplate(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: offerTemplate
          ? "Offer Template Updated Successfully."
          : "Offer Template Created Successfully",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      searchOfferTemplate();
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to create Offer Template",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const searchOfferTemplate = async () => {
    const req = new SearchOfferTemplatesReq();
    req.fiberCompanyId = company.id;
    const res = await OfferApi.SearchOfferTemplate(req);

    if (res?.status?.success) {
        setOffers(res?.data);
        console.log("offers: ", res?.data);
      setOfferTemplate(res?.data[0])
      setName(res?.data[0]?.name)
      setDiscount(res?.data[0]?.discount)
      setBulletPoints(res?.data[0]?.bulletPoints)
      setPromos(res?.data[0]?.promos)
      setId(res?.data[0]?.id)
     
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to fetch Offer Template",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (company) searchOfferTemplate();
  }, [company, reload]);

  return (
    <div className="create_offer_template">
      <div className="form">
        <img
          style={{ maxHeight: "100px", margin: "auto" }}
          src={"data:image/png;base64," + company.logo}
          alt={company.name}
        />
        <h2>Create Offer Template</h2>
        <div className=" max-x[100vw] overflow-hidden ">
          <Swiper
            modules={[Navigation, A11y, EffectCoverflow, Pagination]}
            spaceBetween={5}
            slidesPerView={3} // Dynamically adjust slidesPerView
            navigation={true}
            // pagination={{
            //   clickable: true,
            // }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={(swiper) => {
              if (swiper.isEnd) {
                setPageNumber(pageNumber + 1);
              }
            }}
          >
            <div className="flex items-center ">
              {offers.map((offer) => {
                return (
                  <SwiperSlide key={offer.id}>
                    <div
                      onClick={() => {
                        setIsAdd(false);
                        setName(offer.name);
                        setDiscount(offer.discount);
                        setBulletPoints(offer.bulletPoints);
                        setPromos(offer.promos);
                       setId(offer.id);
                      }}
                      className={`flex p-4 border  rounded-md justify-center items-center cursor-pointer ${
                        offer.id === id ? " border-blue-400" : "border-gray-300"
                      }`}
                    >
                      <p>{offer.name}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>

            {/* Add Button Slide */}
            <SwiperSlide key="add-button">
              <div
                className="flex p-4 border border-dashed border-gray-300 rounded-md justify-center items-center cursor-pointer"
                onClick={() => {
                  setIsAdd(true);
                  setName("");
                  setDiscount("");
                  setBulletPoints("");
                  setPromos("");
                  setId(null);
                }}
              >
                <p className="text-blue-500 font-semibold">+</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="input_div">
          <label>Offer Template Name</label>
          <input
            type="text"
            placeholder="Enter Offer Template Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="input_div">
          <label>Discount</label>
          <input
            type="number"
            placeholder="Enter Discount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
        </div>
        <div className="input_div">
          <label>Bullet Points</label>
          <p className="note" style={{ marginLeft: "0px" }}>
            Note: Each bullet point should start from a new line
          </p>
          <textarea
            rows={7}
            placeholder="Enter Bullet Points"
            value={bulletPoints}
            onChange={(e) => setBulletPoints(e.target.value)}
          />
        </div>
        <div className="input_div">
          <label>Promos</label>
          <p className="note" style={{ marginLeft: "0px" }}>
            Note: Each promo should start from a new line
          </p>
          <textarea
            rows={3}
            placeholder="Enter Promos"
            value={promos}
            onChange={(e) => setPromos(e.target.value)}
          />
        </div>
        <button
          onClick={() => {
            
          
            createOfferTemplate();
          }}
          style={{ backgroundColor: "#181E4B" }}
        >
          {isAdd ? "Create" : "Update"}
        </button>
      </div>
    </div>
  );
};

export default CreateOfferTemplateModal;

import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class MasterSheetApi {
  baseUrl = EnvSettings.url;
  CreateDynamicListUrl = "CreateDynamicList";
  GetAllDynamicListsUrl = "GetAllDynamicLists";
  GetLeadsByDynamicListIdUrl = "GetLeadsByDynamicListId";
  EditLeadActionsUrl = "EditLeadActions";
  ExportLeadsToDynamicListUrl = "ExportLeadsToDynamicList";
  ExportCandidatesUrl = "ExportCandidates";
  AssignDynamicListToUserUrl = "AssignDynamicListToUser";
  RemoveDynamicListFromUserUrl = "RemoveDynamicListFromUser";
  EditDynamicListColumnsUrl = "EditDynamicListColumns";
  GetDynamicListColumnsUrl = "GetDynamicListColumns";
  DeleteDynamicListUrl = "DeleteDynamicList";
  CreateStaticListUrl = "CreateStaticList";
  SearchListLeadHistoriesUrl = "SearchListLeadHistories";
  CreateCandidateDynamicListUrl = "CreateCandidateDynamicList";
  GetCandidatesByDynmicListIdUrl = "GetCandidatesByDynmicListId";

  async GetCandidatesByDynmicListId(req) {
    const url = `${this.baseUrl}${this.GetCandidatesByDynmicListIdUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async CreateCandidateDynamicList(req) {
    const url = `${this.baseUrl}${this.CreateCandidateDynamicListUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async SearchListLeadHistories(req) {
    const url = `${this.baseUrl}${this.SearchListLeadHistoriesUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async CreateStaticList(req) {
    const url = `${this.baseUrl}${this.CreateStaticListUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async DeleteDynamicList(req) {
    const url = `${this.baseUrl}${this.DeleteDynamicListUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async GetDynamicListColumns(req) {
    const url = `${this.baseUrl}${this.GetDynamicListColumnsUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async EditDynamicListColumns(req) {
    const url = `${this.baseUrl}${this.EditDynamicListColumnsUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async RemoveDynamicListFromUser(req) {
    const url = `${this.baseUrl}${this.RemoveDynamicListFromUserUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async AssignDynamicListToUser(req) {
    const url = `${this.baseUrl}${this.AssignDynamicListToUserUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async ExportLeadsToDynamicList(req) {
    const url = `${this.baseUrl}${this.ExportLeadsToDynamicListUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async ExportCandidates(req) {
    const url = `${this.baseUrl}${this.ExportCandidatesUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async EditLeadActions(req) {
    const url = `${this.baseUrl}${this.EditLeadActionsUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async CreateDynamicList(req) {
    const url = `${this.baseUrl}${this.CreateDynamicListUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async GetAllDynamicLists(req) {
    const url = `${this.baseUrl}${this.GetAllDynamicListsUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }

  async GetLeadsByDynamicListId(req) {
    const url = `${this.baseUrl}${this.GetLeadsByDynamicListIdUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${UsersApi.GetToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
    const result = await response.json();
    return result;
  }
}

export default new MasterSheetApi();

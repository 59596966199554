import { Box, CircularProgress, Rating } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MapContext } from "../../";
import MapIconApi from "../../../../../API/MapIconApi";
import StringIntReq from "../../../../../Requests/StringIntReq";
import userGrey from "../../../../../assets/images/userGrey.png";
import "./ActivityHistory.scss";
import { FaCircleInfo } from "react-icons/fa6";
import freeMonth from "./../../Images/payItForward.png";
import { BsFire } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { BsLightningChargeFill } from "react-icons/bs";
import { Modal } from "@mui/material";
import CreateCustomer from "../CreateCustomer";
import CustomerApi from "../../../../../API/CustomerApi";
import Swal from "sweetalert2";
import { useSwipeable } from "react-swipeable";
import { IoMdArrowRoundBack } from "react-icons/io";
const ActivityHistoryNew = ({ lead, user, fiberHouse, searchFiberHouses }) => {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activityHistory, setActivityHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState(2);
  let { mapIcons, setIndex } = useContext(MapContext);
  const [loading, setLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  // const toggleDropdown = () => {
  //     setIsDropdownOpen(!isDropdownOpen);
  // };

  const getActivityHistory = async () => {
    setLoading(true);
    const req = new StringIntReq();
    req.int = lead.id;
    console.log(req);
    const res = await MapIconApi.GetLeadActivityHistory(req);
    console.log(res);
    if (res?.status?.success) {
      setActivityHistory(res.data);
      console.log(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getActivityHistory();
  }, []);

  const extractNotesArray = (notes) => {
    // let notesArray = notes.split("*!*");
    //split by *!* and remove empty strings
    let notesArray = notes.split("!*!").filter((note) => note !== "");
    return notesArray;
  };
  const stopRef = React.useRef(null);
  const refPassthrough = (el) => {
    handlers.ref(el);
    stopRef.current = el;
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwiping: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedDown: (eventData) => {
      eventData.event.stopPropagation();
    },
  });
  // const CardOLD = ({ acHistory }) => {
  //     return (
  //         <div className="ActivityHistoryCard">
  //             <div className="ActivityHistoryCardHeader">
  //                 <h3>{acHistory?.activityType}</h3>
  //             </div>
  //             <div className="ActivityHistoryCardBody">
  //                 {
  //                     acHistory?.activityType === "Map Icon Change" ?
  //                         <div className="MapIconChangeDiv">
  //                             <div className="MapIconChange">
  //                                 <h3>Old Icon:</h3>
  //                                 <img src={"data:image/png;base64," + mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconFromId)?.image} alt="Old Map Icon" />
  //                             </div>
  //                             <div className="MapIconChange">
  //                                 <h3>New Icon:</h3>
  //                                 <img src={"data:image/png;base64," + mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconToId)?.image} alt="New Map Icon" />
  //                             </div>
  //                         </div>
  //                         :
  //                         <>
  //                             <h3>Notes:</h3>
  //                             {
  //                                 extractNotesArray(acHistory?.notes).map((note) => {
  //                                     return (
  //                                         <p>{note}</p>
  //                                     );
  //                                 })
  //                             }
  //                         </>
  //                 }
  //             </div>
  //             <div className="ActivityHistoryCardFooter">
  //                 <div>

  //                     {
  //                         acHistory?.salesRep?.profileImage?.url != null ?
  //                             <img src={acHistory?.salesRep?.profileImage?.url} alt="Sales Rep" /> :
  //                             <img src={userGrey} alt="Sales Rep" />
  //                     }
  //                     <h3>

  //                         {acHistory?.salesRep?.firstName + " " + acHistory?.salesRep?.lastName}</h3>
  //                 </div>
  //                 <h4>{new Date(acHistory?.timestamp).toLocaleString()}</h4>

  //             </div>
  //         </div>
  //     );
  // };

  const getIcon = (acHistory) => {
    switch (acHistory?.activityType) {
      case "Map Icon Change":
        if (acHistory?.salesRep?.profileImage?.url != null) {
          return (
            <img
              src={
                "data:image/png;base64," +
                mapIcons?.find(
                  (assignedMapIcons) =>
                    assignedMapIcons.id === acHistory?.iconToId
                )?.image
              }
              alt="Map Icon"
            />
          );
        } else {
          return <img src={userGrey} alt="Sales Rep" />;
        }
      case "Lead Create":
        return (
          <img
            src={
              "data:image/png;base64," +
              mapIcons?.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === acHistory?.iconFromId
              )?.image
            }
            alt="Map Icon"
          />
        );
      case "OwnerShip Change":
        if (acHistory?.salesRep?.profileImage?.url != null) {
          return (
            <img src={acHistory?.salesRep?.profileImage?.url} alt="Sales Rep" />
          );
        } else {
          return <img src={userGrey} alt="Sales Rep" />;
        }
      case "Lead Edit":
        if (acHistory?.salesRep?.profileImage?.url != null) {
          return (
            <img src={acHistory?.salesRep?.profileImage?.url} alt="Sales Rep" />
          );
        } else {
          return <img src={userGrey} alt="Sales Rep" />;
        }
      default:
        return (
          <img
            src={
              "data:image/png;base64," +
              mapIcons?.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === acHistory?.iconFromId
              )?.image
            }
            alt="Map Icon"
          />
        );
    }
  };

  const getTitle = (acHistory) => {
    switch (acHistory?.activityType) {
      case "Map Icon Change":
        return (
          <h3
            style={{
              textAlign: "left",
            }}
          >
            {
              mapIcons?.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === acHistory?.iconToId
              )?.name
            }
          </h3>
        );
      case "Lead Create":
        return (
          <h3
            style={{
              textAlign: "left",
            }}
          >
            {
              mapIcons?.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === acHistory?.iconFromId
              )?.name
            }
          </h3>
        );
      case "OwnerShip Change":
        if (acHistory?.salesRep == null) {
          return (
            <h3
              style={{
                textAlign: "left",
              }}
            >
              {"No Sales Rep Found"}
            </h3>
          );
        }
        return (
          <h3
            style={{
              textAlign: "left",
            }}
          >
            {acHistory?.salesRep?.firstName +
              " " +
              acHistory?.salesRep?.lastName}
          </h3>
        );
      case "Lead Edit":
        if (acHistory?.salesRep == null) {
          return (
            <h3
              style={{
                textAlign: "left",
              }}
            >
              {"No Sales Rep Found"}
            </h3>
          );
        }
        return (
          <h3
            style={{
              textAlign: "left",
            }}
          >
            {acHistory?.salesRep?.firstName +
              " " +
              acHistory?.salesRep?.lastName}
          </h3>
        );
      default:
        return (
          <h3
            style={{
              textAlign: "left",
            }}
          >
            {
              mapIcons?.find(
                (assignedMapIcons) =>
                  assignedMapIcons.id === acHistory?.iconFromId
              )?.name
            }
          </h3>
        );
    }
  };

  const getLine1 = (acHistory) => {
    switch (acHistory?.activityType) {
      case "Map Icon Change":
        return <span>Status Update</span>;
      case "Lead Create":
        return <span>Lead Created</span>;
      case "OwnerShip Change":
        return <span>Ownership Change</span>;
      case "Lead Edit":
        return <span>Lead Edited</span>;
      default:
        return <span>Lead Edited</span>;
    }
  };

  const getLine2 = (acHistory) => {
    if (acHistory?.salesOrg == null) {
      return <span>No Sales Org Found</span>;
    }
    return <span>{acHistory?.salesOrg?.name}</span>;
  };

  const getLine3 = (acHistory) => {
    switch (acHistory?.activityType) {
      case "Map Icon Change":
        if (acHistory?.salesRep == null) {
          return;
        }
        return (
          //display in one row styling

          <span style={{ display: "flex", flexDirection: "row" }}>
            Icon changed from
            {
              <img
                width="25px"
                height="20px"
                style={{ marginRight: "5px", marginLeft: "5px" }}
                src={
                  "data:image/png;base64," +
                  mapIcons?.find((icons) => icons.id === acHistory?.iconFromId)
                    ?.image
                }
                alt="Map Icon"
              />
            }
            to
            {
              <img
                width="25px"
                height="20px"
                style={{ marginRight: "5px", marginLeft: "5px" }}
                src={
                  "data:image/png;base64," +
                  mapIcons?.find((icons) => icons.id === acHistory?.iconToId)
                    ?.image
                }
                alt="Map Icon"
              />
            }
          </span>
        );
      case "Lead Create":
        return;
      case "OwnerShip Change":
        return <span>{acHistory?.notes}</span>;
      case "Lead Edit":
        return extractNotesArray(acHistory?.notes).map((note) => {
          return <p>Notes Updated</p>;
        });
      default:
        return extractNotesArray(acHistory?.notes).map((note) => {
          return <p>{note}</p>;
        });
    }
  };

  const getLine4 = (acHistory) => {
    if (acHistory?.activityType !== "OwnerShip Change") {
      if (acHistory?.salesRep == null) {
        return <span>No Sales Rep Found</span>;
      }
      return (
        <span>
          {acHistory?.salesRep?.firstName + " " + acHistory?.salesRep?.lastName}
        </span>
      );
    }
  };

  const Card = ({ acHistory }) => {
    return (
      <div className="NewActivityHistoryCard">
        <div className="ActivityHistoryCardHeader">
          <div className="ImgDiv">{getIcon(acHistory)}</div>
          <div className="TitleDiv">{getTitle(acHistory)}</div>
        </div>
        <div className="ActivityHistoryCardBody">
          <div className="Line1">{getLine1(acHistory)}</div>
          <div className="Line1">{getLine4(acHistory)}</div>
          <div className="Line2">{getLine2(acHistory)}</div>
          <div className="Line3">{getLine3(acHistory)}</div>
          <div className="Timestamp">
            <span>{new Date(acHistory?.timestamp).toLocaleString()}</span>
          </div>
        </div>
      </div>
    );
  };

  const [customer, setCustomer] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && lead) {
      getFidiumCustomer();
    }
  }, [lead, isLoaded]);

  useEffect(() => {
    if (customer != null) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [customer]);

  const getFidiumCustomer = async () => {
    const req = new StringIntReq();
    req.int = lead.fidiumCustomerId;
    const res = await CustomerApi.GetFidiumCustomerById(req);
    if (res?.status?.success) {
      setCustomer(res.data);
      console.log("Fidium Customer", res.data);
    }
  };

  const getFeedbackContent = (feedback) => {
    switch (feedback?.feedbackType) {
      case "Video":
        return (
          <video width="320" height="240" controls>
            <source
              src={`${feedback?.video?.url}#t=0.1`}
              muted
              t
              type="video/mp4"
            />
          </video>
        );
      case "Written":
        return <p>{feedback?.content}</p>;
      case "SpeedTest":
        return (
          <div className="SpeedTestDiv">
            <div className="SpeedTestImage">
              <span>Before</span>
              <img
                src={
                  "data:image/png;base64," + feedback?.speedTest?.beforeImage
                }
                alt="Before Speed Test"
              />
            </div>
            <div className="SpeedTestImage">
              <span>After</span>
              <img
                src={"data:image/png;base64," + feedback?.speedTest?.afterImage}
                alt="After Speed Test"
              />
            </div>
          </div>
        );
      default:
        return <p>{feedback?.content}</p>;
    }
  };
  const info = isLoaded ? (
    <div className="infoContainer">
      <div className="customerCard">
        <div className="image">
          {/* <img src={'/images/Avatar/dummy.png'} /> */}
          {customer?.user?.profileImage?.url != null ? (
            <img src={customer?.user.profileImage?.url} alt="Cust" />
          ) : (
            <img src={userGrey} alt="Cust" />
          )}
        </div>
        <div className="info">
          <h3>{customer?.user?.firstName + " " + customer?.user?.lastName}</h3>
          <div className="logo">
            <img src={"/images/nav/nav-logo.png"} />
          </div>
          <div className="location">
            <FaLocationDot size={20} color="black" />
            <p>{customer.user?.address}</p>
          </div>
        </div>
      </div>
      {customer?.feedback != null && (
        <div className="feedbackCard">
          <div className="rating">
            <Rating
              size={"large"}
              name="read-only"
              value={customer.feedback.rating}
              readOnly
            />
          </div>
          <div className="content">{getFeedbackContent(customer.feedback)}</div>
        </div>
      )}
      <div className="gridContainer">
        <div className="item">
          <h3>FIBER SPECIALIST</h3>
          <div className="fiber-card">
            <div className="image">
              {/* <img src={userGrey} /> */}
              {customer?.salesRep?.profileImage?.url != null ? (
                <img
                  src={customer?.salesRep?.profileImage?.url}
                  alt="Sales Rep"
                />
              ) : (
                <img src={userGrey} alt="Sales Rep" />
              )}
            </div>
            {/* <h3>PLACE HOLDER</h3> */}
            <h3>
              {customer?.salesRep?.firstName +
                " " +
                customer?.salesRep?.lastName}
            </h3>
          </div>
        </div>
        <div className="item">
          <h3>Lghtning Bundle</h3>
          <div className="light-container">
            <BsLightningChargeFill size={30} color="white" />
          </div>
          <h3>Platinum</h3>
        </div>
        <div className="item">
          <h3>Connection</h3>
          <div>
            <img src={"/images/NetworkTeam/fidium.png"} alt="Sales Rep" />
          </div>
        </div>
        <div className="item">
          <h3>Fiber Plan</h3>
          <div className="blueText">
            {/* <p>2G</p> */}
            <p>{customer?.fiberPlanString}</p>
          </div>
        </div>
        <div className="item">
          <div>
            <img src={freeMonth} alt="Sales Rep" />
          </div>
        </div>
        <div className="item">
          <h3>Reviews</h3>
          <div className="blueText">
            <p>0</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="infoContainer">
      <Box
        sx={{
          display: "flex",
          // width: "100vw",
          // height: "100vh",
          position: "relative",
          top: "50%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: "var(--color-icon)" }} size={100} />
      </Box>
    </div>
  );

  const forms = (
    <div className="formsContainer">
      <div className="freeInstallMonth">
        {/* Free Month */}
        <div onClick={() => setIndex(12)}>
          <div>
            <img src={freeMonth} alt="Sales Rep" />
          </div>
          <h3>FREEE MONTH</h3>
        </div>
        {/* Free Install */}
        <div
          onClick={() => {
            if (!user?.fidiumCustomer) setModalOpen(true);
            else
              Swal.fire({
                title: "Error",
                text: "Customer Already Exists",
                icon: "error",
                showConfirmButton: true,
                timer: 1500,
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
              });
          }}
        >
          <div>
            <img src={freeMonth} alt="Sales Rep" />
          </div>
          <h3>FREE INSTALL</h3>
        </div>
      </div>
      {/* Hot Lead */}
      <div onClick={() => setIndex(11)} className="hotLead">
        <div>
          <BsFire size={80} color="white" />
        </div>
        <h3>HOT LEAD</h3>
      </div>
    </div>
  );

  const actvity = (
    <div
      style={{
        height: window.innerHeight - 350 + "px",
      }}
      ref={refPassthrough}
      {...handlers}
      className="overflow_container"
    >
      <div className="NewActivityHistoryBody">
        {!loading ? (
          activityHistory.length > 0 ? (
            <>
              {activityHistory.map((activity) => {
                if (activity.activityType !== "Lead Edit")
                  return <Card acHistory={activity} />;
              })}
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                NO ACTIVITY HISTORY
              </h1>
            </div>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              // width: "100vw",
              // height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--color-icon)" }} size={40} />
          </Box>
        )}
      </div>
    </div>
  );

  const getTab = () => {
    switch (currentTab) {
      case 0:
        return info;
      case 1:
        return forms;
      case 2:
        return actvity;
      default:
        return actvity;
    }
  };

  return (
    <div>
      {/* <div
        className="go_back_button"
        style={{ display: window.innerWidth > 1000 ? "block" : "none" }}
      >
        <IoMdArrowRoundBack
          size={30}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIndex(10);
          }}
        />
      </div> */}
      <div className="NewActivityHistory">
        {/* <div className="TitleDiv">
                <h2 onClick={toggleDropdown}>
                    {isDropdownOpen ? '▼' : '►'} Activity History
                </h2>
            </div> */}
        <div
          className="NewActivityHistoryHeader"
          style={{
            gridTemplateColumns: user?.fidiumCustomer
              ? "1fr 1fr 1fr"
              : "1fr 1fr",
            placeItems: "center",
          }}
        >
          {user?.fidiumCustomer && (
            <div
              onClick={() => setCurrentTab(0)}
              className={`menu ${currentTab === 0 && "active"}`}
            >
              <FaCircleInfo size={30} color="#2761D0" />
              <p>INFO</p>
            </div>
          )}
          {!user?.fidiumCustomer && (
            <div className={`menu ${currentTab === 1 && "active"}`}>
              <p style={{ color: "#ccc" }}>FORMS</p>
            </div>
          )}
          <div
            onClick={() => setCurrentTab(2)}
            className={`menu ${currentTab === 2 && "active"}`}
          >
            <p>ACTIVITY</p>
          </div>
          {/* <div className='delete'>
                    <MdDeleteOutline size={30} color='#FC1C0F' />
                </div> */}
        </div>
        {getTab()}
        <Modal
          className="flex items-center justify-center newCustomerFormModal"
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <CreateCustomer
            fiberHouse={fiberHouse}
            lead={lead}
            setOpen={setModalOpen}
            setIndex={setIndex}
            searchFiberHouses={searchFiberHouses}
            user={user}
            HotLeadForm={false}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ActivityHistoryNew;

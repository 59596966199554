import { useEffect, useState } from "react";
import TagRow from "./TagRow";
import TagCreateModal from "../Modals/TagCreateModal";
import { deleteTag, getAllTagsWithAssoication } from "../api/tagsAPI";
import Swal from "sweetalert2";

export default function TagsTable() {
  const [expandedTagId, setExpandedTagId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await getAllTagsWithAssoication();
        console.log("Fetched All Tags successfully", data);
        setTags(data.data.items);
      } catch (error) {
        console.error("Failed to fetch tags", error.message);
      }
    };
    fetchTags();
  }, []);

  useEffect(() => {
    if (tags.length > 0) {
      console.log("Current Tags from State:", tags);
    }
  }, [tags]);

  const toggleExpand = (tagId) => {
    setExpandedTagId(expandedTagId === tagId ? null : tagId);
  };

  const handleCreateNewClick = () => {
    setShowModal(true);
  };
  // handleDeleteTag
  const handleDeleteTag = async (tagId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteTag(tagId);
        setTags(tags.filter((tag) => tag.id !== tagId));

        Swal.fire({
          title: "Deleted!",
          text: "The tag has been deleted.",
          icon: "success",
        });
      }
    });
  };
  // handleEditTag
  const handleEditTag = async (tag) => {
    setTags(oldTags => oldTags.map(t => t.id === tag.id ? tag : t))
    // const confirmed = window.confirm(
    //   "Are you sure you want to delete this tag?"
    // );
    // if (confirmed) {
    //   await deleteTag(tagId);
    //   setTags(tags.filter((tag) => tag.id !== tagId));
    // }
  };
  return (
    <div className="mx-auto p-4">
      <button
        onClick={handleCreateNewClick}
        className="px-3 py-2 mb-3 bg-black rounded-md text-white"
      >
        Create New
      </button>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 text-left"></th>
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Description</th>
            <th className="p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag) => (
            <TagRow
              key={tag.id}
              tag={tag}
              expandedTagId={expandedTagId}
              toggleExpand={toggleExpand}
              onDelete={handleDeleteTag}
              onEdit={handleEditTag}
            />
          ))}
        </tbody>
      </table>
      <TagCreateModal
        showModal={showModal}
        setShowModal={setShowModal}
        onCreateTag={(newTag) => setTags([...tags, newTag])}
      />
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import { UserContext } from "../../../../../App";
import EditLeadReq from "../../../../../Requests/FiberHouse/EditLeadReq";
import MapIconDisplay from "./../MapIconDisplay";
import "./MainLeadCard.scss";
import LeadEdit from "./LeadEdit";
import LeadData from "./LeadData";
import LightningMessage from "./Images/LightningMessage.png";
import Call from "./Images/Call.png";
import Email from "./Images/Email.png";
import Message from "./Images/Message.png";
import wa from "./Images/whatsapp.svg";
import Directions from "./Images/Directions.png";
// import { MdEmail, MdLocationOn } from 'react-icons/md'
// import CustomerApi from '../../../../../API/CustomerApi'
import StringIntReq from "../../../../../Requests/StringIntReq";
import ContactButtons from "../../../../../Services/ContactButtons";
import { useNavigate } from "react-router-dom";

import { ImSpinner8 } from "react-icons/im";
// import SearchLeadsForContactCenterReq from '../../../../../Requests/Customer/SearchLeadsForContactCenterReq'
// import MapIconApi from '../../../../../API/MapIconApi'
import { MapContext } from "../..";
import { CircularProgress } from "@mui/material";
import { FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";
import { IoIosAddCircle, IoIosRemoveCircle, IoIosSearch } from "react-icons/io";
import CustomerApi from "../../../../../API/CustomerApi";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { Icon } from "ol/style";
import { useSwipeable } from "react-swipeable";
import ChangeAccountStatusModal from "./ChangeAccountStatusModal";
import handleLastLead from "../handelLastLead";
import {
  User,
  Phone,
  Mail,
  Star,
  Clock,
  CheckCircle,
  Plus,
  Send,
  ChevronDown,
} from "lucide-react";
import { none } from "ol/centerconstraint";
import { Tooltip } from "@mui/material";
import {
  FaCarCrash,
  FaUser,
  FaUserTie,
  FaQuestionCircle,
  FaShieldAlt,
  FaDollarSign,
  FaDog,
} from "react-icons/fa";
import {
  MdOutlineSecurity,
  MdOutlineFamilyRestroom,
  MdPets,
} from "react-icons/md";
import {
  IoSearch,
  IoCalendarOutline,
  IoChatbubblesOutline,
  IoTrendingDownSharp,
  IoPersonOutline,
} from "react-icons/io5";
import { AiOutlineWarning, AiOutlineClockCircle } from "react-icons/ai";
import { GiFamilyHouse, GiPoliceOfficerHead } from "react-icons/gi";
import { FaTv, FaCar, FaRegHeart, FaBaby, FaChild } from "react-icons/fa";
import { IoReceiptOutline } from "react-icons/io5";
import CarCrashOutlinedIcon from "@mui/icons-material/CarCrashOutlined";
import SelectionMenu from "../../../../SuperAdmin/Tags/components/SelectionMenu";
import TagsState from "../../../../SuperAdmin/Tags/components/TagsState";
import {
  assoicateTag,
  disAssoicateTag,
} from "../../../../SuperAdmin/Tags/api/tagsAPI";
import CustomNote from "./CustomNote";

// import { TbFilePhone } from 'react-icons/tb'
// import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'

const iconDisplayMapping = {
  3: [],
  16: [
    { component: CarCrashOutlinedIcon, label: "No Car" },
    { component: FaCar, label: "1 Car" },
    { component: FaCar, label: "2 Cars" },
    { component: GiFamilyHouse, label: "Definitely Home" },
  ],
  17: [
    { component: CarCrashOutlinedIcon, label: "No Car" },
    { component: FaCar, label: "1 Car" },
    { component: FaCar, label: "2 Cars" },
    { component: GiFamilyHouse, label: "Definitely Home" },
  ],
  21: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  18: [
    { component: FaChild, label: "Kid" },
    { component: FaUser, label: "Elder" },
    { component: FaBaby, label: "Babysitter" },
    { component: FaQuestionCircle, label: "Other" },
  ],
  33: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  22: [
    { component: MdOutlineSecurity, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: FaUser, label: "Fiber Competitor" },
    { component: IoTrendingDownSharp, label: "Negative Reputation" },
    { component: IoReceiptOutline, label: "Company Pays Bill " },
    { component: IoIosSearch, label: "Too Busy" },
  ],
  5: [
    { component: MdOutlineSecurity, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Negative Reputation" },
    { component: IoReceiptOutline, label: "Company Pays Bill" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  6: [
    { component: AiOutlineWarning, label: "Got Aggressive" },
    { component: AiOutlineWarning, label: "Got Violent" },
    { component: FaDog, label: "Dangerous Dogs" },
    { component: FaQuestionCircle, label: "Other" },
  ],
  24: [
    { component: IoCalendarOutline, label: "1 Month" },
    { component: IoCalendarOutline, label: "2 Months" },
    { component: IoCalendarOutline, label: "3 Months" },
    { component: IoCalendarOutline, label: "6 Months" },
    { component: IoCalendarOutline, label: "1 Year +++" },
  ],
  19: [
    { component: IoChatbubblesOutline, label: "Speak Zero Spanish" },
    { component: IoChatbubblesOutline, label: "Speak Little Spanish" },
    { component: IoChatbubblesOutline, label: "Translator Attempted" },
  ],
  23: [],
  31: [],
  39: [
    { component: GiPoliceOfficerHead, label: "Marked Vehicle" },
    { component: GiPoliceOfficerHead, label: "Unmarked Vehicle" },
  ],
  8: [
    { component: FaRegHeart, label: "Talk to Spouse" },
    { component: IoSearch, label: "Research" },
    { component: IoReceiptOutline, label: "Check Bill" },
    { component: FaTv, label: "TV Bundle" },
    { component: MdOutlineSecurity, label: "Security Bundle" },
    { component: FaDollarSign, label: "Paying Less" },
    { component: IoPersonOutline, label: "Fiber Competitor" },
    { component: AiOutlineWarning, label: "Too Busy" },
  ],
  9: [],
  40: [],
  36: [],
  28: [],
  10: [],
  11: [],
  35: [],
  12: [],
};
const MainLeadCard = ({
  searchFiberHouses,
  selectedFiberHouse,
  user,
  setIndex,
}) => {
  let User = useContext(UserContext);
  const {
    selectedLead,
    setSelectedLead,
    setSelectedFiberHouse,
    selectedFiberHouseFeature,
    mapIcons,
    mapObject,
    boltMode,
    setBoltMode,
    tags,
    setTags,
    assignedSelectedTags,
    setAssignedSelectedTags,
    searchCriteria,
    setSearchCriteria,
  } = useContext(MapContext);
  const mapContext = useContext(MapContext);
  const [loading, setLoading] = useState(true);
  const [leadNotes, setLeadNotes] = useState(selectedLead?.notes);
  const [edit, setEdit] = useState(false);
  const [fidiumCustomer, setFidiumCustomer] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [mapIcon, setMapIcon] = useState(selectedFiberHouse?.mapIconId);
  const [markedInvalid, setMarkedInvalid] = useState(
    selectedLead?.fidiumCustomer?.isInvalid
  );
  // const [associateSelectedTags]
  const [accountStatusModal, setAccountStatusModal] = useState(false);
  // const [appointmentDate, setAppointmentDate] = useState(selectedLead?.appointmentDateTime)
  const [reload, setReload] = useState(false);
  const intialRender = useRef(true);
  const shouldSaveText = useRef(false);
  const stopRef = useRef(null);
  const navigate = useNavigate();

  const [isQuickNotesOpen, setIsQuickNotesOpen] = useState(true);
  const [notes, setNotes] = useState([]);
  // const [note, setNote] = useState("");
  const [note, setNote] = useState("📝 ");
  const [isCustomNoteOpen, setIsCustomNoteOpen] = useState(false);
  const [customNotes, setCustomNotes] = useState([]);

  const [isViewNotesOpen, setIsViewNotesOpen] = useState(true);
  const [activeButton, setActiveButton] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 2; // Adjust visible notes per page
  const totalPages = Math.ceil(notes.length / itemsPerPage);
  const maxVisibleDots = 3; // Only show 3 dots at a time
  const [disabled, setDisabled] = useState(false);
  // Sorting and slicing notes based on pagination
  const sortedNotes = notes.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );
  const visibleNotes = sortedNotes.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  // Handle Swipe Gestures
  const QuickNotehandlers = useSwipeable({
    onSwipedUp: () =>
      setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1)),
    onSwipedDown: () => setCurrentPage((prev) => Math.max(prev - 1, 0)),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // Logic to show a maximum of 3 dots at a time
  let start = Math.max(0, currentPage - 1);
  let end = Math.min(totalPages, start + maxVisibleDots);
  if (end - start < maxVisibleDots) {
    start = Math.max(0, end - maxVisibleDots);
  }
  const visibleDots = Array.from({ length: end - start }, (_, i) => start + i);

  const getDisplayComponents = (iconName) => {
    if (selectedLead.fidiumCustomer) {
      if (
        selectedLead.fidiumCustomer.accountStatus === "Sold" ||
        selectedLead.fidiumCustomer.accountStatus === "Scheduled" ||
        selectedLead.fidiumCustomer.accountStatus === "Rescheduled"
      ) {
        return iconDisplayMapping[9];
      } else if (selectedLead.fidiumCustomer.isMissing === true) {
        return iconDisplayMapping[40];
      } else if (selectedLead.fidiumCustomer.isInvalid === true) {
        return iconDisplayMapping[36];
      } else if (selectedLead.fidiumCustomer.accountStatus === "Canceled") {
        return iconDisplayMapping[28];
      } else if (selectedLead.fidiumCustomer.accountStatus === "Completed") {
        return iconDisplayMapping[10];
      } else if (
        selectedLead.fidiumCustomer.accountStatus === "Completed" &&
        selectedLead.fidiumCustomer.customerFeedbacks != null
      ) {
        return iconDisplayMapping[11];
      }
    } else return iconDisplayMapping[iconName] || [];
  };

  const GetQuickNotes = async () => {
    let req = {
      LeadId: selectedLead?.id,
    };
    const res = await CustomerApi.GetQuickNotes(req);
    if (res?.status?.success) {
      setNotes(res.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };
  useEffect(() => {
    GetQuickNotes();
  }, [selectedLead]);

  const handleQuickNoteClick = async (item) => {
    console.log(item);
    let req = {
      Note: item,
      LeadId: selectedLead.id,
    };

    const res = await CustomerApi.CreateQuickNote(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Quick Note Added",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      GetQuickNotes();
      setIsViewNotesOpen(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        toast: true,
        timer: 1500,
        position: "bottom-end",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const getLead = async (id) => {
    setFidiumCustomer(null);
    const req = new StringIntReq();
    req.int = id ? id : selectedFiberHouse.id;
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1;
    const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
    if (res?.status?.success) {
      console.log("Lead", res.data);
      setSelectedLead(res.data);
      setMapIcon(res.data.fiberHouse.mapIconId);
      setLoading(false);
    }
  };

  const refPassthrough = (el) => {
    handlers.ref(el);
    stopRef.current = el;
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwiping: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedDown: (eventData) => {
      eventData.event.stopPropagation();
    },
  });

  useEffect(() => {
    if (selectedLead?.notes) {
      setLeadNotes(selectedLead?.notes);
    } else {
      setLeadNotes("");
    }
  }, [selectedLead]);

  useEffect(() => {
    if (selectedFiberHouse != null) {
      setLoading(true);
      setMapIcon(selectedFiberHouse.mapIconId);
      getLead(null);
    } else {
      setIndex(-1);
    }
  }, [selectedFiberHouse, reload]);

  useEffect(() => {
    if (mapContext.boltMode) {
      let shouldDisable = true;
      if (user.userType === "SuperAdmin") {
        shouldDisable = false;
      } else {
        shouldDisable = true;
        if (
          selectedLead?.fiberHouse?.salesRepFiberHouses.some(
            (salesRep) => salesRep.salesRepId === user.id
          )
        ) {
          shouldDisable = false;
        }
      }
      setDisabled(shouldDisable);
    } else {
      setDisabled(false);
    }
  }, [mapContext.boltMode, selectedLead]);

  let contactButtons = new ContactButtons(User);

  const handleLightningMessage = async (lead) => {
    console.log("Lead", lead);
    const res = await contactButtons.lightningMessageLead({
      ...lead,
      fidiumCustomer: fidiumCustomer,
    });
    if (res != null) {
      console.log("Conversation", res);
      navigate("/chat", {
        state: { conversation: res },
      });
    }
  };

  const editNotes = useCallback(async () => {
    let req = new EditLeadReq();
    req.id = selectedLead.id;
    req.salesOrgId = User.salesOrgId;
    req.salesRepId = User.id;
    req.notes = leadNotes;
    await FiberHouseApi.EditLead(req);
    setRequestInProgress(false);
  }, [leadNotes]);

  useEffect(() => {
    if (intialRender.current) {
      intialRender.current = false;
      return;
    } else if (shouldSaveText.current) {
      const timer = setTimeout(async () => {
        await editNotes();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [leadNotes, editNotes]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "var(--primary-color)",
          }}
        />
      </div>
    );
  }

  const copyNotes = () => {
    navigator.clipboard.writeText(leadNotes);
    Swal.fire({
      icon: "success",
      title: "Copied",
      text: "Notes Copied to Clipboard",
      showConfirmButton: false,
      toast: true,
      position: "bottom-end",
      timer: 1500,
    });
  };

  const markCustomerAsInvalid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Invalid",
      text: "Are you sure you want to mark this customer as invalid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      text: "Marking Customer As Invalid",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsInvalid(req);
    if (res?.status?.success) {
      let assignedMapIcons = mapIcons.find((x) => x.abbreviation === "IC");
      if (
        selectedFiberHouseFeature &&
        selectedFiberHouseFeature.get("id") === selectedFiberHouse.id
      ) {
        console.log("Fiber House Feature Found");
        const newStyle = new Style({
          image: new Icon({
            scale: 1.5, // Adjust this value to change the scale of the icon
            src: "data:image/png;;base64," + assignedMapIcons?.image,
          }),
        });

        const circleStyle = new Style({
          image: new CircleStyle({
            radius: 15, // This is now in pixels
            stroke: new Stroke({
              color: "black", // Border color
              width: 4, // Border width
            }),
            fill: new Fill({
              color: "rgba(0, 0, 0, 0)", // Transparent fill
            }),
          }),
        });
        selectedFiberHouseFeature.setStyle([
          newStyle, // This is the icon style
          circleStyle, // This is the circle style
        ]);
        selectedFiberHouseFeature.values_.mapIconId = assignedMapIcons.id;
        console.log(assignedMapIcons.id);
        console.log(selectedFiberHouseFeature);
        mapObject.getView().setZoom(mapObject.getView().getZoom() + 0.000001);
      }
      Swal.fire({
        icon: "success",
        title: "Customer Marked As Invalid",
        text: "The customer has been marked as invalid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });

      setMarkedInvalid(true);
      setMapIcon(assignedMapIcons.id);
      setReload(!reload);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const markCustomerAsValid = async (lead) => {
    const opt = await Swal.fire({
      icon: "warning",
      title: "Mark Customer As Valid",
      text: "Are you sure you want to mark this customer as valid?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    });

    if (!opt.isConfirmed) {
      return;
    }
    Swal.fire({
      title: "Please Wait",
      icon: "info",
      text: "Marking Customer As Valid",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const req = new StringIntReq();
    req.int = lead.fidiumCustomer.id;
    const res = await CustomerApi.MarkCustomerAsValid(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Customer Marked As Valid",
        text: "The customer has been marked as valid",
        onOpen: () => {
          Swal.hideLoading();
        },
      });
      setMarkedInvalid(false);
      setReload(!reload);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
      });
    }
  };

  const handleAssociate = async (associateSelectedTags) => {
    const data = {
      tagIds: associateSelectedTags.map((item) => item.id),
      leadIds: [selectedLead.id],
      candidateIds: null,
    };
    const res = await assoicateTag(data);

    setTags((prevTags) =>
      prevTags.map((t) => ({
        ...t,
        tagEntities: [
          ...t.tagEntities,
          ...res.data.filter((tEntity) => tEntity.leadTagId === t.id),
        ],
      }))
    );

    console.log(data);

    Swal.fire({
      title: "Success!",
      text: "Tags have been associated successfully.",
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const handleDisassociate = async (selectedTagIds) => {
    const tagEntities = tags
      .filter((t) => selectedTagIds.includes(t.id))
      .reduce(
        (prev, currT) => [
          ...prev,
          ...currT.tagEntities.filter(
            (tEntity) => selectedLead.id === tEntity.userId
          ),
        ],
        []
      );

    console.log({ selectedLead });
    console.log({ tagEntities });

    const res = await Promise.all(
      tagEntities.map(({ id }) => disAssoicateTag({ id }))
    );

    const numberOfSuccess = res.reduce(
      (prev, currRes) => (currRes.status.success ? prev + 1 : prev),
      0
    );

    console.log({ res });

    // setTags((prevTags) =>
    //   prevTags.map((t) => ({
    //     ...t,
    //     tagEntities: [
    //       ...t.tagEntities,
    //       ...res.data.filter((tEntity) => tEntity.leadTagId === t.id),
    //     ],
    //   }))
    // );

    // setAssociateSelectedTags([]);
    // console.log(data);

    const checkThisOut = tagEntities
      .filter((_, i) => res[i].status.success)
      .reduce(
        (prev, currTEntity) => ({
          ...prev,
          [currTEntity.leadTagId]: [
            ...(prev[currTEntity.leadTagId] ?? []),
            currTEntity.id,
          ],
        }),
        {}
      );

    console.log({ checkThisOut });

    setTags((prevTags) =>
      prevTags.map((t) => {
        console.log(
          t.id,
          Object.keys(checkThisOut),
          Object.keys(checkThisOut).includes(t.id.toString())
        );
        return Object.keys(checkThisOut).includes(t.id.toString())
          ? {
              ...t,
              tagEntities: t.tagEntities.filter(
                (tEntity) => !checkThisOut[t.id].includes(tEntity.id)
              ),
            }
          : t;
      })
    );

    console.log({ numberOfSuccess });

    if (numberOfSuccess === tagEntities.length) {
      Swal.fire({
        title: "Success!",
        text: `${numberOfSuccess} Tag Entit${
          numberOfSuccess > 1 ? "ies" : "y"
        } disassociated successfully.`,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Problem!",
        text: `${tagEntities.length - numberOfSuccess} Tag Entit${
          tagEntities.length - numberOfSuccess > 1 ? "ies" : "y"
        } faced problems.`,
        icon: "warning",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="MainLeadCard">
      <div ref={refPassthrough} className=" overflow-x-auto ">
        <div className="MainLeadCard_LeadData">
          <ChangeAccountStatusModal
            setReload={setReload}
            open={accountStatusModal}
            setOpen={setAccountStatusModal}
            customer={selectedLead?.fidiumCustomer}
          />

          <LeadData
            mapIcon={mapIcon}
            setMapIcon={setMapIcon}
            setReload={setReload}
            fidiumCustomer={selectedLead?.fidiumCustomer}
            lead={selectedLead}
            setEdit={setEdit}
            fiberHouse={selectedFiberHouse}
            setFiberouse={setSelectedFiberHouse}
            searchFiberHouses={getLead}
            setIndex={setIndex}
            setAccountStatusModal={setAccountStatusModal}
          />
        </div>
        {/* {console.log(selectedFiberHouse)} */}

        <divx1
          style={{
            // overflowY: "auto",
            // padding:"2rem",
            // make the height dynamic
            // height: "100%",
            // padding: "1rem",
            // boxShadow: "0px 0px 5px rgba(0,0,0,0.1) inset",

            borderRadius: "10px",
          }}
          className=" p-4"
        >
          {/* {user.userType === "SuperAdmin" &&
            selectedLead.fidiumCustomer != null && (
              <div className="flex p-2 justify-center items-center">
                <p
                  onClick={() => {
                    setAccountStatusModal(true);
                  }}
                  className="font-bold uppercase text-[#2761D0] underline cursor-pointer"
                >
                  Change Account Status
                </p>
              </div>
            )}
          {selectedLead.fidiumCustomer != null && (
            <div className="isCustomerMarked">
              {selectedLead?.fidiumCustomer?.orderNumber != null ? (
                <span
                  style={{
                    color: selectedLead?.fidiumCustomer?.isInvalid
                      ? "black" // Invalid Customer
                      : "green", // Verified Customer
                  }}
                >
                  <span className="check-icon">✔</span>
                  {selectedLead?.fidiumCustomer?.isInvalid
                    ? " Invalid Customer"
                    : " Verified Customer"}
                </span>
              ) : (
                <span style={{ color: "gray" }}>
                  <span className="check-icon">✔</span>
                  Customer
                </span>
              )}
              {selectedLead?.fidiumCustomer?.isInvalid === true ||
                markedInvalid ? (
                <IoIosAddCircle
                  onClick={() => {
                    markCustomerAsValid(selectedLead);
                  }}
                  color="#81848A"
                  size={30}
                />
              ) : (
                <IoIosRemoveCircle
                  onClick={() => {
                    markCustomerAsInvalid(selectedLead);
                  }}
                  color="#81848A"
                  size={30}
                />
              )}
            </div>
          )} */}

          {
            // selectedLead.isProspect === true &&
            // <div className="isCustomerMarked">
            //     <span>
            //         <span className="check-icon">✔</span>
            //         Offer Sent
            //         {/* put a check icon */}
            //     </span>
            // </div>
          }

          {/* Lead Status */}

          {selectedLead.fidiumCustomer == null && (
            <div
              className="sm:block hidden MainLeadCard_MapIcons"
              style={{
                marginTop: "0",
                boxShadow: "none",
              }}
            >
              <h1 className="text-lg font-bold">Lead Status</h1>
              <MapIconDisplay
                isCustomer={selectedLead.fidiumCustomer != null}
                removeLightningBolts={true}
                setMapIcon={setMapIcon}
                selectedFiberHouse={selectedFiberHouse}
              />
            </div>
          )}

          <TagsState
            label="Leads"
            leadOrCandidateIds={[selectedLead.id]}
            onDisassociate={handleDisassociate}
            tags={tags}
          />
          <SelectionMenu
            tags={tags}
            onButtonClick={handleAssociate}
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
            selectedItems={assignedSelectedTags}
            setSelectedItems={setAssignedSelectedTags}
          />
          <CustomNote
            open={isCustomNoteOpen}
            setOpen={setIsCustomNoteOpen}
            user={user}
            setCustomNotes={setCustomNotes}
            setReload={setReload}
          />

          {/* Quick Notes */}

          <div className=" sm:flex hidden md:flex-row flex-col items-center md:justify-between md:space-x-1 w-full">
            {mapIcons?.map((icon, index) =>
              icon.id === mapIcon ? (
                getDisplayComponents(icon.id)?.length > 0 ? (
                  <div className="mt-4  border-2 border-gray-200 rounded-lg p-2 md:w-1/2 w-full">
                    <div className="flex justify-between items-center ">
                      <h3 className="font-bold text-gary-700">Quick Notes</h3>
                      <div className="flex justify-between gap-1">
                        <button
                          onClick={() => {
                            setIsCustomNoteOpen(true);
                          }}
                          className="border  border-[var(--primary-color)] rounded-lg text-[var(--primary-color)] text-sm p-1"
                        >
                          + Custom Note
                        </button>
                      </div>
                      {/* <div className="flex justify-between gap-1">
                        <button
                          onClick={() => {
                            setIsQuickNotesOpen(false);
                            setIsViewNotesOpen(false);
                          }}
                          className="border rounded-lg text-gray-600 text-sm p-1"
                        >
                          + Create Notes
                        </button>
                      </div> */}
                    </div>

                    <div
                      className="flex  gap-3 mt-3 overflow-x-auto  h-24 "
                      style={{
                        scrollbarWidth: "none",
                      }}
                    >
                      {mapIcons?.map((icon, index) =>
                        icon.id === mapIcon ? (
                          icon.name === "Cancelled" ||
                          icon.name === "Pending Cancel" ? (
                            <p className="p-3 flex flex-col items-center w-full text-gray-700 border border-gray-200 rounded-lg">
                              {selectedLead?.fidiumCustomer?.issueDescription}
                            </p>
                          ) : (
                            customNotes?.map((note, index) => (
                              icon.id == note.mapIconId && (
                              <button
                                onClick={() => {
                                  handleQuickNoteClick(note.note);
                                }}
                                key={index}
                                className="p-3 flex flex-col items-center w-20 text-gray-700"
                              >
                                <img className="w-5 h-5" src={note?.icon?.url} />
                                <span className="text-sm mt-1">
                                  {note.note}
                                </span>
                              </button>
                              )
                            ))
                          )
                        ) : null
                      )}

                      {mapIcons?.map((icon, index) =>
                        icon.id === mapIcon ? (
                          icon.name === "Cancelled" ||
                          icon.name === "Pending Cancel" ? (
                            <p className="p-3 flex flex-col items-center w-full text-gray-700 border border-gray-200 rounded-lg">
                              {selectedLead?.fidiumCustomer?.issueDescription}
                            </p>
                          ) : (
                            getDisplayComponents(icon.id)?.map(
                              
                              (item, idx) => (
                                <button
                                  onClick={() => {
                                    handleQuickNoteClick(item.label);
                                  }}
                                  key={`${index}-${idx}`}
                                  className="p-3 flex flex-col items-center w-20 text-gray-700"
                                >
                                  <item.component className="w-5 h-5" />
                                  <span className="text-sm mt-1">
                                    {item.label}
                                  </span>
                                </button>
                              )
                            )
                          )
                        ) : null
                      )}
                    </div>
                  </div>
                ) : null
              ) : null
            )}

            <div className="mt-4  border-2 p-2 border-gray-200 rounded-lg md:w-1/2 w-full">
              <div className="flex justify-between items-center ">
                <h3 className="font-bold text-gray-700">Notes</h3>
                <div className="flex justify-between gap-2">
                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      setIsViewNotesOpen(false);
                    }}
                    className={`border rounded-lg text-sm p-1 ${
                      activeButton === "create"
                        ? "bg-[var(--primary-color)] text-white"
                        : "border-[var(--primary-color)] text-[var(--primary-color)]"
                    }`}
                  >
                    + Create Notes
                  </button>

                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      setIsViewNotesOpen(true);
                      GetQuickNotes();
                    }}
                    className={`border rounded-lg text-sm p-1 ${
                      activeButton === "view"
                        ? "bg-[var(--primary-color)] text-white"
                        : "border-[var(--primary-color)] text-[var(--primary-color)]"
                    }`}
                  >
                    View All Notes
                  </button>
                </div>
              </div>

              {isViewNotesOpen && (
                <div
                  //  {...QuickNotehandlers}
                  className="relative mt-2 w-full rounded-lg  text-gray-600  h-24 overflow-y-auto space-y-2"
                  // style={{ scrollbarWidth: "none" }}
                >
                  {notes.map((note, index) => (
                    <Tooltip
                      key={index}
                      title={`${new Date(
                        note.timestamp
                      ).toLocaleDateString()} @ ${new Date(
                        note.timestamp
                      ).toLocaleTimeString()} | ${note.note}`}
                    >
                      <div className="border border-gray-200 p-3  h-8 overflow-hidden whitespace-nowrap rounded-lg flex items-center">
                        {new Date(note.timestamp).toLocaleDateString()} @{" "}
                        {new Date(note.timestamp).toLocaleTimeString()} |{" "}
                        {note.note}
                      </div>
                    </Tooltip>
                  ))}

                  {/* {totalPages > 1 && (
                    <div className="absolute top-1/2 right-2 transform -translate-y-1/2 flex flex-col gap-1">
                      {visibleDots.map((index) => (
                        <span
                          key={index}
                          className={`h-2 w-2 rounded-full ${index === currentPage ? "bg-blue-600" : "bg-blue-300"}`}
                          onClick={() => setCurrentPage(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </div>
                  )} */}
                </div>
              )}

              {!isViewNotesOpen && (
                <div className="mt-2 w-full relative rounded-lg p-2 border-gray-200 h-24 ">
                  <textarea
                    value={note}
                    onChange={(e) => {
                      // Ensure the icon stays at the start
                      if (!e.target.value.startsWith("📝 ")) {
                        setNote("📝 " + e.target.value.replace(/^📝\s*/, ""));
                      } else {
                        setNote(e.target.value);
                      }
                    }}
                    className="w-full h-full border-none outline-none"
                    placeholder="Type your notes here..."
                  ></textarea>

                  <button
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      handleQuickNoteClick(note);
                      setNote("");
                      // setIsQuickNotesOpen(true)
                      setIsViewNotesOpen(true);
                    }}
                    className="bg-blue-600 text-white rounded-full text-lg  
                          absolute bottom-2 right-2 px-2 "
                  >
                    +
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            className="flex mt-4 pb-4 space-x-2 items-center w-full justify-between"
            style={{ color: "black !important" }}
          >
            <div
              onClick={() => {
                if (disabled) {
                  return;
                }
                contactButtons.emailLead(selectedLead);
              }}
              className="flex gap-2 items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] bg-[var(--action-bg)] rounded-full p-4 shadow-lg shadow-gray-400 cursor-pointer"
            >
              <img
                style={{ color: "black !important" }}
                src={Email}
                alt="Email"
              />
            </div>
            <div
              onClick={() => {
                if (disabled) {
                  return;
                }
                contactButtons.callLead(selectedLead);
              }}
              style={{ color: "black !important" }}
              className="flex gap-2 items-center  sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] bg-[var(--action-bg)] rounded-full p-4 shadow-lg shadow-gray-400 cursor-pointer"
            >
              <img src={Call} alt="Call" />
            </div>

            <div
              onClick={() => {
                if (disabled) {
                  return;
                }
                contactButtons.whatsappLead(selectedLead);
              }}
              className="flex gap-2 items-center  sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] bg-[var(--action-bg)] rounded-full p-4 shadow-lg shadow-gray-400 cursor-pointer"
            >
              <img src={wa} alt="wa" />
            </div>

            <div
              onClick={() => {
                if (disabled) {
                  return;
                }
                contactButtons.directions(selectedLead.fiberHouse.coordinates);
              }}
              className="flex gap-2 items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] bg-[var(--action-bg)] rounded-full p-4 shadow-lg shadow-gray-400 cursor-pointer"
            >
              <img src={Directions} alt="Message" />
            </div>
          </div>
        </divx1>
      </div>
    </div>
  );
};

export default MainLeadCard;

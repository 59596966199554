function ChecklistBlock({ data }) {
    const { items } = JSON.parse(data);
  
    return (
      <div className="my-4">
        {items.map((item, index) => (
          <label key={index} className="block">
            <input
              type="checkbox"
              className="mr-2"
              defaultChecked={item.meta.checked}
              value={item.content}
            />
            {item.content}
          </label>
        ))}
      </div>
    );
  }
  
  export default ChecklistBlock;
  
import React, { useContext, useEffect, useState } from "react";
import { Modal, useTheme, useMediaQuery } from "@mui/material";
import Select from "react-select";
import "./InvitationModal.scss";

import Swal from "sweetalert2";
import { UserContext } from "../../../../../App";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import FiberCompanyApi from "../../../../../API/FiberCompanyApi";
import SendCandidateInvitationReq from "../../../../../Requests/FiberHouse/SendCandidateInvitationReq";
import StringIntReq from "../../../../../Requests/StringIntReq";
import CandidateJobApplicationReq from "../../../../../Requests/Users/CandidateJobApplicationReq";
import UsersApi from "../../../../../API/UsersApi";
import ContactButtons from "../../../../../Services/ContactButtons";
import { v4 as uuidv4 } from "uuid";
import { uploadFileToFirebase } from "../../../../../Services/DisplayFile";
import CommandTextArea from "../../../../../components/CommandTextArea";

const modeOptions = ["email", "sms", "both"];

const InvitationModal = ({
  candidate,
  custom,
  open = false,
  isModeSettable = false,
  bulk = false,
  onClose = () => {},
  isStatEmail,
  mode: propMode,
  isStatSMS,
  candidateId,
  filteredCandidateIds,
  candidateType,
  action,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [subject, setSubject] = useState("");
  const [messageText, setMessageText] = useState("");
  const [smsText, setSmsText] = useState("");
  const [attachmentBase64, setAttachmentBase64] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [communicationTemplates, setCommunicationTemplates] = useState([]);
  const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] =
    useState(null);
  const [mode, setMode] = useState(propMode);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [category, setCategory] = useState(
    candidateType === "Recruit" ? "Job AD" : "Consultation"
  );
  const [selectedInterviewer, setSelectedInterviewer] = useState(null);
  const [generatedLink, setGeneratedLink] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const user = useContext(UserContext);

  useEffect(() => {
    console.log(propMode);
    setMode(propMode);
  }, [propMode]);

  const fetchRecruitTemplates = async () => {
    try {
      let response = await FiberCompanyApi.GetRecruitTemplates();
      if (response.status.success) {
        const filteredTemplates = response.data.filter(
          (template) =>
            template.isVisible === true &&
            template.candidateType === candidateType
        );

        const formattedTemplates = filteredTemplates.map((template) => ({
          label: template.templateName,
          value: template.id,
          text: template.templateText,
          imageUrl: template.templateImage?.url,
          positionTitle: template.positionTitle,
          candidateType: template.candidateType,
          jobDescription: template.jobDescription,
          jobRequirements: template.jobRequirements
            .split("\n")
            .map((req) => req),
          fullData: template,
          subject: template.subject,
          content: template.content,
        }));
        setTemplates(formattedTemplates);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCommunicationTemplates = async () => {
    try {
      let req = new StringIntReq();
      req.string = candidateType;
      req.category = getCategory() || getCategoryBasedOnStatus();
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 1000;
      let response = await FiberCompanyApi.GetCommunicationTemplates(req);
      if (response.status.success) {
        const filteredTemplates = response.data.list.filter((t) => t.isVisible)
        .filter((t)=>{
            switch(mode){
               case "email":
                 return t.body && t.subject
                case "sms":
                    return t.textBody
                case "both":
                    return t.body && t.subject && t.textBody
                default:
                    return t.body && t.subject
            }
        })
        const formattedTemplates = filteredTemplates.map((template) => ({
          label: template.name + " | " + template.category,
          value: template,
        }));
        setCommunicationTemplates(formattedTemplates);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedCommunicationTemplate) {
      if (selectedCommunicationTemplate.attachments.length > 0) {
        const response = fetch(
          selectedCommunicationTemplate.attachments[0].url
        );
        response.then((res) => {
          res.blob().then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              setAttachmentBase64(reader.result.split(",")[1]);
              setFileName(selectedCommunicationTemplate.attachments[0].name);
            };
          });
        });
      } else {
        setAttachmentBase64(null);
        setFileName(null);
      }
    }
  }, [selectedCommunicationTemplate]);

  useEffect(() => {
    fetchRecruitTemplates();
  }, [candidateType]);

  useEffect(() => {
    setSubject("");
    setMessageText("");
    setSmsText("");
    fetchCommunicationTemplates();
  }, [action, candidateType, category,mode,open]);

  const handleTemplateChange = (selected) => {
    setSelectedTemplate(selected);
  };

  const sendBoth = async () => {
    const option = await Swal.fire({
      title: "Send Invitation",
      text: "Are you sure you want to send this invitation?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#4caf50",
      cancelButtonColor: "#f44336",
    });

    if (!option.isConfirmed) return;

    await sendSingleEmail(false);
    await sendSingleSms(false);
    // Swal.fire({
    //   title: 'Sending Invitation',
    //   text: 'Please wait...',
    //   icon: 'info',
    //   didOpen: () => {
    //     Swal.showLoading();
    //   }
    // })

    // let req = new SendCandidateInvitationReq()
    // req.candidateId = candidateId
    // req.templateId = selectedTemplate?.value
    // req.userId = user.id
    // req.content = messageText
    // req.subject = subject
    // req.templateType = selectedCommunicationTemplate?.category || category

    // let res = await FiberHouseApi.SendCandidateInvitationWithSMS(req)
    // let base64 = null;
    // let fileName = null;

    // const response = await fetch(selectedCommunicationTemplate?.attachments[0]?.url);

    // if (attachmentBase64) {
    //   req.attachment = {
    //     fileName: fileName,
    //     base64string: attachmentBase64
    //   }
    // }

    // res = await FiberHouseApi.SendCandidateInvitation(req)
    // if (res.status.success) {
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Success',
    //     text: 'Invitation sent successfully',
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   })
    // } else {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: res.status.message,
    //     didOpen: () => {
    //       Swal.hideLoading()
    //     }
    //   })
    // }
  };

  const sendBulkBoth = async () => {
    const option = await Swal.fire({
      title: "Send Invitation",
      text: "Are you sure you want to send these invitations?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#4caf50",
      cancelButtonColor: "#f44336",
    });

    if (!option.isConfirmed) return;

    await sendBulkInvitationEmails(false);
    await sendBulkInvitationSMS(false);
  };

  const sendInvitationEmail = async (showModal = true) => {
    //show swal confirm message
    const result = showModal
      ? await Swal.fire({
          title: "Send Invitation",
          text: "Are you sure you want to send this invitation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#f44336",
        })
      : { isConfirmed: true };

    if (result.isConfirmed) {
      //swal loader
      Swal.fire({
        title: "Sending Invitation",
        text: "Please wait...",
        icon: "info",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let req = new SendCandidateInvitationReq();
      req.candidateId = candidateId;
      req.templateId = selectedTemplate?.value;
      req.userId = user.id;
      req.content = messageText;
      req.subject = subject;
      req.templateType = selectedCommunicationTemplate?.category || category;
      let res = null;

      if (attachmentBase64) {
        req.attachment = {
          fileName: fileName,
          base64string: attachmentBase64,
        };
      }

      res = await FiberHouseApi.SendCandidateInvitation(req);
      if (res.status.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Invitation sent successfully",
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.status.message,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }

    } else {
      Swal.fire({
        icon: "info",
        title: "Cancelled",
        text: "Invitation not sent",
      });
    }
  };

  const sendBulkInvitationEmails = async (showModal = true) => {
    if (!selectedCommunicationTemplate) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a template",
      });
      return;
    }

    const result = showModal
      ? await Swal.fire({
          title: "Send Invitation",
          text: "Are you sure you want to send these invitations?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#f44336",
        })
      : { isConfirmed: true };
    if (result.isConfirmed) {
      //swal loader
      Swal.fire({
        title: "Sending Invitations",
        text: "Please wait...",
        icon: "info",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let req = new SendCandidateInvitationReq();
      req.candidateIds = filteredCandidateIds;
      req.templateId = selectedTemplate?.value;
      req.userId = user.id;
      req.content = messageText;
      req.subject = subject;
      req.templateType = selectedCommunicationTemplate?.category || category;
      if (attachmentBase64) {
        req.attachment = {
          fileName: fileName,
          base64string: attachmentBase64,
        };
      }

      let res = await FiberHouseApi.BulkSendCandidateInvitations(req);
      if (res.status.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.status.message,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.status.message,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Cancelled",
        text: "Invitation not sent",
      });
    }
  };

  const sendInvitationSMS = async (showModal = true) => {
    // Implement SMS functionality here
    const option = showModal
      ? await Swal.fire({
          title: "Send SMS",
          text: "Are you sure you want to send this invitation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#f44336",
        })
      : { isConfirmed: true };

    if (!option.isConfirmed) return;

    let req = new SendCandidateInvitationReq();
    req.candidateId = candidateId;
    req.templateId = selectedTemplate?.value;
    req.userId = user.id;
    req.content = smsText;
    req.subject = "";
    req.templateType = selectedCommunicationTemplate?.category || category;

    let res = await FiberHouseApi.SendCandidateInvitationWithSMS(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const sendBulkInvitationSMS = async (showModal = true) => {
    if (!selectedCommunicationTemplate) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a template",
      });
      return;
    }

    const option = showModal
      ? await Swal.fire({
          title: "Send SMS",
          text: "Are you sure you want to send these invitations?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#f44336",
        })
      : { isConfirmed: true };

    if (!option.isConfirmed) return;

    Swal.fire({
      title: "Sending SMS",
      text: "Please wait...",
      icon: "info",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let req = new SendCandidateInvitationReq();
    req.candidateIds = filteredCandidateIds;
    req.templateId = selectedTemplate?.value;
    req.userId = user.id;
    req.content = smsText;
    req.subject = "";

    req.templateType = selectedCommunicationTemplate?.category || category;

    let res = await FiberHouseApi.BulkSendInvitationWithSMS(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const getCategory = () => {
    switch (action) {
      case "Send Ad":
        return "Job AD";
      case "Schedule Interview":
        return "Schedule Interview";
      case "Send Job Offer":
        return "Job Offer";
      case "Send Job Offer Reminder":
        return "Job Offer Reminder";
      case "Onboarding Reminder":
        return "Onboarding Reminder"
      case "Send Consultation":
        return "Consultation"
      case "Send Proposal":
        return "Proposal"
      case "Send Pitch Deck":
        return "Pitch Deck"
      case "Send Consultation Reminder":
        return "Consultation Reminder"
      case "Send NDA":
        return "NDA"
      case "Send Contract":
        return "Contract"
      default:
        return "";
    }
  };


  const getCategoryBasedOnStatus = () => {
    switch (candidate?.status) {
      case "New Candidate":
        return "Job AD";
      case "Contacted":
        return "Job AD";
      case "Application Received":
        return "Schedule Interview";
      case "Interview Scheduled":
        return "Job Offer";
      case "Offer Extended":
        return "Onboarding Reminder";
      case "Future Consideration":
        return "Job AD";
      default:
        return "";
    }
  };

  const users = [
    {
      value: "https://calendly.com/jessica-ylwk/interview",
      label: "Jessica Man (jessica@lightningos.world)",
      type: "Recruit",
    },
    {
      value: "https://calendly.com/shasta-lightningos/interview",
      label: "Shasta Weishampel (shasta@lightningos.world)",
      type: "Company",
    },
  ];

  const determineInterviewRound = () => {
    if (!candidate.jobApplicant?.firstInterviewCompleted) {
      return "1";
    } else if (!candidate.jobApplicant?.secondInterviewCompleted) {
      return "2";
    }
  };

  const handleScheduleInterview = async () => {
    //swal loader
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Sending Schedule",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let req = new CandidateJobApplicationReq();
      req.CandidateId = candidateId;
      req.ScheduleLink = generatedLink;
      req.UserId = user.id;
      req.subject = custom ? subject : selectedCommunicationTemplate.subject;
      req.body = custom ? messageText : selectedCommunicationTemplate.body;
      const res = await UsersApi.scheduleRecruitInterview(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Interview scheduled successfully",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            Swal.hideLoading();
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message || "Failed to schedule interview",
          showConfirmButton: true,
          willClose: () => {
            Swal.hideLoading();
          },
        });
      }
    } catch (error) {
      console.error("Error scheduling interview:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred",
        showConfirmButton: true,
        willClose: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  async function sendSingleEmail(showModal = true) {
    const category = getCategory() || getCategoryBasedOnStatus();

    if (subject === "" || messageText === "") {
      Swal.fire({
        title: "Error Sending Email",
        text: "Subject and Message are required",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }

    // if (custom && user.userType === "SalesOrgAdmin") {
    //   const subject = custom ? subject : selectedCommunicationTemplate.subject;
    //   const body = custom ? messageText : selectedCommunicationTemplate.body;
    //   const emailLink = `mailto:${candidate.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    //   window.location.href = emailLink;
    //   return
    // }

    if (category === "Schedule Interview") {
      await handleScheduleInterview();
    } else if (
      category === "Job AD" ||
      category === "Consultation" ||
      category === "Job Offer" || category === "Proposal" || category === "Pitch Deck" || category === "NDA" || category === "Contract"
    ) {
      await sendInvitationEmail(showModal);
    }
  }

  const sendSingleSms = async (showModal = true) => {
    const category = getCategory() || getCategoryBasedOnStatus();

    // if (custom && user.userType === "SalesOrgAdmin") {
    //   const body = custom ? messageText : selectedCommunicationTemplate.body;
    //   const smsLink = `sms:${candidate.phone}?body=${encodeURIComponent(body)}`;
    //   window.location.href = smsLink;
    //   return;
    // }

    if (category === "Schedule Interview") {
      await handleScheduleInterview();
    } else if (
      category === "Job AD" ||
      category === "Consultation" ||
      category === "Job Offer" || category === "Proposal" || category === "Pitch Deck" || category === "NDA" || category === "Contract"
    ) {
      await sendInvitationSMS(showModal);
    }
  };

  const handleSaveNewTemplate = async () => {
    try {
      Swal.fire({
        title: "Adding New Template",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let cloudFile = null;

      if (uploadedFile) {
        const uniqueId = uuidv4();
        cloudFile = await uploadFileToFirebase(
          uploadedFile,
          `communication-templates/${uniqueId}`
        );
      }

      if (candidateType === "Recruit" && (!subject || !candidateType)) {
        Swal.fire({
          title: "Error Saving Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      if (candidateType === "Company" && !subject) {
        Swal.fire({
          title: "Error Saving Template",
          text: "All fields are required",
          icon: "error",
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }

      const newTemplateReq = {
        name: subject.slice(0, 10),
        type: candidateType,
        attachments: [],
        body: messageText,
        subject: subject,
        category: getCategory() || getCategoryBasedOnStatus(),
        adTemplateId: selectedTemplate?.value || null,
      };

      if (cloudFile) {
        newTemplateReq.attachments.push({
          Name: cloudFile.name,
          Url: cloudFile.url,
          Path: cloudFile.path,
          Extension: cloudFile.extension,
          FileSize: cloudFile.fileSize,
          FileType: cloudFile.fileType,
          Caption: "Template Image",
        });
      }

      const response = await FiberCompanyApi.CreateCommunicationTemplate(
        newTemplateReq
      );

      if (response.status.success) {
        Swal.fire({
          title: "Template Added Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Error Saving Templatee",
          text: response.status.message,
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error Saving Template",
        text: error.message,
        icon: "error",
        showConfirmButton: true,
      });
    }
  };
  const options = [
    {
      label: "Candidate Name",
      value: "{{candidate}}",
    },
    {
      label: "Link",
      value: "{{link}}",
    },
  ];

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="invitation-modal">
      <div className={`invitation-modal ${isMobile ? "mobile" : ""}`}>
        <h1 className="text-2xl py-4 font-medium text-center">{action}</h1>
        <div className="scrollable-content">
          <div className="content">
            {isModeSettable && (
              <div className="flex flex-col gap-2">
                <label>Status</label>
                <Select
                  value={{ label: mode, value: mode }}
                  onChange={(option) => setMode(option.value)}
                  options={modeOptions.map((m) => ({
                    label: m,
                    value: m,
                  }))}
                  placeholder="Select Mode"
                />
              </div>
            )}
            {!custom && (
              <div className="flex flex-col gap-2">
                <label>Select Communication Template</label>
                <Select
                  options={communicationTemplates}
                  onChange={(selected) => {
                    setSelectedCommunicationTemplate(selected.value);

                    setMessageText(selected.value.body || "");
                    setSubject(selected.value.subject || "");
                    setSmsText(selected.value.textBody || "");
                  }}
                  // value={selectedCommunicationTemplate}
                  placeholder="Choose a template"
                  className="template-select"
                  menuPortalTarget={document.body} // renders the dropdown in the body element
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure a high z-index for the portal
                  }}
                />
              </div>
            )}

            {(getCategory() === "Schedule Interview" ||
              getCategoryBasedOnStatus() === "Schedule Interview") && (
              <>
                <label>Select a Calendly User</label>
                <Select
                  options={users.filter((user) => user.type === candidateType)}
                  value={selectedInterviewer}
                  onChange={(selectedOption) => {
                    setSelectedInterviewer(selectedOption);
                    let interviewRound = determineInterviewRound();
                    let link = ``;
                    link = `${selectedOption.value}?a2=${candidateId}&a3=${interviewRound}&a4=${candidateType}`;
                    if (candidateType === "Company") {
                      link = `${selectedOption.value}?a2=${candidateId}&a3=${candidateType}`;
                    }
                    setGeneratedLink(link);
                  }}
                  placeholder="Select a user"
                />
              </>
            )}

            {(getCategory() === "Job AD" ||
              getCategoryBasedOnStatus() === "Job AD") && (
              <div className="flex flex-col gap-2">
                {selectedCommunicationTemplate?.type === "Recruit" ? (
                  <label>Select InApp Job AD Templates</label>
                ) : (
                  <label>Select InApp Consultation Templates</label>
                )}
                <Select
                  options={templates}
                  onChange={handleTemplateChange}
                  value={selectedTemplate}
                  placeholder="Choose a template"
                  className="template-select"
                  menuPortalTarget={document.body} // renders the dropdown in the body element
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure a high z-index for the portal
                  }}
                />
              </div>
            )}

            {(mode === "email" || mode === "both") && (
              <div className="flex flex-col gap-2">
                <label>Subject</label>
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="message-field"
                  disabled={!custom}
                />
              </div>
            )}

            {(mode === "email" || mode === "both") && (
              <div className="flex flex-col gap-2">
                <label>Body</label>
                <div className="relative">
                  <CommandTextArea
                    options={options}
                    text={messageText}
                    setText={setMessageText}
                    disabled={!custom}
                    className="message-field"
                  />
                  <p className="absolute bottom-2 text-xs right-0 bg-gray-700 text-white px-4 py-2 rounded-3xl">
                    {messageText.length} characters
                  </p>
                </div>
              </div>
            )}

            {(mode === "sms" || mode === "both") && (
              <div className="flex flex-col gap-2">
                <label>SMS Body</label>
                <div className="relative">
                  <CommandTextArea
                    options={options}
                    text={smsText}
                    setText={setSmsText}
                    disabled={!custom}
                    className="message-field"
                  />
                  <p className="absolute bottom-2 text-xs right-0 bg-gray-700 text-white px-4 py-2 rounded-3xl">
                    {smsText.length} characters
                  </p>
                </div>
              </div>
            )}

            {custom && (mode === "email" || mode === "both") && (
              <div>
                <label>Attachment (For Email Only)</label>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setUploadedFile(file);
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      setAttachmentBase64(reader.result.split(",")[1]);
                      setFileName(file.name);
                    };
                  }}
                />
              </div>
            )}

            {!custom && (mode === "email" || mode === "both") && (
              <div className="flex flex-col gap-2">
                <label>Attachment (For Email Only)</label>
                <img
                  src={selectedCommunicationTemplate?.attachments[0]?.url}
                  alt="attachment"
                  className="h-20 w-fit object-contain"
                />
              </div>
            )}
          </div>
        </div>

        {!bulk && mode === "email" && (
          <button
            className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
            onClick={sendSingleEmail}
          >
            Send Email
          </button>
        )}
        {!bulk && mode === "sms" && (
          <button
            className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
            onClick={sendSingleSms}
          >
            Send SMS
          </button>
        )}
        {!bulk && mode === "both" && (
          <button
            className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
            onClick={() => {
              sendBoth();
            }}
          >
            Send Both
          </button>
        )}
        {custom && user.userType === "SuperAdmin" && (
          <button
            className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
            onClick={() => {
              handleSaveNewTemplate();
            }}
          >
            Save As Template
          </button>
        )}

        <div className="action-buttons py-4">
          {bulk && mode === "email" && (
            <button
              className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg"
              onClick={sendBulkInvitationEmails}
            >
              Send Emails
            </button>
          )}
          {bulk && mode === "sms" && (
            <button
              className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
              onClick={sendBulkInvitationSMS}
            >
              Send SMS
            </button>
          )}
          {bulk && mode === "both" && (
            <button
              className="w-full px-4 py-2 bg-[#022780] text-white rounded-lg mt-4"
              onClick={sendBulkBoth}
            >
              Send Both
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InvitationModal;

import React, { useState, useEffect, useContext, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import SellingCardApi from "../../../../API/SellingCardApi";
import Swal from "sweetalert2";
import StringIntReq from "../../../../Requests/StringIntReq";
import CreateSubTrainingModuleReq from "../../../../Requests/TrainingModule/CreateSubTrainingModuleReq";
import UpdateSubTrainingModuleReq from "../../../../Requests/TrainingModule/UpdateSubTrainingModuleReq";
import VersatileLoader from "../../../../components/VersatileLoader";
import { UserContext } from "../../../../App";
import { Tree } from "react-arborist";
import ContextMenu from "../../../../components/ContextMenu";
import { MdDelete, MdEdit, MdOutlineQuiz, MdQuiz } from "react-icons/md";
import {
  FaFile,
  FaFileMedical,
  FaFolder,
  FaFolderOpen,
  FaFolderPlus,
} from "react-icons/fa6";
import CreateSellingCardReq from "../../../../Requests/SellingCard/CreateSellingCardReq";
import ReOrderTrainingModuleReq from "../../../../Requests/TrainingModule/ReOrderTrainingModuleReq";
import { AiOutlineFile, AiOutlineFolder, AiOutlineFolderOpen } from "react-icons/ai";

const SideBarTree = ({
  trainingModule,
  setSelectedSellingCardId,
  setSelectedSubTrainingModuleId,
  hasUnsavedChanges,
  selectedSellingCardId,
  setHasUnsavedChanges,
  setSellingCardIds,
  setQuizModalOpen,
  // isProcessing,
  // setIsProcessing,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const tree = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const formatTreeData = (data, sellingCardIds) => {
    let child = [];
    if (data.sellingCards && data.sellingCards.length > 0) {
      child = data.sellingCards.map((card, index) => ({
        name: card.name,
        isFolder: false,
        id: card.id.toString(),
        index: index,
        sellingCardNumber: card.sellingCardNumber,
        type: card.type,
      }));
    }
    if (
      data.childSubTrainingModules &&
      data.childSubTrainingModules.length > 0
    ) {
      child
        .concat(formatTreeData(data.childSubTrainingModules, sellingCardIds))
        .flat();
    }
    data.map((item) => {
      if (item.sellingCards && item.sellingCards.length > 0) {
        item.sellingCards.map((card, index) => {
          sellingCardIds.push(card.id);
        });
      }
    });
    return data.map((item, index) => ({
      id: item.id.toString(),
      name: item.name,
      isOpen: false,
      isFolder: true,
      index: index,
      subTrainingModuleNumber: item.subTrainingModuleNumber,
      children: new Array().concat(
        item.sellingCards?.length > 0
          ? item.sellingCards?.map((card, index) => ({
              name: card.name,
              isFolder: false,
              index: index,
              id: card.id.toString(),
              sellingCardNumber: card.sellingCardNumber,
              type: card.type,
            }))
          : [],
        formatTreeData(item.childSubTrainingModules, sellingCardIds)
      ),
    }));
  };

  const sortTreeData = (data) => {
    data.sort((a, b) => {
      if (a.isFolder && b.isFolder) {
        return a.subTrainingModuleNumber - b.subTrainingModuleNumber;
      } else if (!a.isFolder && !b.isFolder) {
        return a.sellingCardNumber - b.sellingCardNumber;
      } else if (a.isFolder && !b.isFolder) {
        return -1;
      } else {
        return 1;
      }
    });

    data.forEach((item) => {
      if (item.children && item.children.length > 0) {
        sortTreeData(item.children);
      }
    });

    return data;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const req = new StringIntReq();
      req.int = parseInt(trainingModule.id);
      const res = await SellingCardApi.GetSubTrainingModuleTree(req);
      const data = res.data;
      // console.log(data);
      let sellingCardIds = [];
      const formattedTreeData = formatTreeData(data, sellingCardIds);

      // console.log("formattedTreeData:", formattedTreeData);
      console.log("sellingCardIds:", sellingCardIds);

      // console.log("first Formatted Tree Data:", formattedTreeData);
      setTreeData(formattedTreeData);
      setSellingCardIds(sellingCardIds);
      if (!selectedSellingCardId) {
        setSelectedSellingCardId(sellingCardIds[0]);
      }
      setLoading(false);
      // console.log("second Tree Data:", formattedTreeData);
    } catch (error) {
      console.error("Error fetching tree data:", error);
    }
  };

  const handleCreate = async (nodeData) => {
    if (user.userType === "SalesRep") return;
    // setIsProcessing(true);
    const req = new CreateSubTrainingModuleReq();
    req.parentSubTrainingModuleId = nodeData.id;
    req.trainingModuleId = trainingModule.id;

    const { value: name } = await Swal.fire({
      title: "Create Sub Training Module",
      input: "text",
      inputLabel: "Name",
      showCancelButton: true,
      confirmButtonText: "Create",
    });

    if (name) {
      req.name = name;
      try {
        await SellingCardApi.CreateSubTrainingModule(req);
        await fetchData();
        Swal.fire("Created!", "", "success");
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
    // setIsProcessing(false);
  };

  const handleCreateSellingCard = async (nodeData, type = 'selling') => {
    if (user.userType === "SalesRep") return;
    // setIsProcessing(true);
    const req = new CreateSellingCardReq();
    req.subTrainingModuleId = nodeData.id;
    req.type = type;

    const { value: name } = await Swal.fire({
      title: `Create ${type === 'quiz' ? 'Quiz' : 'Selling'} Card`,
      input: "text",
      inputLabel: "Name",
      showCancelButton: true,
      confirmButtonText: "Create",
    });

    if (name) {
      req.name = name;
      try {
        await SellingCardApi.CreateSellingCard(req);
        await fetchData();
        Swal.fire("Created!", "", "success");
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
    // setIsProcessing(false);
  };

  const handleEdit = async (nodeData) => {
    if (user.userType === "SalesRep") return;
    // setIsProcessing(true);
    const req = new UpdateSubTrainingModuleReq();
    req.id = nodeData.id;
    req.trainingModuleId = trainingModule.id;

    const { value: name } = await Swal.fire({
      title: "Edit Sub Training Module",
      input: "text",
      inputLabel: "Name",
      inputValue: nodeData.name,
      showCancelButton: true,
      confirmButtonText: "Save",
    });

    if (name) {
      req.name = name;
      setTreeData((prev) => {
        const updatedTreeData = { ...prev };
        const findAndReplace = (data) => {
          if (data.id === nodeData.id) {
            data.name = name;
          } else if (data.children) {
            data.children = data.children.map((child) => findAndReplace(child));
          }
          return data;
        };
        updatedTreeData.children = updatedTreeData.children.map((child) =>
          findAndReplace(child)
        );
        return updatedTreeData;
      });

      try {
        await SellingCardApi.UpdateSubTrainingModule(req);
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
    // setIsProcessing(false);
  };

  const handleEditSellingCard = async (nodeData) => {
    if (user.userType === "SalesRep") return;
    // setIsProcessing(true);
    const req = {
      id: nodeData.id,
      name: nodeData.name,
    };

    const { value: name } = await Swal.fire({
      title: "Edit Sub Selling Card",
      input: "text",
      inputLabel: "Name",
      inputValue: nodeData.name,
      showCancelButton: true,
      confirmButtonText: "Save",
    });

    if (name) {
      req.name = name;

      try {
        await SellingCardApi.EditSellingCard(req);
        await fetchData();
        Swal.fire("Updated!", "", "success");
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
    // setIsProcessing(false);
  };

  const handleDelete = async (nodeData) => {
    if (user.userType === "SalesRep") return;
    // setIsProcessing(true);
    const req = new StringIntReq();
    req.int = nodeData.id;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire("Deleting...", "", "info");
        await SellingCardApi.DeleteSubTrainingModule(req);
        await fetchData();
        Swal.fire(
          "Deleted!",
          "Your sub-training module has been deleted.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
    // setIsProcessing(false);
  };

  const handleDeleteSellingCard = async (nodeData) => {
    if (user.userType === "SalesRep") return;
    const req = new StringIntReq();
    req.int = nodeData.id;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire("Deleting...", "", "info");
        await SellingCardApi.DeleteSellingCard(req);
        await fetchData();
        Swal.fire(
          "Deleted!",
          "Your selling card has been deleted.",
          "success"
        );
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
  };

  const onNameClick = async ({ nodeData }) => {
    // if (isProcessing) {
    //   Swal.fire(
    //     "Processing",
    //     "Please wait until the current action is completed.",
    //     "info"
    //   );
    //   return;
    // }
    // // setIsProcessing(true);
    // console.log("Node Data:", nodeData);
    // console.log("isFolder", nodeData.isLeaf);
    // console.log(nodeData);
    if (user.userType === "SalesRep") {
      if (nodeData.isLeaf) {
        if (hasUnsavedChanges && selectedSellingCardId !== nodeData.id) {
          const result = await Swal.fire({
            title: "Unsaved Changes",
            text: "You have unsaved changes. Do you want to save them before navigating?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            cancelButtonText: "Don't Save",
            showDenyButton: true,
            denyButtonText: "Edit",
          });

          if (result.isDismissed) {
            setHasUnsavedChanges(false);
          } else if (result.isDenied) {
            // setIsProcessing(false);
            return; // Prevent navigation and remain on the current page
          }
        }
        setSelectedSellingCardId(nodeData.id);
        return;
      }
      return;
    }
    if (nodeData.isLeaf) {
      if (hasUnsavedChanges && selectedSellingCardId !== nodeData.id) {
        const result = await Swal.fire({
          title: "Unsaved Changes",
          text: "You have unsaved changes. Do you want to save them before navigating?",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: false,
          allowOutsideClick: false,
          cancelButtonText: "Don't Save",
          showDenyButton: true,
          denyButtonText: "Edit",
        });

        if (result.isDismissed) {
          setHasUnsavedChanges(false);
        } else if (result.isDenied) {
          // setIsProcessing(false);
          return; // Prevent navigation and remain on the current page
        }
      }
      console.log("Node Data1:", selectedSellingCardId);
      setSelectedSellingCardId(nodeData.id);
      console.log("Node Data2:", selectedSellingCardId);
      // setIsProcessing(false);
      return;
    }
    setSelectedSubTrainingModuleId(nodeData.id);
    console.log("Node Data:", nodeData.id);
    Swal.fire({
      title: "Manage Sub Training Module",
      html: `
        <button id="create-btn" class="swal2-confirm swal2-styled" style="margin-right: 10px;">
          <i class="fa fa-plus"></i> Create
        </button>
        <button id="edit-btn" class="swal2-confirm swal2-styled" style="margin-right: 10px;">
          <i class="fa fa-edit"></i> Edit
        </button>
        <button id="delete-btn" class="swal2-cancel swal2-styled">
          <i class="fa fa-trash"></i> Delete
        </button>
      `,
      showConfirmButton: false,
      didOpen: () => {
        const createBtn = Swal.getPopup().querySelector("#create-btn");
        const editBtn = Swal.getPopup().querySelector("#edit-btn");
        const deleteBtn = Swal.getPopup().querySelector("#delete-btn");

        createBtn.addEventListener("click", () => {
          handleCreate(nodeData);
        });

        editBtn.addEventListener("click", () => {
          handleEdit(nodeData);
        });

        deleteBtn.addEventListener("click", () => {
          handleDelete(nodeData);
        });
      },
    });
    // setIsProcessing(false);
  };

  let exportMenuItems = [
    {
      title: "Create",
      onClick: () => {},
    },
    {
      title: "Delete",
      onClick: () => {},
    },
    {
      title: "Rename",
      onClick: () => {},
    },
  ];

  function Node({ node, style, tree, dragHandle }) {
    let customStyle =
      "flex items-center gap-4 hover:bg-gray-100 cursor-pointer rounded w-30";
    if (node.isSelected) {
      customStyle =
        "flex items-center gap-4 hover:bg-blue-650 cursor-pointer bg-blue-700 text-white rounded w-30";
    }
    return (
      <div
        style={style}
        ref={dragHandle}
        onClick={() => {
          if (!node.isLeaf) {
            tree.toggle(node.id);
          } else {
            onNameClick({ nodeData: node });
          }
        }}
        className={customStyle}
      >
        <div className="flex items-center gap-2">
          <div>
            {node.isLeaf ? (
              node.data.type === "quiz" ? (
                <MdOutlineQuiz />
              ) : (
              <AiOutlineFile />)
            ) : node.isOpen ? (
              <AiOutlineFolderOpen />
            ) : (
              <AiOutlineFolder />
            )}
          </div>
          <div>{node.data.name}</div>
        </div>
        {node.isSelected && user.userType !== "SalesRep" && (
          <div className="flex gap-1 items-center">
            <MdEdit
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (node.isLeaf) {
                  handleEditSellingCard(node.data);
                } else {
                  handleEdit(node.data);
                }
              }}
            />
            <MdDelete
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (node.isLeaf) {
                  handleDeleteSellingCard(node.data);
                } else {
                  handleDelete(node.data);
                }
              }}
            />
            {!node.isLeaf && (
              <MdQuiz
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCreateSellingCard(node.data, 'quiz');
                }}
              />
            )}
            {!node.isLeaf && (
              <FaFolderPlus
                className="cursor-pointer"
                onClick={(e) => {
                  console.log("node.data:", node.data);
                  e.stopPropagation();
                  handleCreate(node.data);
                }}
              />
            )}
            {!node.isLeaf && (
              <FaFileMedical
                className="cursor-pointer"
                onClick={(e) => {
                  console.log("node.data:", node.data);
                  e.stopPropagation();
                  handleCreateSellingCard(node.data);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
  const onCreate = ({ parentId, index, type }) => {
    console.log("onCreate:", parentId);
  };
  const onRename = ({ id, name }) => {
    console.log("onRename:", id, name);
  };
  const onMove = async ({ dragIds, parentId, index }) => {
    try {
      const draggedNode = tree.current.get(dragIds[0]);
      const parentNode = draggedNode.parent;

      if (parentId === parentNode.id) {
        if (draggedNode.data.index === index) return;
      }

      const req = new ReOrderTrainingModuleReq();

      if (draggedNode.isLeaf) {
        req.SellingCardId = draggedNode.id;
        req.SellingCardNumber = index;
      } else {
        req.SubTrainingModuleId = draggedNode.id;
        req.SubTrainingModuleNumber = index;
      }

      req.ParentSubTrainingModuleId = parentId;
      req.isSellingCard = draggedNode.isLeaf ? true : false;

      const res = await SellingCardApi.ReOrderTrainingModule(req);

      if (res.status.success) {
        await fetchData();
        Swal.fire("Success", "Reordered successfully", "success");
      } else {
        Swal.fire("Error", res.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };
  const onDelete = ({ ids }) => {
    console.log("onDelete:", ids);
  };
  return loading ? (
    <div className="hidden spinner md:w-64 md:block" style={{ height: "70vh" }}>
      <VersatileLoader color="#2761D0" size="large" />
    </div>
  ) : (
    <div className="relative h-full">
      {!isOpen && (
        <button
          className="md:hidden fixed top-15 left-5 bg-blue-950 text-white px-2 py-1 rounded shadow-lg z-50"
          onClick={() => setIsOpen(true)}
          // disabled={isProcessing}
        >
          <MenuIcon />
        </button>
      )}

      <div
        className={`p-4 h-full fixed botom-0 left-0 bg-white transition-transform duration-300 z-40 overflow-y-auto  rounded-lg overflow-x-hidden
        ${isOpen ? "translate-x-0 left-0" : "-translate-x-full"} 
        md:translate-x-0 md:static md:block w-64 md:w-64 md:h-full overflow-x-hidden
        lg:w-64 lg:h-full
        `}
        style={{ height: "80vh" }}
      >
        {/* <div className="p-2 border-b absolute w-auto bg-white z-40 flex justify-between items-center">
          <h3 className="text-md font-semibold">Sub Training Modules</h3>
          <div className="flex gap-2">
            <MdAdd className="cursor-pointer" onClick={() => handleCreate(activeNode)} />
            <MdDelete className="cursor-pointer" onClick={() => handleDelete(activeNode)} />
          </div>
        </div> */}
        {/* <ContextMenu
          menuItems={exportMenuItems}
          anchorEl={anchorEl}
          handleClose={() => {
            setAnchorEl(null);
          }}
        /> */}
        {/* <div className="flex justify-between items-center p-4"> */}
          {treeData && (
            <Tree
              data={treeData}
              // onCreate={onCreate}
              // onRename={onRename}
              onMove={
                user.userType === "SalesRep" ? null : onMove}
              ref={tree}
              selection={
                selectedSellingCardId ? selectedSellingCardId.toString() : ""
              }
              height={800}
            >
              {Node}
            </Tree>
          )}
        {/* </div> */}
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 md:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default SideBarTree;

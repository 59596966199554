import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Swal from 'sweetalert2';
import UserSelector from '../../../components/UserSelector'; // Assuming this is the component you're using for selecting sales reps
import { MdDelete } from 'react-icons/md';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import StringIntReq from '../../../Requests/StringIntReq';
import FiberCompanySalesRepReq from '../../../Requests/Organizations/FiberCompanySalesRepReq'; // Assuming you have a request for sales reps
import UsersApi from '../../../API/UsersApi';

const FiberCompanySalesReps = forwardRef(({ fiberCompanyId }, ref) => {
    const [repWithName, setRepWithName] = useState([]);
    const [selectedRep, setSelectedRep] = useState(null);
    const [assignedReps, setAssignedReps] = useState([]);
    
    useEffect(() => {
        fetchAssignedSalesReps();
    }, [fiberCompanyId]);


    const fetchAssignedSalesReps = async () => {
        try {
            const req = new StringIntReq();
            req.int = fiberCompanyId;
            const res = await UsersApi.GetAssignedSalesRepsForFiberCompany(req);
            if (res?.status?.success) {
                if (!res.data || res.data.length === 0) {
                    setAssignedReps([]); // Clear state if no reps are assigned
                } else {
                    const mappedReps = res.data.map((rep) => ({
                        id: rep.id,
                        label: rep.firstName + " " + rep.lastName,
                    }));
                    setAssignedReps(mappedReps); // Set the new sales reps
                }
            } else {
                console.error('Failed to fetch sales reps');
            }
        } catch (error) {
            console.error('Error fetching sales reps:', error);
        }
    };

    const refreshSalesReps = () => {
        fetchAssignedSalesReps();
    };

    useImperativeHandle(ref, () => ({
        refreshSalesReps,
    }));


    const addSalesRep = () => {
        Swal.fire({
            icon: 'info',
            title: 'Assigning Sales Rep',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        
        const salesRepReq = new FiberCompanySalesRepReq();
        salesRepReq.fiberCompanyId = fiberCompanyId;
        salesRepReq.salesRepId = selectedRep;

        FiberCompanyApi.AddSalesRepToFiberCompany(salesRepReq).then(salesRepRes => {
            if (salesRepRes?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sales Rep assigned successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
                fetchAssignedSalesReps();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: salesRepRes?.status?.message || 'Failed to Assign Sales Rep',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        });
    };

    const removeSalesRep = (repId) => {
        Swal.fire({
            icon: 'info',
            title: 'Removing Sales Rep',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const salesRepReq = new FiberCompanySalesRepReq();
        salesRepReq.fiberCompanyId = fiberCompanyId;
        salesRepReq.salesRepId = repId;

        FiberCompanyApi.RemoveSalesRepFromFiberCompany(salesRepReq).then(salesRepRes => {
            if (salesRepRes?.status?.success) {
                fetchAssignedSalesReps();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sales Rep removed successfully',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: salesRepRes?.status?.message || 'Failed to Remove Sales Rep',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        });
    };

    return (
        <div className="add_plan_to_template">
            <div className='form'>
                <h2>Assign Sales Representatives</h2>

                <UserSelector
                    setUser={setSelectedRep}
                    conversationType={"Private"}
                    placeholder="Select Sales Representative"
                />

                {selectedRep && (
                    <button onClick={addSalesRep} className="add-btn">
                        Add Sales Rep
                    </button>
                )}
            </div>

            <div className="fiber_plans">
                {assignedReps.length > 0 ? (
                    assignedReps.map((rep) => (
                        <div key={rep.id} className="fiber_plan">
                            <span>{rep.label}</span>
                            <button
                                className="delete-btn"
                                onClick={() => removeSalesRep(rep.id)}
                            >
                                <MdDelete size={20} color="white" />
                            </button>
                        </div>
                    ))
                ) : (
                    <p>No sales representatives assigned yet.</p>
                )}
            </div>
        </div>
    );
});

export default FiberCompanySalesReps;

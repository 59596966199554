import React from "react";
import BusinessCard from "../../../Shared/BusinessCenter/components/BusinessCard";
import { IoWifi } from "react-icons/io5";
import { IoMdTv } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import ContactButtons from "../../../../Services/ContactButtons";
import MobileVersionBusinessCard from "../../../Reps/DigitalBusinessCenter/components/MobileVersionBusinessCard";
import PlanCard from "./PlanCard";
import { FaCheckCircle } from "react-icons/fa";
const Index = ({
  offer,
  setOpenConsultation,
  setOpenShareModal,
  setColor,
  setOpen,
  setSelectedPlan,
}) => {
  return (
    <div>
      <div
        style={{
          background: "none",
          backgroundColor: "white",
        }}
        className="bg-white new_offer_page"
      >
        <div
          style={{
            maxWidth: "800px",
          }}
          className="inner_container"
        >
          <div className="banner">
            <img
              style={{ width: "100%", height: "auto" }}
              src={`/images/offer/wyyerd/wyyerd_banner.png`}
              alt="banner"
              className="banner_image first"
            />
            <div className="top_accept_offer">
              <div className="inner_div shadow-xl">
                <img
                  style={{ width: "250px" }}
                  src={"data:image/png;base64," + offer?.fiberCompany?.logo}
                  alt="logo"
                  className="logo"
                />
                {
                  <div>
                    <h1 className="text-[3rem] text-[#58595C] font-[800] text-center">
                      AMERICA’S
                      <br />
                      FASTEST,
                      <br />
                    </h1>
                    <h1 className="sm:text-[2.375rem] text-[1.75rem]  font-[800] text-center text-[#F47920]">
                      MOST AFFORDABLE
                      <br />
                    </h1>
                    <h1 className="sm:text-[2.375rem] text-[2rem] font-[800] text-center text-[#58595C]">
                      INTERNET SERVICE
                      <br />
                    </h1>
                  </div>
                }

                <button
                  className="text-white bg-[#1B51A4] p-4 rounded-2xl text-center font-open-sans text-3xl font-extrabold uppercase "
                  onClick={() => {
                    document
                      .getElementById("plan1")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  VIEW PLANS
                </button>
              </div>
            </div>
          </div>
          <div className="company_details">
            <p className="limited_offer text-[#58595C]">WYYERD FIBER</p>
            <p className="text-5xl font-[800] text-[#F47920]">LIMITED TIME</p>
            <p className="text-5xl font-[800] text-[#1B51A4]">HOT OFFER</p>
            <div className="promo_offer_border">
              <h1
                className="bg-white promo_offer"
                style={{ borderColor: "#1B51A4" }}
              >
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.2rem",
                    fontWeight: "800",
                    color: "#1B51A4",
                  }}
                >
                  -----
                </span>
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.2rem",
                    fontWeight: "800",
                    color: "#58595C",
                  }}
                >
                  {" "}
                  FREE{" "}
                </span>
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.2rem",
                    fontWeight: "800",
                    color: "#1B51A4",
                  }}
                >
                  -----
                  <br />
                </span>
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.2rem",
                    fontWeight: "800",
                    color: "#58595C",
                  }}
                >
                  INSTALLATION
                </span>
              </h1>
            </div>
            <p className="available text-2xl text-[#81848A]">AVAILABE WITH</p>
            <img
              src={`/images/offer/wyyerd/logo.png`}
              alt="WYYERD"
              className="company_logo"
              style={{ maxWidth: "800px",width:"100%" }}
            />
            <img
              src="/images/offer/logo.png"
              alt="lightning"
              className="company_logo max-w-[800px] w-full"
            />
          </div>
          {offer?.salesRep && (
            <div
              style={{
                padding: "1rem",
                marginTop: "2rem",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "600px",
                margin: "auto",
                zIndex: 100,
                position: "relative",
              }}
              className="shadow-xl rounded-lg"
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  // borderRadius: "10px",
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "600px",
                }}
                
              >
                <MobileVersionBusinessCard
                  displayContactButtons={false}
                  displayQr={false}
                  reviewButton={false}
                  displayOffer={false}
                  salesRep={offer?.salesRep}
                  salesRepIsUser={false}
                />
              </div>
            </div>
          )}
          {/* {offer?.salesRep && (
            <div className="bg-white">
              <BusinessCard
                salesRep={offer?.salesRep}
                salesRepId={offer?.salesRepId}
                displayOffer={false}
                fiberCompany={offer?.fiberCompanyId}
              />
            </div>
          )} */}

          <div className="banner" id="plan1" style={{ marginTop: "-4rem" }}>
            <img
              style={{ width: "100%", height: "auto" }}
              src="/images/offer/wyyerd/smilling_banner.png"
              alt="banner"
              className="banner_image"
            />
            {/* <div className='flex justify-center items-center' style={{
                                backgroundColor: "#F47920",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",

                                // borderRadius:"20px"
                            }}>
                                <PointPlan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[0].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[1]?.promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[0].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div> */}
          </div>
          {offer?.offerTemplate && (
            <div className="company_details margin-top-4">
              <p className="limited_offer text-black">View Plans</p>
              <PlanCard
                offer={offer}
                plan={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]?.fiberPlan
                }
                color={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]?.color
                    ? offer?.offerTemplate?.offerTemplateFiberPlans[0].color
                    : "#F47920"
                }
                setSelectedPlan={setSelectedPlan}
                setOpen={setOpen}
                name={
                  offer?.offerTemplate?.offerTemplateFiberPlans[0]
                    ?.promotionalName
                }
              />
              <PlanCard
                offer={offer}
                plan={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]?.fiberPlan
                }
                color={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]?.color
                    ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color
                    : "#1B51A4"
                }
                setSelectedPlan={setSelectedPlan}
                setOpen={setOpen}
                name={
                  offer?.offerTemplate?.offerTemplateFiberPlans[1]
                    ?.promotionalName
                }
              />
              {offer?.offerTemplate?.offerTemplateFiberPlans?.length > 2 && (
                <PlanCard
                  offer={offer}
                  plan={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]?.fiberPlan
                  }
                  color={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]?.color
                      ? offer?.offerTemplate?.offerTemplateFiberPlans[2].color
                      : "#000626"
                  }
                  setSelectedPlan={setSelectedPlan}
                  setOpen={setOpen}
                  name={
                    offer?.offerTemplate?.offerTemplateFiberPlans[2]
                      ?.promotionalName
                  }
                />
              )}
              {offer?.offerTemplate?.bulletPoints && (
                <div
                  style={{
                    marginTop: "2rem",
                    padding: "0rem",
                  }}
                  className=" offer_services_container"
                >
                  <div
                    className="min-w-[350px] text-black inner_container"
                    style={{
                      border: "4px solid #1B51A4",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1rem",
                      gap: "2rem",
                    }}
                  >
                    <ul className="flex flex-col gap-4">
                      {offer?.offerTemplate?.bulletPoints
                        .split("\n")
                        .map((bulletPoint, index) => (
                          <li
                            className="flex items-center justify-start gap-4"
                            key={index}
                          >
                            <FaCheckCircle color={"#1B51A4"} size={25} />
                            <p className="text-black font-bold">
                              {bulletPoint}
                            </p>
                          </li>
                        ))}
                    </ul>
                    <img
                      style={{ width: "250px" }}
                      src={"data:image/png;base64," + offer?.fiberCompany?.logo}
                      alt="logo"
                      className="logo"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* {
                        offer?.offerTemplate && offer?.fiberCompanyId !== 10 &&
                        <div style={{
                            marginTop: "-8rem"
                        }} className=' banner' id="plan1">
                            <img style={{ width: "100%", height: "auto" }} src="/images/offer/pointbroadband/family.png" alt='banner' className='banner_image' />
                            <div className='flex justify-center items-center' style={{
                                backgroundColor: "#F47920",
                                marginTop: "-8rem",
                                padding: "5rem 1rem",

                                // borderRadius:"20px"
                            }}>
                                <PointPlan offer={offer} setColor={setColor} color={offer?.offerTemplate?.offerTemplateFiberPlans[1].color ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color : "#01A7E1"} setSelectedPlan={setSelectedPlan} setOpen={setOpen} name={offer?.offerTemplate?.offerTemplateFiberPlans[1].promotionalName} plan={offer?.offerTemplate?.offerTemplateFiberPlans[1].fiberPlan} bulletPoints={offer?.offerTemplate?.bulletPoints} />
                            </div>
                        </div>
                    } */}

          <div
            style={{
              marginTop: "-4rem",
            }}
            className="banner"
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src="/images/offer/astound/smiling_customer.png"
              alt="banner"
              className="banner_image"
            />
            <div
              style={{
                marginTop: "-3rem",
                width: "100%",
                backgroundColor: "white",
                padding: "2rem 0rem",
              }}
            >
              <button
                style={{
                  backgroundColor: "#1B51A4",
                }}
                onClick={() => setOpenConsultation(true)}
                className=" schedule_button"
              >
                Schedule A<br />
                Consultation
              </button>
              <h1 className="customer_service">
                Great Customer
                <br />
                Service is Built In.
              </h1>
              <p className="customer_service_p font-semibold">
                We understand the importance
                <br /> of the services we provide to <br />
                your home – that’s why we
                <br />
                treat you like family.
              </p>
            </div>
          </div>
          {/* <div className=' banner'> */}
          {/* <div className='flex justify-center items-center flex-col p-4 gap-4' style={{ marginTop: "-4rem", width: "100%", backgroundColor: "black", padding: "2rem 0rem" }}>
                            <p className='text-5xl font-extrabold text-[#F47920]'>GIVE $100</p>
                            <p className='text-5xl font-extrabold'>GET $100</p>
                            <p className='text-lg text-center'>Give your friends a $100 gift card when they use your unique link to sign up for Point Broadband service online, and you'll get a $100 gift card*, too, after their service is installed.</p>
                            <p className="text-3xl text-center font-bold">GET $100 FOR EVERY<br />FRIEND YOU REFER</p>
                        </div> */}

          {/* </div> */}
          <div
            style={
              {
                // marginTop: "-8rem"
              }
            }
            className="banner"
          >
            {/* <button
              onClick={() => {
                // createSharedOffer()
                setOpenShareModal(true);
              }}
              style={{ backgroundColor: "#F47920" }}
              className="schedule_button"
            >
              Share Offer
            </button> */}
            <img
              style={{ marginTop: "-3rem", width: "100%", height: "auto" }}
              src="/images/offer/wyyerd/vr_gaming.png"
              alt="banner"
              className="banner_image"
            />
          </div>
          <div
            style={{
              marginTop: "-14rem",
              border: "4px solid #F47920",
            }}
            className=" offer_services_container"
          >
            <div className="min-w-[350px] text-black inner_container">
              <h1>
                FIBER INTERNET
                <br />
                PROVIDER, TV,
                <br />
                DIGITAL PHONE
              </h1>
              <div className="services">
                <IoWifi color="#1B51A4" size={100} />
                <h3>Internet</h3>
                <p
                  style={{
                    color: "#1B51A4",
                  }}
                >
                  See Plans
                </p>
              </div>
              <div className="services">
                <IoMdTv color="#1B51A4" size={100} />
                <h3>TV</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Start Streaming now
                </p>
              </div>
              <div className="services">
                <FaPhone color="#1B51A4" size={100} />
                <h3>Voice</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Talk More. Pay Less
                </p>
              </div>
            </div>
          </div>
          <div className="banner">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                borderTopLeftRadius: "0px",
                marginTop: "2rem",
                borderTopRightRadius: "0px",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="quick_h1">Quick Links</h1>
              <img
                style={{ margin: "1rem 0rem" }}
                src={`/images/offer/wyyerd/person.png`}
                alt="wyyerd"
                className="company_logo"
              />
              <h3 className="quick_h3">Contact</h3>
              <p className="quick_p">Lightning Support</p>
              <p
                onClick={() => {
                  let contactButton = new ContactButtons();
                  contactButton.call("+16193936033");
                }}
                className="quick_p cursor-pointer underline"
              >
                619-393-6033
              </p>
              <img
                style={{ margin: "1rem 0rem" }}
                src={`/images/offer/wyyerd/logo.png`}
                alt="wyyerd"
                className="company_logo"
              />
              <img
                style={{ margin: "1rem 0rem" }}
                src="/images/offer/logo.png"
                alt="lightning"
                className="company_logo"
              />
              <p className="quick_copy">
                Copyright Lightning OS 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

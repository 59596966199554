import { Rating } from '@mui/material'
import React from 'react'
import greyUser from './../../../../assets/images/userGrey.png'
const WrittenReview = ({ review }) => {

    const [showMore, setShowMore] = React.useState(false)
    return (
        <div className='written_review_container'>
            <div className='review_header'>
                <div className='profile'>
                    <img alt='review' src={review?.fidiumCustomer?.profileImage ? review.fidiumCustomer.profileImage : greyUser} />
                </div>
                <div className='info'>
                    <h3>{ review?.fidiumCustomer? review.fidiumCustomer.firstName + " " + review.fidiumCustomer.lastName : review.name}</h3>
                    <p>
                        {
                            review?.fidiumCustomer?
                        review.fidiumCustomer.address.length > 32
                            ? review.fidiumCustomer.address.substring(0, 32) + "..."
                            : review.fidiumCustomer.address
                            :
                            ''
                        }
                    </p>
                </div>
            </div>
            <div className='body sm:p-0 px-8'>
                <p className='text-xs sm:text-sm' >{review?.content?.length>50 && !showMore?(review.content.slice(0,60)+"..."):review.content} <span onClick={()=>{
                    setShowMore(!showMore)
                }} style={{fontSize:"0.8rem",color:"#2761D0",textDecoration:"underline",cursor:"pointer"}}>{review?.content?.length>80? !showMore?"Read More":"Read Less":""}</span></p>
            </div>
            <div className='rating'>
                <Rating name="read-only" value={review.rating} readOnly />
            </div>
            <p>{new Date(review?.timestamp).toDateString()}</p>
        </div>
    )
}

export default WrittenReview
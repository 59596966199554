import { DrawButton, MapContext } from '@terrestris/react-geo'
import { toLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import React, { useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import { MdOutlineDraw } from 'react-icons/md'
import MapHelper from '../../../../TeamAdmin/MapPage/MapHelper'
import Swal from 'sweetalert2'

const SelectArea = ({ map,candidates,setSelectedCandidateIds, searchAreaFeature,fiberHouses,searchArea,setSelectedFiberHouseIds, setSearchAreaFeature, setSearchArea }) => {

    const [active, setActive] = React.useState(false)
    const helper = new MapHelper(map)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
        })

        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth)
            })
        }

    }, [])


    const onDrawEnd = (e) => {
        console.log("draw end")
        const feature = e.feature;
        const geometry = feature.getGeometry();
        const coordinates = geometry.getCoordinates();
        setSearchAreaFeature([feature])

        setSearchArea(coordinates[0].map(coord => {
            const [lng, lat] = toLonLat(coord)
            return { latitude: lat, longitude: lng }
        }
        ))

        setActive(false)
    }

    useEffect(() => {
        if (searchAreaFeature?.length > 0) {
            // Get the map object

            let featureLayer;
            map.getLayers().forEach(layer => {
                const source = layer.getSource();
                if (source instanceof VectorSource) {
                    const features = source.getFeatures();
                    searchAreaFeature.forEach(feature => {
                        if (features.includes(feature)) {
                            featureLayer = layer;
                        }
                    })
                }
            });

            if (featureLayer) {
                featureLayer.setZIndex(1000);
            }
        }
    }, [searchAreaFeature]);

    const deletePolygon = () => {
        if (searchAreaFeature.length > 0) {


            searchAreaFeature.forEach(function (feature) {
                var selectedGeometry = feature.getGeometry(); // Assuming only one feature is selected
                map.getLayers().getArray().forEach(layer => {
                    const source = layer.getSource()
                    if (source instanceof VectorSource)
                        source.forEachFeature(function (feature) {
                            var featureGeometry = feature.getGeometry();
                            if (featureGeometry === selectedGeometry) {
                                layer.getSource().removeFeature(feature);
                                setSearchAreaFeature([])
                                setSearchArea(null)
                                setSelectedFiberHouseIds([])
                            }
                        });
                });
            })
        }
        else {


        }
    }

    const handleClick = async (index) => {
        const fiberHouseList = [];
        const candidateList = [];

        fiberHouses.forEach(fiberHouse => {
            if (helper.checkIfFiberHouseIsInsideServicableArea(fiberHouse, searchArea)) {
                fiberHouseList.push(fiberHouse);
            }
        });

        candidates.forEach(candidate => {
            if (helper.checkIfFiberHouseIsInsideServicableArea(candidate, searchArea)) {
                candidateList.push(candidate);
            }
        });


        if (fiberHouseList.length > 5000 && screenWidth < 1000) {
            Swal.fire({
                icon: 'error',
                title: 'Too Many Leads',
                text: 'Please mark less than 5,000 Leads, or use your PC to mark more',
                customClass: {
                    container: 'custom-swal'
                },
            });
            deletePolygon();
            return;
        }

        if (screenWidth > 1000 && (fiberHouseList.length + candidateList.length) > 40000) {
            Swal.fire({
                icon: 'error',
                title: 'Too Many Leads',
                text: 'Please mark less than 20,000 Leads',
                customClass: {
                    container: 'custom-swal'
                },
            });
            deletePolygon();
            return;
        }

        if (fiberHouseList.length === 0 && candidateList.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No Leads Found',
                text: 'Please mark another area',
                customClass: {
                    container: 'custom-swal'
                },
            });
            deletePolygon();
            return;
        }

        console.log("Fiber Houses: ", fiberHouseList);
        console.log("Candidates: ", candidateList);

        // Set the fiber house and candidate IDs to the respective context
        setSelectedFiberHouseIds(fiberHouseList.map(fiberHouse => fiberHouse.id));
        setSelectedCandidateIds(candidateList.map(candidate => candidate.id));
    };

    useEffect(
        () => {
            if (searchAreaFeature.length > 0) {
                handleClick(0);
            }
        },[searchAreaFeature]
    )

    return (
        <div className='absolute top-4 right-4 z-50'>
            <MapContext.Provider value={map}>
                {!searchAreaFeature.length > 0 ? <DrawButton
                    className={` ${active ? "bg-[#4DD4FF]" : "bg-[#fff]"} `}
                    name="drawPolygon"
                    drawType="Polygon"
                    drawInteractionConfig={{
                        freehand: screenWidth > 1000 ? false : true,
                        geometryName: "stat",

                    }}

                    zIndex={1000}
                    size='small'
                    onToggle={(pressed) => {
                        if (active !== pressed) {
                            console.log("toggle", pressed)
                            setActive(pressed)

                        }
                    }}

                    onDrawEnd={onDrawEnd}
                    style={{
                        borderRadius: '50%',
                        padding: "0.5rem",
                        color: "white",
                        boxShadow: '0px 0px 10px 0px #2B92D5'
                    }}
                >
                    <MdOutlineDraw color={active ? `#fff` : `#181E4B`} size={screenWidth > 1000 ? 30 : 20} />
                </DrawButton>
                    :
                    <button style={{
                        borderRadius: '50%',
                        padding: "0.5rem"
                    }} onClick={() => deletePolygon()} className='TrackLocationButton'>
                        <IoClose color='#181E4B' size={screenWidth > 1000 ? 30 : 20} />
                    </button>
                }
            </MapContext.Provider>
        </div>
    )
}

export default SelectArea
import React, { useContext, useEffect, useRef, useState } from "react";
import bot from "./../../Views/TeamAdmin/MapPage/Images/bot.png";
import ScheduleForm from "./components/ScheduleForm";
import FreeInstallForm from "./components/FreeInstallForm";
import AppointmentForm from "./components/AppointmentForm";
import ReviewModal from "./components/ReviewModal";
import Swal from "sweetalert2";
import { UserContext } from "../../App";

const DigitalActionBar = ({ lead, onReload = () => { } }) => {
    const [action, setAction] = useState("Send Offer");
    const [openModal, setOpenModal] = useState(false);
    const [lightOpenModal, setLightOpenModal] = useState(false);
    const [offerOrOrder, setOfferOrOrder] = useState("offer");
    const [openInstallTimeModal, setOpenInstallTimeModal] = useState(false);
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);
    const user = useContext(UserContext);
    function getActions() {
        if (lead.fidiumCustomer == null) {
            if (lead.offer == null)
                return [
                    {
                        label: "Send Offer",
                        onClick: () => {
                            setOfferOrOrder("offer");
                            setLightOpenModal(true);
                        },
                    },
                    {
                        label: "Reserve Free Install",
                        onClick: () => {
                            setOfferOrOrder("order");
                            setLightOpenModal(true);
                        },
                    },
                    {
                        label: "Schdule Follow-Up",
                        onClick: () => {
                            setOpenModal(true);
                        },
                    },
                ];
            else
                return [
                    {
                        label: "Reserve Free Install",
                        onClick: () => {
                            setOfferOrOrder("order");
                            setLightOpenModal(true);
                        },
                    },
                    {
                        label: "Schdule Follow-Up",
                        onClick: () => {
                            setOpenModal(true);
                        },
                    },
                ];
        } else if (lead.fidiumCustomer != null) {
            if (lead.fidiumCustomer.accountStatus === "Sold") {
                return [
                    {
                        label: "Schedule Install",
                        onClick: () => {
                            setOpenInstallTimeModal(true);
                        },
                    },
                ];
            } else if (
                lead.fidiumCustomer.accountStatus === "Scheduled" ||
                lead.fidiumCustomer.accountStatus === "Rescheduled"
            ) {
                return [
                    {
                        label: "Reschedule Install",
                        onClick: () => {
                            setOpenInstallTimeModal(true);
                        },
                    },
                ];
            } else if (lead.fidiumCustomer.accountStatus === "Completed") {
                return [
                    {
                        label: "Review Request",
                        onClick: () => {
                            if (user.userType === "SuperAdmin") {
                                setOpenReviewModal(true);
                            } else {
                                Swal.fire({
                                    icon: "info",
                                    title: "Available Soon",
                                    text: "This feature is not available yet",
                                });
                            }
                        },
                    },
                    // {
                    //     label: "Referral Request",
                    //     onClick: () => {
                    //         console.log("Referral Request")
                    //     }
                    // }
                ];
            } else if (lead.fidiumCustomer.accountStatus === "Canceled") {
                return [
                    {
                        label: "Reschedule Install",
                        onClick: () => {
                            setOpenInstallTimeModal(true);
                        },
                    },
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    useEffect(() => {
        if (lead) {
            if (getActions().length > 0) setAction(getActions()[0].label);
        }
    }, [lead]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const executeMainAction = () => {
        const selectedAction = getActions().find((a) => a.label === action);
        if (selectedAction) {
            selectedAction.onClick();
        }
    };

    return lead ? (
        <>
            <AppointmentForm
                onReload={onReload}
                lead={lead}
                open={openModal}
                setOpen={setOpenModal}
                setReload={() => { }}
            />
            <FreeInstallForm
                onReload={onReload}
                lead={lead}
                fiberHouse={lead?.fiberHouse}
                open={lightOpenModal}
                setOpen={setLightOpenModal}
                type={offerOrOrder}
                setReload={() => { }}
            />
            <ScheduleForm
                onReload={onReload}
                lead={lead}
                fidiumCustomer={lead?.fidiumCustomer}
                fiberCompany={lead?.fidiumCustomer?.fiberCompany}
                open={openInstallTimeModal}
                setOpen={setOpenInstallTimeModal}
                setReload={() => { }}
            />
            <ReviewModal
                lead={lead}
                open={openReviewModal}
                setOpen={setOpenReviewModal}
            />
            <div
                className="flex gap-2 items-center justify-between border-2 bg-white cursor-pointer border-[#022780] rounded-xl px-4 py-1 relative"
                ref={containerRef}
            >
                <div className="flex items-center " onClick={executeMainAction}>
                    <img src={bot} alt="bot" style={{ width: "40px", height: "40px" }} />
                </div>
                <span className="font-bold uppercase text-[#022780]">{action}</span>

                <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="focus:outline-none px-2 hover:bg-gray-100 rounded"
                >
                    ▼
                </button>

                {showDropdown && (
                    <div className='absolute top-full right-0 mt-1 bg-white border border-[#022780] rounded-lg shadow-lg z-50'>
                        {getActions().map((option) => (
                            <div
                                key={option.label}
                                onClick={() => {
                                    setAction(option.label);
                                    option.onClick();
                                    setShowDropdown(false);
                                }}
                                className='px-4 py-2 hover:bg-gray-100 cursor-pointer text-[#022780]'
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>)
        : null

}

export default DigitalActionBar;

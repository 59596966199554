import React, { useState } from "react";
import Swal from "sweetalert2";
import CreateRescheduleFormReq from "../../../../Requests/Customer/CreateRescheduleFormReq";
import CustomerApi from "../../../../API/CustomerApi";
import UpdateCustomerInstallDateReq from "../../../../Requests/Customer/UpdateCustomerInstallDateReq";

const ScheduleForm = ({ lead, fiberCompany }) => {
  const [rescheduleFrom, setRescheduleFrom] = useState("");
  const [installTimeFrom, setInstallTimeFrom] = useState("");
  const [installTimeTo, setInstallTimeTo] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [installPlan, setInstalltPlan] = useState(0);

  const daysOut = fiberCompany?.daysOut ?? 4;

  const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const updateInstallDate = async () => {
    if (!lead?.fidiumCustomer) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Customer not found",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Updating Install Date",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: "custom-swal",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    // the day should be the same in the install date and install date to and the time in the install date should be less than the time in the install date to
    if (!rescheduleFrom) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Date",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!installTimeFrom || !installTimeTo) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Time",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    const req = new UpdateCustomerInstallDateReq();
    req.customerId = lead?.fidiumCustomer.id;
    const [year, month, day] = rescheduleFrom.split("-").map(Number);

    // Create a new date object with the desired year, month, and day
    const installDate = new Date(year, month - 1, day);
    let from = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeFrom.split(":")[0],
          installTimeFrom.split(":")[1]
        )
      )
    );
    let to = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeTo.split(":")[0],
          installTimeTo.split(":")[1]
        )
      )
    );
    req.installDateTime = from;
    req.installDateTimeTo = to;
    req.promo = promoCode;
    const res = await CustomerApi.EditCustomerInstallDate(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Install Date Updated Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  return (
    <div className="bg-white p-8 max-w-xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">
        Schedule Installation
      </h1>

      <div className="space-y-6 pb-6">
        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Install Date
          </label>
          <div className="relative">
            <input
              type="date"
              value={rescheduleFrom}
              onChange={(e) => {
                setRescheduleFrom(e.target.value);
              }}
              className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
              placeholder="mm/dd/yyyy"
              min={
                new Date(Date.now() + 86400000 * daysOut)
                  .toISOString()
                  .split("T")[0]
              }
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Install Time Slot
          </label>
          <div className="flex items-center space-x-4">
            <div className="relative flex-1">
              <input
                type="time"
                value={installTimeFrom}
                onChange={(e) => {
                  setInstallTimeFrom(e.target.value);
                  setSelectedTimeRange("");
                }}
                className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
              />
            </div>

            <span className="text-gray-500 font-medium">TO</span>

            <div className="relative flex-1">
              <input
                type="time"
                value={installTimeTo}
                onChange={(e) => {
                  setInstallTimeTo(e.target.value);
                  setSelectedTimeRange("");
                }}
                className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
              />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <p className="text-center text-lg font-medium text-gray-500 my-4">
            OR
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => {
                setSelectedTimeRange("AM");
                setInstallTimeFrom("08:00");
                setInstallTimeTo("12:00");
              }}
              className={`px-8 py-3 rounded-full text-base font-medium transition-colors duration-200 ${
                selectedTimeRange === "AM"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              AM
            </button>
            <button
              onClick={() => {
                setSelectedTimeRange("PM");
                setInstallTimeFrom("12:00");
                setInstallTimeTo("16:00");
              }}
              className={`px-8 py-3 rounded-full text-base font-medium transition-colors duration-200 ${
                selectedTimeRange === "PM"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              PM
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={updateInstallDate}
        className="w-full bg-blue-500 text-white py-3 px-6 rounded-xl font-medium text-lg hover:bg-blue-600 transition-colors duration-200"
      >
        Submit
      </button>
    </div>
  );
};

export default ScheduleForm;

import React, { useEffect, useContext } from "react";
import { useRecording } from "./RecordingContext";
import IconButton from "@mui/material/IconButton";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { RecordingContext } from "./RecordingContext";

const FloatingRecordButton = () => {
  const { isRecording, isPaused, handleStartRecording, handleStopRecording } = useContext(RecordingContext);
  const [audioRecording, setAudioRecording] = React.useState(false);

  useEffect(() => {
    setAudioRecording(isRecording);
  }, [isRecording]);

  return (
    <>
      {
        audioRecording ?
          <IconButton
            onClick={handleStopRecording}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              width: "60px",
              height: "60px",
              backgroundColor: audioRecording ? "red" : "blue",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              color: "white",
            }}
          >
            <StopIcon />
          </IconButton>
          : null
      }
    </>
  );
};

export default FloatingRecordButton;

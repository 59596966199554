import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';
import SearchFilters from './SearchFilters';
import CreateCandidateLead from './CreateCandidateLead';
import CandidateFilters from './CandidateSearchFilters';
import CompanyCoverageAreaFilters from './CompanyCoverageAreaFilters';
import FccDataSearchFilters from './FCCData/FCCSearchFilters';
import { UserContext } from '../../../../App';
import { useSwipeable } from 'react-swipeable';

const TabbedSearchFilters = ({ location, ...props }) => {
  // Initialize with the unique value for the default tab
 
  const [activeTab, setActiveTab] = useState("leadFilters");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCandidateTab, setShowCandidateTab] = useState(false);
  const [fccTab, setFccTab] = useState(false);
  const user = useContext(UserContext);
  const stopRef = React.useRef(null);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const refPassthrough = (el) => {
    handlers.ref(el);
    stopRef.current = el;
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwiping: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedDown: (eventData) => {
      eventData.event.stopPropagation();
    },
  });

  useEffect(() => {
    console.log("Recruit Mode:", props.recruitMode);
    setShowCandidateTab(props.recruitMode);
    setFccTab(props.fccMode);
  }, [props.recruitMode, props.fccMode]);

  return (
    <Box sx={{ width: '100%' }} ref={refPassthrough}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant={isMobile ? "fullWidth" : "standard"}
        centered={!isMobile}
        sx={{ mb: 2 }}
      >
        <Tab label="Lead Filters" value="leadFilters" />
        {/* {user.userType === "SuperAdmin" && (
          <Tab label="Company Coverage" value="companyCoverage" />
        )} */}
        {showCandidateTab && (
          <Tab label="Candidate Filters" value="candidateFilters" />
        )}
        {fccTab && <Tab label="FCC Filters" value="fccFilters" />}
      </Tabs>

      <Box sx={{ p: 2 }}>
        {activeTab === "leadFilters" && (
          <SearchFilters {...props} />
        )}
        {/* {activeTab === "companyCoverage" && (
          <CompanyCoverageAreaFilters />
        )} */}
        {activeTab === "candidateFilters" && (
          <CandidateFilters {...props} />
        )}
        {activeTab === "fccFilters" && (
          <FccDataSearchFilters {...props} />
        )}
      </Box>
    </Box>
  );
};

export default TabbedSearchFilters;

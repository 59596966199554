import React, { useState, useEffect, useContext } from "react";
import Creatable from "react-select/creatable";
import { Pagination, PaginationItem, CircularProgress } from "@mui/material";
import { BiRightArrow, BiLeftArrow, BiSortDown } from "react-icons/bi";
import QuickDate from "../../../components/QuickDate";
import { SearchFiltersContext, UserContext } from "../../../App";
import OrgApi from "../../../API/OrgApi";
import "./index.scss";
import Layout from "../../../Layouts/Layout";
import UserSelector from "../../../components/UserSelector";
import { Navigate, useNavigate } from "react-router-dom";
import OrganizationSelector from "../../../components/OrganizationSelector";
import ReportReq from "../../../Requests/Users/SalesReportReq";
import FiberCompanySelector from "../../../components/FiberCompanySelector";

const SalesReport = () => {
  const user = useContext(UserContext);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [salesReps, setSalesReps] = useState([]);
  const [rankedBy, setRankedBy] = useState("Sales");
  const [filterBy, setFilterBy] = useState("Me");
  const [quickDateValue, setQuickDateValue] = useState("Today");
  const [orgIds, setOrgIds] = useState([]);
  const [orgWithName, setOrgWithName] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedData, setSortedData] = useState(salesData);
  const [fiberCompanies, setFiberCompanies] = useState([]);
  const [fiberCompanyWithName, setFiberCompanyWithName] = useState(null);
  const searchFilter = useContext(SearchFiltersContext);
  const navigate = useNavigate();

  const getSalesReportData = async () => {
    setLoading(true);
    let req = new ReportReq();

    if (user.userType !== "SuperAdmin") {
      setOrgIds((prevOrgIds) => [...prevOrgIds, user.salesOrgId]);
    }
    req.orgIds = orgIds;
    req.userId = user.id;
    req.filterBy = filterBy;
    if (user.userType === "SuperAdmin") {
      req.filterBy = "All";
    }

    req.fromDate = fromDate || null;
    req.toDate = toDate || null;
    req.salesRepIds = salesReps;
    req.fiberCompanyIds = fiberCompanies;

    try {
      const res = await OrgApi.getReportData(req);
      if (res?.status?.success) {
        setSalesData(res.data);
        setSortedData(res.data);
        setTotalPages(res.data.totalPages);
      }
    } catch (err) {
      console.error("Error fetching sales report data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSalesReportData();
  }, [filterBy]);

  const statusMap = {
    doorsKnocked: "Knocked",
    doorsSold: "Sold",
    doorsScheduled: "PendingInstalls",
    doorsInstalled: "Completed",
    cancelRequests: "PendingCancels",
    doorsCanceled: "Canceled",
  };

  const handleSort = (column) => {
    if (column !== "salesRepName") {
      setSortedColumn(column);
      const sorted = [...sortedData].sort((a, b) => b[column] - a[column]);
      setSortedData(sorted);
    }
  };

  return (
    <Layout>
      <div className="sales-report-container">
        <div className="filter-container">
          <div className="btn_con">
            <button
              style={{
                color: "white",
                fontSize: "0.8rem",
                padding: "0.3rem",
                fontWeight: "normal",
              }}
              onClick={() => {
                navigate("/leaderboard");
              }}
            >
              Go To LeaderBoard
            </button>

            {user.userType === "SalesOrgAdmin" && (
              <div className="toggle-switchh">
                <label>{"Viewing " + filterBy}</label>
                <input
                  type="checkbox"
                  checked={filterBy === "Team"}
                  onChange={() =>
                    setFilterBy(filterBy === "Me" ? "Team" : "Me")
                  }
                />
              </div>
            )}
          </div>
          <QuickDate
            selectQuickDate="All Time"
            setFrom={setFromDate}
            setTo={setToDate}
            setQuickDateValue={setQuickDateValue}
          />

          {user.userType === "SuperAdmin" && (
            <div className="selector_con">
              <label>Sales Orgs</label>
              <OrganizationSelector
                orgWithName={orgWithName}
                setOrgWithName={setOrgWithName}
                multiple={true}
                setUser={setOrgIds}
                placeholder="Select Organizations"
              />
            </div>
          )}

          {user.userType === "SuperAdmin" && (
            <div className="selector_con">
              <label>Fiber Company</label>
              <FiberCompanySelector
                setCompanyObject={setFiberCompanyWithName}
                companiesWithOfferTemplateOnly={true}
                placeholder="Select Fiber Company"
                setUser={setFiberCompanies}
                multiple={true}
              />
            </div>
          )}

          {(user.userType === "SalesOrgAdmin" ||
            user.userType === "SuperAdmin") && (
            <div className="selector_con">
              <label>Sales Reps</label>
              <UserSelector
                salesOrgIds={orgIds}
                SalesOrgId={
                  user.userType === "SalesOrgAdmin" ? user.salesOrgId : null
                }
                setUser={setSalesReps}
                conversationType={"Group"}
                placeholder="Select Sales Reps"
                userTypes={["SalesRep", "SalesOrgAdmin"]}
              />
            </div>
          )}

          <button onClick={() => getSalesReportData()}>Fetch Report</button>
        </div>

        <div className="table-container">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th onClick={() => handleSort("doorsKnocked")}>
                    Doors Knocked <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("doorsSold")}>
                    Doors Sold <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("doorsScheduled")}>
                    Doors Scheduled <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("doorsInstalled")}>
                    Doors Installed <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("cancelRequests")}>
                    Cancel Requests <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("doorsCanceled")}>
                    Doors Canceled <BiSortDown />
                  </th>
                  <th onClick={() => handleSort("acceptedOffers")}>
                    Accepted Offers
                    <BiSortDown />
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  <>
                    {user.userType === "SalesOrgAdmin" ||
                    user.userType === "SuperAdmin" ||
                    user.userType === "SalesRep"
                      ? sortedData.map((data, index) => (
                          <tr key={index}>
                            <td>{data.salesRepName}</td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      passedOrgIds: orgWithName,
                                      knocked: true,
                                      leadType: "KnockedDoors",
                                      dateType: "KnockedDate",
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.doorsKnocked} ({data.leadsKnocked})
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      type: statusMap.doorsSold,
                                      passedOrgIds: orgWithName,
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.doorsSold}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      type: statusMap.doorsScheduled,
                                      passedOrgIds: orgWithName,
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.doorsScheduled}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      type: statusMap.doorsInstalled,
                                      passedOrgIds: orgWithName,
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.doorsInstalled}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      type: statusMap.cancelRequests,
                                      passedOrgIds: orgWithName,
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.cancelRequests}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  const updatedSalesReps = salesReps.some(
                                    (rep) => rep.id === data.salesRepId
                                  )
                                    ? salesReps
                                    : [
                                        ...salesReps,
                                        {
                                          id: data.salesRepId,
                                          label: data.salesRepName,
                                        },
                                      ];
                                  searchFilter.current = {
                                    ...searchFilter.current,
                                    contactCenter: {
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      salesReps: updatedSalesReps,
                                      quickDateValue: quickDateValue,
                                      type: statusMap.doorsCanceled,
                                      passedOrgIds: orgWithName,
                                    },
                                  };
                                  navigate("/contact-center");
                                }}
                                className="tableBtn"
                              >
                                {data.doorsCanceled}
                              </button>
                            </td>
                            <td>
                              <button className="tableBtn">
                                {data.offersAccepted}
                              </button>
                            </td>
                          </tr>
                        ))
                      : user.userType === "SalesRep"
                      ? salesData.map((data, index) => (
                          <tr key={index}>
                            <td>{data.salesRepName}</td>
                            <td>{data.doorsKnocked}</td>
                            <td>{data.doorsSold}</td>
                            <td>{data.doorsScheduled}</td>
                            <td>{data.doorsInstalled}</td>
                            <td>{data.cancelRequests}</td>
                            <td>{data.doorsCanceled}</td>
                            <td>{data.offersAccepted}</td>
                          </tr>
                        ))
                      : null}
                  </>
                }
              </tbody>
            </table>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Pagination
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={(e, value) => setCurrentPage(value)}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: (props) => (
                    <button {...props}>
                      <BiRightArrow />
                    </button>
                  ),
                  previous: (props) => (
                    <button {...props}>
                      <BiLeftArrow />
                    </button>
                  ),
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default SalesReport;

import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { IoCloseCircle } from "react-icons/io5";
import Swal from "sweetalert2";
import CustomerApi from "../../API/CustomerApi";
import PhoneInput from "react-phone-input-2";
import AccountStatusChangeForm from "../../Views/Shared/MasterSheet/AccountStatusChangeForm";
import { IoMdClose } from "react-icons/io";
import { User } from "lucide-react";
import { AiOutlineEdit } from "react-icons/ai";
import { VscSaveAs } from "react-icons/vsc";
import { RxCross1 } from "react-icons/rx";
const Index = ({
  lead,
  customer,
  notes,
  open,
  setOpen,
  editable,
  setReload,
}) => {
  console.log(customer);

  let formData = lead?.formData ? JSON.parse(lead?.formData) : null;
  const [editMode, setEditMode] = React.useState(false);

  const [name, setName] = React.useState(
    customer?.firstName + " " + customer?.lastName
  );
  const [address, setAddress] = React.useState(customer?.address);
  const [email, setEmail] = React.useState(customer?.email);
  const [phoneNumber, setPhoneNumber] = useState(customer?.cellPhone);
  const [customerNotes, setCustomerNotes] = React.useState(
    customer?.accountNotes
  );
  const [accountStatusChangeModal, setAccountStatusChangeModal] =
    React.useState(false);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [customAttributesLeads, setCustomAttributesLeads] = useState([]);
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [editingField, setEditingField] = useState(null);
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (customer) {
      setEditMode(false);
      setName(customer?.firstName + " " + customer?.lastName);
      setAddress(customer?.address);
      setEmail(customer?.email);
      setCustomerNotes(customer?.accountNotes);
      setPhoneNumber(customer?.cellPhone);
    }
  }, [customer]);

  function onCancel() {
    setEditMode(false);
    setName(customer?.firstName + " " + customer?.lastName);
    setAddress(customer?.address);
    setEmail(customer?.email);
    setCustomerNotes(customer?.accountNotes);
  }

  const getCustomAttributes = async () => {
    const req = {
      for: "Customer",
      name: "",
      dataType: "",
    };

    const res = await CustomerApi.SearchCustomAttributes(req);

    if (res.status.success) {
      setCustomAttributes(res.data);
    }
  };

  const getLeadAttributes = async () => {
    const req = {
      for: "Lead",
      name: "",
      dataType: "",
    };

    const res = await CustomerApi.SearchCustomAttributes(req);

    if (res.status.success) {
      setCustomAttributesLeads(res.data);
    }
  };

  useEffect(() => {
    getLeadAttributes();
    getCustomAttributes();
  }, []);

  async function onSave() {
    Swal.fire({
      title: "Please wait",
      text: "Saving Customer Info",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom_swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!phoneNumber) {
      Swal.fire({
        title: "Error",
        text: "Phone Number is required",
        icon: "error",
        customClass: {
          container: "custom_swal",
        },
      });
      return;
    }

    if (phoneNumber.length < 11) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone is invalid",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });

      return;
    }

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (!email) {
      Swal.fire({
        title: "Error",
        text: "Email is required",
        icon: "error",
        customClass: {
          container: "custom_swal",
        },
      });
    }

    if (!pattern.test(email)) {
      Swal.fire({
        title: "Error",
        text: "Invalid Email Address",
        icon: "error",
        customClass: {
          container: "custom_swal",
        },
      });
      return;
    }

    let data = {
      customerId: customer?.id,
      firstName: name.split(" ")[0],
      lastName: name.split(" ")[1],
      address: address,
      accountNotes: customerNotes,
      phoneNumber: phoneNumber,
      email: email,
    };

    const res = await CustomerApi.EditCustomerInfo(data);
    if (res.status.success) {
      Swal.fire({
        title: "Success",
        text: "Customer Info Saved Successfully",
        icon: "success",
        customClass: {
          container: "custom_swal",
        },
      });
      setEditMode(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Failed to save customer info",
        icon: "error",
        customClass: {
          container: "custom_swal",
        },
      });
    }
  }
  const renderValue = (value) => {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    return value;
  };

  const handleEditCustomAttribute = async (customAttributeId) => {
    const req = {
      customerId: customer.id,
      customAttributeId: customAttributeId,
      value: fieldValue,
    };

    const res = await CustomerApi.AssignCustomAttributeValueToCustomer(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Custom attribute updated successfully.",
      });

      // Optionally refresh custom attributes or update state
      // setChange(!change);
      getCustomAttributes();
      setEditingField(null);
      setReload((prev) => !prev);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to update custom attribute.",
      });
    }
  };

  return (
    <>
      <AccountStatusChangeForm
        customer={{
          ...customer,
          customerId: customer?.id,
          name: customer?.firstName + " " + customer?.lastName,
        }}
        open={accountStatusChangeModal}
        setOpen={(open) => {
          setAccountStatusChangeModal(open);
          setOpen(!open);
        }}
      />
      <Modal
        sx={{ outline: "none" }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="customer_info_model">
          {/* <IoCloseCircle onClick={() => {
                        setOpen(false)
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' /> */}
          <IoMdClose
            className="absolute top-3 right-3 w-6 h-6 hover:cursor-pointer hover:text-red-500"
            onClick={() => {
              setOpen(false);
            }}
          />
          <h2>Customer Info</h2>
          {customer ? (
            <div className="customer_info">
              <div className="single flex justify-end items-center">
                {editable ? (
                  editMode ? (
                    <>
                      <button
                        className="mr-4 bg-[#de3e3e] px-4 py-2 text-sm rounded-lg text-white"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          onSave();
                        }}
                        className="bg-[#2761d0] px-4 py-2 text-sm rounded-lg text-white"
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setEditMode(true);
                      }}
                      className="bg-[#2761d0] px-4 py-2 text-sm rounded-lg text-white"
                    >
                      Edit
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
              <h3 className="single">Personal Information</h3>
              <div className="info">
                <h4>Name</h4>
                {editMode ? (
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2"
                  />
                ) : (
                  <p>{name}</p>
                )}
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                }}
                className="info single"
              >
                <h4>Address</h4>
                {editMode ? (
                  <textarea
                    className=" border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  ></textarea>
                ) : (
                  <p>{address}</p>
                )}
              </div>
              <div className="info">
                <h4>Email</h4>
                {editMode ? (
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2"
                  />
                ) : (
                  <p>{email}</p>
                )}
              </div>
              <div className="info">
                <h4>Phone</h4>
                {editMode ? (
                  <PhoneInput
                    containerStyle={{
                      gridColumn: "span 2",
                    }}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e);
                    }}
                  />
                ) : (
                  <p>{phoneNumber}</p>
                )}
              </div>

              <h3 className="single">Installation Information</h3>
              <div className="info">
                <h4>Sales Rep</h4>
                <p>
                  {customer?.salesRep?.firstName +
                    " " +
                    customer?.salesRep?.lastName}
                </p>
              </div>
              <div className="info">
                <h4>Sold At</h4>
                <p>
                  {new Date(customer?.timeStamp).toDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
              <div className="info">
                <h4>Account Status</h4>
                <p
                  onClick={() => {
                    if (User.userType !== "SuperAdmin") {
                      return;
                    }

                    setOpen(false);
                    setAccountStatusChangeModal(true);
                  }}
                  style={{ color: "#2761D0" }}
                  className="font-bold underline cursor-pointer"
                >
                  {customer?.accountStatus}
                </p>
              </div>
              <div className="info">
                <h4>Install Date</h4>
                <p>
                  {customer?.installDateTime
                    ? new Date(customer?.installDateTime).toDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : "Not Scheduled"}
                </p>
              </div>
              <div className="info">
                <h4>Original Install Date</h4>
                <p>
                  {customer?.originallInstallDateTimeFrom
                    ? new Date(
                        customer?.originallInstallDateTimeFrom
                      ).toDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "Not Scheduled"}
                </p>
              </div>
              <div className="info">
                <h4>Updated Install Date</h4>
                <p>
                  {customer?.updatedInstallDateTimeFrom
                    ? new Date(
                        customer?.updatedInstallDateTimeFrom
                      ).toDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "N/A"}
                </p>
              </div>
              <div className="info">
                <h4>Cancel Reason</h4>
                {customer?.issueDescription ? (
                  <p>{customer?.issueDescription}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <div className="info">
                <h4>Internet Plan</h4>
                <p>{customer?.fiberPlan?.description}</p>
              </div>
              <div className="info">
                <h4>Rep Notes</h4>
                <p>{notes ? notes : "No Notes"}</p>
              </div>
              <div className="info">
                <h4>Customer Notes</h4>
                {editMode ? (
                  <textarea
                    className=" border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2"
                    value={customerNotes}
                    onChange={(e) => {
                      setCustomerNotes(e.target.value);
                    }}
                  ></textarea>
                ) : (
                  <p>{customerNotes ? customerNotes : "No Notes"}</p>
                )}
              </div>
              {/* {customer?.customAttributeValues?.length > 0 && (
                <>
                  <h3 className="single">Custom Fields</h3>
                  {customer?.customAttributeValues?.map((field, index) => {
                    return (
                      <div className="info" key={index}>
                        <h4>{field?.customAttribute?.name}</h4>
                        {editMode ? (
                          <textarea
                            className=" border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2"
                            value={field?.value}
                            onChange={(e) => {
                              //   setCustomerNotes(e.target.value);
                              // Implement Editing of Custom Fields
                            }}
                          ></textarea>
                        ) : (
                          <p>{field?.value ? field?.value : "No Value"}</p>
                        )}
                      </div>
                    );
                  })}
                </>
              )} */}

              <h3 className="single">Custom Fields For Customer</h3>
              {customAttributes?.map((field, index) => {
                const matchingValue =
                  customer.customAttributeValues?.find(
                    (val) => val.customAttributeId === field.id
                  )?.value ?? "No Value";

                const isEditing = editingField === field.id;

                return (
                  <div className="info" key={index}>
                    <h4>{field?.name}</h4>
                    {isEditing ? (
                      <div className="flex items-center">
                        <input
                          value={fieldValue}
                          onChange={(e) => setFieldValue(e.target.value)}
                          placeholder="Enter value"
                          type={field?.dataType}
                          className="border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2"
                        />
                        <VscSaveAs
                          size={30}
                          onClick={() => handleEditCustomAttribute(field.id)}
                          className="ml-2 text-blue-700"
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                        <RxCross1
                          size={25}
                          onClick={() => setEditingField(null)}
                          className="ml-2 text-red-500"
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </div>
                    ) : (
                      <p className="flex justify-start items-start">
                        {matchingValue}
                        <AiOutlineEdit
                          size={20}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            setEditingField(field.id);
                            setFieldValue(matchingValue);
                          }}
                        />
                      </p>
                    )}
                  </div>
                );
              })}

              <Link
                to={`/CustomerDetails/${customer?.id}`}
                className="view_more"
              >
                View More
              </Link>
            </div>
          ) : (
            <div
              className="customer_info"
              style={{
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <h3 className="single">Personal Information</h3>

              <div className="info">
                <h4>Name</h4>
                <p>{lead?.name}</p>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                }}
                className="info single"
              >
                <h4>Address</h4>
                <p>{lead?.fiberHouse?.mapString}</p>
              </div>
              <div className="info">
                <h4>Email</h4>
                <p>{lead?.email}</p>
              </div>
              <div className="info">
                <h4>Phone</h4>
                <p>{lead?.phone}</p>
              </div>
              {lead?.offer && (
                <div className="info">
                  <h4>Selected Plan</h4>
                  <p>{lead?.offer?.fiberPlan?.description}</p>
                </div>
              )}
              {lead?.offer?.fiberCompanyId === 9 && formData && (
                <>
                  <div className="info">
                    <h4>Secondary account holder</h4>
                    <p>{formData?.secondaryHolder}</p>
                  </div>
                  <div className="info">
                    <h4>City, State, Zip</h4>
                    <p>{formData?.cityStateZip}</p>
                  </div>
                  <div className="info">
                    <h4>Internet Speed</h4>
                    <p>{formData?.internetSpeed}</p>
                  </div>
                  <div className="info">
                    <h4>
                      Is military, a senior, a first responder, or an educator?
                    </h4>
                    <p>{formData?.eligibility}</p>
                  </div>
                  <div className="info">
                    <h4>Preferred Installation Day?</h4>
                    <p>{formData?.installDay}</p>
                  </div>
                  <div className="info">
                    <h4>Preferred Installation Time?</h4>
                    <p>{formData?.timePreference}</p>
                  </div>
                  <div className="info">
                    <h4>High school attended/graduated</h4>
                    <p>{formData?.highSchool}</p>
                  </div>
                  <div className="info">
                    <h4>Mother's Maiden Name</h4>
                    <p>{formData?.mothersMaidenName}</p>
                  </div>
                  <div className="info">
                    <h4>Last 4 digits of SSN </h4>
                    <p>{formData?.ssnLast4}</p>
                  </div>
                </>
              )}

              <h3>Custom Fields For Lead</h3>
              {customAttributesLeads?.map((field, index) => {
                const matchingValue =
                  lead?.customAttributeValues?.find(
                    (val) => val?.customAttributeId === field?.id
                  )?.value ?? "No Value";

                const isEditing = editingField === field.id;

                return (
                  <div className="info" key={index}>
                    <h4>{field?.name}</h4>
                    {isEditing ? (
                      <div className="flex items-center">
                        <input
                          value={fieldValue}
                          onChange={(e) => setFieldValue(e.target.value)}
                          placeholder="Enter value"
                          type={field?.dataType}
                          className="border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2"
                        />
                        <VscSaveAs
                          size={30}
                          onClick={() => handleEditCustomAttribute(field.id)}
                          className="ml-2 text-blue-700"
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                        <RxCross1
                          size={25}
                          onClick={() => setEditingField(null)}
                          className="ml-2 text-red-500"
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </div>
                    ) : (
                      <p className="flex justify-start items-start">
                        {matchingValue}
                        <AiOutlineEdit
                          size={20}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            setEditingField(field.id);
                            setFieldValue(matchingValue);
                          }}
                        />
                      </p>
                    )}
                  </div>
                );
              })}

              {(lead?.offer?.fiberCompanyId === 2 ||
                lead?.offer?.fiberCompanyId === 10) &&
                formData &&
                Object.keys(formData)?.map((key, index) => {
                  return (
                    <div className="info" key={index}>
                      <h4>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </h4>
                      <p>{renderValue(formData[key])}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Index;

import { useEffect, useState } from "react";
import BrandApi from "../../../../API/BrandApi";
import Swal from "sweetalert2";
import Layout from "../../../../Layouts/Layout";

export default function BrandDetailPage() {
    const [brand, setBrand] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // Track edit mode
    const [editBrand, setEditBrand] = useState(null); // Track edited data


    const getBrand = async () => {
        let req = { string: "LightningOs" };
        const res = await BrandApi.GetBrands(req);

        if (res?.status?.success) {
            setBrand(res.data[0]);
            setEditBrand(res.data[0]); // Initialize editable data
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.status?.message,
            });
        }
    };


    const handleSave = async () => {
        let req = {
            Name: editBrand.name,
            LargeLogo: editBrand.largeLogo,
            SmallLogo: editBrand.smallLogo,
            PrimaryColor: editBrand.primaryColor,
            SecondaryColor: editBrand.secondaryColor,
        }
        const res = await BrandApi.UpdateBrand(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: res?.status?.message,
            });
            getBrand();
            setIsEditing(false);
            window.location.reload();
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.status?.message,
            });
        }

        
    };
    const handleLogoChange = (field, e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditBrand((prev) => ({
                    ...prev,
                    [field]: file, // Keep the file for uploading
                    [`preview${field.charAt(0).toUpperCase() + field.slice(1)}`]: reader.result, // Set preview URL
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (field, value) => {
        setEditBrand((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    useEffect(() => {
        getBrand();
    }, []);

    return (

        <Layout>
            <div className="mx-auto py-10 px-4">
                <div className="mb-6 flex items-center justify-end">
                    {/* <a href="/brands" className="flex items-center text-blue-600 hover:text-blue-800">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        Back to Brands
                    </a> */}
                    {!isEditing ? (
                        <button
                            onClick={() => setIsEditing(true)} // Enable edit mode
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                            </svg>
                            Edit Brand
                        </button>
                    ) : (
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => setIsEditing(false)} // Disable edit mode
                                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 flex items-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                                Cancel
                            </button>

                            <button
                                onClick={handleSave} // Save changes
                                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center"
                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </div>
                <div className="bg-white shadow rounded-lg p-6">
                    <h1 className="text-3xl font-bold mb-6">
                       
                        {brand?.name}
                        
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div>
                            <h2 className="text-xl font-semibold mb-4">Logos</h2>
                            <div className="flex flex-col space-y-4">
                                <div>
                                    <p className="text-sm text-gray-500 mb-2">Main Logo</p>
                                    {isEditing ? (
                                        <div className="flex-col items-start space-y-4">
                                            {/* Preview the current or newly uploaded logo */}
                                            <img
                                                src={
                                                    editBrand?.previewLargeLogo || // Show the new logo preview if available
                                                    "data:image/png;base64," + brand?.largeLogo || // Otherwise, show the existing logo
                                                    "/placeholder.svg"
                                                }
                                                alt={`${brand?.name} logo`}
                                                className="w-48 h-48 object-contain border rounded-lg"
                                            />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleLogoChange("largeLogo", e)}
                                                className="border rounded-md p-2 w-48"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={"data:image/png;base64," + brand?.largeLogo || "/placeholder.svg"}
                                            alt={`${brand?.name} logo`}
                                            className="w-48 h-48 object-contain border rounded-lg"
                                        />
                                    )}
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500 mb-2">Small Logo</p>
                                    {isEditing ? (
                                        <div className="flex-col items-start space-y-4">
                                            {/* Preview the current or newly uploaded small logo */}
                                            <img
                                                src={
                                                    editBrand?.previewSmallLogo || // Show the new logo preview if available
                                                    "data:image/png;base64," + brand?.smallLogo || // Otherwise, show the existing logo
                                                    "/placeholder.svg"
                                                }
                                                alt={`${brand?.name} small logo`}
                                                className="w-24 h-24 object-contain border rounded-lg"
                                            />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleLogoChange("smallLogo", e)}
                                                className="border rounded-md p-2 w-48"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={"data:image/png;base64," + brand?.smallLogo || "/placeholder.svg"}
                                            alt={`${brand?.name} small logo`}
                                            className="w-24 h-24 object-contain border rounded-lg"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-xl font-semibold mb-4">Brand Colors</h2>
                            <div className="space-y-4">
                                <div>
                                    <p className="text-sm text-gray-500 mb-2">Primary Color</p>
                                    {isEditing ? (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                            <input
                                                type="color"
                                                value={editBrand?.primaryColor || "#FFFFFF"} // Default color
                                                onChange={(e) => handleChange("primaryColor", e.target.value)}
                                                className="w-16 h-16 rounded-lg border mr-4 outline-none"
                                            />
                                            <input
                                                type="text"
                                                value={editBrand?.primaryColor || ""}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChange("primaryColor", value);
                                                }}
                                                className="border rounded-md p-2 w-32"
                                                placeholder="#FFFFFF"
                                            />
                                            {/* <div
                                                className="w-16 h-16 rounded-lg border"
                                                style={{
                                                    backgroundColor: editBrand?.primaryColor || "#FFFFFF",
                                                }}
                                            /> */}
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <div
                                                className="w-16 h-16 rounded-lg border mr-4"
                                                style={{ backgroundColor: brand?.primaryColor }}
                                            />
                                            <span className="text-lg">{brand?.primaryColor}</span>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500 mb-2">Secondary Color</p>
                                    {isEditing ? (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                            <input
                                                type="color"
                                                value={editBrand?.secondaryColor || "#FFFFFF"} // Default color
                                                onChange={(e) => handleChange("secondaryColor", e.target.value)}
                                                className="w-16 h-16 rounded-lg border mr-4"
                                            />
                                            <input
                                                type="text"
                                                value={editBrand?.secondaryColor || ""}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChange("secondaryColor", value);
                                                }}
                                                className="border rounded-md p-2 w-32"
                                                placeholder="#FFFFFF"
                                            />
                                            {/* <div
                                                className="w-16 h-16 rounded-lg border"
                                                style={{
                                                    backgroundColor: editBrand?.secondaryColor || "#FFFFFF",
                                                }}
                                            /> */}
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <div
                                                className="w-16 h-16 rounded-lg border mr-4"
                                                style={{ backgroundColor: brand?.secondaryColor }}
                                            />
                                            <span className="text-lg">{brand?.secondaryColor}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

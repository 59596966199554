import { useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Modal } from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";

const Plan = ({
  setSelectedPlan,
  setOpen,
  offerTemplateFiberPlan,
  offerTemplate,
  fiberCompany,
}) => {
  const [speedOpen, setSpeedOpen] = useState(false);

  function onClick() {
    setSelectedPlan(plan);
    setOpen(true);
  }

  function hexToRgba(hex, alpha = 1) {
    let r = 0,
      g = 0,
      b = 0;

    // 3 digits
    if (hex.length === 4) {
      r = Number.parseInt(hex[1] + hex[1], 16);
      g = Number.parseInt(hex[2] + hex[2], 16);
      b = Number.parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = Number.parseInt(hex[1] + hex[2], 16);
      g = Number.parseInt(hex[3] + hex[4], 16);
      b = Number.parseInt(hex[5] + hex[6], 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const color = useMemo(
    () => offerTemplateFiberPlan.color ?? "#01A7E1",
    [offerTemplateFiberPlan]
  );

  const plan = useMemo(
    () => offerTemplateFiberPlan.fiberPlan,
    [offerTemplateFiberPlan]
  );

  return (
    <>
      <div className="bg-white text-[#6F6C90] p-8 rounded-3xl -mt-20 relative z-10 mx-auto">
        <h1
          className="text-2xl font-bold text-center text-white absolute -top-5 left-1/2 transform -translate-x-1/2 py-2 px-4 rounded-full"
          style={{ backgroundColor: color }}
        >
          {offerTemplateFiberPlan.promotionalName}
        </h1>

        <p className="text-center text-[#170F49] mt-4">
          <span className="text-4xl font-bold">${plan.price}</span> /mo*
        </p>

        <div
          className="rounded-3xl px-2 py-2 mt-4"
          style={{ backgroundColor: hexToRgba(color, 0.5) }}
        >
          <div className="text-center">
            <p className="text-[#170F49]">Speeds up to</p>
            <p className="text-4xl font-bold text-[#170F49]">
              {plan?.speed * 1000} Mbps
            </p>
            <span className="flex items-center justify-center">
              {plan.speed} GIG
            </span>
          </div>
          <p
            className="text-center text-[#170F49] text-sm cursor-pointer"
            onClick={() => {
              setSpeedOpen(true);
            }}
          >
            {/* {offer.fiberType === "Fiber" ? "Upload/" : ""}Download Speeds */}
          </p>
        </div>

        <ul className="mt-4 space-y-2">
          {offerTemplate.bulletPoints.split("\n").map((bulletPoint, index) => (
            <li key={index} className="flex items-center space-x-2">
              <FaCheckCircle style={{ color: color }} />
              <p>{bulletPoint}</p>
            </li>
          ))}
        </ul>
        <p className="text-base font-semibold mt-4 text-center">
          {plan?.speed === 1 ? (
            <>
              Get a $150 credit towards your <br />
              first two months of service.
            </>
          ) : (
            <>
              Get a $100 credit towards your <br />
              first two months of service.
            </>
          )}
        </p>
        <div className="flex w-full mt-4">
          <button
            onClick={onClick}
            style={{ backgroundColor: color }}
            className="w-full py-2 px-8 rounded-full text-white text-xl"
          >
            Accept Offer
          </button>
        </div>
      </div>
      <Modal
        open={speedOpen}
        onClose={() => {
          setSpeedOpen(false);
        }}
      >
        <div className="bg-white p-8 rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="w-full">
            <IoMdCloseCircle
              className="ml-auto cursor-pointer"
              onClick={() => {
                setSpeedOpen(false);
              }}
              size={30}
              color={color}
            />
          </div>
          <div className="text-center">
            <h1 className="text-3xl font-bold">{plan?.speed} GIG</h1>
            <div className="mt-4">
              <p className="text-lg">
                Download Speeds -{" "}
                <span className="font-bold">{plan?.speed * 1000} Mbps</span>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Plan;

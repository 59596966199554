import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import { UserContext } from "../../../../App";
import StringIntReq from "../../../../Requests/StringIntReq";
import UsersApi from "../../../../API/UsersApi";
import {
  ChevronRight,
  Share2,
  Phone,
  Mail,
  MapPin,
  Eye,
  Video,
  Image,
  ArrowUp,
  ArrowDown,
  Trash2,
  CheckIcon,
  EditIcon,
  CloseIcon,
  X,
  User,
  ChevronDown,
  UploadIcon,
  LinkIcon,
} from "lucide-react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import LightCustomerModal from "./../../../Reps/DigitalBusinessCenter/components/LightCustomerModal";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LuFileVideo } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { Pagination, EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { uploadFileToFirebase } from "../../../../Services/DisplayFile";
import { deleteObject, getStorage, ref } from "firebase/storage";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchCustomerFeedbackReq from "../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq";
import CustomerFeedbackApi from "../../../../API/CustomerFeedbackApi";
import { CircularProgress } from "@mui/material";
import { FaRegSave, FaStar } from "react-icons/fa";
import greyUser from "../../../../assets/images/userGrey.png";
import lightning from "../assets/images/Vector.png";
import mapicon from "../assets/images/mapicon.png";
import call from "../assets/images/call.png";
import message from "../assets/images/sms.png";
import messageText from "../assets/images/message-text.png";
import whatsapp from "../assets/images/whatsapp.png";
import ContactButtons from "../../../../Services/ContactButtons";
import frame1 from "../assets/images/Frame 1000006133.png";
import frame2 from "../assets/images/Frame 2.png";
import frame3 from "../assets/images/frame3.png";
import frame4 from "../assets/images/frame4.png";
import frame5 from "../assets/images/frame5.png";
import frame6 from "../assets/images/frame6.png";
import frame7 from "../assets/images/frame7.png";
import primary from "../assets/images/primary.png";
import primaryLogo from "../../../../Layouts/icons/logo.png";
import FiberPlanApi from "../../../../API/FiberPlanApi";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdUpload } from "react-icons/md";
import CardDetailsModal from "./CardDetailsModal";
import { filter } from "jszip";

const BusinessCard = ({
  salesRep,
  searchArea,
  salesRepId,
  displayOffer = true,
  fiberCompany,
  filterFiberCompanyId,
  setFilterFiberCompanyId,
}) => {
  const [businessCards, setBusinessCards] = useState([]);
  const [selectedBusinessCard, setSelectedBusinessCard] = useState(null);
  const user = useContext(UserContext);

  const [totalItems, setTotalItems] = useState(0);
  const [myReviews, setMyReviews] = useState([]);
  const [hasReviews, setHasReviews] = useState(true);
  const storage = getStorage();
  const [avgRating, setAvgRating] = useState(5);
  const [reviewCount, setReviewCount] = useState(0);
  const [promo, setPromo] = useState(null);
  const [plans, setPlans] = useState([]);
  const [fiberCompanyId, setFiberCompanyId] = useState(
    fiberCompany
      ? fiberCompany
      : salesRep.fiberCompanyId
      ? salesRep.fiberCompanyId
      : 2
  );
  const [reviews, setReviews] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showMyReviews, setShowMyReviews] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  const fetchReviews = async (isMyReviews = false) => {
    setLoading(true);
    const req = new SearchCustomerFeedbackReq();

    if (showMyReviews) {
      req.salesRepId = salesRepId;
      if (user.id) {
        req.salesRepId = user.id;
      }
    }

    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1000;

    try {
      const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
      if (res?.status?.success) {
        console.log("fetch");
        setTotalItems(res.data.totalItems);

        if (isMyReviews) {
          setReviews(res.data.list);
          setHasReviews(res.data.list.length > 0);
        } else {
          setReviews(res.data.list);
        }
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setHasReviews(false);
    }
    setLoading(false);
  };

  const setBackgroundImage = async (name) => {
    const req = new StringIntReq();
    req.int = selectedBusinessCard.id;
    req.string = name;
    const res = await UsersApi.SetBackgroundImageForBusinessCard(req);
    if (res?.status?.success) {
      fetchBusinessCards();
    }
  };
  const setProfileImageAndVideo = async (businessCard) => {
    const req = new StringIntReq();
    req.int = businessCard.id;
    const res = await UsersApi.AddUserVideoForBizCard(req);
    if (res?.status?.success) {
      setSelectedBusinessCard(businessCard);
    }
  };
  const SortVideos = async (videoIds, sortOrder) => {
    const req = {
      videoIds: videoIds,
      sortOrder: sortOrder,
    };

    const res = await UsersApi.SortBusinessCardVideos(req);
    if (res?.status?.success) {
      // setSelectedBusinessCard(res.data);
      fetchBusinessCards();
    }
  };

  const [primaryProfile, setPrimaryProfile] = useState(null);
  const makePrimaryCard = async (businessCard) => {
    const req = new StringIntReq();
    req.int = businessCard.id;
    const res = await UsersApi.MakePrimaryBusinessCard(req);

    if (res?.status?.success) {
      // Update local state to reflect the new primary card
      setBusinessCards((prevCards) =>
        prevCards.map((card) =>
          card.id === businessCard.id
            ? { ...card, isPrimary: true }
            : { ...card, isPrimary: false }
        )
      );

      setSelectedBusinessCard(businessCard);
      setPrimaryProfile(businessCard);
    }
  };

  const [pressTimer, setPressTimer] = useState(null); // To track long press

  const handleProfileClick = (index, businessCard) => {
    if (businessCard.id === selectedBusinessCard.id) {
      makePrimaryCard(businessCard);
    }
    // For single click, switch the selected profile immediately
    setSelectedIndex(index);
    setSelectedBusinessCard(businessCard);
  };

  useEffect(() => {
    if (selectedBusinessCard) {
      if (
        !selectedBusinessCard.backgroundImage ||
        selectedBusinessCard.backgroundImage === "Primary"
      ) {
        setBackground("Primary");
        setIsPrimary(true);
      } else {
        setBackground(selectedBusinessCard.backgroundImage);
        setIsPrimary(false);
      }
    }
  }, [selectedBusinessCard]);

  const handleProfileLongPress = (index, businessCard) => {
    // Set profile as primary after long press
    setPrimaryProfile(businessCard);
    setSelectedIndex(index);
    setSelectedBusinessCard(businessCard);
  };

  const handleTouchStart = (index, businessCard) => {
    // Start the press timer for mobile touch devices
    const timer = setTimeout(() => {
      handleProfileLongPress(index, businessCard);
    }, 1000); // 1 second long press duration
    setPressTimer(timer);
  };

  const handleTouchEnd = () => {
    // Clear the timer if touch ends before 1 second
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
    }
  };

  const handleMouseDown = (index, businessCard) => {
    // Start the press timer for desktop mouse events (though it's mainly for mobile)
    const timer = setTimeout(() => {
      handleProfileLongPress(index, businessCard);
    }, 1000); // 1 second long press duration
    setPressTimer(timer);
  };

  const handleMouseUp = () => {
    // Clear the timer if mouse is released before 1 second
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
    }
  };

  const DraggableItem = ({ item, index, moveItem, itemCount }) => {
    return (
      <li className="flex items-center gap-2 p-2 bg-white rounded shadow-sm">
        <span className="flex-1">{item.name}</span>

        <div className="flex items-center gap-1">
          <button
            onClick={() => moveItem(index, index - 1)}
            disabled={index === 0}
            className={`p-1 ${index === 0 ? "opacity-50" : "opacity-100"}`}
          >
            <ArrowUp size={16} />
          </button>
          <button
            onClick={() => moveItem(index, index + 1)}
            disabled={index === itemCount - 1}
            className={`p-1 ${
              index === itemCount - 1 ? "opacity-50" : "opacity-100"
            }`}
          >
            <ArrowDown size={16} />
          </button>
        </div>

        {/* <RotateCcw size={16} className="cursor-pointer" /> */}
        <Trash2
          onClick={() => {
            deleteVideo(item.id);
          }}
          size={16}
          className="cursor-pointer text-red-500"
        />
      </li>
    );
  };
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const [background, setBackground] = useState("Primary");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isPrimary, setIsPrimary] = useState(true);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const dropdownRef = useRef(null);
  const [videos, setVideos] = useState([
    "Intro Video",
    "Product Demo",
    "Customer Testimonial",
    "weweq",
  ]);
  const [photos, setPhotos] = useState([
    "Photo 1",
    "Photo 2",
    "Photo 3",
    "Photo 4",
  ]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [bulletPoints, setBulletPoints] = useState(null);

  const backgrounds = [
    { name: "Primary", image: primary },
    { name: "Background 1", image: frame1 },
    { name: "Background 2", image: frame2 },
    { name: "Background 3", image: frame3 },
    { name: "Background 4", image: frame4 },
    { name: "Background 5", image: frame5 },
    { name: "Background 6", image: frame6 },
    { name: "Background 7", image: frame7 },
  ];

  const handleBackgroundChange = (selectedImage) => {
    setBackground(selectedImage);
    setIsPrimary(selectedImage === "Primary");
    setBackgroundImage(selectedImage);
    setShowDropdown(false);
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setActiveSubmenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const textColor = isPrimary ? "text-black" : "text-white";
  const ItemTypes = {
    VIDEO: "video",
    PHOTO: "photo",
  };

  const moveItem = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= videos.length) return; // Prevent moving out of bounds

    const updatedList = [...selectedBusinessCard.videos];
    const [movedItem] = updatedList.splice(fromIndex, 1);
    updatedList.splice(toIndex, 0, movedItem);
    SortVideos(
      updatedList.map((video) => video.id),
      Array.from({ length: updatedList.length }, (_, i) => i)
    );
  };

  const [selectedIndex, setSelectedIndex] = useState(
    businessCards?.findIndex((card) => card.primary) || 0
  );

  // Toggle between Company and My Reviews
  const handleReviewToggle = (isMyReviews) => {
    setShowMyReviews(isMyReviews);
    // if (isMyReviews) {
    //     fetchReviews(true); // Fetch my reviews
    // } else {
    //     fetchReviews(); // Fetch company reviews
    // }
  };
  const contactButtons = new ContactButtons(salesRep);

  const buttonClicked = async (text) => {
    const phoneNumber = selectedBusinessCard?.phoneNumber?.trim()
      ? selectedBusinessCard.phoneNumber
      : salesRep?.phoneNumber;
    const email = selectedBusinessCard?.email?.trim()
      ? selectedBusinessCard.email
      : salesRep?.email;

    if (text === "email") {
      contactButtons.email(email);
    } else if (text === "message") {
      contactButtons.sms(phoneNumber);
    } else if (text === "Wa") {
      contactButtons.whatsapp(phoneNumber);
    } else if (text === "Call") {
      contactButtons.call(phoneNumber);
    }
  };
  // Initial load for company reviews
  useEffect(() => {
    fetchReviews();
  }, [salesRep, salesRepId, showMyReviews]); // Added salesRep? and salesRepId as dependencies

  // const currentReviews = showMyReviews ? myReviews : reviews;
  const getReviews = async () => {
    setLoading(true);
    const req = new SearchCustomerFeedbackReq();
    if (searchArea) req.area = searchArea;
    if (showMyReviews) req.salesRepId = salesRepId;
    req.pagingParams.pageNumber = pageNumber;
    req.pagingParams.pageSize = 10;
    req.feedbackType = "Written";

    const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
    if (res?.status?.success) {
      console.log("get");

      setReviews((prev) =>
        pageNumber === 1 ? res.data.list : [...prev, ...res.data.list]
      );
      setHasMore(res.data.totalPages > pageNumber);
    }
    setLoading(false);
  };
  useEffect(() => {
    setReviews([]);
    setHasMore(true);
    setPageNumber(1);
    getReviews();
  }, [searchArea, salesRepId]); // Added searchArea and salesRepId as dependencies

  useEffect(() => {
    if (pageNumber > 1) getReviews();
  }, [pageNumber, searchArea, salesRepId]); // Added searchArea and salesRepId as dependencies

  const fetchBusinessCards = async () => {
    const req = new StringIntReq();
    req.int = salesRep?.id;

    const res = await UsersApi.GetBusinessCardForSalesRep(req);
    if (res?.status?.success) {
      let cards = res.data || [];

      // Find all default cards (CompanyId should be null)
      let defaultCards = cards.filter((card) => card.companyId === null);

      // Ensure only one default card exists
      let defaultCard;
      if (defaultCards.length > 1) {
        defaultCard = defaultCards[0]; // Keep the first one
        cards = cards.filter((card) => card !== defaultCard); // Remove extras
      } else {
        defaultCard = defaultCards.length ? defaultCards[0] : null;
      }

      setBusinessCards(cards);

      // Logic to select the correct business card
      let selectedCard = null;

      if (fiberCompany) {
        selectedCard = cards.find((card) => card.companyId == fiberCompany);
      }

      if (!selectedCard && selectedBusinessCard !== null) {
        selectedCard = cards.find(
          (card) => card.id === selectedBusinessCard.id
        );
      }

      let primaryCard = cards.find((card) => card.isPrimary);

      if (!selectedCard && primaryCard) {
        selectedCard = primaryCard;
      }

      if (!selectedCard) {
        selectedCard = defaultCard || cards[0];
      }

      if (selectedCard) {
        setSelectedBusinessCard(selectedCard);
        setFiberCompanyId(selectedCard.companyId);
        setBackground(selectedCard.backgroundImage);
      }
      // Previous primary card logic restored
      else {
        if (primaryCard && !selectedCard) {
          if (!selectedBusinessCard) {
            setSelectedBusinessCard(primaryCard);
          }
          setFiberCompanyId(primaryCard.companyId);
          setBackground(primaryCard.backgroundImage);
          setPrimaryProfile(primaryCard);
          if (
            !primaryCard.backgroundImage &&
            primaryCard.backgroundImage === "Primary"
          ) {
            setIsPrimary(true);
          } else {
            setIsPrimary(false);
          }
        }
        setSelectedBusinessCard(cards[0]);
        setFiberCompanyId(cards[0].companyId);
        setBackground(cards[0].backgroundImage);
        if (
          !cards[0].backgroundImage &&
          cards[0].backgroundImage === "Primary"
        ) {
          setIsPrimary(true);
        } else {
          setIsPrimary(false);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedBusinessCard != null)
      setFiberCompanyId(selectedBusinessCard?.companyId);
  }, [selectedBusinessCard]);

  useEffect(() => {
    fetchBusinessCards();
  }, [salesRep]); // Added salesRep as a dependency

  const [isModalOpen, setIsModalOpen] = useState(false);

  const downloadQrCode = () => {
    const canvas = document.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    const fileName = `${salesRep?.firstName} ${salesRep?.lastName} QR Code.png`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const videoRef = useRef(null);
  const [userVideos, setUserVideos] = useState([]);
  const [isCardDetailsModalOpen, setIsCardDetailsModalOpen] = useState(false);

  // Function to fetch salesRep videos from the server
  const searchUserVideos = async () => {
    const req = new StringIntReq();
    req.int = salesRep?.id;
    const res = await UsersApi.SearchUserVideosForBizCard(req);
    if (res.status.success) {
      setUserVideos(res.data);
    } else {
      console.log(res.status.message);
    }
  };

  // Function to upload a video
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    // Check if the file is a video
    if (!fileType?.startsWith("video/")) {
      Swal.fire({
        title: "Invalid File Type",
        text: "Please upload a video file.",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }
    Swal.fire({
      title: "Uploading Video",
      icon: "info",
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const uniqueId = uuidv4();
    const cloudFile = await uploadFileToFirebase(
      e.target.files[0],
      `profileVideos/${salesRep?.id}/${uniqueId}`
    );
    cloudFile.videoUserId = selectedBusinessCard ? null : salesRep?.id;
    cloudFile.businessCardId = selectedBusinessCard
      ? selectedBusinessCard.id
      : null;
    const res = await UsersApi.AddUserVideoForBizCard(cloudFile);

    if (res?.status?.success) {
      Swal.fire({
        title: "Video Uploaded",
        icon: "success",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      fetchBusinessCards(); // Reload business cards
      searchUserVideos(); // Reload salesRep? videos
    } else {
      Swal.fire({
        title: "Error Uploading Video",
        icon: "error",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };
  function getPlanSpeed(plan) {
    if (plan.speed < 1) {
      return plan.speed * 1000 + " Mbps";
    } else {
      return plan.speed + " Gig";
    }
  }

  const copyUrlToClipboard = () => {
    // if (salesRepIsUser) {
    //     navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + salesRep?.id+(fiberCompanyId ? "/"+fiberCompanyId : ""))
    // } else {
    //     navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + salesRep.id+(fiberCompanyId ? "/"+fiberCompanyId : ""))
    // }
    // Swal.fire({
    //     title: "Link Copied",
    //     icon: "success",
    //     showConfirmButton: false,
    //     timer: 1500,
    //     toast: true,
    //     position: "bottom-right",
    //     timerProgressBar: true,
    // })

    let p = promo?.split("\n")?.join(" & ");

    let pl = plans
      ?.map((plan) => {
        return `${getPlanSpeed(plan)}`;
      })
      .join(" OR ");

    navigator.share({
      title: "Fiber Business Card",
      text:
        `Hey 👋 \n\nContact ${
          salesRep?.firstName + " " + salesRep?.lastName
        } to receive ${p} for either ${pl}. \n\n ` +
        window.location.origin +
        "/digital-business-center/" +
        salesRep?.id +
        (fiberCompanyId ? "/" + fiberCompanyId : ""),
    });
  };
  // Function to delete a video
  const deleteVideo = async (videoId, showModal = true) => {
    if (showModal)
      Swal.fire({
        icon: "info",
        title: "Deleting Video",
        text: "Please wait...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

    try {
      let videoToDelete = userVideos.find((video) => video.id === videoId);
      if (!videoToDelete) {
        videoToDelete = selectedBusinessCard?.videos.find(
          (video) => video.id === videoId
        );
      }
      if (!videoToDelete) {
        if (videoId === selectedBusinessCard?.profileImage?.id) {
          videoToDelete = selectedBusinessCard?.profileImage;
        }
      }
      if (!videoToDelete) throw new Error("Video Not Found");

      const isFirebaseStorage =
        videoToDelete.path && !videoToDelete.url.startsWith("http");

      if (isFirebaseStorage) {
        const storageRef = ref(storage, videoToDelete.path);
        await deleteObject(storageRef);
      }

      const req = new StringIntReq();
      req.int = videoId;
      const res = await UsersApi.DeleteUserBizCardVideo(req);

      if (res.status.success) {
        if (showModal)
          Swal.fire({
            icon: "success",
            title: "Video Deleted",
            text: "Video has been deleted successfully.",
            didOpen: () => {
              Swal.hideLoading();
            },
            showConfirmButton: true,
          });

        fetchBusinessCards(); // Refresh video list
        setUserVideos((prevVideos) =>
          prevVideos.filter((video) => video.id !== videoId)
        );
      } else {
        if (showModal)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.status.message,
            didOpen: () => {
              Swal.hideLoading();
            },
            showConfirmButton: true,
          });
      }
    } catch (error) {
      console.log(error);
      if (showModal)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
          showCloseButton: true,
        });
    }
  };

  const getAvgRating = async () => {
    const req = new SearchCustomerFeedbackReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1000;
    req.feedbackType = null;
    const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
    if (res?.status?.success) {
      let totalRating = 0;
      res.data.list.forEach((review) => {
        totalRating += review.rating;
      });
      setAvgRating(totalRating / res.data.totalItems);
      setReviewCount(res.data.totalItems);
    }
  };
  useEffect(() => {
    getAvgRating();
  }, []);

  useEffect(() => {
    if (user?.id === salesRep?.id) {
      setIsLoggedInUser(true);
    }
  }, [user, salesRep]);

  const contactPart = () => {
    return (
      <div className="space-y-6 ">
        {/* Contact Information */}
        <div className="bg-transparent rounded-xl space-y-2 pl-5 sm:pl-0">
          <div className="inline-flex items-center gap-2 w-full px-1 py-1 text-sm text-black-600">
            <MapPin className="w-4 h-4 text-black-400" />
            {selectedBusinessCard?.address?.trim()
              ? selectedBusinessCard.address
              : salesRep?.address || "Not Available"}
          </div>
          <div className="inline-flex items-center gap-2 w-full px-1 py-1 text-sm text-black-600">
            <Phone className="w-4 h-4 text-black-400" />
            {selectedBusinessCard?.phoneNumber?.trim()
              ? selectedBusinessCard.phoneNumber
              : salesRep?.phoneNumber || "Not Available"}
          </div>
          <div className="inline-flex items-center gap-2 w-full px-1 py-1 text-sm text-black-600">
            <Mail className="w-4 h-4 text-black-400" />
            {selectedBusinessCard?.email?.trim()
              ? selectedBusinessCard.email
              : salesRep?.email || "Not Available"}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-3 justify-center sm:justify-normal">
          <button
            onClick={() => {
              buttonClicked("message");
            }}
            className="w-11 h-11 sm:w-14 sm:h-14 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200 transition-colors shadow-lg"
          >
            <img
              src={messageText}
              alt="messagetext"
              className="text-black-600"
            />
          </button>
          <button
            onClick={() => {
              buttonClicked("email");
            }}
            className="w-11 h-11 sm:w-14 sm:h-14 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200 transition-colors shadow-lg"
          >
            <img src={message} alt="message" className="text-gray-600" />
          </button>
          <button
            onClick={() => {
              buttonClicked("Call");
            }}
            className="w-11 h-11 sm:w-14 sm:h-14 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200 transition-colors shadow-lg"
          >
            <img src={call} alt="call" className=" text-gray-600" />
          </button>
          <button
            onClick={() => {
              buttonClicked("Wa");
            }}
            className="w-11 h-11 sm:w-14 sm:h-14 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200 transition-colors shadow-lg"
          >
            <img src={whatsapp} alt="whatsapp" className="text-gray-600" />
          </button>
        </div>
      </div>
    );
  };
  const customStyles = `
    .custom-pagination {
      display: flex;
      justify-content: center;
      gap: 2px;
    }
    .custom-pagination .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: #E5E7EB;
      opacity: 1;
      margin: 0 4px;
      border-radius: 50%;
      transition: background-color 0.3s ease;
    }
    .custom-pagination .swiper-pagination-bullet-active {
      background: #0038FF;
    }
      .video-swiper {
  padding-bottom: 40px; 
}
  .video-swiper .swiper-pagination {
 bottom: 1px; /* Adjust to align with the right side */
  display: flex;
  justify-content: center;
  align-items: center;

}
 
  `;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openLightModal, setOpenLightModal] = useState(false);

  const handleOpenLightModal = () => {
    if (fiberCompanyId === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a Fiber Company",
        showCloseButton: true,
      });
      return;
    }
    setOpenLightModal(true);
  };

  const getPlans = async () => {
    const req = new StringIntReq();
    req.int = selectedBusinessCard?.companyId;
    const res = await FiberPlanApi.GetFiberPlanOffers(req);

    if (res.status.success) {
      res.data.fiberPlans =
        salesRep?.userType === "SuperAdmin" || salesRep?.id === 103
          ? res.data.fiberPlans.filter((plan) => plan.name.includes("FTTH"))
          : res.data.fiberPlans.filter((plan) => !plan.name.includes("FTTH"));
      const fiberPlans = res.data.fiberPlans;
      const bulletPoints = res.data.bulletPoints;
      const promo = res.data.promos;

      setPlans(fiberPlans);
      setSelectedPlan(fiberPlans[0]);
      setBulletPoints(bulletPoints);
      setPromo(promo);
    }
  };

  const handleCompanyChange = (companyId) => {
    setFiberCompanyId(companyId);
  };

  useEffect(() => {
    if (selectedBusinessCard?.companyId != null) {
      getPlans();
    }
  }, [selectedBusinessCard]);

  const [videoLink, setVideoLink] = useState("");

  const uploadProfileImage = async (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    if (!fileType?.startsWith("image/")) {
      Swal.fire({
        title: "Invalid File Type",
        text: "Please upload a image file.",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    }
    Swal.fire({
      title: "Uploading Image",
      icon: "info",
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const uniqueId = uuidv4();
    console.log(selectedBusinessCard);
    if (selectedBusinessCard.profileImage) {
      console.log(selectedBusinessCard.profileImage);
      await deleteVideo(selectedBusinessCard.profileImage.id, false);
    }
    const cloudFile = await uploadFileToFirebase(
      e.target.files[0],
      `profileImages/${user.id}/${uniqueId}`
    );
    cloudFile.businessCardProfileId = selectedBusinessCard.id;
    const res = await UsersApi.UpdateProfilePicture(cloudFile);

    if (res?.status?.success) {
      Swal.fire({
        title: "Image Uploaded",
        icon: "success",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      // Update the profile image in the state
      salesRep.profileImage = cloudFile;
      fetchBusinessCards(); // Reload business cards
    } else {
      Swal.fire({
        title: "Error Uploading Image",
        icon: "error",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  useEffect(() => {
    const img = document.getElementById("profile_image");
    if (img && selectedBusinessCard?.profileImage) {
      img.src = selectedBusinessCard.profileImage.url;
    } else if (img && salesRep?.profileImage) {
      img.src = salesRep.profileImage.url;
    } else if (img) {
      img.src = "/images/Avatar/profile.jpg";
    }
  }, [selectedBusinessCard]);

  const debounceRef = useRef(null);

  const updateBusinessCardDetails = async (updatedDetails) => {
    const req = {
      businessCardId: selectedBusinessCard.id,
      email: updatedDetails.email,
      phoneNumber: updatedDetails.phoneNumber,
      address: updatedDetails.address,
    };

    const res = await UsersApi.UpdateBusinessCardDetails(req);
    if (res?.status?.success) {
      fetchBusinessCards(); // Refresh cards
      Swal.fire({
        title: "Updated Successfully",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Error Updating",
        icon: "error",
        text: res.status.message,
      });
    }
  };

  const [showBackgroundDropdown, setShowBackgroundDropdown] = useState(false);

  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [showVideoLinkInput, setShowVideoLinkInput] = useState(false);
  const [showVideoLinkPopup, setShowVideoLinkPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleVideoLinkUpload = async () => {
    if (!videoLink.trim()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a video URL.",
      });
      return;
    }

    const req = {
      businessCardId: selectedBusinessCard.id,
      url: videoLink.trim(),
    };

    const res = await UsersApi.AddUserVideoForBizCard(req);

    if (res?.status?.success) {
      Swal.fire({
        title: "Video Uploaded",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      fetchBusinessCards();
    } else {
      Swal.fire({
        icon: "error",
        title: "Upload Failed",
        text: res?.status?.message,
      });
    }

    setShowVideoLinkPopup(false);
    setVideoLink("");
  };
  const handleDownloadVCF = () => {
    const structuredName = [
      salesRep.lastName || "",
      salesRep.firstName || "",
      "", // Additional names
      "", // Honorific prefixes
      "", // Honorific suffixes
    ].join(";");
    // Create vCard content
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:${structuredName}
FN:${salesRep.firstName} ${salesRep.lastName}
ORG:Lightning OS
TEL:${salesRep.phoneNumber}
EMAIL:${salesRep.email}
ADR:;;${getAddress()}
NOTE:${
      salesRep.userType === "SalesRep"
        ? "Fiber Specialist"
        : salesRep.id === 157
        ? "Owner | CEO"
        : "Area Manager"
    }
END:VCARD
    `.trim();

    // Create Blob and download
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${salesRep.firstName}.vcf`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const getAddress = () => {
    const { city, state, country } = salesRep;
    let addressParts = [];

    if (city) addressParts.push(city);
    if (state) addressParts.push(state);
    if (country) addressParts.push(country);

    return addressParts.join(", ");
  };
  return (
    <>
      <style>{customStyles}</style>

      {/* Profile Carousel */}
      {!salesRepId &&
        businessCards.length > 0 &&
        businessCards.some((card) => card.companyId !== null) && (
          <div>
            <h2 className="text-xl font-bold p-4">Choose Profile</h2>
            <div className="flex items-center gap-3 overflow-x-auto pb-4 scrollbar-hide p-4">
              {businessCards
                .sort((a, b) => (a.isPrimary ? -1 : b.isPrimary ? 1 : 0)) // Primary always first
                .map((businessCard, index) => (
                  <div
                    key={index}
                    className={`relative flex-shrink-0 px-6 py-4 rounded-xl border cursor-pointer transition-all ${
                      businessCard.isPrimary
                        ? "border-blue-500 shadow-lg shadow-blue-300 scale-105" // Highlight primary card
                        : "bg-white border-gray-200 hover:border-gray-400"
                    }`}
                    onClick={() => {
                      setSelectedBusinessCard(businessCard);
                      setFilterFiberCompanyId(businessCard.companyId);
                    }}
                  >
                    {/* Primary Badge */}
                    {businessCard.isPrimary && (
                      <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-blue-600 text-white text-xs font-bold px-2 py-1 rounded-full">
                        Primary
                      </div>
                    )}

                    {/* Company Logo */}
                    <div className="flex items-center justify-center w-32 h-12 bg-transparent">
                      <img
                        src={
                          businessCard.company?.logo
                            ? `data:image/png;base64,${businessCard.company.logo}`
                            : "/images/nav/nav-logo.png"
                        }
                        alt={businessCard?.company?.name}
                        className="max-w-full max-h-full object-contain"
                      />
                    </div>

                    {/* Smaller Set as Primary Button */}
                    {!businessCard.isPrimary && (
                      <button
                        className="mt-2 px-3 py-1 text-sm bg-blue-500 text-white rounded-full hover:bg-blue-600 transition"
                        onClick={() => makePrimaryCard(businessCard)}
                      >
                        Set Primary
                      </button>
                    )}

                    {/* Underline Indicator */}
                    {businessCard.id === selectedBusinessCard?.id && (
                      <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-600 rounded-t-lg"></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

      <LightCustomerModal
        lightOpenModal={openLightModal}
        setLightOpenModal={setOpenLightModal}
        salesRepId={salesRep?.id}
        fiberCompanyId={fiberCompanyId}
        fiberPLanId={selectedPlan?.id}
        salesOrgId={salesRep?.salesOrgId}
      />

      <div className="p-4 sm:p-0">
        <div className="max-w-6xl mx-auto space-y-6 border border-gray-200 rounded-lg shadow-xl">
          {/* Main Profile Card */}
          <div
            className={`rounded-xl p-6 transition-all duration-300 relative ${textColor}`}
            style={{
              backgroundImage: `url("${
                backgrounds.find((x) => x.name === background)?.image
              }")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* 📌 Transparent Download VCF Button */}
            <button
              className="absolute top-2 right-2 p-1 opacity-70 hover:opacity-100 transition-all"
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadVCF(selectedBusinessCard);
              }}
            >
              <FaRegSave size={22} color="currentColor" />
            </button>

            <div className="flex flex-col md:flex-row items-start justify-between gap-6">
              <div className="sm:hidden h-16 w-32 bg-transparent rounded flex items-center justify-center overflow-hidden">
                {selectedBusinessCard?.company?.logo ? (
                  <img
                    className="h-full w-full object-contain"
                    src={`data:image/png;base64,${selectedBusinessCard.company.logo}`}
                    alt="Company Logo"
                  />
                ) : (
                  <img
                    className="h-full w-full object-contain bg-transparent"
                    src={primaryLogo}
                    alt="Company Logo"
                  />
                )}
              </div>
              <div className="flex items-center p-3 gap-6">
                {/* Profile Image */}
                <div className="relative group sm:w-36 sm:h-36 w-24 h-24 rounded-full border-4 border-[#0038FF] flex items-center justify-center sm:-mt-40 mt-0">
                  <div className="sm:w-32 sm:h-32 w-20 h-20 rounded-full border-1 border-white overflow-hidden">
                    <img
                      src={
                        selectedBusinessCard?.profileImage
                          ? selectedBusinessCard?.profileImage.url
                          : salesRep?.profileImage
                          ? salesRep?.profileImage.url
                          : "/images/Avatar/profile.jpg"
                      }
                      className="w-full h-full object-cover"
                      alt="Profile"
                      id="profile_image"
                    />
                  </div>

                  {/* Edit Button */}
                  {isLoggedInUser && (
                    <>
                      <button
                        className={`sm:w-12 sm:h-12 w-8 h-8 absolute bottom-0 left-0 flex items-center justify-center cursor-pointer  overflow-hidden ${textColor}  rounded-full bg-blue-500 p-2`}
                        onClick={() => setIsEditModalOpen(true)}
                      >
                        <EditIcon className="pointer-events-auto" />
                      </button>

                      {isEditModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                          <div className="bg-white p-6 rounded-lg w-full max-w-md sm:w-[90%] shadow-xl relative">
                            {/* Close Button */}
                            <button
                              onClick={() => setIsEditModalOpen(false)}
                              className="absolute top-4 right-4 text-gray-600 hover:text-red-600 transition"
                            >
                              ✕
                            </button>

                            {/* Logo */}
                            <div className="flex justify-center">
                              <img
                                src="/images/nav/nav-logo.png"
                                alt="Lightning OS"
                                className="h-12"
                              />
                            </div>

                            {/* Title */}
                            <h3 className="text-xl font-semibold text-center text-black mt-2">
                              Customize Your Business Card
                            </h3>

                            {/* Content Section */}
                            <div className="mt-4 space-y-3">
                              {/* Background Selection */}
                              <div className="relative">
                                <button
                                  className="w-full flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200 transition text-black"
                                  onClick={() =>
                                    setShowBackgroundDropdown(
                                      !showBackgroundDropdown
                                    )
                                  }
                                >
                                  <div className="flex items-center gap-2">
                                    <Image />
                                    <span className="text-black">
                                      Background
                                    </span>
                                  </div>
                                  <ChevronDown
                                    size={20}
                                    className={`transition-transform text-black ${
                                      showBackgroundDropdown ? "rotate-180" : ""
                                    }`}
                                  />
                                </button>
                                {showBackgroundDropdown && (
                                  <div className="absolute z-50 w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg text-black">
                                    <ul className="max-h-40 overflow-y-auto">
                                      {backgrounds.map((bg, index) => (
                                        <li
                                          key={index}
                                          className="cursor-pointer flex items-center justify-between px-3 py-2 hover:bg-gray-200 transition"
                                          onClick={() => {
                                            handleBackgroundChange(bg.name);
                                            setShowBackgroundDropdown(false);
                                          }}
                                        >
                                          {bg.name}
                                          {background === bg.name && (
                                            <CheckIcon className="text-green-500" />
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>

                              {/* Profile Image Upload */}
                              <div>
                                <button
                                  className="w-full flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200 transition text-black"
                                  onClick={() =>
                                    setActiveSubmenu(
                                      activeSubmenu === "profileImage"
                                        ? null
                                        : "profileImage"
                                    )
                                  }
                                >
                                  <div className="flex items-center gap-2">
                                    <User />
                                    <span className="text-black">
                                      Profile Image
                                    </span>
                                  </div>
                                  <ChevronRight
                                    size={20}
                                    className={`transition-transform text-black ${
                                      activeSubmenu === "profileImage"
                                        ? "rotate-90"
                                        : ""
                                    }`}
                                  />
                                </button>
                                {activeSubmenu === "profileImage" && (
                                  <div className="pl-4 mt-2 border-l-2 border-gray-300 text-black">
                                    <label
                                      htmlFor="profile_image_upload"
                                      className="flex items-center justify-center bg-gray-200 p-3 rounded-md cursor-pointer hover:bg-gray-300 transition text-black"
                                    >
                                      <MdUpload
                                        size={24}
                                        className="text-gray-700"
                                      />
                                      <span className="ml-2 text-gray-700">
                                        Upload Profile Image
                                      </span>
                                    </label>
                                    <input
                                      type="file"
                                      id="profile_image_upload"
                                      className="hidden"
                                      onChange={uploadProfileImage}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* Videos Section */}
                              <button
                                className="w-full flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200 transition text-black"
                                onClick={() =>
                                  setActiveSubmenu(
                                    activeSubmenu === "videos" ? null : "videos"
                                  )
                                }
                              >
                                <div className="flex items-center gap-2">
                                  <Video className="w-5 h-5" />
                                  <span className="text-black">Videos</span>
                                </div>
                                <ChevronRight
                                  size={20}
                                  className={`transition-transform ${
                                    activeSubmenu === "videos"
                                      ? "rotate-90"
                                      : ""
                                  }`}
                                />
                              </button>

                              {activeSubmenu === "videos" && (
                                <div className="pl-8 space-y-4 text-sm border-l-2 border-gray-400 overflow-auto h-fit text-black">
                                  <ul>
                                    {selectedBusinessCard?.videos
                                      ?.sort(
                                        (a, b) => a.orderNumber - b.orderNumber
                                      )
                                      .map((video, index) => (
                                        <DraggableItem
                                          key={index}
                                          item={video}
                                          index={index}
                                          moveItem={moveItem}
                                          type={ItemTypes.VIDEO}
                                        />
                                      ))}
                                  </ul>
                                </div>
                              )}

                              <div className="mt-6">
                                <button
                                  className="w-full flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200 transition text-black"
                                  onClick={() =>
                                    setIsCardDetailsModalOpen(true)
                                  }
                                >
                                  <div className="flex items-center gap-2">
                                    <EditIcon className="text-black" />{" "}
                                    <span className="text-black">
                                      Change Card Details
                                    </span>
                                  </div>
                                  <ChevronRight size={20} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <CardDetailsModal
                        isOpen={isCardDetailsModalOpen}
                        onClose={() => setIsCardDetailsModalOpen(false)}
                        onSave={updateBusinessCardDetails}
                      />
                    </>
                  )}
                </div>

                {/* Profile Info */}
                <div className=" space-y-4">
                  <div>
                    <h2 className="text-xl font-semibold">
                      {salesRep?.firstName + " " + salesRep?.lastName}
                    </h2>
                    <p className={`${textColor}`}>
                      {salesRep.id === 157 ? "Owner | CEO" : salesRep?.userType}
                    </p>
                    <div className="flex items-center gap-2 mt-2">
                      <Rating
                        name="read-only"
                        size="small"
                        precision={0.5}
                        value={avgRating}
                        readOnly
                      />
                      <span className={`text-sm ${textColor}`}>
                        ({avgRating.toFixed(1)})
                      </span>
                    </div>
                  </div>
                  <div className="sm:block hidden">{contactPart()}</div>
                </div>
              </div>
              <div className="sm:hidden block">{contactPart()}</div>

              <div className="flex flex-col items-center w-full md:w-auto relative ">
                {/* Company Logo */}
                <div className="sm:flex h-16 w-32 bg-transparent rounded hidden items-center justify-center overflow-hidden">
                  {selectedBusinessCard?.company?.logo ? (
                    <img
                      className="h-full w-full object-contain"
                      src={`data:image/png;base64,${selectedBusinessCard.company.logo}`}
                      alt="Company Logo"
                    />
                  ) : (
                    <img
                      className="h-full w-full object-contain bg-transparent"
                      src={primaryLogo}
                      alt="Company Logo"
                    />
                  )}
                </div>
                <div className="flex flex-col items-center md:items-end gap-2 w-full md:w-auto relative -mb-4 mt-4">
                  {/* QR Code */}
                  <div className="flex flex-col items-center gap-6 mb-4 ">
                    {/* QR Code Container */}
                    <div className="relative bg-transparent shadow-sm border border-white border-4">
                      <QRCode
                        value={`${window.location.origin}/digital-business-center/${salesRep?.id}/${fiberCompanyId}`}
                        size={160}
                        className="w-40 h-40"
                      />
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center gap-6">
                      <button
                        onClick={setIsModalOpen}
                        className="flex items-center gap-1.5 text-black-600 text-sm hover:text-black-900"
                      >
                        <Eye className="w-4 h-4" />
                        View
                      </button>
                      <button
                        onClick={() => copyUrlToClipboard()}
                        className="flex items-center gap-1.5 text-black-600 text-sm hover:text-black-900"
                      >
                        <Share2 className="w-4 h-4" />
                        Share
                      </button>
                      {selectedBusinessCard?.companyId !== null &&
                        selectedBusinessCard?.companyId !== undefined && (
                          <button
                            onClick={() => {
                              window.open(
                                `${window.location.origin}/preview-offer/${salesRep?.id}/${selectedBusinessCard?.companyId}`
                              );
                            }}
                            className="flex items-center gap-1.5 text-black-600 text-sm hover:text-black-900"
                          >
                            Preview
                          </button>
                        )}
                    </div>
                  </div>

                  {/* Modal for Expanding QR */}
                  {isModalOpen && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white p-6 rounded-lg w-96 space-y-4">
                        <div className="flex justify-between items-center">
                          <h3 className="text-xl font-semibold">QR Code</h3>
                          <button
                            onClick={() => setIsModalOpen(false)}
                            className="text-black font-bold text-xl"
                          >
                            X
                          </button>
                        </div>
                        {/* Display QR Code inside modal */}
                        <QRCode
                          value={`${window.location.origin}/digital-business-center/${salesRep?.id}/${fiberCompanyId}`}
                          size={300}
                          className="mx-auto shadow-md"
                        />
                        {/* Download Button */}
                        <button
                          onClick={downloadQrCode}
                          className="w-full py-2 text-white bg-blue-600 rounded-full"
                        >
                          Download QR Code
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Section */}
          <div
            className={`grid grid-cols-1  gap-6 px-8 ${
              user.id === salesRep.id ||
              selectedBusinessCard?.videos?.length > 0
                ? "md:grid-cols-2 "
                : "justify-center items-center md:grid-cols-1"
            }`}
          >
            {/* Featured Videos */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Featured Videos</h3>
              <div>
                {/* Swiper for video carousel */}
                {(selectedBusinessCard?.videos?.length > 0 ||
                  user.id === salesRep.id) && (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{ clickable: true }}
                    navigation={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="video-swiper"
                    style={{ width: "100%" }}
                  >
                    {/* Display Profile Video if Available */}
                    {selectedBusinessCard?.videos
                      ?.sort((a, b) => a.orderNumber - b.orderNumber)
                      .map((video, index) => {
                        return (
                          <SwiperSlide
                            className="bg-black flex justify-center items-center"
                            key={index}
                          >
                            <video
                              onLoadedData={(e) => {
                                e.target.currentTime = 1;
                              }} // Play video for 1 second to show the first frame
                              style={{ backgroundColor: "black" }}
                              type="video/mp4"
                              controls
                              src={video.url}
                              alt="video"
                              className="max-h-[300px]"
                            />
                            {user.id === salesRep.id &&
                              !window.location.href.includes(
                                "preview-offer"
                              ) && (
                                <button
                                  style={{
                                    position: "absolute",
                                    top: "5%",
                                    left: "2%",
                                    zIndex: 1000,
                                    border: "3px solid #007aff", // Thicker light blue outline
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background for better visibility
                                    width: "50px", // Increased size
                                    height: "50px", // Increased size
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    transition:
                                      "background-color 0.3s, transform 0.2s", // Smooth transition
                                  }}
                                  className="delete_video_bizcard hover:bg-red-600 hover:scale-110 "
                                  onClick={() => {
                                    if (window.href.includes("preview-offer")) {
                                      deleteVideo(video.id);
                                    }
                                  }}
                                >
                                  <X
                                    style={{ color: "white", fontSize: "24px" }}
                                    className="sm:text-sm text-lg"
                                  />{" "}
                                  {/* Larger white cross icon */}
                                </button>
                              )}
                          </SwiperSlide>
                        );
                      })}

                    {/* {salesRep?.profileVideo && (
                      <SwiperSlide className="bg-black flex justify-center items-center">
                        <video
                          ref={videoRef}
                          style={{ backgroundColor: "black" }}
                          type="video/mp4"
                          controls
                          className="max-h-[300px]"
                          src={
                            salesRep?.profileVideo
                              ? salesRep?.profileVideo.url
                              : "https://placehold.co/1920x1080.mp4"
                          }
                          alt="video"
                        />
                      </SwiperSlide>
                    )} */}

                    {/* Display Uploaded salesRep? Videos */}
                    {!selectedBusinessCard &&
                      userVideos.map((video, index) => {
                        return (
                          <SwiperSlide
                            key={index}
                            className="bg-black flex justify-center items-center"
                          >
                            <video
                              onLoadedData={(e) => {
                                e.target.currentTime = 1;
                              }} // Play video for 1 second to show the first frame
                              style={{ backgroundColor: "black" }}
                              type="video/mp4"
                              controls
                              src={video.url}
                              alt="video"
                              className="max-h-[300px]"
                            />
                            {salesRep && (
                              <button
                                style={{
                                  position: "absolute",
                                  top: "5%",
                                  left: "2%",
                                  zIndex: 1000,
                                  border: "3px solid #007aff", // Thicker light blue outline
                                  borderRadius: "50%",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background for better visibility
                                  width: "50px", // Increased size
                                  height: "50px", // Increased size
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  transition:
                                    "background-color 0.3s, transform 0.2s", // Smooth transition
                                }}
                                className="delete_video_bizcard hover:bg-red-600 hover:scale-110 "
                                onClick={() => {
                                  deleteVideo(video.id);
                                }}
                              >
                                <X
                                  style={{ color: "white", fontSize: "24px" }}
                                  className="sm:text-sm text-lg"
                                />{" "}
                                {/* Larger white cross icon */}
                              </button>
                            )}
                          </SwiperSlide>
                        );
                      })}

                    {/* Upload New Video Button */}
                    {salesRep?.id && !salesRepId && (
                      <SwiperSlide>
                        {/* Video Upload Section */}
                        <Box
                          sx={{
                            maxWidth: 500,
                            mx: "auto",
                            p: 12,
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            mb={4}
                          >
                            {/* Upload from Device Button */}
                            <Button
                              component="label"
                              variant="outlined"
                              startIcon={<UploadIcon />}
                              sx={{
                                py: 1.5,
                                bgcolor: "grey.50",
                                color: "text.primary",
                                borderColor: "grey.400",
                                fontWeight: "bold",
                                justifyContent: {
                                  xs: "center",
                                  sm: "flex-start",
                                },
                                "& .MuiButton-startIcon": {
                                  mr: { xs: 0, sm: 1 },
                                },
                                "&:hover": {
                                  bgcolor: "grey.200",
                                  borderColor: "grey.600",
                                },
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  display: { xs: "none", sm: "inline" },
                                }}
                              >
                                Upload from Device
                              </Box>
                              <input
                                type="file"
                                id="biz_card_video"
                                hidden
                                onChange={uploadFile}
                                accept="video/*"
                              />
                            </Button>

                            {/* Upload via Link Button */}
                            <Button
                              variant="contained"
                              startIcon={<LinkIcon />}
                              onClick={() => setShowVideoLinkPopup(true)}
                              sx={{
                                py: 1.5,
                                fontWeight: "bold",
                                bgcolor: "#0038FF",
                                color: "#ffffff",
                                justifyContent: {
                                  xs: "center",
                                  sm: "flex-start",
                                },
                                "& .MuiButton-startIcon": {
                                  mr: { xs: 0, sm: 1 },
                                },
                                "&:hover": {
                                  bgcolor: "#0026CC",
                                },
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  display: { xs: "none", sm: "inline" },
                                }}
                              >
                                Upload via Link
                              </Box>
                            </Button>
                          </Stack>

                          {/* Video Link Dialog */}
                          <Dialog
                            open={showVideoLinkPopup}
                            onClose={() => setShowVideoLinkPopup(false)}
                            maxWidth="sm"
                            fullWidth
                          >
                            <div className="flex justify-center mt-4">
                              <img
                                src="/images/nav/nav-logo.png"
                                alt="Lightning OS"
                                className="h-12"
                              />
                            </div>
                            <DialogTitle
                              sx={{
                                bgcolor: "#ffffff",
                                color: "#000000",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Upload Video via Link
                            </DialogTitle>
                            <DialogContent sx={{ bgcolor: "#F9FAFB", p: 3 }}>
                              <TextField
                                autoFocus
                                margin="dense"
                                fullWidth
                                placeholder="Paste video link (MP4, WebM, OGG)"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                                variant="outlined"
                                sx={{
                                  bgcolor: "white",
                                  borderRadius: 1,
                                  "& input": {
                                    color: "#333",
                                  },
                                }}
                              />
                            </DialogContent>
                            <DialogActions
                              sx={{ px: 4, pb: 4, bgcolor: "#F9FAFB" }}
                            >
                              <Button
                                onClick={() => setShowVideoLinkPopup(false)}
                                variant="outlined"
                                sx={{
                                  borderColor: "grey.400",
                                  color: "#000000",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleVideoLinkUpload}
                                variant="contained"
                                sx={{
                                  bgcolor: "#0038FF",
                                  "&:hover": { bgcolor: "#0026CC" },
                                  color: "#ffffff",
                                }}
                              >
                                Upload
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </SwiperSlide>
                    )}
                  </Swiper>
                )}
              </div>
            </div>

            {/* Reviews Section */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900">
                Reviews ({reviews.length})
              </h3>
              <div className="bg-white rounded-lg p-6">
                {/* Reviews Header */}
                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                  <div className="flex gap-2">
                    <button
                      className={`px-6 py-2 text-sm font-medium rounded-lg transition-all ${
                        !showMyReviews
                          ? "text-white bg-[#0038FF]"
                          : "text-gray-600 border border-gray-200"
                      }`}
                      onClick={() => handleReviewToggle(false)}
                    >
                      COMPANY REVIEWS
                    </button>
                    <button
                      className={`px-6 py-2 text-sm font-medium rounded-lg transition-all ${
                        showMyReviews
                          ? "text-white bg-[#0038FF]"
                          : "text-gray-600 border border-gray-200"
                      }`}
                      onClick={() => handleReviewToggle(true)}
                    >
                      MY REVIEWS
                    </button>
                  </div>
                </div>

                {/* Reviews Swiper */}
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  pagination={{
                    clickable: true,
                    el: ".custom-pagination",
                    renderBullet: (index, className) => {
                      if (index > 2) return "";
                      return `<span class="${className}"></span>`;
                    },
                  }}
                  onSlideChange={(swiper) => {
                    const total = reviews.length;
                    const current = swiper.activeIndex;

                    // Get all bullets
                    const bullets = document.querySelectorAll(
                      ".custom-pagination .swiper-pagination-bullet"
                    );

                    // Reset all bullets
                    bullets.forEach((bullet) => {
                      bullet.classList.remove(
                        "swiper-pagination-bullet-active"
                      );
                    });

                    // Set active bullet based on position
                    if (current === 0) {
                      bullets[0]?.classList.add(
                        "swiper-pagination-bullet-active"
                      );
                    } else if (current === total - 1) {
                      bullets[2]?.classList.add(
                        "swiper-pagination-bullet-active"
                      );
                    } else {
                      bullets[1]?.classList.add(
                        "swiper-pagination-bullet-active"
                      );
                    }
                  }}
                  className="!pb-10"
                  loop={false}
                >
                  {loading && (
                    <SwiperSlide>
                      <div className="flex justify-center items-center h-40">
                        <CircularProgress
                          sx={{ color: "var(--primary-color)" }}
                          size={40}
                        />
                      </div>
                    </SwiperSlide>
                  )}

                  {/* Display reviews */}
                  {reviews.map((review, index) => (
                    <SwiperSlide key={index}>
                      <div className="space-y-4">
                        {/* Author Info */}
                        <div className="flex items-center gap-3">
                          <img
                            alt="Reviewer"
                            src={
                              review?.fidiumCustomer?.profileImage || greyUser
                            }
                            className="w-12 h-12 rounded-full object-cover border border-gray-200"
                          />
                          <div>
                            <p className="font-semibold text-gray-900">
                              {review?.fidiumCustomer
                                ? `${review.fidiumCustomer.firstName} ${review.fidiumCustomer.lastName}`
                                : review.name || "Anonymous"}
                            </p>
                            <p className="text-sm text-gray-500">
                              {review.fidiumCustomer?.address || "Unknown"}
                            </p>
                          </div>
                        </div>

                        {/* Star Rating */}
                        <div className="flex items-center gap-1">
                          {[...Array(5)].map((_, i) => (
                            <FaStar
                              key={i}
                              className={`w-5 h-5 ${
                                i < review.rating
                                  ? "text-yellow-400"
                                  : "text-gray-200"
                              }`}
                            />
                          ))}
                        </div>

                        {/* Review Content */}
                        {review.content?.trim() && (
                          <p className="text-gray-600 leading-relaxed max-h-[120px] overflow-y-auto">
                            {review.content}
                          </p>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}

                  {/* No Reviews */}
                  {!loading && reviews.length == 0 && (
                    <SwiperSlide>
                      <div className="flex justify-center items-center h-40 text-gray-500">
                        No Reviews
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
                <div className="custom-pagination" />
              </div>
            </div>
          </div>
        </div>
        {!user?.id && (
          <div
            className="offerAndQr"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2rem",
            }}
          >
            {displayOffer && (
              <div
                className="offer_con"
                style={{
                  padding: "1rem",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  width: "100%",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                <h1
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    textAlign: "left",
                  }}
                >
                  Limited Time Offer
                </h1>
                {plans.length > 0 ? (
                  <div
                    className="banner_top"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    {plans?.map((plan, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedPlan(plan)}
                        className={`banner_card ${
                          selectedPlan?.id === plan?.id && "active"
                        }`}
                        style={{
                          padding: "1rem",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          transition: "transform 0.2s",
                          transform:
                            selectedPlan?.id === plan?.id
                              ? "scale(1.05)"
                              : "scale(1)",
                          textAlign: "left",
                        }}
                      >
                        {selectedPlan?.id === plan?.id && (
                          <IoIosCheckmarkCircle
                            style={{
                              position: "absolute",
                              top: "-5%",
                              right: "-5%",
                            }}
                            size={40}
                            color="#2761D0"
                          />
                        )}
                        <img
                          style={{ height: "30px", marginBottom: "0.2rem" }}
                          src={
                            "data:image/png;base64," + plan.fiberCompany?.logo
                          }
                          alt="fiber company logo"
                        />
                        <h2
                          style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}
                        >
                          {getPlanSpeed(plan)}
                        </h2>
                        <h4>${plan.price}/mo*</h4>
                      </div>
                    ))}
                  </div>
                ) : (
                  "No Plans Available"
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    padding: "1rem",
                    borderRadius: "10px",
                    marginTop: "1rem",
                    textAlign: "left",
                  }}
                >
                  {bulletPoints?.split("\n").map((point, index) => (
                    <div key={index} className="flex gap-4">
                      <IoIosCheckmarkCircle color="#2761D0" size={20} />
                      <p>{point}</p>
                    </div>
                  ))}
                </div>
                {plans.length > 0 && (
                  <button
                    style={{
                      backgroundColor: "#2761D0",
                      color: "white",
                      padding: "0.5rem 1rem",
                      borderRadius: "10px",
                      marginTop: "1rem",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    disabled={plans.length === 0}
                    onClick={() => {
                      if (user?.id !== salesRep?.id) {
                        handleOpenLightModal();
                      }
                    }}
                  >
                    Accept Offer
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessCard;

import React, { useState, useEffect, useRef, useContext } from "react";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOsm from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
import MapComponent from "@terrestris/react-geo/dist/Map/MapComponent/MapComponent";
import SatelliteLayer from "../../../../TeamAdmin/MapPage/components/SatelliteLayer";
import RenderFiberHouses from "./RenderFiberHouses";
import MapIconApi from "../../../../../API/MapIconApi";
import StringIntReq from "../../../../../Requests/StringIntReq";
import FiberHouseApi from "../../../../../API/FiberHouseApi";
import SelectArea from "./SelectArea";
import Item from "antd/lib/list/Item";
import SearchLocation from "../../../../TeamAdmin/MapPage/components/SearchLocation";
import RenderCandidates from "./RenderCandidates";
import DigitalActionBar from "./../../../../../components/DigitalActionBar";
import { UserContext } from "../../../../../App";
const Index = ({
  leadType,
  mode,
  accountStatus,
  selectedContacts,
  setSelectedContacts,
  dateType,
  from,
  to,
  setSelectedFiberHouseIds,
  selectedFiberHouseIds,
  cleadType,
  cAccountStatus,
  cIndustry,
  cDateType,
  cFrom,
  cTo,
  selectedCandidateIds,
  setSelectedCandidateIds,
}) => {
  const [map, setMap] = useState(null);
  const [mapIcons, setMapIcons] = useState(null);
  const [leadsData, setLeadsData] = useState([]);
  const [searchArea, setSearchArea] = useState(null);
  const [searchAreaFeature, setSearchAreaFeature] = useState([]);
  const [fiberHouses, setFiberHouses] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const user = useContext(UserContext);
  const fetchMapIcons = async () => {
    const res = await MapIconApi.GetAllMapIcons();
    if (res?.status?.success) {
      setMapIcons(res.data);
    }
  };

  const getStatusText = (contact) => {
    if (mode === "Sales") {
      if (contact?.fidiumCustomer != null) {
        return contact?.fidiumCustomer?.accountStatus;
      } else if (contact?.offer != null && contact?.fidiumCustomer == null) {
        return "Hot Lead";
      } else {
        return "Lead";
      }
    } else {
      return contact.status;
    }
  };

  useEffect(() => {
    setMap(
      new OlMap({
        layers: [
          new OlLayerTile({
            name: "OSM",
            source: new OlSourceOsm(),
          }),
        ],
        view: new OlView({
          center: fromLonLat([-98.5795, 39.8283]),
          zoom: 4,
          zoomDelta: 0.5,
        }),
      })
    );
  }, []);

  const fetchLeadData = async () => {
    const req = {
      ids: selectedFiberHouseIds,
    };

    const res = await FiberHouseApi.SearchFiberHousesOptimized(req);

    if (res?.status?.success) {
      setLeadsData(res.data);
    }
  };

  const fetchCandidates = async () => {
    const req = {
      candidateIds: selectedCandidateIds,
    };

    const res = await FiberHouseApi.GetCandidatesByIds(req);

    if (res?.status?.success) {
      setCandidateData(res.data);
    }
  };

  useEffect(() => {
    if (leadsData?.length > 0) {
      setSelectedContacts(
        leadsData.map((lead) => {
          return {
            ...lead.lead,
            fiberHouse: {
              ...lead,
              customer: null,
              lead: null,
            },
            fidiumCustomer: {
              ...lead?.customer,
              fiberHouse: null,
            },
          };
        })
      );
    }
  }, [leadsData]);

  useEffect(() => {
    if (candidateData?.length > 0) {
      setSelectedContacts(candidateData);
    }
  }, [candidateData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchLeadData();
    }, 3000);
    return () => clearTimeout(timer);
  }, [selectedFiberHouseIds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCandidates();
    }, 3000);
    return () => clearTimeout(timer);
  }, [selectedCandidateIds]);

  const handleCheckboxChange = (contact) => {
    setSelectedContacts((prev) =>
      prev.some((c) => c.id === contact.lead.id)
        ? prev.filter((c) => c.id !== contact.lead.id)
        : [
            ...prev,
            {
              ...contact.lead,
              fiberHouse: {
                ...contact,
                customer: null,
                lead: null,
              },
              fidiumCustomer: {
                ...contact?.customer,
                fiberHouse: null,
              },
            },
          ]
    );
  };

  const handleCheckBoxChangeForCandidates = (contact) => {
    setSelectedContacts((prev) =>
      prev.some((c) => c.id === contact.id)
        ? prev.filter((c) => c.id !== contact.id)
        : [...prev, contact]
    );
  };

  const LeadTable = ({ leads }) => {
    return (
      <div className="border rounded-lg overflow-auto h-[30vh]">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-slate-200">
            <tr>
              <th className="w-12 px-6 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Account Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Install Date
              </th>
              <th className="px-6 py-3 text-left text-xs w-96 font-medium text-gray-500 uppercase tracking-wider">
                Last Updated
              </th>
              <th className="px-6 py-3 text-left text-xs w-96 font-medium text-gray-500 uppercase tracking-wider">
                Map Icon
              </th>
              <th className="px-6 py-3 text-left text-xs w-96 font-medium text-gray-500 uppercase tracking-wider">
                Preview Offer
              </th>
              <th className="px-2 py-3 text-left text-xs w-96 font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {leads.map((lead, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedContacts.some(
                      (c) => c.id === lead.lead.id
                    )}
                    onChange={() => handleCheckboxChange(lead)}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600"
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-3">
                    <div
                      className={`flex justify-center items-center w-10 h-10 rounded-full overflow-hidden `}
                    >
                      <h1>{lead?.name ? lead.name[0] : ""}</h1>
                    </div>
                    <span className="font-medium text-gray-900">
                      {lead?.name}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span
                    className={`inline-flex text-center px-2 py-1 text-xs font-semibold rounded-full`}
                  >
                    {getStatusText(lead)}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {lead?.fidiumCustomer?.installDateTime
                    ? new Date(
                        lead?.fidiumCustomer?.installDateTime
                      ).toLocaleDateString()
                    : ""}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {lead.lastUpdatedAt
                    ? new Date(lead.lastUpdatedAt).toLocaleDateString()
                    : ""}
                </td>
                <td className="px-6 py-4">
                  {lead?.fiberHouse?.mapIconId ? (
                    <span>
                      {
                        mapIcons.find(
                          (icon) => icon.id === lead?.fiberHouse?.mapIconId
                        ).name
                      }
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>
                {lead.fidiumCustomer === null ? (
                  <td className="px-6 py-4">
                    <button
                      onClick={() => {
                        window.open(
                          window.location.origin +
                            `/preview-offer/${user?.id}/${lead.fiberCompanyId}/${lead.id}`
                        );
                      }}
                      className="w-40 bg-blue-600 text-white px-2 py-2 rounded-lg"
                    >
                      Preview Offer
                    </button>
                  </td>
                ) : (
                  <td className="px-6 py-4">
                    <button className="w-4 text-black px-2 py-2 rounded-lg cursor-default">
                      Customer
                    </button>
                  </td>
                )}
                <td className="px-2 py-4 w-96">
                  <div className="w-full">
                    <DigitalActionBar
                      onReload={() => {
                        fetchLeadData();
                      }}
                      lead={{
                        ...lead.lead,
                        fiberHouse: {
                          ...lead,
                          customer: null,
                          lead: null,
                        },
                        fidiumCustomer: {
                          ...lead?.customer,
                          fiberHouse: null,
                        },
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const CandidatesTable = ({ candidates }) => {
    return (
      <div className="border rounded-lg overflow-auto h-[30vh]">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-slate-200">
            <tr>
              <th className="px-6 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Account Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Install Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Updated
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Map Icon
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {candidates?.map((candidate, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedContacts?.some(
                      (c) => c.id === candidate?.id
                    )}
                    onChange={() =>
                      handleCheckBoxChangeForCandidates(candidate)
                    }
                    className="h-4 w-4 rounded border-gray-300 text-blue-600"
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-3">
                    <div
                      className={`flex justify-center items-center w-10 h-10 rounded-full overflow-hidden `}
                    >
                      <h1>{candidate?.name ? candidate.name[0] : ""}</h1>
                    </div>
                    <span className="font-medium text-gray-900">
                      {candidate?.name}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span
                    className={`inline-flex text-center w-10 px-2 py-1 text-xs font-semibold rounded-full`}
                  >
                    {getStatusText(candidate)}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {candidate?.fidiumCustomer?.installDateTime
                    ? new Date(
                        candidate?.fidiumCustomer?.installDateTime
                      ).toLocaleDateString()
                    : ""}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {candidate?.lastUpdatedAt
                    ? new Date(candidate?.lastUpdatedAt).toLocaleDateString()
                    : ""}
                </td>
                <td className="px-6 py-4">
                  {candidate?.fiberHouse?.mapIconId ? (
                    <span>
                      {
                        mapIcons.find(
                          (icon) => icon.id === candidate?.fiberHouse?.mapIconId
                        ).name
                      }
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    fetchMapIcons();
  }, []);

  return (
    <div className="relative">
      <MapContext.Provider value={map}>
        <MapComponent
          map={map}
          style={{
            height: "70vh",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        />
        <SelectArea
          fiberHouses={fiberHouses}
          candidates={candidates}
          setSelectedCandidateIds={setSelectedCandidateIds}
          map={map}
          searchAreaFeature={searchAreaFeature}
          setSearchAreaFeature={setSearchAreaFeature}
          setSearchArea={setSearchArea}
          searchArea={searchArea}
          setSelectedFiberHouseIds={setSelectedFiberHouseIds}
        />
        <SearchLocation
          setSelectedFiberHouse={() => {}}
          mapObject={map}
          fiberHouses={fiberHouses}
          servicableAreas={[]}
        />
        {map && (
          <>
            <SatelliteLayer map={map} satelliteMode={true} />
            {mode === "Sales" ? (
              <RenderFiberHouses
                fiberHouses={fiberHouses}
                setFiberHouses={setFiberHouses}
                selectedFiberHouseIds={selectedFiberHouseIds}
                setSelectedFiberHouseIds={setSelectedFiberHouseIds}
                mapIcons={mapIcons}
                map={map}
                accountStatus={accountStatus}
                leadType={leadType}
                dateType={dateType}
                from={from}
                to={to}
              />
            ) : (
              <RenderCandidates
                mapIcons={mapIcons}
                selectedCandidateIds={selectedCandidateIds}
                setSelectedCandidateIds={setSelectedCandidateIds}
                leadType={cleadType}
                accountStatus={cAccountStatus}
                industry={cIndustry}
                dateType={cDateType}
                from={cFrom}
                to={cTo}
                map={map}
                candidates={candidates}
                setCandidates={setCandidates}
              />
            )}
          </>
        )}
      </MapContext.Provider>
      <div className="">
        {mode === "Sales" ? (
          <p>Selected Leads : {selectedFiberHouseIds?.length}</p>
        ) : (
          <p>Selected Candidates : {selectedCandidateIds?.length}</p>
        )}

        {mode === "Sales" ? (
          <LeadTable leads={leadsData} />
        ) : (
          <CandidatesTable candidates={candidateData} />
        )}
      </div>
    </div>
  );
};

export default Index;

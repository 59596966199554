import React, { useEffect, useState } from 'react'
import lightcurveLogo from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png";
import Swal from 'sweetalert2';
import GetOfferByGuidReq from '../../../Requests/Offer/GetOfferByGuidReq';
import { useParams } from 'react-router-dom';
import OfferApi from '../../../API/OfferApi';
import CustomerApi from '../../../API/CustomerApi';
import { Check } from '@mui/icons-material';
import FiberHouseApi from '../../../API/FiberHouseApi';
import StringIntReq from '../../../Requests/StringIntReq';

const initialFormData = {
    firstName: '',
    lastName: '',
    secondaryHolder: '',
    email: '',
    phone: '',
    address: '',
    apartment: '',
    desiredInstallDate: '',
    installDate: '',
    installTime: '',
    fourDigitPin: '',
    verificationPhrase: '',
    securityQuestion1: '',
    securityAnswer1: '',
    securityQuestion2: '',
    securityAnswer2: '',
    companyNotes: '',
    schedulingNotes: '',
    promoCode: '',
    goPaperless: false,
    rentARouter: false,
}

export default function Index() {

    const { offerGuid } = useParams()
    const [formData, setFormData] = useState(initialFormData)
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(true)
    const [internetSpeed, setInternetSpeed] = useState('1GB')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [timeSlot, setTimeSlot] = useState('9:00 AM - 11:00 AM')
    const [ownARouter, setOwnARouter] = useState(null)


    useEffect(() => {
        const getOffer = async () => {
            setLoading(true)
            // console.log(id)
            const req = new GetOfferByGuidReq();
            req.string = offerGuid;
            req.int = 1;
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 10;
            const res = await OfferApi.GetOfferByGuid(req);
            // console.log(res)
            if (res?.status?.success) {

                let fiberPlans = res.data.offerTemplate.offerTemplateFiberPlans

                if (res?.data?.fiberType === "Fiber") {
                    fiberPlans = fiberPlans.filter(plan => plan.fiberPlan.name.includes("FTTH"))
                }
                else {
                    fiberPlans = fiberPlans.filter(plan => !plan.fiberPlan.name.includes("FTTH"))
                }
                res.data.offerTemplate.offerTemplateFiberPlans = fiberPlans

                setOffer(res.data)
                setFormData({
                    ...formData,
                    firstName: res?.data?.lead?.name?.split(" ")[0],
                    lastName: res?.data?.lead?.name?.split(" ")[1],
                    email: res?.data.lead?.email,
                    phone: res?.data.lead?.phone,
                    address: res?.data.lead?.fiberHouse?.mapString,
                   
                })

                setInternetSpeed(res.data?.fiberPlan?.name)

                setLoading(false)
                // }
            }
        }
        getOffer()
        // scroll to top
        window.scrollTo(0, 0)
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? (e.target).checked : value
        }))
    }
    const installTimeOptions = ['Anytime', 'Morning', 'Afternoon']
    const securityQuestions1 = [
        "Your Pet's Name:",
        "Where were you born:",
        "What is your Favourite Color.",
        "What is your Highschool Mascot"
    ]


    async function onSubmit(e) {
        e.preventDefault();
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are submitting your form",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        if (formData.firstName === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.lastName === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Last Name is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.email === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Email is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.phone === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone Number is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.address === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Address is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.installDate === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Install Date is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.installTime === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Install Time is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }


        if (formData.verificationPhrase === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Verification Phrase is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.securityQuestion1 === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Security Question 1 is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if (formData.securityAnswer1 === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Security Answer 1 is required",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if(ownARouter === null){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Do you own a router please select an option",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }

        if(ownARouter === "No" && formData.rentARouter === false){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select if you want to rent a router",
                showConfirmButton: true,
                showCancelButton: false
            })
            return;
        }



        let form = {
            secondaryHolder: formData.secondaryHolder,
            installDate: formData.installDate,
            installTime: formData.installTime,
            verificationPhrase: formData.verificationPhrase,
            securityQuestion1: formData.securityQuestion1,
            securityAnswer1: formData.securityAnswer1,
            companyNotes: formData.companyNotes,
            schedulingNotes: formData.schedulingNotes,
            promoCode: formData.promoCode,
            goPaperless: formData.goPaperless,
            rentARouter: formData.rentARouter,      
        }

        let req = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            formData: JSON.stringify(form),
            apartment: formData.apartment,
            cityStateZip: formData.cityStateZip,
            leadId: offer.leadId
        }

        let res = await CustomerApi.SubmitFastwyreCompleteForm(req);

        if (res?.status?.success) {
            Swal.fire({
                title: "Success",
                text: "Form submitted successfully. Our Representative will contact you soon.",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setFormSubmitted(true)
        }
        else {
            Swal.fire({
                title: "Error",
                text: res.status.message,
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    const setAppointmentTime = async (e) => {

        e.preventDefault()

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Setting Appointment Time",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        console.log('Setting Appointment Time')
        const req = new StringIntReq()
        req.int = offer?.leadId
        req.string = timeSlot
        const res = await FiberHouseApi.SetAppointmentTimeForRequestCall(req)

        if (res.status.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Expect a call from a representative in the selected time slot",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setFormSubmitted(true)
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    if (formSubmitted) {
        return (
            <div className="min-h-screen flex bg-gradient-to-r from-[#022780] to-[#000614] p-8">
                <div className="max-w-3xl m-auto bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="p-6">
                        <img src={lightcurveLogo} alt="Fastwyre Logo" className="w-full h-32 object-contain object-center" />
                        <div className="flex items-center justify-center mb-6">
                            <h2 className="text-2xl text-center text-[#000]">Thank you for Submitting your Info</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function getPlanSpeed(plan) {
        if (plan.speed < 1) {
            return plan.speed * 1000 + " Mbps"
        } else {
            return plan.speed + " Gig"
        }
    }

    return (
        <div className="min-h-screen bg-gradient-to-r from-[#022780] to-[#000614] p-8">
            <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="p-6">
                    <img src={lightcurveLogo} alt="Fastwyre Logo" className="w-full h-32 object-contain object-center" />
                    <div className="flex items-center justify-center mb-6">
                        <h2 className="text-2xl font-bold text-[#2761D0]">Customer Information Form</h2>
                    </div>
                    <form onSubmit={onSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                    required
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="secondaryHolder" className="block text-sm font-medium text-gray-700 mb-1">Secondary Account Holder Name</label>
                            <input
                                type="text"
                                id="secondaryHolder"
                                name="secondaryHolder"
                                value={formData.secondaryHolder}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="apartment" className="block text-sm font-medium text-gray-700 mb-1">Apartment (Optional)</label>
                            <input
                                type="text"
                                id="apartment"
                                name="apartment"
                                value={formData.apartment}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                            />
                        </div>
                        <div>
                            <fieldset>
                                <legend className="text-lg font-semibold text-[#2761D0] mb-2">Install Date Options</legend>
                                <div className="grid grid-cols-2 gap-4">

                                    <label key={"ASAP"} className="flex items-center space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                                        <input
                                            type="checkbox"
                                            name="installDate"
                                            value={"ASAP"}
                                            checked={formData.installDate === "ASAP"}
                                            onChange={() => {
                                                setFormData({
                                                    ...formData,
                                                    installDate: formData.installDate === "ASAP" ? "" : "ASAP"
                                                })
                                            }}
                                            className="form-radio text-[#2761D0] h-5 w-5"
                                        />
                                        <span className="font-medium">ASAP</span>
                                    </label>
                                    <input type="date" name="installDate" value={formData.installDate} onChange={handleInputChange} className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]" disabled={formData.installDate === "ASAP"} />
                                </div>
                            </fieldset>
                        </div>
                        <div>
                            <fieldset>
                                <legend className="text-lg font-semibold text-[#2761D0] mb-2">Install Time Options</legend>
                                <div className="grid grid-cols-2 gap-4">
                                    {installTimeOptions.map((option) => (
                                        <label key={option} className="flex items-center space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                                            <input
                                                type="radio"
                                                name="installTime"
                                                value={option}
                                                checked={formData.installTime === option}
                                                onChange={handleInputChange}
                                                className="form-radio text-[#2761D0] h-5 w-5"
                                            />
                                            <span className="font-medium">{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </fieldset>
                        </div>
                        {/* <div>
                            <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Internet Speed Options *</h3>

                            <div className="space-y-6">

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {
                                        offer?.offerTemplate?.offerTemplateFiberPlans?.map((plan, index) => (
                                            <label key={index} className={`relative cursor-pointer ${internetSpeed === plan.fiberPlan.name ? 'ring-2 ring-[#2761D0]' : 'ring-1 ring-gray-200'} rounded-lg p-4 transition-all duration-200`}>
                                                <input
                                                    type="radio"
                                                    name="internetSpeed"
                                                    value={plan.fiberPlan.name}
                                                    checked={internetSpeed === plan.fiberPlan.name}
                                                    onChange={() => {
                                                        setInternetSpeed(plan.fiberPlan.name)
                                                    }}
                                                    className="sr-only"
                                                />
                                                <div className="space-y-2">
                                                    <div className="font-semibold text-gray-600">LightCurve</div>
                                                    <div className="text-3xl font-bold">{getPlanSpeed(plan.fiberPlan)}</div>
                                                    <div className="text-2xl font-bold">${plan.fiberPlan.price}/mo*</div>
                                                </div>
                                                {internetSpeed === plan.fiberPlan.name && (
                                                    <div className="absolute -top-2 -right-2 w-6 h-6 bg-[#2761D0] rounded-full flex items-center justify-center">
                                                        <Check className="w-4 h-4 text-white" />
                                                    </div>
                                                )}
                                            </label>
                                        ))
                                    }
                                </div>
                                <div className="space-y-3">
                                    {offer?.offerTemplate?.bulletPoints?.split('\n').map((benefit, index) => (
                                        <div key={index} className="flex items-center gap-2 text-gray-600">
                                            <div className="w-5 h-5 rounded-full bg-[#2761D0] flex items-center justify-center flex-shrink-0">
                                                <Check className="w-3 h-3 text-white" />
                                            </div>
                                            <span>{benefit}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> */}

                    
                        <div>
                            <label htmlFor="verificationPhrase" className="block text-sm font-medium text-gray-700 mb-1">Verification Phrase</label>
                            <input
                                type="text"
                                id="verificationPhrase"
                                name="verificationPhrase"
                                value={formData.verificationPhrase}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="securityQuestion1" className="block text-sm font-medium text-gray-700 mb-1">Security Question 1</label>
                            <select
                                id="securityQuestion1"
                                name="securityQuestion1"
                                value={formData.securityQuestion1}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            >
                                <option value="">Select a question</option>
                                {securityQuestions1.map((question) => (
                                    <option key={question} value={question}>{question}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="securityAnswer1" className="block text-sm font-medium text-gray-700 mb-1">Answer Security Question 1</label>
                            <input
                                type="text"
                                id="securityAnswer1"
                                name="securityAnswer1"
                                value={formData.securityAnswer1}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                required
                            />
                        </div>
                      
                 
                        <div>
                            <label htmlFor="companyNotes" className="block text-sm font-medium text-gray-700 mb-1">Notes for the Company (Optional)</label>
                            <textarea
                                id="companyNotes"
                                name="companyNotes"
                                value={formData.companyNotes}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                rows={3}
                            ></textarea>
                        </div>
                        <div>
                            <label htmlFor="schedulingNotes" className="block text-sm font-medium text-gray-700 mb-1">Scheduling Notes (Optional)</label>
                            <textarea
                                id="schedulingNotes"
                                name="schedulingNotes"
                                value={formData.schedulingNotes}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                rows={3}
                            ></textarea>
                        </div>
                        <div>
                            <label htmlFor="promoCode" className="block text-sm font-medium text-gray-700 mb-1">Promo Code</label>
                            <input
                                type="text"
                                id="promoCode"
                                name="promoCode"
                                value={formData.promoCode}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                            />
                        </div>
                    
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-[#2761D0] mb-2">Additional Options</h3>

                            <label className="flex items-start space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="freeLeasedDevice"
                                    checked={true}
                                    disabled={true}
                                    className="mt-1"
                                />
                                <div>
                                    <span className="font-medium">Free Modem for Life.</span>
                                </div>
                            </label>

                            <div className="flex items-start space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                            
                                <div>
                                    <span className="font-medium">Do you own a Router?</span>
                                    <div>
                                        <input onClick={(e) => {
                                            setOwnARouter(e.target.value)
                                            setFormData({
                                                ...formData,
                                                rentARouter: false
                                            })
                                        }} type="radio" name="freeLeasedDevice" value="Yes" checked={ownARouter === "Yes"} className="mr-2" />
                                        <label htmlFor="Yes" className="mr-4">Yes</label>
                                    </div>
                                    <div>
                                        <input onClick={(e) => {
                                            setOwnARouter(e.target.value)
                                        }} type="radio" name="freeLeasedDevice" checked={ownARouter === "No"} value="No" className="mr-2" />
                                        <label htmlFor="No">No</label>
                                    </div>
                                </div>
                            </div>

                            <label className="flex items-start space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="rentARouter"
                                    checked={formData.rentARouter}
                                    onChange={handleInputChange}
                                    className="mt-1"
                                    disabled={!(ownARouter === "No")}
                                />
                                <div>
                                    <span className="font-medium">Rent Wifi Router</span>
                                    <p className="text-sm text-gray-500">$10.0 / Month.</p>
                                </div>
                            </label>

                            <label className="flex items-start space-x-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="goPaperless"
                                    checked={formData.goPaperless}
                                    onChange={handleInputChange}
                                    className="mt-1"
                                />
                                <div>
                                    <span className="font-medium">Go Paperless</span>
                                    <p className="text-sm text-gray-500">If you choose to receive a paper statement a $2 monthly statement fee will apply.</p>
                                </div>
                            </label>
                        </div>

                        <button type="submit" className="w-full bg-[#2761D0] text-white py-2 px-4 rounded-md hover:bg-[#2761D0]/90 focus:outline-none focus:ring-2 focus:ring-[#2761D0] focus:ring-offset-2">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
import React, { useContext, useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { getEditorJsTools } from "../constants/constant";
import "./SellingCardEditor.scss";
import StringIntReq from "../../../../Requests/StringIntReq";
import DeleteSellingCardBlockReq from "../../../../Requests/SellingCard/DeleteSellingCardBlockReq";
import Swal from "sweetalert2";
import SellingCardApi from "../../../../API/SellingCardApi";
import { UserContext } from "../../../../App";
import VersatileLoader from "../../../../components/VersatileLoader";
import { getStorage, ref, deleteObject } from "firebase/storage";
import QuizSubmissionView from "./QuizSubmissionView";
import { Alert, AlertTitle, Stack } from "@mui/material";
import { GrNext, GrPrevious } from "react-icons/gr";

const SellingCardEditor = ({
  editorId,
  selectedSellingCardId,
  selectedSubTrainingModuleId,
  setSelectedSellingCardId,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  sellingCardIds,
}) => {
  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState(null);
  const [editorDataCopy, setEditorDataCopy] = useState(null);
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isSalesRep, setSalesRep] = useState(false);
  const [ready, setReady] = useState(false);
  const [currentSellingCardId, setCurrentSellingCardId] = useState(
    selectedSellingCardId
  );
  const [readOnly, setReadOnly] = useState(false);
  const [toggle, setToggle] = useState(false);
  const sellingCardType = useRef(null);
  const [makeReadOnly, setMakeReadOnly] = useState(false);
  const storage = getStorage();
  const [showSubmissions, setShowSubmissions] = useState(false);
  const [reload, setReload] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  function readData() {
    // setReadOnly(true);
    // return true;
    if (user.userType === "SalesRep") {
      setSalesRep(true);

      return true;
    }
    return false;
  }
  function isNumber(str) {
    return !isNaN(Number(str));
  }

  const saveEditorData = async (sellingCardId) => {
    if (editorRef.current) {
      // setIsProcessing(true);
      const content = await editorRef.current.save();

      let stringEditorData = [];
      for (let i = 0; i < content.blocks.length; i++) {
        stringEditorData.push(content.blocks[i]);
        stringEditorData[i].data = JSON.stringify(stringEditorData[i].data);
        stringEditorData[i].id = isNumber(stringEditorData[i].id)
          ? stringEditorData[i].id
          : 0;
      }
      console.log(`Quiz Data ${content}`);
      console.log(`Quiz Data Stringify ${JSON.stringify(stringEditorData)}`);
      await SellingCardApi.saveEditorData({
        id: sellingCardId,
        content: stringEditorData,
      });
      Swal.fire("Success", `Editor data saved successfully`, "success");
      setHasUnsavedChanges(false);
      setReload(true);
      // setIsProcessing(false);
    }
  };

  const sendQuizSubmission = async (quizId, userId, quizAnswers) => {
    try {
      const response = await SellingCardApi.CreateQuizSubmission({
        QuizId: quizId,
        UserId: userId,
        QuizAnswers: quizAnswers,
      });

      if (response.status.success) {
        Swal.fire("Success", "Quiz submission created successfully", "success");
        setHasSubmitted(true);
      } else {
        Swal.fire("Error", response.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const CreateQuizSubmission = async (sellingCardId) => {
    if (editorRef.current) {
      editorRef.current.readOnly.toggle();
      const content = await editorRef.current.save();
      let quizAnswers = [];
      for (let i = 0; i < content.blocks.length; i++) {
        quizAnswers.push({
          data: JSON.stringify(content.blocks[i].data),
        });
      }
      if (editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
      await sendQuizSubmission(sellingCardId, user.id, quizAnswers);
    }
  };

  const handleNavigation = async (direction) => {
    // if (isProcessing) {
    //   Swal.fire("Processing", "Please wait until the current action is completed.", "info");
    //   return;
    // }
    // setIsProcessing(true);
    if (hasUnsavedChanges) {
      const result = await Swal.fire({
        title: "Unsaved Changes",
        text: "You have unsaved changes. Do you want to save them before navigating?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: "Save",
        cancelButtonText: "Don't Save",
        showDenyButton: true,
        denyButtonText: "Edit",
      });

      if (result.isConfirmed) {
        await saveEditorData(currentSellingCardId);
      } else if (result.isDenied) {
        // setIsProcessing(false);
        return; // Prevent navigation and remain on the current page
      }
    }
    await fetchAdjacentSellingCard(direction);
    setHasSubmitted(false); // Reset hasSubmitted when navigating to a new card
    // setIsProcessing(false);
  };

  const fetchData = async () => {
    // if (isProcessing) {
    //   Swal.fire("Processing", "Please wait until the current action is completed.", "info");
    //   return;
    // }
    // setIsProcessing(true);
    try {
      setLoading(true);
      const req = new StringIntReq();
      req.int = selectedSellingCardId;
      const res = await SellingCardApi.GetSellingCard(req);
      if (res.status.success) {
        if (res.data.type === "quiz") {
          sellingCardType.current = res.data.type;
        } else {
          sellingCardType.current = "selling";
        }
        const data = res.data;

        const b = res.data.blocks.map((block) => ({
          id: block.id.toString(),
          type: block.type,
          data: JSON.parse(block.data),
        }));

        const editorData = {
          time: 1550476186479,
          blocks: b,
          version: "2.8.1",
        };

        setEditorData(editorData);
        setEditorDataCopy(JSON.parse(JSON.stringify(editorData)));
        setLoading(false);
        setCurrentSellingCardId(selectedSellingCardId);
        // setIsProcessing(false);
      }
    } catch (error) {
      // setIsProcessing(false);
      console.error(`Error fetching tree data:${error}`, error.message);
      setLoading(false);
    }
    // setIsProcessing(false);
  };

  const checkIfSubmitted = async () => {
    try {
      const req = new StringIntReq();
      req.int = selectedSellingCardId;
      const response = await SellingCardApi.GetQuizSubmissions(req);
      if (response.status.success) {
        console.log({ response });
        const userSubmission = response.data.find(
          (submission) => submission.userId === user.id
        );
        if (userSubmission) {
          setHasSubmitted(true);
        }
      } else {
        Swal.fire("Error", response.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const fetchAdjacentSellingCard = async (direction) => {
    try {
      const currentIndex = sellingCardIds
        .map((id) => parseInt(id))
        .indexOf(parseInt(selectedSellingCardId));
      let newIndex;
      if (direction === "previous") {
        newIndex =
          currentIndex > 0 ? currentIndex - 1 : sellingCardIds.length - 1;
      } else if (direction === "next") {
        newIndex =
          currentIndex < sellingCardIds.length - 1 ? currentIndex + 1 : 0;
      }

      const newSellingCardId = sellingCardIds[newIndex];
      if (newSellingCardId) {
        setSelectedSellingCardId(newSellingCardId);
        setHasUnsavedChanges(false);
      } else {
        Swal.fire("Info", `No ${direction} selling card found.`, "info");
      }
      setHasSubmitted(false); // Reset hasSubmitted when fetching a new card
    } catch (error) {
      console.error(`Error fetching ${direction} selling card:`, error);
      Swal.fire("Error", `Error fetching ${direction} selling card.`, "error");
    }
  };

  useEffect(() => {
    if (editorData && sellingCardType.current && !hasSubmitted) {
      initializeEditor();
    }
  }, [editorData, sellingCardType.current]);

  useEffect(() => {
    if (selectedSellingCardId) {
      setShowSubmissions(false);
      checkIfSubmitted();
      fetchData();
      setHasSubmitted(false);
    }
  }, [selectedSellingCardId]);

  useEffect(() => {
    if (reload) {
      checkIfSubmitted();
      fetchData();

      setHasSubmitted(false);
      setReload(false);
    }
  }, [reload]);

  const onBlockRemoved = async (block, blockIndex) => {
    // setIsProcessing(true);
    const req = new DeleteSellingCardBlockReq();
    req.int = block.id;
    try {
      const res = await SellingCardApi.DeleteSellingCardBlock(req);
      if (res.status.success) {
        Swal.fire("Deleted!", "The block has been deleted.", "success");

        if (block.type === "image") {
          const filePath = block.data.file.url;
          const fileRef = ref(storage, filePath);
          await deleteObject(fileRef);
        }
      } else {
        Swal.fire("Error!", res.status.message, "error");
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
    // setIsProcessing(false);
  };

  const initializeEditor = async () => {
    if (editorRef.current) {
      if (editorRef.current.destroy) {
        editorRef.current.destroy();
      }
      editorRef.current = null;
      setReady(false);
      setHasUnsavedChanges(false);
    }

    console.log("selling card type", sellingCardType.current);
    const tools =
      sellingCardType.current === "selling"
        ? getEditorJsTools(user)
        : {
            // paragraph:false,
            quiz: getEditorJsTools(user, makeReadOnly).quiz,
            question: getEditorJsTools(user, makeReadOnly).question,
            // image: getEditorJsTools(user).image,
            // video: getEditorJsTools(user).video,
          };
    console.log("im in init", readOnly);
    editorRef.current = new EditorJS({
      holder: "editorjs",
      tools: tools,
      // defaultBlock:  'quiz',
      onReady: () => {
        setReady(true);
        setHasUnsavedChanges(false);
      },
      onChange: async (api, event) => {
        // setHasUnsavedChanges(true);
        let e;
        if (event.length === undefined) {
          e = event;
        } else {
          e = event[0];
        }
        console.log(e);
        if (
          e.type === "block-removed" &&
          e.detail.index < editorData.blocks.length &&
          !/[a-z]/i.test(e.detail.target.id)
        ) {
          const blockIndex = e.detail.index;
          const block = editorData.blocks[blockIndex];
          await onBlockRemoved(block, blockIndex);
          setHasUnsavedChanges(false);
        }
        // Update the editorDataCopy in real-time
        const updatedContent = await editorRef.current.save();
        setEditorDataCopy(updatedContent);
      },
      data: editorData,

      readOnly: user.userType === "SalesRep" ? true : readOnly,
    });
  };

  const toggleReadOnly = () => {
    setToggle(true);
    setReadOnly(!readOnly);
    // if(editorRef.current){
    // editorRef.current.readOnly.toggle();
    // }
  };

  const handleShowSubmissions = () => {
    // if (editorRef.current && editorRef.current.destroy) {
    //   editorRef.current.readOnly.toggle();
    //   editorRef.current.readOnly.toggle();
    // }
    setShowSubmissions(false);
    setReload(true);
  };

  useEffect(() => {
    if (toggle) {
      initializeEditor();
      setToggle(false);
    }
  }, [readOnly]);
  // selectedSellingCardId ?
  return loading && ready ? (
    <div className="spinner">
      <VersatileLoader color="#2761D0" size="large" />
    </div>
  ) : (
    <div
      style={{ maxHeight: "80vh", position: "relative" }}
      className="w-full h-full"
    >
      {user.userType === "SuperAdmin" && sellingCardType.current === "quiz" && (
        <div className="fixed flex z-10 flex-row justify-end items-center gap-2 my-2 right-10">
          <button
            className="quiz-button rounded-lg p-2 px-2 bg-blue-950 text-white"
            onClick={handleShowSubmissions}
          >
            Quiz
          </button>
          <button
            className="submissions-button rounded-lg p-2 px-2 bg-blue-950 text-white"
            onClick={() => {
              if (editorRef.current && editorRef.current.destroy) {
                editorRef.current.destroy();
              }
              setShowSubmissions(true);
              setReload(false);
            }}
          >
            Submissions
          </button>
        </div>
      )}
      {showSubmissions ? (
        <div className="pt-20">
          <QuizSubmissionView quizId={currentSellingCardId} />
        </div>
      ) : hasSubmitted ? (
        <div className="w-full h-full flex justify-center items-start pt-4">
          <Stack sx={{ width: "45%", height: "20%" }} spacing={2}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              You have already submitted this quiz
            </Alert>
          </Stack>
        </div>
      ) : (
        <div
          id="editorjs"
          className="bg-white overflow-y-auto w-full flex-1 p-4 rounded-lg h-full transition-all duration-300 flex flex-col justify-between"
        ></div>
      )}

      {!showSubmissions && (
        <div
          className="absolute bottom-5 z-10 gap-4 pl-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="prev-button rounded-lg p-2 px-4 bg-blue-950 text-white"
            onClick={() => handleNavigation("previous")}
          >
            <GrPrevious />
          </button>
          <button
            className="next-button rounded-lg p-2 px-4 bg-blue-950 text-white"
            onClick={() => handleNavigation("next")}
          >
            <GrNext />
          </button>
        </div>
      )}

      {user.userType === "SuperAdmin" &&
        sellingCardType.current === "quiz" &&
        !showSubmissions &&
        !hasSubmitted && (
          <button
            onClick={toggleReadOnly}
            className="preview-button rounded-lg p-2 px-4 bg-blue-950 text-white"
            style={{
              position: "absolute",
              bottom: "20px",
              right: readOnly ? "20px" : "100px",
              zIndex: 1,
            }}
          >
            {readOnly ? "Edit" : "Preview"}
          </button>
        )}

      {!readOnly &&
        !(user.userType === "SalesRep") &&
        !showSubmissions &&
        !hasSubmitted && (
          <button
            onClick={() => saveEditorData(currentSellingCardId)}
            className="save-button rounded-lg p-2 px-4 bg-blue-950 text-white"
            style={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
              zIndex: 1,
            }}
          >
            Save
          </button>
        )}
      {sellingCardType.current === "quiz" &&
        user.userType === "SalesRep" &&
        !showSubmissions &&
        !hasSubmitted && (
          <button
            onClick={() => CreateQuizSubmission(currentSellingCardId)}
            className="save-button rounded-lg p-2 bg-blue-950 text-white"
            style={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
              zIndex: 1,
            }}
          >
            Submit
          </button>
        )}

      {/* <button
        onClick={() => CreateQuizSubmission(currentSellingCardId)}
        className="save-button rounded-lg p-2 bg-blue-950 text-white"
        style={{
          position: "absolute",
          bottom: "20px",
          right: "200px",
          zIndex: 1,
        }}
      >
        Submit
      </button> */}

      {/* {unsavedBlocks.length > 0 && (
          <div className="unsaved-blocks">
            <h3>Unsaved Changes:</h3>
            <ul>
              {unsavedBlocks.map((blockId) => (
                <li key={blockId}>Block ID: {blockId}</li>
              ))}
            </ul>
          </div>
        )} */}
    </div>
  );
  // : (
  //   <div
  //     style={{ maxHeight: "80vh", position: "relative" }}
  //     className="w-full h-full"
  //   >
  //     <div className="bg-white  overflow-y-auto w-full flex-1 p-4 pt-8 rounded-lg h-full transition-all duration-300 justify-center items-center"></div>
  //     <h1 className="text-center text-2xl">Please select a selling card</h1>
  //   </div>
  // );
};

export default SellingCardEditor;

import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { UserContext } from '../../../App'
import Sidebar from '../../../Layouts/Sidebar'
import './CustomerDetails.scss'
import Details from './Details'
import Edit from './Edit'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import SearchCustomerReq from '../../../Requests/Customer/SearchCustomerReq'
import CustomerApi from '../../../API/CustomerApi'
import { CircularProgress } from '@mui/material'
const Index = () => {
    const { User } = useContext(UserContext);
    const { state } = useLocation();
    const [edit, setEdit] = useState(false);
    
    const [customer, setCustomer] = useState(null);
    const [change,setChange] = useState(false);
    const {id} = useParams();

    // let customer = state.customer;
    
    
    const searchCustomer = async () => {
        const req = new SearchCustomerReq();
        req.id = id;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 10;
        const res = await CustomerApi.SearchFidiumCustomers(req);
        console.log(res)
        if(res.status.success){
            setCustomer(res.data.list[0])
        }
    }
    
    useEffect(
        ()=>{
            if(id){
                searchCustomer()
            }
        },[change]
    )
//     accountStatus d
// addedToCrm ?
// address d
// answerSecurityQuestion1 d
// answerSecurityQuestion2 d
// cellPhone d
// desiredInstallDateString d
// desiredInstallTimeString d
// email d
// fiberPlanString d
// securityQuestion1 d
// securityQuestion2 d
// isHotLead d
// firstName d
// fourDigitPin d
// lastName d
// freeMonth d
// installCopy d
// installDateTime d
// orderNumber d
// promoCode d
// notesToFiberCompanySpecialInstructions d
// schedulingNotes d
// secondaryAccountHolderFullName d
// secondaryPhoneNumber d
// timeZone d
// timeStamp d
// salesOrg n
// salesRep n
// speedTestAfterImage n
// speedTestBeforeImage n
// submittedToEmbarcForm n
    return (
        <Layout >
            {
                customer?
                <Details customer={customer} setEdit={setEdit} setChange={setChange} change={change} />
                :
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <CircularProgress size={40} sx={{
                        color:"var(--primary-color)"
                    }} />
                </div>
            }
        </Layout>
    )
}

export default Index
const PlanCard = ({
    plan,
    color = "#483092",
    setSelectedPlan,
    setOpen,
    offer,
    name,
    // setColor,
  }) => {
    console.log("plan", plan)
    const dot = plan?.speed?.toString().includes(".") ? true : false;
    const getPlanSpeed = () => {
            return `${plan?.speed * 1000} Mbps*`
    }
    function onClick() {
        setSelectedPlan(plan)
        setOpen(true)
        // setColor("#F47920")
    }
    return (
      <div className="max-w-sm rounded-3xl overflow-hidden border border-gray-200">
        <div className={`text-white p-6 pb-8`} style={{backgroundColor: color}}>
          <div className="text-xl font-medium">Up To</div>
          <div className="flex items-center justify-center">
            <div className="font-bold" style={{fontSize: dot ? "4.5rem" : "6rem", lineHeight:1 }}>{plan?.speed + "GIG"}</div>
            <div className="ml-2 text-2xl">{getPlanSpeed(plan?.speed)}</div>
          </div>
          <div className="mt-2 text-xl font-medium">UPLOAD/DOWNLOAD SPEEDS</div>
          <div className="text-xl font-medium">UNLIMITED DATA</div>
        </div>
  
        <div className="bg-white p-6">
          <div className="flex items-center justify-center">
            <div className={`text-9xl font-bold `} style={{color: color}}>{plan?.price}</div>
            <div className={`ml-2 text-4xl font-medium`}style={{color: color}}>
            00/mo*
              <div className="text-2xl text-black font-medium">No contract required</div>
            </div>
          </div>
  
          <button
            onClick={()=>onClick()}
            className="w-full mt-4 py-3 text-xl font-bold text-white rounded-lg bg-gradient-to-r from-[#483092] via-[#019A86] to-[#C1DE2A]"
          >
            ACCEPT OFFER
          </button>
        </div>
      </div>
    )
  }
  
export default PlanCard;
  
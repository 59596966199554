import { useEffect, useState } from "react";
import { FiX, FiSearch, FiCheck } from "react-icons/fi";
import StringIntReq from "../../../../Requests/StringIntReq";
import SellingCardApi from "../../../../API/SellingCardApi";
import Swal from "sweetalert2";
import UserSelector from "../../../../components/UserSelector";

export function UserPermissionManager({ trainingModuleId, isOpen, onClose }) {
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssignedUsers();
  }, []);

  const fetchAssignedUsers = async () => {
    setLoading(true);
    const req = new StringIntReq();
    req.int = trainingModuleId;
    const res = await SellingCardApi.FetchAssignedUsers(req);
    if (res.status.success) {
      setAssignedUsers(res.data);
    } else {
      Swal.fire("Error", res.status.message, "error");
    }
    setLoading(false);
  };

  const handleAssignUser = async (userId) => {
    setLoading(true);
    const req = {
      trainingModuleId: trainingModuleId,
      userId: userId,
    };
    const res = await SellingCardApi.AssignUserToTrainingModule(req);
    if (res.status.success) {
      fetchAssignedUsers();
      Swal.fire("Success", "User assigned successfully", "success");
    } else {
      Swal.fire("Error", res.status.message, "error");
    }
    setLoading(false);
  };

  const handleRemoveUser = async (userId) => {
    setLoading(true);
    const req = {
      trainingModuleId: trainingModuleId,
      userId: userId,
    };
    const res = await SellingCardApi.RemoveUserFromTrainingModule(req);
    if (res.status.success) {
      fetchAssignedUsers();
      Swal.fire("Success", "User removed successfully", "success");
    } else {
      Swal.fire("Error", res.status.message, "error");
    }
    setLoading(false);
  };

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md transform transition-all">
        {/* Modal Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold text-gray-800">
              Manage User Permissions
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors"
              style={{
                padding: "0",
                width: "auto",
                background: "none",
                color: "grey",
                boxShadow: "none",
              }}
            >
              <FiX className="w-6 h-6" />
              <span className="sr-only">Close</span>
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div className="p-6">
          <div className="relative">
            {/* <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" /> */}
            {/* <input
              type="text"
              placeholder="Select User To Assign"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            /> */}
            <UserSelector
              setUser={handleAssignUser}
              placeholder="Select User To Assign"
            />
          </div>

          {/* Assigned Users */}
          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-500 mb-3">
              Assigned Users
            </h3>
            <div className="max-h-[280px] overflow-y-auto border border-gray-200 rounded-lg">
              {assignedUsers.map((user) => (
                <div
                  key={user.id}
                  className="flex items-center justify-between p-3 group hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={user.user.profileImage?.url}
                      alt=""
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="font-medium text-gray-700">
                      {user.user.firstName} {user.user.lastName}{" "}
                    </span>
                  </div>
                  <button
                    onClick={() => handleRemoveUser(user.user.id)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-red-50 rounded-full"
                  >
                    <FiX className="w-5 h-5 text-red-500" />
                    <span className="sr-only">
                      Remove {user.user.firstName} {user.user.lastName}
                    </span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="p-6 border-t border-gray-200">
          <button
            onClick={onClose}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserPermissionManager;

class MapHelper {
    constructor(mapObject) {
        this.map = mapObject;
        this.requestQueue = [];
        this.isProcessing = false;
        this.RATE_LIMIT = 50; // Maximum requests per second
        this.CONCURRENT_LIMIT = 10; // Maximum concurrent requests
        this.activeRequests = 0;
    }

    checkIfFiberHouseIsInsideServicableArea(fiberHouse, searchArea) {
        const lat = fiberHouse.coordinates.latitude;
        const lng = fiberHouse.coordinates.longitude;

        let inside = false;
        for (let i = 0, j = searchArea.length - 1; i < searchArea.length; j = i++) {
            const xi = searchArea[i].latitude;
            const yi = searchArea[i].longitude;
            const xj = searchArea[j].latitude;
            const yj = searchArea[j].longitude;

            const intersect = ((yi > lng) !== (yj > lng)) && (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside; // Toggle the inside flag on each intersection
            }
        }
        return inside;
    }

    async searchLocation(address) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0`;

        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                const { lat, lng } = data.results[0].geometry.location;
                return [lng, lat];
            } else {
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    };


    // combineTwoAreas(area1,area2){

    // }

    async searchLocationDetails(address) {
        return new Promise((resolve) => {
            this.requestQueue.push({ address, resolve });
            this.processQueue();
        });
    }

    async processQueue() {
        if (this.isProcessing || this.requestQueue.length === 0) return;
        this.isProcessing = true;

        while (this.requestQueue.length > 0) {
            if (this.activeRequests >= this.CONCURRENT_LIMIT) {
                await new Promise(resolve => setTimeout(resolve, 1000)); // Wait if too many active requests
                continue;
            }

            const batch = this.requestQueue.splice(0, Math.min(this.RATE_LIMIT, this.requestQueue.length));
            this.activeRequests += batch.length;

            batch.forEach(async (request, index) => {
                try {
                    // Add delay between requests in the batch
                    await new Promise(resolve => setTimeout(resolve, index * (1000 / this.RATE_LIMIT)));

                    const result = await this.makeGeocodingRequest(request.address);
                    request.resolve(result);
                } catch (error) {
                    console.error(`Error processing address: ${request.address}`, error);
                    request.resolve({ coordinates: null, address: request.address });
                } finally {
                    this.activeRequests--;
                }
            });

            // Wait before processing next batch
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        this.isProcessing = false;
    }

    async makeGeocodingRequest(address) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;

        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                const { lat, lng } = data.results[0].geometry.location;
                return { coordinates: [lng, lat], address: address };
            } else {
                console.log("Geocoding error:", data.status, data.error_message);
                return { coordinates: null, address: address };
            }
        } catch (error) {
            console.error("Request failed:", error);
            return { coordinates: null, address: address };
        }
    }


    async getLocationName(coordinates) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;
        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                return data.results[0].formatted_address;
            } else {
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return null;
        }

    }

    async getLocationDetails(coordinates) {
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;
        try {
            const response = await fetch(geocodingUrl);
            const data = await response.json();

            if (response.status === 200 && data.status === "OK") {
                const result = data.results[0];
                const addressComponents = result.address_components;

                let city = null;
                let state = null;
                let country = null;

                addressComponents.forEach(component => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });

                return { city, state, country };
            } else {
                throw new Error(data.error_message || "An error occurred");
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    // For Stats 

    // Get the Unique Sales Orgs from the Fiber Houses
    getUniqueSalesOrgsFromFiberHouses(fiberHouses) {
        let salesOrgs = [];
        fiberHouses.forEach((fiberHouse) => {
            if (fiberHouse.salesOrgId) {
                if (!salesOrgs.some(salesOrg => salesOrg.id === fiberHouse.salesOrgId)) {
                    salesOrgs.push({
                        id: fiberHouse.salesOrgId,
                        name: fiberHouse.salesOrgName
                    })
                }
            }
        });
        return salesOrgs;
    }

    // Get the Unique Sales Reps from the Fiber Houses
    getUniqueSalesRepsFromFiberHouses(fiberHouses) {
        let salesReps = [];
        let userIds = new Set();

        fiberHouses.forEach((fiberHouse) => {
            if (fiberHouse.owners) {
                fiberHouse.owners.forEach((owner) => {
                    if (!userIds.has(owner.userId)) {
                        userIds.add(owner.userId);
                        salesReps.push({
                            userId: owner.userId,
                            name: owner.name,
                            profileImage: owner.profileImage
                        });
                    }
                });
            }
        });
        console.log(salesReps)
        return salesReps;
    }

    // Get the count of fiberHouses owned by each salesRep
    getCountOfEachSalesRepFiberHouses(fiberHouses) {
        const combinationsCount = {};

        fiberHouses?.forEach((fiberhouse) => {
            const ownerIds = fiberhouse?.owners?.map(owner => { return { id: owner.userId, salesRepF: [owner.salesRepFiberHouseId] } });
            // console.log(ownerIds)

            if (ownerIds) {
                for (let i = 0; i < ownerIds.length; i++) {
                    for (let j = i + 1; j < ownerIds.length; j++) {
                        const sortedPair = [ownerIds[i].id, ownerIds[j].id].sort();
                        const pairKey = sortedPair.join(',');

                        if (combinationsCount[pairKey]) {
                            combinationsCount[pairKey].count++;
                            combinationsCount[pairKey].salesRepF.push(...ownerIds[i].salesRepF, ...ownerIds[j].salesRepF);
                        } else {
                            combinationsCount[pairKey] = {
                                count: 1,
                                salesRepF: [...ownerIds[i].salesRepF, ...ownerIds[j].salesRepF]
                            };
                        }
                    }
                }
            }
        });

        return combinationsCount;
    }

    isGalaxyTablet() {
        const ua = navigator.userAgent.toLowerCase();

        // Check for Galaxy Tablet identifiers in User-Agent
        const isAndroid = /android/.test(ua);
        const isSamsung = /samsung/.test(ua);
        const isGalaxyModel = /sm-t[0-9]+|sm-p[0-9]+/i.test(ua); // Common Galaxy Tab model prefixes

        return isAndroid && (isSamsung || isGalaxyModel);
    }

    isIpad() {
        const ua = navigator.userAgent.toLowerCase();
        const platform = navigator.platform.toLowerCase();

        // Check for "iPad" in User-Agent or platform
        const hasIpadKeyword = /ipad/.test(ua) || /ipad/.test(platform);

        // Check for Macintosh User-Agent with touch support (iPadOS 13+)
        const isMacDesktop = /macintosh|macintel/.test(ua);
        const hasTouch = navigator.maxTouchPoints > 0;

        return hasIpadKeyword || (isMacDesktop && hasTouch) || this.isGalaxyTablet();
    }

    cleanup() {
        this.map = null;
    }

}

export default MapHelper;
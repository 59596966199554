import React, { useState } from 'react';
import BrandApi from '../../../../API/BrandApi'
import Swal from 'sweetalert2';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    // Paper,
    // IconButton,
    Box,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    CalendarToday as CalendarIcon,
} from '@mui/icons-material';
import Layout from '../../../../Layouts/Layout';
// import h from '@editorjs/simple-image';
import { useEffect } from 'react';
import StringIntReq from '../../../../Requests/StringIntReq';

export default function BrandsPage() {
    const [brands, setBrands] = useState([]);
    const [updatedBrands, setUpdatedBrands] = useState({
        brandName: '',
        description: '',
        largeLogo: '',
        smallLogo: '',
        primaryColor: '',
        secondaryColor: ''
    });

    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [smallLogoPreview, setSmallLogoPreview] = useState(null);

    const [primaryColor, setPrimaryColor] = useState('#FF0000');
    const [secondaryColor, setSecondaryColor] = useState('#00FF00');
    const [brandName, setBrandName] = useState('');
    const [description, setDescription] = useState('');
    const [smallLogo, setSmallLogo] = useState('');
    const [largeLogo, setLargeLogo] = useState('');
    


    const getBrands = async () => {
        let req = new StringIntReq();
        const res = await BrandApi.GetBrands(req);
        if (res?.status?.success) {
            setBrands(res.data);
        }
    }


    useEffect(() => {

        getBrands();

    }, []);



    const handleEdit = (brand) => {
        setSelectedBrand(brand);
        setUpdatedBrands({
            brandName: brand.name,
            description: brand.description,
            largeLogo: brand.largeLogo,
            smallLogo: brand.smallLogo,
            primaryColor: brand.primaryColor,
            secondaryColor: brand.secondaryColor,
        });
        setIsEditDialogOpen(true);
    };
    // console.log(selectedBrand);
    // console.log(updatedBrands);


    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {

            setLargeLogo(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleSmallLogoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSmallLogo(file);
            setSmallLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleSaveBrand = async () => {
        let req = {
            Name: brandName,
            Description: description,
            LargeLogo: largeLogo,
            SmallLogo: smallLogo,
            PrimaryColor: primaryColor,
            SecondaryColor: secondaryColor,
        };
        const res = await BrandApi.CreateBrand(req);

        if (res?.status?.success) {
            Swal.fire({
                title: 'Brand Created',
                text: 'Brand has been created',
                icon: 'success',
            });
            getBrands();
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res?.status?.message,
                icon: 'error',
            });
        }
    }

    const handleDelete = async (brand) => {
      


        let req = brand.name
            
        
        const res = await BrandApi.DeleteBrand(req);

        if (res?.status?.success) {
            Swal.fire({
                title: 'Brand Deleted',
                text: 'Brand has been deleted',
                icon: 'success',
            });
            getBrands();
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res?.status?.message,
                icon: 'error',
            });
        }
    }

    const handleUpdateBrand = async () => {

        // console.log(selectedBrand);
        let req = {
            Name: selectedBrand.name,
            Description: selectedBrand.description,
            LargeLogo: selectedBrand.largeLogo,
            SmallLogo: selectedBrand.smallLogo,
            PrimaryColor: selectedBrand.primaryColor,
            SecondaryColor: selectedBrand.secondaryColor,
        };
        const res = await BrandApi.UpdateBrand(req);

        if (res?.status?.success) {
            Swal.fire({
                title: 'Brand Updated',
                text: 'Brand has been updated',
                icon: 'success',
            });
            getBrands();
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res?.status?.message,
                icon: 'error',
            });
        }
    }




    return (
        <Layout>
            <Box sx={{ p: { sm: '1', md: "4" } }}>
                <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px', textAlign: 'center' }}>Manage Brands</h1>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: 1,
                        borderRadius: 1,
                        p: 3,
                        mb: 4,
                    }}
                >
                    <Box display="flex" gap={2} mb={2} flexDirection="column">
                        <TextField fullWidth
                            placeholder='Search by Name'
                            variant="outlined" />


                        <Box display="flex" alignItems="center" gap={2} flexGrow={1}>
                            <TextField
                                type="date"
                                fullWidth
                                //   label="From Date"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <span>to</span>
                            <TextField
                                type="date"
                                fullWidth
                                //   label="End Date"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: 'var(--old-primary-color)', color: 'white !important',

                                ":hover": {
                                    backgroundColor: 'var(--primary-color)',
                                }

                            }}
                        >Search</Button>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={() => setIsAddDialogOpen(true)}
                        >
                            Add
                        </Button>
                    </Box>

                    <div className="border rounded-lg overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Description</th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Logo</th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Small Logo</th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Primary Color</th>
                                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Secondary Color</th>
                                    <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                {brands.map((brand) => (
                                    <tr key={brand.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4">{brand.name}</td>
                                        <td className="px-6 py-4">{brand.description}</td>
                                        <td className="px-6 py-4">
                                            <img
                                                src={"data:image/png;base64," + brand.largeLogo || "/placeholder.svg"}
                                                alt={`${brand.name} logo`}
                                                className="w-10 h-10 object-contain"
                                            />
                                        </td>
                                        <td className="px-6 py-4">
                                            <img
                                                src={"data:image/png;base64," + brand.smallLogo || "/placeholder.svg"}
                                                alt={`${brand.name} small logo`}
                                                className="w-6 h-6 object-contain"
                                            />
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center gap-2">
                                                <div
                                                    className="w-6 h-6 rounded border"
                                                    style={{ backgroundColor: brand.primaryColor }}
                                                />
                                                {brand.primaryColor}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center gap-2">
                                                <div
                                                    className="w-6 h-6 rounded border"
                                                    style={{ backgroundColor: brand.secondaryColor }}
                                                />
                                                {brand.secondaryColor}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex justify-end gap-2">
                                                <button
                                                    onClick={() => handleEdit(brand)}
                                                    className="p-2 hover:bg-gray-100 rounded-lg"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                    </svg>
                                                </button>
                                                <button 
                                                    onClick={() => 
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: 'You will not be able to recover this brand!',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes, delete it!',
                                                            cancelButtonText: 'No, keep it',
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                handleDelete(brand);
                                                            }
                                                        })


                                                    }
                                                className="p-2 hover:bg-gray-100 rounded-lg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Box>

                {/* Add Dialog */}
                <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
                    <DialogTitle
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        Add New Brand
                    </DialogTitle>
                    <DialogContent>
                        {/* Name and Description */}
                        <TextField
                            fullWidth
                            margin="normal"
                            placeholder="Name"
                            variant="outlined"
                            onChange={(e) => setBrandName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            placeholder="Description"
                            multiline
                            rows={3}
                            variant="outlined"
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        {/* Upload Logo */}
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                            <Button
                                variant="outlined"
                                component="label"
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}

                            >
                                Upload Logo
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => handleLogoUpload(e)} // Update preview here
                                />
                            </Button>
                            {/* Logo Preview */}
                            {logoPreview && (
                                <div
                                    style={{
                                        marginTop: '10px',
                                        textAlign: 'center',
                                        border: '1px dashed #ccc',
                                        borderRadius: '8px',
                                        padding: '10px',
                                    }}
                                >
                                    <img
                                        src={logoPreview}
                                        alt="Logo Preview"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100px',
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                    <p style={{ marginTop: '5px', color: '#555' }}>
                                        Logo Preview
                                    </p>
                                </div>
                            )}
                        </div>

                        {/* Upload Small Logo */}
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                            <Button
                                variant="outlined"
                                component="label"
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                Upload Small Logo
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => handleSmallLogoUpload(e)} // Update preview here
                                />
                            </Button>
                            {/* Small Logo Preview */}
                            {smallLogoPreview && (
                                <div
                                    style={{
                                        marginTop: '10px',
                                        textAlign: 'center',
                                        border: '1px dashed #ccc',
                                        borderRadius: '8px',
                                        padding: '10px',
                                    }}
                                >
                                    <img
                                        src={smallLogoPreview}
                                        alt="Small Logo Preview"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100px',
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                    <p style={{ marginTop: '5px', color: '#555' }}>
                                        Small Logo Preview
                                    </p>
                                </div>
                            )}
                        </div>

                        {/* Colors */}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}>
                            {/* Primary Color */}
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <label style={{ minWidth: '120px', fontWeight: 'bold' }}>Primary Color:</label>
                                <input
                                    type="color"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                    }}
                                    value={primaryColor}
                                    onChange={(e) => setPrimaryColor(e.target.value)} // Set primary color
                                />
                                <span
                                    style={{
                                        // padding: '10px',
                                        backgroundColor: primaryColor,
                                        borderRadius: '8px',
                                        border: '1px solid #ccc',
                                        display: 'inline-block',
                                        width: '90px',
                                        textAlign: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    {primaryColor}
                                </span>
                            </div>

                            {/* Secondary Color */}
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <label style={{ minWidth: '120px', fontWeight: 'bold' }}>Secondary Color:</label>
                                <input
                                    type="color"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                    }}
                                    value={secondaryColor}
                                    onChange={(e) => setSecondaryColor(e.target.value)} // Set secondary color
                                />
                                <span
                                    style={{
                                        // padding: '10px   ',
                                        backgroundColor: secondaryColor,
                                        borderRadius: '8px',
                                        border: '1px solid #ccc',
                                        display: 'inline-block',
                                        width: '90px',
                                        textAlign: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    {secondaryColor}
                                </span>
                            </div>
                        </div>

                        {/* <TextField
                            type="color"
                            fullWidth
                            label="Primary Color"
                            margin="normal"
                        />
                        <TextField
                            type="color"
                            fullWidth
                            label="Secondary Color"
                            margin="normal"
                        /> */}
                    </DialogContent>
                    <DialogActions>
                        <Button

                            // when clicked, save the brand and close the dialog and also set the logoPreview and smallLogoPreview to null
                            onClick={() => {
                                handleSaveBrand();
                                setIsAddDialogOpen(false);
                                setLogoPreview(null);
                                setSmallLogoPreview(null);

                            }}
                            sx={{
                                backgroundColor: 'var(--old-primary-color)',
                                color: 'white !important',
                                ":hover": {
                                    backgroundColor: 'var(--primary-color)',
                                },
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setIsAddDialogOpen(false);
                                setLogoPreview(null);
                                setSmallLogoPreview(null);
                            }}
                            variant="outlined"
                            sx={{
                                color: 'red !important',
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>





                {/* Edit Dialog */}

                {isEditDialogOpen && selectedBrand && (
                    <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
                        <DialogTitle
                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Edit Brand
                        </DialogTitle>
                        <DialogContent>
                            {/* Name and Description */}
                            <TextField
                                fullWidth
                                margin="normal"
                                placeholder="Name"
                                variant="outlined"
                                defaultValue={selectedBrand.name}
                                
                                disabled



                                // onChange={(e) => setSelectedBrand({ ...selectedBrand, name: e.target.value })}

                                
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                placeholder="Description"
                                multiline
                                rows={3}
                                variant="outlined"
                                defaultValue={selectedBrand.description}
                                onChange={(e) => setSelectedBrand({ ...selectedBrand, description: e.target.value })}
                                
                            />

                            {/* Upload Logo */}
                            <div style={{ marginTop: '16px', textAlign: 'center' }}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}


                                >
                                    Upload Logo
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"

                                        onChange={(e) => 
                                            setSelectedBrand({ ...selectedBrand, largeLogo: e.target.files[0] })
                                        } // Update preview here
                                        
                                    
                                    />
                                </Button>
                                {/* Logo Preview */}
                                {selectedBrand.largeLogo && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            textAlign: 'center',
                                            border: '1px dashed #ccc',
                                            borderRadius: '8px',
                                            padding: '10px',
                                        }}
                                    >
                                        <img
                                            src={"data:image/png;base64," + selectedBrand.largeLogo || "/placeholder.svg"}
                                            alt="Logo Preview"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100px',
                                                display: 'block',
                                                margin: 'auto',
                                            }}
                                        />
                                        <p style={{ marginTop: '5px', color: '#555' }}>
                                            Large Logo 
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Upload Small Logo */}
                            <div style={{ marginTop: '16px', textAlign: 'center' }}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}
                                >
                                    Upload Small Logo
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => 
                                            setSelectedBrand({ ...selectedBrand, smallLogo: e.target.files[0] })
                                        } // Update preview here
                                    />
                                </Button>
                                {/* Small Logo Preview */}
                                {selectedBrand.smallLogo && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            textAlign: 'center',
                                            border: '1px dashed #ccc',
                                            borderRadius: '8px',
                                            padding: '10px',
                                        }}
                                    >
                                        <img
                                            src={"data:image/png;base64," + selectedBrand.smallLogo || "/placeholder.svg"}
                                            alt="Small Logo Preview"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100px',
                                                display: 'block',
                                                margin: 'auto',
                                            }}
                                        />
                                        <p style={{ marginTop: '5px', color: '#555' }}>
                                            Small Logo 
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Colors */}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}>
                                {/* Primary Color */}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <label style={{ minWidth: '120px', fontWeight: 'bold' }}>Primary Color:</label>
                                    <input
                                        type="color"
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                        }}
                                        value={selectedBrand.primaryColor}
                                        onChange={(e) => setSelectedBrand({ ...selectedBrand, primaryColor: e.target.value })} // Set primary color
                                    />
                                    <span
                                        style={{
                                            // padding: '10px',
                                            backgroundColor: primaryColor,
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            display: 'inline-block',
                                            width: '90px',
                                            textAlign: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        {selectedBrand.primaryColor}
                                    </span>
                                </div>

                                {/* Secondary Color */}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                    <label style={{ minWidth: '120px', fontWeight: 'bold' }}>Secondary Color:</label>
                                    <input
                                        type="color"
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                        }}
                                        value= {selectedBrand.secondaryColor}
                                        onChange={(e) => setSelectedBrand({ ...selectedBrand, secondaryColor: e.target.value })} // Set secondary color
                                    />
                                    <span
                                        style={{
                                            // padding: '10px   ',
                                            backgroundColor: secondaryColor,
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            display: 'inline-block',
                                            width: '90px',
                                            textAlign: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        {selectedBrand.secondaryColor}
                                    </span>
                                </div>
                            </div>

                            {/* <TextField
                            type="color"
                            fullWidth
                            label="Primary Color"
                            margin="normal"
                        />
                        <TextField
                            type="color"
                            fullWidth
                            label="Secondary Color"
                            margin="normal"
                        /> */}
                        </DialogContent>
                        <DialogActions>
                            <Button

                                // when clicked, save the brand and close the dialog and also set the logoPreview and smallLogoPreview to null
                                onClick={() => {
                                    handleUpdateBrand();
                                    setIsEditDialogOpen(false); 

                                }}
                                sx={{
                                    backgroundColor: 'var(--old-primary-color)',
                                    color: 'white !important',
                                    ":hover": {
                                        backgroundColor: 'var(--primary-color)',
                                    },
                                }}
                                variant="contained"
                            >
                                Update
                            </Button>
                            <Button
                                // onClick={() => {
                                //     setIsAddDialogOpen(false);
                                //     setLogoPreview(null);
                                //     setSmallLogoPreview(null);
                                // }}
                                onClick={() => setIsEditDialogOpen(false)}
                                variant="outlined"
                                sx={{
                                    color: 'red !important',
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}


               
            </Box>
        </Layout>
    );
}

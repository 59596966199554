import { Modal } from '@mui/material'
import React, { useContext } from 'react'

import authorized from './Images/authorized.png'
import authorizedFastwyre from './Images/authorizedFastwyre.png'
import logo from './Images/logo.png'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import CustomerApi from '../../../../../API/CustomerApi'
import { UserContext } from '../../../../../App'
import { MapContext } from '../..'
import { Fill, Icon, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'

const ChangeAccountStatusModal = ({ open, setOpen, customer,setReload }) => {

    const user = useContext(UserContext)
    const map = useContext(MapContext)

    const [accountStatus, setAccountStatus] = React.useState(customer?.accountStatus)
    const [rescheduleFrom, setRescheduleFrom] = React.useState(customer?.installDateTime?.split("T")[0])
    const [installTimeFrom, setInstallTimeFrom] = React.useState(customer?.installTimeFrom)
    const [installTimeTo, setInstallTimeTo] = React.useState(customer?.installTimeTo)
    const [selectedTimeRange, setSelectedTimeRange] = React.useState("")

    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const changeAccountStatus = async () => {
        if(accountStatus === customer?.accountStatus){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'This is the Current Account Status',
            })
            return
        }

        if(accountStatus === "Rescheduled" || accountStatus === "Scheduled"){
            if(!rescheduleFrom || !installTimeFrom || !installTimeTo){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please Fill All Fields',
                })
                return
            }
        }

        if(accountStatus === "Sold"){
            setRescheduleFrom("")
            setInstallTimeFrom("")
            setInstallTimeTo("")
        }

        const opt = await Swal.fire({
            icon:"info",
            title:"Are you sure?",
            text:"You are about to change the account status",
            showCancelButton:true,
            confirmButtonText:"Yes",
            cancelButtonText:"No"
        })

        if(!opt.isConfirmed){
            return
        }

        Swal.fire({
            icon:"info",
            title:"Please Wait",
            text:"Changing Account Status",
            showConfirmButton:false,
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            didOpen:()=>{
                Swal.showLoading()
            }
        })

        
        const req = {
            customerId : customer?.id,
            accountStatus: accountStatus,
            salesRepId: user.id,
            fromDate: null,
            toDate: null
        }
        if(accountStatus === "Rescheduled" || accountStatus === "Scheduled"){
            const [year, month, day] = rescheduleFrom.split('-').map(Number);
            const installDate = new Date(year, month - 1, day);
            let from = formatDateToISOString(new Date(installDate.setHours(installTimeFrom.split(':')[0], installTimeFrom.split(':')[1])))
            let to = formatDateToISOString(new Date(installDate.setHours(installTimeTo.split(':')[0], installTimeTo.split(':')[1])))
            req.fromDate = from
            req.toDate = to
        }

        const res = await CustomerApi.ChangeAccountStatus(req)

        if(res.status.success){
            Swal.fire({
                icon:"success",
                title:"Success",
                text:"Account Status Changed Successfully"
            })
            if(map.selectedFiberHouseFeature){
                let mapIconId = null
                if(accountStatus === "Scheduled"){
                    mapIconId = 9
                }
                else if(accountStatus === "Rescheduled"){
                    mapIconId = 9
                }
                else if(accountStatus === "Sold"){
                    mapIconId = 9
                }
                else if(accountStatus === "Canceled"){
                    mapIconId = 28
                }
                else if(accountStatus === "Completed"){
                    mapIconId = 10
                }


                const newStyle = new Style({
                    image: new Icon({
                        scale: 1.5, // Adjust this value to change the scale of the icon
                        src: "data:image/png;;base64," + map?.mapIcons?.find(icon => icon.id === mapIconId)?.image
                    }),
                });

                const circleStyle = new Style({
                    image: new CircleStyle({
                        radius: 15, // This is now in pixels
                        stroke: new Stroke({
                            color: "black", // Border color
                            width: 4, // Border width
                        }),
                        fill: new Fill({
                            color: "rgba(0, 0, 0, 0)", // Transparent fill
                        }),
                    }),
                });
                map.selectedFiberHouseFeature.setStyle([
                    newStyle, // This is the icon style
                    circleStyle, // This is the circle style
                ]);
                map.selectedFiberHouseFeature.values_.mapIconId = mapIconId
                setReload(prev => !prev)
                setOpen(false)
            }
        }
        else{
            Swal.fire({
                icon:"error",
                title:"Error",
                text:res.status.message
            })
        }

    }

    return (
        <Modal open={open} onClose={() => {
            setOpen(false)
        }}>

            <div className='custom_modal'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                {
                    customer?.fiberCompanyId === 2 ?
                        <img alt="Logo" style={{ width: "100%" }} src={authorized} className='logo' />
                        : customer?.fiberCompanyId === 9 ?
                            <img alt="Logo" style={{ width: "100%" }} src={authorizedFastwyre} className='logo' />
                            : <img alt='Logo' src={logo} style={{ width: "100%" }} className='logo' />
                }
                <h2 className='text-lg font-semibold'>Change Account Status</h2>

                <label className=''>Account Status</label>
                <select value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                    <option value='Scheduled'>Scheduled</option>
                    <option value='Canceled'>Canceled</option>
                    <option value='Completed'>Completed</option>
                    <option value="Rescheduled">Rescheduled</option>
                    <option value="Sold">Sold</option>
                </select>

                {/* <h1 >Install Date Time</h1> */}
                {
                    (accountStatus === "Rescheduled" || accountStatus === "Scheduled") &&
                    <>
                        <label>Install Date</label>
                        <input type="date" value={rescheduleFrom} onChange={(e) => {
                            setRescheduleFrom(e.target.value)
                        }
                        } />
                        <label>Intall Time Slot:</label>
                        <div className='time_slot'>
                            <input type='time' value={installTimeFrom} onChange={(e) => {
                                setInstallTimeFrom(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                            <p>To</p>
                            <input type='time' value={installTimeTo} onChange={(e) => {
                                setInstallTimeTo(e.target.value)
                                setSelectedTimeRange("")
                            }} />
                        </div>
                        <p className='or'>OR</p>
                        <div className='am_pm'>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("AM");
                                    setInstallTimeFrom("08:00")
                                    setInstallTimeTo("12:00")
                                }
                            } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM</p>
                            <p onClick={
                                () => {
                                    setSelectedTimeRange("PM");
                                    setInstallTimeFrom("12:00")
                                    setInstallTimeTo("16:00")
                                }
                            } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM</p>
                        </div>
                    </>
                }

                <p className='note'>
                    {
                        accountStatus === "Sold"?
                        "Note: If you change the account status to Sold, the Install Dates will be reset."
                        :null
                    }
                </p>
                <button
                    onClick={() => {
                        //    submitReschedule()
                        changeAccountStatus()
                    }
                    }>Change Account Status</button>
            </div>
        </Modal>
    )
}

export default ChangeAccountStatusModal
"use client";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBlog } from "../../api/blogApi";
import HeaderBlock from "./blocks/HeaderBlock";
import ParagraphBlock from "./blocks/ParagraphBlock";
import ListBlock from "./blocks/ListBlock";
import ChecklistBlock from "./blocks/ChecklistBlock";
import ImageBlock from "./blocks/ImageBlock";
import TableBlock from "./blocks/TableBlock";
import PublicLayout from "../../../../../Layouts/PublicLayout";

function BlogDetails() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadBlog() {
      try {
        const response = await getBlog(id);
        setBlog(response.data);
      } catch (error) {
        console.error("Error loading blog:", error);
      } finally {
        setIsLoading(false);
      }
    }
    loadBlog();
  }, [id]);

  if (isLoading) {
    return (
      <PublicLayout>
        <div className="flex items-center justify-center min-h-screen"></div>
      </PublicLayout>
    );
  }

  if (!blog) {
    return (
      <PublicLayout>
        <div className="flex items-center justify-center min-h-screen">
          <p className="text-xl text-gray-400">Blog not found.</p>
        </div>
      </PublicLayout>
    );
  }

  const { title, description, blogBlocks: blocks } = blog;

  return (
    <PublicLayout>
      <div className="p-4">
        <article className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8 py-12 space-y-8 bg-[#68656c78] rounded-lg mt-10">
          <header className="space-y-4">
            <h1 className="text-xl sm:text-3xl md:text-4xl capitalize font-bold tracking-tight text-white">
              {title}
            </h1>
            {description && (
              <p className="text-xl text-gray-300">{description}</p>
            )}
          </header>

          <div className="space-y-8 text-gray-200">
            {blocks.map((block) => {
              switch (block.type) {
                case "header":
                  return <HeaderBlock key={block.id} data={block.data} />;
                case "paragraph":
                  return <ParagraphBlock key={block.id} data={block.data} />;
                case "list":
                  return <ListBlock key={block.id} data={block.data} />;
                case "checklist":
                  return <ChecklistBlock key={block.id} data={block.data} />;
                case "image":
                  return <ImageBlock key={block.id} data={block.data} />;
                case "table":
                  return <TableBlock key={block.id} data={block.data} />;
                default:
                  return null;
              }
            })}
          </div>
        </article>
      </div>
    </PublicLayout>
  );
}

export default BlogDetails;

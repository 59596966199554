export default class SearchLeadsForContactCenterReq {
    constructor() {
        this.LeadId = null;
        this.SalesOrgId = null;
        this.SalesRepId = null;
        this.FidiumCustomerId = null;
        this.Name = null;
        this.Address = null;
        this.Phone = null;
        this.CustomersOnly = null;
        this.MapIconId = null;
        this.SearchString = null;
        this.SortBy = null;
        this.IsVerified = null;
        this.PagingParams = {
            PageNumber: null,
            PageSize: null
        }
        this.FiberCompanyIds = null;
    }
}

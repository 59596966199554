import React from "react";

export default function SMSTemplate({ selectedTemplate,setSelectedTemplate, enableEditing,textBody, setTextBody }) {
  return (
    <div className="space-y-2">
      <label className="text-sm text-gray-500">SMS Description</label>
      <textarea
        className="w-full min-h-[120px] p-3 border rounded-lg focus:outline-none disabled:bg-gray-50 disabled:text-gray-500"
        placeholder="Hi [First Name], don't miss our exclusive 2 GIG plan at $69.99/month! Unlimited data, no contracts, and free installation. Call me at [Your Number] or reply now to sign up!"
        disabled={!enableEditing}
        value={selectedTemplate?.textBody ?? textBody}
        onChange={(e) => {
          setTextBody(e.target.value);
          setSelectedTemplate({ ...selectedTemplate, textBody: e.target.value })}}
      />
    </div>
  );
}

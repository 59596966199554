function ImageBlock({ data }) {
  const { file } = JSON.parse(data);

  return (
    <div className="my-4">
      <img
        src={file.url}
        alt={file.name || "Image"}
        className="w-full max-w-md mx-auto rounded-lg shadow-md"
      />
    </div>
  );
}

export default ImageBlock;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MapContext as MP } from "..";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import BatchCreateFiberHousesReq from "../../../../Requests/FiberHouse/BatchCreateFiberHousesReq";
import CreateFiberHouseReq from "../../../../Requests/FiberHouse/CreateFiberHouseReq";
import Button from "../../../../components/Button";
import OrganizationSelector from "../../../../components/OrganizationSelector";
import Papa from "papaparse";
import Modify from "ol/interaction/Modify";
import Collection from "ol/Collection";
import * as XLSX from "xlsx";
import MapHelper from "../MapHelper";
import FiberCompanyApi from "../../../../API/FiberCompanyApi";
import FileUploadWizard from "../../../SuperAdmin/MappingFilter/FileUploadWizard";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import CustomerApi from "../../../../API/CustomerApi";

const CreateUsingFile = ({ user }) => {
  const [selectedMapIcon, setSelectedMapIcon] = useState(null);

  const [file, setFile] = useState(null);
  const [mapStrings, setMapStrings] = useState([]);
  const [mapStringObjects, setMapStringObjects] = useState([]);
  const [mapStringObjectsGood, setMapStringObjectsGoood] = useState([]);
  const [mapStringObjectsBad, setMapStringObjectsBad] = useState([]);
  const [
    mapStringObjectsWithServicableArea,
    setMapStringObjectsWithServicableArea,
  ] = useState([]);
  const [housesWithSameCoordinates, setHousesWithSameCoordinates] = useState(
    []
  );
  const [newHousesLayer, setNewHousesLayer] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [salesOrgId, setSalesOrgId] = useState();
  const [customAttributes, setCustomAttributes] = useState([]);
  // const [coordinatesIncluded, setCoordinatesIncluded] = useState(false)
  const [excelHouseObjects, setExcelHouseObjects] = useState(null);

  const { mapObject, setIndex, mapIcons, searchFiberHouses } =
    React.useContext(MP);
  const helper = new MapHelper(mapObject);
  const [fiberCompany, setFiberCompany] = React.useState(null);

  const mapping = [
    { header: "address", Datatype: "string", Required: true },
    { header: "State", Datatype: "string", Required: false },
    { header: "City", Datatype: "string", Required: false },
    { header: "Zip Code", Datatype: "string", Required: false },
    { header: "Unit", Datatype: "string", Required: false },
    { header: "Service State", Datatype: "string", Required: false },
    { header: "Map Icon Id", Datatype: "string", Required: false },
    { header: "Map String", Datatype: "string", Required: false },
    // temp
    { header: "Latitude", Datatype: "string", Required: false },
    { header: "Longitude", Datatype: "string", Required: false },
    { header: "UseLocationID", Datatype: "string", Required: false },
    { header: "CreatedOn", Datatype: "string", Required: false },
    { header: "PreDirectional", Datatype: "string", Required: false },
    { header: "PostalCodePlus4", Datatype: "string", Required: false },
    { header: "LocationComment", Datatype: "string", Required: false },
    { header: "ProjectOldId", Datatype: "string", Required: false },
    { header: "Cabinet", Datatype: "string", Required: false },
    { header: "CabinetOld", Datatype: "string", Required: false },
    { header: "taxAreaCode", Datatype: "string", Required: false },
    { header: "Zone", Datatype: "string", Required: false },
    { header: "Region", Datatype: "string", Required: false },
    { header: "Rooftop", Datatype: "string", Required: false },
    { header: "CensusStateCode", Datatype: "string", Required: false },
    { header: "CensusCountyCode", Datatype: "string", Required: false },
    { header: "CensusTract", Datatype: "string", Required: false },
    { header: "CensusBlock", Datatype: "string", Required: false },
    { header: "Servicable", Datatype: "string", Required: false },
    { header: "Data", Datatype: "string", Required: false },
    { header: "CATV", Datatype: "string", Required: false },
    { header: "CATVDigital", Datatype: "string", Required: false },
    { header: "FixedWireless", Datatype: "string", Required: false },
    { header: "Fiber", Datatype: "string", Required: false },
    { header: "DefaultNetworkDelivery", Datatype: "string", Required: false },
    { header: "HasActiveAccount", Datatype: "string", Required: false },
    { header: "ExternalID", Datatype: "string", Required: false },
    { header: "VetroCircuitID", Datatype: "string", Required: false },
    { header: "ModifiedBy", Datatype: "string", Required: false },
    { header: "PremiseType", Datatype: "string", Required: false },
  ];

  const searchCustomFields = async () => {
    const req = {
      id: null,
      name: null,
      dataType: null,
      for: "Lead",
    };

    const res = await CustomerApi.SearchCustomAttributes(req);
    if (res?.status?.success) {
      console.log("CUSTOM FIELDS", res.data);
      const customFields = res.data.map((field) => {
        return {
          header: field.name,
          Datatype: field.dataType,
          Required: false,
          id: field.id,
          custom: true,
        };
      });

      setCustomAttributes(customFields);
    }
  };

  useEffect(() => {
    searchCustomFields();
  }, []);

  const CreateFiberHouses = async () => {
    if (
      !mapStringObjectsWithServicableArea ||
      mapStringObjectsWithServicableArea.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Upload a File",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    if (selectedMapIcon === null) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select a Map Icon",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (fiberCompany == null) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select a Fiber Company",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Creating Fiber Houses",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let orgId = null;
    if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
      orgId = salesOrgId;
    } else {
      orgId = user.salesOrgId;
    }
    // if (orgId == null) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Organization is required',
    //         text: 'Please select Organization',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: true,
    //         didOpen: () => {
    //             Swal.hideLoading()
    //         }
    //     })
    //     return;
    // }

    var list = [];

    mapStringObjectsWithServicableArea.forEach((mapStringObject, index) => {
      const req = {
        ...mapStringObject,
        coordinate: {
          latidude: null,
          longitude: null,
        },
      };

      req.lead = {};
      req.createdById = user.id;
      req.salesOrgId = orgId;
      // req.country = mapStringObject.country
      req.state = mapStringObject.state;
      req.city = mapStringObject.city;
      req.address = mapStringObject.address;
      req.zipCode = String(mapStringObject.zipCode);
      // req.notes = mapStringObject.notes
      // req.lead.name = metaData[index].firstName + " " + metaData[index].lastName
      // req.lead.email = metaData[index].email
      // req.lead.phone = metaData[index].phone
      // req.lead.notes = metaData[index].notes
      // req.mapIconId = metaData[index].mapIconId
      req.servicableAreaId = mapStringObject.servicableArea?.id;
      req.coordinate.latitude = mapStringObject.coordinates[1];
      req.coordinate.longitude = mapStringObject.coordinates[0];
      req.coordinate.popUpColor = "#000000";
      req.mapIconId = selectedMapIcon.id;
      req.mapString = mapStringObject.mapString;
      req.unit = mapStringObject.unit;
      req.customAttributeIds = mapStringObject.customFields.map((field) => {
        return Object.keys(field)[0];
      });
      req.customAttributeValues = mapStringObject.customFields.map((field) => {
        return Object.values(field)[0];
      });

      list.push(req);
    });

    let req = new BatchCreateFiberHousesReq();
    req.fiberCompanyId = fiberCompany;
    req.fiberHouses = list;
    console.log(req);

    var response = await FiberHouseApi.BatchCreateFiberHouse(req);
    console.log(response);
    if (response?.status?.success) {
      Swal.fire({
        title: "Fiber Houses Created",
        text: response?.status?.message,
        icon: "success",
        allowEnterKey: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });

      searchFiberHouses();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response?.status?.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const fileChange = () => {
    // parse a list of strings into an array from a txt file with each string seperated by a new line
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      if (file.name.endsWith(".csv")) {
        // Parse CSV file assuming it has a column named 'ADDRESS'
        const { data: parsedDataWithColumn } = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
          delimiter: ",",
        });
        const addressesWithColumn = parsedDataWithColumn.map((row) => ({
          mapString: row.ADDRESS,
        }));
        const meta = [];
        parsedDataWithColumn.map((row) => {
          meta.push({
            mapIconId: row.MapIconId,
            firstName: row.FirstName,
            lastName: row.LastName,
            email: row.Email,
            phone: row.Phone,
            notes: row.Notes,
          });
        });

        // Check if 'ADDRESS' column exists and contains data
        const addressesWithColumnExist =
          addressesWithColumn &&
          addressesWithColumn.length > 0 &&
          addressesWithColumn[0].mapString;
        const mapIconExist = meta && meta.length > 0 && meta[0];

        if (addressesWithColumnExist) {
          // Extract address column if exists
          setMapStrings(addressesWithColumn);
          if (mapIconExist) {
            setMetaData(meta);
          }
          console.log("ADDRESSES (with column):", addressesWithColumn);
        } else {
          // Fallback: Treat each line as an address
          var lines = text
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line !== "");
          lines = lines.map((line) => ({
            mapString: line.replace(/['"]+/g, ""),
          }));
          setMapStrings(lines);
          console.log("ADDRESSES (no column):", lines);
        }
      } else if (file.name.endsWith(".txt")) {
        var lines = [];
        lines = text.split("\n").map((line) => line.trim());
        // Remove empty lines
        lines = lines.filter((line) => line !== "");
        // Remove quotes from each line and map to objects with the 'mapString' key
        lines = lines.map((line) => ({
          mapString: line.replace(/['"]+/g, ""),
        }));
        setMapStrings(lines);
        console.log("LINES", lines);
      } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        var allHousesList = [];
        let lines = [];
        workbook.SheetNames.forEach((sheetName) => {
          console.log(`Sheet Name: ${sheetName}`);
          const worksheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Headers
          const headers = sheetData[0];
          console.log("Headers:");
          console.table(headers);

          // Rows
          const rows = sheetData.slice(1);
          console.log("Rows:");
          console.table(rows);

          //check if longitude and latitude columns exist
          if (!headers.includes("Longitude") || !headers.includes("Latitude")) {
            //if they dont exist, use the address column to get the coordinates
            //push the address to the mapStrings array

            rows.forEach((row) => {
              // let address = row[headers.indexOf('Address')];
              let address = "";
              if (row[headers.indexOf("FullServiceAddress")]) {
                address = row[headers.indexOf("FullServiceAddress")];
              } else if (row[headers.indexOf("Address")]) {
                address = row[headers.indexOf("Address")];
              } else if (row[headers.indexOf("Service Address")]) {
                address = row[headers.indexOf("Service Address")];
              }
              let city = "";
              if (row[headers.indexOf("City")]) {
                city = row[headers.indexOf("City")];
              } else if (row[headers.indexOf("Community")]) {
                city = row[headers.indexOf("Community")];
              }
              let state = "";
              if (row[headers.indexOf("State")]) {
                state = row[headers.indexOf("State")];
              } else if (row[headers.indexOf("911 State")]) {
                state = row[headers.indexOf("911 State")];
              }
              let zipCode = "";
              if (row[headers.indexOf("Zip")]) {
                zipCode = row[headers.indexOf("Zip")];
              } else if (row[headers.indexOf("911 Zip")]) {
                zipCode = row[headers.indexOf("911 Zip")];
              }
              let unit = "";
              if (row[headers.indexOf("Serv Address Unit")]) {
                unit = row[headers.indexOf("Serv Address Unit")];
              }
              let serviceState = "";
              if (row[headers.indexOf("Service Address Status")]) {
                serviceState = row[headers.indexOf("Service Address Status")];
              }

              lines.push({
                mapString: address + " " + city + " " + state,
                address: address + " " + city + " " + state,
                city: city,
                state: state,
                zipCode: zipCode,
                unit: unit,
                serviceState: serviceState,
              });
            });

            console.log("NO LONGITUDE OR LATITUDE COLUMNS");
          } else {
            rows.forEach((row) => {
              let address = row[headers.indexOf("Address 1")];
              if (row[headers.indexOf("Address 2")]) {
                address += " " + row[headers.indexOf("Address 2")];
              }
              var house = {
                sheetName: sheetName,
                address: address,
                city: row[headers.indexOf("City")],
                state: row[headers.indexOf("State")],
                zipCode: row[headers.indexOf("Zip")],
                // country: row[headers.indexOf('Country')],
                latidude: row[headers.indexOf("Latitude")],
                longitude: row[headers.indexOf("Longitude")],
                serviceState: row[headers.indexOf("Service Address Status")],
              };

              allHousesList.push(house);
            });

            console.log("ALL HOUSES LIST", allHousesList);
          }
        });

        if (lines.length > 0) {
          setMapStrings(lines);
        } else if (allHousesList.length > 0) {
          setExcelHouseObjects(allHousesList);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invalid File Type",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: true,
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
    };
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  };

  // useEffect(() => {
  //     if (file) {
  //         fileChange()
  //     }
  // }, [file])

  const mapStringsChange = async (data) => {
    Swal.fire({
      icon: "info",
      title: "Fetching Coordinates",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const list = [];

    for (let i = 0; i < data.length; i++) {
      var location = data[i];
      try {
        Swal.update({
          title: "Fetching Coordinates",
          html: `Please wait...<br>Progress: ${i + 1}/${data.length}`,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // if it's a custom field, store it in the metaData array
        let customFields = [];

        Object.keys(location).forEach((key) => {
          if (customAttributes.some((field) => field.header === key)) {
            let ca = customAttributes.find((field => field.header === key));
            customFields.push({
              [ca.id]: location[key],
            });
          }
        });

        let mapString =
          location.address + " " + location.City + " " + location.State;
        if (location.Latitude && location.Longitude) {
          list.push({
            ...location,
            mapString: mapString,
            address: location.address,
            coordinates: [location.Longitude, location.Latitude],
            serviceState: location.serviceState,
            city: location.City,
            state: location.State,
            zipCode: location["Zip Code"],
            unit: location.Unit,
            index: i,
            customFields: customFields,
          });
        } else {
          var details = await helper.searchLocationDetails(mapString);
          list.push({
            ...location,
            mapString: mapString,
            address: location.address,
            coordinates: details.coordinates,
            serviceState: location.serviceState,
            city: location.city,
            state: location.state,
            zipCode: location.zipCode,
            unit: location.unit,
            index: i,
            customFields: customFields,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }

    console.log("LIST", list);
    setMapStringObjects(list);

    Swal.fire({
      icon: "success",
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      showConfirmButton: true,
      timer: 1500,
      didOpen: () => {
        Swal.hideLoading();
      },
    });
  };

  const snapToLocation = (fiberHouse) => {
    let lonlat = [
      fiberHouse.coordinates.longitude,
      fiberHouse.coordinates.latitude,
    ];
    mapObject.getView().setCenter(fromLonLat(lonlat));
    mapObject.getView().setZoom(21);
  };

  useEffect(() => {
    console.log("MAP STRINGS", mapStrings);
    if (mapStrings && mapStrings.length > 0) {
      mapStringsChange();
    }
  }, [mapStrings]);

  const excelHouseObjectsChange = () => {
    const list = [];

    if (excelHouseObjects == null || excelHouseObjects?.length === 0) {
      return;
    }

    excelHouseObjects.forEach((location, index) => {
      list.push({
        mapString: location.address,
        coordinates: [location.longitude, location.latidude],
        index: index,
      });
    });

    //check if any entries are undefined
    if (list.some((entry) => entry.coordinates === null)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Some addresses could not be found",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    if (list.some((entry) => entry === null)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Some addresses could not be found ENTRYY",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    setMapStringObjects(list);
  };

  useEffect(() => {
    excelHouseObjectsChange();
  }, [excelHouseObjects]);

  const showFiberHousesOnMap = () => {
    const oldZoom = mapObject.getView().getZoom();
    if (newHousesLayer) {
      mapObject.removeLayer(newHousesLayer);
    }
    const vectorSource = new VectorSource();
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const markerStyle = new Style({
      image: new Icon({
        src: "https://openlayers.org/en/latest/examples/data/icon.png", // Blue-dot icon
        scale: 0.9, // Adjust the scale as needed
        zIndex: 10000000, // Make sure the icon stays on top of other map features
      }),
    });

    mapObject.addLayer(vectorLayer);
    mapObject.getView().setZoom(oldZoom);
    const features = new Collection();

    mapStringObjectsGood.forEach((ms) => {
      const point = new Point(fromLonLat(ms.coordinates));
      const feature = new Feature(point);
      feature.set("mapString", ms.mapString);
      feature.set("index", ms.index);
      feature.set("coordinates", ms.coordinates);
      feature.set("bulk_mark", 1);
      feature.setStyle(markerStyle);
      vectorSource.addFeature(feature);
      features.push(feature);
    });

    const modify = new Modify({ features: features });
    mapObject.addInteraction(modify);
    modify.on("modifyend", function (e) {
      const features = e.features.getArray();
      console.log("MODIFY END", features);
      features.forEach((feature) => {
        const coordinates = feature.getGeometry().getCoordinates();
        const index = feature.get("index");
        setMapStringObjectsGoood((prevList) => {
          return prevList.map((ms) => {
            if (ms.index === index) {
              return { ...ms, coordinates: toLonLat(coordinates) };
            }
            return ms;
          });
        });
      });
    });
    vectorLayer.setZIndex(1000);

    if (!newHousesLayer) {
      mapObject.getView().fit(vectorSource.getExtent());
    }
    setNewHousesLayer(vectorLayer);

    mapObject.on("click", function (evt) {
      const feature = mapObject.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature?.get("bulk_mark")) {
        Swal.fire({
          title: "Popup Title",
          html: `<div><p>${feature.get("mapString")}</p></div>`,
          footer: '<button id="customButton">Remove House</button>',
          confirmButtonText: "OK",
        });

        document
          .getElementById("customButton")
          .addEventListener("click", () => {
            removeEntryFromMapStringObjects(feature.get("index"));
          });
      }
    });
  };

  const removeEntryFromMapStringObjects = (index) => {
    setMapStringObjectsGoood((prevList) => {
      return prevList.filter((ms) => ms.index !== index);
    });

    setMapStringObjectsWithServicableArea((prevList) => {
      return prevList.filter((ms) => ms.index !== index);
    });
  };

  const filterMapStringObjects = () => {
    let goodList = [];
    let badList = [];
    mapStringObjects.forEach((ms) => {
      if (ms.coordinates) {
        goodList.push(ms);
      } else {
        badList.push(ms);
      }
    });
    setMapStringObjectsGoood(goodList);
    setMapStringObjectsBad(badList);
  };

  const getHousesWithSameCoordinates = () => {
    // let list = [];
    let coordinates = [];
    mapStringObjectsGood.forEach((ms) => {
      coordinates.push(ms.coordinates);
    });
    let duplicates = [];
    let temp = coordinates;

    for (let i = 0; i < temp.length; i++) {
      for (let j = i + 1; j < temp.length; j++) {
        if (temp[i][0] === temp[j][0] && temp[i][1] === temp[j][1]) {
          setMapStringObjectsGoood((prevList) => {
            return prevList.map((ms) => {
              if (
                temp == null ||
                temp[j] == null ||
                ms == null ||
                ms.coordinates == null
              ) {
                return ms;
              }
              if (
                ms?.coordinates[0] === temp[j][0] &&
                ms?.coordinates[1] === temp[j][1]
              ) {
                return { ...ms, coordinates: temp[j], duplicate: true };
              }
              return ms;
            });
          });

          duplicates.push(temp[j]);
          temp.splice(j, 1);
          j--;
        }
      }
    }

    setHousesWithSameCoordinates(duplicates);
  };

  const downloadBadAddresses = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    mapStringObjectsBad.forEach((ms) => {
      csvContent += ms.mapString + "\n";
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "badAddresses.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  useEffect(() => {
    if (mapStringObjects && mapStringObjects.length > 0) {
      console.log("MAP STRING OBJECTS", mapStringObjects);
      filterMapStringObjects();
    }
  }, [mapStringObjects]);

  useEffect(() => {
    if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
      console.log("MAP STRING OBJECTS GOOD", mapStringObjectsGood);
      showFiberHousesOnMap();
      UpdateMapStringObjectsWithServicableArea();
    }
  }, [mapStringObjectsGood]);

  useEffect(() => {
    if (mapStringObjectsGood && mapStringObjectsGood.length > 0) {
      getHousesWithSameCoordinates();
    }
  }, [mapStringObjectsBad]);

  const UpdateMapStringObjectsWithServicableArea = () => {
    let updatedList = [];
    mapStringObjectsGood.forEach((fa) => {
      let newListObject = fa;
      updatedList.push(newListObject);
    });

    setMapStringObjectsWithServicableArea(updatedList);
  };

  useEffect(() => {
    return () => {
      if (newHousesLayer) {
        mapObject.removeLayer(newHousesLayer);
      }
    };
  }, []);

  return (
    <div className="FiberHousefileDiv">
      <div className="TitleDiv">
        <h3>Bulk Create Fiber Houses</h3>
        <button
          onClick={() => setIndex(2)}
          className="uppercase text-[#2761D0] "
        >
          Create Manually
        </button>
      </div>
      <div className="FormDiv">
        <div className="w-full mx-auto">
          <FileUploadWizard
            showLayout={false}
            mapping={mapping.concat(customAttributes)}
            onFinish={mapStringsChange}
          />
        </div>
        {user?.userType === "SuperAdmin" && (
          <div className="selector">
            <FiberCompanySelector
              className={"w-full"}
              setUser={setFiberCompany}
              placeholder="Select Fiber Company"
            />
          </div>
        )}
        {(user?.userType === "SuperAdmin" || user?.userType === "Admin") && (
          <OrganizationSelector
            setUser={setSalesOrgId}
            placeholder="Select Organization"
          />
        )}
        <div className="MapIconsDiv">
          {mapIcons
            .filter((x) => x.iconType === null)
            .map((assignedMapIcons, index) => {
              return (
                <div
                  className="MapIconDiv"
                  key={index}
                  style={{
                    border:
                      selectedMapIcon?.id === assignedMapIcons.id
                        ? "2px solid #2761D0"
                        : "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedMapIcon(assignedMapIcons)}
                >
                  <div className="MapIconImageDiv">
                    <img
                      src={"data:image/png;base64," + assignedMapIcons.image}
                      alt="Map Icon"
                    />
                  </div>
                  <p className="MapIconName">{assignedMapIcons.abbreviation}</p>
                  <div className="MapIconButtonsDiv"></div>
                </div>
              );
            })}
        </div>
        <Button onClick={CreateFiberHouses}>Upload</Button>
        <div className="StatusDiv">
          {mapStringObjectsWithServicableArea != null &&
            mapStringObjectsWithServicableArea.length > 0 && (
              <div className="MapStringObjectDiv">
                <div className="MapStringObjectMapStringDiv">
                  <p className="MapStringObjectMapString">
                    {mapStringObjectsWithServicableArea.length} Houses Detected
                    from {mapStringObjects.length} Addresses and{" "}
                    {mapStrings.length} Lines of Data
                  </p>
                  <p className="MapStringObjectMapString">
                    {mapStringObjectsGood.length} Good Addresses
                  </p>
                  <p className="MapStringObjectMapString">
                    {housesWithSameCoordinates.length} Duplicate Coordinates
                  </p>
                  <p
                    className="MapStringObjectMapString"
                    style={{
                      color: mapStringObjectsBad.length > 0 ? "red" : "green",
                    }}
                  >
                    {mapStringObjectsBad.length} Bad Addresses
                    <button
                      onClick={downloadBadAddresses}
                      className="DownloadButton"
                    >
                      Download
                    </button>
                  </p>
                </div>
                <div className="MapStringObjectServicableAreaDiv">
                  <p className="MapStringObjectServicableArea">
                    {
                      mapStringObjectsWithServicableArea.filter(
                        (fa) => fa.servicableArea !== null
                      ).length
                    }{" "}
                    Inside Serviceable Areas
                  </p>
                </div>
              </div>
            )}
          {mapStringObjectsWithServicableArea != null &&
          mapStringObjectsWithServicableArea.length > 0 ? (
            mapStringObjectsWithServicableArea.map((mapStringObject, index) => {
              return (
                <div key={index} className="MapStringObjectDiv">
                  <div className="MapStringObjectMapStringDiv">
                    <p className="MapStringObjectMapString">
                      {mapStringObject.mapString}
                    </p>
                    <p
                      className="MapStringObjectServicableArea"
                      style={{
                        color: mapStringObject.servicableArea
                          ? mapStringObject.servicableArea.color
                          : "black",
                      }}
                    >
                      {mapStringObject.servicableArea &&
                        mapStringObject.servicableArea.name}
                    </p>
                    {metaData &&
                      metaData.length > 0 &&
                      metaData[index].firstName && (
                        <p>First Name : {metaData[index].firstName}</p>
                      )}
                    {metaData &&
                      metaData.length > 0 &&
                      metaData[index].lastName && (
                        <p>Last Name : {metaData[index].lastName}</p>
                      )}
                    {metaData &&
                      metaData.length > 0 &&
                      metaData[index].email && (
                        <p>Email : {metaData[index].email}</p>
                      )}
                    {metaData &&
                      metaData.length > 0 &&
                      metaData[index].phone && (
                        <p>Phone : {metaData[index].phone}</p>
                      )}
                    {metaData &&
                      metaData.length > 0 &&
                      metaData[index].notes && (
                        <p>Notes : {metaData[index].notes}</p>
                      )}

                    {/* {
                                            //show if house is duplicate
                                            housesWithSameCoordinates.filter(house => house.coordinates === mapStringObject.coordinates).length > 1 &&
                                            <p className='MapStringObjectMapString' style={{ color: "red" }}>
                                                Duplicate
                                            </p>
                                        } */}
                  </div>
                  <div className="MapStringObjectServicableAreaDiv">
                    <button
                      onClick={() => {
                        snapToLocation({
                          coordinates: {
                            latitude: mapStringObject.coordinates[1],
                            longitude: mapStringObject.coordinates[0],
                          },
                        });
                      }}
                      style={{ width: "100%" }}
                    >
                      Snap To Location
                    </button>
                    <button
                      style={{ width: "100%" }}
                      onClick={() => removeEntryFromMapStringObjects(index)}
                      className="remove"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="MapStringObjectDiv">
              <div className="MapStringObjectMapStringDiv">
                <p className="MapStringObjectMapString">No Map Strings</p>
              </div>
              <div className="MapStringObjectServicableAreaDiv">
                <p className="MapStringObjectServicableArea">
                  No Servicable Area
                </p>
              </div>
            </div>
          )}
          {mapStringObjectsBad != null &&
            mapStringObjectsBad.length > 0 &&
            mapStringObjectsBad.map((mapStringObject, index) => {
              return (
                <div key={index} className="MapStringObjectDiv">
                  <div className="MapStringObjectMapStringDiv">
                    <p className="MapStringObjectMapString">
                      {mapStringObject.mapString}
                    </p>
                  </div>
                  <div className="MapStringObjectServicableAreaDiv">
                    <p
                      className="MapStringObjectServicableArea"
                      style={{ color: "red" }}
                    >
                      Bad Address
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CreateUsingFile;

import React, { useEffect, useState } from "react";
import authorized from "./Images/authorized.png";
import authorizedFastwyre from "./Images/authorizedFastwyre.png";
import { Modal } from "@mui/material";
import { IoCloseCircle, IoTimeOutline } from "react-icons/io5";
import FiberPlanSelector from "../../../../../components/FiberPlanSelector";
import Swal from "sweetalert2";
import UpdateCustomerInstallDateReq from "../../../../../Requests/Customer/UpdateCustomerInstallDateReq";
import CustomerApi from "../../../../../API/CustomerApi";
import CreateRescheduleFormReq from "../../../../../Requests/Customer/CreateRescheduleFormReq";

const ScheduleForm = ({
  open,
  setOpen,
  lead,
  fidiumCustomer,
  setReload,
  fiberCompany,
}) => {
  const [rescheduleFrom, setRescheduleFrom] = useState("");
  const [secondReference, setSecondReference] = useState("");
  const [installTimeFrom, setInstallTimeFrom] = useState("");
  const [installTimeTo, setInstallTimeTo] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeRadio, setPromoCodeRadio] = useState(null);
  const [installPlan, setInstalltPlan] = useState(fidiumCustomer?.fiberPlanId);
  const [orderNumber, setOrderNumber] = useState("");
  const [slotSeledcted, setSlotSelected] = useState("");
  const [notes, setNotes] = useState("");
  const [daySelected, setDaySelected] = useState("");
  const [paymentInfoCollected, setPaymentInfoCollected] = useState(false);
  const [uploadBooster, setUploadBooster] = useState(fidiumCustomer?.uploadBooster);
  const daysOut = fiberCompany?.daysOut ?? 4;

  const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };
  const updateInstallDate = async () => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Updating Install Date",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        container: "custom-swal",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    // the day should be the same in the install date and install date to and the time in the install date should be less than the time in the install date to
    if (!rescheduleFrom) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Date",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!installTimeFrom || !installTimeTo) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Time",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!installPlan) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a plan",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if(fidiumCustomer?.fiberCompanyId === 2 && uploadBooster === null && lead.leadType==="HFC Plus"){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select if upload booster was applied",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (
      fidiumCustomer?.fiberCompanyId === 10 ||
      fidiumCustomer?.fiberCompanyId === 11
    ) {
      if (!orderNumber) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter order number",
          customClass: {
            container: "custom-swal",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        return;
      }
    }

    const req = new UpdateCustomerInstallDateReq();
    req.customerId = fidiumCustomer.id;
    const [year, month, day] = rescheduleFrom.split("-").map(Number);


    let secondPreference = null;

    if (secondReference) {
      const [year2, month2, day2] = secondReference.split("-").map(Number);
      secondPreference = new Date(year2, month2 - 1, day2);

      let second = formatDateToISOString(
        new Date(
          secondPreference.setHours(
            installTimeFrom.split(":")[0],
            installTimeFrom.split(":")[1]
          )
        )
      );
      req.installDateTimeSecondPrefrence = second;
    }

    // Create a new date object with the desired year, month, and day
    const installDate = new Date(year, month - 1, day);
    let from = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeFrom.split(":")[0],
          installTimeFrom.split(":")[1]
        )
      )
    );
    let to = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeTo.split(":")[0],
          installTimeTo.split(":")[1]
        )
      )
    );

    req.installDateTime = from;
    req.installDateTimeTo = to;
    req.promo = promoCode + "\n" + promoCodeRadio;
    req.paymentInfoCollected = paymentInfoCollected;
    req.orderNumber = orderNumber;
    req.uploadBooster = uploadBooster;
    const res = await CustomerApi.EditCustomerInstallDate(req);
    if (res?.status?.success) {
      setOpen(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Install Date Updated Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
      setReload((prev) => {
        return !prev;
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  const submitReschedule = async () => {
    setOpen(false);
    Swal.fire({
      icon: "info",
      title: "Please Wait",
      text: "Submitting Reschedule Request",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    if (!rescheduleFrom && !daySelected) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Date Or Day",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!installTimeFrom || !installTimeTo) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select Install Time",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!lead?.fidiumCustomer) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There is no customer associated with this lead",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new CreateRescheduleFormReq();
    req.FidiumCustomerId = fidiumCustomer.id;
    console.log(rescheduleFrom);
    const [year, month, day] = rescheduleFrom.split("-").map(Number);
    if (daySelected) {
      req.rescheduleDay = daySelected;
    }

    // Create a new date object with the desired year, month, and day
    const installDate = new Date(year, month - 1, day);

    // resshcedule from should be atleast 7 days from the current date
    const minDate = new Date();
    minDate.setDate(
      minDate.getDate() + (fidiumCustomer?.fiberCompanyId === 9 ? 13 : 6)
    );
    let selectedFromDate = new Date(
      installDate.setHours(
        installTimeFrom.split(":")[0],
        installTimeFrom.split(":")[1]
      )
    );

    if (selectedFromDate < minDate) {
      Swal.fire({
        icon: "error",
        title: "Invalid Date",
        text: `Please select a date that is at least ${
          fidiumCustomer?.fiberCompanyId === 9 ? 14 : 7
        } days from today.`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    let from = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeFrom.split(":")[0],
          installTimeFrom.split(":")[1]
        )
      )
    );
    let to = formatDateToISOString(
      new Date(
        installDate.setHours(
          installTimeTo.split(":")[0],
          installTimeTo.split(":")[1]
        )
      )
    );
    if (!daySelected) {
      req.NewInstallDate = from;
      req.NewInstallDateTo = to;
    }
    req.notes = notes;
    const res = await CustomerApi.RescheduleForm(req);
    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Reschedule Request Submitted Successfully",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          container: "custom-swal",
        },
        willClose: () => {
          Swal.hideLoading();
        },
      });
      setReload((prev) => {
        return !prev;
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res?.status?.message,
      });
    }
  };

  const getMinDate = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 7);
    return minDate.toISOString().split("T")[0];
  };

  const minDateToday = () => {
    const minDate = new Date();
    return minDate.toISOString().split("T")[0];
  };

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      {lead?.fidiumCustomer?.installDateTime ? (
        <div
          style={{
            justifyContent: "initial",
          }}
          className="custom_modal"
        >
          <IoCloseCircle
            onClick={() => {
              setOpen(false);
            }}
            size={30}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            color="#2761d0"
          />
          {fidiumCustomer?.fiberCompanyId === 2 && (
            <img alt="Logo" src={authorized} className="logo" />
          )}
          {fidiumCustomer?.fiberCompanyId === 9 && (
            <img alt="Logo" src={authorizedFastwyre} className="logo" />
          )}
          {(fidiumCustomer?.fiberCompanyId === 10 ||
            fidiumCustomer?.fiberCompanyId === 11) && (
            <img
              src="/images/offer/pointbroadband/logo.png"
              alt="Authorized Point Broadband"
            />
          )}

          {fidiumCustomer?.fiberCompanyId === 12 && (
            <img src="/images/offer/wyyerd/logo.png" alt="Wyyerd Contractor" />
          )}
          {
            fidiumCustomer?.fiberCompanyId === 13 && (
              <img src="/images/offer/astound/logo.png" alt="Authorized Point Broadband" />
            )
          }



          {/* <h1 >Install Date Time</h1> */}
          <label>Reschedule Date</label>
          <input
            type="date"
            value={rescheduleFrom}
            // min={getMinDate()}
            // disabled={daySelected}
            onChange={(e) => {
              setDaySelected("");
              setRescheduleFrom(e.target.value);
            }}
            min={
              new Date(Date.now() + 86400000 * daysOut)
                .toISOString()
                .split("T")[0]
            }
          />

          {fidiumCustomer?.fiberCompanyId === 10 ||
          fidiumCustomer?.fiberCompanyId === 11 ? (
            <>
              <p className="or">OR</p>
              <label>Select Available Days</label>
              <div className="grid grid-cols-2 gap-2 w-full">
                {[
                  "Next Available",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day, index) => {
                  return (
                    <p
                      onClick={() => {
                        setDaySelected(day);
                        setRescheduleFrom("");
                      }}
                      key={index}
                      className={`p-2 rounded-lg border-4 cursor-pointer ${
                        daySelected === day
                          ? "border-[#2761d0] text-[#2761d0]"
                          : ""
                      } w-full text-center`}
                    >
                      {day}
                    </p>
                  );
                })}
              </div>
              <label>Intall Time Slot:</label>
              <div className="grid grid-cols-2 gap-2 w-full">
                <p
                  onClick={() => {
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("10:00");
                    setSelectedTimeRange("8-10");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "8-10"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  8:00am - 10:00am
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("10:00");
                    setInstallTimeTo("12:00");
                    setSelectedTimeRange("10-12");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "10-12"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  10:00am - 12:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("12:00");
                    setInstallTimeTo("14:00");
                    setSelectedTimeRange("12-2");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "12-2"
                      ? "border-[#2761d0] font-bold text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  12:00pm - 2:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("14:00");
                    setInstallTimeTo("16:00");
                    setSelectedTimeRange("2-4");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "2-4"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  2:00pm - 4:00pm
                </p>
              </div>
            </>
          ) : 
          fidiumCustomer?.fiberCompanyId === 13 ? (
            <>
                 <label>Intall Time Slot:</label>
              <div className="grid grid-cols-2 gap-2 w-full">
                <p
                  onClick={() => {
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("10:00");
                    setSelectedTimeRange("8-10");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "8-10"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  8:00am - 10:00am
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("12:00");
                    setSelectedTimeRange("8-12");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "8-12"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  8:00am - 12:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("10:00");
                    setInstallTimeTo("12:00");
                    setSelectedTimeRange("10-12");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "10-12"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  10:00am - 12:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("13:00");
                    setInstallTimeTo("15:00");
                    setSelectedTimeRange("1-3");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "1-3"
                      ? "border-[#2761d0] font-bold text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  1:00pm - 3:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("15:00");
                    setInstallTimeTo("17:00");
                    setSelectedTimeRange("3-5");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "3-5"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  3:00pm - 5:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("17:00");
                    setInstallTimeTo("19:00");
                    setSelectedTimeRange("5-7");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "5-7"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  5:00pm - 7:00pm
                </p>
              </div>
            </>
          ):
          (
            <>
              <label>Intall Time Slot:</label>
              <div className="time_slot">
                <input
                  type="time"
                  value={installTimeFrom}
                  onChange={(e) => {
                    setInstallTimeFrom(e.target.value);
                    setSelectedTimeRange("");
                  }}
                />
                <p>To</p>
                <input
                  type="time"
                  value={installTimeTo}
                  onChange={(e) => {
                    setInstallTimeTo(e.target.value);
                    setSelectedTimeRange("");
                  }}
                />
              </div>
              <p className="or">OR</p>
              <div className="am_pm">
                <p
                  onClick={() => {
                    setSelectedTimeRange("AM");
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("12:00");
                  }}
                  className={`${selectedTimeRange === "AM" ? "active" : ""}`}
                >
                  AM
                  <br />
                  <span className=" text-xs">8:00am - 12:00pm</span>
                </p>
                <p
                  onClick={() => {
                    setSelectedTimeRange("PM");
                    setInstallTimeFrom("12:00");
                    setInstallTimeTo("16:00");
                  }}
                  className={`${selectedTimeRange === "PM" ? "active" : ""}`}
                >
                  PM
                  <br />
                  <span className=" text-xs">12:00pm - 4:00pm</span>
                </p>
              </div>
            </>
          )}

          <div className="mt-4 w-full border-2 p-2 border-gray-200 rounded-lg ">
            <div className="flex justify-between items-center ">
              <h3 className="font-bold text-gray-700">Notes</h3>
            </div>
            <div className="mt-2 w-full relative rounded-lg p-2 border-gray-200 h-24 ">
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full h-full border-none focus:outline-none outline-none"
                placeholder="Type your notes here..."
              ></textarea>
            </div>
          </div>
          <button
            onClick={() => {
              submitReschedule();
            }}
          >
            SUBMIT RESCHEDULE REQUEST
          </button>
          <img alt="Logo" src={"/images/nav/nav-logo.png"} className="logo" />
        </div>
      ) : (
        <div
          style={{
            justifyContent: "initial",
          }}
          className="custom_modal"
        >
          <div className="flex items-center justify-end w-full">
            <IoCloseCircle
              onClick={() => {
                setOpen(false);
              }}
              size={30}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              color="#2761d0"
            />
          </div>
          <div className="h-[200px]">
            {fidiumCustomer?.fiberCompanyId === 2 && (
              <img alt="Logo" src={authorized} className="logo" />
            )}
            {fidiumCustomer?.fiberCompanyId === 9 && (
              <img alt="Logo" src={authorizedFastwyre} className="logo" />
            )}
            {(fidiumCustomer?.fiberCompanyId === 10 ||
              fidiumCustomer?.fiberCompanyId === 11) && (
              <img
                src="/images/offer/pointbroadband/logo.png"
                alt="Authorized Point Broadband"
              />
            )}

            {fidiumCustomer?.fiberCompanyId === 12 && (
              <img
                src="/images/offer/wyyerd/logo.png"
                alt="Wyyerd Contractor"
              />
            )}

            {
              fidiumCustomer?.fiberCompanyId === 13 && (
                <img src="/images/offer/astound/logo.png" alt="Authorized Point Broadband" />
              )
            }

          </div>
          {/* <h1 >Install Date Time</h1> */}
          {fidiumCustomer?.fiberCompanyId === 10 ||
          fidiumCustomer?.fiberCompanyId === 11 ? (
            // false?
            <>
              <label>First Preference Install Date</label>
              <input
                type="date"
                value={rescheduleFrom}
                min={minDateToday()}
                onChange={(e) => {
                  setRescheduleFrom(e.target.value);
                }}
              />
              <label>Second Preference Install Date (Optional)</label>
              <input
                type="date"
                value={secondReference}
                min={minDateToday()}
                onChange={(e) => {
                  setSecondReference(e.target.value);
                }}
              />
            </>
          ) : (
            <>
              <label>Install Date</label>
              <input
                type="date"
                value={rescheduleFrom}
                onChange={(e) => {
                  setRescheduleFrom(e.target.value);
                }}
                min={minDateToday()}
              />
            </>
          )}

          <label>Intall Time Slot:</label>

          {fidiumCustomer?.fiberCompanyId === 10 ||
          fidiumCustomer?.fiberCompanyId === 11 ? (
            <div className="grid grid-cols-2 gap-2 w-full">
              <p
                onClick={() => {
                  setInstallTimeFrom("08:00");
                  setInstallTimeTo("10:00");
                  setSelectedTimeRange("8-10");
                }}
                className={`p-2 rounded-lg border-4 cursor-pointer  ${
                  selectedTimeRange === "8-10"
                    ? "border-[#2761d0] text-[#2761d0]"
                    : ""
                } w-full text-center`}
              >
                8:00am - 10:00am
              </p>
              <p
                onClick={() => {
                  setInstallTimeFrom("10:00");
                  setInstallTimeTo("12:00");
                  setSelectedTimeRange("10-12");
                }}
                className={`p-2 rounded-lg border-4 cursor-pointer ${
                  selectedTimeRange === "10-12"
                    ? "border-[#2761d0] text-[#2761d0]"
                    : ""
                } w-full text-center`}
              >
                10:00am - 12:00pm
              </p>
              <p
                onClick={() => {
                  setInstallTimeFrom("12:00");
                  setInstallTimeTo("14:00");
                  setSelectedTimeRange("12-2");
                }}
                className={`p-2 rounded-lg border-4 cursor-pointer ${
                  selectedTimeRange === "12-2"
                    ? "border-[#2761d0] font-bold text-[#2761d0]"
                    : ""
                } w-full text-center`}
              >
                12:00pm - 2:00pm
              </p>
              <p
                onClick={() => {
                  setInstallTimeFrom("14:00");
                  setInstallTimeTo("16:00");
                  setSelectedTimeRange("2-4");
                }}
                className={`p-2 rounded-lg border-4 cursor-pointer  ${
                  selectedTimeRange === "2-4"
                    ? "border-[#2761d0] text-[#2761d0]"
                    : ""
                } w-full text-center`}
              >
                2:00pm - 4:00pm
              </p>
            </div>
          ) : 
          fidiumCustomer?.fiberCompanyId === 13 ? (
            <>
                 
              <div className="grid grid-cols-2 gap-2 w-full">
                <p
                  onClick={() => {
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("10:00");
                    setSelectedTimeRange("8-10");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "8-10"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  8:00am - 10:00am
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("08:00");
                    setInstallTimeTo("12:00");
                    setSelectedTimeRange("8-12");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "8-12"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  8:00am - 12:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("10:00");
                    setInstallTimeTo("12:00");
                    setSelectedTimeRange("10-12");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "10-12"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  10:00am - 12:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("13:00");
                    setInstallTimeTo("15:00");
                    setSelectedTimeRange("1-3");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer ${
                    selectedTimeRange === "1-3"
                      ? "border-[#2761d0] font-bold text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  1:00pm - 3:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("15:00");
                    setInstallTimeTo("17:00");
                    setSelectedTimeRange("3-5");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "3-5"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  3:00pm - 5:00pm
                </p>
                <p
                  onClick={() => {
                    setInstallTimeFrom("17:00");
                    setInstallTimeTo("19:00");
                    setSelectedTimeRange("5-7");
                  }}
                  className={`p-2 rounded-lg border-4 cursor-pointer  ${
                    selectedTimeRange === "5-7"
                      ? "border-[#2761d0] text-[#2761d0]"
                      : ""
                  } w-full text-center`}
                >
                  5:00pm - 7:00pm
                </p>
              </div>
            </>
          ):
          (
            <div className="time_slot">
              <input
                type="time"
                value={installTimeFrom}
                onChange={(e) => {
                  setInstallTimeFrom(e.target.value);
                  setSelectedTimeRange("");
                }}
              />
              <p>To</p>
              <input
                type="time"
                value={installTimeTo}
                onChange={(e) => {
                  setInstallTimeTo(e.target.value);
                  setSelectedTimeRange("");
                }}
              />
            </div>
          )}

          {fidiumCustomer?.fiberCompanyId !== 10 &&
            fidiumCustomer?.fiberCompanyId !== 11 &&fidiumCustomer?.fiberCompanyId !== 13 && (
              <>
                <p className="or">OR</p>
                <div className="am_pm">
                  <p
                    onClick={() => {
                      setSelectedTimeRange("AM");
                      setInstallTimeFrom("08:00");
                      setInstallTimeTo("12:00");
                    }}
                    className={`${selectedTimeRange === "AM" ? "active" : ""}`}
                  >
                    AM
                    <br />
                    <span className=" text-xs">8:00am - 12:00pm</span>
                  </p>
                  <p
                    onClick={() => {
                      setSelectedTimeRange("PM");
                      setInstallTimeFrom("12:00");
                      setInstallTimeTo("16:00");
                    }}
                    className={`${selectedTimeRange === "PM" ? "active" : ""}`}
                  >
                    PM
                    <br />
                    <span className=" text-xs">12:00pm - 4:00pm</span>
                  </p>
                </div>
              </>
            )}
          {/* <FiberCompanySelector setUser={setInstallCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" /> */}
          <FiberPlanSelector
            fiberPlan={fidiumCustomer?.fiberPlan}
            setUser={setInstalltPlan}
            placeholder="Select a Plan"
            className="fiber_company_selector"
            fiberCompany={fidiumCustomer?.fiberCompanyId}
          />

          {(fidiumCustomer?.fiberCompanyId === 10 ||
            fidiumCustomer?.fiberCompanyId === 11) && (
            <>
              <label>Order Number</label>
              <input
                type="text"
                value={orderNumber}
                onChange={(e) => {
                  setOrderNumber(e.target.value);
                }}
              />
            </>
          )}

        

          <div className="flex items-center justify-start gap-2 w-full">
            <input
              type="checkbox"
              style={{
                width: "fit-content",
              }}
              checked={paymentInfoCollected}
              onChange={(e) => {
                setPaymentInfoCollected(e.target.checked);
              }}
            />
            <label>PAYMENT INFO COLLECTED</label>
          </div>
          <div className="promos" style={{ width: "100%" }}>
            <p>Promos</p>
            <div className="promo_options">
              {fidiumCustomer?.fiberCompany?.offerTemplates[0]?.promos
                ?.split("\n")
                .map((promo, index) => {
                  return (
                    <label
                      htmlFor={`free` + index}
                      key={index}
                      className="promo"
                    >
                      <input
                        onChange={(e) => {
                          let arr = promoCode.split("\n");
                          if (arr.includes(promo)) {
                            arr = arr.filter((item) => item !== promo);
                          } else {
                            arr.push(promo);
                          }
                          setPromoCode(arr.join("\n"));
                        }}
                        type="checkbox"
                        id={`free` + index}
                      />
                      <sapn>{promo}</sapn>
                    </label>
                  );
                })}
            </div>
          </div>
          {lead.leadType === "HFC Plus" && fidiumCustomer?.fiberCompanyId === 2 && (
            <div className="w-full">
              <h4 className="text-lg font-bold">Upload Booster</h4>
              <div className="flex items-center justify-start gap-8">
                {/* radio button yes or no */}
                <div className="flex items-center justify-start gap-2">
                  <input
                    type="radio"
                    name="uploadBooster"
                    id="yes"
                    value="yes"
                    checked={uploadBooster === true}
                    onChange={() => {
                      setUploadBooster(true);
                    }}
                  />
                  <label className="text-base" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="flex items-center justify-start gap-2">
                  <input
                    type="radio"
                    name="uploadBooster"
                    id="no"
                    value="no"
                    checked={uploadBooster === false}
                    onChange={() => {
                      setUploadBooster(false);
                    }}
                  />
                  <label className="text-base" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
            </div>
          )}
          <button
            onClick={() => {
              updateInstallDate();
            }}
          >
            SCHEDULE INSTALL
          </button>
          <img alt="Logo" src={"/images/nav/nav-logo.png"} className="logo" />
        </div>
      )}
    </Modal>
  );
};

export default ScheduleForm;


import React, { useState, useEffect, useContext } from 'react'

import Layout from '../../../Layouts/Layout'
import { UserContext } from '../../../App';
import StringIntReq from '../../../Requests/StringIntReq';
import UsersApi from '../../../API/UsersApi';
import ContactList from './components/ContactList';
import DigitalActions from './components/DigitalActions';
import TemplateSelector from './components/TemplateMessage';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import BusinessCard from "./components/BusinessCard";
import { useParams } from 'react-router-dom';
import SearchUserReq from '../../../Requests/Users/SearchUserReq';
import LightningLoader from '../../../components/Loader';

const Index = () => {

  const { id, fiberCompany } = useParams();
  const [selectedAction, setSelectedAction] = useState({ id: 0, label: "" })
  const [selectedContacts, setSelectedContacts] = useState([])
  const [salesRep, setSalesRep] = useState(null);
  const [loading, setLoading] = useState(true);
  const [view,setView] = useState("list");
  const user = useContext(UserContext);
  const [mode, setMode] = useState("Sales");
  const [filterFiberCompanyId, setFilterFiberCompanyId] = useState(fiberCompany ? fiberCompany : null);

  const fetchSalesRep = async () => {
    const req = new StringIntReq();
    req.int = id;
    const res = await UsersApi.GetUserById(req);
    if (res?.status?.success) {
      setSalesRep(res?.data);
      setLoading(false);
    }

  }

  useEffect(() => {
    if (id) {
      fetchSalesRep();
    }
  }, [])

  const content = (
    <>
      <BusinessCard salesRep={id ? salesRep : user} salesRepId={id} fiberCompany={fiberCompany} filterFiberCompanyId = {filterFiberCompanyId} setFilterFiberCompanyId = {setFilterFiberCompanyId} />
      {
        !id &&
        <div>
          <ContactList mode={mode} setMode={setMode} setSelectedAction={setSelectedAction} view={view} setView={setView} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} filterFiberCompanyId = {filterFiberCompanyId}/>
          {user.userType === "SuperAdmin" && <DigitalActions  mode={mode} selectedAction={selectedAction} selectedContacts={selectedContacts} setSelectedAction={setSelectedAction} />}
          <TemplateSelector selectedContacts={selectedContacts} selectedAction={selectedAction} setSelectedAction={setSelectedAction} mode={mode}/>
        </div>
      }
    </>
  )
  if (user && !id) {
    return (
      <Layout>
        {content}
      </Layout>
    );
  }
  else {
    if (loading) {
    return <LightningLoader />;
    } else {
      return (
        <div className='flex flex-col p-4 items-center justify-center min-h-screen'>
          {content}
        </div>
      )
    }
  }
};

export default Index;

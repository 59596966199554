import React from "react";
import { disAssoicateTag } from "../api/tagsAPI";
import Swal from "sweetalert2";

export default function TabPanel({ tag, activeTab, setActiveTab, onDelete , onEdit}) {
  const handleDisAssoicate = async (tagId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    });
  
    if (result.isConfirmed) {
      await disAssoicateTag({ id: tagId });
      Swal.fire("Removed!", "The tag has been disassociated.", "success");

      onEdit({...tag, tagEntities: tag.tagEntities.filter(t => t.id !== tagId)})
    }
  }

  const filteredEntities =
    tag?.tagEntities?.filter(
      (entity) => entity.type.toLowerCase() === activeTab
    ) || [];

  return (
    <>
      <div className="mb-4">
        <button
          className={`mr-2 px-3 py-1 rounded ${
            activeTab === "lead" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("lead")}
        >
          Leads (
          {tag?.tagEntities?.filter((e) => e.type === "Lead").length || 0})
        </button>
        <button
          className={` px-3 py-1 rounded ${
            activeTab === "candidate" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setActiveTab("candidate")}
        >
          Candidates (
          {tag?.tagEntities?.filter((e) => e.type === "Candidate").length || 0})
        </button>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Email</th>
            <th className="p-2 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntities.length > 0 ? (
            filteredEntities.map((item) => (
              <tr key={item.id} className="border-b border-gray-200">
                <td className="p-2">{item.name || "N/A"}</td>
                <td className="p-2">{item.email || "N/A"}</td>
                <td
                  className="p-2 text-red-400 font-bold cursor-pointer"
                  onClick={() => handleDisAssoicate(item.id)}
                >
                  X
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center p-4">
                No {activeTab === "lead" ? "Leads" : "Candidates"} available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

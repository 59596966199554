import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FiberHouseApi from '../../../../../API/FiberHouseApi';

const CompanyFilter = ({ candidateCompanies, setCandidateCompanies }) => {
  const [companyOptions, setCompanyOptions] = useState([]);

  const fetchCompanyOptions = async () => {
    const res = await FiberHouseApi.GetCandidateCompanies();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) => a.label.localeCompare(b.label));
      setCompanyOptions(sortedData);
    }
  };

  useEffect(() => {
    fetchCompanyOptions();
  }, []);

  return (
    <div className="InputDiv">
      <label htmlFor="companies">Companies</label>
      <Select
        isMulti
        value={candidateCompanies}
        onChange={setCandidateCompanies}
        options={companyOptions}
        placeholder="Select Companies"
      />
    </div>
  );
};

export default CompanyFilter;

import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserContext } from "../../../../App";
import CreateLightCustomerReq from "../../../../Requests/Customer/CreateLightCustomerReq";
import CustomerApi from "../../../../API/CustomerApi";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import PhoneInput from "react-phone-input-2";
import FiberPlanSelector from '../../../../components/FiberPlanSelector'

const FreeInstallForm = ({ lead }) => {
  const user = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);
  const [fiberPlan, setFiberPlan] = useState(null);

  const [companyId, setCompanyId] = useState("");
  // const [fiberPlanId, setFiberPlanId] = React.useState('')

  useEffect(() => {
    setFirstName(lead?.name?.split(" ")[0]);
    setLastName(lead?.name?.split(" ")[1]);
    setEmail(lead?.email);
    setPhone(lead?.phone);
  }, [lead]);

  const createLightCustomer = async () => {
    Swal.fire({
      title: "Creating Customer",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!firstName) {
      Swal.fire({
        icon: "error",
        title: "First Name is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Email is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "Phone is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (phone.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Phone is invalid",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    if (!consent) {
      Swal.fire({
        icon: "error",
        title: "Consent is required",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }

    const req = new CreateLightCustomerReq();
    req.firstName = firstName;
    req.lastName = lastName;
    req.email = email;
    req.phoneNumber = phone;
    req.companyId = companyId;
    req.planId = fiberPlan.id;
    req.salesRepId = user?.id;
    req.leadId = lead?.id;
    req.signedUpBy = "SalesRep";

    const res = await CustomerApi.CreateLightCustomer(req);

    if (res?.status?.success) {
      Swal.fire({
        icon: "success",
        title: "Customer Created Successfully",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to create customer",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  return (
    <div className="max-w-xl mx-auto p-8 bg-white">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">
        Free Install Form
      </h2>

      <div className="space-y-4">
        <div>
          <label className="block text-lg font-medium text-gray-700">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Company
          </label>
          <FiberCompanySelector
            companiesWithOfferTemplateOnly={true}
            setUser={setCompanyId}
            placeholder="Select Fiber Company"
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
        </div>
        {companyId && (
          <>
          <label className="block text-lg font-medium text-gray-700">Plan </label>
          <FiberPlanSelector
            fiberCompany={companyId}
            setUser={setFiberPlan}
            disabled={companyId ? false : true}
            placeholder="Select a Plan"
            className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />
          </>
          
        )}

        <div className="w-full p-">
          <label className="block text-lg font-medium text-gray-700">
            Phone
          </label>
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            containerClass="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
            inputClass="w-full border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
            buttonStyle={{ height: "60%", top: "12px" }}
            inputStyle={{ width: "100%" }}
          />
        </div>

        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            checked={consent}
            onChange={() => setConsent(!consent)}
            className="form-checkbox h-5 w-5 text-blue-500 rounded border-gray-300 focus:ring-blue-500"
          />
          <p className="text-sm text-gray-600">
            I agree to receive emails and/or sms messages from Lightning OS LLC.
          </p>
        </div>

        <button
          onClick={createLightCustomer}
          className="w-full bg-blue-500 text-white py-3 px-6 rounded-xl font-medium text-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Reserve Free Install
        </button>
      </div>
    </div>
  );
};

export default FreeInstallForm;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
import MapComponent from "@terrestris/react-geo/dist/Map/MapComponent/MapComponent";
import { Collection, Geolocation, Overlay } from "ol";
import Feature from "ol/Feature";
import OlMap from "ol/Map";
import LiveTracking from "../Images/map.png";
import OlView from "ol/View";
import { LineString, Polygon } from "ol/geom";
import Point from "ol/geom/Point";
import Modify from "ol/interaction/Modify";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom.js";
import PinchZoom from "ol/interaction/PinchZoom.js";
import OlLayerTile from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { WKT } from "ol/format";
import "ol/ol.css";
import { fromLonLat, toLonLat } from "ol/proj";
import { getVectorContext } from "ol/render";
import Cluster from "ol/source/Cluster.js";
import OlSourceOsm from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { getDistance } from "ol/sphere";
import CircleStyle from "ol/style/Circle.js";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text.js";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BiCar, BiCurrentLocation } from "react-icons/bi";
import { CiMapPin } from "react-icons/ci";
import {
  FaCarSide,
  FaChevronDown,
  FaChevronUp,
  FaMap,
  FaSatelliteDish,
  FaWalking,
} from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { MdFilterListAlt, MdOutlineAreaChart } from "react-icons/md";
import Swal from "sweetalert2";
import { MapContext as MP } from "..";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import MapIconApi from "../../../../API/MapIconApi";
import UsersApi from "../../../../API/UsersApi";
import { UserContext } from "../../../../App";
import EditFiberHouseReq from "../../../../Requests/FiberHouse/EditFiberHouseReq";
import LoadingIcon from "../Images/loadingicon.png";
import MapHelper from "../MapHelper";
import "../MapPage.scss";
import Player from "../Player";
import SatelliteLayer from "./SatelliteLayer";
import SelectArea from "./SelectArea";
import MiniLeadCard from "./MiniLeadCard";
import ReactDOM from "react-dom/client";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoFlash,
} from "react-icons/io5";
import handleLastLead from "./handelLastLead";
import MiniCandidateLeadCard from "./MiniCandidateLeadCard";
import MiniSalesRepCard from "./MiniSalesRepCard";
import RenderCoverageArea from "./RenderCoverageArea";
import { buffer, createEmpty, getCenter } from "ol/extent";
import { LuPanelTopOpen } from "react-icons/lu";
import Rotate from "ol/control/Rotate.js";
import RBush from "rbush";
import { TbLassoPolygon } from "react-icons/tb";
import StringIntReq from "../../../../Requests/StringIntReq";
import { components } from "react-select";
import FccDataDetails from "./FCCData/FCCSearchFilters/FccDataDetails";
import FccMapPopUpDetails from "./FCCData/FCCSearchFilters/FccMapPopUpDetails";
import FccLegend from "./FCCData/FCCSearchFilters/FccLegend";
import { IoLayersOutline } from "react-icons/io5";
import AppointmentPopup from "./AppointmentPopup";
import MiniLiveTrackingTab from "./LiveTracking/MiniLiveTrackingTab";
import { useLocation } from "react-router-dom";
import { RiFilterOffFill } from "react-icons/ri";
import { easeOut } from 'ol/easing';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';

// import MapHelper from "../MapHelper";
export const Map = ({
  fiberHouseClicked,
  currentPositionLayer,
  setCurrentPositionLayer,
  candidateClicked,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const user = useContext(UserContext);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [map, setMap] = useState();
  //const [cluster, setCluster] = useState(null);
  const [clusterLayer, setClusterLayer] = useState(null);
  // const [circleLayers, setCircleLayers] = useState([])
  const [isDrawingToolActive, setisDrawingToolActive] = useState(false);

  const [showTrackingTab, setShowTrackingTab] = useState(false);

  const mapHelper = new MapHelper();
  // const []
  const {
    setMapObject,
    mapIcons,
    index,
    setIndex,
    selectedFiberHouse,

    fiberHouses,
    setOpenMobileMenu,

    setSelectedFiberHouseFeature,
    setFiberHouseIds,
    // totalFiberHouses,
    // setTotalFiberHouses,
    searchAreaFeature,
    servicableAreas,
    shouldReRenderMap,
    cluster,
    locationHistories,
    playTime,
    pathFeature,
    setPathFeature,
    targetUserId,
    userPathColor,
    toggleState,
    activityCoords,
    repLatestLocation,
    candidates,
    setSelectedCandidateFeature,
    setCandidateIds,
    setCurrentLocationCoordinates,
    setSearchAreaFeature,
    setSearchArea,
    selectedCandidateFeature,
    setFiberHouses,
    setCandidates,
    salesMode,
    setSalesMode,
    recruitMode,
    // Adding FccFilteration attributes.
    fccDataGeoType,
    setFccDataGeoType,
    fccDataTecType,
    setFccDataTecType,
    fccDataPhyTecType,
    setFccDataPhyTecType,
    fccDataProviders,
    setFccDataProviders,
    fccData,
    setFccData,
    //Adding FCC Mode
    fccMode,
    setFCCMode,
    shouldReRenderServicableAreas,
    setRecruitMode,
    createMode,
    setSelectedFiberHouse,
    selectedFiberHouseFeature,
    selectedCandidateLead,
    setSelectedCandidateLead,
    salesReps,
    housesSortedByLatitude,
    rTree,
    housesSortedByLongitude,
    setFiberHousesLoading,
    searchFiberHouses,
    boltMode,
    calendarPopup,
    setCalendarPopup,
    setBoltMode,
    setCurrentSalesRepId,
    currentSalesRepId,
    
    setCurrentSalesRepDate,
    currentSalesRepDate,
    fccSearchType,
    setFccSearchType,
  } = useContext(MP);

  const [satelliteMode, setSatelliteMode] = useState(true);
  const [addedServicableAreas, setAddedServicableAreas] = useState([]);
  // const fiberHousesRef = useRef();
  const selectedFiberHouseRef = useRef();
  selectedFiberHouseRef.current = selectedFiberHouse;
  // fiberHousesRef.current = fiberHouses;
  const selectedCandidateRef = useRef();
  selectedCandidateRef.current = selectedCandidateFeature;
  //Displaying all fiber houses on the map
  const indexRef = useRef();
  const [zoom, setZoom] = useState(0);
  const [pathLayer, setPathLayer] = useState(null);
  const initialRender = useRef(true);
  const candidateLayerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const expandedMenuRef = useRef(null);
  const [liveTrackingMode, setLiveTrackingMode] = useState(false);
  const [repLocationsLayer, setRepLocationsLayer] = useState(null);
  const overlaysRef = useRef({});
  const [notificationPanel, setNotificationPanel] = useState(false);
  const liveTrackingUserIds = [153, 1, 3, 55, 156, 157];
  const fccUserIds = [153, 1, 3, 55, 156, 157];
  const [isMiniLeadCardVisible, setIsMiniLeadCardVisible] = useState(false);
  const [isMiniCandidateCardVisible, setIsMiniCandidateCardVisible] =
    useState(false);
  const [position, setPosition] = useState([0, 0]);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const activeGroupIndex = useRef(0);
  const [forceUpdateActiveGroupIndex, setForceUpdateActiveGroupIndex] = useState(false);
  const [pathGrouping, setPathGrouping] = useState([]);
  const [newSpeedsByGroup, setNewSpeedsByGroup] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const isMobile = screenWidth <= 768;
  const buttonSize = isMobile ? "w-8 h-8" : "w-10 h-10";
  const iconSize = isMobile ? 20 : 24;
  const visitedFeatures = useRef([]);
  const visitedCandidates = useRef([]);
  const [popupEnabled, setPopupEnabled] = useState(false);
  const [areasEnabled, setAreasEnabled] = useState(true);
  const SELECTED_MAPICON_SCALE = 1;
  const CIRCLE_RADIUS = 30;
  const [triggerBoltModeSearch, setTriggerBoltModeSearch] = useState(false);


  const [isPanning, setIsPanning] = useState(true);
  const [playTimeMultiplier, setPlayTimeMultiplier] = useState(2);
  const [currentGroupSpeeds, setCurrentGroupSpeeds] = useState([]);

  const [animationState, setAnimationState] = useState({
    isPlaying: false,
    currentGroup: 0,
    progress: 0
  });


  // Add these refs instead
const animatingRef = useRef(false);
const distanceRef = useRef(0);
const startAnimationRef = useRef(null);
const stopAnimationRef = useRef(null);
const resumeAnimationRef = useRef(null);
const lastTimeRef = useRef(Date.now());
const previousSpeedRef = useRef(0);
const isPanningRef = useRef(false);
const timeDisplayElement = document.querySelector('.time-display');

const walkingIconSvg = `data:image/svg+xml;utf8,<svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="%23007aff" stroke="%23ffffff" stroke-width="0.5" d="M10.1 3A1.9 1.9 0 1 1 12 4.9 1.899 1.899 0 0 1 10.1 3zm6.257 13.26l-2.425-3.377-.293-5.403c-.067-1.287-1.823-1.449-3.401-1.579-.945 1.6-2.48 4.575-3.125 5.838a.721.721 0 0 0 .265.942.74.74 0 0 0 1.033-.264l2.104-3.72.258 4.338 3.47 4.144 1.724 4.858a1.088 1.088 0 0 0 2.081-.496.953.953 0 0 0-.03-.202c-.03-.113-1.66-5.078-1.66-5.078zm-6.014-2.18l1.581 1.887-.335 1.588-3.227 4.285a1.087 1.087 0 1 1-1.808-1.2l3.065-4.285zM18 10.936a.881.881 0 0 1-.824 1.51c-.611-.37-2.35-1.536-2.35-1.536l-.104-1.926z"/>
  <path fill="none" d="M0 0h24v24H0z"/>
</svg>`;

const carIconSvg = `data:image/svg+xml;utf8,<svg width="800px" height="800px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
  <path fill="%23ff9500" stroke="%23ffffff" stroke-width="10" d="M544 192h-16L419.22 56.02A64.025 64.025 0 0 0 369.24 32H155.33c-26.17 0-49.7 15.93-59.42 40.23L48 194.26C20.44 201.4 0 226.21 0 256v112c0 8.84 7.16 16 16 16h48c0 53.02 42.98 96 96 96s96-42.98 96-96h128c0 53.02 42.98 96 96 96s96-42.98 96-96h48c8.84 0 16-7.16 16-16v-80c0-53.02-42.98-96-96-96zM160 432c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48zm72-240H116.93l38.4-96H232v96zm48 0V96h89.24l76.8 96H280zm200 240c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48z"/>
</svg>`;


// Update handlePlayPause
const handlePlayPause = () => {
  // console.log("Play button from Map.js", animatingRef.current, distanceRef.current);
  if (animatingRef.current) {
    stopAnimationRef.current();
  } else if (distanceRef.current === 1) {
    distanceRef.current = 0;
    startAnimationRef.current(activeGroupIndex.current);
  } else if (distanceRef.current === 0) {
    startAnimationRef.current(activeGroupIndex.current);
  } else {
    resumeAnimationRef.current();
  }
};

const handleSpeedChange = () => {
  const newMultiplier = (playTimeMultiplier % 8) + 1;
  setPlayTimeMultiplier(newMultiplier);
};

const handlePanToggle = () => {
  setIsPanning(!isPanning);
};

// Add group change handler
const handleGroupChange = (newGroupIndex) => {
  activeGroupIndex.current = newGroupIndex;
  setForceUpdateActiveGroupIndex(prev => !prev);

  // console.log(" activeGroupIndex.current from handleGroupChange: ", activeGroupIndex.current);

  // setAnimationState(prev => ({
  //   ...prev,
  //   currentGroup: newGroupIndex,
  //   progress: 0
  // }));
};

  
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        expandedMenuRef.current &&
        !expandedMenuRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleZoomChange() {
    if (map) {
      if (map.getView().getZoom() >= 15.6) {
        setZoom("ZoomIn");
      } else {
        setZoom("ZoomOut");
      }
    }
    // console.log(zoom)
  }

  useEffect(() => {
    if (map) {
      map.getView().on("change:resolution", handleZoomChange);
    }
  }, [map]);

  // useEffect(() => {
  //   if (clusterLayer && zoom === "ZoomIn") {
  //     clusterLayer?.setDistance(2);
  //     // showPathLayer.current = true;
  //     // setClusterDistance(0)
  //     // change the color of the circle to purple
  //   } else if (clusterLayer && zoom === "ZoomOut") {
  //     clusterLayer?.setDistance(50);

  //     // setClusterDistance(50)
  //   }
  // }, [zoom]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else {
      if (map) {
        if (activityCoords) {
          map
            .getView()
            .setCenter(fromLonLat([activityCoords.long, activityCoords.lat]));
          map.getView().setZoom(20);
        }
      }
    }
  }, [activityCoords]);

  useEffect(() => {
    indexRef.current = index;
    console.log("Index from Map.js: ", indexRef.current);
  }, [index]);

  // useEffect(
  //   () => {
  //     if (map) {
  //       console.log("Map is set")
  //       const view = map.getView();
  //       view.on('change', function () {

  //         handleChange(view)
  //       })
  //     }
  //   }, [map, fiberHouses]
  // )

  async function resetIconStyle(vectorSource) {
    if (selectedFiberHouseRef.current) {
      // eslint-disable-next-line array-callback-return
      vectorSource.getFeatures().map((fea) => {
        if (fea.get("id") === selectedFiberHouseRef.current.id) {
          // const fiberHouseIndex =  binarySearchFiberHouses(fiberHousesRef.current, selectedFiberHouseRef.current.id);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === fea.get("mapIconId")?.mapIconId
          );

          const newStyle = new Style({
            image: new Icon({
              scale: SELECTED_MAPICON_SCALE, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
            }),
            zIndex: 11100,
          });
          const circleStyle = new Style({
            image: new CircleStyle({
              radius: CIRCLE_RADIUS, // This is now in pixels
              stroke: new Stroke({
                color: "white", // Border color
                width: 4, // Border width
              }),
              fill: new Fill({
                color: "rgba(0, 0, 0, 0)", // Transparent fill
              }),
            }),
          });
          // Apply the circle style to the feature
          fea.setStyle([
            newStyle, // This is the icon style
            circleStyle, // This is the circle style
          ]);
          // zoom the map a little bit to make the icon visible
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
      });
      // console.log(vectorSource.getFeatures())
      // const feature = binarySearchFeatureById(vectorSource.getFeatures(), selectedFiberHouseRef.current.id);
      // console.log(feature)
      // if (feature) {
      //   const fiberHouse = fiberHousesRef.current.find(
      //     (fh) => fh.id === selectedFiberHouseRef.current.id
      //   );
      //   let houseMapIcon = mapIcons.find(
      //     (mi) => mi.id === fiberHouse?.mapIconId
      //   );
      //   const newStyle = new Style({
      //     image: new Icon({
      //       scale: 0.5, // Adjust this value to change the scale of the icon
      //       src: "data:image/png;base64," + houseMapIcon?.image,
      //     }),
      //   });

      //   // Apply the circle style to the feature
      //   feature.setStyle([
      //     newStyle, // This is the icon style
      //   ]);
      // }
    }
  }

  useEffect(() => {
    if (map && candidates) {
      if (candidateLayerRef.current) {
        candidateLayerRef.current.getSource().getSource().clear();
        map.removeLayer(candidateLayerRef.current);
        candidateLayerRef.current = null;
        map.render();
      }
      if (candidates.length > 0) {
        const features = candidates
          .filter((cand) => cand.coordinates && !cand.remove) // Ensure only valid candidates are included
          .map((cand) => {
            const { coordinates, id, mapIconId, selected } = cand;

            if (
              coordinates &&
              coordinates.longitude !== undefined &&
              coordinates.latitude !== undefined
            ) {
              const coordinate = [coordinates.longitude, coordinates.latitude];
              const feature = new Feature({
                geometry: new Point(fromLonLat(coordinate)),
                data: {
                  id: id,
                  mapIconId: mapIconId,
                  selected: selected,
                },
              });
              return feature;
            }
            return null; // In case coordinates are still somehow invalid
          })
          .filter(Boolean); // Filter out any `null` values from the features array

        const vectorSource = new VectorSource({
          features: features,
        });

        const clusterSource = new Cluster({
          distance: 50,
          source: vectorSource,
        });

        const styleCache = {};

        const vectorLayer = new VectorLayer({
          source: clusterSource,
          name: "candidates",
          style: (feature) => {
            const size = feature.get("features").length;
            let style = styleCache[size];

            if (size === 1) {
              const singleFeature = feature.get("features")[0];
              const candidateData = singleFeature.get("data");
              let iconUrl = mapIcons.find(
                (icon) => icon.id === candidateData.mapIconId
              )?.image;
              if (
                selectedCandidateRef.current &&
                candidateData.id === selectedCandidateRef.current.get("data").id
              ) {
                style = [
                  new Style({
                    image: new Icon({
                      src: "data:image/png;base64," + iconUrl,
                      scale: 0.5,
                    }),
                  }),
                  new Style({
                    image: new CircleStyle({
                      radius: 22,
                      stroke: new Stroke({
                        color: "blue",
                        width: 4,
                      }),
                      fill: new Fill({
                        color: "rgba(0, 0, 0, 0)", // Transparent fill
                      }),
                    }),
                  }),
                ];
              }
              // } else if (candidateData.isHighlighted) {
              //   style = [
              //     new Style({
              //       image: new Icon({
              //         src: "data:image/png;base64," + iconUrl,
              //         scale: 0.5,
              //       }),
              //     }),
              //     new Style({
              //       text: new Text({
              //         text: "Highlighted",
              //         fill: new Fill({
              //           color: "#fff",
              //         }),
              //         font: "bold 12px Arial",
              //         offsetY: -10,
              //       }),
              //     }),
              //   ];
              // }
              else {
                style = new Style({
                  image: new Icon({
                    src: "data:image/png;base64," + iconUrl,
                    scale: 0.3,
                  }),
                });
              }
            } else {
              style = new Style({
                image: new CircleStyle({
                  radius: 22,
                  stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                  }),
                  fill: new Fill({
                    color: map.getView().getZoom() > 15 ? "#800080" : "#d1390a",
                  }),
                }),
                text: new Text({
                  text: size.toString(),
                  fill: new Fill({
                    color: "#fff",
                  }),
                  font: "bold 12px Arial",
                  scale: 1,
                }),
              });
            }

            styleCache[size] = style;
            return style;
          },
        });

        map.addLayer(vectorLayer);
        candidateLayerRef.current = vectorLayer;
      }
    }
  }, [candidates, map, mapIcons]);

  const polygonLayerRef = useRef(null);
  const popupRef = useRef(null);
  const overlayRef = useRef(null);
  const [popupData, setPopupData] = useState(null);
  const [showPopup, setShowPopup] = useState(true);



  const getFillColor = (pct) => {
    const clampedPct = Math.max(0, Math.min(100, pct));

    // Define color transition points for each 25% segment
    const colorStops = [
      { pos: 0, color: [0, 153, 76] }, // Dark green
      { pos: 25, color: [255, 204, 0] }, // Bright yellow
      { pos: 50, color: [255, 128, 0] }, // Vibrant orange
      { pos: 75, color: [255, 0, 0] }, // Intense red
      { pos: 100, color: [200, 0, 0] }, // Darker red
    ];

    // Find which segment the percentage falls into
    let startStop, endStop;
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (
        clampedPct >= colorStops[i].pos &&
        clampedPct <= colorStops[i + 1].pos
      ) {
        startStop = colorStops[i];
        endStop = colorStops[i + 1];
        break;
      }
    }

    // Calculate interpolation ratio between the two stops
    const range = endStop.pos - startStop.pos;
    const ratio = (clampedPct - startStop.pos) / range;

    // Interpolate RGB values
    const r = Math.round(
      startStop.color[0] + (endStop.color[0] - startStop.color[0]) * ratio
    );
    const g = Math.round(
      startStop.color[1] + (endStop.color[1] - startStop.color[1]) * ratio
    );
    const b = Math.round(
      startStop.color[2] + (endStop.color[2] - startStop.color[2]) * ratio
    );

    return `rgba(${r}, ${g}, ${b}, 0.4)`;
  };

  const styleFunction = (feature) => {
    const residentialPct = feature.get("residentialServicePct") || 0;
    // console.log(getFillColor(residentialPct * 100));
    return new Style({
      fill: new Fill({ color: getFillColor(residentialPct * 100) }), // Multiply by 100 if stored as decimal (0-1)
      stroke: new Stroke({ color: "#ffcc33", width: 2 }),
    });
  };
  // Create/update polygon layer when map or FCC data changes
  useEffect(() => {
    if (map) {
      if (!fccMode || !fccData) {
        // Remove polygon layer when FCC mode is disabled

        if (polygonLayerRef.current) {
          map.removeLayer(polygonLayerRef.current);
          polygonLayerRef.current = null;
        }
        return;
      }

      if (fccData && fccData.length > 0) {
        // Remove previous polygon layer if it exists
        if (polygonLayerRef.current) {
          map.removeLayer(polygonLayerRef.current);
        }

        const wktFormat = new WKT();
        const features = fccData

          .map((data,index) => {
            if (data.geometries && (fccSearchType === "provider" || (fccSearchType === "geography" && index === 0))) {
              const polygonFeature = wktFormat.readFeature(data.geometries, {
                dataProjection: "EPSG:4326",
                featureProjection: map.getView().getProjection(),
              });
              console.log({ data });
              // Attach additional properties (FCC details) to the feature
              polygonFeature.setProperties({
                geographyId: data.geographyId,
                geoName: data.geoName,
                holdingCompany: data.holdingCompany,
                technologyDesc: data.technologyDesc,
                totalUnits: data.totalUnits,
                speed_02_02: data.speed_02_02,
                speed_10_1: data.speed_10_1,
                speed_25_3: data.speed_25_3,
                speed_100_20: data.speed_100_20,
                speed_250_25: data.speed_250_25,
                speed_1000_100: data.speed_1000_100,
                residentialServicePct: data.residentialServicePct,
                businessServicePct: data.businessServicePct,
              });
              return polygonFeature;
            }
            return null;
          })
          .filter(Boolean);

        const vectorSource = new VectorSource({ features });

        const polygonLayer = new VectorLayer({
          source: vectorSource,
          style: styleFunction,
          name: "responsePolygon",
        });

        map.addLayer(polygonLayer);
        polygonLayerRef.current = polygonLayer;
      }
    }
  }, [map, fccData, fccMode]);

  // Setup the overlay and click handler for displaying FCC details
  useEffect(() => {
    if (map) {
      // Initialize the overlay only once if it hasn't been created
      if (!overlayRef.current) {
        overlayRef.current = new Overlay({
          element: popupRef.current,
          autoPan: true,
          autoPanAnimation: { duration: 250 },
        });
        map.addOverlay(overlayRef.current);
      }

      const handleMapClick = (evt) => {
        // Check if a polygon was clicked
        const clickedFeature = map.forEachFeatureAtPixel(
          evt.pixel,
          (feature) => {
            const properties = feature.getProperties();
            console.log(
              `At properties: ${properties.hasOwnProperty("geographyId")}`
            );
            if (properties.hasOwnProperty("geographyId")) {
              return feature;
            }
            return false;
          }
        );

        if (clickedFeature) {
          console.log(
            `At handleMapClick clickedFeatures:`,
            clickedFeature.getProperties()
          );

          // Use the feature's properties as the popup data
          console.log({ clickedFeature });
          console.log({ properties: clickedFeature.getProperties() });
          setPopupData(clickedFeature.getProperties());
          setShowPopup(true);
          overlayRef.current.setPosition(evt.coordinate);

          // Ensure the popup is visible
          popupRef.current.style.display = "block";
        } else {
          // Hide the popup if no feature is clicked
          setPopupData(null);
          setShowPopup(false);
          overlayRef.current.setPosition(undefined);
          popupRef.current.style.display = "none";
        }
      };

      map.on("click", handleMapClick);
      return () => {
        map.un("click", handleMapClick);
      };
    }
  }, [map]);

  const clearPreviousSelections = () => {
    // Clear previous candidate selection
    if (selectedCandidateRef.current) {
      const previousCandidateFeature = selectedCandidateRef.current;
      if (
        previousCandidateFeature &&
        typeof previousCandidateFeature.setStyle === "function"
      ) {
        previousCandidateFeature.setStyle(
          new Style({
            image: new Icon({
              src:
                "data:image/png;base64," +
                mapIcons.find(
                  (icon) =>
                    icon.id === previousCandidateFeature.get("data").mapIconId
                )?.image,
              scale: 0.3,
            }),
          })
        );
      }
      setSelectedCandidateFeature(null);
      selectedCandidateRef.current = null;
    }

    // Clear previous fiber house selection
    if (selectedFiberHouseRef.current) {
      const previousFiberHouseFeature = selectedFiberHouseRef.current;
      if (
        previousFiberHouseFeature &&
        typeof previousFiberHouseFeature.setStyle === "function"
      ) {
        previousFiberHouseFeature.setStyle(
          new Style({
            image: new Icon({
              src:
                "data:image/png;base64," +
                mapIcons.find(
                  (icon) =>
                    icon.id === previousFiberHouseFeature.get("mapIconId")
                )?.image,
              scale: 0.3,
            }),
          })
        );
      }
      setSelectedFiberHouseFeature(null);
      selectedFiberHouseRef.current = null;
    }
    map.getView().setZoom(map.getView().getZoom() - 0.0000001);
  };

  const handleCandidateMapClick = useCallback(
    (event) => {
      setIsMiniCandidateCardVisible(false);
      visitedCandidates.current = [];
      if (isDrawingToolActive) return;
      // if(createMode.current) return;
      let candidateFound = false;
      let count = 0;
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const size = feature.get("features")?.length;
        const features = feature.get("features");

        if (isDrawingToolActive) {
          return;
        }
        if (
          Object.keys(feature.values_)[0] === "stat" &&
          !isDrawingToolActive
        ) {
          setIndex(8);
          count++;
          return;
        }

        if (size > 1) {
          // Handle clustered features

          if (features.length > 5000) {
            Swal.fire({
              icon: "warning",
              title: "Too Many Candidates",
              text: "Limit is 5000 candidates per cluster.",
              toast: true,
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
            });
            return;
          }

          let candidateIds = [];
          features.forEach((singleFeature) => {
            const candidateData = singleFeature.get("data");
            if (candidateData && candidateData.id) {
              if (candidateData.id !== undefined) {
                candidateIds.push(candidateData.id);
              }
            }
          });

          setIndex(8);
          setCandidateIds(candidateIds);
          count++;
          return;
        } else if (
          (zoom === "ZoomIn" && feature.get("data")) ||
          (size === 1 && features[0].get("data"))
        ) {
          const candidateFeature = features[0];
          const candidateData = candidateFeature.get("data");

          // Clear any previous fiber house selection if it exists
          if (selectedFiberHouseRef.current) {
            const previousFiberHouseFeature = selectedFiberHouseRef.current;
            if (
              previousFiberHouseFeature &&
              typeof previousFiberHouseFeature.setStyle === "function"
            ) {
              previousFiberHouseFeature.setStyle(
                new Style({
                  image: new Icon({
                    src:
                      "data:image/png;base64," +
                      mapIcons.find(
                        (icon) =>
                          icon.id === previousFiberHouseFeature.get("mapIconId")
                      )?.image,
                    scale: 0.3,
                  }),
                })
              );
            }
            setSelectedFiberHouseFeature(null);
            selectedFiberHouseRef.current = null;
          }

          // Clear the previous candidate selection if it is different from the new one
          if (
            selectedCandidateRef.current &&
            selectedCandidateRef.current !== candidateFeature
          ) {
            const previousCandidateFeature = selectedCandidateRef.current;
            if (
              previousCandidateFeature &&
              typeof previousCandidateFeature.setStyle === "function"
            ) {
              previousCandidateFeature.setStyle(
                new Style({
                  image: new Icon({
                    src:
                      "data:image/png;base64," +
                      mapIcons.find(
                        (icon) =>
                          icon.id ===
                          previousCandidateFeature.get("data").mapIconId
                      )?.image,
                    scale: 0.3,
                  }),
                })
              );
            }
            setSelectedCandidateFeature(null);
            selectedCandidateRef.current = null;
          }

          // If the clicked feature is already selected, deselect it
          if (selectedCandidateRef.current === candidateFeature) {
            selectedCandidateRef.current = null;
            setSelectedCandidateFeature(null);
            candidateFound = false;
            setIndex(-1);
            return;
          }

          // Highlight the newly selected feature
          const newStyle = [
            new Style({
              image: new Icon({
                src:
                  "data:image/png;base64," +
                  mapIcons.find((icon) => icon.id === candidateData.mapIconId)
                    ?.image,
                scale: 0.5,
              }),
            }),
            new Style({
              image: new CircleStyle({
                radius: 15,
                stroke: new Stroke({
                  color: "blue",
                  width: 4,
                }),
                fill: new Fill({
                  color: "rgba(0, 0, 0, 0)", // Transparent fill
                }),
              }),
            }),
          ];
          candidateFeature.setStyle(newStyle);

          // Update the reference to the new selected candidate feature
          selectedCandidateRef.current = candidateFeature;
          setSelectedCandidateFeature(candidateFeature);
          setSelectedCandidateLead(candidateData);
          setIsMiniCandidateCardVisible(true);
          // candidateClicked(candidateData);

          candidateFound = true;
          return;
        }
      });

      if (!candidateFound) {
        if (selectedCandidateRef.current) {
          const previousCandidateFeature = selectedCandidateRef.current;
          if (
            previousCandidateFeature &&
            typeof previousCandidateFeature.setStyle === "function"
          ) {
            previousCandidateFeature.setStyle(
              new Style({
                image: new Icon({
                  src:
                    "data:image/png;base64," +
                    mapIcons.find(
                      (icon) =>
                        icon.id ===
                        previousCandidateFeature.get("data").mapIconId
                    )?.image,
                  scale: 0.3,
                }),
              })
            );
          }
          setSelectedCandidateFeature(null);
          selectedCandidateRef.current = null;
        }
      }
    },
    [
      map,
      candidateClicked,
      isDrawingToolActive,
      isDrawingToolActive,
      mapIcons,
      zoom,
    ]
  );

  // Attach and detach the click event on map
  useEffect(() => {
    if (map) {
      map.on("click", handleCandidateMapClick);

      return () => {
        map.un("click", handleCandidateMapClick); // Clean up on unmount
      };
    }
  }, [map, handleCandidateMapClick]);
  

//   function groupLocations(locations) {


//     console.log("Locations from Map.js: ", locations);

//     const groups = [];
//     let currentGroup = [];
//     const TIME_GAP_THRESHOLD = 4 * 60 * 1000; // 2 minutes in milliseconds

//     // Helper function to parse the timestamp while handling undefined values.
//     function parseDate(dateStr) {
//         if (!dateStr) {
//             // You can either return a fallback value (like epoch) or handle it differently.
//             console.warn("Missing recordedAt timestamp; using epoch as fallback.");
//             return new Date(0);
//         }
//         // This regex converts microseconds to milliseconds.
//         return new Date(dateStr.replace(/(\.\d{3})\d+/, "$1"));
//     }

//     locations = locations.filter(loc => 
//       loc.timestamp && 
//       typeof loc.lat === "number" && 
//       typeof loc.lon === "number" &&
//       loc.lat >= -90 && loc.lat <= 90 &&
//       loc.lon >= -180 && loc.lon <= 180
//     );

//     // Optionally filter out locations missing the recordedAt property
//     locations = locations.filter(loc => loc.timestamp !== undefined);

//     // Sort locations by timestamp using the parseDate function.
//     locations.sort((a, b) => parseDate(a.timestamp) - parseDate(b.timestamp));

//     for (let i = 0; i < locations.length; i++) {
//         const loc = locations[i];
//         const currentDate = parseDate(loc.timestamp);

//         if (currentGroup.length > 0) {
//             const lastLoc = currentGroup[currentGroup.length - 1];
//             const lastDate = parseDate(lastLoc.timestamp);

//             console.log("Time Gap:", currentDate, lastDate);

//             // If the time difference exceeds the threshold, finalize the current group.
//             if ((currentDate - lastDate) > TIME_GAP_THRESHOLD) {
//                 if (currentGroup.length >= 2) {
//                     currentGroup[0].isStart = true;
//                     currentGroup[currentGroup.length - 1].isEnd = true;
//                     if (currentGroup.length >= 7)
//                       groups.push([...currentGroup]);
//                 }
//                 currentGroup = []; // Start a new group after a big gap.
//             }
//         }

//         currentGroup.push(loc);
//     }

//     // Handle the final group if it has at least two locations.
//     if (currentGroup.length >= 2) {
//         currentGroup[0].isStart = true;
//         currentGroup[currentGroup.length - 1].isEnd = true;
//         groups.push([...currentGroup]);
//     }
//     console.log("Groups from Map.js: ", groups);
//     return groups;
// }

function groupLocations(locations, config = {}) {
  const {
    timeThreshold = 4,
    minGroupSize = 7,
    requireStartEnd = true
  } = config;

  const TIME_GAP_MS = timeThreshold * 60 * 1000;
  const MIN_POINTS = Math.max(minGroupSize, 1);
  
  try {
    // 1. Data Validation and Cleaning
    const validLocations = locations.filter(loc => {
      const isValid =
        loc?.lat !== undefined && loc?.lon !== undefined &&
        typeof loc.lat === 'number' && typeof loc.lon === 'number' &&
        loc.lat >= -90 && loc.lat <= 90 &&
        loc.lon >= -180 && loc.lon <= 180 &&
        loc.timestamp && !isNaN(new Date(loc.timestamp).getTime());

      if (!isValid) {
        console.warn('Invalid location entry skipped:', loc);
        return false;
      }
      return true;
    });

    if (validLocations.length === 0) {
      console.warn('No valid locations found');
      return [];
    }

    // 2. Timestamp Parsing with Microsecond Handling
    const parseTimestamp = (ts) => {
      try {
        // Handle ISO timestamps with microseconds
        const normalized = ts.replace(
          /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})\d+/,
          '$1'
        );
        const date = new Date(normalized);
        
        if (isNaN(date)) throw new Error('Invalid date');
        return date;
      } catch (error) {
        console.error(`Failed to parse timestamp: ${ts}`, error);
        return new Date(0); // Fallback to epoch
      }
    };

    // 3. Sort locations chronologically
    const sortedLocations = [...validLocations].sort((a, b) => 
      parseTimestamp(a.timestamp) - parseTimestamp(b.timestamp)
    );

    // 4. Grouping Logic
    const groups = [];
    let currentGroup = [];
    
    for (let i = 0; i < sortedLocations.length; i++) {
      const currentLoc = sortedLocations[i];
      const currentTime = parseTimestamp(currentLoc.timestamp);

      if (currentGroup.length > 0) {
        const lastLoc = currentGroup[currentGroup.length - 1];
        const lastTime = parseTimestamp(lastLoc.timestamp);
        const timeDiff = currentTime - lastTime;

        // Split group if time gap exceeds threshold
        if (timeDiff > TIME_GAP_MS) {
          if (currentGroup.length >= MIN_POINTS) {
            groups.push(currentGroup);
          }
          currentGroup = [];
        }
      }

      currentGroup.push(currentLoc);

      // Split groups that are too large
      if (currentGroup.length === 100) { // Safety valve
        groups.push(currentGroup);
        currentGroup = [];
      }
    }

    // Handle final group
    if (currentGroup.length >= MIN_POINTS) {
      groups.push(currentGroup);
    }

    // 5. Post-process groups
    return groups.map(group => {
      if (requireStartEnd && group.length >= 2) {
        return [
          { ...group[0], isStart: true },
          ...group.slice(1, -1),
          { ...group[group.length - 1], isEnd: true }
        ];
      }
      return group;
    });
    
  } catch (error) {
    console.error('Failed to group locations:', error);
    return []; // Fail gracefully
  }
}

  
  
  const clearPreviousPath = useCallback(() => {
    if (!map || !pathLayer) return;
    // Remove overlays with name "image"
    map.getOverlays().forEach((overlay) => {
      if (overlay?.get("name") === "image") {
        map.removeOverlay(overlay);
      }
    });
    // Remove features from the path layer
    pathLayer.getSource().forEachFeature((feature) => {
      pathLayer.getSource().removeFeature(feature);
    });
    pathLayer.getSource().clear();
    map.removeLayer(pathLayer);
    map.render();
    setPathLayer(null);
  }, [map, pathLayer]);


  // // Helper: Feature styling function
  // const featureStyle = (feature) => {
  //   const fType = feature.get("type");
  //   switch (fType) {
  //     case "route":
  //       return new Style({
  //         stroke: new Stroke({
  //           // width: 6,
  //           // color: feature.get("pathColor") || "red",
  //           // lineDash: [10, 10], // Dashed line effect
  //           // color: '#007BFF', // Blue path color
  //           color: feature.get("pathColor") || "#007BFF",
  //           width: 5,         // Path thickness
  //           lineDash: [10, 10] // Dashed line effect
  //         }),
  //       });
  //     case "icon":
  //       // Differentiate start and end markers with labels
  //       const markerType = feature.get("markerType");
  //       const labelText = markerType === "start" ? "A" : markerType === "end" ? "B" : "";
  //       return new Style({
  //         image: new CircleStyle({
  //           radius: 7,
  //           fill: new Fill({ color: "black" }),
  //           stroke: new Stroke({
  //             color: "white",
  //             width: 2,
  //           }),
  //         }),
  //         text: new Text({
  //           text: labelText,
  //           offsetY: -25,
  //           fill: new Fill({ color: "#000" }),
  //           stroke: new Stroke({ color: "#fff", width: 2 }),
  //         }),
  //       });
  //     case "geoMarker":
  //       return new Style({
  //         image: new CircleStyle({
  //           radius: 7,
  //           fill: new Fill({ color: "black" }),
  //           stroke: new Stroke({
  //             color: "white",
  //             width: 2,
  //           }),
  //         }),
  //       });
  //     default:
  //       return null;
  //   }
  // };

  // Updated featureStyle function
const featureStyle = (feature) => {
  const fType = feature.get("type");
  const speed = feature.get("speed"); // Speed in m/s

  switch (fType) {
    case "route":
      const color = speed ? getSpeedColor(speed * 3.6) : "#007BFF"; // Convert to km/h
      return new Style({
        stroke: new Stroke({
          color,
          width: 6,
          lineDash: [10, 10],
        }),
        zIndex: 1,
      });
    // ... rest of the styles
  }
};

// Helper function to get color based on speed
function getSpeedColor(speedKmh) {
  const heatmap = [
    [0, "#4CAF50"],    // Green (0-10 km/h)
    [10, "#FFC107"],   // Yellow (10-30 km/h)
    [30, "#FF5722"],    // Orange (30-50 km/h)
    [50, "#F44336"],    // Red (50+ km/h)
  ];
  
  for (let i = heatmap.length - 1; i >= 0; i--) {
    if (speedKmh >= heatmap[i][0]) {
      return heatmap[i][1];
    }
  }
  return heatmap[0][1];
}

  useEffect(() => {

    if (!map || !locationHistories || !targetUserId) return;

    clearPreviousPath();

    let coordinatesList = [];
    let features = [];
    let repData = locationHistories.find((u) => u.userId === targetUserId);

    const pathColor = repData?.pathColor !== null ? repData.pathColor : userPathColor;

    if (toggleState.path) {
      if (!repData?.locations) return;

      const pathGroups = groupLocations(repData.locations);
      setPathGrouping(pathGroups);
      // randomColor is your path color
      const randomColor =
        repData?.pathColor !== null ? repData.pathColor : userPathColor;

      const routeFeatures = [];
      const geoMarkerFeatures = [];
      const iconFeatures = [];
      const groupLineMap = {};
      const groupGeoMarkerMap = {};
      const speedsByGroup = {};

      let animating = false;
      animatingRef.current = animating;
      let distance = 0;
      distanceRef.current = distance;
      let lastTime;
      let playTimeMultiplier = 2;
      const maxPlayTimeMultiplier = 8;
      const baseSpeed = 3;
      let playTime = new Date();

      if (pathGroups.length > 0) {
        const firstGroup = pathGroups[0];
        if (firstGroup.length > 0) {
          const firstCoord = fromLonLat([firstGroup[0].lon, firstGroup[0].lat]);
          map.getView().setCenter(firstCoord);
          map.getView().setZoom(16);
        }
      }

      // Build the geometry/features for each group
      pathGroups.forEach((group, groupIndex) => {
        group.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        const coords = group.map((loc) => fromLonLat([loc.lon, loc.lat]));
        const timestamps = group.map((loc) =>
          new Date(loc.timestamp).getTime()
        );

        if (coords.length === 1) {
          const singleMarker = new Feature({
            type: "icon",
            geometry: new Point(coords[0]),
            groupIndex,
          });
          iconFeatures.push(singleMarker);
          return;
        }

        if (coords.length === 0) {
          return;
        }

        const line = new LineString(coords);
        const routeFeature = new Feature({
          type: "route",
          geometry: line,
          groupIndex,
          timestamps,
          pathColor,
        });
        routeFeatures.push(routeFeature);
        groupLineMap[groupIndex] = line;

        const startLoc = group.find((loc) => loc.isStart) || group[0];
        const endLoc = group.find((loc) => loc.isEnd) || group[group.length - 1];
        const startCoord = fromLonLat([startLoc.lon, startLoc.lat]);
        const endCoord = fromLonLat([endLoc.lon, endLoc.lat]);

        const startMarker = new Feature({
          type: "icon",
          geometry: new Point(startCoord),
          groupIndex,
          markerType: "start", // <--- "start"
        });

        const endMarker = new Feature({
          type: "icon",
          geometry: new Point(endCoord),
          groupIndex,
          markerType: "end", // <--- "end"
        });
        iconFeatures.push(startMarker, endMarker);

        const geoMarker = new Feature({
          type: "geoMarker",
          geometry: new Point(startCoord),
          groupIndex,
        });
        geoMarkerFeatures.push(geoMarker);

        // Also store it in a map so we can manipulate
        groupGeoMarkerMap[groupIndex] = geoMarker;

        const speeds = [];
        for (let i = 1; i < coords.length; i++) {
          const distance = getDistance(
            [group[i - 1].lon, group[i - 1].lat],
            [group[i].lon, group[i].lat]
          ); // in meters
          distanceRef.current = distance;
          const timeDifference = (timestamps[i] - timestamps[i - 1]) / 1000; // in seconds
          const speed = timeDifference > 0 ? distance / timeDifference : baseSpeed; // m/s
          speeds.push(speed);
        }
        speedsByGroup[groupIndex] = speeds;
      });
      setNewSpeedsByGroup(speedsByGroup);

      // 5) Create a single vector layer with all features
      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [...routeFeatures, ...iconFeatures, ...geoMarkerFeatures],
        }),
        style: featureStyle,
      });

      map.addLayer(vectorLayer);
      setPathLayer(vectorLayer);

      const speedBtn = document.getElementById("speedBtn");
      const seekerInput = document.getElementById("seeker");
      const startButton = document.getElementById("PlayPauseBtn");
      const panBtn = document.getElementById("panSwitch");
      const previousBtn = document.getElementById("previousBtn");
      const nextBtn = document.getElementById("nextBtn");
      const rewindBtn = document.getElementById("rewindBtn");
      const forwardBtn = document.getElementById("forwardBtn");
      const timeDisplay = document.querySelector(".time-display");
      const sectionBtn = document.getElementsByClassName("section-btn");

      startButton.innerHTML = `
      <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='currentColor'><path d='M8 5v14l11-7z'></path></svg>" 
      alt="Play Icon" style="vertical-align: middle;">
      `;

      // function updateTimeDisplay() {
      //   if (!routeFeatures[activeGroupIndex.current]) return;
      //   const timestamps =
      //     routeFeatures[activeGroupIndex.current]?.get("timestamps");
      //   if (!timestamps) return;

      //   const currentPointIndex = Math.floor(
      //     distance * (timestamps.length - 1)
      //   );
      //   if (timestamps[currentPointIndex]) {
      //     const currentTimestamp = new Date(timestamps[currentPointIndex]);
      //     timeDisplay.textContent = currentTimestamp.toLocaleString([], {
      //       year: "numeric",
      //       month: "2-digit",
      //       day: "2-digit",
      //       hour: "2-digit",
      //       minute: "2-digit",
      //       second: "2-digit",
      //     });
      //   }
      // }

      // 7) The moveFeature function, adapted for multiple groups
      const moveFeature = (event, config = {}) => {
        const {
          panAheadFactor = 0.3,
          speedSmoothing = 0.7,
          maxFrameTime = 100
        } = config;
      
        try {
          // 1. Animation state validation
          if (!animatingRef.current || activeGroupIndex.current == null) {
            return;
          }
      
          // 2. Critical data checks
          const activeLine = groupLineMap[activeGroupIndex.current];
          const timestamps = routeFeatures[activeGroupIndex.current]?.get('timestamps');
          const speeds = speedsByGroup[activeGroupIndex.current];
          
          if (!activeLine || !timestamps || !speeds) {
            console.error('Missing animation data', { activeLine, timestamps, speeds });
            stopAnimationRef.current();
            return;
          }
      
          // 3. Time calculation with safety limits
          const now = Date.now();
          const elapsedTime = Math.min(
            ((now - (lastTimeRef.current || now)) / 1000) * playTimeMultiplier,
            maxFrameTime / 1000
          );
          lastTimeRef.current = now;
      
          // 4. Path progress calculation
          const lineLength = activeLine.getLength();
          if (lineLength === 0) {
            console.warn('Zero-length path detected');
            return;
          }
      
          // 5. Velocity-based movement
          const currentSegment = Math.floor(distanceRef.current * (timestamps.length - 1));
          const baseSpeed = 1.4; // m/s (average walking speed)
          const rawSpeed = (speeds[currentSegment] || baseSpeed) * 3.6; // Convert to km/h
          const smoothedSpeed = (rawSpeed * speedSmoothing) + 
            ((previousSpeedRef.current || rawSpeed) * (1 - speedSmoothing));
          previousSpeedRef.current = smoothedSpeed;
      
          const speedMps = smoothedSpeed / 3.6; // Convert back to m/s
          const distanceDelta = (speedMps * elapsedTime) / lineLength;
          
          // 6. Progress update with bounds checking
          distanceRef.current = Math.min(Math.max(
            distanceRef.current + distanceDelta,
            0
          ), 1);
      
          // 7. Coordinate interpolation
          const currentCoord = activeLine.getCoordinateAt(distanceRef.current);
          if (!currentCoord) {
            console.error('Invalid coordinate calculation');
            return;
          }
      
          // 8. Marker position update
          const geoMarker = groupGeoMarkerMap[activeGroupIndex.current];
          if (geoMarker?.getGeometry()) {
            geoMarker.getGeometry().setCoordinates(currentCoord);
            
            // Update marker style based on speed
            const isWalking = smoothedSpeed < 10; // 10 km/h threshold
            geoMarker.setStyle(new Style({
              image: new Icon({
                src: isWalking ? walkingIconSvg : carIconSvg,
                scale: 0.05,
                rotation: getMovementDirection(activeLine, distanceRef.current),
              }),
            }));
          }
      
          // 9. UI updates
          seekerInput.value = Math.round(distanceRef.current * 100);
          updateTimeDisplay(timestamps, distanceRef.current);
      
          // 10. Predictive panning
          if (isPanningRef.current) {
            const view = map.getView();
            const currentCenter = view.getCenter();
            const nextCoord = activeLine.getCoordinateAt(
              Math.min(distanceRef.current + panAheadFactor, 1)
            );
            
            if (currentCenter && nextCoord) {
              const panVector = [
                nextCoord[0] - currentCenter[0],
                nextCoord[1] - currentCenter[1]
              ];
              
              view.animate({
                center: [
                  currentCenter[0] + panVector[0] * 0.3,
                  currentCenter[1] + panVector[1] * 0.3
                ],
                duration: 300,
                easing: easeOut
              });
            }
          }
      
          // 11. Completion handling
          if (distanceRef.current >= 0.999) {
            stopAnimationRef.current();
            distanceRef.current = 1;
            seekerInput.value = 100;
            updateTimeDisplay(timestamps, 1);
          }

          // setAnimationState(prev => ({
          //   ...prev,
          //   progress: distanceRef.current
          // }));
      
          // 12. Force redraw
          map.render();
        } catch (error) {
          console.error('Animation error:', error);
          stopAnimationRef.current();
        }
      };
      
      // Helper function to calculate movement direction
      const getMovementDirection = (line, distance) => {
        const sampleDistance = Math.max(0.01, Math.min(0.99, distance));
        const prevCoord = line.getCoordinateAt(sampleDistance - 0.01);
        const nextCoord = line.getCoordinateAt(sampleDistance + 0.01);
        
        if (!prevCoord || !nextCoord) return 0;
        
        const dx = nextCoord[0] - prevCoord[0];
        const dy = nextCoord[1] - prevCoord[1];
        return Math.atan2(dy, dx);
      };
      
      // Time display update function
      const updateTimeDisplay = (timestamps, progress) => {
        if (!timestamps?.length) return;
        
        const index = Math.floor(progress * (timestamps.length - 1));
        const timestamp = timestamps[Math.min(index, timestamps.length - 1)];
        
        if (timestamp && timeDisplayElement) {
          timeDisplayElement.textContent = new Date(timestamp).toLocaleString([], {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          });
        }
      };
      function startAnimation(groupIndex) {
        activeGroupIndex.current = groupIndex;
        if (
          activeGroupIndex.current === null ||
          activeGroupIndex.current === undefined
        )
          return;

        animating = true;
        animatingRef.current = animating;
        // setAnimationState(prev => ({
        //   ...prev,
        //   isPlaying: true,
        //   currentGroup: groupIndex,
        //   progress: 0
        // }));
        // distance = 0;
        // distanceRef.current = distance;
        seekerInput.value = 0;
        lastTime = Date.now();

        const startCoord = groupLineMap[groupIndex]?.getFirstCoordinate();
        map.getView().setCenter(startCoord);
        map.getView().setZoom(16);

        startButton.innerHTML = `
            <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='currentColor'><rect x='6' y='4' width='4' height='16'></rect><rect x='14' y='4' width='4' height='16'></rect></svg>"
            alt="Pause Icon" style="vertical-align: middle;">
          `;

        vectorLayer.on("postrender", moveFeature);
      }
      startAnimationRef.current = startAnimation;

      function resumeAnimation() {
        if (animating) return;

        animating = true;
        animatingRef.current = animating;
        // setAnimationState(prev => ({
        //   ...prev,
        //   isPlaying: true
        // }));
        lastTime = Date.now();

        startButton.innerHTML = `
            <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='currentColor'><rect x='6' y='4' width='4' height='16'></rect><rect x='14' y='4' width='4' height='16'></rect></svg>"
            alt="Pause Icon" style="vertical-align: middle;">
          `;

        vectorLayer.on("postrender", moveFeature);

        // Ensure map re-renders
        map.render();
      }
      resumeAnimationRef.current = resumeAnimation;

      function stopAnimation() {
        animating = false;
        animatingRef.current = animating;
        // setAnimationState(prev => ({
        //   ...prev,
        //   isPlaying: false
        // }));
        // Detach the moveFeature handler
        vectorLayer.un("postrender", moveFeature);

        // Ensure seeker reflects the exact paused progress
        const seekerValue = Math.min(Math.round(distanceRef.current * 100), 100); // Convert to percentage (0-100)
        seekerInput.value = seekerValue;

        // Update play button to "Play"
        startButton.innerHTML = `
            <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='currentColor'><path d='M8 5v14l11-7z'></path></svg>"
            alt="Play Icon" style="vertical-align: middle;">
          `;
      }
      stopAnimationRef.current = stopAnimation;

      animatingRef.current = animating;
      distanceRef.current = distance;

      speedBtn?.addEventListener("click", () => {
        playTimeMultiplier = (playTimeMultiplier % maxPlayTimeMultiplier) + 1;
        speedBtn.textContent = `x${playTimeMultiplier}`;
        speedBtn.style.color = "black";
      });

      console.log("activeGroupIndex from map.js", activeGroupIndex);
      // Hook up startButton click to toggle animation
      startButton?.addEventListener("click", function () {
        if (animating) {
          stopAnimation(); // Pause the animation
        } else if (distance === 1) {
          // Reset the animation to start over
          distance = 0;
          distanceRef.current = distance;
          startAnimation(activeGroupIndex.current); // Restart from the current group
        } else if (distance === 0) {
          startAnimation(activeGroupIndex.current); // Start fresh
        } else {
          resumeAnimation(); // Resume from the current state
        }
      });

      // Next button functionality
      nextBtn?.addEventListener("click", function () {
        const groupIndices = Object.keys(groupLineMap);
        activeGroupIndex.current =
          (activeGroupIndex.current + 1) % groupIndices.length;

        // Reset distance and lastTime
        distance = 0;
        distanceRef.current = distance;
        lastTime = Date.now();

        // Center map on the start coordinate (Point A)
        const startCoord =
          groupLineMap[activeGroupIndex.current].getFirstCoordinate();
        map.getView().setCenter(startCoord);
        map.getView().setZoom(16);

        // Start animation for the new group
        startAnimation(activeGroupIndex.current);
      });

      // Rewind button functionality
      rewindBtn?.addEventListener("click", function () {
        const timestamps =
          routeFeatures[activeGroupIndex.current]?.get("timestamps");
        if (!timestamps) return;

        playTime = new Date(playTime.getTime() - 10000); // Subtract 10 seconds
        const totalDuration = timestamps[timestamps.length - 1] - timestamps[0];
        const fractionToSubtract = 10000 / totalDuration;
        distance -= fractionToSubtract;

        if (distance < 0) {
          distance = 0;
          distanceRef.current = distance;
        }
        moveFeature({ frameState: { time: lastTime - 10000 } });
      });

      // Forward button dynamic adjustment
      forwardBtn?.addEventListener("click", () => {
        distance += 0.1; // Forward by 10% of the path
        if (distance >= 1) {
          distance = 0; // Reset to start if at the end
          distanceRef.current = distance;
          stopAnimation();
        }
        moveFeature({ frameState: { time: lastTime || Date.now() } });
        map.render();
      });

      // 10) Seeker logic (slider) - if you want it to manually set `distance`
      seekerInput?.addEventListener("input", function (e) {
        const val = parseFloat(e.target.value);
        if (!isNaN(val)) {
          distance = val / 100;
          distanceRef.current = distance;
          moveFeature({ frameState: { time: lastTime || Date.now() } });
          map.render();
        }
      });

      // Previous button functionality
      previousBtn?.addEventListener("click", function () {
        const groupIndices = Object.keys(groupLineMap);
        activeGroupIndex.current =
          (activeGroupIndex.current - 1 + groupIndices.length) %
          groupIndices.length;
        startAnimation(activeGroupIndex.current);
      });

      // 11) Make each path group clickable
      map.on("click", function (evt) {
        map.forEachFeatureAtPixel(evt.pixel, function (clickedFeature) {
          const gIndex = clickedFeature.get("groupIndex");
          if (typeof gIndex !== "undefined") {
            activeGroupIndex.current = gIndex;
            // If we want to start animation for that group
            if (animating) {
              stopAnimation();
            }
            startAnimation(gIndex);
          }
        });
      });
    } else if (toggleState.currentLocation) {

      // const activeHistoryResponse = await 

      const currentLocation = repLatestLocation.find((u) => u.userId === targetUserId);
      const transformedCoords = fromLonLat([
        currentLocation.longitude,
        currentLocation.latitude,
      ]);
      let locFeature = new Feature({
        geometry: new Point(transformedCoords),
        data: {
          info: `TimeStamp: ${new Date(
            currentLocation.recordedAt
          ).toLocaleTimeString()}`,
          name: repData.name,
          repId: repData.userId,
        },
        isPath: true,
        isLast: true,
        repProfile: repData.profile,
        repId: repData.userId,
      });
      features.push(locFeature);

      const vectorSource = new VectorSource({
        features: features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: function (feature) {
          if (feature.get("isLast") && feature.get("isPath") && !feature.get("overlayCreated")) {
            createOverlay(feature); // Create overlay once per feature
            feature.set("overlayCreated", true);
          }
        },
      });
      map.addLayer(vectorLayer);
      setPathLayer(vectorLayer);
      map.getView().setCenter(transformedCoords);
      map.getView().setZoom(20);


    } else if (toggleState.activity) {
      repData.locations.map((loc, index) => {
        // let isLast = index == repData.locations.length - 1;

        const isLast = index === repData.locations.length - 1;

        const transformedCoords = fromLonLat([loc.lon, loc.lat]);
        let feature = new Feature({
          geometry: new Point(transformedCoords),
          data: {
            info: `TimeStamp: ${new Date(loc.timestamp).toLocaleTimeString()}`,
            name: repData.name,
            repId: repData.userId,
            activity: loc.activityHistory || null,
          },
          isPath: true,
          isLast,
          repProfile: isLast ? repData.profile : null,
          isActivity: !!loc.activityHistory,
          activityHistory: loc.activityHistory || null,
          repId: isLast ? repData.userId : null,
          pathColor,
        });

        if (loc.activityHistory) {
          features.push(feature);
        }
        coordinatesList.push(transformedCoords);
      });

      let featureLine = new Feature({
        geometry: new LineString(coordinatesList),
        // randomColor: randomColor,
        randomColor: pathColor,
      });

      features.push(featureLine);


      const vectorSource = new VectorSource({
        features: features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: function (feature) {
          if (feature.get("isActivity")) {
            return new Style({
              image: new CircleStyle({
                radius: 7, // Larger circle for activities.
                fill: new Fill({ color: "#2761D0" }), // Tomato color.
                stroke: new Stroke({ color: "#fff", width: 1 }),
              }),
            });
          }
        },
      });
      map.addLayer(vectorLayer);
      setPathLayer(vectorLayer);

      const popupElement = document.getElementById("popup");
      const popup = new Overlay({
        element: popupElement,
        positioning: "bottom-center",
        stopEvent: false,
        offset: [0, -10],
      });

      map.addOverlay(popup);
      handleClickMap(popup);
    }
  }, [locationHistories, toggleState, targetUserId, forceUpdateActiveGroupIndex]);

  useEffect(() => {
    console.log(" activeGroupIndex.current from useEffect: ", activeGroupIndex.current);
  }, [forceUpdateActiveGroupIndex]);
  useEffect(() => {
    if (!map) return;

    if (repLocationsLayer) {
      map.removeLayer(repLocationsLayer);
      setRepLocationsLayer(null);
    }

    Object.values(overlaysRef.current).forEach((overlay) => {
      map.removeOverlay(overlay);
    });
    overlaysRef.current = {};

    if (!liveTrackingMode) return;

    if (!repLatestLocation || repLatestLocation.length === 0) return;

    const features = [];

    repLatestLocation.forEach((rep) => {
      const repData = locationHistories.find((u) => u.userId === rep.userId);
      if (repData) {
        const transformedCoords = fromLonLat([rep.longitude, rep.latitude]);
        const locFeature = new Feature({
          geometry: new Point(transformedCoords),
          data: {
            info: `TimeStamp: ${new Date(rep.recordedAt).toLocaleTimeString()}`,
            name: repData.name,
            repId: repData.userId,
          },
          isLast: true,
          repProfile: repData.profile,
          repId: repData.userId,
        });
        features.push(locFeature);
        createOrUpdateOverlay(locFeature, map);
      }
    });

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: null,
    });

    map.addLayer(vectorLayer);
    setRepLocationsLayer(vectorLayer);

    // Center the map on the first rep's location
    if (features.length > 0) {
      const firstRepCoords = features[0].getGeometry().getCoordinates();
      // map.getView().setCenter(fromLonLat([-98.5795, 39.8283]));
      // map.getView().setZoom(10); // Adjust zoom level as needed
    }
  }, [map, repLatestLocation, liveTrackingMode, locationHistories]);

  function createOrUpdateOverlay(feature, map) {
    const repId = feature.get("repId");
    if (repId !== null){
      setCurrentSalesRepId(repId);
      console.log("CurrentSalesRepId from Map.js: ", currentSalesRepId);
    }
    const coordinates = feature.getGeometry().getCoordinates();

    // Remove existing overlay for this rep if it exists
    if (overlaysRef.current[repId]) {
      map.removeOverlay(overlaysRef.current[repId]);
    }

    const imgSrc = feature.get("repProfile");
    const name = feature.get("data").name || "Unnamed";
    const overlayElement = document.createElement("div");
    overlayElement.style.width = "3rem";
    overlayElement.style.height = "3rem";
    overlayElement.style.borderRadius = "50%";
    overlayElement.style.display = "flex";
    overlayElement.style.flexDirection = "column";
    overlayElement.style.alignItems = "center";
    overlayElement.style.objectFit = "cover";

    // const nameLabel = document.createElement('div');
    // nameLabel.textContent = name;
    // nameLabel.style.fontSize = '12px';
    // nameLabel.style.fontWeight = 'bold';
    // nameLabel.style.marginBottom = '5px';
    // nameLabel.style.color = '#fff';
    // nameLabel.style.textShadow = '0px 0px 5px rgba(0, 0, 0, 0.7)';

    const imgElement = document.createElement("img");
    imgElement.src = imgSrc;
    imgElement.onclick = () => {
      console.log("Image Clicked", repId);
      setSelectedSalesRep(repId);
      setPosition(coordinates);
      map.getView().setCenter(coordinates);
      // You can add additional functionality here, like setShowRepPath(repId)
    };
    imgElement.style.width = "3rem";
    imgElement.style.height = "3rem";
    imgElement.style.objectFit = "cover";
    imgElement.style.borderRadius = "50%";

    // overlayElement.appendChild(nameLabel);
    overlayElement.appendChild(imgElement);

    const overlay = new Overlay({
      element: overlayElement,
      positioning: "center-center",
      stopEvent: false,
      offset: [0, 0],
    });

    overlay.set("name", "image");

    map.addOverlay(overlay);
    overlay.setPosition(coordinates);

    // Store the new overlay in our ref
    overlaysRef.current[repId] = overlay;
  }

  const handleClickMap = (popup) => {
    map.on("click", (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const coordinates = feature.getGeometry().getCoordinates();

        // if (feature.get('isLast')) {
        //   showRepPath.current = feature.get('repId');
        //   console.log("last Clicked")
        // }

        // if (feature.get('isPath')) {
        //   const info = feature.get('data').info;
        //   popup.setPosition(coordinates);
        //   document.getElementById('popup-content').innerHTML = info;
        //   document.getElementById('popup').style.display = 'block';

        // }

        if (feature.get("isActivity")) {
          const activity = feature.get("data").activity;
          popup.setPosition(coordinates);
          let iconFrom =
            "data:image/png;base64," +
            mapIcons.find((icon) => icon.id === activity.iconFromId)?.image;
          let iconTo =
            "data:image/png;base64," +
            mapIcons.find((icon) => icon.id === activity.iconToId)?.image;
          let nameOfCustomer = activity?.lead?.name
            ? activity?.lead?.name
            : "No Name";
          let date = new Date(activity.timestamp).toLocaleDateString();
          let time = new Date(activity.timestamp).toLocaleTimeString();
          function onClick() {
            document.getElementById("popup").style.display = "none";
          }

          const snapToLocation = () => {
            fiberHouseClicked(activity.lead.fiberHouse);
            let lonlat = [
              activity.lead.fiberHouse.coordinates.longitude,
              activity.lead.fiberHouse.coordinates.latitude,
            ];
            map.getView().setCenter(fromLonLat(lonlat));
            map.getView().setZoom(21);
          };

          if (document.getElementById("popup-content") != null) {
            document.getElementById(
              "popup-content"
            ).innerHTML = `<div class="w-32">
      <p class="text-sm text-red-500"  id="close_button" >Close</p>
      <p class=" text-center font-bold mb-2">${nameOfCustomer}</p>
      <p class="text-grey text-center">${date}</p>
      <p class="text-grey text-center">${time}</p>
      <div class="flex items-center justify-center gap-4">
      <img class="w-8 h-8" src=${iconFrom} alt="activity" />
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      <img class="w-8 h-8" src=${iconTo} alt="activity" />
      </div>
      <p id="snap_button" class="text-center underline text-[#2761D0]" >Go To Lead</p>
      </div>`;
            document.getElementById("popup").style.display = "block";
            document
              .getElementById("close_button")
              .addEventListener("click", onClick);
            document
              .getElementById("snap_button")
              .addEventListener("click", snapToLocation);
          }
        }
      });
    });
  };

  function createOverlay(feature) {
    const imgSrc = feature.get("repProfile");
    const coordinates = feature.getGeometry().getCoordinates();
    const name = feature.get("data").name || "Unnamed";
    const overlayElement = document.createElement("div");
    overlayElement.style.width = "100px";
    overlayElement.style.height = "100px";
    overlayElement.style.borderRadius = "50%";
    overlayElement.style.display = "flex";
    overlayElement.style.flexDirection = "column";
    overlayElement.style.alignItems = "center";
    overlayElement.style.objectFit = "cover";

    const nameLabel = document.createElement("div");
    nameLabel.textContent = name;
    nameLabel.style.fontSize = "12px";
    nameLabel.style.fontWeight = "bold";
    nameLabel.style.marginBottom = "5px";
    nameLabel.style.color = "#fff";
    nameLabel.style.textShadow = "0px 0px 5px rgba(0, 0, 0, 0.7)";

    const imgElement = document.createElement("img");
    imgElement.src = imgSrc;
    imgElement.onclick = () => {
      // setShowRepPath(feature.get('repId'));
    };
    imgElement.style.width = "50%";
    imgElement.style.height = "50%";
    imgElement.style.objectFit = "cover";
    imgElement.style.borderRadius = "50%";

    overlayElement.appendChild(nameLabel);
    overlayElement.appendChild(imgElement);

    const overlay = new Overlay({
      element: overlayElement,
      positioning: "center-center",
      stopEvent: false,
      offset: [0, 0],
    });

    overlay.set("name", "image");

    map.addOverlay(overlay);

    overlay.setPosition(coordinates); // Set the overlay at feature coordinates
  }

  // useEffect(
  //   () => {
  //     if (map && pathFeature) {
  //       pathFeature.forEach((coordinatesList) => {
  //         if (coordinatesList.length > 0) {
  //           const marker = new Feature({
  //             geometry: new Point(coordinatesList[0]),
  //           });

  //           const markerStyle = new Style({
  //             image: new CircleStyle({
  //               radius: 15,
  //               fill: new Fill({ color: 'red' }),
  //               stroke: new Stroke({ color: 'black', width: 2 })
  //             })
  //           });

  //           marker.setStyle(markerStyle);
  //           pathLayer.getSource().addFeature(marker);
  //           let index = 0;
  //           const speed = 100; // speed of the animation
  //           console.log(coordinatesList)
  //           function animateMarker() {
  //             if (index < coordinatesList.length - 1) {
  //               index++;
  //               console.log(index)
  //               marker.getGeometry().setCoordinates(coordinatesList[index]);
  //               setTimeout(animateMarker, speed);
  //             }
  //           }

  //           animateMarker();
  //         }
  //       })
  //     }
  //   }, [playTime, pathFeature]
  // )

  const lightningBolts = [9, 10, 11, 12, 35];

  useEffect(() => {
    if (!shouldReRenderMap.current) {
      shouldReRenderMap.current = true;
      return;
    }
    if (fiberHouses && map) {
      if (cluster.current) {
        cluster.current.getSource().getSource().clear();
        cluster.current.getSource().clear();
        map.removeLayer(cluster.current);
        cluster.current = null;
        map.render();
        setClusterLayer(null);
      }

      const features = [];
      const coordinates = [];

      for (let i = 0; i < fiberHouses.length; i++) {
        const fiberHouse = fiberHouses[i];

        if (!fiberHouse.remove) {
          let coordinate = [
            fiberHouse.coordinates.longitude,
            fiberHouse.coordinates.latitude,
          ];
          let feature = new Feature(new Point(fromLonLat(coordinate)));
          coordinates.push(coordinate);

          if (fiberHouse.id) {
            feature.set("id", fiberHouse.id);
            feature.set("fiber_house", true);
            feature.set("mapIconId", fiberHouse.mapIconId);
            feature.set("selected", fiberHouse.selected);
            feature.set("isBundled", fiberHouse.isBundled);
            feature.set("appointment", fiberHouse.appointmentDateTime);
          } else {
            console.error("fiberHouse.id is not defined");
          }

          feature.set("isNewHouse", !!fiberHouse.isNewHouse);

          features.push(feature);
        }
      }

      rTree.current = new RBush();
      rTree.current.load(
        features.map((house) => {
          return {
            minX: house.getGeometry().getCoordinates()[0],
            minY: house.getGeometry().getCoordinates()[1],
            maxX: house.getGeometry().getCoordinates()[0],
            maxY: house.getGeometry().getCoordinates()[1],
            feature: house,
          };
        })
      );

      // console.log("Duplicates: ", duplicates);
      const vectorSource = new VectorSource({
        features: features,
        loader: function (extent, resolution, projection) {
          const extentFeatures = rTree.current.search(extent);
          const features = extentFeatures.map((item) => item.feature);
          vectorSource.addFeatures(features);
        },
        strategy: bboxStrategy,
      });

      const clusterSource = new Cluster({
        distance:  20,
        source: vectorSource,
      });
      const styleCache = {};
      let clusters = null;

      clusters = new VectorLayer({
        source: clusterSource,
        name: "fiberHouses",
        style: function (feature) {
          const size = feature.get("features").length;

          let style = styleCache[size];
          if (size === 1) {
            let houseMapIcon = mapIcons.find(
              (mi) => mi.id === feature.get("features")[0]?.get("mapIconId")
            );

            let imgScale = 1;
            if (!houseMapIcon) {
              houseMapIcon = LoadingIcon;
              imgScale = 0.1;
            } else {
              houseMapIcon = "data:image/png;base64," + houseMapIcon?.image;
              imgScale = 0.5;
            }
            if (
              selectedFiberHouseRef.current &&
              selectedFiberHouseRef.current.id ===
              feature.get("features")[0].get("id")
            ) {
              style = [
                new Style({
                  image: new Icon({
                    scale: SELECTED_MAPICON_SCALE, // Adjust this value to change the scale of the icon
                    src: houseMapIcon,
                  }),
                  // zIndex: 11100,
                }),
                new Style({
                  image: new CircleStyle({
                    radius: CIRCLE_RADIUS, // This is now in pixels
                    stroke: new Stroke({
                      color: "white", // Border color
                      width: 4, // Border width
                    }),
                    fill: new Fill({
                      color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                  }),
                }),
              ];

              if (feature.get("features")[0].get("isBundled")) {
                style.push(
                  new Style({
                    text: new Text({
                      text: "TV",
                      fill: new Fill({
                        color: "#fff", // White text color
                      }),
                      font: "bold 12px Arial",
                      scale: 1.2,
                      textAlign: "center",
                      textBaseline: "middle",
                      offsetX: 10,
                      offsetY: 11,
                    }),
                  })
                );
              }

           



              return style;
            }

            // if (feature.get("features")[0].get("selected")) {
            //   style = [
            //     new Style({
            //       image: new Icon({
            //         src: houseMapIcon,
            //         scale: imgScale,
            //         // anchor: [0.5, 1],
            //       }),
            //     }),
            //     new Style({
            //       image: new CircleStyle({
            //         radius: 15, // This is now in pixels
            //         stroke: new Stroke({
            //           color: "blue", // Border color
            //           width: 4, // Border width
            //         }),
            //         fill: new Fill({
            //           color: "rgba(0, 0, 0, 0)", // Transparent fill
            //         }),
            //       }),
            //     }),
            //   ];

            //   return style;
            // }

          
              if (feature.get("features")[0].get("appointment")) {
                // Create calendar icon element
                const iconElement = document.createElement('div');
                iconElement.innerHTML = '📅'; // Calendar emoji
                iconElement.style.cssText = `
                  cursor: pointer;
                  font-size: 20px;
                  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
                `;
              
                // Create overlay for the appointment
                const appointmentOverlay = new Overlay({
                  element: iconElement,
                  positioning: 'bottom-center',
                  stopEvent: true, // Allow interaction
                  offset: [10, 25],
                  position: feature.getGeometry().getCoordinates(),
                  
                });
              
                // Add click handler to the icon
                iconElement.addEventListener('click', (e) => {
                  e.stopPropagation();
                  setSelectedFiberHouse(
                    {
                      id: feature.get("features")[0].get("id"),
                      mapIconId: feature.get("features")[0].get("mapIconId"),
                    }
                  )
                  setIndex(-1);
                  setSelectedFiberHouseFeature(feature.get("features")[0]);
                  map.getView().setCenter(feature.getGeometry().getCoordinates());
                  setCalendarPopup(true);
                  // Add your click handler logic here
                });
              
                map.addOverlay(appointmentOverlay);
              }

            if (feature.get("features")[0].get("isBundled")) {
              style = [
                new Style({
                  image: new Icon({
                    src: houseMapIcon,
                    scale: imgScale,
                    anchor: [0.5, 1],
                  }),
                }),
                new Style({
                  text: new Text({
                    text: "TV",
                    fill: new Fill({
                      color: "#fff", // White text color
                    }),
                    font: "bold 12px Arial", // Make the text bold and clear
                    scale: 1.2, // Scale the text for clarity
                    textAlign: "center", // Center align text
                    textBaseline: "middle", // Vertically center text
                    offsetX: 10, // Offset the text to match the circle's displacement
                    offsetY: -2, // Offset to ensure "B" stays in the middle of the circle
                  }),
                }),
              ];
              return style;
            }

            style = [
              new Style({
                image: new Icon({
                  src: houseMapIcon,
                  scale: imgScale,
                  anchor: [0.5, 0.5],
                }),
              }),
              new Style({
                image: new CircleStyle({
                  radius: 22, // This is now in pixels
                  fill: new Fill({
                    color: "rgba(0, 0, 0, 0)", // Transparent fill
                  }),
                }),
              }),
            ];
          } else {
            style = new Style({
              image: new CircleStyle({
                radius: 22,
                stroke: new Stroke({
                  color: "#fff",
                  width: 3,
                }),
                fill: new Fill({
                  color: map.getView().getZoom() > 18 ? "#800080" : "#2761D0",
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
                font: "bold 10px Arial",
                scale: 1.2,
              }),
            });
          }

          return style;
        },
      });

      cluster.current = clusters;
      setClusterLayer(clusterSource);
      clusters.setZIndex(11000);
      map.addLayer(clusters);
      map.getView().setZoom(map.getView().getZoom() + 0.1);
      // vectorSource.refresh();
      // map.addInteraction(modify);
    }
  }, [fiberHouses, mapIcons]);

  useEffect(() => {
    if (map) {
      map.on("click", handleMapClick);

      return () => {
        map.un("click", handleMapClick);
      };
    }
  }, [map, isDrawingToolActive, popupEnabled, screenWidth, selectedFiberHouse]);

  const handleMapClick = (event) => {
    mapClickHandler(event);
  };

  const searchSoldLeadsForBoltMode = async () => {
    setFiberHousesLoading(true);
    const req = new StringIntReq();
    if (user.userType === "SalesRep" || user.userType === "SalesOrgAdmin") {
      req.int =
        user.fiberCompanyId || user.salesRepFiberCompanies[0].fiberCompanyId;
    }

    const res = await FiberHouseApi.SearchSoldFiberHousesForBoltMode(req);
    if (res.status.success) {
      setFiberHouses(res.data);
      setFiberHousesLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.status.message,
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
      setFiberHousesLoading(false);
    }
  };

  useEffect(() => {
    
    if(initialRender.current){
      initialRender.current = false;
      return;
    }

    if (!triggerBoltModeSearch) {
      setTriggerBoltModeSearch(true);
      return;
    }
    if (boltMode) {
      setIndex(-1);
      searchSoldLeadsForBoltMode();
    } else {
      setIndex(-1);
      searchFiberHouses();
    }
  }, [boltMode]);

  function mapClickHandler(event) {
    let count = 0;
    setIsMiniLeadCardVisible(false);
    selectedFiberHouseRef.current = null;
    setSelectedFiberHouse(null);
    setSelectedFiberHouseFeature(null);
    setSelectedSalesRep(null);
    setCalendarPopup(false);
    clearPreviousSelections();
    // if(clusterLayer){
    //   resetIconStyle(clusterLayer.getSource());
    // }
    console.log("Map Clicked");
    if (createMode.current) return;
    map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
      const size = feature?.get("features")?.length;
      const originalFeatures = feature.get("features");
      if (count > 0) {
        return;
      }

      if (isDrawingToolActive) {
        return;
      }

      if (
        (Object.keys(feature.values_)[0] === "stat" && !isDrawingToolActive) ||
        feature.get("type") === "Tag"
      ) {
        let helper = new MapHelper();
        if (feature.get("type") === "Tag") {
          const geometry = feature.getGeometry();
          const coordinates = geometry.getCoordinates();
          setSearchAreaFeature([feature]);

          let area = coordinates[0].map((coord) => {
            const [lng, lat] = toLonLat(coord);
            return { latitude: lat, longitude: lng };
          });
          setSearchArea(area);
          const list = [];
          fiberHouses.forEach((fiberHouse) => {
            if (
              helper.checkIfFiberHouseIsInsideServicableArea(fiberHouse, area)
            ) {
              list.push(fiberHouse);
            }
          });
          setFiberHouseIds(list.map((fiberHouse) => fiberHouse.id));
        }

        setIndex(8);
        count++;
        return;
      }

      if (size > 1) {
        // const coordinates = feature.getGeometry().getCoordinates();
        if (screenWidth > 1000 && originalFeatures.length > 20000) {
          Swal.fire({
            icon: "warning",
            title: "Too Many Houses",
            text: "Limit is 20000 houses per cluster.",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }

        if (screenWidth < 1000 && originalFeatures.length > 5000) {
          Swal.fire({
            icon: "warning",
            title: "Too Many Houses",
            text: "Limit is 5000 houses per cluster.",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }

        let fiberHouseIds = [];
        originalFeatures.map((f) => {
          if (f.get("id") !== undefined) {
            fiberHouseIds.push(f.get("id"));
          }
        });

        setFiberHouseIds(fiberHouseIds);
        setIndex(8);
        count++;
        return;
      } else if (
        (zoom === "ZoomIn" && feature.get("fiber_house")) ||
        (size === 1 && originalFeatures[0].get("fiber_house"))
      ) {
        let selectedFeature = null;
        let fiberHouseId = null;

        // if (zoom === "ZoomIn") {
        //   fiberHouseId = feature.get("id");
        //   selectedFeature = feature;

        // } else {
        fiberHouseId = originalFeatures[0].get("id");
        selectedFeature = originalFeatures[0];

        // }
        // const fiberHouseIndex = binarySearchFiberHouses(fiberHousesRef.current, fiberHouseId);

        // const fiberHouseIndex = binarySearch(fiberHousesRef.current, fiberHouseId);
        // const fiberHouse = fiberHousesRef.current[fiberHouseIndex];

        if (!isNaN(fiberHouseId)) {
          // handleLastLead({
          //   id: fiberHouseId,
          //   mapIconId: selectedFeature.get("mapIconId"),
          // })

          if (popupEnabled) {
            if (
              isMiniLeadCardVisible &&
              selectedFeature.get("id") == selectedFiberHouse.id
            ) {
              setIsMiniLeadCardVisible(false);
              fiberHouseClicked({
                id: fiberHouseId,
                mapIconId: selectedFeature.get("mapIconId"),
              });
              return;
            }

            setIsMiniLeadCardVisible(true);
            setSelectedFiberHouseFeature(selectedFeature);

            const coor = selectedFeature.getGeometry().getCoordinates();
            setPosition(coor);

            setSelectedFiberHouse({
              id: fiberHouseId,
              mapIconId: selectedFeature.get("mapIconId"),
            });
            setIndex(-1);
          } else {
            setSelectedFiberHouseFeature(selectedFeature);
            const coor = selectedFeature.getGeometry().getCoordinates();
            setPosition(coor);

            setSelectedFiberHouse({
              id: fiberHouseId,
              mapIconId: selectedFeature.get("mapIconId"),
            });
            fiberHouseClicked({
              id: fiberHouseId,
              mapIconId: selectedFeature.get("mapIconId"),
            });
          }
          // setIndex(-1);
          // fiberHouseClicked({
          //   id: fiberHouseId,
          //   mapIconId: selectedFeature.get("mapIconId"),
          // });
          // console.log(fiberHouse);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === selectedFeature.get("mapIconId")
          );

          const newStyle = new Style({
            image: new Icon({
              scale: SELECTED_MAPICON_SCALE, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
              // height:30,
              // width:30
            }),
            // zIndex: 11100,
          });
          if (clusterLayer) resetIconStyle(clusterLayer?.getSource());
          // Apply the new style to the feature
          const circleStyle = new Style({
            image: new CircleStyle({
              radius: CIRCLE_RADIUS, // This is now in pixels
              stroke: new Stroke({
                color: "white", // Border color
                width: 4, // Border width
              }),
              fill: new Fill({
                color: "rgba(0, 0, 0, 0)", // Transparent fill
              }),
            }),
          });
          let modify = null;
          // if (zoom === "ZoomIn") {
            // modify = new Modify({
            //   features: new Collection([feature]),
            //   hitDetection: new Collection([feature]),
            //   style: null,
            // });
          // } else {/
            modify = new Modify({
              features: new Collection([originalFeatures[0]]),
              hitDetection: new Collection([originalFeatures[0]]),
              style: null,
            });
          // }
          // const modify = new Modify({
          //     features: new Collection([originalFeatures[0]]),
          //     hitDetection: new Collection([originalFeatures[0]]),
          // });
          map.getInteractions().forEach((interaction) => {
            if (interaction instanceof Modify) {
              map.removeInteraction(interaction);
              // console.log("removed");
            }
          });
          map.render()
          modify.on("modifyend", async function (event) {
            // Swal.fire({
            //   icon: "info",
            //   title: "Please Wait",
            //   text: "Moving Fiber House...",
            //   toast: true,
            //   position: "bottom-end",
            //   didOpen: () => {
            //     Swal.showLoading();
            //   },
            // });
            const coordinates = event.features
              .getArray()[0]
              .getGeometry()
              .getCoordinates();
            const req = new EditFiberHouseReq();
            req.id = selectedFeature.get("id");

            req.coordinate = {
              latitude: toLonLat(coordinates)[1],
              longitude: toLonLat(coordinates)[0],
            };
            req.salesRepId = user?.id;
            const res = await FiberHouseApi.EditFiberHouse(req);
            if (res?.status?.success) {
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message || "Fiber House Move Failed",
                didOpen: () => {
                  Swal.hideLoading();
                },
              });
            }
          });
          map.addInteraction(modify);

          // Apply the circle style to the feature
          feature.setStyle([
            newStyle, // This is the icon style
            circleStyle, // This is the circle style
          ]);
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
        count++;
        return;
      }
    });
  }

  // Map INIT
  useEffect(() => {
    setMap(
      new OlMap({
        layers: [
          new OlLayerTile({
            name: "OSM",
            source: new OlSourceOsm(),
          }),
        ],
        view: new OlView({
          center: fromLonLat([-98.5795, 39.8283]),
          zoom: 4,
          zoomDelta: 0.5,
        }),
      })
    );
  }, []);

const location = useLocation();
useEffect(() => {
  if (location.state?.location && map) {
    console.log("Moving map to:", location.state.location.lat, location.state.location.lng);
    
    setTimeout(() => {
      map.getView().setCenter(fromLonLat([location.state.location.lng, location.state.location.lat]));
      map.getView().setZoom(location.state.zoom || 16);
    }, 500); // Small delay to ensure the map is fully initialized
  }
}, [location, map]); // Runs when location or map changes


  useEffect(() => {
    if (map) {
      const mouseWheelZoom = new MouseWheelZoom({
        duration: 100,
        constrainResolution: true,
        maxDelta: 1.5,
        timeout: 50,
      });
      map.addInteraction(mouseWheelZoom);
      const pinchZoom = new PinchZoom({
        duration: 200, // Adjust this value for faster zooming
      });

      const customIcon = document.createElement("img");
      customIcon.src = "/images/map/compassarrow.svg"; // Path to your custom icon
      customIcon.alt = "compass";
      customIcon.style.width = "50px"; // Adjust size as needed
      customIcon.style.height = "50px"; // Adjust size as needed

      const customRotateControl = new Rotate({
        duration: 1000, // Adjust this value for faster rotation
        label: customIcon, // Use the custom icon as the label
        tipLabel: "Rotate", // Tooltip text
        autoHide: false, // Always show the control
      });

      map.addControl(customRotateControl);

      // map.addInteraction(pinchZoom);
      // const keyboardPan = new KeyboardPan({
      //   condition: (event) => true, // Allow panning with any keyboard event
      //   pixelDelta: 128, // Number of pixels to pan per key press
      // });
      // map.addInteraction(keyboardPan);
    }
  }, [map]);

  //Set Context Map Object
  useEffect(() => {
    if (map) {
      setMapObject(map);
    }
  }, [map]);

  //Set Map Center to current location

  function openNextRep() {
    if (!selectedSalesRep) return;

    const currentRepIndex = repLatestLocation.findIndex(
      (rep) => rep.userId === selectedSalesRep
    );
    if (currentRepIndex === -1) return;

    const currentRep = repLatestLocation[currentRepIndex];
    const currentLongitude = currentRep.longitude;

    let nearestRep = null;
    let minDistance = Infinity;

    repLatestLocation.forEach((rep) => {
      if (rep.userId !== selectedSalesRep && rep.longitude > currentLongitude) {
        const distance = getDistance(
          [currentRep.longitude, currentRep.latitude],
          [rep.longitude, rep.latitude]
        );
        distanceRef.current = distance;
        if (distance < minDistance) {
          minDistance = distance;
          nearestRep = rep;
        }
      }
    });

    if (nearestRep) {
      const locationHistory = locationHistories.find(
        (loc) => loc.userId === nearestRep.userId
      );
      if (locationHistory) {
        setPosition(fromLonLat([nearestRep.longitude, nearestRep.latitude]));
        setSelectedSalesRep(nearestRep.userId);
        map
          .getView()
          .setCenter(fromLonLat([nearestRep.longitude, nearestRep.latitude]));
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "End of List",
        text: "No more reps to show",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  }

  function openPreviousRep() {
    if (!selectedSalesRep) return;

    const currentRepIndex = repLatestLocation.findIndex(
      (rep) => rep.userId === selectedSalesRep
    );
    if (currentRepIndex === -1) return;

    const currentRep = repLatestLocation[currentRepIndex];
    const currentLongitude = currentRep.longitude;

    let nearestRep = null;
    let minDistance = Infinity;

    repLatestLocation.forEach((rep) => {
      if (rep.userId !== selectedSalesRep && rep.longitude < currentLongitude) {
        const distance = getDistance(
          [currentRep.longitude, currentRep.latitude],
          [rep.longitude, rep.latitude]
        );
        distanceRef.current = distance;
        if (distance < minDistance) {
          minDistance = distance;
          nearestRep = rep;
        }
      }
    });

    if (nearestRep) {
      const locationHistory = locationHistories.find(
        (loc) => loc.userId === nearestRep.userId
      );
      if (locationHistory) {
        setPosition(fromLonLat([nearestRep.longitude, nearestRep.latitude]));
        setSelectedSalesRep(nearestRep.userId);
        map
          .getView()
          .setCenter(fromLonLat([nearestRep.longitude, nearestRep.latitude]));
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "End of List",
        text: "No more reps to show",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  }

  function makeTransparent(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  function getPolygonArea(latLngs) {
    const numPoints = latLngs.length;

    if (numPoints < 3) {
      return 0; // A polygon with less than 3 vertices has no area
    }

    let area = 0;

    for (let i = 0; i < numPoints; i++) {
      const [xi, yi] = latLngs[i];

      const nextIndex = (i + 1) % numPoints;
      const [xj, yj] = latLngs[nextIndex];

      area += xi * yj - xj * yi;
    }

    area = Math.abs(area) / 2;

    return area;
  }

  useEffect(() => {
    if (!areasEnabled) {
      addedServicableAreas?.map((layer) => {
        layer.getSource().clear();
        map.removeLayer(layer);
      });
      setAddedServicableAreas([]);
      return;
    }

    if (!shouldReRenderServicableAreas.current) {
      shouldReRenderServicableAreas.current = true;
      return;
    }

    if (map && areasEnabled && servicableAreas && servicableAreas.length > 0) {
      if (addedServicableAreas.length > 0) {
        addedServicableAreas?.map((layer) => {
          map.removeLayer(layer);
        });
        setAddedServicableAreas([]);
      }
      const layers = [];
      const sortedServicableAreas = servicableAreas.sort(
        (a, b) => getPolygonArea(b.latlngs) - getPolygonArea(a.latlngs)
      );
      sortedServicableAreas?.map((area, ind) => {
        // Create a polygon feature
        const coordinates = area?.latlngs?.map((latlng) => {
          return fromLonLat([latlng[1], latlng[0]]);
        });
        coordinates.push(coordinates[0]);
        const polygon = new Polygon([[...coordinates]]);
        const polygonFeature = new Feature({
          geometry: polygon,
        });
        let color = area?.label?.color ? area?.label?.color : area.color;
        polygonFeature.set("type", area.type);
        polygonFeature.set("s_id", area.id);
        polygonFeature.set("name", area.name);
        polygonFeature.set("color", color);
        polygonFeature.set("label_id", area?.label?.id);
        polygonFeature.set("label", area?.label?.label);
        polygonFeature.set("createdById", area?.createdById);
        const style = {
          Polygon: [
            new Style({
              stroke: new Stroke({
                color: color,
                width: 1,
              }),
              fill: new Fill({
                color: makeTransparent(color, 0.25),
              }),
            }),
            new Style({
              text: new Text({
                text: area.name ? area.name : "",
                fill: new Fill({
                  color: "#fff",
                }),
                font: "bold 16px Arial",
                offsetY: -10,
              }),
            }),
          ],
        };
        polygonFeature.setStyle(style["Polygon"]);
        // Create a vector source and add the feature to it
        const vectorSource = new VectorSource({
          features: [polygonFeature],
        });

        // Create a vector layer with the source
        const vectorLayer = new VectorLayer({
          source: vectorSource,
          name: "areas",
        });
        layers.push(vectorLayer);
        // Create a tile layer for the base map\
        vectorLayer.setZIndex(1010 + ind);

        map.addLayer(vectorLayer);

        if (
          user.userType === "SalesOrgAdmin" ||
          user.userType === "SuperAdmin"
        ) {
          map.on("click", function (event) {
            event.preventDefault();
            let count = 0;
            map.forEachFeatureAtPixel(event?.pixel, function (feature, layer) {
              if (feature?.getGeometry()?.getType() === "Point") {
                count++;
              } else {
              }
            });
          });
        }
      });

      setAddedServicableAreas(layers);
    }
  }, [servicableAreas, map, areasEnabled]);

  function DisplayMapIconOverlay({ mapIcon }) {
    return (
      <img
        className="w-16 h-16 rounded-full border-2 border-white bg-white"
        src={"data:image/png;base64," + mapIcon?.image}
        alt="mapIcon"
      />
    );
  }

  useEffect(() => {
    if (!map) return;
    if (!popupEnabled) {
      candidateClicked(selectedCandidateLead);
      return;
    }
    const overlayElement = document.createElement("div");
    overlayElement.className = "custom-overlay absolute z-10";
    const root = ReactDOM.createRoot(overlayElement);
    const overlayMapIcon = document.createElement("div");
    const root2 = ReactDOM.createRoot(overlayMapIcon);
    const mapIcon = mapIcons.find(
      (mi) => mi.id === selectedCandidateLead?.mapIconId
    );

    setTimeout(() => {
      overlayMapIcon.addEventListener("click", function (event) {
        event.preventDefault();
        candidateClicked(selectedCandidateLead);
      });
    }, 500);

    if (isMiniCandidateCardVisible) {
      root.render(
        // <Suspense fallback={<div>Loading...</div>}>

        // <MapContext.Provider>

        <MiniCandidateLeadCard
          mapIcons={mapIcons}
          // selectedFiberHouse={selectedFiberHouse}

          selectedCandidateFeature={selectedCandidateFeature}
          selectedCandidateLead={selectedCandidateLead}
          candidates={candidates}
          setCandidates={setCandidates}
          setSelectedCandidateFeature={setSelectedCandidateFeature}
          setSelectedCandidateLead={setSelectedCandidateLead}
          mapObject={map}
          shouldReRenderMap={shouldReRenderMap}
          user={user}
          openPreviousNearestCandidate={openPreviousNearestCandidate}
          openNextNearestCandidate={openNextNearestCandidate}
          isVisible={isMiniLeadCardVisible}
          onClose={() => setIsMiniCandidateCardVisible(false)}
          candidate={selectedCandidateLead}
        />
        // </MapContext.Provider>
        // </Suspense>
      );

      root2.render(<DisplayMapIconOverlay mapIcon={mapIcon} />);
    }

    const overlay = new Overlay({
      element: overlayElement,
      positioning: "bottom-left",
      stopEvent: true,
      offset: [0, 0],
      className: "w-full",
    });

    const imageOverlay = new Overlay({
      element: overlayMapIcon,
      positioning: "center-center",
      stopEvent: true,
      offset: [0, 0],
      className: "z-50",
    });

    map.addOverlay(overlay);
    map.addOverlay(imageOverlay);
    overlay.setPosition(
      selectedCandidateRef.current?.getGeometry()?.getCoordinates()
    );
    imageOverlay.setPosition(
      selectedCandidateRef.current?.getGeometry()?.getCoordinates()
    );

    return () => {
      if (root) {
        root.unmount();
      }
      if (root2) {
        root2.unmount();
      }
      map.removeOverlay(overlay);
      map.removeOverlay(imageOverlay);
    };
  }, [
    isMiniCandidateCardVisible,
    selectedCandidateRef,
    selectedCandidateFeature,
    selectedCandidateLead,
  ]);

  const DisplayRepImageOverlay = ({ repImage }) => {
    return (
      <img
        className="w-16 h-16 rounded-full border-2 border-white bg-white"
        src={repImage}
        alt="repImage"
      />
    );
  };

  // useEffect(() => {
  //   if (!map) return;
  //   if (!popupEnabled) return;

  //   const overlayElement = document.createElement("div");
  //   overlayElement.className = "custom-overlay absolute z-10";
  //   const root = ReactDOM.createRoot(overlayElement);
  //   const overlayMapIcon = document.createElement("div");
  //   const root2 = ReactDOM.createRoot(overlayMapIcon);
  //   const repImage = salesReps.find((rep) => rep.id === selectedSalesRep)?.profileImage?.url;

  //   let salesRep = salesReps.find((rep) => rep.id == selectedSalesRep);
  //   if (selectedSalesRep) {
  //     root.render(
  //       // <Suspense fallback={<div>Loading...</div>}>
  //       <MiniSalesRepCard salesRep={salesRep} openNextRep={openNextRep} openPreviousRep={openPreviousRep} />
  //       // </Suspense>
  //     );

  //     root2.render(
  //       <DisplayRepImageOverlay repImage={repImage} />
  //     );

  //   }

  //   const overlay = new Overlay({
  //     element: overlayElement,
  //     positioning: 'bottom-left',
  //     stopEvent: true,
  //     offset: [0, 0],
  //     className: 'w-full',

  //   });

  //   const imageOverlay = new Overlay({
  //     element: overlayMapIcon,
  //     positioning: 'center-center',
  //     stopEvent: true,
  //     offset: [0, 0],
  //     className: 'z-50'

  //   });

  //   map.addOverlay(overlay);
  //   map.addOverlay(imageOverlay);
  //   overlay.setPosition(position);
  //   imageOverlay.setPosition(position);

  //   return () => {

  //     if (root) {
  //       root.unmount();
  //     }
  //     if (root2) {
  //       root2.unmount();
  //     }
  //     map.removeOverlay(overlay);
  //     map.removeOverlay(imageOverlay);

  //   };

  // }, [selectedSalesRep])


  useEffect(() => {
    if (!map) return;
    if (!popupEnabled && !calendarPopup) {
      setIndex(10);
      return;
    }
    // if(!isMiniLeadCardVisible) return;

    const overlayElement = document.createElement("div");
    overlayElement.className = "custom-overlay absolute z-10";
    const root = ReactDOM.createRoot(overlayElement);
    const overlayMapIcon = document.createElement("div");
    const root2 = ReactDOM.createRoot(overlayMapIcon);
    const mapIcon = mapIcons.find((mi) => mi.id === selectedFiberHouse?.mapIconId);

    setTimeout(() => {
      overlayMapIcon.addEventListener("click", function (event) {
        event.preventDefault()
        console.log("Image Clicked")
        setIndex(10);

      });
    }, 500);


    if (isMiniLeadCardVisible) {

      root.render(
        // <Suspense fallback={<div>Loading...</div>}>
        <MiniLeadCard
          mapIcons={mapIcons}
          salesReps={salesReps}
          isVisible={isMiniLeadCardVisible}
          onClose={() => setIsMiniLeadCardVisible(false)}
          fiberHouse={selectedFiberHouse}
          selectedFiberHouseFeature={selectedFiberHouseFeature}
          fiberHouses={fiberHouses}
          setFiberHouses={setFiberHouses}
          mapObject={map}
          setSelectedFiberHouse={setSelectedFiberHouse}
          shouldReRenderMap={shouldReRenderMap}
          user={user}
          openNextNearestFiberHouse={openNextNearestFiberHouse}
          openPreviousNearestFiberHouse={openPreviousNearestFiberHouse}
        />
        // </Suspense>
      );

      root2.render(
        <DisplayMapIconOverlay mapIcon={mapIcon} />
      );
    }

    // Create OpenLayers overlay
    const overlay = new Overlay({
      element: overlayElement,
      positioning: 'bottom-left',
      stopEvent: true,
      offset: [0, 0],
      className: 'w-full',

    });

    const imageOverlay = new Overlay({
      element: overlayMapIcon,
      positioning: 'center-center',
      stopEvent: true,
      offset: [0, 0],
      className: 'z-50'

    });

    map.addOverlay(overlay);

    map.addOverlay(imageOverlay);
    overlay.setPosition(position);
    imageOverlay.setPosition(position);

    return () => {

      if (root) {
        root.unmount();
      }
      if (root2) {
        root2.unmount();
      }
      map.removeOverlay(overlay);
      map.removeOverlay(imageOverlay);

    };
  }, [isMiniLeadCardVisible, selectedFiberHouse, selectedFiberHouseRef, selectedFiberHouseFeature]);


  useEffect(() => {
    if (!salesMode) {
      setBoltMode(false);
    }
  }, [salesMode])

  function panToFeatureAtTopLeft(feature) {
    // Replace with your OpenLayers map instance

    // set the map rotation to 0
    map.getView().setRotation(0);

    const view = map.getView();
    const geometry = feature.getGeometry();

    if (geometry.getType() === 'Point') {
      // Get the feature's coordinate (in the view's projection)
      const featureCoord = geometry.getCoordinates();

      // Get the current map size (width/height in pixels)
      const mapSize = map.getSize();

      // Get the current view resolution (units per pixel)
      const resolution = view.getResolution();

      // Calculate the new center to position the feature at the top-left

      let offsetX = 90
      let offsetY =  160

      const newCenter = [
        featureCoord[0] + (mapSize[0] / 2 - offsetX) * resolution, // Move feature right
        featureCoord[1] - (mapSize[1] / 2 - offsetY) * resolution  // Move feature down
      ];

      // Pan the map to the new center
      view.setCenter(newCenter);
    }
  }


  useEffect(
    () => {
      if (selectedFiberHouseFeature && popupEnabled) {
        panToFeatureAtTopLeft(selectedFiberHouseFeature)
      }
    }, [selectedFiberHouseFeature]
  )

  if (!map) {
    return null;
  }

  const markCarParkLocation = async () => {
    let coordinates = localStorage.getItem("currentLocation")
      ? JSON.parse(localStorage.getItem("currentLocation"))
      : null;
    let req = {
      salesRepId: user.id,
      Type: "car park",
      coordinate: {
        longitude: toLonLat(coordinates)[0],
        latitude: toLonLat(coordinates)[1],
      },
    };

    let res = await MapIconApi.markCarParkLocation(req);
    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "success",
        text: "Car park location saved successfully.",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: "FAILED to Save Car park location.",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const center = fromLonLat([
        position.coords.longitude,
        position.coords.latitude,
      ]);
      // mapObject.getView().setCenter(center)
      // mapObject.getView().setZoom(18)
      try {
        map.getView().animate({ center: center, zoom: 18, duration: 500 });
      } catch (e) {
        console.error(e);
      }

      const geolocation = new Geolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map?.getView().getProjection(),
      });
      if (currentPositionLayer) {
        map.removeLayer(currentPositionLayer);
        setCurrentPositionLayer(null);
      }
      const positionFeature = new Feature(new Point(center));
      const iconStyle = new Style({
        image: new CircleStyle({
          radius: 12,
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
          fill: new Fill({
            color: "#0038FF",
          }),
        }),
      });
      positionFeature.setStyle(iconStyle);
      const vectorSource = new VectorSource({
        features: [positionFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      vectorLayer.setZIndex(1000);

      map.addLayer(vectorLayer);
      setCurrentPositionLayer(vectorLayer);
      geolocation.on("change:position", async function () {
        let point1 = localStorage.getItem("currentLocation")
          ? JSON.parse(localStorage.getItem("currentLocation"))
          : null;
        const coordinates = geolocation.getPosition();
        let distance = getDistance(toLonLat(point1), toLonLat(coordinates));
        distanceRef.current = distance;
        if (
          point1 == null ||
          getDistance(toLonLat(point1), toLonLat(coordinates)) >= 15
        ) {
          setCurrentLocationCoordinates({
            longitude: toLonLat(coordinates)[0],
            latitude: toLonLat(coordinates)[1],
          });

          localStorage.setItem("currentLocation", JSON.stringify(coordinates));

          // let req = {
          //   repId: user.id,
          //   coordinate: {
          //     longitude: toLonLat(coordinates)[0],
          //     latitude: toLonLat(coordinates)[1]
          //   }
          // }
          // let res = await UsersApi.saveSalesRepLocationHistory(req);
          // if (res.status.success) {
          // } else {
          // }
        }

        positionFeature.setGeometry(
          coordinates ? new Point(coordinates) : null
        );
      });
      geolocation.setTracking(true);
    });
  };

  const expandedButtons = [
    {
      icon: (
        <BiCurrentLocation
          color="#181E4B"
          size={screenWidth > 1000 ? 30 : 20}
        />
      ),
      onClick: setCurrentLocation,
    },
    // {
    //   icon: <BiCar color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />,
    //   onClick: markCarParkLocation,
    // },
    {
      icon: satelliteMode ? (
        <FaMap color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
      ) : (
        <FaSatelliteDish color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
      ),
      onClick: () => setSatelliteMode(!satelliteMode),
    },
    {
      icon: <CiMapPin color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />,
      onClick: () => setIndex(2),
    },
    {
      component: (
        <button
          onClick={() => {
            setAreasEnabled(!areasEnabled);
          }}
          className={`p-2 rounded-full ${areasEnabled ? "bg-[#0038FF]" : "bg-white"
            } `}
        >
          <TbLassoPolygon
            size={screenWidth > 1000 ? 30 : 20}
            color={areasEnabled ? "#fff" : "#181E4B"}
          />
        </button>
      ),
    },
    {
      icon: (
        <MdOutlineAreaChart
          size={screenWidth > 1000 ? 30 : 20}
          color="#181E4B"
        />
      ),
      onClick: () => setIndex(22),
    },

    {
      component: (
        <SelectArea
          active={isDrawingToolActive}
          setActive={setisDrawingToolActive}
          screenWidth={screenWidth}
        />
      ),
    },
    {
      icon: (
        <MdFilterListAlt size={screenWidth > 1000 ? 30 : 20} color="#181E4B" />
      ),
      onClick: () => {
        setTriggerBoltModeSearch(false);
        setBoltMode(false);
        setIndex(7)
      },
    },
    {
      icon: <IoMdSettings size={screenWidth > 1000 ? 30 : 20} color="#181E4B"/>,
      onClick: (e) => setOpenMobileMenu(e.currentTarget),
    }
  ];
  const expandedButtons2 = [
    {
      component: (
        <button
          onClick={() => {
            setBoltMode(!boltMode);
          }}
          className={`p-2 rounded-full ${boltMode ? "bg-[#0038FF]" : "bg-white"
            } ${salesMode === true ? "block" : "hidden"} `}
        >
          <IoFlash
            size={screenWidth > 1000 ? 30 : 20}
            color={boltMode ? "#fff" : "#181E4B"}
          />
        </button>
      ),
    },
    {
      component: (
        <button
          onClick={() => {
            setPopupEnabled(!popupEnabled);
          }}
          className={`p-2 rounded-full ${popupEnabled ? "bg-[#0038FF]" : "bg-white"
            }  `}
        >
          <LuPanelTopOpen
            size={screenWidth > 1000 ? 30 : 20}
            color={popupEnabled ? "#fff" : "#181E4B"}
          />
        </button>
      ),
    },
   
   
  ];


  const handleRecruitModeClick = () => {
    setRecruitMode(!recruitMode);
  };
  const handelFccModeClick = () => {
    // HandelFccModeClick
    if (typeof setFCCMode !== "function") {
      // alert(`setFccMode is not a function! Current value: ${setFCCMode}`);
      return;
    }
    // alert(`Done`);
    if (!fccMode)
    {  setIndex(7);
    }else
     { setIndex(-1);
     }
     setFCCMode(!fccMode);
  };

  const handleSalesModeClick = () => {
    setSalesMode(!salesMode);
  };

  // if (screenWidth <= 1000) {
  //   expandedButtons.push({
  //     icon: <IoMdSettings size={20} color="#181E4B" />,
  //     onClick: (e) => setOpenMobileMenu(e.currentTarget),
  //   });
  //}

  const openNextNearestFiberHouse = async () => {
    if (selectedFiberHouseFeature) {
      let extent = createEmpty();
      const initialBuffer = 100; // Initial buffer distance in meters
      let bufferDistance = initialBuffer;
      let nearestFeature = null;

      while (!nearestFeature) {
        extent = buffer(
          selectedFiberHouseFeature.getGeometry().getExtent(),
          bufferDistance
        );
        const featuresInExtent = cluster.current
          .getSource()
          .getFeaturesInExtent(extent);

        // Filter out the selected feature itself and already visited features
        const otherFeatures = featuresInExtent
          .map((f) => f.get("features") || f)
          .flat()
          .filter((f) => {
            const featureCenter = getCenter(f.getGeometry().getExtent());
            const selectedFeatureCenter = getCenter(
              selectedFiberHouseFeature.getGeometry().getExtent()
            );
            return (
              f !== selectedFiberHouseFeature &&
              featureCenter[0] > selectedFeatureCenter[0]
            );
          });

        if (otherFeatures.length > 0) {
          // Find the nearest feature
          const selectedFeatureCenter = getCenter(
            selectedFiberHouseFeature.getGeometry().getExtent()
          );
          nearestFeature = otherFeatures.reduce((nearest, feature) => {
            const featureCenter = getCenter(feature.getGeometry().getExtent());
            const distance = getDistance(selectedFeatureCenter, featureCenter);
            distanceRef.current = distance;
            if (!nearest || distance < nearest.distance) {
              return { feature, distance };
            }
            return nearest;
          }, null).feature;
        }

        // Increase the buffer distance if no feature is found
        if (bufferDistance < 5000) {
          bufferDistance += initialBuffer;
        } else {
          Swal.fire({
            icon: "info",
            title: "No Leads",
            text: "No more leads within 5 km to the Right.",
          });
          break;
        }
      }

      // Do something with the nearest feature
      if (nearestFeature) {
        visitedFeatures.current.push(selectedFiberHouseFeature);

        setPosition(nearestFeature.getGeometry().getCoordinates());
        setSelectedFiberHouseFeature(nearestFeature);
        setSelectedFiberHouse({
          id: nearestFeature.get("id"),
          mapIconId: nearestFeature.get("mapIconId"),
        });
        // For example, you can select the nearest feature or zoom to it
        map.getView().setCenter(nearestFeature.getGeometry().getCoordinates());
      }
    }
  };

  const openPreviousNearestFiberHouse = async () => {
    if (selectedFiberHouseFeature) {
      let extent = createEmpty();
      const initialBuffer = 100; // Initial buffer distance in meters
      let bufferDistance = initialBuffer;
      let nearestFeature = null;

      while (!nearestFeature) {
        extent = buffer(
          selectedFiberHouseFeature.getGeometry().getExtent(),
          bufferDistance
        );
        const featuresInExtent = cluster.current
          .getSource()
          .getFeaturesInExtent(extent);

        // Filter out the selected feature itself and already visited features
        const otherFeatures = featuresInExtent
          .map((f) => f.get("features") || f)
          .flat()
          .filter((f) => {
            const featureCenter = getCenter(f.getGeometry().getExtent());
            const selectedFeatureCenter = getCenter(
              selectedFiberHouseFeature.getGeometry().getExtent()
            );
            return (
              f !== selectedFiberHouseFeature &&
              featureCenter[0] < selectedFeatureCenter[0]
            );
          });

        if (otherFeatures.length > 0) {
          // Find the nearest feature
          const selectedFeatureCenter = getCenter(
            selectedFiberHouseFeature.getGeometry().getExtent()
          );
          nearestFeature = otherFeatures.reduce((nearest, feature) => {
            const featureCenter = getCenter(feature.getGeometry().getExtent());
            const distance = getDistance(selectedFeatureCenter, featureCenter);
            distanceRef.current = distance;
            if (!nearest || distance < nearest.distance) {
              return { feature, distance };
            }
            return nearest;
          }, null).feature;
        }

        // Increase the buffer distance if no feature is found
        if (bufferDistance < 5000) {
          bufferDistance += initialBuffer;
        } else {
          Swal.fire({
            icon: "info",
            title: "No Leads",
            text: "No more leads within 5 km to the left.",
          });
          break;
        }
      }

      if (nearestFeature) {
        visitedFeatures.current.push(selectedFiberHouseFeature);

        setPosition(nearestFeature.getGeometry().getCoordinates());
        setSelectedFiberHouseFeature(nearestFeature);
        setSelectedFiberHouse({
          id: nearestFeature.get("id"),
          mapIconId: nearestFeature.get("mapIconId"),
        });
        // For example, you can select the nearest feature or zoom to it
        map.getView().setCenter(nearestFeature.getGeometry().getCoordinates());
      }
      // Do something with the nearest feature
    }
  };

  const openNextNearestCandidate = async () => {
    if (selectedCandidateFeature) {
      let extent = createEmpty();
      const initialBuffer = 100; // Initial buffer distance in meters
      let bufferDistance = initialBuffer;
      let nearestFeature = null;

      while (!nearestFeature) {
        extent = buffer(
          selectedCandidateFeature.getGeometry().getExtent(),
          bufferDistance
        );
        const featuresInExtent = candidateLayerRef.current
          .getSource()
          .getFeaturesInExtent(extent);

        // Filter out the selected feature itself and already visited features
        const otherFeatures = featuresInExtent
          .map((f) => f.get("features") || f)
          .flat()
          .filter((f) => {
            const featureCenter = getCenter(f.getGeometry().getExtent());
            const selectedFeatureCenter = getCenter(
              selectedCandidateFeature.getGeometry().getExtent()
            );
            return (
              f !== selectedCandidateFeature &&
              featureCenter[0] > selectedFeatureCenter[0]
            );
          });

        if (otherFeatures.length > 0) {
          // Find the nearest feature
          const selectedFeatureCenter = getCenter(
            selectedCandidateFeature.getGeometry().getExtent()
          );
          nearestFeature = otherFeatures.reduce((nearest, feature) => {
            const featureCenter = getCenter(feature.getGeometry().getExtent());
            const distance = getDistance(selectedFeatureCenter, featureCenter);
            distanceRef.current = distance;
            if (!nearest || distance < nearest.distance) {
              return { feature, distance };
            }
            return nearest;
          }, null).feature;
        }

        // Increase the buffer distance if no feature is found
        bufferDistance += initialBuffer;
      }

      if (nearestFeature) {
        // Do something with the nearest feature
        visitedCandidates.current.push(selectedCandidateFeature);

        setPosition(nearestFeature.getGeometry().getCoordinates());
        setSelectedCandidateFeature(nearestFeature);
        setSelectedCandidateLead({
          id: nearestFeature.get("data").id,
          mapIconId: nearestFeature.get("data").mapIconId,
        });
        // For example, you can select the nearest feature or zoom to it
        map.getView().setCenter(nearestFeature.getGeometry().getCoordinates());
      }
    }
  };

  const openPreviousNearestCandidate = async () => {
    if (selectedCandidateFeature) {
      let extent = createEmpty();
      const initialBuffer = 100; // Initial buffer distance in meters
      let bufferDistance = initialBuffer;
      let nearestFeature = null;

      while (!nearestFeature) {
        extent = buffer(
          selectedCandidateFeature.getGeometry().getExtent(),
          bufferDistance
        );
        const featuresInExtent = candidateLayerRef.current
          .getSource()
          .getFeaturesInExtent(extent);

        // Filter out the selected feature itself and already visited features
        const otherFeatures = featuresInExtent
          .map((f) => f.get("features") || f)
          .flat()
          .filter((f) => {
            const featureCenter = getCenter(f.getGeometry().getExtent());
            const selectedFeatureCenter = getCenter(
              selectedCandidateFeature.getGeometry().getExtent()
            );
            return (
              f !== selectedCandidateFeature &&
              featureCenter[0] > selectedFeatureCenter[0]
            );
          });

        if (otherFeatures.length > 0) {
          // Find the nearest feature
          const selectedFeatureCenter = getCenter(
            selectedCandidateFeature.getGeometry().getExtent()
          );
          nearestFeature = otherFeatures.reduce((nearest, feature) => {
            const featureCenter = getCenter(feature.getGeometry().getExtent());
            const distance = getDistance(selectedFeatureCenter, featureCenter);
            distanceRef.current = distance;
            if (!nearest || distance > nearest.distance) {
              return { feature, distance };
            }
            return nearest;
          }, null).feature;
        }

        // Increase the buffer distance if no feature is found
        bufferDistance += initialBuffer;
      }

      // Do something with the nearest feature
      visitedCandidates.current.push(selectedCandidateFeature);

      setPosition(nearestFeature.getGeometry().getCoordinates());
      setSelectedCandidateFeature(nearestFeature);
      setSelectedCandidateLead({
        id: nearestFeature.get("data").id,
        mapIconId: nearestFeature.get("data").mapIconId,
      });
      // For example, you can select the nearest feature or zoom to it
      map.getView().setCenter(nearestFeature.getGeometry().getCoordinates());
    }
  };

  const rotateMap = () => {
    const rotationStep = Math.PI / 12; // 15 degrees
    const view = map.getView();
    const currentRotation = view.getRotation();
    view.setRotation(currentRotation + rotationStep);
  };

  return (
    <div className="relative h-full">
      <MapContext.Provider value={map}>
        <SatelliteLayer satelliteMode={satelliteMode} map={map} />

        <MapComponent
          map={map}
          // tabIndex={0}
          style={{
            height: screenWidth > 1000 ? "100vh" : "92vh",
            overflow: "auto",
            // borderRadius: screenWidth > 1000 && !mapHelper.isIpad() ? "10px" : "0px",
          }}
        />
        <div id="map-tooltip"></div>
        <div id="popup" class="ol-popup" style={{ display: "none" }}>
          <div id="popup-content"></div>
        </div>

        {screenWidth > 1000 && !mapHelper.isIpad() && (
          <div
            style={{
              borderRadius: "50%",
              padding: "0.5rem",
              backgroundColor: "white",
              position: "absolute",
              bottom: "5%",
              left: "2%",
            }}
            onClick={() => {
              rotateMap();
            }}
            className={"TrackLocationButton cursor-pointer"}
          >
            <FaArrowsRotate size={20} color="#181E4B" />
          </div>
        )}
        <div
          id="image-overlay"
          class="ol-popup"
          style={{ display: "none", position: "absolute" }}
        >
          <div id="image-overlay-content">
            <img id="overlay-image" src="" alt="Rep Profile" />
          </div>
        </div>
        {/* <div className="flex justify-center items-center text">
          <div
            style={{
              top:
                screenWidth > 1000 && !mapHelper.isIpad()
                  ? null
                  : "calc(7% + 65px)",
              bottom: screenWidth > 1000 && !mapHelper.isIpad() ? "5%" : null,
              // maxWidth: "400px",
              flexWrap: "wrap",
              padding:
                screenWidth < 1000 || mapHelper.isIpad() ? "1rem" : "0rem",
              paddingLeft: "2rem",
              justifyContent:
                screenWidth > 1000 && !mapHelper.isIpad()
                  ? "center"
                  : "space-between",
            }}
            className={`absolute ${
              screenWidth > 1000 && !mapHelper.isIpad() ? "bottom-8" : ""
            } flex  md:justify-center justify-between w-fit gap-4 items-center`}
          >
            <div className="flex gap-2 ">
              {['SM', 'RM','LT', 'FCC'].map((mode) => {
                const isActive =
                  (mode === 'SM' && salesMode) ||
                  (mode === 'RM' && recruitMode) ||
                  (mode === 'LT' && liveTrackingMode) ||
                  (mode === 'FCC' && fccMode);
                const onClick =
                  mode === "SM"
                    ? handleSalesModeClick
                    : mode === "FCC"
                    ? handelFccModeClick
                    : mode === "RM"
                    ? handleRecruitModeClick
                    : () => setLiveTrackingMode(!liveTrackingMode);

                // if (mode === 'LT' && !liveTrackingUserIds.includes(user.id)) {
                //   return null;
                // }
                // if (!liveTrackingUserIds.includes(user.id)) {
                // if (!liveTrackingUserIds.includes(user.id)) {
                //   return null;
                // }

                if (mode === "RM" && user.userType !== "SuperAdmin") {
                  return null;
                }

                if (mode === "SM" && user.userType !== "SuperAdmin") {
                  return null;
                }

                return (
                  <button
                    key={mode}
                    style={{
                      borderRadius: "50%",
                      width:
                        screenWidth > 1000 && !mapHelper.isIpad()
                          ? "40px"
                          : "32px",
                      height:
                        screenWidth > 1000 && !mapHelper.isIpad()
                          ? "40px"
                          : "32px",
                      padding: "0.5rem",
                      fontSize: "12px",
                      color: isActive ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      backgroundColor: isActive ? "#0038FF" : "white",
                    }}
                    onClick={onClick}
                    className={`${isActive ? "bg-blue-500" : ""}`}
                  >
                    <p
                      style={{
                        alignItems: "center",
                        margin: 0,
                        fontWeight: 900,
                      }}
                    >
                      {mode}
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>*/}
        <div
          className={`absolute ${ "top-[calc(2%+65px)]"}  right-1 transform rounded-lg  overflow-hidden transition-all duration-300 ease-in-out`}
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "0.3rem",
            padding: "0.5rem",
          }}
        >
          {expandedButtons.map((button, index) =>
            button.component ? (
              <div key={index}>{button.component}</div>
            ) : (
              <button
                key={index}
                onClick={button.onClick}
                className="TrackLocationButton"
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                  backgroundColor: "white",
                }}
              >
                {button.icon}
              </button>
            )
          )}
        </div>
        <div
          className={`absolute ${"top-[calc(2%+65px)]"}  left-1 transform rounded-lg  overflow-hidden transition-all duration-300 ease-in-out`}
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "0.3rem",
            padding: "0.5rem",
            zIndex: "2",
          }}
        >
          {liveTrackingUserIds.includes(user.id) && (
            <button
              onClick={() => setLiveTrackingMode(!liveTrackingMode)}
              className="TrackLocationButton"
              style={{
                borderRadius: "50%",
                padding: "0.2rem",
                backgroundColor: "white",
                width: screenWidth < 1000 ? "35px" : "46px", // Smaller button on mobile
                height: screenWidth < 1000 ? "35px" : "46px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                src={LiveTracking}
                alt="Live Tracking"
                style={{
                  width: screenWidth < 1000 ? "35px" : "46px",
                  height: screenWidth < 1000 ? "35px" : "46px",
                  maxWidth: "none",
                  zIndex: "3",
                  position: "relative",
                }}
              />

              {liveTrackingMode && (
                <span
                  style={{
                    fontSize: screenWidth < 1000 ? "8px" : "11px", // Adjust font size for mobile
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    padding:
                      screenWidth < 1000 ? "0.03rem 0.15rem" : "0.5rem 0.3rem",
                    borderRadius: "12px",
                    height: screenWidth < 1000 ? "13px" : "15px", // Adjust pill height
                    lineHeight: screenWidth < 1000 ? "13px" : "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    left: "75%",
                    height: "30px",
                    width: "7rem",
                    backgroundColor: "white",
                    zIndex: "2",
                  }}
                >
                  TRACKING ON
                </span>
              )}
            </button>
          )}
          {expandedButtons2.map((button, index) =>
            button.component ? (
              <div key={index}>{button.component}</div>
            ) : (
              <button
                key={index}
                onClick={button.onClick}
                className="TrackLocationButton"
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                  backgroundColor: "white",
                }}
              >
                {button.icon}
              </button>
            )
          )}

          {fccUserIds.includes(user.id) && (
            <div>
              <button
                onClick={() => {
                  handelFccModeClick();
                }}
                className={`p-2 rounded-full ${fccMode ? "bg-[#0038FF]" : "bg-white"
                  }  `}
              >
                <IoLayersOutline
                  size={screenWidth > 1000 ? 30 : 20}
                  color={fccMode ? "#fff" : "#181E4B"}
                />
              </button>
            </div>
          )}
        </div>
        {/* {
          isMiniLeadCardVisible &&
          <MiniLeadCard isVisible={isMiniLeadCardVisible} onClose={()=>{
            setIsMiniLeadCardVisible(false)
          }} position={position}  />
        } */}
        <RenderCoverageArea />

        {console.log("Player activeGroupIndex.current: ", activeGroupIndex.current)}

        {toggleState.path && showTrackingTab &&(
          <Player
          activeGroupIndex={activeGroupIndex}
          speedsByGroup={newSpeedsByGroup}
          pathGroups={pathGrouping}
          onPlayPause={handlePlayPause}
          onSpeedChange={handleSpeedChange}
          onPanToggle={handlePanToggle}
          playTimeMultiplier={playTimeMultiplier}
          isPanning={isPanning}
          currentGroupSpeeds={currentGroupSpeeds}
          animationState={animationState}
          onStateChange={setAnimationState}
          onGroupChange={handleGroupChange}
          index={index}
          setIndex={setIndex}
          />
        )}

        {/* {
          isMiniLeadCardVisible && popupEnabled && 
          <div className={`absolute ${screenWidth>1000?mapHelper.isIpad()?"top-36":"top-16":"bottom-4"}  ${screenWidth>1000?"left-20":"left-2"}  z-10`}>
            <MiniLeadCard
              mapIcons={mapIcons}
              salesReps={salesReps}
              isVisible={isMiniLeadCardVisible}
              onClose={() => setIsMiniLeadCardVisible(false)}
              fiberHouse={selectedFiberHouse}
              selectedFiberHouseFeature={selectedFiberHouseFeature}
              fiberHouses={fiberHouses}
              setFiberHouses={setFiberHouses}
              mapObject={map}
              setSelectedFiberHouse={setSelectedFiberHouse}
              shouldReRenderMap={shouldReRenderMap}
              user={user}
              openNextNearestFiberHouse={openNextNearestFiberHouse}
              openPreviousNearestFiberHouse={openPreviousNearestFiberHouse}
              boltMode={boltMode}
            />
          </div>
        } */}

        {selectedSalesRep && !showTrackingTab && (
          <div
            className={`absolute ${
              screenWidth > 1000 ? "top-16 left-20" : "bottom-4 left-2"
            } z-10`}
          >
            <MiniSalesRepCard
              onTrack={(rep) => {
                setSelectedSalesRep(rep);
                setShowTrackingTab(true);
              }}
              salesRep=
              {
                typeof selectedSalesRep === 'object'
                          ? salesReps.find((rep) => rep.id === selectedSalesRep.id)
                          : salesReps.find((rep) => rep.id === selectedSalesRep)
              }
              openNextRep={openNextRep}
              openPreviousRep={openPreviousRep}
            />
          </div>
        )}

        {showTrackingTab && (
          <div className="fixed right-0 top-0 bottom-0 z-50 shadow-xl">
            <MiniLiveTrackingTab
              onClose={() => setShowTrackingTab(false)}
              selectedRep={selectedSalesRep}
              setCurrentSalesRepDate={setCurrentSalesRepDate}
              currentSalesRepDate={currentSalesRepDate}
            />
          </div>
        )}


        {
          calendarPopup && (
            <div className={`absolute ${screenWidth > 1000 ? "top-16" : "bottom-4"
              }  ${screenWidth > 1000 ? "left-20" : "left-2"}  `} >
              <AppointmentPopup
                open={calendarPopup}
                setOpen={setCalendarPopup}
                fiberHouse={selectedFiberHouse}
                mapIcons={mapIcons}
              />
            </div>
          )
        }

      </MapContext.Provider>
      {/* FCC Legend Overlay */}
      <div>{fccMode && <FccLegend />}</div>
      <div
        id="popup"
        ref={popupRef}
        className="ol-popup"
        style={{ display: showPopup ? "block" : "none" }}
      >
        {popupData && showPopup ? (
          <FccMapPopUpDetails
          fccData={[popupData]}
          allData={fccData}
          onClose={() => setShowPopup(false)}
        />
        ) : (
          <div className="popup-placeholder"></div>
        )}
      </div>
    </div>
  );
};

// fiberHouses  | dispalyed on the map as features

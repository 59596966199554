import React, { useState, useContext } from "react";
import QuickDate from "../../../components/QuickDate";
import { UserContext } from "../../../App";
import "./index.scss";
import Layout from "../../../Layouts/Layout";
import OrganizationSelector from "../../../components/OrganizationSelector";
import UserSelector from "../../../components/UserSelector";
import FiberCompanySelector from "../../../components/FiberCompanySelector";
import ReportReq from "../../../Requests/Users/SalesReportReq";
import Swal from "sweetalert2";
import CustomerApi from "../../../API/CustomerApi";

const formatDateToISOString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  // Concatenate without timezone offset
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const EmailReport = () => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    formatDateToISOString(new Date(new Date().setHours(0, 0, 0, 0)))
  );
  const [toDate, setToDate] = useState(
    formatDateToISOString(new Date(new Date().setHours(23, 59, 59, 999)))
  );
  const [salesReps, setSalesReps] = useState([]);
  const [orgWithName, setOrgWithName] = useState(null);
  const [quickDateValue, setQuickDateValue] = useState("Today");
  const [orgIds, setOrgIds] = useState([]);
  const [fiberCompanies, setFiberCompanies] = useState([]);
  const [fiberCompanyWithName, setFiberCompanyWithName] = useState(null);
  const [userWithName, setUserWithName] = useState(null);
  const [resetKey, setResetKey] = useState(0);

  const resetForm = () => {
    setFromDate(
      formatDateToISOString(new Date(new Date().setHours(0, 0, 0, 0)))
    );
    setToDate(
      formatDateToISOString(new Date(new Date().setHours(23, 59, 59, 999)))
    );
    setSalesReps([]);
    setOrgIds([]);
    setFiberCompanies([]);
    setUserWithName(null);
    setOrgWithName(null);
    setFiberCompanyWithName(null);
    setQuickDateValue("Today");
    setResetKey(prev => prev + 1); // Increment reset key

  };

  const sendEmail = async () => {
    if (!toDate || !fromDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a date range",
      });
      return;
    }

    if (salesReps.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select a Sales Rep",
      });
      return;
    }
    setLoading(true);

    Swal.fire({
      // title: "Sending Email...",
      icon: "info",
      title: "Processing Your Request",
      text: "Please wait while we sending email",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let req = new ReportReq();

    if (fromDate && toDate) {
      req.fromDate = fromDate;
      req.toDate = toDate;
    }

    req.salesRepIds = salesReps;

    try {
      await CustomerApi.SendEmailReport(req);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Email Sent Successfully",
      });
      resetForm(); // Reset only on success
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to send email",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div style={{width:"80%"}} className="sales-report-container ">
        <div className="filter-container">
          <h1> Email Report</h1>
          <QuickDate
            selectQuickDate="Today"
            setFrom={setFromDate}
            setTo={setToDate}
            setQuickDateValue={setQuickDateValue}
            key={`quickdate-${resetKey}`}  // Add key here

          />

          {user.userType === "SuperAdmin" && (
            <div className="selector">
              <div className="single">
                <FiberCompanySelector
                  setCompanyObject={setFiberCompanyWithName}
                  key={`fiber-${resetKey}`}  // Add key prop
                  companiesWithOfferTemplateOnly={true}
                  placeholder="Select Fiber Company"
                  setUser={setFiberCompanies}
                  multiple={true}
                />
              </div>
              <div className="single">
                <OrganizationSelector
                  multiple={true}
                  key={`org-${resetKey}`}  // Changed to org prefix
                  setOrgWithName={setOrgWithName}
                  setUser={setOrgIds}
                  placeholder="Select Organizations"
                />
              </div>
              <div className="single">
                <UserSelector
                  setUserWithName={setUserWithName}
                  key={`user-${resetKey}`}  // Changed to user prefix
                  salesOrgIds={orgIds}
                  SalesOrgId={
                    user.userType === "SalesOrgAdmin" ? user.salesOrgId : null
                  }
                  setUser={setSalesReps}
                  conversationType={"Group"}
                  placeholder="Select Sales Reps"
                  userTypes={["SalesRep", "SalesOrgAdmin"]}
                />
              </div>
            </div>
          )}

          <button onClick={() => sendEmail()}>Send Email</button>
        </div>
      </div>
    </Layout>
  );
};

export default EmailReport;

import React from 'react';

const UploadStep = ({ file, error, handleFileChange }) => {
  return (
    <div className="upload-step">
      <h2>Upload your file</h2>
      <p>Before you upload your file, ensure it’s ready for import.</p>
      <div className="upload-box">
        <input
          type="file"
          id="file-upload"
          accept=".csv"
          onChange={handleFileChange}
          hidden
        />
        <label htmlFor="file-upload">
          <span>Upload a file or drag and drop</span>
          <p>All .csv file types (up to 50MB in size) are supported.</p>
        </label>
        {file && <p className="file-info">Selected File: {file.name}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default UploadStep;

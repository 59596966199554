import React, { useContext, useEffect } from 'react'
import FileUploadWizard from '../../../SuperAdmin/MappingFilter/FileUploadWizard'
import MapHelper from '../MapHelper'
import { MapContext } from '..'
import Swal from 'sweetalert2'
import FiberHouseApi from '../../../../API/FiberHouseApi'
import FiberCompanyApi from '../../../../API/FiberCompanyApi'
import FiberCompanySelector from './../../../../components/FiberCompanySelector'
const UploadCoverageArea = () => {


    const map = useContext(MapContext)
    // const [data,setData] = React.useState([])
    const helper = new MapHelper(map.mapObject)
    const [fiberCompany, setFiberCompany] = React.useState(null)
    const mapping = [
        { header: 'Address', Datatype: 'string', Required: true },
        { header: 'Availability', Datatype: 'string', Required: true },
        { header: "Average Best Speed", Datatype: 'string', Required: true },
    ]


    const uploadData = async (data) => {


        Swal.fire({
            icon: 'info',
            title: 'Fetching Coordinates',
            text: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        let coordinates = []
        let availabilities = []
        let speeds = []
        let addresses = []

        for (let i = 0; i < data.length; i++) {
            var location = data[i]
            try{
                var details = await helper.searchLocationDetails(location.Address);
                Swal.update({
                    title: 'Fetching Coordinates',
                    html: `Please wait...<br>Progress: ${i + 1}/${data.length}`,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
    
                if (details) {
                    coordinates.push({
                        latitude: details.coordinates[1],
                        longitude: details.coordinates[0]
                    })
                    addresses.push(location.Address)
                    availabilities.push(parseFloat(location?.Availability?.replace("%","")))
                    speeds.push(parseFloat(location["Average Best Speed"].split(" ")[0].replace(",","")))
                }

            }
            catch(e){
                console.log(e)
            }
        }

        const req = {
            coordinates: coordinates,
            addresses: addresses,
            availabilities: availabilities,
            averageBestSpeeds: speeds,
            companyId : fiberCompany
        }

        Swal.update({
            title: 'Uploading Data',
            html: 'Please wait...',
            icon:"info",
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const res = await FiberCompanyApi.BulkCreateCoverageArea(req)

        if(res.status.success){
            Swal.update({
                icon: 'success',
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showConfirmButton: true,
                didOpen: () => {
                    
                    Swal.hideLoading();
                }
            });
        }
        else{
            Swal.update({
                icon: 'error',
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                showConfirmButton: true,
                didOpen: () => {
                    
                    Swal.hideLoading();
                }
            });
        }


    }

    return (
        <div>
            <FiberCompanySelector setUser={setFiberCompany} />
           <FileUploadWizard showLayout={false} mapping={mapping} onFinish={uploadData} />
        </div>
    )
}

export default UploadCoverageArea
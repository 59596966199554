import { Modal } from '@mui/material'
import React, { useContext, useState } from 'react'
import OrganizationSelector from '../../../components/OrganizationSelector'
import FiberCompanySelector from '../../../components/FiberCompanySelector'
import UserSelector from '../../../components/UserSelector'
import Swal from 'sweetalert2'
import FiberHouseApi from '../../../API/FiberHouseApi'
import MasterSheetApi from '../../../API/MasterSheetApi'
import { UserContext } from '../../../App'
import { useTabs } from './TabContext'
const CreateSheetModal = ({ open, setOpen }) => {

    const [sheetName, setSheetName] = useState(null)
    const [sheetDescription, setSheetDescription] = useState(null)
    const [leadType, setLeadType] = useState(null)
    const [accountStatus, setAccountStatus] = useState(null)
    const [salesOrgIds, setSalesOrgIds] = useState([])
    const [salesRepIds, setSalesRepIds] = useState([])
    const [fiberCompanyIds, setFiberCompanyIds] = useState([])
    const {getAllDynamicLists} = useTabs()
    const user = useContext(UserContext)

    const createSheet = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are creating the sheet",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        if (sheetName === null || sheetName.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a sheet name"
            })
            return
        }

        if (sheetDescription === null || sheetDescription.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a sheet description"
            })
            return
        }

        if (leadType === null || leadType.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a lead type"
            })
            return
        }

        const req = {
            listName: sheetName,
            listDescription: sheetDescription,
            customerType: leadType,
            customerStatus: accountStatus,
            salesOrgIds: user.userType === "SalesOrgAdmin"?[user?.salesOrgId] :salesOrgIds,
            userIds:  [...salesRepIds, user?.id],
            salesRepIds: user.userType==="SalesRep" ?[user?.id]:null,
            fiberCompanyIds: fiberCompanyIds,
            listType: "Dynamic",
            pagingParams: {}
        }

        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1000000;

        const res = await MasterSheetApi.ExportLeadsToDynamicList(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Sheet created successfully"
            })
            setOpen(false)
            getAllDynamicLists()
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occured while creating the sheet"
            })
        }

    }

    return (
        <Modal open={open} onClose={() => {
            setOpen(false)
        }}>
            <div style={{
                width: "min(500px,80vw)"
            }} className="absolute bg-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border-t border-gray-200 p-8 rounded-lg">
                <h1 className="text-2xl text-center font-semibold">Create New Sheet</h1>
                <div className="mt-4">
                    <label htmlFor="sheetName" className="block">Sheet Name</label>
                    <input type="text" id="sheetName" className="w-full border rounded-md p-2" value={sheetName} onChange={(e) => setSheetName(e.target.value)} />
                </div>
                <div className="mt-2">
                    <label htmlFor="sheetDescription" className="block">Sheet Description</label>
                    <textarea id="sheetDescription" className="w-full border rounded-md p-2" value={sheetDescription} onChange={(e) => setSheetDescription(e.target.value)} />
                </div>
                <div className='mt-2'>
                    <label className="block">Lead Type</label>
                    <select className="w-full border rounded-md p-2" value={leadType} onChange={(e) => setLeadType(e.target.value)}>
                        <option value="Prospect">Hot Lead</option>
                        <option value="Customer">Customer</option>
                    </select>
                </div>
                {
                    leadType === 'Customer' &&
                    <div className='mt-2'>
                        <label className="block">Account Status</label>
                        <select className="w-full border rounded-md p-2" value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                            <option value="Sold">Sold</option>
                            <option value="Canceled">Canceled</option>
                            <option value="Completed">Completed</option>
                            <option value="Missing">Missing</option>
                        </select>
                    </div>
                }
                {
                    user?.userType === 'SuperAdmin' &&
                    <div className='mt-2'>
                        <label className="block">Organziations</label>
                        <OrganizationSelector placeholder='Select Organziations' setUser={setSalesOrgIds} multiple={true} />
                    </div>
                }
                {
                    user?.userType === 'SuperAdmin' &&
                    <div className='mt-2'>
                        <label className="block">Companies</label>
                        <FiberCompanySelector placeholder='Select Companies' setUser={setFiberCompanyIds} multiple={true} />
                    </div>
                }
                {
                    (user?.userType === 'SuperAdmin' || user?.userType === 'SalesOrgAdmin') &&
                    <div className='mt-2'>
                        <label className="block">Sales Reps</label>
                        <UserSelector placeholder='Select Sales Reps' conversationType={"Group"} setUser={setSalesRepIds} />
                    </div>
                }
                <div className="mt-2">
                    <button className="bg-blue-600 text-white p-2 rounded-lg w-full" onClick={() => {
                        createSheet()
                    }}>Create</button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateSheetModal
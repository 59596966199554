import React, { useContext, useEffect, useState } from 'react'
import { MdCancel, MdCelebration, MdOutlineLocationSearching, MdSchedule } from "react-icons/md";
import { fromLonLat } from 'ol/proj';
import MapIconApi from '../../../../API/MapIconApi';
import SearchLeadsForContactCenterReq from '../../../../Requests/Customer/SearchLeadsForContactCenterReq';
import BulkMarkNotificationsAsSeenReq from '../../../../Requests/Notifications/BulkMarkNotificationsAsSeenReq';
import CustomerApi from '../../../../API/CustomerApi';
import ManyToManyReq from '../../../../Requests/ManyToManyReq';
import Swal from 'sweetalert2';
import { NotificationsContext, UserContext } from '../../../../App';
import UsersApi from '../../../../API/UsersApi';
import StringIntReq from '../../../../Requests/StringIntReq';
import { IoIosNotifications, IoIosWarning } from 'react-icons/io';
import { ClickAwayListener } from '@mui/material';
import { FcInfo } from 'react-icons/fc';
import { BiHide } from 'react-icons/bi';
import { Polygon } from 'ol/geom';
import { Search } from 'lucide-react';


export const getSuggestionDetails = async (id) => {
    const detailsPlaceUrl = await MapIconApi.GetMapIdDetails(id);
    console.log(detailsPlaceUrl)
    return detailsPlaceUrl;
}
export const searchLocation = async (address, houses, areas) => {

    try {
        const req = new SearchLeadsForContactCenterReq();
        req.SearchString = address;
        req.PagingParams.PageNumber = 1;
        req.PagingParams.PageSize = 10;
        const locationSuggestions = await MapIconApi.SearchMapPlacesUrl(address);

        let formattedLocationSuggestions = [];
        if (locationSuggestions && locationSuggestions.status === "OK") {
            formattedLocationSuggestions = locationSuggestions.predictions.map((suggestion) => ({
                formatted_address: suggestion.description,
                place_id: suggestion.place_id,
                types: suggestion.types,
                isCustomer: false, // Mark this as a location result
            }));
        }


        const formattedCustomerSuggestions = houses
            .filter((house) =>
                (house.name && house.name.toLowerCase().includes(address.toLowerCase())) ||
                (house.email && house.email.toLowerCase().includes(address.toLowerCase())) ||
                (house.phoneNumber && house.phoneNumber.includes(address))
            )
            .slice(0, 10)
            .map((customer) => ({
                name: customer.name || 'No Name',
                email: customer.email || 'No Email',
                phoneNumber: customer.phoneNumber || 'No Phone',
                longitude: customer.coordinates.longitude,
                latitude: customer.coordinates.latitude,
                isCustomer: true,
                fiberHouse: customer,
            }));

        const formattedAreaSuggestions = areas
            .filter(area => area.name.toLowerCase().includes(address.toLowerCase()))
            .slice(0, 10)
            .map((area) => {
                const coordinates = area?.latlngs?.map((latlng) => {
                    return fromLonLat([latlng[1], latlng[0]]);
                });

                const polygon = new Polygon([coordinates])
                const interiorPoint = polygon.getInteriorPoint();
                const centerCoordinates = interiorPoint.getCoordinates();
                return {
                    name: area.name || 'No Name',
                    longitude: centerCoordinates[0],
                    latitude: centerCoordinates[1],
                    isArea: true,
                }
            }
            );


        const combinedSuggestions = [...formattedCustomerSuggestions, ...formattedAreaSuggestions, ...formattedLocationSuggestions];
        return { results: combinedSuggestions, status: "OK" };

    } catch (error) {
        console.error(error);
        return null;
    }
};



const SearchLocation = ({ setIndex, setSelectedFiberHouse, fiberHouses, servicableAreas, mapObject }) => {

    const [search, setSearch] = useState("")
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false)
    const [notificationPanel, setNotificationPanel] = useState(false);
    const { notifications, setNotifications } = useContext(NotificationsContext)
    const [showSuggestions, setShowSuggestions] = useState(false);
    const user = useContext(UserContext)
    const locationTypeZoomLevels = {
        "continent": 3,
        "country": 6,
        "administrative_area_level_1": 8,
        "administrative_area_level_2": 9,
        "locality": 10,
        "sublocality": 14,
        "neighborhood": 15,
        "route": 16,
        "street_address": 17,
        "premise": 20,
        "establishment": 18,
        "postal_code": 12,
        "natural_feature": 12,
        "point_of_interest": 16
    };

    // Define the priority order of location types
    const priorityOrder = [
        "premise",
        "establishment",
        "street_address",
        "route",
        "neighborhood",
        "sublocality",
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
        "postal_code",
        "natural_feature",
        "point_of_interest",
        "country",
        "continent"
    ];

    let selectedZoomLevel = null;
    let selectedType = null;

    const handleSelect = async (result) => {
        setLocations(null);
        setShowSuggestions(false);

        if (result.isCustomer) {
            // Handle the customer selection
            mapObject.getView().setCenter(fromLonLat([result.longitude, result.latitude]));
            mapObject.getView().setZoom(18);
            setSelectedFiberHouse(result.fiberHouse);
            if (setIndex)
                setIndex(10);
            console.log('Customer selected:', result);
            // You can navigate to their details or move the map to their location if needed
        }
        else if (result.isArea) {
            mapObject.getView().setCenter([result.longitude, result.latitude]);
            mapObject.getView().setZoom(18);
        }
        else {
            // Handle the location suggestion
            const res = await getSuggestionDetails(result.place_id);
            mapObject.getView().setCenter(fromLonLat([res.result.geometry.location.lng, res.result.geometry.location.lat]));

            // Adjust the zoom level based on the result type
            for (const type of priorityOrder) {
                if (result.types.includes(type)) {
                    selectedZoomLevel = locationTypeZoomLevels[type];
                    selectedType = type;
                    break;
                }
            }

            if (selectedZoomLevel !== null) {
                mapObject.getView().setZoom(selectedZoomLevel);
            }
        }

    }

    useEffect(
        () => {
            async function getLocation() {
                if (search === "") {
                    return
                }
                setLoading(true)
                const data = await searchLocation(search, fiberHouses, servicableAreas)
                setLocations(data)
                setShowSuggestions(true);
                setLoading(false)
            }

            const timer = setTimeout(
                () => {
                    getLocation()
                }, 500
            )

            return () => {
                clearTimeout(timer)
            }

        }, [search]
    )

    const handleBlur = () => {
        console.log("Blurred")
        setShowSuggestions(false); // Delay to allow click event to register before hiding suggestions
    }

    const handleFocus = () => {
        if (search !== "") {
            setShowSuggestions(true); // Show suggestions again if input is focused and has value
        }
    }

    const openMap = async (notification) => {
        if (notification.fiberHouse) {
            // navigate('/map', {
            //   state: { fiberHouse: notification.fiberHouse }
            // })
        }
        else {
            console.log("No FiberHouse")
        }

    }

    const deleteNotifications = async () => {
        const req = new BulkMarkNotificationsAsSeenReq();
        req.notificationIds = notifications.map(notification => notification.id);
        setNotifications([]);
        const res = await UsersApi.DeleteNotifications(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("All notifications deleted");
        }
    }

    const generateICalEvent = (appointmentDateTime) => {
        const start = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d+/g, '');
        const end = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d+/g, ''); // Adding 1 hour to end time
        const icsContent = `
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:Consultation
        DTSTART:${start}
        DTEND:${end}
        DESCRIPTION:Appointment Details
        LOCATION:Appointment Location
        END:VEVENT
        END:VCALENDAR
    `;

        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'appointment.ics';
        link.click();

        Swal.fire('iCalendar Event Generated!');
    };

    const addGoogleCalendarEvent = (appointmentDateTime) => {
        const base = 'https://calendar.google.com/calendar/r/eventedit?';

        const startTime = new Date(appointmentDateTime).toISOString().replace(/-|:|\.\d\d\d/g, '');
        const endTime = new Date(new Date(appointmentDateTime).getTime() + 60 * 60 * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');

        // Creating URL parameters
        const params = new URLSearchParams({
            text: `Consultation`,
            dates: `${startTime}/${endTime}`,
        });


        return `${base}${params.toString()}`;
    };


    const handleAddToCalendar = (appointmentDateTime) => {
        const googleCalendarUrl = addGoogleCalendarEvent(appointmentDateTime);
        window.open(googleCalendarUrl, '_blank', 'noopener,noreferrer');
    };


    const promptAddToCalendar = async (appointmentDateTime) => {
        const { value } = await Swal.fire({
            title: 'Add Appointment to Calendar',
            text: 'Would you like to add this appointment to Google Calendar or iCalendar?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Google Calendar',
            denyButtonText: 'iCalendar',
            cancelButtonText: 'No, thanks',
        });

        if (value === true) {
            // User chose Google Calendar
            handleAddToCalendar(appointmentDateTime);
        } else if (value === false) {
            // User chose iCalendar
            generateICalEvent(appointmentDateTime);
        }
    };

    const changeOwnerShipOfTheConsultation = async (notification) => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Processing your request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })

        const req = new ManyToManyReq();
        req.id1 = notification.consultation.id;
        req.id2 = user.id;
        const res = await CustomerApi.ChangeOwnershipOfConsultation(req);
        if (res?.status?.success) {
            console.log("Ownership Changed Successfully")
            setNotifications(
                (prev) => prev.map((not) => {
                    if (not.id === notification.id) {
                        return { ...not, consultation: { ...res.data } }
                    }
                    return notification;
                })
            )

            promptAddToCalendar(res?.data?.consultationTime)

        }
    }

    const bulkMarkNotificationsAsSeen = async () => {
        const req = new BulkMarkNotificationsAsSeenReq();
        req.notificationIds = notifications.filter(notification => !notification.seen).map(notification => notification.id);
        setNotifications(
            (prev) => prev.map((notification) => {
                if (req.notificationIds.includes(notification.id)) {
                    return { ...notification, seen: true }
                }
                return notification;
            })
        )
        const res = await UsersApi.BulkMarkNotificationsAsSeen(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("All notifications marked as seen");

        }
    }

    const markNotificationAsSeen = async (notificationId) => {
        const req = new StringIntReq();
        setNotifications(
            (prev) => prev.filter((notification) => notification.id !== notificationId)
        )
        req.int = notificationId;
        const res = await UsersApi.MarkNotificationAsSeen(req);
        if (res?.status?.success) {
            // searchNotifications();
            console.log("Notification marked as seen");
        }
    }

    function isGalaxyTablet() {
        const ua = navigator.userAgent.toLowerCase();
      
        // Check for Galaxy Tablet identifiers in User-Agent
        const isAndroid = /android/.test(ua);
        const isSamsung = /samsung/.test(ua);
        const isGalaxyModel = /sm-t[0-9]+|sm-p[0-9]+/i.test(ua); // Common Galaxy Tab model prefixes
      
        return isAndroid && (isSamsung || isGalaxyModel);
      }

    function isIpad() {
        const ua = navigator.userAgent.toLowerCase();
        const platform = navigator.platform.toLowerCase();

        // Check for "iPad" in User-Agent or platform
        const hasIpadKeyword = /ipad/.test(ua) || /ipad/.test(platform);

        // Check for Macintosh User-Agent with touch support (iPadOS 13+)
        const isMacDesktop = /macintosh|macintel/.test(ua);
        const hasTouch = navigator.maxTouchPoints > 0;

        return hasIpadKeyword || (isMacDesktop && hasTouch) || isGalaxyTablet();
    }

    return (
        <>
            <div style={{
                width: isIpad()?"234.082px":"",
                top: "calc(2% + 65px)",
                border: '1px solid #ccc', 
                borderRadius: '8px', 
            
            }} className='SearchLocation'>
                <input value={search} onChange={(e) => { setSearch(e.target.value) }} onFocus={handleFocus} onBlur={handleBlur} type="text" placeholder="Search Location"  style={{ border: 'none', outline: 'none', flex: 1 }} />
                <Search className='w-6 h-12' />
            </div>
            {showSuggestions && (

                <div className='options'>
                    {
                        locations?.results?.map(
                            (result, index) => {
                                return (
                                    <div onMouseDown={() => { handleSelect(result) }} key={index}>
                                        <p>
                                            {!result.isCustomer && result.formatted_address}
                                            {result.isCustomer && <span style={{ color: "blue" }}>{result.name}</span>}
                                            {result.isArea && <span style={{ color: "green" }}>{result.name}</span>}
                                        </p>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            )}



        </>
    )
}

export default SearchLocation
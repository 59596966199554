import React from "react";
import Layout from "../../../Layouts/Layout";
import AdminDashboard from "./pages/AdminDashboard";

function BlogsPage() {
  return (
    <Layout>
      <AdminDashboard /> 
    </Layout>
  );
}

export default BlogsPage;

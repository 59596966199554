import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserContext } from "../../../../App";
import CreateLightCustomerReq from "../../../../Requests/Customer/CreateLightCustomerReq";
import CustomerApi from "../../../../API/CustomerApi";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import PhoneInput from "react-phone-input-2";
import FiberPlanSelector from "../../../../components/FiberPlanSelector";
import CreateOrEditOfferReq from "../../../../Requests/Offer/CreateOrEditOfferReq";
import OfferApi from "../../../../API/OfferApi";
import ContactButtons from "../../../../Services/ContactButtons";
import EditFiberHouseReq from "../../../../Requests/FiberHouse/EditFiberHouseReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";

const SendOffer = ({ lead }) => {
  const user = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);
  const [company, setCompany] = useState(null);
  const [free, setFree] = useState("");
  const contactButtons = new ContactButtons();
  const [radioFree, setRadioFree] = useState(null)

  const [companyId, setCompanyId] = useState("");

  useEffect(() => {
    setFirstName(lead?.name?.split(" ")[0]);
    setLastName(lead?.name?.split(" ")[1]);
    setEmail(lead?.email);
    setPhone(lead?.phone);
  }, [lead]);

  const createOffer = async (method) => {

    if (!firstName) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Enter First Name"
        });
        return
    }

    if (!email && !phone) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Enter Email OR Cell Phone"
        });
        return
    }

    if (!company) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Select Fiber Company"
        });
        return
    }

    Swal.fire({
        icon: "info",
        title: 'Creating Offer',
        html: 'Please Wait',
        allowOutsideClick: false,
        customClass: 'custom-swal',
        didOpen: () => {
            Swal.showLoading()
        }
    })

    var pattern = new RegExp(/^\S+@\S+\.\S+$/);
    if (email && !pattern.test(email)) {
        Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'Please enter a valid email address',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
                Swal.hideLoading()
            }
        });
        return;
    }

    if (phone && phone.length < 11) {
        Swal.fire({
            icon: 'error',
            title: 'Invalid Phone Number',
            text: 'Please enter a valid phone number',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            didOpen: () => {
                Swal.hideLoading()
            }
        });
        return;
    }

    const req = new CreateOrEditOfferReq();
    req.firstName = firstName;
    req.lastName = lastName;
    if (email === "null") {
        req.email = null;
    } else {
        req.email = email
    }
    if (phone === "null") {
        req.phone = null;
    } else {
        req.phone = phone
    }
    //req.fiberType = "1G";
    req.leadId = lead.id;
    req.salesRepId = user?.id;
    req.fiberCompanyId =companyId;
    req.fidiumCustomerId = lead.fidiumCustomerId ? lead.fidiumCustomerId : 0;
    req.promo = free + "\n" + radioFree;
    req.templateId = company?.offerTemplates[0]?.id
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 10;
    const res = await OfferApi.CreateEditOffer(req);
    if (res?.status?.success) {
        Swal.fire({
            icon: "success",
            title: 'Offer Created Successfully',
        })
        const offerLink = window.location.origin + "/fiber-offer/" + res.data.offerGuid;
        const message = `Hey, ${firstName}${lastName ? " " + lastName : ""}.\n\nIt was great to meet today. As promised, the link below is the ${company?.name}’s Internet Offer.\n\nRemember… this is a LIMITED TIME OFFER, and the link will expire in 7 days from now. Click below and fill out the form to reserve your spot on the Free Install Map - and receive your FIRST MONTH FREE.\n\n${offerLink}`;
        if (method === 'email') {
            contactButtons.email(`${email}?subject=Digital Business Card&body=${encodeURIComponent(message)}`);
        }
        else if (method === "wa") {
            contactButtons.whatsapp(`${phone}?text=${encodeURIComponent(message)}`);
        }
        else if (method === "sms") {
            contactButtons.sms(`${phone}?body=${encodeURIComponent(message)}`);
        }
        else if (method === "copy") {
            Swal.fire({
                icon: 'info',
                title: 'Copying',
                text: 'Please Keep The Window Open To Copy Link',
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                toast: true
            })
            try {

                navigator.clipboard.writeText(window.location.origin + "/fiber-offer/" + res.data.offerGuid)
                Swal.fire({
                    icon: 'success',
                    title: 'Copied',
                    text: 'Link Copied to Clipboard',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            } catch (e) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Link Not Copied to Clipboard because browser window was closed',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            }
        }
        else {
            Swal.fire({
                icon: 'success',
                title: 'Offer Sent!',
                text: 'The Offer has been sent to the Prospect.',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Close',
                customClass: {
                    container: 'custom-swal'
                }
            })
        }

        const req = new EditFiberHouseReq();
        req.id = lead?.fiberHouse?.id;
        req.mapIconId = 8;
        req.coordinate = null;
        req.salesRepId = user?.id;

        try {
            const res = await FiberHouseApi.EditFiberHouse(req);
            if (res?.status?.success) {

            }
        }
        catch (e) {
            console.log(e)
        }

    }
    else {
        Swal.fire({
            icon: "error",
            title: 'Error',
            text: res?.status?.message
        })
    }

}

function sendOffer() {
  // if(lead?.length > 1) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Error",
  //     text: "Please Select One Lead"
  //   });
  //   return
  // }
    if (!firstName) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Enter First Name"
        });
        return
    }

    if (!consent) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Agree to Receive Email and/or SMS Messages"
        });
        return
    }

    if (!email && !phone) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Enter Email OR Cell Phone"
        });
        return
    }

    if (!company) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Select Fiber Company"
        });
        return
    }

    createOffer()
}

  return (
    <div className="max-w-xl mx-auto p-8 bg-white">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Send Offer</h2>

      <div className="space-y-4">
        <div>
          <label className="block text-lg font-medium text-gray-700">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-200 rounded-xl outline-none"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-gray-700">
            Company
          </label>
          <FiberCompanySelector
            companiesWithOfferTemplateOnly={true}
            setUser={setCompanyId}
            setCompanyObject={setCompany}
            placeholder="Select Fiber Company"
            className="w-full p-3 border border-gray-200 rounded-xl outline-none"
          />
        </div>

        <div className="w-full p-">
          <label className="block text-lg font-medium text-gray-700">
            Phone
          </label>
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            containerClass="w-full p-3 border border-gray-200 rounded-xl outline-none"
            inputClass="w-full border border-gray-200 rounded-xl outline-none"
            buttonStyle={{ height: "60%", top: "12px" }}
            inputStyle={{ width: "100%" }}
          />
        </div>
        {company && (
          <>
            <div className="mb-4">
              <label className="block text-lg font-medium text-gray-700">Promos</label>
              <div className="promo_options space-y-2">
                {company?.offerTemplates?.length > 0
                  ? company?.offerTemplates[0].promos
                      .split("\n")
                      .map((promoGroup, index) => {
                        return (
                          <div key={index} className="flex gap-4">
                              {
                                  promoGroup.split('"').map((promo, subIndex) => {
                                      if (promo.trim() === '') return null;
                                      if (promoGroup.includes('"')) {
                                          return (
                                              <label className="promo gap-2 flex" htmlFor={`free${index}-${subIndex}`} key={subIndex}>
                                                  <input
                                                      type="radio"
                                                      id={`free${index}-${subIndex}`}
                                                      name="promo"
                                                      onChange={(e) => {
                                                          // if (radioFree) {
                                                          //     let arr = radioFree.split('\n');
                                                          //     if (arr.includes(promo)) {
                                                          //         arr = arr.filter((item) => item !== promo);
                                                          //         setRadioFree(arr.join('\n'));
                                                          //     } else {
                                                          //         setRadioFree((prev) => (prev ? prev + '\n' + promo : promo));
                                                          //     }
                                                          // } else {
                                                              setRadioFree(promo);
                                                          // }
                                                      }}
                                                  />
                                                  <span>{promo}</span>
                                              </label>
                                          );
                                      } else {
                                          return (
                                              <label className="promo gap-2 flex" htmlFor={`free${index}-${subIndex}`} key={subIndex}>
                                                  <input
                                                      type="checkbox"
                                                      id={`free${index}-${subIndex}`}
                                                      name={`free${index}-${subIndex}`}
                                                      onChange={(e) => {
                                                          // if (free) {
                                                          //     let arr = free.split('\n');
                                                          //     if (arr.includes(promo)) {
                                                          //         arr = arr.filter((item) => item !== promo);
                                                          //         setFree(arr.join('\n'));
                                                          //     } else {
                                                          //         setFree((prev) => (prev ? prev + '\n' + promo : promo));
                                                          //     }
                                                          // } else {
                                                              setFree(promo);
                                                          // }
                                                      }}
                                                  />
                                                  <span>{promo}</span>
                                              </label>
                                          );
                                      }
                                  })
                              }
                          </div>
                      );
                      })
                  : null}
              </div>
            </div>
          </>
        )}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            checked={consent}
            onChange={() => setConsent(!consent)}
            className="form-checkbox h-5 w-5 text-blue-500 rounded border-gray-300"
          />
          <p className="text-sm text-gray-600">
            I agree to receive emails and/or sms messages from Lightning OS LLC.
          </p>
        </div>

        <button
          onClick={sendOffer}
          className="w-full bg-blue-500 text-white py-3 px-6 rounded-xl font-medium text-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Send Offer
        </button>
      </div>
    </div>
  );
};

export default SendOffer;

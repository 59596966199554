import React, { useState, useEffect } from "react";
import "./index.scss";
import { IoMdCheckmark } from "react-icons/io";

const MapStep = ({
  csvData,
  headersExtracted,
  mapping,
  onMappingChange,
  onValidationError,
}) => {
  // State to track mapping between extracted headers and target headers
  const [mappedHeaders, setMappedHeaders] = useState(
    headersExtracted.reduce((acc, header) => {
      const autoMap = mapping.find((map) => map.header === header);
      acc[header] = autoMap ? autoMap.header : ""; // Map to target header or leave empty
      return acc;
    }, {})
  );
  const [error, setError] = useState("");

  useEffect(() => {
    onMappingChange(mappedHeaders);
  }, [mappedHeaders, onMappingChange]);

  if (!csvData || headersExtracted.length === 0) {
    return <p>No data to map. Please upload a valid CSV file.</p>;
  }
  // Handle changes in mapping dropdown

  const handleMappingChange = (extractedHeader, targetHeader) => {
    setMappedHeaders((prev) => ({
      ...prev,
      [extractedHeader]: targetHeader,
    }));
    onMappingChange({ ...mappedHeaders, [extractedHeader]: targetHeader });
    setError("");
  };

  // missingRequiredFields
  const missingRequiredFields = mapping
    .filter((map) => map.Required)
    .map((map) => map.header)
    .filter(
      (requiredField) => !Object.values(mappedHeaders).includes(requiredField)
    );
  // validateMappings
  const validateMappings = () => {
    if (missingRequiredFields.length > 0) {
      setError(
        `The following required fields are not mapped: ${missingRequiredFields.join(
          ", "
        )}`
      );
      if (onValidationError) {
        onValidationError(missingRequiredFields);
      }
      return false;
    }
    return true;
  };
  const first3Rows = csvData.length > 0 ? csvData.slice(0, 3) : {};
  return (
    <div className="map-step">
      {error && <p className="error-message">{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Matched</th>
            <th>Column Header</th>
            <th>Preview</th>
            <th>Contact Fields</th>
          </tr>
        </thead>
        <tbody>
          {headersExtracted.map((header, index) => (
            <tr key={index}>
              <td>
                {mappedHeaders[header] ? (
                  <span>
                    <IoMdCheckmark color="#008000" />
                  </span>
                ) : (
                  <span style={{ color: "red", border: "2px solid red" }}>
                    X
                  </span>
                )}
              </td>
              <td>
                {console.log(missingRequiredFields)}
                {header}
                {missingRequiredFields.includes(header.toLowerCase()) && (
                  <span style={{ color: "red" }}>*</span>
                )}
              </td>
              <td>
                <span>{first3Rows[0]["Company Name"]}</span>
                <span>{first3Rows[1]["Company Name"]}</span>
                <span>{first3Rows[2]["Company Name"]}</span>
              </td>
              {/* Show first row's value */}
              <td>
                <select
                  value={mappedHeaders[header] || ""}
                  onChange={(e) => handleMappingChange(header, e.target.value)}
                >
                  <option value="">Not selected</option>
                  {mapping.map((map) => (
                    <option key={map.header} value={map.header}>
                      {map.header} ({map.Datatype},{" "}
                      {map.Required ? "Required" : "Optional"})
                    </option>
                  ))}
                </select>
                {mapping.some(
                  (map) => map.header === mappedHeaders[header] && map.Required
                ) &&
                  mappedHeaders[header] === "" && (
                    <span className="error-message">
                      This field is required.
                    </span>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        {Object.values(mappedHeaders).filter((val) => val === "").length}{" "}
        unmapped columns remain.
      </p>
      <button onClick={validateMappings}>Validate</button>
    </div>
  );
};

export default MapStep;

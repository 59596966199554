import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

import "./index.scss";
import Papa from "papaparse";
import * as XLSX from "xlsx"; // Import SheetJS
import UploadStep from "../Steps/UploadStep";
import MapStep from "../Steps/MapStep";
import DetailsStep from "../Steps/DetailsStep";
import Layout from "../../../../Layouts/Layout";
import { IoMdCheckmark } from "react-icons/io";

const FileUploadWizard = ({
  initialFile = null,
  onFileParsed = () => {},
  onFinish = () => {},
  mapping,
  apiCallback = null, // API function callback
  apiRequest = null, // API request object
  dataField = "data", // Default target field
  showLayout = true,
}) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(initialFile);
  const [csvData, setCsvData] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [error, setError] = useState("");
  const [mappedData, setMappedData] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);

  // useEffect(() => {
  //   if (initialFile) {
  //     parseFile(initialFile);
  //   }
  // }, [initialFile]);

  const parseFile = (file) => {
    const fileType = file.name.split(".").pop().toLowerCase();

    if (fileType === "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setCsvData(result.data);
          setHeaders(Object.keys(result.data[0]));
          onFileParsed(result.data);
        },
        error: (error) => {
          setError(`Error parsing CSV file: ${error.message}`);
          console.error("Error parsing CSV:", error);
        },
      });
    } else if (fileType === "xlsx" || fileType === "xls") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Use the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Convert to structured data
        const [headerRow, ...rows] = jsonData;
        const formattedData = rows.map((row) =>
          headerRow.reduce((acc, header, index) => {
            acc[header] = row[index] || "";
            return acc;
          }, {})
        );

        setCsvData(formattedData);
        setHeaders(headerRow);
        onFileParsed(formattedData);
      };

      reader.onerror = (error) => {
        setError(`Error reading Excel file: ${error.message}`);
        console.error("Error reading Excel file:", error);
      };

      reader.readAsArrayBuffer(file);
    } else {
      setError("Unsupported file type. Please upload a .csv or .xlsx file.");
      setFile(null);
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setError("");
      setFile(uploadedFile);
      parseFile(uploadedFile);
    }
  };

  const handleNext = () => {
    if (step === 1 && !file) {
      setError("Please upload a valid CSV file before proceeding.");
      return;
    }

    if (step === 2) {
      const missingFields = mapping
        .filter((map) => map.Required)
        .map((map) => map.header)
        .filter(
          (requiredField) => !Object.values(mappedData).includes(requiredField)
        );

      if (missingFields.length > 0) {
        // setValidationErrors(missingFields);
        Swal.fire({
          title: "Error!",
          html: `
            <p class="swal-error-message">Please select the following required fields:</p>
            <ul class="swal-error-list">
              ${missingFields.map((field) => `<li>${field}</li>`).join("")}
            </ul>
          `,
          icon: "error",
          confirmButtonText: "OK",
        });

        setError(
          `The following required fields are not mapped: ${missingFields.join(
            ", "
          )}`
        );
        return;
      } else {
        setValidationErrors([]);
      }
    }

    if (step < 3) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleFinish = async () => {
    const parsedData = parseMappedData();
    console.log("Parsed Data:", parsedData);

    // not testing yet
    if (apiCallback && apiRequest) {
      try {
        const requestPayload = {
          ...apiRequest,
          [dataField]: parsedData,
        };
        console.log(" requestPayload:", requestPayload);

        const response = await apiCallback(requestPayload);
        // const response = await apiCallback({ ...apiRequest, data: parsedData });
        console.log("Upload successful:", response);
        alert("Data uploaded successfully.");
      } catch (error) {
        console.error("Error during API call:", error);
        alert("Failed to upload data.");
      }
    } else {
      console.log("Data prepared but not uploaded:", parsedData);
      onFinish(parsedData);
    }

    //not use yet
    if (onFinish) {
      onFinish(parsedData);
    }
    //

    //onFinish(parsedData);
    //onFinish(csvData);
  };

  const StepIcon = styled("div")(({ theme, active }) => ({
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: active ? "green" : "lightgray",
    color: "white",
    fontSize: 20,
  }));
  const parseMappedData = () => {
    if (!csvData || csvData.length === 0) {
      alert("No data to parse.");
      return [];
    }

    const populatedObjects = csvData.map((row) => {
      const mappedObject = {};
      mapping.forEach((map) => {
        const targetHeader = Object.keys(mappedData).find(
          (key) => mappedData[key] === map.header
        );

        if (targetHeader) {
          // Map the CSV field value to the mapping object field
          mappedObject[map.header] = row[targetHeader] || "";
        } else if (map.Required) {
          // Required field is not mapped
          mappedObject[map.header] = ""; // Leave blank or handle as needed
          console.warn(`Missing required field: ${map.header}`);
        }
      });
      return mappedObject;
    });

    return populatedObjects;
  };

  const content = (
    <div className="file-upload">
      <div className="file-upload-wizard">
        <div className="steps">
          <div className="step">
            <StepIcon active={step === 1}>
              {step === 1 ? <IoMdCheckmark /> : "1"}
            </StepIcon>
            <span>Upload</span>
          </div>
          <div className="step">
            <StepIcon active={step === 2}>
              {step === 2 ? <IoMdCheckmark /> : "2"}
            </StepIcon>
            <span>Map</span>
          </div>
          <div className="step">
            <StepIcon active={step === 3}>
              {step === 3 ? <IoMdCheckmark /> : "3"}
            </StepIcon>
            <span>Details</span>
          </div>
        </div>

        <div className="step-content">
          {step === 1 && (
            <UploadStep
              file={file}
              error={error}
              handleFileChange={handleFileChange}
            />
          )}
          {step === 2 && (
            <MapStep
              csvData={csvData}
              headersExtracted={headers}
              mapping={mapping}
              onMappingChange={(mapped) => setMappedData(mapped)}
              onValidationError={(errors) => setValidationErrors(errors)}
            />
          )}
          {step === 3 && (
            <DetailsStep
              csvData={csvData}
              headersExtracted={headers}
              mappedData={mappedData || {}} // Ensure mappedData is not undefined
              mapping={mapping}
              onCancel={() => setStep(1)} // Reset to the first step
            />
          )}
        </div>

        <div className="actions">
          <button onClick={handleBack} disabled={step === 1}>
            Back
          </button>
          <button onClick={step === 3 ? handleFinish : handleNext}>
            {step === 3 ? "Finish" : "Next"}
          </button>
        </div>
        {validationErrors.length > 0 && (
          <div className="error-message">
            <p>
              Please resolve the following errors before proceeding: <br />
              {validationErrors.join(", ")}
            </p>
          </div>
        )}
      </div>
    </div>
  );

  return showLayout ? <Layout>{content}</Layout> : content;
};

export default FileUploadWizard;

import env from "../../../../../API/EnvSettings";
const API_URL = env.url;

export const CreateBlog = async (editorInstance, title, description) => {
  if (!editorInstance) {
    console.error("Editor not initialized yet.");
    return;
  }
  try {
    const content = await editorInstance.save();
    let blocks = [];
    for (let i = 0; i < content.blocks.length; i++) {
      let block = content.blocks[i];
      block = {
        type: content.blocks[i].type,
        data: JSON.stringify(block.data),
        blockNumber: i + 1,
      };

      blocks.push(block);
    }
    const blog = {
      Title: title.trim(),
      description,
      isDraft: true,
      userId: 153,
      blogBlocks: blocks,
    };

    await fetch(`${API_URL}SaveBlogData`, {
      method: "POST",
      body: JSON.stringify(blog),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    console.log("Blog Created Succesfully", blog);
    return blog;
  } catch (error) {
    console.error("Creating Blog failed:", error);
    throw error;
  }
};

class GenerateAffiliateCodeReq {
  constructor(userId, fiberCompanyId, code) {
    this.userId = userId;
    this.fiberCompanyId = fiberCompanyId;
    this.code = code;
  }
}

// module.exports = GenerateAffiliateCodeReq;
export default GenerateAffiliateCodeReq;

import React from "react"
import { useState, useRef, useCallback, useEffect } from "react"

const defaultOptions = [
  { label: "Heading 1", value: "h1" },
  { label: "Heading 2", value: "h2" },
  { label: "Bullet List", value: "ul" },
  { label: "Numbered List", value: "ol" },
  { label: "Quote", value: "quote" },
]

export default function Index({options,text,setText,disabled,className}) {

  const [showDropdown, setShowDropdown] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })
  const [slashIndex, setSlashIndex] = useState(-1)
  const [filteredOptions, setFilteredOptions] = useState(options ? options : defaultOptions)
  const textareaRef = useRef(null)

  const handleTextChange = (e) => {
    const newText = e.target.value
    setText(newText)

    const { selectionStart } = e.target
    const textBeforeCursor = newText.slice(0, selectionStart)
    const lastSlashIndex = textBeforeCursor.lastIndexOf("/")

    if (lastSlashIndex !== -1 && lastSlashIndex === selectionStart - 1) {
      setSlashIndex(lastSlashIndex)
      const lines = textBeforeCursor.split("\n")
      const currentLineNumber = lines.length - 1
      const currentLineStart = lines[currentLineNumber].length - 1

      if (textareaRef.current) {
        const { offsetLeft, offsetTop } = textareaRef.current
        const lineHeight = Number.parseInt(getComputedStyle(textareaRef.current).lineHeight)
        setDropdownPosition({
          top: offsetTop + lineHeight * currentLineNumber,
          left: offsetLeft + currentLineStart * 8, // Approximate character width
        })
      }
      setShowDropdown(true)
      setFilteredOptions(options ? options : defaultOptions)
    } else if (lastSlashIndex !== -1 && selectionStart > lastSlashIndex + 1) {
      const searchTerm = newText.slice(lastSlashIndex + 1, selectionStart).toLowerCase()
      const filtered = options ?  options.filter(
        (option) => option.label.toLowerCase().includes(searchTerm) || option.value.toLowerCase().includes(searchTerm),
      )
      :
      defaultOptions.filter(
        (option) => option.label.toLowerCase().includes(searchTerm) || option.value.toLowerCase().includes(searchTerm),
      )
      setFilteredOptions(filtered)
    } else if (lastSlashIndex === -1 || selectionStart <= lastSlashIndex) {
      setShowDropdown(false)
    }
  }

  const handleOptionSelect = (value) => {
    if (slashIndex !== -1) {
      const newText = text.slice(0, slashIndex) + value + " " + text.slice(slashIndex + 1)
      setText(newText)
      setShowDropdown(false)

      setTimeout(() => {
        if (textareaRef.current) {
          const newCursorPosition = slashIndex + value.length + 1
          textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
          textareaRef.current.focus()
        }
      }, 0)
    }
  }

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      setShowDropdown(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown )
    return () => {
      document.removeEventListener("keydown", handleKeyDown )
    }
  }, [handleKeyDown])

  const closeDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <div className="relative">
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleTextChange}
        placeholder="Type / to see options..."
        className={className}
        disabled={disabled}
      />
      {showDropdown && (
        <div
          className="absolute z-10 w-64 mt-1 bg-white rounded-md shadow-lg"
          style={{ top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}
        >
          <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
            <span className="text-sm font-semibold text-gray-700">Options</span>
            <button onClick={closeDropdown} className="text-gray-500 hover:text-gray-700 focus:outline-none">
              <svg
                className="h-4 w-4"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <ul className="py-1 overflow-auto max-h-60">
            {filteredOptions?.map((option) => (
              <li
                key={option.value}
                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleOptionSelect(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

import { useState } from "react";
import { createTag } from "../api/tagsAPI";
import Swal from "sweetalert2";

const TagCreateModal = ({ showModal, setShowModal, onCreateTag }) => {
  const [formData, setFormData] = useState({ name: "", description: "" });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return null;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createTag(formData);
      onCreateTag(formData);
      setFormData({ name: "", description: "" });
  
      Swal.fire({
        title: "Success!",
        text: "Tag created successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.log("Error creating the tag", error.message);
  
      Swal.fire({
        title: "Error!",
        text: "Failed to create the tag. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    setShowModal(false);
  };
  

  if (!showModal) return null;
  return (
    <div className="p-4">
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="w-full max-w-lg bg-white rounded-2xl shadow-lg p-10">
          <h2 className="text-xl font-bold mb-4">Add Details</h2>

          <div className="flex flex-col space-y-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Name"
              className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300 outline-none"
            />
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter Description"
              className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300 outline-none"
            />
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <button
              onClick={() => {
                setShowModal(false);
                setFormData({ name: "", description: "" });
              }}
              className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagCreateModal;

import React from "react";
import BusinessCard from "../../../Shared/BusinessCenter/components/BusinessCard";
import PointPlan from "./PointPlan";
import { IoWifi } from "react-icons/io5";
import { IoMdTv } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import ContactButtons from "../../../../Services/ContactButtons";
import MobileVersionBusinessCard from "../../../Reps/DigitalBusinessCenter/components/MobileVersionBusinessCard";
const Index = ({
  offer,
  setOpenConsultation,
  setOpenShareModal,
  setColor,
  setOpen,
  setSelectedPlan,
}) => {
  return (
    <div>
      <div
        style={{
          background: "none",
          backgroundColor: "black",
        }}
        className="bg-black new_offer_page"
      >
        <div
          style={{
            maxWidth: "800px",
          }}
          className="inner_container"
        >
          <div className="banner">
            <img
              style={{ width: "100%", height: "auto" }}
              src={`/images/offer/pointbroadband/pointbroadband_banner.png`}
              alt="banner"
              className="banner_image first"
            />
            <div className="top_accept_offer">
              <div className="inner_div">
                <img
                  style={{ width: "250px" }}
                  src={"data:image/png;base64," + offer?.fiberCompany?.logo}
                  alt="logo"
                  className="logo"
                />
                {
                  <h1 className="text-[2.375rem] font-[800] text-center text-black">
                    ULTRA-FAST
                    <br />
                    INTERNET FOR
                    <br />
                    <span className="text-[#F47920] text-[1.875rem]">
                      THE SPEED OF LIFE
                    </span>
                  </h1>
                }

                <button
                  className="text-white bg-black p-4 rounded-2xl text-center font-open-sans text-3xl font-extrabold uppercase "
                  onClick={() => {
                    document
                      .getElementById("plan1")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  VIEW PLANS{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="company_details">
            <p className="limited_offer">POINT BROADBAND</p>
            <p className="text-5xl font-[800] text-white">
              LIMITED TIME
              <br />
              HOT OFFERS
            </p>
            <div className="promo_offer_border">
              <h1
                style={{ border: "none" }}
                className="bg-[#FC7B1E] border-[#FC7B1E] promo_offer"
              >
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2.5rem",
                    fontWeight: "800",
                  }}
                >
                  FIRST MONTH
                  <br />
                  FREE INTERNET
                </span>
              </h1>
            </div>
            <p className="available">available with</p>
            <img
              src={`/images/offer/pointbroadband/authorized_white.png`}
              alt="fastwyre"
              className="company_logo"
            />
            <img
              src="/images/offer/lightning_logo.png"
              alt="lightning"
              className="company_logo"
            />
          </div>
          {/* {
                        offer?.salesRep &&
                        <div style={{
                            padding: "1rem",
                            marginTop: "2rem",
                            backgroundColor:"white"
                        }}>
                            <div style={{ backgroundColor: "white", padding: "1rem", borderRadius: "10px", color: "black", display: "flex", flexDirection: "column" }}>
                                <MobileVersionBusinessCard displayContactButtons={false} displayQr={false} reviewButton={false} displayOffer={false} salesRep={offer?.salesRep} salesRepIsUser={false} />
                            </div>
                        </div>
                    } */}
          {offer?.salesRep && (
            <div className="bg-white">
              <BusinessCard
                salesRep={offer?.salesRep}
                salesRepId={offer?.salesRepId}
                displayOffer={false}
                fiberCompany={offer?.fiberCompanyId}
              />
            </div>
          )}
          {offer?.offerTemplate && (
            <div className="banner" id="plan1">
              <img
                style={{ width: "100%", height: "auto" }}
                src="/images/offer/pointbroadband/gamer.png"
                alt="banner"
                className="banner_image"
              />
              <div
                className="flex justify-center items-center"
                style={{
                  backgroundColor: "#F47920",
                  marginTop: "-8rem",
                  padding: "5rem 1rem",

                  // borderRadius:"20px"
                }}
              >
                <PointPlan
                  offer={offer}
                  setColor={setColor}
                  color={
                    offer?.offerTemplate?.offerTemplateFiberPlans[0]?.color
                      ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color
                      : "#01A7E1"
                  }
                  setSelectedPlan={setSelectedPlan}
                  setOpen={setOpen}
                  name={
                    offer?.offerTemplate?.offerTemplateFiberPlans[1]
                      ?.promotionalName
                  }
                  plan={
                    offer?.offerTemplate?.offerTemplateFiberPlans[0].fiberPlan
                  }
                  bulletPoints={offer?.offerTemplate?.bulletPoints}
                />
              </div>
            </div>
          )}
          {offer?.offerTemplate && offer?.fiberCompanyId !== 10 && (
            <div
              style={{
                marginTop: "-8rem",
              }}
              className=" banner"
              id="plan1"
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src="/images/offer/pointbroadband/family.png"
                alt="banner"
                className="banner_image"
              />
              <div
                className="flex justify-center items-center"
                style={{
                  backgroundColor: "#F47920",
                  marginTop: "-8rem",
                  padding: "5rem 1rem",

                  // borderRadius:"20px"
                }}
              >
                <PointPlan
                  offer={offer}
                  setColor={setColor}
                  color={
                    offer?.offerTemplate?.offerTemplateFiberPlans[1]?.color
                      ? offer?.offerTemplate?.offerTemplateFiberPlans[1].color
                      : "#01A7E1"
                  }
                  setSelectedPlan={setSelectedPlan}
                  setOpen={setOpen}
                  name={
                    offer?.offerTemplate?.offerTemplateFiberPlans[1]
                      .promotionalName
                  }
                  plan={
                    offer?.offerTemplate?.offerTemplateFiberPlans[1].fiberPlan
                  }
                  bulletPoints={offer?.offerTemplate?.bulletPoints}
                />
              </div>
            </div>
          )}

          <div
            style={{
              marginTop: "-8rem",
            }}
            className="banner"
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src="/images/offer/schedule.png"
              alt="banner"
              className="banner_image"
            />
            <div
              style={{
                marginTop: "-4rem",
                width: "100%",
                backgroundColor: "white",
                padding: "2rem 0rem",
              }}
            >
              <button
                style={{
                  backgroundColor: "#F47920",
                }}
                onClick={() => setOpenConsultation(true)}
                className=" schedule_button"
              >
                Schedule A<br />
                Consultation
              </button>
              <h1 className="customer_service">
                Great Customer
                <br />
                Service is Built In.
              </h1>
              <p className="customer_service_p">
                We understand the importance
                <br /> of the services we provide to <br />
                your home – that’s why we
                <br />
                treat you like family.
              </p>
            </div>
          </div>
          {/* <div className=' banner'> */}
          {/* <div className='flex justify-center items-center flex-col p-4 gap-4' style={{ marginTop: "-4rem", width: "100%", backgroundColor: "black", padding: "2rem 0rem" }}>
                            <p className='text-5xl font-extrabold text-[#F47920]'>GIVE $100</p>
                            <p className='text-5xl font-extrabold'>GET $100</p>
                            <p className='text-lg text-center'>Give your friends a $100 gift card when they use your unique link to sign up for Point Broadband service online, and you'll get a $100 gift card*, too, after their service is installed.</p>
                            <p className="text-3xl text-center font-bold">GET $100 FOR EVERY<br />FRIEND YOU REFER</p>
                        </div> */}

          {/* </div> */}
          <div
            style={
              {
                // marginTop: "-8rem"
              }
            }
            className="banner"
          >
            {!window.location.href.includes("digital-business-center") && (
              <button
                onClick={() => {
                  // createSharedOffer()
                  setOpenShareModal(true);
                }}
                style={{ backgroundColor: "#F47920" }}
                className="schedule_button"
              >
                Share Offer
              </button>
            )}
            <img
              style={{ marginTop: "-3rem", width: "100%", height: "auto" }}
              src="/images/offer/pointbroadband/women.png"
              alt="banner"
              className="banner_image"
            />
          </div>
          <div
            style={{
              marginTop: "-8rem",
            }}
            className=" offer_services_container"
          >
            <div className="min-w-[350px] text-black inner_container">
              <h1>
                FIBER INTERNET
                <br />
                PROVIDER, TV,
                <br />
                DIGITAL PHONE
              </h1>
              <div className="services">
                <IoWifi color="#F47920" size={100} />
                <h3>Internet</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  See Plans
                </p>
              </div>
              <div className="services">
                <IoMdTv color="#F47920" size={100} />
                <h3>TV</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Start Streaming now
                </p>
              </div>
              <div className="services">
                <FaPhone color="#F47920" size={100} />
                <h3>Voice</h3>
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Talk More. Pay Less
                </p>
              </div>
            </div>
          </div>
          <div className="banner">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                borderTopLeftRadius: "0px",
                marginTop: "2rem",
                borderTopRightRadius: "0px",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="quick_h1">Quick Links</h1>
              <FaPhone size={70} color={"#170F49"} />
              <h3 className="quick_h3">Contact</h3>
              <p className="quick_p">Lightning Support</p>
              <p
                onClick={() => {
                  let contactButton = new ContactButtons();
                  contactButton.call("+12533000442");
                }}
                className="quick_p cursor-pointer underline"
              >
                253-300-0442
              </p>
              <img
                style={{ margin: "1rem 0rem" }}
                src={`/images/offer/pointbroadband/logo.png`}
                alt="fastwyre"
                className="company_logo"
              />
              <img
                style={{ margin: "1rem 0rem" }}
                src="/images/offer/logo.png"
                alt="lightning"
                className="company_logo"
              />
              <p className="quick_copy">
                Copyright Lightning OS 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React from 'react'
import { useState, useContext } from 'react';
import Layout from '../../../Layouts/Layout';
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq';
import FiberCompanyApi from '../../../API/FiberCompanyApi';

import { UserContext } from '../../../App';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import SearchOrgReq from '../../../Requests/Organizations/SearchOrgReq';
import OrgApi from '../../../API/OrgApi';
import { get } from 'sortablejs';
import { Search } from 'lucide-react';
import StringIntReq from '../../../Requests/StringIntReq';
import { use } from 'react';
import FiberHouseApi from '../../../API/FiberHouseApi';
import { AudioRecorder } from '../../TeamAdmin/MapPage/components/NewLeadCard/AudioRecording';





const Index = () => {

  const [leadType, setLeadType] = useState("Customer");
  const [accountStatus, setAccountStatus] = useState("Sold");

  const [dateType, setDateType] = useState("Last Updated");
  const [quickDate, setQuickDate] = useState("Today");
  const [fromDateDisplay, setFromDateDisplay] = useState("");
  const [toDateDisplay, setToDateDisplay] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [compaies, setCompaines] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const[searchString , setSearchString] = useState("");


  const [pitches, setPitches] = useState([]);


  const user = useContext(UserContext);


  const GetCompanies = async () => {

    let res = null;
    let searchReq = new SearchFiberCompanyReq();
    searchReq.hasOfferTemplate = true;

    if (user.userType === "SuperAdmin") {
      searchReq.salesOrgId = null;
      searchReq.salesRepId = null;
    }
    else if (user.userType === "SalesOrgAdmin") {
      searchReq.salesOrgId = user.salesOrgId;
    }
    else if (user.userType === "SalesRep") {
      searchReq.salesRepId = user.id;
      searchReq.salesOrgId = user.salesOrgId;
    }

    res = await FiberCompanyApi.SearchFiberCompany(searchReq)
    if (res?.status?.success) {
      let optionsArr = [];

      res?.data?.list.forEach((item) => {
        optionsArr.push({ value: item, label: item.name, id: item.id })
      })
      return optionsArr;
    }
    else {
      Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }



  }

  const GetPitches = async () => {
    
    let req = 
    {
      AccountStatus : accountStatus,
      SearchString : searchString.trim().toLowerCase(),
     
      
      

    }

    let res = await FiberHouseApi.GetPitches(req);

    if (res?.status?.success) {
      setPitches(res.data)
      console.log(res.data)
      
    }
    else {
      Swal.fire({
        title: 'Error',
        text: res.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })

    }
  }

  


  const GetOrg = async () => {
    let searchReq = new SearchOrgReq();

    if (user.userType === "SuperAdmin") {
      searchReq.salesOrgId = null;
      searchReq.salesRepId = null;
    }
    else if (user.userType === "SalesOrgAdmin") {
      searchReq.salesOrgId = user.salesOrgId;
    }
    else if (user.userType === "SalesRep") {
      searchReq.salesRepId = user.id;
      searchReq.salesOrgId = user.salesOrgId;
    }
    let res = await OrgApi.SearchOrg(searchReq);
    if (res?.status?.success) {
      let optionsArr = [];
      res?.data?.list.forEach((item) => {
        optionsArr.push({ value: item, label: item.name, id: item.id });
      });
      // if (!multiple)
      //   optionsArr.unshift({ value: -1, label: "None", id: -1 }); // Assign -1 to "None" option
      // if (allOption)
      //   optionsArr.unshift({ value: 0, label: "All", id: 0 }); // Assign -2 to "All" option
      return optionsArr;
    } else {
      return [];
    }
  };

  useEffect(() => {
    GetPitches();
  }, [ accountStatus , selectedCompany, searchString]);

  useEffect(() => {
    const getOptions = async () => {
      const optionsArr = await GetCompanies();
      setCompaines(optionsArr);
    };

    const getOrgOptions = async () => {
      const optionsArr = await GetOrg();
      setOrgs(optionsArr);
    };

    getOrgOptions();

    getOptions();
  }, []);


  const renderSelect = (label, value, options, setter) => (
    <div className="flex flex-col items-start gap-1 relative">
      <select
        value={value}
        onChange={(e) => setter(e.target.value)}
        className="py-1 w-28"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <span className="text-sm font-medium text-blue-600">{label}</span>
    </div>
  );

  const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };



  return (
    <Layout>
      <div className="p-6 max-w-[1400px] mx-auto">
        <div className="flex flex-wrap justify-between items-center gap-4 mb-6">
          <div className="flex items-center justify-between flex-wrap gap-4">

            {renderSelect(
              "LEAD TYPE",
              leadType,
              ["Customer", "Prospect", "All Leads"],
              setLeadType
            )}

            {leadType !== "All Leads"
              ? renderSelect(
                leadType === "Customer" ? "ACCOUNT STATUS" : "Status",
                accountStatus,
                leadType === "Customer"
                  ? [
                    "Sold",
                    "Sold Only",
                    "Verified Sold",
                    "Pending Installs",
                    "Completed",
                    "Pending Cancels",
                    "Canceled",
                    "Pending Reschedules",
                    "Reviewed",
                    "Missing",
                    "Claw Back",
                    "Paid",
                  ]
                  : ["All", "Offers Sent", "Offers Accepted"],
                setAccountStatus
              )
              : ""}

            {renderSelect(
              "COMPANIES",
              selectedCompany,
              compaies.map(company => company.label),
              setSelectedCompany
            )}

            {renderSelect(
              "ORGANIZATIONS",
              selectedOrg,
              orgs.map(org => org.label),
              setSelectedOrg
            )}

          </div>
        </div>

        {
          <div className="relative mb-6">
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="text"
              placeholder="Search"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              className="pl-10 w-full max-w-sm h-10 rounded-lg border border-gray-200 focus:outline-none focus:border-transparent"
              
            />
          </div>
        }

        <>
          <div className="border rounded-lg overflow-auto h-[50vh]">
            {
              // loading ?
              //   <div className="flex items-center justify-center h-full">
              //     <VersatileLoader size="large" />
              //   </div>
              //   :

              // name of the lead, address, email, phone number, account status and column which shows an audio player
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-slate-200">
                  <tr>
                    <th className="w-12 px-6 py-3">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 "
                      />
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Address
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Phone Number
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Account Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Audio
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {
                    pitches.map((pitch, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 "
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{pitch?.name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap max-w-60 overflow-hidden">
                          <div className="text-sm text-gray-900">{pitch?.fidiumCustomer?.address}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{pitch?.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{pitch?.phone}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{pitch?.fidiumCustomer?.accountStatus}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            <AudioRecorder leadId={pitch.id} pitch={pitch.pitch} setReload={GetPitches} />
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
               
              </table>

            }
          </div>
          {/* {!loading &&
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem ",
                  }}
                >
                  <Pagination
                    color="light"
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          next: (props) => (
                            <button {...props}>
                              <BiRightArrow color="var(--color-icon)" />
                            </button>
                          ),
                          first: (props) => <button {...props}>First</button>,
                          previous: (props) => (
                            <button {...props}>
                              <BiLeftArrow color="var(--color-icon)" />
                            </button>
                          ),
                        }}
                        {...item}
                        sx={{ color: "white" }}
                      />
                    )}
                    count={totalPages}
                    onChange={(e, pageNo) => {
                      setCurrentPage(pageNo);
                    }}
                  />
                </div>
              } */}
        </>
      </div>
    </Layout>
  )
}

export default Index
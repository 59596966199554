import React, { useContext, useEffect, useRef, useState } from 'react'
import UsersApi from '../../../../../API/UsersApi';
import { UserContext } from '../../../../../App';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Cluster } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

import CircleStyle from 'ol/style/Circle';

const RenderCandidates = ({ mapIcons, leadType, accountStatus, industry, dateType, from, to, map, candidates, setCandidates,selectedCandidateIds,setSelectedCandidateIds }) => {

    const [fiberHousesLoading, setFiberHousesLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const candidateLayerRef = useRef(null);
    const selectedCandidateRef = useRef([]);

    const user = useContext(UserContext)

    const fetchCandidates = async () => {

        setFiberHousesLoading(true)
        if (user.userType === "SuperAdmin") {

            setLoading(true);
            let req = {
                ids: null,
                country: null,
                state: null,
                leadType: [leadType],
                status: accountStatus === "All"? null : accountStatus,
                industries: industry && industry[0]!=="All" ? industry : null,
            };

            switch (dateType) {
                case "created":
                    req.createdFrom = from;
                    req.createdTo = to;
                    break;
                case "appointment":
                    req.appointmentFrom = from;
                    req.appointmentTo = to;
                    break;
                case "lastUpdated":
                    req.lastUpdatedFrom = from;
                    req.lastUpdatedTo = to;
                    break;
                default:
                    break;
            }

            let res = await UsersApi.getCandidates(req);
            if (res?.status?.success) {
                setCandidates(res.data);
                setLoading(false);
                setFiberHousesLoading(false)
            }
        }
    }

    useEffect(
        () => {
            fetchCandidates()
        }, [leadType, accountStatus, industry, dateType, from, to]
    )

    const renderCandidatesOnMap = () => {
        if (candidateLayerRef.current) {
            candidateLayerRef.current.getSource().getSource().clear();
            map.removeLayer(candidateLayerRef.current);
            candidateLayerRef.current = null;
            map.render();
        }

        const features = candidates
            .filter(cand => cand.coordinates && !cand.remove) // Ensure only valid candidates are included
            .map((cand) => {
                const { coordinates, id, mapIconId, selected } = cand;

                if (coordinates && coordinates.longitude !== undefined && coordinates.latitude !== undefined) {
                    const coordinate = [coordinates.longitude, coordinates.latitude];
                    const feature = new Feature({
                        geometry: new Point(fromLonLat(coordinate)),
                        data: {
                            id: id,
                            mapIconId: mapIconId,
                            selected: selected,
                        },
                    });
                    return feature;
                }
                return null; // In case coordinates are still somehow invalid
            })
            .filter(Boolean); // Filter out any `null` values from the features array

        const vectorSource = new VectorSource({
            features: features,
        });

        const clusterSource = new Cluster({
            distance: 50,
            source: vectorSource,
        });
        const styleCache = {};

        const vectorLayer = new VectorLayer({
            source: clusterSource,
            name: "candidates",
            style: (feature) => {
                const size = feature.get("features").length;
                let style = styleCache[size];

                if (size === 1) {
                    const singleFeature = feature.get("features")[0];
                    const candidateData = singleFeature.get("data");
                    console.log("Candidate Data:", candidateData);
                    let iconUrl = mapIcons?.find((icon) => icon.id === candidateData.mapIconId)?.image;
                    if (selectedCandidateRef.current.length > 0 && selectedCandidateRef.current.filter((cand) => cand.get('data').id === candidateData.id).length > 0) {
                        style = [
                            new Style({
                                image: new Icon({
                                    src: "data:image/png;base64," + iconUrl,
                                    scale: 0.5,
                                }),
                            }),
                            new Style({
                                image: new CircleStyle({
                                    radius: 22,
                                    stroke: new Stroke({
                                        color: "blue",
                                        width: 4,
                                    }),
                                    fill: new Fill({
                                        color: "rgba(0, 0, 0, 0)", // Transparent fill
                                    }),
                                }),
                            }),
                        ];
                    }
                    else {
                        style = new Style({
                            image: new Icon({
                                src: "data:image/png;base64," + iconUrl,
                                scale: 0.3,
                            }),
                        });
                    }
                } else {
                    style = new Style({
                        image: new CircleStyle({
                            radius: 22,
                            stroke: new Stroke({
                                color: "#fff",
                                width: 3,
                            }),
                            fill: new Fill({
                                color: map.getView().getZoom() > 15 ? "#800080" : "#d1390a",
                            }),
                        }),
                        text: new Text({
                            text: size.toString(),
                            fill: new Fill({
                                color: "#fff",
                            }),
                            font: "bold 12px Arial",
                            scale: 1
                        }),
                    });
                }

                styleCache[size] = style;
                return style;
            },
        });

        map.addLayer(vectorLayer);
        candidateLayerRef.current = vectorLayer;
    }

    useEffect(
        () => {
            if (map && candidates.length > 0) {
                renderCandidatesOnMap();
            }

            return () => {
                if (candidateLayerRef.current) {
                    candidateLayerRef.current.getSource().getSource().clear();
                    map.removeLayer(candidateLayerRef.current);
                    candidateLayerRef.current = null;
                    map.render();
                }
            }

        }, [candidates]
    )

    const handleCandidateMapClick = (event) => {

        map.forEachFeatureAtPixel(event.pixel, (feature) => {
            const size = feature.get("features")?.length;
            const features = feature.get("features");

            if (size > 1) {
                // Handle clustered feature
                let candidateIds = [];
                console.log("Clustered features:", features);
                features.forEach((singleFeature) => {
                    const candidateData = singleFeature.get("data");
                    if (candidateData && candidateData.id) {
                        if (candidateData.id !== undefined) {
                            candidateIds.push(candidateData.id);
                        }
                    }
                });
                console.log("Candidate IDs:", candidateIds);
                // count++;
                return;

            } else if ((size === 1 && features[0].get("data"))) {
                console.log(features)
                const candidateFeature = features[0];
                const candidateData = candidateFeature.get("data");




                if (selectedCandidateRef.current.filter((cand) => cand.get('data').id === candidateData.id).length > 0) {
                    
                    selectedCandidateRef.current = selectedCandidateRef.current.filter((cand) => cand.get('data').id !== candidateData.id);
                    setSelectedCandidateIds(selectedCandidateIds?.filter((id) => id !== candidateData.id));
                    return;
                }

                // Highlight the newly selected feature
                const newStyle = [
                    new Style({
                        image: new Icon({
                            src: "data:image/png;base64," + mapIcons?.find(icon => icon.id === candidateData.mapIconId)?.image,
                            scale: 0.5,
                        }),
                    }),
                    new Style({
                        image: new CircleStyle({
                            radius: 15,
                            stroke: new Stroke({
                                color: "blue",
                                width: 4,
                            }),
                            fill: new Fill({
                                color: "rgba(0, 0, 0, 0)", // Transparent fill
                            }),
                        }),
                    }),
                ];
                candidateFeature.setStyle(newStyle);
                selectedCandidateRef.current.push(candidateFeature);
                setSelectedCandidateIds(selectedCandidateRef.current.map((cand) => cand.get('data').id));
                return;
            }


        });
    }

    useEffect(() => {
        if (map) {
          map.on("click", handleCandidateMapClick);
    
          return () => {
            map.un("click", handleCandidateMapClick); // Clean up on unmount
          };
        }
      }, [map]);

    return (
        <>
        </>
    )
}

export default RenderCandidates
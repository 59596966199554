class UpdateLeadAppointmentReq{
    constructor(){
        this.leadId = null;
        this.appointmentType = null;
        this.appointmentDateTime = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.createNewLead = false;
        this.companyId = null;
        this.salesRepId = null;

    }
}

export default UpdateLeadAppointmentReq;
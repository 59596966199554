import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, FormControl, FormLabel, Box, Grid } from '@mui/material';
import Swal from 'sweetalert2';
import PublicLayout from '../../../../Layouts/PublicLayout';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import logo from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png";
import './ConsultationForm.scss';
import StringIntReq from '../../../../Requests/StringIntReq';
import { useParams } from 'react-router-dom';
import FiberCompanyApi from '../../../../API/FiberCompanyApi';
import UsersApi from '../../../../API/UsersApi';
import CreateConsultationReq from '../../../../Requests/Users/CreateConsultationReq';

const ConsultationForm = () => {
    const { id } = useParams();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [candidateId, setCandidateId] = useState('');
    const [positionTitle, setPositionTitle] = useState('');
    const [industry, setIndustry] = useState('');
    const [consultationDate, setConsultationDate] = useState('');
    const [message, setMessage] = useState('');
    const [inviteId, setInviteId] = useState('');
    const [formTextMessage, setFormTextMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name': setName(value); break;
            case 'email': setEmail(value); break;
            case 'companyName': setCompanyName(value); break;
            case 'positionTitle': setPositionTitle(value); break;
            case 'industry': setIndustry(value); break;
            case 'consultationDate': setConsultationDate(value); break;
            case 'message': setMessage(value); break;
        }
    };

    const getInviteData = async () => {
        let req = new StringIntReq();
        req.string = id;
        const res = await UsersApi.GetCandidateInvitationById(req);

        if (res?.status?.success) {
            const { data } = res;
            console.log(data);
            setName(data.candidate.name);
            setEmail(data.candidate.personalEmail);
            setPhoneNumber(data.candidate.phone ?? null);
            setCompanyName(data.candidate.companyName);
            setPositionTitle(data.candidate.positionTitle);
            setIndustry(data.candidate.industry);
            setInviteId(data.id);
            setCandidateId(data.candidate.id);
            setIsSubmitted(data.accepted)
            setFormTextMessage(data.candidateRecruitTemplate.templateText ?? "A 30-minute initial consultation Discussion of your company's needs and challenges Overview of our services and how we can help Opportunity to ask questions and explore potential solutions");

        }
    }

    useEffect(() => {
        getInviteData();
    }, []);

    const submitConsultationRequest = async () => {
        Swal.fire({
            icon: "info",
            title: "Submitting Consultation Request",
            text: "Please wait...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (!name || !email || !phoneNumber || !companyName || !positionTitle || !industry || !consultationDate) {
            Swal.fire({
                icon: "error",
                title: "Missing Information",
                text: "Please fill in all required fields",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        let req = new CreateConsultationReq();
        req.candidateInvitationId = inviteId;
        req.additionalInfo = message;
        req.phone = phoneNumber;
        req.name = name;
        req.personalEmail = email;
        req.positionTitle = positionTitle;
        req.prefferedDateTime = consultationDate;

        console.log(req);
        let res = await UsersApi.CreateCandidateInviteConsultation(req)

        if (res?.status?.success) {
            setIsSubmitted(true);
            const users = [
                // {
                //     value: "https://calendly.com/jessica-ylwk/interview",
                //     label: "Jessica Man (jessica@lightningos.world)",
                //     type: "Recruit"
                // },
                {
                    value: "https://calendly.com/shasta-lightningos/interview",
                    label: "Shasta Weishampel (shasta@lightningos.world)",
                    type: "Company"
                }
            ];


            const selectedOption = users.find(user => user.type === "Company");

            let interviewRound = 1;
            let link = ``;
            link = `${selectedOption.value}?a2=${candidateId}&a3=${"Company"}`;

            const option = await Swal.fire({
                icon: "success",
                title: "Consultation Request Submitted",
                text: "Thank you for your interest. We'll be in touch soon!",
                showCancelButton: true,
                confirmButtonText: "Schedule Consultation",
                cancelButtonText: "Close",
                customClass: {
                    container: 'custom-swal'
                }
            });

            if (option.isConfirmed) {
                window.open(link, "_blank");
            }

        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                }
            });
        }
    };

    if (isSubmitted) {
        return (
            <PublicLayout>
                <Box className='thank_you_container'>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="h1" align="center" gutterBottom>
                                Thank you for your consultation request!
                            </Typography>
                            <Typography variant="body1" align="center">
                                We have received your information and will contact you shortly to confirm your consultation.
                            </Typography>
                            <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
                                If you have any immediate questions, please don't hesitate to contact our support team.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout>
            <Box className='consultation_container'>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card className='consultation_info'>
                            <CardContent>
                                <Typography variant="h4" component="h1" gutterBottom>Book a Consultation</Typography>
                                <Typography variant="body1" paragraph>
                                    Thank you for your interest in our sales services. Please fill out the form to schedule a consultation with one of our experts. We look forward to helping your company achieve its goals.
                                </Typography>
                                <Typography variant="h6" component="h2" gutterBottom>What to Expect:</Typography>
                                <ul>
                                    <p>{formTextMessage}</p>
                                </ul>
                                <Typography variant="body1" paragraph>
                                    After submitting the form, we will contact you within 1-2 business days to confirm your consultation date and time.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card className='consultation_form'>
                            <CardContent className='form_content'>
                                <Box component="img" src={logo} alt='Company_Logo' className='company_logo' />
                                <Typography variant="h5" component="h2" align="center" gutterBottom>Book a 30m meeting to explore a sales partnership!</Typography>
                                <Box className='form_fields'>
                                    <div className="input-group">
                                        <label htmlFor="firstName">Name</label>
                                        <input type="text" id="name" name="name" value={name} onChange={handleChange} required disabled />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" id="email" name="email" value={email} onChange={handleChange} required />
                                    </div>
                                    <PhoneInput
                                        containerClass='phone_input_container'
                                        inputClass='phone_input'
                                        country={'us'}
                                        value={phoneNumber}
                                        onChange={(phone) => setPhoneNumber(phone)}
                                    />
                                    <div className="input-group">
                                        <label htmlFor="companyName">Company Name</label>
                                        <input type="text" id="companyName" name="companyName" value={companyName} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="positionTitle">Position Title</label>
                                        <input type="text" id="positionTitle" name="positionTitle" value={positionTitle} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="industry">Industry</label>
                                        <input type="text" id="industry" name="industry" value={industry} onChange={handleChange} required />
                                    </div>
                                    <div className="input-group datetime-picker">
                                        <label htmlFor="consultationDate">Preferred Consultation Date/Time</label>
                                        <input
                                            type="datetime-local"
                                            id="consultationDate"
                                            name="consultationDate"
                                            value={consultationDate}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="message">Additional Information or Questions (Optional)</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={4}
                                            value={message}
                                            onChange={handleChange}
                                            className="full-width-textarea"
                                        />
                                    </div>
                                </Box>
                            </CardContent>
                            <Box className='form_footer'>
                                <Box className='submit_button_container'>
                                    <Button variant="contained" color="primary" onClick={submitConsultationRequest} fullWidth>
                                        Submit Consultation Request
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </PublicLayout>
    );
};

export default ConsultationForm;
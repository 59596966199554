import React, { useEffect } from "react";
import { Button, Modal } from "@mui/material";
import authorized from "./Images/authorized.png";
import { IoCloseCircle } from "react-icons/io5";
import { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import CustomerApi from "../../../../../API/CustomerApi";
import { MapContext } from "../..";

import { useContext } from "react";
import StringIntReq from "../../../../../Requests/StringIntReq";

const CustomNote = ({ open, setOpen, user, setReload, setCustomNotes }) => {
  const [frontImage, setFrontImage] = useState(null);
  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [note, setNote] = useState(null);
  const [mapIcon, setMapIcon] = useState(null);
  const { mapIcons } = useContext(MapContext);

  useEffect(() => {
    getCustomNotes();
  }, []);

  const uploadFileToFirebase = async (file, path) => {
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          Swal.update({
            text: `Uploading ${file.name} ${progress.toFixed(0)}%`,
          });
        },
        (error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Upload Failed",
            text: `Failed to upload ${file.name}.`,
            customClass: {
              container: "custom-swal",
            },
            didOpen: () => {
              Swal.hideLoading();
            },
          });
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(storageRef);
          resolve({
            name: file.name,
            url: downloadURL,
            path: path,
            extension: file.name.split(".").pop(),
            fileSize: file.size.toString(),
            fileType: file.type,
            caption: file.name,
          });
        }
      );
    });
  };

  const handleIconUpload = async (e) => {
    Swal.fire({
      icon: "info",
      title: "Uploading Image",
      text: "Please wait...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "custom-swal",
      },
      didOpen: () => Swal.showLoading(),
    });
    const file = e.target.files[0];
    if (file) {
      const path = `/customer/${user.firebaseId}/customNote/${uuidv4()}`;
      try {
        const uploadedFile = await uploadFileToFirebase(file, path);
        setFrontImage(uploadedFile); // Update the state with the new file info
        setFrontImageUrl(uploadedFile.url); // Immediately show the new image in the UI

        Swal.fire({
          icon: "success",
          title: "File Uploaded",
          customClass: {
            container: "custom-swal",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
  };

  //   console.log(mapIcons);

  const handleSaveNote = async () => {
    if (!note) {
      Swal.fire({
        icon: "error",
        title: "Note is empty",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    if (!frontImage) {
      Swal.fire({
        icon: "error",
        title: "Please upload an icon",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    if (!mapIcon) {
      Swal.fire({
        icon: "error",
        title: "Please select an icon",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      return;
    }
    const icon = {
      name: frontImage.name,
      url: frontImage.url,
      path: frontImage.path,
      extension: frontImage.extension,
      fileSize: frontImage.fileSize,
      fileType: frontImage.fileType,
      caption: frontImage.caption,
    };
    let req = {
      note: note,
      createdById: user.id,
      icon: icon,
      MapIconId: mapIcon?.id,
    };

    let res = await CustomerApi.CreateCustomQuickNote(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Note Added",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      setOpen(false);
      setReload(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to Add Note",
        customClass: {
          container: "custom-swal",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const getCustomNotes = async () => {
    let req = new StringIntReq();
    req.Int = user?.id;

    let res = await CustomerApi.GetCustomQuickNote(req);

    if (res.status.success) {
      setCustomNotes(res.data);
      setReload(true);
      // console.log(res.data);
    } else {
      console.log(res.message);
    }
  };

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      
    >
      <div className=" p-4 bg-white rounded-md flex flex-col gap-4 overflow-y-auto sm:w-[50%] w-[90%] max-h-[80vh]">
        <div className="flex justify-between items-center" >
        <h2 className="text-xl font-bold text-gray-800">Add Your Note</h2>

        <IoCloseCircle
          onClick={() => {
            setOpen(false);
          }}
          size={30}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          color="#2761d0"
        />
        {/* <img alt="Logo" src={authorized} className="logo" /> */}

        </div>
        <p className="text-sm text-gray-400">Quick Note</p>

        <div className="MainLeadCard_MapIcons">
          <div className="MapIconDisplay">
            {mapIcons?.map((icon, index) => (
              <div
                key={index}
                className={`MapIconDiv`}
                style={{
                  border: (() => {
                    if (mapIcon?.id === icon.id) {
                      return "2px solid #2761d0";
                    } else {
                      return "2px solid gray";
                    }
                  })(),
                }}
                onClick={() => {
                  setMapIcon(icon);
                }}
              >
                <img
                  src={"data:image/png;base64," + icon.image}
                  alt="icon"
                  className="w-16"
                />
                <div>{icon.abbreviation}</div>
              </div>
            ))}
          </div>
        </div>

        <textarea
          className="w-full min-h-24 p-2 mt-2 border border-gray-300 rounded-md"
          placeholder="Write something here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        {/* <Button
          variant="outlined"
          component="label"
          sx={{
            width: "100%",
            textAlign: "left",
            border: "1px solid lightgray",
          }}
        >
          Upload Icon
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handleIconUpload(e)} // Update preview here
          />
        </Button>
        {iconPreview && (
          <div className="w-full h-24 mt-2 border border-gray-300 rounded-md">
            <img
              src={iconPreview}
              alt="icon"
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        )} */}

        <Button
          variant="outlined"
          component="label"
          sx={{
            width: "100%",
            textAlign: "left",
            border: "1px solid lightgray",
          }}
        >
          Upload Icon
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handleIconUpload(e)} // Update preview here
          />
        </Button>
        {frontImageUrl && (
          <div className="w-full h-24 mt-2 border border-gray-300 rounded-md">
            <img
              src={frontImageUrl}
              alt="icon"
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        )}

        <button
          onClick={() => {
            handleSaveNote();
          }}
          className="w-full py-2 mt-4 text-sm font-semibold text-white bg-blue-500 rounded-md"
        >
          Add Note
        </button>
      </div>
    </Modal>
  );
};

export default CustomNote;

import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { Star, Mail, Phone, MapPin } from "lucide-react"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { TbMessage } from "react-icons/tb"
import FiberCompanyApi from "../../../../API/FiberCompanyApi"
import StringIntReq from '../../../../Requests/StringIntReq'
import ContactButtons from '../../../../Services/ContactButtons'
import VersatileLoader from '../../../../components/VersatileLoader'
import './MiniSalesRepCard.scss'
import { MapContext } from '..'
// import { motion } from 'framer-motion'

const MiniSalesRepCard = ({ salesRep, openNextRep, openPreviousRep, onTrack }) => {
  
  const map = useContext(MapContext); // Added context access

  const [companies, setCompanies] = useState([])
  const [rank, setRank] = useState(0)
  const [totalSales, setTotalSales] = useState(0)
  const [totalInstalls, setTotalInstalls] = useState(0)
  // const [totalReferrals, setTotalReferrals] = useState(0)
  const [totalReviews, setTotalReviews] = useState(0)
  const [rating, setRating] = useState(0)
  const [loading, setLoading] = useState(false)
  const contactButtons = new ContactButtons()





  const formatDateToISO = (date) => {
    const pad = (n) => String(n).padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.${String(date.getMilliseconds()).padStart(3, '0')}`;
  };

  // Add this handler for the Track button
  const handleTrackRep = () => {
    // console.log("SaleRep from MiniSalesRepCard all: ", allSalesReps);
    if (!map || !salesRep?.id) return;

  const today = new Date();
  const startOfDay = new Date(today.setHours(0, 0, 0, 0));
  const endOfDay = new Date(today.setHours(23, 59, 59, 999));

  // Update map context
  map.setSalesRepIds?.([salesRep?.id]);
  map.setLocationFromDate?.(formatDateToISO(startOfDay));
  map.setLocationToDate?.(formatDateToISO(endOfDay));
  map.setTargetUserId?.(salesRep?.id);
  map.setUserPathColor?.('#3f51b5');
  map.setCurrentSalesRepId(salesRep?.id);

  // console.log("SalesRep from MiniSalesRepCard map data: ", map.currentSalesRepId, "sales rep id = ", salesRep?.id);




  // Notify parent to show tracking tab
  if (onTrack) {
    onTrack(salesRep);
  }
  console.log(`SalesRep from handleTrackRep method: ${salesRep.firstName}`);
  };

  // console.log(`SalesRep data: ${salesRep}`);

  const getFiberCompanies = async () => {
    let req = {
      id: salesRep?.fiberCompanyId,
      hasOfferTemplate: true,
      pagingParams: {
        pageNumber: 1,
        pageSize: 100
      }
    }
    const res = await FiberCompanyApi.SearchFiberCompany(req)
    if (res?.status?.success) {
      setCompanies(res.data.list)
    }


  }

  const getRepStats = async () => {
    setLoading(true)
    let req = new StringIntReq();

    req.Int = salesRep?.id
    const res = await FiberCompanyApi.GetRepStats(req)
    if (res?.status?.success) {
      setRank(res.data.rank)
      setTotalSales(res.data.totalSales)
      setTotalInstalls(res.data.totalInstalls)
      // setTotalReferrals(res.data.totalReferrals)
      setTotalReviews(res.data.totalReviews)
      setRating(res.data.avgRating)
      setLoading(false)
    }
    else {
      setRank(0)
      setTotalSales(0)
      setTotalInstalls(0)
      // setTotalReferrals(0)
      setTotalReviews(0)
      setRating(0)
      setLoading(false)
    }
  }

  useEffect(() => {
    getRepStats()
    getFiberCompanies()
  }, [salesRep])

  const renderStars = (rating) => {
    return (
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            className={`w-4 h-4 ${i < rating ? 'fill-yellow-400 stroke-yellow-400' : 'stroke-gray-300'}`}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="w-80 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
      {loading ? (
        <div className="p-6 flex flex-col items-center">
          <VersatileLoader size="medium" color="#2761D0" />
          <p className="mt-3 text-sm text-gray-500">Loading profile...</p>
        </div>
      ) : (
        <>
          {/* Compact Header */}
          <div className="p-4 flex items-center gap-3 border-b">
            <div className="relative">
              <img 
                src={salesRep?.profileImage?.url || '/avatar-placeholder.png'}
                className="w-14 h-14 rounded-full border-2 border-white shadow"
                alt="Profile"
                onError={(e) => e.target.src = '/avatar-placeholder.png'}
              />
              {/* Online Status Indicator */}
              <div 
                className={`absolute top-0 right-0 w-3 h-3 rounded-full border-2 border-white ${
                  salesRep?.isOnline ? 'bg-green-700' : 'bg-gray-200'
                }`}
              />

              <div className="absolute -bottom-1 -right-1 bg-blue-600 text-white text-xs px-1.5 rounded-full">
                #{rank || 'NA'}
              </div>
            </div>
            
            <div className="flex-1 min-w-0">
            <h3 className="font-semibold truncate">
  {salesRep?.firstName || ''} {salesRep?.lastName || ''}
</h3>
              <div className="flex items-center gap-1 mt-1 text-sm">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`w-4 h-4 ${i < Math.round(rating) 
                      ? 'fill-yellow-400' 
                      : 'fill-gray-200'}`}
                  />
                ))}
                <span className="text-gray-500 ml-1">({totalReviews})</span>
              </div>
              <div className="flex items-center gap-1 text-sm text-gray-500 truncate">
  <MapPin className="w-4 h-4 flex-shrink-0" />
  {salesRep?.address || 'Location not available'}
</div>
            </div>
          </div>

          {/* Scrollable Content */}
          <div className="max-h-[400px] overflow-y-auto">
            {/* Companies */}
            {companies.length > 0 && (
              <div className="p-4 border-b">
                <h4 className="text-sm font-medium mb-2">Companies</h4>
                <div className="flex gap-2 overflow-x-auto">
                  {companies.map((company, i) => (
                    <div key={i} className="flex items-center gap-2 bg-gray-50 px-2 py-1 rounded">
                      <img 
                        src={`data:image/png;base64,${company.logo}`} 
                        className="w-6 h-6 object-contain" 
                        alt={company.name}
                      />
                      <span className="text-sm whitespace-nowrap">{company.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Stats */}
            <div className="p-4 border-b">
              <h4 className="text-sm font-medium mb-2">Performance</h4>
              <div className="grid grid-cols-2 gap-2">
                {[
                  { value: totalSales, label: 'Sales', color: 'text-blue-600' },
                  { value: totalInstalls, label: 'Installs', color: 'text-green-600' },
                  { value: 0, label: 'Referrals', color: 'text-purple-600' },
                  { value: totalReviews, label: 'Reviews', color: 'text-orange-600' }
                ].map((stat, i) => (
                  <div key={i} className="p-2 bg-gray-50 rounded">
                    <div className={`text-xl font-bold ${stat.color}`}>
                      {stat.value.toLocaleString()}
                    </div>
                    <div className="text-sm text-gray-500">{stat.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className="p-4 flex justify-between items-center">
            <div className="flex gap-2">
              <IconButton onClick={openPreviousRep} icon={FaArrowLeft} />
              <IconButton 
                onClick={() => new ContactButtons().sms(salesRep.phoneNumber)} 
                icon={TbMessage}
                tooltip="Send message"
              />
            </div>
            
            <button 
      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors relative"
      onClick={handleTrackRep}
    >
      Track
      {map.targetUserId === salesRep?.id && (
        <span className="absolute -top-2 -right-2 w-3 h-3 bg-green-400 rounded-full animate-pulse"></span>
      )}
    </button>

            <div className="flex gap-2">
              <IconButton 
                onClick={() => new ContactButtons().email(salesRep.email)} 
                icon={Mail}
                tooltip="Send email"
              />
              <IconButton 
                onClick={() => new ContactButtons().call(salesRep.phoneNumber)} 
                icon={Phone}
                tooltip="Make call"
              />
              <IconButton onClick={openNextRep} icon={FaArrowRight} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const IconButton = ({ icon: Icon, onClick, tooltip }) => (
  <button 
    onClick={onClick}
    className="p-2 hover:bg-gray-100 rounded-full relative group"
    aria-label={tooltip}
  >
    <Icon className="w-5 h-5 text-gray-600" />
    {tooltip && (
      <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
        {tooltip}
      </span>
    )}
  </button>
)

export default MiniSalesRepCard;
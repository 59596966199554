import { useEffect, useState } from "react";
import { useEditor } from "../../hooks/useEditor";
import { CreateBlog } from "./CreateBlog";
import { useNavigate, useParams } from "react-router-dom";
import { getBlog } from "../../api/blogApi";
import { EditPost } from "./UpdateBlog";
import Swal from "sweetalert2";
import Layout from "../../../../../Layouts/Layout";

const PostEditor = ({ action }) => {
  const { id: postId } = useParams();
  const [editorData, setEditorData] = useState(null);
  const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [init, setInit] = useState(false);
  const editorInstance = useEditor(action === "edit" ? editorData : null, init);
  const navigate = useNavigate();

  useEffect(() => {
    if (action === "edit") {
      async function LoadBlog() {
        try {
          const blog = await getBlog(postId);
          setBlog(blog.data);
          const blocks = blog.data.blogBlocks
            .map((block) => ({
              id: block.id,
              type: block.type,
              data: JSON.parse(block.data),
            }))
            .map((b) =>
              b.type !== "list"
                ? b
                : {
                    ...b,
                    data: {
                      style: b.data.style,
                      items: b.data.items.map((item) => item.content),
                    },
                  }
            );

          const editorData = {
            time: 1550476186479,
            blocks,
            version: "2.8.1",
          };
          console.log("editorData", editorData);
          setEditorData(editorData);
          console.log("Parsed Editor Data", editorData);
          setTitle(blog.data.title);
          setDescription(blog.data.description);
          console.log(blog.data.blogBlocks);
          console.log("Blog Fetched Successfully", blog);
        } catch (error) {
          console.log("Error fetching blog:", error);
        }
      }
      LoadBlog();
    }
    setInit(true);
  }, [action, postId]);

  const handleSavePost = async () => {
    try {
      await CreateBlog(editorInstance.current, title, description);
      Swal.fire("Saved!", "Your post has been saved.", "success");
      navigate("/blogs");
    } catch (error) {
      console.error("Error Saving The Post", error);
    }
  };
  const handleUpatingBlog = async () => {
    try {
      await EditPost(editorInstance.current, title, description, blog);
      Swal.fire("Updated!", "Your blog has been updated.", "success");
      navigate("/blogs");
    } catch (error) {
      console.error("Error Saving The Post", error);
    }
  };

  return (
    <Layout>
      <div className="p-8 w-full lg:w-[90%] mx-auto max-w-4xl">
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter post title"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter blog Description"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div>
        <div
          id="editorjs"
          className="mt-5 border-2 border-gray-300 rounded-lg p-4 min-h-[550px] bg-gray-50"
        ></div>
        <div className="mt-5 flex gap-4">
          {action === "create" && (
            <button
              onClick={handleSavePost}
              className="w-full md:w-fit bg-black text-white py-2 px-6 rounded-lg "
            >
              Create Post
            </button>
          )}

          {action === "edit" && (
            <button
              onClick={handleUpatingBlog}
              className="w-full md:w-fit bg-black text-white py-2 px-6 rounded-lg t"
            >
              Update Post
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PostEditor;

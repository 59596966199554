import React, { useState } from 'react';
import './styles/FccDataDetails.css';

const FccDataDetails = ({ fccData }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());

  const toggleRow = (index) => {
    const newExpanded = new Set(expandedRows);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpandedRows(newExpanded);
  };

  if (!fccData || fccData.length === 0) {
    return <div className="no-data">No FCC data available.</div>;
  }

  return (
    <div className="fcc-data-container">
      <h2 className="header">FCC Data Details</h2>
      <div className="records-container">
        {fccData.map((item, index) => (
          <div 
            key={index}
            className={`record-card ${expandedRows.has(index) ? 'expanded' : ''}`}
          >
            <div 
              className="record-header"
              onClick={() => toggleRow(index)}
              role="button"
              tabIndex="0"
              aria-expanded={expandedRows.has(index)}
              onKeyPress={(e) => e.key === 'Enter' && toggleRow(index)}
            >
              <div className="header-content">
                <span className="geo-name">{item.geoName.replace(/"/g, "")}</span>
                <div className="header-details">
                  <span>{item.holdingCompany}</span>
                  <span>{item.technologyDesc}</span>
                  <span>{item.totalUnits} Units</span>
                </div>
              </div>
              <span className="toggle-icon">
                {expandedRows.has(index) ? '▼' : '▶'}
              </span>
            </div>
            
            {expandedRows.has(index) && (
              <div className="record-details">
                <div className="detail-group">
                  <h4>Speed Distribution</h4>
                  <div className="speed-grid">
                    <div className="speed-item">
                      <label>0-2 Mbps:</label>
                      <span>{(item.speed_02_02 * 100).toFixed(2)}%</span>
                    </div>
                    <div className="speed-item">
                      <label>1-10 Mbps:</label>
                      <span>{(item.speed_10_1 * 100).toFixed(2)}%</span>
                    </div>
                    <div className="speed-item">
                      <label>3-25 Mbps:</label>
                      <span>{(item.speed_25_3 * 100).toFixed(2)}%</span>
                    </div>
                    <div className="speed-item">
                      <label>20-100 Mbps:</label>
                      <span>{(item.speed_100_20 * 100).toFixed(2)}%</span>
                    </div>
                    <div className="speed-item">
                      <label>25-250 Mbps:</label>
                      <span>{(item.speed_250_25 * 100).toFixed(2)}%</span>
                    </div>
                    <div className="speed-item">
                      <label>100-1000 Mbps:</label>
                      <span>{(item.speed_1000_100 * 100).toFixed(2)}%</span>
                    </div>
                  </div>
                </div>

                <div className="detail-group">
                  <div className="percentage-distribution">
                    <div className="percentage-item">
                      <label>Residential:</label>
                      <span className="percentage-value">
                        {(item.residentialServicePct * 100).toFixed(2)}%
                      </span>
                    </div>
                    <div className="percentage-item">
                      <label>Business:</label>
                      <span className="percentage-value">
                        {(item.businessServicePct * 100).toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FccDataDetails;
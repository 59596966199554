import { useContext, useEffect, useState } from "react";
import StringIntReq from "../../../../Requests/StringIntReq";
import FiberCompanyApi from "../../../../API/FiberCompanyApi";
import Swal from "sweetalert2";
import CustomerApi from "../../../../API/CustomerApi";
import EmailTemplate from "./EmailTemplate";
import SMSTemplate from "./SMSTemplate";
import CancelForm from "./CancelForm";
import IssueTicket from "./IssueTicket";
import ScheduleInstall from "./ScheduleInstall";
import SendOffer from "./SendOffer";
import ReserveFreeInstall from "./ReserveFreeInstall";
import ScheduleFollowUp from "./ScheduleFollowUp";
import { UserContext } from "../../../../App";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import SendCandidateInvitationReq from "../../../../Requests/FiberHouse/SendCandidateInvitationReq";
import { Modal } from "@mui/material";
import { IoMdClose } from "react-icons/io";

export default function TemplateSelector({
  selectedContacts,
  selectedAction,
  setSelectedAction,
  mode,
  leadType,
}) {
  const [messageType, setMessageType] = useState("sms");
  const [enableEditing, setEnableEditing] = useState(true);
  const [sendToSelected, setSendToSelected] = useState(true);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectOpenForInApp, setIsSelectOpenForInApp] = useState(false);
  const [selectAllMedia, setSelectAllMedia] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [inAppTemplates, setInAppTemplates] = useState([]);
  const [selectedInAppTemplate, setSelectedInAppTemplate] = useState(null);
  const [files, setFiles] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [textBody, setTextBody] = useState("");
  const [type, setType] = useState("");
  const [selectedInterviewer, setSelectedInterviewer] = useState(null);
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateSubject, setNewTemplateSubject] = useState("");
  const [newTemplateBody, setNewTemplateBody] = useState("");
  const [newTemplateTextBody, setNewTemplateTextBody] = useState("");

  const user = useContext(UserContext);

  useEffect(() => {
    fetchCommunicationTemplates();
    fetchRecruitTemplates();
    setSelectedTemplate(null);
    setSelectedInAppTemplate(null);
    setFiles([]);
    setSubject("");
    setBody("");
    setTextBody("");
    if (mode === "Recruit") {
      switch (selectedAction.id) {
        case 1:
          setType("Job Ad");
          break;
        case 2:
          setType("Schedule Interview");
          break;
        case 3:
          setType("Consultation");
          break;
        case 4:
          setType("Proposal");
          break;
        default:
          break;
      }
    }
  }, [selectedAction]);

  const fetchCommunicationTemplates = async () => {
    try {
      const req = new StringIntReq();
      req.pagingParams.pageNumber = 1;
      req.pagingParams.pageSize = 1000;

      if (mode === "Recruit") {
        switch (selectedAction.id) {
          case 1:
            req.category = "Job Ad";
            break;
          case 2:
            req.category = "Schedule Interview";
            break;
          case 3:
            req.category = "Consultation";
            break;
          case 4:
            req.category = "Proposal";
            break;
          default:
            break;
        }
      }

      if (mode === "Sales") {
        switch (selectedAction.id) {
          case 1:
            req.category = "ReviewRequest";
            break;
          default:
            break;
        }
      }

      const response = await FiberCompanyApi.GetCommunicationTemplates(req);
      if (response.status.success) {
        setTemplates(response.data.list.filter((t) => t.isVisible));

      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.status.message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };

  const fetchRecruitTemplates = async () => {
    try {
      let response = await FiberCompanyApi.GetRecruitTemplates();
      if (response.status.success) {
        let filteredTemplates = response.data.filter(
          (template) => template.isVisible === true
        );

        if (selectedAction.label === "Send AD") {
          filteredTemplates = filteredTemplates.filter(
            (template) => template.candidateType === "Recruit"
          );
        } else if (selectedAction.label === "Schedule Consultation") {
          filteredTemplates = filteredTemplates.filter(
            (template) => template.candidateType === "Company"
          );
        }

        setInAppTemplates(filteredTemplates);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFiles([
          ...files,
          {
            id: files.length + 1,
            name: file.name,
            url: e.target.result,
            type: file.type,
            selected: false,
          },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFiles([
          ...files,
          {
            id: files.length + 1,
            name: file.name,
            url: e.target.result,
            type: file.type,
            selected: false,
          },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleSelectAll = () => {
    setSelectAllMedia(!selectAllMedia);
    setFiles(files.map((file) => ({ ...file, selected: !selectAllMedia })));
  };

  const isImage = (fileType) => {
    return fileType?.startsWith("image/");
  };

  const sendAd = async () => {
    const selectedFiles = files.filter((file) => file.selected);
    let selectedTemplateMedia = "";
    let selectedTemplateMediaName = "";
    if (selectedFiles.length > 0) {
      selectedTemplateMedia = selectedFiles.map((file) => file.url);
      selectedTemplateMediaName = selectedFiles.map((file) => file.name);
    }

    const selectedTemplateSubject = selectedTemplate.subject;
    const selectedTemplateBody = selectedTemplate.body;

    const result = await CustomerApi.SendEmailAd({
      subject: selectedTemplateSubject,
      body: selectedTemplateBody,
      attachment: {
        base64string: selectedTemplateMedia[0]?.split(",")[1],
        fileName: selectedTemplateMediaName[0],
      },
      leadIds: selectedContacts.map((contact) => contact.id),
    });
    if (result.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Email sent successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: result.status.message,
      });
    }
  };

  const SendCandidateInvitation = async () => {
    if (selectedContacts.length > 0) {
      // Swal.fire({
      //   icon: "info",
      //   title: "Sending Candidate Invitations",
      //   text: "Please wait...",
      //   showConfirmButton: false,
      //   showCancelButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   allowEnterKey: false,
      //   willOpen: () => {
      //     Swal.showLoading();
      //   },
      // });

      if (
        selectedAction.label === "Send AD" ||
        selectedAction.label === "Schedule Consultation"
      ) {
        if (!selectedInAppTemplate) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please select an InApp template",
          });
          return;
        }
      }

      if (selectedAction.label === "Schedule Interview") {
        if (!selectedInterviewer) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please select an interviewer",
          });
          return;
        }
      }

      Swal.fire({
        icon: "info",
        title: "Sending Candidate Invitations",
        text: "Please wait...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const selectedFiles = files.filter((file) => file.selected);
      let selectedTemplateMedia = "";
      let selectedTemplateMediaName = "";
      if (selectedFiles.length > 0) {
        selectedTemplateMedia = selectedFiles.map((file) => file.url);
        selectedTemplateMediaName = selectedFiles.map((file) => file.name);
      }

      let link = null;
      if (selectedAction.label === "Schedule Interview") {
        let interviewRound = 1;
        link = ``;
        link = `${selectedInterviewer.value}?a2={{candidateId}}&a3=${interviewRound}&a4=Recruit`;
      }
      const selectedTemplateSubject = selectedTemplate.subject;
      const selectedTemplateBody = selectedTemplate.body;
      const req = {
        candidateIds: selectedContacts.map((contact) => contact.id),
        subject: selectedTemplateSubject,
        content: selectedTemplateBody,
        userId: user.id,
        templateId: selectedInAppTemplate?.id,
        templateType: selectedTemplate.category,
        scheduleLink: link,
        attachment: {
          base64string: selectedTemplateMedia[0]?.split(",")[1],
          fileName: selectedTemplateMediaName[0],
        },
      };

      if (mode === "Recruit") {
        switch (selectedAction.id) {
          case 1:
            req.templateType = "Job Ad";
            break;
          case 2:
            req.templateType = "Schedule Interview";
            break;
          case 3:
            req.templateType = "Consultation";
            break;
          case 4:
            req.templateType = "Proposal";
            break;
          default:
            break;
        }
      }

      const result = await FiberHouseApi.BulkSendCandidateInvitations(req);
      if (result.status.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Candidate Invitations sent successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: result.status.message,
        });
      }
    }
  };

  const sendReviewRequest = async () => {
    Swal.fire({
      icon: "info",
      title: "Sending Review Request",
      text: "Please wait...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const selectedFiles = files.filter((file) => file.selected);
    let selectedTemplateMedia = "";
    let selectedTemplateMediaName = "";
    if (selectedFiles.length > 0) {
      selectedTemplateMedia = selectedFiles.map((file) => file.url);
      selectedTemplateMediaName = selectedFiles.map((file) => file.name);
    }

    const selectedTemplateSubject = selectedTemplate.subject;
    const selectedTemplateBody = selectedTemplate.body;
    const smsBody = selectedTemplate.textBody;
    let sent = 0;
    let failed = 0;
    for (let i = 0; i < selectedContacts.length; i++) {
      console.log(selectedContacts[i]);    
      const req = {
        leadId: selectedContacts[i].id,
        subject: selectedTemplateSubject,
        content: selectedTemplateBody,
        body: smsBody,
        userId: user.id,
        mode: messageType,
        attachment: {
          base64string: selectedTemplateMedia[0]?.split(",")[1],
          fileName: selectedTemplateMediaName[0],
        },
      };

      const result = await CustomerApi.SendReviewRequestToCustomer(req);
      if (result.status.success) {
        sent++;
      } else {
        failed++;
      }

      Swal.update({
        title: "Sending Review Request",
        text: `Sending Review Request to ${selectedContacts.length} contacts. <br> Sent: ${sent} <br> Failed: ${failed}`,
        icon: "info",
      });
    }

    Swal.fire({
      icon: "success",
      title: "Success",
      text: `Review Request sent to ${sent} contacts successfully. Failed to send to ${failed} contacts.`,
      didOpen: () => {
        Swal.hideLoading();
      },
    });
  };

  useEffect(() => {
    if (selectedContacts.length > 1) {
      const singleOptionActions = [3, 4, 5, 6, 7, 8];

      if (singleOptionActions.includes(selectedAction.id)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select only one contact to proceed",
        });
        setSelectedAction({ id: 0, label: "" });
      }
    }
  }, [selectedContacts]);

  const createCommTemplate = async () => {
    if (
      !selectedTemplate?.subject ||
      !selectedTemplate?.body ||
      !selectedTemplate?.textBody
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the fields",
      });
      return;
    }
    const result = await FiberCompanyApi.CreateCommunicationTemplate({
      name: newTemplateName,
      category: type,
      subject: newTemplateSubject,
      body: newTemplateBody,
      textBody: newTemplateTextBody,
      type: mode === "Recruit" ? leadType : null,
    });
    if (result.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Template created successfully",
      });
      fetchCommunicationTemplates();
      setIsCreateTemplateOpen(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: result.status.message,
      });
    }
  };

  const sendBulkInvitationSMS = async (showModal = true) => {
    if (!selectedTemplate) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a template",
      });
      return;
    }

    if (
      selectedAction.label === "Send AD" ||
      selectedAction.label === "Schedule Consultation"
    ) {
      if (!selectedInAppTemplate) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select an InApp template",
        });
        return;
      }
    }

    if (selectedAction.label === "Schedule Interview") {
      if (!selectedInterviewer) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select an interviewer",
        });
        return;
      }
    }

    const option = showModal
      ? await Swal.fire({
          title: "Send SMS",
          text: "Are you sure you want to send these invitations?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#f44336",
        })
      : { isConfirmed: true };

    if (!option.isConfirmed) return;

    Swal.fire({
      title: "Sending SMS",
      text: "Please wait...",
      icon: "info",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const smsBody = selectedTemplate.textBody;
    let req = new SendCandidateInvitationReq();
    req.candidateIds = selectedContacts.map((contact) => contact.id);
    req.templateId = selectedInAppTemplate?.id;
    req.userId = user.id;
    req.content = smsBody;
    req.subject = "";

    let link = null;
    if (selectedAction.label === "Schedule Interview") {
      let interviewRound = 1;
      link = ``;
      link = `${selectedInterviewer.value}?a2={{candidateId}}&a3=${interviewRound}&a4=Recruit`;
    }

    req.scheduleLink = link;

    if (mode === "Recruit") {
      switch (selectedAction.id) {
        case 1:
          req.templateType = "Job Ad";
          break;
        case 2:
          req.templateType = "Schedule Interview";
          break;
        case 3:
          req.templateType = "Consultation";
          break;
        case 4:
          req.templateType = "Proposal";
          break;
        default:
          break;
      }
    }

    let res = await FiberHouseApi.BulkSendInvitationWithSMS(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const users = [
    {
      value: "https://calendly.com/jessica-ylwk/interview",
      label: "Jessica Man (jessica@lightningos.world)",
      type: "Recruit",
    },
    // {
    //   value: "https://calendly.com/shasta-lightningos/interview",
    //   label: "Shasta Weishampel (shasta@lightningos.world)",
    //   type: "Company",
    // },
  ];

  console.log({ modeHereeeee: mode });

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      {mode !== "Sales" ? (
        <>
          {selectedAction.id >= 1 && selectedAction.id <= 4 && (
            <>
              <div className="flex flex-col space-y-6 sm:flex-row sm:justify-end sm:items-center">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <div className="flex items-center gap-4">
                    <span className="text-sm text-gray-500">Choose Type</span>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="email"
                          checked={messageType === "email"}
                          onChange={() => setMessageType("email")}
                          className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                        />
                        <label htmlFor="email" className="text-sm font-medium">
                          Email
                        </label>
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="sms"
                          checked={messageType === "sms"}
                          onChange={() => setMessageType("sms")}
                          className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                        />
                        <label htmlFor="sms" className="text-sm font-medium">
                          SMS
                        </label>
                      </div>
                    </div>
                  </div>
                  {mode === "Recruit" && (
                    <button
                      onClick={() => createCommTemplate()}
                      className="px-4 py-2 bg-[#4169E1] text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none"
                    >
                      Create A New Template
                    </button>
                  )}
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm text-gray-500">Select Template</label>
                <div className="relative w-full sm:w-[300px]">
                  <button
                    onClick={() => setIsSelectOpen(!isSelectOpen)}
                    className="w-full px-4 py-2 text-left border rounded-lg bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    {selectedTemplate
                      ? selectedTemplate.name
                      : "Select Template"}
                  </button>
                  {isSelectOpen && (
                    <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                      {templates.map((template) => (
                        <button
                          key={template.id}
                          onClick={() => {
                            setSelectedTemplate(template);
                            setIsSelectOpen(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-gray-50"
                        >
                          {template.name ?? "No Template"}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {mode === "Recruit" &&
                (selectedAction.label === "Send AD" ||
                  selectedAction.label === "Schedule Consultation") && (
                  <div className="space-y-2">
                    <label className="text-sm text-gray-500">
                      Select InApp Job Ad Temlate
                    </label>
                    <div className="relative w-full sm:w-[300px]">
                      <button
                        onClick={() =>
                          setIsSelectOpenForInApp(!isSelectOpenForInApp)
                        }
                        className="w-full px-4 py-2 text-left border rounded-lg bg-white hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedInAppTemplate
                          ? selectedInAppTemplate.templateName
                          : "Select InApp Ad Template"}
                      </button>
                      {isSelectOpenForInApp && (
                        <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                          {inAppTemplates.map((template) => (
                            <button
                              key={template.id}
                              onClick={() => {
                                setSelectedInAppTemplate(template);
                                setIsSelectOpenForInApp(false);
                              }}
                              className="w-full px-4 py-2 text-left hover:bg-gray-50"
                            >
                              {template.templateName ?? "No Template"}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {mode === "Recruit" &&
                selectedAction.label === "Schedule Interview" && (
                  <div className="space-y-2">
                    <label className="text-sm text-gray-500">
                      Select Interviewer
                    </label>
                    <div className="relative w-full sm:w-[300px]">
                      <button
                        onClick={() =>
                          setIsSelectOpenForInApp(!isSelectOpenForInApp)
                        }
                        className="w-full px-4 py-2 text-left border rounded-lg bg-white hover:bg-gray-50 focus:outline-none"
                      >
                        {selectedInterviewer
                          ? selectedInterviewer.label
                          : "Select Interviewer"}
                      </button>
                      {isSelectOpenForInApp && (
                        <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                          {users.map((template) => (
                            <button
                              key={template.id}
                              onClick={() => {
                                setSelectedInterviewer(template);
                                setIsSelectOpenForInApp(false);
                              }}
                              className="w-full px-4 py-2 text-left hover:bg-gray-50"
                            >
                              {template.label ?? "No Template"}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

              <div className="space-y-6">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="editing"
                    checked={enableEditing}
                    onChange={(e) => setEnableEditing(e.target.checked)}
                    className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                  />
                  <label htmlFor="editing" className="text-sm font-medium">
                    Enable Editing
                  </label>
                </div>

                {messageType === "email" ? (
                  <EmailTemplate
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    enableEditing={enableEditing}
                    files={files}
                    handleFileUpload={handleFileUpload}
                    handleDrop={handleDrop}
                    toggleSelectAll={toggleSelectAll}
                    selectAllMedia={selectAllMedia}
                    setFiles={setFiles}
                    isImage={isImage}
                    body={body}
                    setBody={setBody}
                    subject={subject}
                    setSubject={setSubject}
                  />
                ) : (
                  <SMSTemplate
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    enableEditing={enableEditing}
                    textBody={textBody}
                    setTextBody={setTextBody}
                  />
                )}
              </div>

              <div className="space-y-6 pt-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="sendAction"
                    checked={sendToSelected}
                    onChange={(e) => setSendToSelected(e.target.checked)}
                    className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                  />
                  <label htmlFor="sendAction" className="text-sm font-medium">
                    I want to send action to {selectedContacts.length} selected
                    contacts
                  </label>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      if (selectedAction.id === 1 && mode === "Sales") {
                        sendReviewRequest();
                      } else {
                        if (mode === "Sales") {
                          sendAd();
                        } else {
                          if (messageType === "email") {
                            SendCandidateInvitation();
                          } else {
                            sendBulkInvitationSMS();
                          }
                        }
                      }
                    }}
                    className="w-[200px] px-4 py-2 bg-[#4169E1] text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none"
                  >
                    {selectedAction.label}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {selectedAction.id === 1 && (
            <>
              <div className="flex flex-col space-y-6 sm:flex-row sm:justify-end sm:items-center">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <div className="flex items-center gap-4">
                    <span className="text-sm text-gray-500">Choose Type</span>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="email"
                          checked={messageType === "email"}
                          onChange={() => setMessageType("email")}
                          className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                        />
                        <label htmlFor="email" className="text-sm font-medium">
                          Email
                        </label>
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="sms"
                          checked={messageType === "sms"}
                          onChange={() => setMessageType("sms")}
                          className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                        />
                        <label htmlFor="sms" className="text-sm font-medium">
                          SMS
                        </label>
                      </div>
                    </div>
                  </div>
                  {mode === "Recruit" && (
                    <button className="px-4 py-2 bg-[#4169E1] text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none">
                      Create A New Template
                    </button>
                  )}
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm text-gray-500">Select Template</label>
                <div className="relative w-full sm:w-[300px]">
                  <button
                    onClick={() => setIsSelectOpen(!isSelectOpen)}
                    className="w-full px-4 py-2 text-left border rounded-lg bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    {selectedTemplate
                      ? selectedTemplate.name
                      : "Select Template"}
                  </button>
                  {isSelectOpen && (
                    <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                      {templates.map((template) => (
                        <button
                          key={template.id}
                          onClick={() => {
                            setSelectedTemplate(template);
                            setIsSelectOpen(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-gray-50"
                        >
                          {template.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="editing"
                    checked={enableEditing}
                    onChange={(e) => setEnableEditing(e.target.checked)}
                    className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                  />
                  <label htmlFor="editing" className="text-sm font-medium">
                    Enable Editing
                  </label>
                </div>

                {messageType === "email" ? (
                  <EmailTemplate
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    enableEditing={enableEditing}
                    files={files}
                    handleFileUpload={handleFileUpload}
                    handleDrop={handleDrop}
                    toggleSelectAll={toggleSelectAll}
                    selectAllMedia={selectAllMedia}
                    setFiles={setFiles}
                    isImage={isImage}
                    body={body}
                    setBody={setBody}
                    subject={subject}
                    setSubject={setSubject}
                  />
                ) : (
                  <SMSTemplate
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    enableEditing={enableEditing}
                    textBody={textBody}
                    setTextBody={setTextBody}
                  />
                )}
              </div>

              <div className="space-y-6 pt-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="sendAction"
                    checked={sendToSelected}
                    onChange={(e) => setSendToSelected(e.target.checked)}
                    className="w-4 h-4 rounded border-gray-300 focus:outline-none checked:bg-blue-500"
                  />
                  <label htmlFor="sendAction" className="text-sm font-medium">
                    I want to send action to {selectedContacts.length} selected
                    contacts
                  </label>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      if (selectedAction.id === 1) {
                        sendReviewRequest();
                      } else {
                        sendAd();
                      }
                    }}
                    className="w-[200px] px-4 py-2 bg-[#4169E1] text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none"
                  >
                    {selectedAction.label}
                  </button>
                </div>
              </div>
            </>
          )}
          {selectedAction.id === 3 && (
            <CancelForm selectedContacts={selectedContacts} />
          )}
          {selectedAction.id === 4 && (
            <ScheduleInstall
              lead={selectedContacts[0]}
              fiberCompany={selectedContacts[0]?.fidiumCustomer?.fiberCompany}
            />
          )}
          {selectedAction.id === 5 && (
            <IssueTicket lead={selectedContacts[0]} />
          )}
          {selectedAction.id === 6 && <SendOffer lead={selectedContacts[0]} />}
          {selectedAction.id === 7 && (
            <ReserveFreeInstall lead={selectedContacts[0]} />
          )}
          {selectedAction.id === 8 && (
            <ScheduleFollowUp lead={selectedContacts[0]} />
          )}
        </>
      )}
      <Modal
        open={isCreateTemplateOpen}
        onClose={() => {
          setIsCreateTemplateOpen(false);
          setNewTemplateName("");
          setNewTemplateSubject("");
          setNewTemplateBody("");
          setNewTemplateTextBody("");
        }}
      >
        <div className="custom_modal" style={{ maxHeight: "fit-content" }}>
          <div className="flex flex-col gap-2 w-full">
            <div className="relative flex justify-between items-center">
              <h1>Create New Template</h1>
              <IoMdClose
                className="absolute top-0 right-0 w-6 h-6 hover:cursor-pointer hover:text-red-500"
                onClick={() => {
                  setIsCreateTemplateOpen(false);
                  setNewTemplateName("");
                  setNewTemplateSubject("");
                  setNewTemplateBody("");
                  setNewTemplateTextBody("");
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="templateName" className="text-sm font-medium">
                Template Name
              </label>
              <input
                id="templateName"
                type="text"
                placeholder="Template Name"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                className="border-2 border-[#022780] rounded-lg p-1"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="templateSubject" className="text-sm font-medium">
                Subject
              </label>
              <input
                id="templateSubject"
                type="text"
                placeholder="Subject"
                value={newTemplateSubject}
                onChange={(e) => setNewTemplateSubject(e.target.value)}
                className="rounded-lg p-1"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="templateBody" className="text-sm font-medium">
                Email Body
              </label>
              <textarea
                id="templateBody"
                placeholder="Email Body"
                value={newTemplateBody}
                onChange={(e) => setNewTemplateBody(e.target.value)}
                className="rounded-lg p-2 h-24"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="templateTextBody" className="text-sm font-medium">
                SMS Body
              </label>
              <textarea
                id="templateTextBody"
                placeholder="SMS Body"
                value={newTemplateTextBody}
                onChange={(e) => setNewTemplateTextBody(e.target.value)}
                className="rounded-lg p-2 h-24"
              />
            </div>
            <button
              onClick={createCommTemplate}
              className="bg-[#022780] text-white px-4 py-2 rounded-lg"
            >
              Create Template
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

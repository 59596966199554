import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FiberHouseApi from '../../../../../API/FiberHouseApi';

const PositionTitleFilter = ({ candidatePositionTitles, setCandidatePositionTitles }) => {
  const [positionTitleOptions, setPositionTitleOptions] = useState([]);

  const fetchPositionTitleOptions = async () => {
    const res = await FiberHouseApi.GetCandidatePositionTitles();
    if (res.status.success) {
      const sortedData = res.data.sort((a, b) => a.label.localeCompare(b.label));
      setPositionTitleOptions(sortedData);
    }
  };

  useEffect(() => {
    fetchPositionTitleOptions();
  }, []);

  return (
    <div className="InputDiv">
      <label htmlFor="assignedConvertedToCustomer">Position Titles</label>
      <Select
        isMulti
        value={candidatePositionTitles}
        onChange={setCandidatePositionTitles}
        options={positionTitleOptions}
        placeholder="Select Position Titles"
      />
    </div>
  );
};

export default PositionTitleFilter;

import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Divider,
  Pagination,
  CircularProgress,
  Box,
  Grid,
} from "@mui/material";
import {
  Email,
  Message,
  Phone,
  LocationOn,
  WhatsApp,
  ContentCopy,
  Map,
  Business,
  Person,
  Send,
  MailOutline,
  GetApp,
  DoorFront,
} from "@mui/icons-material";
import "./CandidateStats.scss";
import { MapContext as MP } from "..";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import GetCandidatesByIdsReq from "../../../../Requests/FiberHouse/GetCandidatesByIdsReq";
import { filter } from "jszip";
import { BiBriefcase } from "react-icons/bi";
import { FcOrganization } from "react-icons/fc";
import InvitationModal from "./CandidateMainLeadCard/InviteForm";
import Swal from "sweetalert2";
import { useSwipeable } from "react-swipeable";
import ContactButtons from "../../../../Services/ContactButtons";
import { UserContext } from "../../../../App";
import { MdOutlineDirections } from "react-icons/md";
import { IoMapOutline } from "react-icons/io5";
import { fromLonLat } from "ol/proj";
import ExportData from "../../../../Services/ExportData";
import MapIconSelector from "../../../../components/MapIconSelector";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import bot from "./../Images/bot.png";
import TagsState from "../../../SuperAdmin/Tags/components/TagsState";
import SelectionMenu from "../../../SuperAdmin/Tags/components/SelectionMenu";
import {
  assoicateTag,
  disAssoicateTag,
} from "../../../SuperAdmin/Tags/api/tagsAPI";
const CandidateStats = ({ candidates }) => {
  const map = useContext(MP);
  const [candidatesState, setCandidatesState] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterMapIconIds, setFilterMapIconIds] = useState([]);
  const [filterLeadTypes, setFilterLeadTypes] = useState([
    "Recruit",
    "Company",
  ]);
  const [filterInviteStatus, setFilterInviteStatus] = useState([
    "sent",
    "unsent",
  ]);
  const [filterContactInfoStatus, setFilterContactInfoStatus] = useState([
    "emailOnly",
    "phoneOnly",
    "both",
    "none",
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [candidateType, setCandidateType] = useState("Recruit");
  const [fiberCompany, setFiberCompany] = useState(null);
  const itemsPerPage = 10;
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const user = useContext(UserContext);
  const contactButtons = new ContactButtons(user);
  const stopRef = React.useRef(null);
  const [modeSelected, setModeSelected] = useState("email");
  const [action, setAction] = useState("Send Ad");
  const [customMessage, setCustomMessage] = useState(false);
  const [selectedMapIcon, setSelectedMapIcon] = useState(null);

  const { tags, setTags } = map;

  const refPassthrough = (el) => {
    handlers.ref(el);
    stopRef.current = el;
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwiping: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedDown: (eventData) => {
      eventData.event.stopPropagation();
    },
  });

  useEffect(() => {
    fetchData();
  }, [map.candidateIds]);

  // Fetch the candidate data when component mounts
  const fetchData = async () => {
    setLoading(true);
    try {
      let req = new GetCandidatesByIdsReq();
      req.candidateIds = map.candidateIds;
      console.log(req);
      const candidateResponse = await FiberHouseApi.GetCandidatesByIds(req);

      if (candidateResponse.status.success) {
        const candidates = candidateResponse.data;
        setCandidatesState(candidates);
        setFilteredCandidates(candidates);

        setFilterMapIconIds(map.mapIcons.map((icon) => icon.id));
        setLoading(false);
      } else {
        console.error(
          "Error fetching candidate data:",
          candidateResponse.status.message
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log({ filteredCandidates });

  const handleAssociate = async (associateSelectedTags) => {
    const data = {
      tagIds: associateSelectedTags.map((item) => item.id),
      leadIds: null,
      candidateIds: filteredCandidates.map((l) => l.id),
    };

    const res = await assoicateTag(data);

    setTags((prevTags) =>
      prevTags.map((t) => ({
        ...t,
        tagEntities: [
          ...t.tagEntities,
          ...res.data.filter((tEntity) => tEntity.leadTagId === t.id),
        ],
      }))
    );

    Swal.fire({
      title: "Success!",
      text: "Tags have been associated successfully.",
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const handleDisassociate = async (selectedTagIds, selectedCandidateIds) => {
    const tagEntities = tags
      .filter((t) => selectedTagIds.includes(t.id))
      .reduce(
        (prev, currT) => [
          ...prev,
          ...currT.tagEntities.filter((tEntity) =>
            selectedCandidateIds.includes(tEntity.userId)
          ),
        ],
        []
      );

    const res = await Promise.all(
      tagEntities.map(({ id }) => disAssoicateTag({ id }))
    );

    const numberOfSuccess = res.reduce(
      (prev, currRes) => (currRes.status.success ? prev + 1 : prev),
      0
    );

    const checkThisOut = tagEntities
      .filter((_, i) => res[i].status.success)
      .reduce(
        (prev, currTEntity) => ({
          ...prev,
          [currTEntity.leadTagId]: [
            ...(prev[currTEntity.leadTagId] ?? []),
            currTEntity.id,
          ],
        }),
        {}
      );

    setTags((prevTags) =>
      prevTags.map((t) => {
        console.log(
          t.id,
          Object.keys(checkThisOut),
          Object.keys(checkThisOut).includes(t.id.toString())
        );
        return Object.keys(checkThisOut).includes(t.id.toString())
          ? {
              ...t,
              tagEntities: t.tagEntities.filter(
                (tEntity) => !checkThisOut[t.id].includes(tEntity.id)
              ),
            }
          : t;
      })
    );

    if (numberOfSuccess === tagEntities.length) {
      Swal.fire({
        title: "Success!",
        text: `${numberOfSuccess} Tag Entit${
          numberOfSuccess > 1 ? "ies" : "y"
        } disassociated successfully.`,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Problem!",
        text: `${tagEntities.length - numberOfSuccess} Tag Entit${
          tagEntities.length - numberOfSuccess > 1 ? "ies" : "y"
        } faced problems.`,
        icon: "warning",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    filterCandidates();
  }, [
    filterMapIconIds,
    filterLeadTypes,
    filterInviteStatus,
    searchTerm,
    candidatesState,
    filterContactInfoStatus,
  ]);

  const filterCandidates = () => {
    let filtered = candidatesState.filter((candidate) => {
      // Map Icon Filtering
      console.log(candidate);
      const mapIconMatch =
        filterMapIconIds.length === 0 ||
        filterMapIconIds.includes(candidate.mapIconId);

      // Lead Type Filtering
      const leadTypeMatch =
        filterLeadTypes.length === 0 ||
        filterLeadTypes.includes(candidate.leadType);

      let inviteStatusMatch = true; // Default to true if no filter is applied
      if (
        filterInviteStatus.includes("sent") &&
        filterInviteStatus.includes("unsent")
      ) {
        inviteStatusMatch = true;
      } else if (filterInviteStatus.includes("sent")) {
        inviteStatusMatch = candidate.candidateInvitation !== null;
      } else if (filterInviteStatus.includes("unsent")) {
        inviteStatusMatch = candidate.candidateInvitation === null;
      }

      let contactInfoMatch = true;
      if (filterContactInfoStatus.length > 0) {
        const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
        const hasPhone = !!candidate.phone;

        if (filterContactInfoStatus.includes("none")) {
          contactInfoMatch = !hasEmail && !hasPhone;
        }
        if (filterContactInfoStatus.includes("emailOnly")) {
          contactInfoMatch = hasEmail && !hasPhone;
        }
        if (filterContactInfoStatus.includes("phoneOnly")) {
          contactInfoMatch = hasPhone && !hasEmail;
        }
        if (filterContactInfoStatus.includes("both")) {
          contactInfoMatch = hasEmail && hasPhone;
        }
      }

      const searchTermMatch =
        !searchTerm ||
        (candidate?.name &&
          candidate.name.toLowerCase().includes(searchTerm.toLowerCase()));

      return (
        mapIconMatch &&
        leadTypeMatch &&
        inviteStatusMatch &&
        searchTermMatch &&
        contactInfoMatch
      );
    });
    console.log(filtered);
    setFilteredCandidates(filtered);
    // if (filtered.length > 0) {
    //     map?.setCandidateIds(filtered.map(candidate => candidate.id));
    // } else {
    //     //map?.setCandidateIds([]); // Clear candidate IDs if no candidates match the filters
    // }
  };

  const toggleMapIcon = (iconId) => {
    setFilterMapIconIds((prev) =>
      prev.includes(iconId)
        ? prev.filter((id) => id !== iconId)
        : [...prev, iconId]
    );
  };

  const handleSelectAllMapIcons = () => {
    const allMapIcons = [
      ...new Set(
        candidatesState
          .map((candidate) => candidate.mapIconId)
          .filter((id) => id !== null)
      ),
    ];
    setFilterMapIconIds(allMapIcons);
  };

  const handleDeselectAllMapIcons = () => {
    setFilterMapIconIds([]);
  };

  const toggleLeadType = (type) => {
    setFilterLeadTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const toggleContactInfoStatus = (status) => {
    setFilterContactInfoStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const toggleInviteStatus = (status) => {
    setFilterInviteStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const exportDataInstance = new ExportData();

  const handleExportLeads = () => {
    exportDataInstance.exportCandidateStatsLeads(filteredCandidates);
  };
  const hasValidEmailCandidates = filteredCandidates.some(
    (candidate) => candidate.personalEmail || candidate.companyEmail
  );
  const hasValidPhoneCandidates = filteredCandidates.some(
    (candidate) => candidate.phone
  );

  const getMapIconStats = () => {
    const mapIconStats = [];
    const mapIconIdsFromCandidates = [
      ...new Set(
        candidatesState
          .map((candidate) => candidate.mapIconId)
          .filter((id) => id !== null)
      ),
    ];

    mapIconIdsFromCandidates.forEach((iconId) => {
      const assignedMapIcon = map.mapIcons.find((icon) => icon.id === iconId);
      if (assignedMapIcon) {
        const count = filteredCandidates.filter(
          (candidate) => candidate.mapIconId === iconId
        ).length;
        mapIconStats.push({ assignedMapIcons: assignedMapIcon, count });
      }
    });

    return mapIconStats;
  };

  const getRecruitCompanyStats = () => {
    const recruitCompanyStats = { recruit: 0, company: 0 };
    filteredCandidates.forEach((candidate) => {
      console.log(candidate);
      if (candidate.leadType === "Recruit") {
        recruitCompanyStats.recruit += 1;
      } else if (candidate.leadType === "Company") {
        recruitCompanyStats.company += 1;
      }
    });

    return recruitCompanyStats;
  };

  const getInviteStatusStats = () => {
    const inviteStats = { sent: 0, unsent: 0 };
    filteredCandidates.forEach((candidate) => {
      console.log(candidate);
      if (candidate.candidateInvitation !== null) {
        inviteStats.sent += 1;
      } else {
        inviteStats.unsent += 1;
      }
    });

    return inviteStats;
  };

  const getContactInfoStats = () => {
    const stats = {
      emailOnly: 0,
      phoneOnly: 0,
      both: 0,
      none: 0,
    };

    filteredCandidates.forEach((candidate) => {
      const hasEmail = !!candidate.personalEmail || !!candidate.companyEmail;
      const hasPhone = !!candidate.phone;

      if (hasEmail && hasPhone) {
        stats.both += 1;
      } else if (hasEmail) {
        stats.emailOnly += 1;
      } else if (hasPhone) {
        stats.phoneOnly += 1;
      } else {
        stats.none += 1;
      }
    });

    return stats;
  };

  const snapToLocation = (candidate) => {
    map.candidateClicked(candidate);
    let lonlat = [
      candidate.coordinates.longitude,
      candidate.coordinates.latitude,
    ];
    map.mapObject.getView().setCenter(fromLonLat(lonlat));
    map.mapObject.getView().setZoom(21);
  };

  useEffect(() => {
    if (map.searchAreaFeature.length > 0) {
      try {
        // Identify candidate IDs that should be removed
        console.log("Filtered Candidates:", filteredCandidates);
        console.log("Candidates on map:", candidatesState);
        const idsToRemove = candidatesState
          .filter(
            (candidate) =>
              !filteredCandidates.some((fh) => fh.id === candidate.id)
          )
          .map((candidate) => candidate.id);

        console.log(idsToRemove);
        // Update the map with the candidates
        map.setCandidates(
          candidates.map((candidate) => {
            if (idsToRemove.includes(candidate.id)) {
              return {
                ...candidate,
                remove: true,
              };
            } else {
              const fh = filteredCandidates.filter(
                (fh) => fh.id === candidate.id
              )[0];
              return {
                ...candidate,
                remove: false,
                selected: fh ? true : false,
              };
            }
          })
        );
      } catch (error) {
        console.error("Error updating candidates on the map:", error);
      }
    }
  }, [filteredCandidates]);

  const contactInfoStats = getContactInfoStats();

  const recruitCompanyStats = getRecruitCompanyStats();
  const inviteStatusStats = getInviteStatusStats();

  const paginatedCandidates = filteredCandidates.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const InfoRow = ({ label, value }) => (
    <Box sx={{ display: "flex", alignItems: "baseline", mb: 0.5 }}>
      <Typography
        variant="caption"
        sx={{ fontWeight: "bold", mr: 0.5, minWidth: "80px" }}
      >
        {label}:
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </Box>
  );

  //open invitation model
  const openInvitationModelForEmail = () => {
    //if recruits and companies aer both selected return swal error
    if (
      filterLeadTypes.includes("Recruit") &&
      filterLeadTypes.includes("Company")
    ) {
      //show swal

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select only one either Recruits or Companies to send invites",
      });
      return;
    } else {
      if (filterLeadTypes.includes("Recruit")) {
        setCandidateType("Recruit");
      } else {
        setCandidateType("Company");
      }
      setIsInviteOpen("Email");
    }
  };

  const openInvitationModelForSMS = () => {
    //if recruits and companies aer both selected return swal error
    if (
      filterLeadTypes.includes("Recruit") &&
      filterLeadTypes.includes("Company")
    ) {
      //show swal

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select only one either Recruits or Companies to send invites",
      });
      return;
    } else {
      if (filterLeadTypes.includes("Recruit")) {
        setCandidateType("Recruit");
      } else {
        setCandidateType("Company");
      }
      setIsInviteOpen("SMS");
    }
  };

  const allFields = [
    { key: "id", label: "ID" },
    { key: "name", label: "Name" },
    { key: "personalEmail", label: "Personal Email" },
    { key: "phone", label: "Phone" },
    { key: "zipCode", label: "Zip Code" },
    { key: "city", label: "City" },
    { key: "Address", label: "Address" },
    { key: "mapIcon", label: "Map Icon" },
    { key: "notes", label: "Lightning Notes" },
    { key: "appointmentDateTime", label: "Appointment Date" },
    { key: "CompanyName", label: "Company Name" },
    { key: "Industry", label: "Industry" },
    { key: "positionTitle", label: "Position Title" },
    { key: "mapString", label: "Map String" },
    { key: "status", label: "Status" },
    { key: "companyEmail", label: "Company Email" },
    { key: "doorToDoor", label: "Door To Door" },
    { key: "linkedIn", label: "LinkedIn" },
  ];

  const exportData = new ExportData();

  const handleExportClick = async () => {
    const selectedFields = await exportData.promptFieldSelection(allFields);

    if (selectedFields.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No Fields Selected",
        text: "Please select at least one field to export.",
      });
      return;
    }

    let ed = new ExportData();

    let l = filteredCandidates.map((c) => {
      let allData = {
        id: c.id,
        name: c.name,
        personalEmail: c.personalEmail,
        phone: c.phone,
        zipCode: c.zipCode,
        city: c.city,
        address: c.address,
        mapIcon: c.mapIcon?.name,
        notes: c.notes,
        appointmentDateTime: c.appointmentDateTime,
        companyName: c.companyName,
        industry: c.industry,
        positionTitle: c.positionTitle,
        mapString: c.mapString,
        status: c.status,
        companyEmail: c.companyEmail,
        doorToDoor: c.doorToDoor ? "Yes" : "No",
        linkedIn: c.linkedIn,
      };

      // Filter the data to include only the selected fields
      let filteredData = {};
      selectedFields.forEach((field) => {
        if (allData.hasOwnProperty(field)) {
          filteredData[field] = allData[field];
        }
      });

      return filteredData;
    });

    let ids = filteredCandidates.map((fh) => fh.id);
    ed.ExportToCsv("data.csv", l, ids);
  };

  return (
    <div className="candidate-stats">
      <div
        style={{ height: window.innerHeight - 260 + "px", overflowY: "auto" }}
        ref={refPassthrough}
      >
        <div className="TitleDiv justify-center">
          <h1 className="text-4xl font-bold">Candidate Stats</h1>
        </div>

        <div className="Stats grid grid-cols-1 gap-1">
          {/* <div className='grid grid-cols-3 gap-2'>

                        <button

                            onClick={() => openInvitationModelForEmail()}
                            startIcon={<Email />}
                            // className="px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto"
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'
                            disabled={!hasValidEmailCandidates}
                        >
                            Send Email Invites
                        </button>
                        <button

                            startIcon={<Message />}
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'
                            onClick={() => openInvitationModelForSMS()}
                            disabled={!hasValidPhoneCandidates}
                        >
                            Send SMS Invites
                        </button>
                        <button

                            startIcon={<GetApp />}
                            className='px-4 py-2 text-sm rounded-lg bg-blue-500 text-white w-full ml-auto'

                            onClick={handleExportLeads}
                        >
                            Export {filteredCandidates.length} Leads
                        </button>




                    </div> */}

          {
            // communicationTemplates.length > 0 &&
            <div className="w-fit m-auto my-4">
              <div className="grid grid-cols-2 gap-0 px-0">
                <button
                  style={{
                    backgroundColor:
                      candidateType === "Recruit" ? "#022780" : "",
                    color: candidateType === "Recruit" ? "white" : "",
                  }}
                  onClick={() => setCandidateType("Recruit")}
                  className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tl-lg"
                >
                  Recruits
                </button>
                <button
                  style={{
                    backgroundColor:
                      candidateType === "Company" ? "#022780" : "",
                    color: candidateType === "Company" ? "white" : "",
                  }}
                  onClick={() => setCandidateType("Company")}
                  className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white rounded-tr-lg"
                >
                  Companies
                </button>
              </div>
              <div className="grid grid-cols-3 gap-0 px-0">
                <button
                  style={{
                    backgroundColor: modeSelected === "email" ? "#022780" : "",
                    color: modeSelected === "email" ? "white" : "",
                  }}
                  onClick={() => setModeSelected("email")}
                  className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white "
                >
                  Email
                </button>
                <button
                  style={{
                    backgroundColor: modeSelected === "sms" ? "#022780" : "",
                    color: modeSelected === "sms" ? "white" : "",
                  }}
                  onClick={() => setModeSelected("sms")}
                  className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white"
                >
                  SMS
                </button>
                <button
                  style={{
                    backgroundColor: modeSelected === "both" ? "#022780" : "",
                    color: modeSelected === "both" ? "white" : "",
                  }}
                  onClick={() => setModeSelected("both")}
                  className="focus:outline-none px-2 py-1  border-2 border-[#022780] hover:bg-[#022780] text-[#022780] hover:text-white"
                >
                  Both
                </button>
              </div>
              {
                <div className="flex gap-2 items-center justify-center border-2 m-auto bg-white cursor-pointer border-[#022780] rounded-tl-none rounded-tr-none rounded-xl  px-8 py-1">
                  <div
                    onClick={() => {
                      setIsInviteOpen(true);
                    }}
                  >
                    <img
                      src={bot}
                      alt="bot"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>

                  <select
                    onClick={(e) => {
                      setAction(e.target.value);
                      if (e.target.value === "Send Custom Text") {
                        setCustomMessage(true);
                      } else {
                        setCustomMessage(false);
                      }
                    }}
                    className="bg-transparent w-[12rem] outline-none focus:outline-none font-bold uppercase text-[#022780]"
                  >
                    {["Send Ad", "Send Custom Text"].map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              }
            </div>
          }

          <div className="Stat flex justify-center items-center gap-2 mt-2">
            <p>TOTAL LEADS: {filteredCandidates.length}</p>
          </div>

          <hr className="my-2 mx-0" />
          <h1 className="lead_owners_title">Leads Status</h1>
          <div className="select_deselect">
            <button
              className="select"
              onClick={() => {
                setFilterMapIconIds([]);
              }}
            >
              Select All
            </button>
            <button
              className="deselect"
              onClick={() => {
                setFilterMapIconIds(map.mapIcons.map((icon) => icon.id));
              }}
            >
              Deselect All
            </button>
          </div>
          {/* <hr className="my-2 mx-0" /> */}

          {/* <TagsState
            label="Candidates"
            leadOrCandidateIds={filteredCandidates.map((c) => c.id)}
            onDisassociate={(selectedTagIds) =>
              handleDisassociate(
                selectedTagIds,
                filteredCandidates.map((c) => c.id)
              )
            }
            tags={tags}
          />
          <hr className="my-2 mx-0" />
          <SelectionMenu tags={tags} onButtonClick={handleAssociate} /> */}
          {/* <hr className="my-2 mx-0" />                                                                     */}

          <div className="MapIconStats">
            {getMapIconStats().map((stat, index) => (
              <div
                className="flex flex-col items-center content-center gap-1"
                key={index}
              >
                <div
                  key={index}
                  // className={`map-icon-stat ${filterMapIconIds.includes(stat.assignedMapIcons.id) ? 'active' : ''}`}
                  onClick={() => toggleMapIcon(stat.assignedMapIcons.id)}
                  style={{ borderRadius: "10px" }}
                  className={`MapIconStat  ${
                    !filterMapIconIds.includes(stat.assignedMapIcons.id)
                      ? "active"
                      : null
                  } border-2`}
                >
                  <h3>
                    <img
                      src={
                        "data:image/png;base64," + stat.assignedMapIcons.image
                      }
                      alt="Map Icon"
                    />
                  </h3>
                  <p>{stat.assignedMapIcons.abbreviation}</p>
                  <p
                    style={{
                      fontWeight: !filterMapIconIds.includes(
                        stat.assignedMapIcons.id
                      )
                        ? "bold"
                        : null,
                    }}
                  >
                    {stat.count}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <hr className="my-2 mx-0" />

          <>
            <h1 className="lead_owners_title">Recruit & Company Stats</h1>

            <div className="stats-section">
              {/* <Typography variant="h6">Recruit & Company Stats</Typography> */}
              <div className="type-stats">
                <div
                  className={`stat-item ${
                    filterLeadTypes.includes("Recruit") ? "active" : ""
                  }`}
                  onClick={() => toggleLeadType("Recruit")}
                >
                  <Person fontSize="large" />
                  <Typography variant="body1">Recruits</Typography>
                  <Typography variant="h6">
                    {recruitCompanyStats.recruit}
                  </Typography>
                </div>
                <div
                  className={`stat-item ${
                    filterLeadTypes.includes("Company") ? "active" : ""
                  }`}
                  onClick={() => toggleLeadType("Company")}
                >
                  <Business fontSize="large" />
                  <Typography variant="body1">Companies</Typography>
                  <Typography variant="h6">
                    {recruitCompanyStats.company}
                  </Typography>
                </div>
              </div>
            </div>
          </>

          <hr className="my-2 mx-0" />

          <div className="stats-section">
            <Typography variant="h6">Info </Typography>
            <div className="invite-stats">
              <div
                className={`stat-item ${
                  filterInviteStatus.includes("sent") ? "active" : ""
                }`}
                onClick={() => toggleInviteStatus("sent")}
              >
                <Send fontSize="medium" />
                <Typography variant="body1">Sent</Typography>
                <Typography variant="h6">{inviteStatusStats.sent}</Typography>
              </div>
              <div
                className={`stat-item ${
                  filterInviteStatus.includes("unsent") ? "active" : ""
                }`}
                onClick={() => toggleInviteStatus("unsent")}
              >
                <MailOutline fontSize="medium" />
                <p>Unsent</p>
                <Typography variant="h6">{inviteStatusStats.unsent}</Typography>
              </div>
            </div>
          </div>

          <hr className="my-2 mx-0" />

          <div className="stats-section">
            <Typography variant="h6">Invite Status</Typography>
            <div className="invite-stats">
              <div
                className={`stat-item ${
                  filterContactInfoStatus.includes("emailOnly") ? "active" : ""
                }`}
                onClick={() => toggleContactInfoStatus("emailOnly")}
              >
                <Send fontSize="medium" />
                <Typography variant="body1">Has Email Only</Typography>
                <Typography variant="h6">
                  {contactInfoStats.emailOnly}
                </Typography>
              </div>
              <div
                className={`stat-item ${
                  filterContactInfoStatus.includes("phoneOnly") ? "active" : ""
                }`}
                onClick={() => toggleContactInfoStatus("phoneOnly")}
              >
                <MailOutline fontSize="medium" />
                <Typography variant="body1">Has Phone Only</Typography>
                <Typography variant="h6">
                  {contactInfoStats.phoneOnly}
                </Typography>
              </div>
              <div
                className={`stat-item ${
                  filterContactInfoStatus.includes("both") ? "active" : ""
                }`}
                onClick={() => toggleContactInfoStatus("both")}
              >
                <MailOutline fontSize="medium" />
                <Typography variant="body1">Has Both</Typography>
                <Typography variant="h6">{contactInfoStats.both}</Typography>
              </div>
              <div
                className={`stat-item ${
                  filterContactInfoStatus.includes("none") ? "active" : ""
                }`}
                onClick={() => toggleContactInfoStatus("none")}
              >
                <MailOutline fontSize="medium" />
                <Typography variant="body1">Has None</Typography>
                <Typography variant="h6">{contactInfoStats.none}</Typography>
              </div>
            </div>
          </div>
        </div>

        <InvitationModal
          action={action}
          mode={modeSelected}
          bulk={true}
          open={isInviteOpen}
          onClose={() => setIsInviteOpen(false)}
          isStatSMS={contactInfoStats.phoneOnly || contactInfoStats.both}
          isStatEmail={contactInfoStats.emailOnly || contactInfoStats.both}
          candidateType={candidateType}
          filteredCandidateIds={filteredCandidates.map((c) => c.id)}
        />

        {/* {
                    (user.userType === "SuperAdmin" || user.userType === "Admin") &&
                    <>
                        <hr style={{ margin: "0.5rem 0rem" }} />
                        <h1 style={{
                            marginTop: "1rem"
                        }} className='lead_owners_title'>Update Lead Status</h1>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            gap: "0.5rem",

                        }}>
                            <MapIconSelector setAssignedMapIcons={setSelectedMapIcon} placeholder='Select Map Icon' />
                            <button style={{
                                backgroundColor: "#181E4B ",
                                color: "white",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}  >Update</button>
                        </div>
                    </>
                }
                <hr style={{ margin: "0.5rem 0rem" }} />
                {
                    user.userType === "SuperAdmin" &&
                    <>
                        <h1 style={{
                            marginTop: "1rem"
                        }} className='lead_owners_title'>Assign Fiber Company</h1>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            gap: "0.5rem",
                        }}>
                            <FiberCompanySelector setUser={setFiberCompany} placeholder='Select Company' />
                            <button style={{
                                backgroundColor: "#181E4B ",
                                color: "white",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}  >Update</button>
                        </div>
                    </>
                } */}

        <button
          onClick={() => {
            handleExportClick();
          }}
          className="p-2 bg-[#2B92D5] text-white rounded-lg text-sm"
        >
          Export {filteredCandidates.length} leads
        </button>
        <div className="search-section">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search candidates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>

        {/* <div className="candidates-list">
                    {paginatedCandidates.map(candidate => (
                        <Card key={candidate.id} className="candidate-card" sx={{ mb: 2 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={8}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <img
                                                src={`data:image/png;base64,${candidate.mapIcon.image}`}
                                                alt="Map Icon"
                                                style={{ width: 24, height: 24, marginRight: 8 }}
                                            />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1 }}>
                                                {candidate.name ? candidate.name : 'No Name'}
                                            </Typography>
                                            {candidate.leadType === "Recruit" ? <BiBriefcase /> : <FcOrganization />}
                                            {candidate.doorToDoor && <DoorFront sx={{ ml: 1, fontSize: 18 }} />}
                                        </Box>
                                        <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                            {candidate.mapString}
                                        </Typography>
                                        {candidate.personalEmail && (
                                            <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                                                {candidate.personalEmail}
                                            </Typography>
                                        )}

                                        <Box sx={{ mt: 1 }}>
                                            <InfoRow label="Company" value={candidate.companyName} />
                                            {candidate.industry && <InfoRow label="Industry" value={candidate.industry} />}
                                            {candidate.positionTitle && <InfoRow label="Title" value={candidate.positionTitle} />}
                                            {candidate.companyEmail && <InfoRow label="Company Email" value={candidate.companyEmail} />}
                                            {candidate.linkedIn && <InfoRow label="LinkedIn" value={candidate.linkedIn} />}
                                        </Box>

                                    </Grid>
                                    <div className="candidate-actions">
                                        <IconButton onClick={() => contactButtons.email(candidate.personalEmail ? candidate.personalEmail : candidate.companyEmail)}>
                                            <Email />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.whatsapp(candidate.phone)}>
                                            <WhatsApp />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.sms(candidate.phone)}>
                                            <Message />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.call(candidate.phone)}>
                                            <Phone />
                                        </IconButton>
                                        <IconButton onClick={() => contactButtons.directions(candidate.coordinates)}>
                                            <MdOutlineDirections color='#81848A' size={30} />
                                        </IconButton>
                                        <IconButton onClick={() => snapToLocation(candidate)}>
                                            <IoMapOutline color='#81848A' size={30} />
                                        </IconButton>
                                        
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </div> */}

        {/* <div className="candidates-list"> */}
        {paginatedCandidates.map((candidate) => (
          <div
            className="NewCandidateCard"
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid var(--primary-color)",
              marginTop: "2rem",
              padding: "1rem 0",
            }}
            // flex direction column on 700px
          >
            <div className="flex gap-2 items-center">
              <div className="ImagesDiv">
                <div className="Image">
                  <img
                    src={`data:image/png;base64,${candidate.mapIcon.image}`}
                    alt="Map Icon"
                  />
                </div>
              </div>

              <div className="DataDiv">
                <div className="Name">
                  <h1 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {" "}
                    {candidate.name ? candidate.name : "No Name"}
                    {/* <span style={{
                                        fontSize: "0.8rem",
                                        color: "#2761D0",

                                    }}>{getSalesRepName(fh)}</span> */}
                  </h1>
                </div>

                <div className="Address">
                  <p
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    {candidate.mapString}
                  </p>
                </div>
                <div className="additional_info">
                  {
                    <p style={{ fontSize: ".8rem" }}>
                      Company | {candidate.companyName}
                    </p>
                  }

                  {candidate.industry && (
                    <p style={{ fontSize: ".8rem" }}>
                      Industry | {candidate.industry}{" "}
                    </p>
                  )}

                  {candidate.positionTitle && (
                    <p style={{ fontSize: ".8rem" }}>
                      Position | {candidate.positionTitle}
                    </p>
                  )}
                  {candidate.companyEmail && (
                    <p style={{ fontSize: ".8rem" }}>
                      Company Email | {candidate.companyEmail}
                    </p>
                  )}
                  {candidate.linkedIn && (
                    <p style={{ fontSize: ".8rem" }}>
                      LinkedIn | {candidate.linkedIn}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="NewCandidateButtons">
              <Email
                fontSize="large"
                sx={{ cursor: "pointer", color: "#81848A" }}
                onClick={() =>
                  contactButtons.email(
                    candidate.personalEmail
                      ? candidate.personalEmail
                      : candidate.companyEmail
                  )
                }
              />
              <WhatsApp
                fontSize="large"
                sx={{ cursor: "pointer", color: "#81848A" }}
                onClick={() => contactButtons.whatsapp(candidate.phone)}
              />
              <Message
                fontSize="large"
                sx={{ cursor: "pointer", color: "#81848A" }}
                onClick={() => contactButtons.sms(candidate.phone)}
              />
              <Phone
                fontSize="large"
                sx={{ cursor: "pointer", color: "#81848A" }}
                onClick={() => contactButtons.call(candidate.phone)}
              />
              <MdOutlineDirections
                style={{
                  fontSize: "2rem",
                  cursor: "pointer",
                  color: "#81848A",
                }}
                onClick={() => contactButtons.directions(candidate.coordinates)}
              />
            </div>
          </div>
        ))}
        {/* </div> */}

        <Pagination
          className="pagination mb-4"
          count={Math.ceil(filteredCandidates.length / itemsPerPage)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default CandidateStats;

import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import FiberPlanApi from '../../API/FiberPlanApi';
import SearchFiberPlanReq from '../../Requests/FiberPlan/SearchFiberPlansReq';
import SoftTypography from '../SoftTypography';
import { UserContext } from '../../App';
const Index = ({setUser,fiberPlan,userId,salesOrgId,disabled=false,placeholder="Select a parent Organization",className,multiple,fiberCompany ,fiberCompaniesIds}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    const user = useContext(UserContext)
    const GetOrg = async () => {
        let searchReq = new SearchFiberPlanReq();

        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.name = UsersSearch;
        searchReq.userId = userId;
        searchReq.salesOrgId = salesOrgId;
        searchReq.fiberCompanyId = fiberCompany;



        if(user.userType === "SalesOrgAdmin"){
            searchReq.salesOrgId = user.salesOrgId
        }

        if(user.userType === "SalesRep"){
            searchReq.userId = user.id
        }

        if(fiberCompaniesIds) searchReq.fiberCompaniesIds = fiberCompaniesIds;
        let res = await FiberPlanApi.SearchFiberPlans(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            console.log(res?.data?.list)

            // sort the list by speed
            const arr =   res?.data?.list
            .sort((a,b)=>{
                return b.speed - a.speed
            })


            arr.forEach((item) => {
                optionsArr.push({ value: item, label: item.name + " | " + item.fiberCompany.name ,id:item.id })
            })
            return optionsArr;
        }
      
    }

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, [disabled,fiberCompany,fiberCompaniesIds]);

    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
             
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    defaultValue={fiberPlan ? { value: fiberPlan, label: fiberPlan.name ,id:fiberPlan.id } : null}
                    onChange={(e) => {
                        if(multiple)
                        setUser(e.map((item)=>{return {id:item.id,name:item.value.name}}))
                        else
                        setUser({id:e.id,name:e.value.name})
                    }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={multiple}
                    defaultOptions={options}
                    classNamePrefix={'async_select'}
                />
            </SoftTypography>

        </div>
    )
}

export default Index
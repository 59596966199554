import React, { useContext, useEffect } from 'react'
import { MapContext } from '..'
import StringIntReq from '../../../../Requests/StringIntReq'
import FiberCompanyApi from '../../../../API/FiberCompanyApi'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style, Icon } from 'ol/style'// Ensure you have this icon in your assets folder
import land from "./../Images/land.svg"
const RenderCoverageArea = () => {

    const map = useContext(MapContext)
    const [coverageAreas, setCoverageAreas] = React.useState([])

    const fetchCoverageArea = async () => {
        const req = new StringIntReq()
        req.int = map.companyIdForCoverageArea

        const res = await FiberCompanyApi.GetCompanyCoverageArea(req)
        if (res.status.success) {
            setCoverageAreas(res.data)
            console.log(res.data)
        }

    }

    useEffect(
        () => {
            if (map.mapObject && map.companyIdForCoverageArea && map.enableCoverageArea) {
                fetchCoverageArea()
            }
        }, [map.mapObject,map.companyIdForCoverageArea,map.enableCoverageArea]
    )

    useEffect(
        () => {
            if (map.mapObject && map.enableCoverageArea  && coverageAreas.length > 0) {
                let features = []

                coverageAreas.forEach((area) => {
                    let feature = new Feature(
                        new Point(fromLonLat([area.coordinate.longitude, area.coordinate.latitude]))
                    )
                    features.push(feature)
                })

                const layerSource = new VectorSource({
                    features: features
                })

                const layer = new VectorLayer({
                    name: 'Coverage Area',
                    source: layerSource,
                    style: new Style({
                        image: new Icon({
                            src: land,
                            scale: 2, // Adjust the scale as needed
                        })
                    })
                })

                map.mapObject.addLayer(layer)
            }

            return () => {
                if(map.mapObject)
                map.mapObject.getLayers().forEach((layer) => {
                    if (layer.get('name') === 'Coverage Area') {
                        map.mapObject.removeLayer(layer)
                    }
                })
            }

        }, [coverageAreas,map.companyIdForCoverageArea,map.enableCoverageArea]
    )

    return (
        <></>
    )
}

export default RenderCoverageArea
import { useState } from 'react';
import './SelfieUploadPopup.css';

const SelfieUploadPopup = ({ onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = () => {
    // Add your upload logic here
    if (selectedFile) {
      console.log('Uploading file:', selectedFile);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="upload-header">
          <h2>
            📸 Upload Your Selfie
          </h2>
          <p className="upload-instructions">
            Please upload a clear photo of yourself facing forward
          </p>
        </div>

        <div 
          className={`upload-area ${isDragging ? 'dragover' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="file-input-wrapper">
            <input
              type="file"
              accept="image/*"
              className="file-input"
              onChange={handleFileChange}
              id="fileInput"
            />
            <label htmlFor="fileInput">
              {previewUrl ? (
                <img 
                  src={previewUrl} 
                  alt="Preview" 
                  className="upload-preview"
                />
              ) : (
                <div>
                  <p>Drag and drop your selfie here</p>
                  <p>or click to browse</p>
                </div>
              )}
            </label>
          </div>
        </div>

        {selectedFile && (
          <p className="selected-file">
            Selected: {selectedFile.name}
          </p>
        )}

        <div className="button-group">
          <button 
            className="upload-button"
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            Upload
          </button>
          <button 
            className="close-button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelfieUploadPopup;
import { Modal } from '@mui/material'
import React, { useState } from 'react'
import UserSelector from './../../../components/UserSelector'
import Swal from 'sweetalert2'
import MasterSheetApi from '../../../API/MasterSheetApi'
const ExportToWorkSheet = ({ open, setOpen }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [salesRepIds, setSalesRepIds] = useState([])
    const [accountStatus, setAccountStatus] = useState("")

    const exportToWorkSheet = async () => {
        Swal.fire({
            title: 'Please Wait',
            text: 'Exporting to Worksheet...',
            icon: 'info',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading()
            }
        })

        if(name ==="")
        {
            Swal.fire({
                title: 'Error',
                text: 'Name is required',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(description ==="")
        {
            Swal.fire({
                title: 'Error',
                text: 'Description is required',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(accountStatus ===""){
            Swal.fire({
                title: 'Error',
                text: 'Account Status is required',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = {
            name: name,
            description: description,
            accountStatus: accountStatus,
            userIds: salesRepIds
        }

        const res = await MasterSheetApi.CreateCandidateDynamicList(req)

        if (res.status.success) {
            Swal.fire({
                title: 'Success',
                text: 'Exported to Worksheet Successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
            setOpen(false)
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.status.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
        }


    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} >
            <div className='custom_modal'>
                <h1>Export To Worksheet</h1>

                <label>Name</label>
                <input style={{textAlign:"left"}} type='text' value={name} onChange={(e) => setName(e.target.value)} />


                <label>Description</label>
                <textarea style={{textAlign:"left"}} className='txt' value={description} onChange={(e) => setDescription(e.target.value)} />


                <label>Account Status</label>
                <select style={{textAlign:"left"}} value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                    <option value="New Candidate">New Candidate</option>
                    <option value="Contacted">Contacted</option>
                    <option value="Application Received">Application Received</option>
                    <option value="Interview Scheduled">Interview Scheduled</option>
                    <option value="Offer Extended">Offer Extended</option>
                    <option value="Future Consideration">Future Consideration</option>
                    <option value="Rejected">Rejected</option>
                </select>


                <label>Sales Reps</label>
                <div className='w-full'>
                    <UserSelector setUser={setSalesRepIds} placeholder='Select User' conversationType={"Group"} />
                </div>

                <button onClick={()=>{
                    exportToWorkSheet()
                }}>Export</button>
            </div>
        </Modal>
    )
}

export default ExportToWorkSheet
import React, { useContext, useEffect } from 'react'
import { MapContext } from '..'
import { BiBuilding, BiPencil, BiTrash, BiUserCircle } from 'react-icons/bi'
import { fromLonLat, toLonLat } from 'ol/proj'
import MapHelper from '../MapHelper'
import { RiBarChart2Fill } from 'react-icons/ri'
import StringIntReq from '../../../../Requests/StringIntReq'
import ServicableAreaApi from '../../../../API/ServicableAreaApi'
import Swal from 'sweetalert2'
import { IoCloseCircle, IoNavigateCircle } from 'react-icons/io5'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import EditServicableArea from './EditServicableArea'
import { Tabs, Tab, Box } from '@mui/material';
import { UserContext } from '../../../../App'


const ManageArea = () => {

  const map = useContext(MapContext)
  const mapHelper = new MapHelper()
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [selectedArea, setSelectedArea] = React.useState(null)
  const [addresses, setAddresses] = React.useState([])
  const [selectedTab, setSelectedTab] = React.useState(0)
  const user = useContext(UserContext)
  const goToArea = (area) => {
    const coordinates = area?.latlngs?.map((latlng) => {
      return fromLonLat([latlng[1], latlng[0]]);
    });

    const polygon = new Polygon([coordinates])
    const interiorPoint = polygon.getInteriorPoint();
    const centerCoordinates = interiorPoint.getCoordinates();

    map.mapObject.getView().setCenter(centerCoordinates)
    map.mapObject.getView().setZoom(16)
  }

  const openStats = (area) => {
    let areaPoints = area.latlngs.map((point) => {
      return {
        latitude: point[0],
        longitude: point[1]
      }
    })
    let fiberHousesIds = []

    map.fiberHouses.forEach((house) => {
      if (mapHelper.checkIfFiberHouseIsInsideServicableArea(house, areaPoints)) {
        fiberHousesIds.push(house.id)
      }
    })

    map.setSearchArea(areaPoints)
    map.mapObject.getLayers().getArray().forEach(layer => {
      if (layer instanceof VectorLayer) {
        if (layer.get('name') === "areas") {
          const source = layer.getSource()
          if (source instanceof VectorSource) {
            source.forEachFeature(
              (feature) => {
                if (feature instanceof Feature) {
                  if (feature.get("s_id") === area.id) {
                    map.setSearchAreaFeature([feature])
                    map.setFiberHouseIds(fiberHousesIds)
                    map.setIndex(8)
                  }
                }
              }
            )
          }
        }
      }
    });
  }


  const areaStats = (area) => {
    let count = 0
    let salesReps = []
    let salesOrgs = []

    let areaPoints = area.latlngs.map((point) => {
      return {
        latitude: point[0],
        longitude: point[1]
      }
    })

    map.fiberHouses?.forEach((house) => {
      if (mapHelper.checkIfFiberHouseIsInsideServicableArea(house, areaPoints)) {

        house?.owners?.forEach((owner) => {
          if (!salesReps.includes(owner.name)) {
            salesReps.push(owner.name)
          }
        })

        if (!salesOrgs.includes(house.salesOrgName)) {
          salesOrgs.push(house.salesOrgName)
        }

        count++
      }
    })

    return { count, salesReps, salesOrgs }
  }

  async function deleteArea(area) {
    const option = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this area!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    })

    if (!option.isConfirmed) {
      return
    }
    Swal.fire({
      title: 'Deleting Area...',
      icon: 'info',
      text: 'Please wait',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    let req = new StringIntReq()

    req.int = area.id

    let res = await ServicableAreaApi.DeleteServicableArea(req)
    if (res?.status?.success) {
      Swal.fire({
        icon: 'success',
        title: 'Area Deleted Successfully',
        showConfirmButton: true,
        // timer: 1500,
        allowOutsideClick: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })

      map.setServicableAreas(map.servicableAreas.filter((a) => a.id !== area.id))

      map.mapObject.getLayers().getArray().forEach(layer => {
        if (layer instanceof VectorLayer) {
          if (layer.get('name') === "areas") {
            const source = layer.getSource()
            if (source instanceof VectorSource) {
              source.forEachFeature(
                (feature) => {
                  if (feature instanceof Feature) {
                    if (feature.get("s_id") === area.id) {
                      source.removeFeature(feature)
                    }
                  }
                }
              )
            }
          }
        }
      });

    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error Deleting Area',
        showConfirmButton: true,
        timer: 1500,
        allowOutsideClick: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      })
    }



  }

  async function getLocationName(area) {

    const coordinates = area?.latlngs?.map((latlng) => {
      return fromLonLat([latlng[1], latlng[0]]);
    });

    const polygon = new Polygon([coordinates])
    const interiorPoint = polygon.getInteriorPoint();
    const centerCoordinates = interiorPoint.getCoordinates();

    const address = await mapHelper.getLocationName(toLonLat(centerCoordinates))

    return address

  }

  async function getLocationDetails(area) {

    const coordinates = area?.latlngs?.map((latlng) => {
      return fromLonLat([latlng[1], latlng[0]]);
    });

    const polygon = new Polygon([coordinates])
    const interiorPoint = polygon.getInteriorPoint();
    const centerCoordinates = interiorPoint.getCoordinates();

    const details = await mapHelper.getLocationDetails(toLonLat(centerCoordinates))

    return details
  }

  useEffect(() => {
    if (map.servicableAreas.length === 0) {
      return
    }
    map.servicableAreas.forEach(async (area) => {
      let details = await getLocationDetails(area)
      setAddresses(
        (prev) => [...prev, details]
      )

    })
  }, [map.servicableAreas])


  const formatedAddress = (address) => {
    let f_address = "N/A"

    if (!address) {
      return f_address
    }

    if (address.city) {
      f_address = address.city
    }
    if (address.state) {
      f_address += ", " + address.state
    }
    if (address.country) {
      f_address += ", " + address.country
    }
    return f_address
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <EditServicableArea open={openEditModal} setOpen={setOpenEditModal} area={selectedArea} />
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Manage Areas</h2>
            <p className="mt-1 text-sm text-gray-500">
              View and manage your areas. Click edit to modify or delete to remove an area.
            </p>
          </div>

        </div>
      </div>
      <div className="tabbed-stats-page">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChange} aria-label="stats tabs">
            <Tab label="My Areas" />
            <Tab label="Other Visible Areas" />
          </Tabs>
        </Box>
      </div>
      <div className="p-6">
        <div className="space-y-2">
          {map.servicableAreas.map((area) => {

            let noOfLeads = areaStats(area).count
            let salesReps = areaStats(area).salesReps
            let salesOrgs = areaStats(area).salesOrgs
            let show = selectedTab === 0 ? area.createdById === user.id : area.createdById !== user.id
            if (show) {
              return <div
                key={area.id}
                className="flex flex-col sm:flex-row sm:items-start justify-between rounded-lg border border-gray-200 p-4 hover:bg-gray-50 transition-colors"
              >
                <div className="flex flex-col mb-4 sm:mb-0">
                  <div className="flex items-center gap-3 mb-2">
                    <div
                      className="h-4 w-4 rounded-full"
                      style={{ backgroundColor: area.color }}
                    />
                    <span className="font-medium text-gray-900">{area.name}</span>
                    {
                      area.label &&
                      <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                        {area.label.label}
                      </span>
                    }
                  </div>
                  <span className="text-xs font-medium text-gray-500 bg-gray-100 rounded-full px-2 py-0.5 self-start mb-1">
                    {noOfLeads} {'leads'}
                  </span>
                  <span className="text-xs text-gray-400 mb-2">
                    Created: {
                      area?.createdAt ?
                        new Date(area?.createdAt).toLocaleDateString()
                        : 'N/A'
                    }
                  </span>
                  <span className="text-xs text-gray-400 mb-2">
                    Address: {formatedAddress(addresses[map.servicableAreas.indexOf(area)])}
                  </span>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-wrap items-center gap-2">
                      <BiBuilding className="h-4 w-4 text-gray-400" />
                      {salesOrgs?.map((org) => (
                        org ?
                          <div key={org} className="flex items-center gap-1 bg-gray-100 rounded-full px-2 py-1">
                            <span className="text-xs text-gray-600">{org}</span>
                          </div>
                          : null
                      ))}
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      <BiUserCircle className="h-4 w-4 text-gray-400" />
                      {salesReps?.map((rep) => (
                        <div key={rep.id} className="flex items-center gap-1 bg-gray-100 rounded-full px-2 py-1">
                          <span className="text-xs text-gray-600">{rep}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">

                  <button
                    className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    aria-label="Show stats"
                    onClick={() => goToArea(area)}
                  >
                    <IoNavigateCircle className="h-6 w-6" />
                  </button>
                  <button
                    className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    aria-label="Show stats"
                    onClick={() => {
                      goToArea(area)
                      openStats(area)
                    }}
                  >
                    <RiBarChart2Fill className="h-6 w-6" />
                  </button>
                  {
                    (selectedTab === 0 || user.userType === "SuperAdmin") &&
                    <button
                      className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      aria-label="Edit"
                      onClick={() => {
                        setSelectedArea(area)
                        setOpenEditModal(true)
                      }}
                    >
                      <BiPencil className="h-6 w-6" />
                    </button>
                  }
                  {
                    (selectedTab === 0 || user.userType === "SuperAdmin") &&
                    <div className="relative">
                      <button
                        onClick={() => deleteArea(area)}
                        className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        aria-label="More options"
                      >
                        <BiTrash className="h-6 w-6" />
                      </button>
                    </div>
                  }
                </div>
              </div>
            }
            else {
              return null
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default ManageArea
import { useCallback, useContext, useEffect, useState } from "react";
import Layout from "../../../Layouts/Layout.js";
import NewCard from "../../../components/Card/NewCard";
import VersatileLoader from "../../../components/VersatileLoader";
import UsersApi from "../../../API/UsersApi.js";
import GenerateAffiliateCodeReq from "../../../Requests/ReferralCode/GenerateAffiliateCodeReq.js";
import { UserContext } from "../../../App.js";
import {
  Clock,
  CheckCircle,
  XCircle,
  AlertCircle,
  Users,
  Gift,
  Zap,
} from "lucide-react";
import FiberCompanyApi from "../../../API/FiberCompanyApi.js";
import ReferralCompanyDetails from "./ReferralCompanyDetails.js";
import GetAffiliateCodesByUserIdReq from "../../../Requests/ReferralCode/GetAffiliateCodesByUserIdReq.js";
import Swal from "sweetalert2";

// const STATUSES = Object.freeze({
//   1: "SIGNED_UP_BUT_NOT_INSTALLED_YET",
//   2: "INSTALLED_WAITING_CUSTOMER_REQUEST",
//   3: "REQUESTED_BY_CUSTOMER",
//   4: "RECEIVED_BY_CUSTOMER_REWARD_SENT",
//   5: "REJECTED_BY_ADMIN",
//   6: "ERROR_DURING_SENDING_REWARD",
//   7: "CANCELLED_INSTALLATION",
// });

export default function ReferralProgram() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customCode, setCustomCode] = useState("");

  const user = useContext(UserContext);

  const [data, setData] = useState({
    companies: [],
    affiliateCodes: [],
  });

  const companyAffiliateCode = data.affiliateCodes.find(
    (af) => af.fiberCompanyId === selectedCompany?.id
  );

  const handleGenerate = useCallback(
    async (code) => {
      const req = new GenerateAffiliateCodeReq(
        user.id,
        selectedCompany.id,
        code
      );

      const response = await UsersApi.GenerateAffiliateCode(req);

      if (!response.status.success) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.status.message,
        });
      }

      setData((prev) => ({
        ...prev,
        affiliateCodes: [...prev.affiliateCodes, response.data],
      }));
    },
    [selectedCompany, user.id]
  );

  const getAffiliateCodes = async () => {
    const req = new GetAffiliateCodesByUserIdReq(user.id);
    req.userId = user.id;
    const response = await UsersApi.GetAffiliateCodesByUserId(req);
    return response.data;
  };

  const getCompanies = async () => {
    const response = await FiberCompanyApi.GetFiberCompanies({
      onlyActive: true,
    });

    if (!response.status.success) {
      console.error(response.status.message);
      return [];
    }
    return response.data;
  };

  useEffect(() => {
    if (!user) return;

    Promise.all([getAffiliateCodes(), getCompanies()])
      .then(([affiliateCodes, companies]) => {
        setData({
          affiliateCodes,
          companies,
        });

        setSelectedCompany((prev) => (!prev ? companies[0] : prev));
      })
      .catch((e) => {
        alert("ERROR!");
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]); // Removed dependencies

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };

  const getTotalRewards = (affiliateCode) => {
    return affiliateCode.affiliateStatuses.filter((as) => as.status === 4)
      .length;
  };

  // const getLatestStatus = (affiliateStatuses) => {
  //   if (affiliateStatuses.length === 0) return null;
  //   return affiliateStatuses.reduce((latest, current) =>
  //     current.createdAt > latest.createdAt ? current : latest
  //   );
  // };

  // const statusMessages = {
  //   0: {
  //     message: "Pending",
  //     icon: <Clock className="w-5 h-5 text-yellow-500" />,
  //   },
  //   1: {
  //     message: "Installed",
  //     icon: <CheckCircle className="w-5 h-5 text-green-500" />,
  //   },
  //   2: {
  //     message: "Reward Received",
  //     icon: <CheckCircle className="w-5 h-5 text-green-500" />,
  //   },
  //   3: {
  //     message: "Rejected",
  //     icon: <XCircle className="w-5 h-5 text-red-500" />,
  //   },
  //   4: {
  //     message: "Error",
  //     icon: <AlertCircle className="w-5 h-5 text-red-500" />,
  //   },
  // };

  return (
    <Layout>
      {loading ? (
        <VersatileLoader color="#2761D0" size="large" />
      ) : (
        <div className="px-4 py-8">
          <div className="flex flex-col w-full overflow-x-hidden">
            <div className="flex shrink-0 border-b border-zinc-700 w-full justify-start bg-muted overflow-x-auto">
              {data.companies.map((c) => (
                <button
                  key={c.id}
                  className={`focus:outline-none px-5 py-2 flex items-center justify-center text-base rounded-t-lg ${
                    c.id === selectedCompany.id ? "bg-blue-600 text-white" : ""
                  }`}
                  onClick={() => setSelectedCompany(c)}
                >
                  {c.name}
                </button>
              ))}
            </div>
            {data.companies.map((company) => (
              <div
                key={company.id}
                className={`space-y-6 grow p-5 rounded-md ${
                  selectedCompany.id !== company.id ? "hidden" : ""
                }`}
              >
                <NewCard
                  title="Plans and Rewards"
                  content={
                    <ReferralCompanyDetails
                      company={selectedCompany}
                      affiliateCode={companyAffiliateCode}
                      handleGenerate={handleGenerate}
                      onAffiliateCodeChange={setCustomCode}
                    />
                  }
                />

                <div className="grid md:grid-cols-3 gap-4">
                  <NewCard
                    title="TOTAL REFERRALS"
                    content={
                      <div className="flex items-center justify-center space-x-2">
                        <Users className="w-6 h-6 text-blue-500" />
                        <span className="text-2xl font-bold">
                          {data.affiliateCodes.reduce(
                            (total, code) =>
                              total + code.affiliateStatuses.length,
                            0
                          )}
                        </span>
                      </div>
                    }
                    contentLarge
                  />
                  <NewCard
                    title="TOTAL REWARDS"
                    content={
                      <div className="flex items-center justify-center space-x-2">
                        <Gift className="w-6 h-6 text-green-500" />
                        <span className="text-2xl font-bold">
                          {data.affiliateCodes.reduce(
                            (total, code) => total + getTotalRewards(code),
                            0
                          )}
                        </span>
                      </div>
                    }
                    contentLarge
                  />
                  <NewCard
                    title="ACTIVE CODES"
                    content={
                      <div className="flex items-center justify-center space-x-2">
                        <Zap className="w-6 h-6 text-yellow-500" />
                        <span className="text-2xl font-bold">
                          {data.affiliateCodes.length}
                        </span>
                      </div>
                    }
                    contentLarge
                  />
                </div>

                <NewCard
                  title="Your Affiliate Codes"
                  content={
                    <div className="flex flex-col gap-4">
                      {data.affiliateCodes.map((affiliateCode) => (
                        <NewCard
                          key={affiliateCode.id}
                          content={
                            <div className="flex justify-between items-center p-4 gap-4">
                              <div>
                                <h3 className="font-semibold">
                                  Code: {affiliateCode.code}
                                </h3>
                                <p className="text-sm text-zinc-500">
                                  Created: {formatDate(Date.now())}
                                </p>
                                <p className="text-sm text-zinc-500">
                                  Visits: {affiliateCode.usesCount}
                                </p>
                                <p className="text-sm text-zinc-500">
                                  Actions:{" "}
                                  {affiliateCode.affiliateStatuses.length}
                                </p>
                                <p className="text-sm text-zinc-500">
                                  Rewards: {getTotalRewards(affiliateCode)}
                                </p>
                              </div>
                              <div className="text-right">
                                {/* {(() => {
                                  const latestStatus = getLatestStatus(
                                    affiliateCode.affiliateStatuses
                                  );
                                  if (latestStatus) {
                                    const status =
                                      statusMessages[latestStatus.status];
                                    return (
                                      <div className="flex items-center justify-end mb-2">
                                        {status.icon}
                                        <span className="ml-2 text-sm font-medium">
                                          {status.message}
                                        </span>
                                      </div>
                                    );
                                  }
                                  return null;
                                })()} */}
                              </div>
                            </div>
                          }
                        />
                      ))}
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
}

import React, { useState, useEffect, useContext } from 'react'
import { MapContext } from '..'
import ServicableAreaApi from '../../../../API/ServicableAreaApi'
import CreateServicableAreaReq from '../../../../Requests/ServicableArea/CreateServicableAreaReq'
import Swal from 'sweetalert2'
import { Modal } from '@mui/material'
import { UserContext } from '../../../../App'
import { IoCloseCircle } from 'react-icons/io5'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style, Text } from 'ol/style'
import AreaLabelSelector from './../../../../components/AreaLabelSelector'
import EditServicableArea from './EditServicableArea'
import CreateAreaLabel from './CreateAreaLabel'

const SaveServicableArea = () => {

    const map = useContext(MapContext)
    const user = useContext(UserContext)

    const [areaName, setAreaName] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openLabelModal, setOpenLabelModal] = useState(false)


    const [label, setLabel] = useState(null)
    const [labelId, setLabelId] = useState(null)

    async function saveServicableArea() {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "We are saving the servicable area",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })

        
        if(!areaName){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter a name for the area",
                showConfirmButton: true,
                showCancelButton: false,

            })
            return
        }

        if(!label){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a label for the area",
                showConfirmButton: true,
                showCancelButton: false,
          
            })
            return
        }

        const req = new CreateServicableAreaReq()
        req.coordinates = map.searchArea
        req.name = areaName
        req.color = label.color
        req.createdById = user.id
        req.notes = ""
        req.salesOrgId = 1 // TODO: Change this so that salesOrgId is nullable
        req.status = "Active";
        req.servicableAreaType = "Tag"
        req.areaLabelId = labelId
        const res = await ServicableAreaApi.CreateServicableArea(req)
        if (res.status.success) {

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Servicable Area Saved Successfully",
                showConfirmButton: true,
                showCancelButton: false,
                willClose: () => {
                    // map.clearSearchArea()
                }
            })

            // deletePolygon()
            map.shouldReRenderServicableAreas.current = false
            map.setServicableAreas((prev) => {
                return [...prev, {
                    latlngs: res.data.coordinates.map(
                        (point) => {
                            return [point.latitude, point.longitude]
                        }
                    ),
                    color: res.data.color,
                    id: res.data.id,
                    name: res.data.name,
                    fiberCompanyId: res.data.fiberCompanyId,
                    salesOrgId: res.data.salesOrgId,
                    type: res.data.servicableAreaType,
                    label: label,
                    labelId: labelId,
                    createdAt: res.data.createdAt
                }]
            })

            // chnage the style of the polygon
            map.searchAreaFeature[0].set("s_id", res.data.id)
            map.searchAreaFeature[0].set("name", areaName)
            map.searchAreaFeature[0].set("color", label.color)
            map.searchAreaFeature[0].set("label", label.label)
            map.searchAreaFeature[0].set("label_id", labelId)
            map.searchAreaFeature[0].set("createdById", user.id)
            map.searchAreaFeature[0].setStyle([
                new Style({
                    stroke: new Stroke({
                        color: label.color,
                        width: 1,
                    }),
                    fill: new Fill({
                        color: makeTransparent(label.color, 0.15),
                    }),
                }),
                new Style({
                    text: new Text({
                        text: areaName ? areaName : "",
                        fill: new Fill({
                            color: "#fff",
                        }),
                        font: "bold 16px Arial",
                        offsetY: -10,
                    }),
                }),
            ])

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res.status.message,
                showConfirmButton: true,
                showCancelButton: false
            })
        }

    }

    function makeTransparent(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }




    const deletePolygon = () => {
        if (map.searchAreaFeature.length > 0) {
            map.searchAreaFeature.forEach(function (feature) {
                var selectedGeometry = feature.getGeometry(); // Assuming only one feature is selected
                map.mapObject.getLayers().getArray().forEach(layer => {
                    const source = layer.getSource()
                    if (source instanceof VectorSource)
                        source.forEachFeature(function (feature) {
                            var featureGeometry = feature.getGeometry();
                            if (featureGeometry === selectedGeometry) {
                                layer.getSource().removeFeature(feature);
                                map.setSearchAreaFeature([])
                            }
                        });
                });
            })
            map.setUsingLight(true);
            map.setFiberHouses(
                (prev) => {
                    return prev.map(fiberHouse => {
                        return { ...fiberHouse, assigned: null, selected: false, remove: false }
                    })
                }

            )
        }
    }




    const saveAreaModal = () => {

        return (
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div style={{ width: "min(500px,80%)" }} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  bg-white p-4 rounded-lg'>
                    <div className='flex flex-col p-4 pt-0 pb-0 gap-4 justify-center items-center '>
                        <IoCloseCircle onClick={() => {
                            setOpenModal(false)
                        }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                        <img alt="Logo" src={'/images/nav/nav-logo.png'} className='object-fit' />
                        <h1 className='text-2xl font-bold'>Create Area</h1>
                        <input
                            className='w-full p-2 border border-gray-300 rounded-lg outline-none'
                            type="text" placeholder="Enter Area Name" value={areaName} onChange={(e) => setAreaName(e.target.value)} />
                        {/* <div className='grid grid-cols-2 gap-4 w-full p-2 border border-gray-300 rounded-lg'>
                            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                            <input type="text" className=' outline-none' value={color} onChange={(e) => setColor(e.target.value)} />
                        </div> */}
                        <AreaLabelSelector setLabelId={setLabelId} placeholder='Select a Label' setLabelObject={setLabel} className='w-full' />
                        <p className='text-sm text-gray-500'>Don't see the label you want? <span className='text-blue-500 cursor-pointer' onClick={() => setOpenLabelModal(true)}>Create Label</span></p>
                        <button
                            className='bg-blue-500 text-white p-2 rounded-lg w-full'
                            onClick={saveServicableArea}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }











    return (
        <>
            {
                (map.searchArea && map.searchAreaFeature?.length > 0 && map.searchAreaFeature[0].get("s_id")) ?
                    (   (map.searchAreaFeature[0].get("createdById") === user.id || user.userType === "SuperAdmin") &&
                        <button onClick={() => {
                            setOpenEditModal(true)
                        }} className='px-4 text-sm py-2 rounded-lg bg-blue-500 text-white w-full ml-auto'>Edit Area</button>)
                    : <button className='px-4 text-sm py-2 rounded-lg bg-blue-500 text-white w-full ml-auto' onClick={() => {
                        setOpenModal(true)
                    }}>Create Area</button>
            }
            {
                saveAreaModal()
            }
            <EditServicableArea open={openEditModal} setOpen={setOpenEditModal} area={{
                id: map.searchAreaFeature[0]?.get("s_id"),
                name: map.searchAreaFeature[0]?.get("name"),
                label: {
                    color: map.searchAreaFeature[0]?.get("color"),
                    label: map.searchAreaFeature[0]?.get("label"),
                    id: map.searchAreaFeature[0]?.get("label_id")
                },
                labelId: map.searchAreaFeature[0]?.get("label_id")
            }} />

            <CreateAreaLabel open={openLabelModal} setOpen={setOpenLabelModal} setPrevious={setOpenModal} />

        </>

    )
}

export default SaveServicableArea
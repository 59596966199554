import { Modal } from "@mui/material";
import React, { useContext, useEffect } from "react";
import FreeInstallForm from "./components/FreeInstallForm";
import "./DigitalToolBelt.scss";
import RescheduleForm from "./components/RescheduleForm";
import CancelForm from "./components/CancelForm";
import IssueTicket from "./components/IssueTicket";
import ReviewForm from "./components/ReviewForm";
import ServiceUpDown from "./components/ServiceUpDown";
import { IoMdCloseCircle } from "react-icons/io";
import ShceduleForm from "./components/ShceduleForm";
import { IoCloseCircle } from "react-icons/io5";
import Refer from "./components/Refer";
import SearchCustomerReq from "../../Requests/Customer/SearchCustomerReq";
import CustomerApi from "../../API/CustomerApi";
import { UserContext } from "../../App";
import authorizedLightCurve from "./../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png";
import authorizedFastwyre from "./../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png";
import logo from "./../../Views/TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png";

const DigitalToolBelt = ({ open, setOpen, lead }) => {
  const [selectctedTab, setSelectedTab] = React.useState(
    !lead?.fidiumCustomer ? "Install" : "Reschedule"
  );
  console.log(lead);
  const [fidiumCustomer, setFidiumCustomer] = React.useState(
    lead?.fidiumCustomer
  );
  const [logoState, setLogoState] = React.useState(
    lead?.fidiumCustomer?.fiberCompanyId
      ? lead?.fidiumCustomer?.fiberCompanyId
      : null
  );
  const user = useContext(UserContext);

  useEffect(() => {
    console.log(logoState);
  }, [logoState]);

  useEffect(() => {
    if (!lead?.fidiumCustomer) {
      setSelectedTab("Install");
    } else if (lead?.fidiumCustomer) {
      setSelectedTab("Reschedule");
    }
  }, [lead]);
  if (!lead) {
    return null;
  }
  return (
    <Modal
      sx={{
        outline: "none",
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className="digital_tool_belt_container">
        {/* <p style={{
                    cursor: 'pointer',
                    textAlign: 'right',
                    // color: '#2761D0',
                    // fontSize: '14px',
                    // fontWeight: 'bold',
                    marginLeft: 'auto'
                }} >
                </p> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <IoMdCloseCircle
            style={{
              cursor: "pointer",
              textAlign: "right",
              marginLeft: "auto",
            }}
            onClick={() => setOpen(false)}
            color="#2761D0"
            size={30}
          />
        </div>
        {logoState === null && (
          <img
            src={logo}
            alt="att_logo"
            style={{
              height: "100px",
              margin: "auto",
            }}
          />
        )}
        {logoState == 2 && <img src={authorizedLightCurve} alt="att_logo" />}
        {logoState == 9 && <img src={authorizedFastwyre} alt="att_logo" />}
        <h1>Digital Tool Belt</h1>
        <div
          style={{
            justifyContent: lead.fidiumCustomer ? "space-between" : "center",
          }}
          className="icons_list"
        >
          {/* {
                        !lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Install')}>
                            <img src={selectctedTab === "Install" ? '/images/digital_tool_belt/light_b.svg' : '/images/digital_tool_belt/light.svg'} alt='light' />
                        </div>
                    } */}

          {lead.fidiumCustomer && (
            <div className="icon" onClick={() => setSelectedTab("Reschedule")}>
              <img
                src={
                  selectctedTab === "Reschedule"
                    ? "/images/digital_tool_belt/reschedule_b.svg"
                    : "/images/digital_tool_belt/reschedule.svg"
                }
                alt="medium"
              />
            </div>
          )}
          {lead.fidiumCustomer && (
            <div className="icon" onClick={() => setSelectedTab("Cancel")}>
              <img
                src={
                  selectctedTab === "Cancel"
                    ? "/images/digital_tool_belt/cancel_b.svg"
                    : "/images/digital_tool_belt/cancel.svg"
                }
                alt="medium"
              />
            </div>
          )}
          {lead.fidiumCustomer && (
            <div className="icon" onClick={() => setSelectedTab("Ticket")}>
              <img
                src={
                  selectctedTab === "Ticket"
                    ? "/images/digital_tool_belt/ticket_b.svg"
                    : "/images/digital_tool_belt/ticket.svg"
                }
                alt="medium"
              />
            </div>
          )}
          {lead.fidiumCustomer && user.userType === "SuperAdmin" && (
            <div className="icon" onClick={() => setSelectedTab("Refer")}>
              <img
                src={
                  selectctedTab === "Refer"
                    ? "/images/digital_tool_belt/refer_b.svg"
                    : "/images/digital_tool_belt/refer.svg"
                }
                alt="medium"
              />
            </div>
          )}
          {lead.fidiumCustomer &&
            lead.fidiumCustomer.accountStatus === "Completed" && (
              <div className="icon" onClick={() => setSelectedTab("Review")}>
                <img
                  src={
                    selectctedTab === "Review"
                      ? "/images/digital_tool_belt/review_b.svg"
                      : "/images/digital_tool_belt/review.svg"
                  }
                  alt="medium"
                />
              </div>
            )}
          {/* {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Arrow')}>
                            <img src={selectctedTab === "Arrow" ? '/images/digital_tool_belt/arrow_b.svg' : '/images/digital_tool_belt/blue_arrow.svg'} alt='medium' />
                        </div>
                    } */}
        </div>
        {selectctedTab === "Install" && (
          <FreeInstallForm setLogo={setLogoState} lead={lead} />
        )}
        {selectctedTab === "Reschedule" &&
          lead?.fidiumCustomer?.installDateTime && (
            <RescheduleForm lead={lead} />
          )}
        {selectctedTab === "Reschedule" &&
          !lead?.fidiumCustomer?.installDateTime && (
            <ShceduleForm lead={lead} />
          )}
        {selectctedTab === "Cancel" && <CancelForm lead={lead} />}
        {selectctedTab === "Ticket" && <IssueTicket lead={lead} />}
        {selectctedTab === "Refer" && <Refer lead={lead} />}
        {selectctedTab === "Review" && (
          <ReviewForm disabled={true} lead={lead} />
        )}
        {selectctedTab === "Arrow" && <ServiceUpDown lead={lead} />}
      </div>
    </Modal>
  );
};

export default DigitalToolBelt;

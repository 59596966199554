import { useState } from "react";
import {
  Star,
  Zap,
  Heart,
  X,
  Calendar,
  Ticket,
  BadgePercent,
  NotebookPen,
  CalendarCheck2,
} from "lucide-react";
import { RiAdvertisementLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { AiOutlineSchedule } from "react-icons/ai";
import { MdSchedule } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";

function ActionCard({ icon, label, isSelected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`flex h-32 w-32 shrink-0 flex-col items-center justify-center gap-3 rounded-xl border border-gray-200 p-4 transition-colors hover:border-gray-300 focus:outline-none
        ${
          isSelected ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-white"
        }`}
    >
      <div className={`h-8 w-8 ${isSelected ? "text-white" : "text-gray-600"}`}>
        {icon}
      </div>
      <span className="text-center text-sm font-medium">{label}</span>
    </button>
  );
}

export default function DigitalActions({
  selectedAction,
  selectedContacts,
  setSelectedAction,
  mode,
}) {
  const CustomerActions = [
    {
      id: 1,
      icon: <Star className="h-full w-full" />,
      label: "Request 5-Star Reviews",
    },
    // {
    //   id: 2,
    //   icon: <Zap className="h-full w-full" />,
    //   label: "Ask for Referral Business",
    // },
    { id: 3, icon: <X className="h-full w-full" />, label: "Cancel Order" },
    {
      id: 4,
      icon: <Calendar className="h-full w-full" />,
      label: "Schedule Install",
    },
    {
      id: 5,
      icon: <Ticket className="h-full w-full" />,
      label: "Issue Ticket",
    },
    {
      id: 6,
      icon: <BadgePercent className="h-full w-full" />,
      label: "Send Offer",
    },
    {
      id: 7,
      icon: <NotebookPen className="h-full w-full" />,
      label: "Reserve Free Install",
    },
    {
      id: 8,
      icon: <CalendarCheck2 className="h-full w-full" />,
      label: "Schedule Follow-Up",
    },
  ];
  const actions = [
    // {
    //   id: 1,
    //   icon: <Star className="h-full w-full" />,
    //   label: "Request 5-Star Reviews",
    // },
    // {
    //   id: 2,
    //   icon: <Zap className="h-full w-full" />,
    //   label: "Ask for Referral Business",
    // },
    // { id: 3, icon: <Heart className="h-full w-full" />, label: "Send Card" },
    {
      id: 1,
      icon: (
        <RiAdvertisementLine className="h-full w-full">AD</RiAdvertisementLine>
      ),
      label: "Send AD",
    },
    {
      id: 2,
      icon: (
        <AiOutlineSchedule className="h-full w-full">
          Schedule Interview
        </AiOutlineSchedule>
      ),
      label: "Schedule Interview",
    },
    {
      id: 3,
      icon: (
        <MdSchedule className="h-full w-full">
          Schedule Consultation
        </MdSchedule>
      ),
      label: "Schedule Consultation",
    },
    {
      id: 4,
      icon: (
        <BiMailSend className="h-full w-full">
          Send Proposal
        </BiMailSend>
      ),
      label: "Send Proposal",
    },
    // { id: 5, icon: <X className="h-full w-full" />, label: "Cancel Order" },
    // {
    //   id: 6,
    //   icon: <Calendar className="h-full w-full" />,
    //   label: "Reschedule Service",
    // },
    // {
    //   id: 7,
    //   icon: <Ticket className="h-full w-full" />,
    //   label: "Issue Ticket",
    // },
  ];

  return (
    <div className="p-6 space-y-6 flex items-center justify-center">
      <div
        className="flex gap-4 overflow-x-auto scrollbar-hide p-2"
        style={{ scrollbarWidth: "none" }}
      >
        {mode === "Sales"
          ? CustomerActions.map((action) => (
              <ActionCard
                key={action.label}
                icon={action.icon}
                label={action.label}
                isSelected={selectedAction.id === action.id}
                onClick={() => {
                  if (selectedContacts?.length > 0) {
                    const singleOptionActions = [3, 4, 5, 6, 7, 8];
                    if (
                      selectedContacts?.length > 1 &&
                      singleOptionActions.includes(action.id)
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Please select only one contact to proceed",
                      });
                      setSelectedAction({ id: 0, label: "" });
                    } else {
                      setSelectedAction({ id: action.id, label: action.label });
                    }
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "Please select a contact to proceed",
                    });
                    setSelectedAction({ id: 0, label: "" });
                    return;
                  }
                }}
              />
            ))
          : actions.map((action) => (
              <ActionCard
                key={action.label}
                icon={action.icon}
                label={action.label}
                isSelected={selectedAction.id === action.id}
                onClick={() => {
                  if (selectedContacts?.length > 0) {
                    const singleOptionActions = [2];
                    if (
                      selectedContacts?.length > 1 &&
                      singleOptionActions.includes(action.id)
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Please select only one contact to proceed",
                      });
                      setSelectedAction({ id: 0, label: "" });
                    } else {
                      setSelectedAction({ id: action.id, label: action.label });
                    }
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "Please select a contact to proceed",
                    });
                    setSelectedAction({ id: 0, label: "" });
                    return;
                  }
                }}
              />
            ))}
      </div>
    </div>
  );
}

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../../../../App";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Rating,
} from "@mui/material";
// import { IoLocationSharp } from "react-icons/io5";
// import { FaPhone } from "react-icons/fa6";
import {
  MdDelete,
  MdOutlineEmail,
  MdOutlineLocalPhone,
  MdOutlineMessage,
} from "react-icons/md";
// import { FaRegMessage } from "react-icons/fa6";
import { FaRegSave, FaWhatsapp } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
// import freeMonth from './../../../TeamAdmin/MapPage/Images/payItForward.png'
import QRCode from "qrcode.react";
import Swal from "sweetalert2";
import ContactButtons from "../../../../Services/ContactButtons";
import { useNavigate, useParams } from "react-router-dom";
// import ReviewContainer from './ReviewContainer';
import { GiWireframeGlobe } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import SearchFiberPlanReq from "../../../../Requests/FiberPlan/SearchFiberPlansReq";
import FiberPlanApi from "../../../../API/FiberPlanApi";
import LightCustomerModal from "./LightCustomerModal";
import FeedbackForm from "../../../Customer/AddFeedback/FeedbackForm";
import { SwiperSlide, Swiper } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { LuFileVideo } from "react-icons/lu";
import StringIntReq from "../../../../Requests/StringIntReq";
import UsersApi from "../../../../API/UsersApi";
import { v4 as uuidv4 } from "uuid";
import { uploadFileToFirebase } from "../../../../Services/DisplayFile";
import ReviewContainer from "./ReviewContainer";
import CustomerFeedbackApi from "../../../../API/CustomerFeedbackApi";
import SearchCustomerFeedbackReq from "../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq";
import FiberCompanySelector from "../../../../components/FiberCompanySelector";
import { deleteObject, getStorage, ref } from "firebase/storage";
import ReactPlayer from "react-player";
import thumbnail from "../../../../assets/images/bruce-mars.jpg";
import OfferApi from "../../../../API/OfferApi";
const MobileVersionBusinessCard = ({
  salesRepIsUser,
  salesRep,
  fiberCompany,
  isTab = false,
  displayOffer = true,
  reviewButton = true,
  displayQr = true,
  displayContactButtons = true,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { id } = useParams();
  // const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const [avgRating, setAvgRating] = useState(5);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const user = useContext(UserContext);
  const contactButtons = new ContactButtons(user);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [openLightModal, setOpenLightModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [userVideos, setUserVideos] = useState([]);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
  const [fiberCompanyId, setFiberCompanyId] = useState(
    fiberCompany
      ? fiberCompany
      : salesRep.fiberCompanyId
      ? salesRep.fiberCompanyId
      : null
  );
  const [bulletPoints, setBulletPoints] = useState(null);
  const [promo, setPromo] = useState(null);
  const storage = getStorage();
  const [thumbnails, setThumbnails] = useState({});

  //   const [profileThumbnail, setProfileThumbnail] = useState(null);
  //   const profileVideoUrl = salesRep?.profileVideo
  //     ? salesRep.profileVideo.url
  //     : "https://placehold.co/1920x1080.mp4";

  //   console.log(salesRep);
  //   const captureThumbnail = (videoUrl) => {
  //     return new Promise((resolve) => {
  //       const video = document.createElement("video");
  //       video.src = videoUrl;
  //       video.crossOrigin = "anonymous";
  //       video.currentTime = 1; // Capture frame at 1 second

  //       video.onloadeddata = () => {
  //         const canvas = document.createElement("canvas");
  //         const ctx = canvas.getContext("2d");

  //         canvas.width = video.videoWidth;
  //         canvas.height = video.videoHeight;
  //         ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  //         const imageUrl = canvas.toDataURL("image/png"); // Convert frame to image URL
  //         resolve(imageUrl);
  //       };
  //     });
  //   };

  // Generate Thumbnails for All Videos
  //   useEffect(() => {
  //     const generateThumbnails = async () => {
  //       let thumbnailMap = {};

  //       for (let video of userVideos) {
  //         const thumbnail = await captureThumbnail(video.url);
  //         thumbnailMap[video.url] = thumbnail; // Store using video URL as key
  //       }

  //       setThumbnails(thumbnailMap);
  //     };

  //     if (userVideos.length > 0) {
  //       generateThumbnails();
  //     }
  //   }, [userVideos]);

  // Generate Thumbnail for Profile Video
  //   useEffect(() => {
  //     const generateProfileThumbnail = async () => {
  //       const thumbnail = await captureThumbnail(profileVideoUrl);
  //       setProfileThumbnail(thumbnail);
  //     };

  //     generateProfileThumbnail();
  //   }, [profileVideoUrl]);

  //   useEffect(() => {
  //     if (videoRef.current) {
  //       videoRef.current.load(() => {});
  //       // play the video for 1 second to show the first frame
  //     }
  //   }, [videoRef.current]);

  useEffect(() => {
    console.log(openReviewModal);
  }, [openReviewModal]);

  useEffect(() => {
    console.log(fiberCompanyId);
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
      // setScreenHeight(window.innerHeight)
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
        // setScreenHeight(window.innerHeight)
      });
    };
  }, []);

  const deleteVideo = async (videoId) => {
    Swal.fire({
      icon: "info",
      title: "Deleting Video",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const videoToDelete = userVideos.find((video) => video.id === videoId);
      if (!videoToDelete) throw new Error("Video Not Found");

      const storageRef = ref(storage, videoToDelete.path);
      await deleteObject(storageRef);

      let req = new StringIntReq();
      req.int = videoId;
      const res = await UsersApi.DeleteUserBizCardVideo(req);

      if (res.status.success) {
        Swal.fire({
          icon: "success",
          title: "Video Deleted",
          text: "Video has been deleted successfully.",
          didOpen: () => {
            Swal.hideLoading();
          },
          showConfirmButton: true,
        });
        setUserVideos((prevVideos) =>
          prevVideos.filter((video) => video.id !== videoId)
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.status.message,
          didOpen: () => {
            Swal.hideLoading();
          },
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong",
        showCloseButton: true,
      });
    }
  };
  const handleOpenLightModal = () => {
    if (fiberCompanyId === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a Fiber Company",
        showCloseButton: true,
      });
      return;
    }
    setOpenLightModal(true);
  };

  const getPlans = async () => {
    const req = new StringIntReq();
    req.int = fiberCompanyId;
    const res = await FiberPlanApi.GetFiberPlanOffers(req);

    if (res.status.success) {
      res.data.fiberPlans =
        fiberCompanyId == 2
          ? salesRep?.userType === "SuperAdmin" ||
            salesRep?.id === 103 ||
            salesRep?.id === 2305 ||
            salesRep?.id === 167
            ? res.data.fiberPlans.filter((plan) => plan.name.includes("FTTH"))
            : salesRep?.id === 299
            ? res.data.fiberPlans.filter((plan) =>
                plan.name.includes("HFC PLUS")
              )
            : res.data.fiberPlans.filter(
                (plan) =>
                  !plan.name.includes("FTTH") && !plan.name.includes("HFC PLUS")
              )
          : 
          fiberCompanyId === 13?
          res.data.fiberPlans.filter((plan) => plan.name.includes("FTTH"))
          :
          res.data.fiberPlans;
      const fiberPlans = res.data.fiberPlans;
      const bulletPoints = res.data.bulletPoints;
      const promo = res.data.promos;

      setPlans(fiberPlans);
      setSelectedPlan(fiberPlans[0]);
      setBulletPoints(bulletPoints);
      setPromo(promo);
    }
  };

  const getAvgRating = async () => {
    const req = new SearchCustomerFeedbackReq();
    req.pagingParams.pageNumber = 1;
    req.pagingParams.pageSize = 1000;
    req.feedbackType = null;
    const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
    if (res?.status?.success) {
      let totalRating = 0;
      res.data.list.forEach((review) => {
        totalRating += review.rating;
      });
      setAvgRating(totalRating / res.data.totalItems);
      setReviewCount(res.data.totalItems);
    }
  };

  useEffect(() => {
    getAvgRating();
  }, []);
  useEffect(() => {
    if (fiberCompany) {
      setFiberCompanyId(fiberCompany);
    } else
      setFiberCompanyId(
        salesRep.fiberCompanyId ? salesRep.fiberCompanyId : null
      );
  }, [fiberCompany]);

  useEffect(() => {
    if (fiberCompanyId != null) {
      getPlans();
      console.log(fiberCompanyId);
    }
  }, [fiberCompanyId]);

  const copyUrlToClipboard = () => {
    // if (salesRepIsUser) {
    //     navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + user.id+(fiberCompanyId ? "/"+fiberCompanyId : ""))
    // } else {
    //     navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + salesRep.id+(fiberCompanyId ? "/"+fiberCompanyId : ""))
    // }
    // Swal.fire({
    //     title: "Link Copied",
    //     icon: "success",
    //     showConfirmButton: false,
    //     timer: 1500,
    //     toast: true,
    //     position: "bottom-right",
    //     timerProgressBar: true,
    // })

    let p = promo?.split("\n")?.join(" & ");

    let pl = plans
      ?.map((plan) => {
        return `${getPlanSpeed(plan)}`;
      })
      .join(" OR ");

    if (salesRep.id === 157) {
      //         Hey 👋

      // Shasta here with Lightning OS, LLC, Play it Forward, LLC & Tel-One Solutions & SalesFox!

      // Click the link to save my contact info on your device.

      // I look forward to doing business with you!

      // Best,

      // Shasta Weishampel
      // Company Owner | CEO
      navigator.share({
        title: "Business Card",
        text: `Hey 👋 \n\n${
          salesRep.firstName + " " + salesRep.lastName
        } here with Lightning OS, LLC, Play it Forward, LLC & Tel-One Solutions & SalesFox! \n\nClick the link to save my contact info on your device.\n\n${
          window.location.origin +
          "/digital-business-center/" +
          salesRep.id +
          (fiberCompanyId ? "/" + fiberCompanyId : "")
        } \n\nI look forward to doing business with you! \n\nBest, \n\nShasta Weishampel`,
        // url: window.location.origin + "/digital-business-center/" + salesRep.id + (fiberCompanyId ? "/" + fiberCompanyId : "")
      });
    } else {
      navigator.share({
        title: "Fiber Business Card",
        text:
          `Hey 👋 \n\nContact ${
            salesRep.firstName + " " + salesRep.lastName
          } to receive ${p} for either ${pl}. \n\n ` +
          window.location.origin +
          "/digital-business-center/" +
          salesRep.id +
          (fiberCompanyId ? "/" + fiberCompanyId : ""),
      });
    }
  };

  const buttonClicked = async (text) => {
    if (text === "email") {
      contactButtons.email(salesRep.email);
    } else if (text === "message") {
      contactButtons.sms(salesRep.phoneNumber);
    } else if (text === "Wa") {
      contactButtons.whatsapp(salesRep.phoneNumber);
    } else if (text === "Call") {
      contactButtons.call(salesRep.phoneNumber);
    }
  };

  function getQrSize() {
    if (screenWidth > 1400) {
      return 300;
    } else if (screenWidth >= 1000) {
      return 250;
    } else if (screenWidth >= 490) {
      return 180;
    } else {
      return 200;
    }
  }

  const getAddress = () => {
    const { city, state, country } = salesRep;
    let addressParts = [];

    if (city) addressParts.push(city);
    if (state) addressParts.push(state);
    if (country) addressParts.push(country);

    return addressParts.join(", ");
  };

  function getPlanSpeed(plan) {
    if (plan.speed < 1) {
      return plan.speed * 1000 + " Mbps";
    } else {
      return plan.speed + " Gig";
    }
  }

  const uploadFile = async (e) => {
    Swal.fire({
      title: "Uploading Video",
      icon: "info",
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const uniqueId = uuidv4();
    const cloudFile = await uploadFileToFirebase(
      e.target.files[0],
      `profileVideos/${user.id}/${uniqueId}`
    );
    cloudFile.videoUserId = user.id;
    const res = await UsersApi.AddUserVideoForBizCard(cloudFile);
    if (res?.status?.success) {
      Swal.fire({
        title: "Video Uploaded",
        icon: "success",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
      searchUserVideos();
    } else {
      Swal.fire({
        title: "Error Uploading Video",
        icon: "error",
        showConfirmButton: false,
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  };

  const searchUserVideos = async () => {
    const req = new StringIntReq();
    req.int = salesRep.id;
    const res = await UsersApi.SearchUserVideosForBizCard(req);
    if (res.status.success) {
      setUserVideos(res.data);
    } else {
      console.log(res.status.message);
    }
  };

  const downloadQrCode = () => {
    const canvas = document.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    let fileName =
      salesRep.firstName + " " + salesRep.lastName + " QR Code.png";
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    searchUserVideos();
  }, []);

  const createBizCardOffer = async () => {
    Swal.fire({
      icon: "info",
      title: "Opening Offer",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const req = {
      salesRepId: salesRep.id,
      fiberCompanyId: fiberCompanyId,
      offerType: "BusinessCard",
    };

    const res = await OfferApi.CreateBusinessCardOffer(req);

    if (res.status.success) {
      Swal.fire({
        icon: "success",
        title: "Offer Created",
        text: "Offer has been created successfully.",
        timer: 100,
        didOpen: () => {
          Swal.hideLoading();
        },
        showConfirmButton: true,
      });
      navigate(`/fiber-offer/${res.data.offerGuid}`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.status.message,
        didOpen: () => {
          Swal.hideLoading();
        },
        showConfirmButton: true,
      });
    }
  };

  const handleDownloadVCF = () => {
    const structuredName = [
      salesRep.lastName || "",
      salesRep.firstName || "",
      "", // Additional names
      "", // Honorific prefixes
      "", // Honorific suffixes
    ].join(";");
    // Create vCard content
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:${structuredName}
FN:${salesRep.firstName} ${salesRep.lastName}
ORG:Lightning OS
TEL:${salesRep.phoneNumber}
EMAIL:${salesRep.email}
ADR:;;${getAddress()}
NOTE:${
      salesRep.userType === "SalesRep"
        ? "Fiber Specialist"
        : salesRep.id === 157
        ? "Owner | CEO"
        : "Area Manager"
    }
END:VCARD
    `.trim();

    // Create Blob and download
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${salesRep.firstName}.vcf`;
    link.click();
    URL.revokeObjectURL(url);
  };

  //   const handleSaveContact = () => {
  //     const contact = {
  //       name: `${salesRep.firstName} ${salesRep.lastName}`,
  //       phone: salesRep.phoneNumber,
  //       email: salesRep.email,
  //     };

  //     const vcard = `BEGIN:VCARD
  // VERSION:4.0
  // FN:${contact.name}
  // TEL;TYPE=work,voice:${contact.phone}
  // EMAIL:${contact.email}
  // END:VCARD`;

  //     const blob = new Blob([vcard], { type: "text/vcard" });
  //     const url = URL.createObjectURL(blob);

  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${contact.name}.vcf`;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  //   };

  return (
    <>
      <LightCustomerModal
        lightOpenModal={openLightModal}
        setLightOpenModal={setOpenLightModal}
        salesRepId={salesRep?.id}
        fiberCompanyId={fiberCompanyId}
        fiberPLanId={selectedPlan?.id}
        salesOrgId={salesRep?.salesOrgId}
      />
      <Modal
        open={openReviewModal}
        setOpen={setOpenReviewModal}
        onClose={() => setOpenReviewModal(false)}
      >
        <div className="review_mobile_modal">
          <button
            className="close-button"
            onClick={() => setOpenReviewModal(false)}
          >
            ✖
          </button>
          <ReviewContainer writtenOnly={true} />
        </div>
      </Modal>
      <div className="w-full flex justify-end">
        <div className="w-full flex items-center justify-between">
          <div className="">
            <div className="logo">
              <img src="/images/card/nav-logo.png" alt="logo" />
            </div>
            <div className="rating-section">
              {/* Rating and Average Rating in one row */}
              <div className="rating-row" style={{ marginLeft: "3rem" }}>
                <span className="avg-rating">{avgRating.toFixed(1)}</span>
                <Rating
                  name="read-only"
                  size="medium"
                  precision={0.5}
                  value={avgRating}
                  readOnly
                />
                {/* <span className="avg-rating">({reviewCount})</span> */}
              </div>
              {/* Reviews count below the rating */}
              {/* <span className="reviews-count" onClick={() => setOpenReviewModal(true)}>
                            {reviewCount} reviews
                        </span> */}
            </div>
          </div>
          <button
            className="outline-none -mt-6"
            onClick={() => handleDownloadVCF()}
          >
            <FaRegSave size={25} color="#81848A" />
          </button>
        </div>
      </div>
      <div style={{ marginBottom: "0rem" }} className="header relative">
        <div className="info">
          <h3>{salesRep?.firstName + " " + salesRep?.lastName}</h3>
          <p>
            {salesRep.userType === "SalesRep"
              ? salesRep.id === 2104 ||
                salesRep.id === 2103 ||
                salesRep.id === 2102
                ? "Regional Sales Manager"
                : "Fiber Specialist"
              : salesRep.userType === "SalesOrgAdmin"
              ? "Area Manager"
              : salesRep.userType === "SuperAdmin"
              ? salesRep.id === 157
                ? "Owner | CEO"
                : "Area Manager"
              : ""}
          </p>
          <div className="infoAndQrContainer">
            <div style={{ gap: "0.5rem" }} className="user-contact">
              <div className="contact-item">
                <GiWireframeGlobe
                  color="black"
                  size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 20}
                />
                <p>{getAddress()}</p>
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  contactButtons.call(salesRep?.phoneNumber);
                }}
                className="contact-item"
              >
                <MdOutlineLocalPhone
                  color="black"
                  size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 20}
                />
                <p>{salesRep?.phoneNumber}</p>
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  contactButtons.email(salesRep?.email);
                }}
                className="contact-item"
              >
                <MdOutlineEmail
                  color="black"
                  size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 20}
                />
                <p>{salesRep?.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid black",
          }}
          className="image"
        >
          <img
            src={
              salesRep?.profileImage
                ? salesRep.profileImage.url
                : "/images/Avatar/profile.jpg"
            }
            style={{
              objectFit: salesRep?.profileImage ? "cover" : "cover",
            }}
            alt="Profile"
          />
        </div>
      </div>

      {(salesRep?.profileVideo ||
        userVideos?.length > 0 ||
        (user.id === salesRep.id && salesRep.id !== 157)) &&
        !isTab && (
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="video"
            style={{
              width: "100%",
            }}
          >
            {salesRep?.profileVideo && (
              <SwiperSlide>
                {/* <video ref={videoRef} style={{
                                backgroundColor: "black"
                            }} type="video/mp4" playsInline controls src={salesRep?.profileVideo ? salesRep.profileVideo.url : "https://placehold.co/1920x1080.mp4"} alt="video" />
                         */}
                <video
                  className="video-player"
                  preload="metadata"
                  controls
                  style={{ height: "100%", width: "100%" }}
                >
                  <source
                    src={`${salesRep?.profileVideo.url}#t=0.1`}
                    type="video/mp4"
                  />
                </video>
              </SwiperSlide>
            )}
            {userVideos.map((video, index) => {
              return (
                console.log(video),
                (
                  <SwiperSlide key={index}>
                    <video
                      className="video-player"
                      preload="metadata"
                      controls
                      style={{ height: "100%", width: "100%" }}
                    >
                      <source src={`${video.url}#t=0.1`} type="video/mp4" />
                    </video>
                    {salesRepIsUser && (
                      <button
                        style={{
                          position: "absolute",
                          top: "5%",
                          left: "2%",
                          zIndex: 1000,
                        }}
                        className="delete_video_bizcard"
                        onClick={() => {
                          deleteVideo(video.id);
                        }}
                      >
                        <MdDelete color="red" size={30} />
                      </button>
                    )}
                  </SwiperSlide>
                )
              );
            })}
            {user.id === salesRep.id && (
              <SwiperSlide>
                <div
                  style={{
                    backgroundColor: "white",
                  }}
                  className="add_video_bizcard"
                >
                  <label htmlFor="biz_card_video">
                    UPLOAD <LuFileVideo size={30} color="#4FAFCB" />
                  </label>
                  <input
                    onChange={(event) => {
                      uploadFile(event);
                    }}
                    type="file"
                    id="biz_card_video"
                  />
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        )}

      <div className="offerAndQr">
        {displayOffer && (
          <div className="offer_con">
            {!fiberCompany &&
              salesRepIsUser &&
              (user.userType === "SuperAdmin" ? (
                <div style={{ zIndex: 1000, width: "100%" }}>
                  <FiberCompanySelector
                    companiesWithOfferTemplateOnly={true}
                    className="fiber_company_selector"
                    placeholder="Select Fiber Company"
                    setUser={setFiberCompanyId}
                    isBusinessCard={true}
                  />
                </div>
              ) : (
                <div style={{ zIndex: 1000, width: "100%" }}>
                  <FiberCompanySelector
                    SalesRepFiberCompanies={true}
                    salesRep={salesRep}
                    companiesWithOfferTemplateOnly={true}
                    className="fiber_company_selector"
                    placeholder="Select Fiber Company"
                    setUser={setFiberCompanyId}
                    isBusinessCard={true}
                  />
                </div>
              ))}
            <h1
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Limited Time Offer
            </h1>
            {plans.length > 0 ? (
              <div
                style={{
                  gridTemplateColumns: plans.length === 1 ? "1fr" : "",
                }}
                className="banner_top"
              >
                {plans?.map((plan, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedPlan(plan);
                      }}
                      className={`banner_card ${
                        selectedPlan?.id === plan?.id && "active"
                      }`}
                    >
                      {selectedPlan?.id === plan?.id && (
                        <IoIosCheckmarkCircle
                          style={{
                            position: "absolute",
                            top: "-5%",
                            right: "-5%",
                          }}
                          size={40}
                          color="#2761D0"
                        />
                      )}
                      <img
                        style={{
                          height: "30px",
                          marginBottom: "0.2rem",
                        }}
                        src={"data:image/png;base64," + plan.fiberCompany?.logo}
                        alt="fiber company logo"
                      />
                      <h2
                        style={{
                          fontSize: "1.2rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {
                          // plan.speed <h2 1 ?
                          // plan.speed * 1000 + " Mbps"
                          getPlanSpeed(plan)
                          // :
                          // plan.speed + " Gig"
                        }
                      </h2>
                      {/* {
                                                // plan.name === "HFC" && <p>Hybrid Fiber Coaxial</p>
                                                plan?.name?.includes("HFC") && <p>Hybrid Fiber Coaxial</p>
                                            } */}
                      <h4>${plan.price}/mo*</h4>
                    </div>
                  );
                })}
              </div>
            ) : (
              "No Plans Available"
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                backgroundColor: "#fff",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                padding: "1rem",
                borderRadius: "10px",
              }}
            >
              {bulletPoints?.split("\n").map((point, index) => {
                return (
                  <div key={index} className="flex gap-4">
                    <IoIosCheckmarkCircle color="#2761D0" size={20} />
                    <p>{point}</p>
                  </div>
                );
              })}
            </div>
            {plans.length > 0 && (
              <button
                style={{
                  backgroundColor: "#2761D0",
                  color: "white",
                  padding: "0.5rem 1rem",
                  borderRadius: "10px",
                }}
                disabled={plans.length === 0}
                onClick={() => {
                  createBizCardOffer();
                }}
              >
                View Offer Details
              </button>
            )}
          </div>
        )}
        {displayQr && (
          <div className="payitForwardContainer">
            <div>
              <h3>Connect/Share</h3>
              <QRCode
                value={
                  window.location.origin +
                  "/digital-business-center/" +
                  salesRep.id +
                  (fiberCompanyId ? "/" + fiberCompanyId : "")
                }
                size={getQrSize()}
              />
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  downloadQrCode();
                }}
              >
                Download QRCode
              </p>
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyUrlToClipboard();
                }}
              >
                Share
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="mobile_biz_card_review">
        <ReviewContainer salesRepId={salesRep.id} />
      </div>

      {displayContactButtons && (
        <div className="contact_buttons">
          <div
            onClick={() => {
              buttonClicked("email");
            }}
            className="contact_button"
          >
            <MdOutlineEmail color="#84848c" className="icon" />
            <p>Email</p>
          </div>
          <div
            onClick={() => {
              buttonClicked("message");
            }}
            className="contact_button"
          >
            <MdOutlineMessage color="#84848c" className="icon" />
            <p>SMS</p>
          </div>
          <div
            onClick={() => {
              buttonClicked("Wa");
            }}
            className="contact_button"
          >
            <FaWhatsapp color="#84848c" className="icon" />
            <p>WA</p>
          </div>
          <div
            onClick={() => {
              buttonClicked("Call");
            }}
            className="contact_button"
          >
            <IoCallSharp color="#84848c" className="icon" />
            <p>Call</p>
          </div>
        </div>
      )}
      {salesRepIsUser && !isTab && (
        <>
          <button
            onClick={() => {
              navigate("/edit-account-details");
            }}
            className="edit_card_details_button"
          >
            Edit Card Details
          </button>
        </>
      )}
      {!salesRepIsUser && reviewButton && (
        <>
          <button
            onClick={() => setOpenFeedbackForm(true)}
            className="edit_card_details_button"
          >
            Give A Review
          </button>
        </>
      )}
      <Modal open={openFeedbackForm} onClose={() => setOpenFeedbackForm(false)}>
        <div className="review_mobile_modal">
          <FeedbackForm
            feedbackType="written"
            setOpen={setOpenFeedbackForm}
            isReview={true}
            salesRepId={id ? id : null}
          />
        </div>
      </Modal>
    </>
  );
};

export default MobileVersionBusinessCard;

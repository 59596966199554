import { Modal } from '@mui/material'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import UsersApi from '../../../API/UsersApi'
import StringIntReq from '../../../Requests/StringIntReq'

const RatingModal = ({ open, setOpen,currentRating }) => {
    const [rating, setRating] = React.useState("")


    useEffect(() => {
        setRating(currentRating?currentRating:"Unrated")
    }, [currentRating])


    const rateApplication = async () => {
        Swal.fire({
            title: 'Please Wait',
            text: 'Rating Applicant...',
            icon: 'info',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq()
        req.int = open
        req.string = rating
        const res = await UsersApi.RateApplication(req)

        if (res.status.success) {

            Swal.fire({
                title: 'Success',
                text: 'Applicant Rated Successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
            setOpen(false)
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.status.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
                didOpen: async () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className='custom_modal'>
                <h1>Rate Applicant</h1>
                <select value={rating} onChange={(e) => setRating(e.target.value)}>
                    <option value="Unrated">Unrated</option>
                    <option value="Maybe a Fit">Maybe a Fit</option>
                    <option value="Good Fit">Good Fit</option>
                    <option value="Best Fit">Best Fit</option>
                    <option value="Not a Fit">Not a Fit</option>
                </select>
                <button onClick={() => rateApplication()}>Rate</button>
            </div>
        </Modal>
    )
}

export default RatingModal
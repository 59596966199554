// components/FccLegend.jsx
import './styles/FccLegend.css';
import React from 'react';

const FccLegend = () => {
  return (
    <div className="fcc-legend">
      <h4>Residential Coverage</h4>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: 'rgba(0, 153, 76, 0.9)' }} />
        <span className="legend-label">0-25%</span>
      </div>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: 'rgba(255, 204, 0, 0.9)' }} />
        <span className="legend-label">25-50%</span>
      </div>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: 'rgba(255, 128, 0, 0.9)' }} />
        <span className="legend-label">50-75%</span>
      </div>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: 'rgba(255, 0, 0, 0.9)' }} />
        <span className="legend-label">75-100%</span>
      </div>
    </div>
  );
};

export default FccLegend;